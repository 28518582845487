import { Checkbox } from '@material-ui/core';
import { Column, Options } from '@infotrack/presentacion-componentes/tabla';
import IProductoPrincipal from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/productoPrincipal';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const columnas = (
    alSeleccionarItem: (itemSeleccionado: IProductoPrincipal) => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string,
    ItemId?: number
): Array<Column<IProductoPrincipal>> => [
    {
        sorting: false,
        render: (rowData) => (
            <Checkbox checked={ItemId === rowData.ItemId} onClick={() => alSeleccionarItem(rowData)} />
        ),
    },
    { field: 'CodigoBarras', title: formatMessage({ id: 'gestionproducto.CodigoBarras' }) },
    {
        field: 'FamiliaDescripcion',
        title: formatMessage({ id: 'gestionproducto.FamiliaDescripcion' }),
    },
    { field: 'DescripcionItem', title: formatMessage({ id: 'gestionproducto.DescripcionItem' }) },
    { field: 'Abreviatura', title: formatMessage({ id: 'gestionproducto.Abreviatura' }) },
];

export const opciones: Options<IProductoPrincipal> = {
    maxBodyHeight: '35vh',
    toolbar: false,
};
