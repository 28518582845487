import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/documento';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import DocumentoCM from '../controladorModelo/documentoCM';
import { acciones, IEstadoAPropiedades, IEstadoDevoluciones, IInputsDocumentos } from '../interfaces';
import FormularioDevolucionesVista from '../vista/formularioDevolucionesVista';

const valorInicial: IInputsDocumentos = {
    DescripcionBodegaOrigen: '',
    DireccionEntrega: '',
    Documento: null,
    EtapaDescripcion: '',
    PersonaRelacionada: '',
    Observacion: '',
};
const documentoCM = new DocumentoCM();

const FormularioDevolucionesCV: React.FunctionComponent<IEstadoAPropiedades> = ({ EmpresaId }) => {
    const [documentos, setDocumentos] = React.useState<IDocumento[]>([]);
    const { setValor, reiniciar, valor } = useInputState(valorInicial);
    const [{ estadoFormulario, detalles, entidadDocumento, tipoFormulario }, dispatch] = useProveedor<
        IEstadoDevoluciones
    >();

    React.useEffect(() => {
        if (estadoFormulario) consultarDocumentos();
    }, [estadoFormulario]);

    React.useEffect(() => {
        if (tipoFormulario === TipoFormulario.Edicion)
            dispatch({ type: acciones.ESTABLECER_DOCUMENTO, payload: entidadDocumento });
    }, [tipoFormulario]);

    React.useEffect(() => {
        if (entidadDocumento) setValor({ ...(entidadDocumento as any), Documento: { ...entidadDocumento } });
    }, [entidadDocumento]);

    const consultarDocumentos = async () => {
        setDocumentos(await documentoCM.consultarDespachosDevoluciones(EmpresaId));
    };

    const alCambiarDocumento = (documento: IDocumento) => {
        setValor({ ...(documento as any), Documento: documento });
        dispatch({ type: acciones.ESTABLECER_DOCUMENTO, payload: documento });
    };

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
        dispatch({ type: acciones.CARGAR_DETALLES, payload: [] });
        dispatch({ type: acciones.ESTABLECER_DOCUMENTO, payload: undefined });
        dispatch({ type: acciones.ESTABLECER_ENTIDAD_DOCUMENTO, payload: undefined });
        reiniciar();
    };

    const confirmarGuardarEditarDocumento = () => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: alGuardarEditarDocumento,
            estado: true,
            mensaje: (
                <Texto
                    id={
                        tipoFormulario === TipoFormulario.Creacion
                            ? 'alerta.confirmacionCreacion'
                            : 'alerta.confirmacionEdicion'
                    }
                />
            ),
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const alGuardarEditarDocumento = async () => {
        manejadorDialogoGlobal({ estado: false });
        if (tipoFormulario === TipoFormulario.Creacion) await documentoCM.crearDocumentoDevoluciones(valor.Documento!);
        else
            await documentoCM.modificarDocumentoDetallesDevoluciones({
                Documento: valor,
                DocumentosDetalles: detalles,
            } as any);
        dispatch({ type: acciones.RECARGAR_TABLA });
        alCerrarFormulario();
    };

    const confirmarCambioEtapa = () => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: modificarEtapa,
            estado: true,
            mensaje: (
                <Texto
                    id={
                        tipoFormulario === TipoFormulario.Creacion
                            ? 'alerta.confirmacionCreacion'
                            : 'alerta.confirmacionEdicion'
                    }
                />
            ),
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const modificarEtapa = async () => {
        manejadorDialogoGlobal({ estado: false });
        await documentoCM.cambiarEtapaDevolucion(valor.Documento!);
        const documento = await documentoCM.consultaGeneralDocumentosFiltro({ DocumentoId: valor.DocumentoId! });
        dispatch({ type: acciones.ESTABLECER_DOCUMENTO, payload: documento });
        setValor({ ...documento, Documento: documento });
    };

    return (
        <FormularioDevolucionesVista
            alCambiarDocumento={alCambiarDocumento}
            alCerrarFormulario={alCerrarFormulario}
            confirmarGuardarEditarDocumento={confirmarGuardarEditarDocumento}
            documento={valor}
            documentos={documentos}
            estadoFormulario={estadoFormulario}
            tipoFormularioDocumento={tipoFormulario}
            modificarEtapa={confirmarCambioEtapa}
        />
    );
};

const estadoAPropiedades = ({ estadoAutenticacion }: IEstadoGlobal): IEstadoAPropiedades => ({
    EmpresaId: estadoAutenticacion.usuarioInformacion!.IdEmpresa,
});

export default connect<IEstadoAPropiedades, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(FormularioDevolucionesCV);
