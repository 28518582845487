import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    dialogoComparacionConteo: {
        maxHeight: '400px',
        width:'400px',
    },
    botonCompracionConteo :{
        margin: '10px',
        textAlign:'center'
    }
});

export default useStyles;
