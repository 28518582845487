export default {
  'sidebar.administracion': 'Administration',
  'sidebar.pais': 'Country',
  'pais.agregarPais': 'Create Country',
  'pais.confirmacionCreacion': 'Are you sure to create the registry?',
  'pais.confirmacionEdicion': 'You are sure you want to edit the registry?',
  'pais.confirmacionEliminacion': 'Are you sure you want to delete the registry?',
  'pais.modificarPais': 'Edit Country',
  'pais.eliminarPais': 'Delete Country',
  'pais.codigoPais': 'Code   Country',
  'pais.nombrePais': 'Name Country',
  'pais.estadoPais': 'State',
  'pais.pais': 'Country',
};
