import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloDocumento from 'Infotrack@Modelos/smartStock/documentos';
import IDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/documento';
import IDocumentoAuditoria from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoAuditoria';
import IFiltroGeneralDocumentoConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/filtroGeneralDocumentoConsulta';
import IFiltrosDocumentos from 'Infotrack@Modelos/smartStock/documentos/entidades/filtrosDocumentosMovimientos';
import moment from 'moment';

export default class DocumentoCM {
    private modeloDocumento: ModeloDocumento;
    constructor() {
        this.modeloDocumento = new ModeloDocumento();
    }

    public consultarDocumentosGestionAuditoriaCompuesto = (paginador: IPaginador, documentos: IFiltrosDocumentos) =>
        this.modeloDocumento
            .consultarListaDocumentosAuditoriaCompuestos({ paginador, documentos })
            .then(({ data }) => data);

    public async consultaGeneralDocumentosFiltro(filtroDocumento: IFiltroGeneralDocumentoConsulta) {
        const {
            data: { Entidades },
        } = await this.modeloDocumento.consultaGeneralDocumentosFiltro(filtroDocumento);
        return Entidades[0];
    }

    public crearDocumentoAuditoria(documentoAuditoria: IDocumentoAuditoria) {
        return this.modeloDocumento.crearDocumentoAuditoria(documentoAuditoria);
    }

    public async anularDocumento(documento: IDocumento) {
        await this.modeloDocumento.anularDocumentoAuditoria(documento);
    }

    public modificarEstadoAuditoria(documento: IDocumento) {
        return this.modeloDocumento.modificarEstadoAuditoria(documento);
    }

    public validarDocumentoAuditoria({ BodegaId }: IDocumentoAuditoria) {
        if (!BodegaId || BodegaId === 0) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        }
        return true;
    }

    public validarFiltros({ BodegaConsulta, FechaInicial, FechaFinal }: IFiltrosDocumentos) {
        if (!BodegaConsulta) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else if (FechaInicial || FechaFinal) {
            if (moment(FechaInicial).isSameOrAfter(FechaFinal)) {
                notificacionGlobal('gestionauditorias.alerta.fechasInicialInvalida', 6000, 'warning', true);
                return false;
            }
            if ((FechaInicial && !FechaFinal) || (FechaFinal && !FechaInicial)) {
                notificacionGlobal('gestionauditorias.alerta.fechaIncompleta', 6000, 'warning', true);
                return false;
            }
            return true;
        }
        return true;
    }
}
