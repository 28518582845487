import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IClientes from 'Infotrack@Modelos/smartStock/clientes/entidades/clientes';

interface ICampoClienteIdProps {
    clientes: IClientes[];
    manejarCambioFiltro: (
        nombreFiltro: keyof IDocumentoFiltro,
        nuevoValor: IDocumentoFiltro[keyof IDocumentoFiltro]
    ) => any;
    valor?: string;
}

const CampoClienteId: FunctionComponent<ICampoClienteIdProps> = ({ clientes, manejarCambioFiltro, valor }) => (
    <Autocomplete
        getOptionLabel={(c) => c.DescripcionCliente}
        onChange={(_: object, value: IClientes | null) =>
            manejarCambioFiltro('ClienteId', value ? value.ClienteId : '')
        }
        options={clientes}
        renderInput={(params) => (
            <TextField {...params} fullWidth label={<Texto id="Documentos.CampoClienteId.Nombre" />} />
        )}
        value={clientes.find((c) => c.ClienteId === valor) || null}
    />
);

export default CampoClienteId;
