export default {
    'sidebar.procesos': 'Procesos',
    'sidebar.traslados': 'Traslados',
    'sidebar.trasladosbodegas': 'Traslados entre bodegas',
    'traslados.bodega': 'Bodega',
    'traslados.proveedor': 'Proveedor',
    'traslados.confirmarTraslados': 'Confirmar traslado',
    'traslados.cambiarEtapa': 'Cambiar etapa',
    'traslados.numeroDocumento': 'N° Documento',
    'traslados.documentoPadre': 'Documento padre',
    'traslados.datosDocumento': 'Datos documento',
    'traslados.detallesDelDocumento': 'Detalles del documento',
    'traslados.etapaDescripcion': 'Etapa',
    'traslados.bodegaOrigen': 'Bodega origen',
    'traslados.observaciones': 'Observaciones',
    'traslados.documento': 'Documento',
    'traslados.traslado': 'Traslados entre bodegas',
    'traslados.aprobarOrigen': '¿Está seguro que desea aprobar el origen?',
    'traslados.aprobarDestino': '¿Está seguro que desea aprobar el destino?',
    'traslados.iniciarAlistamiento': '¿Está seguro que desea iniciar el alistamiento?',
    'traslados.confirmarAlistamiento': '¿Está seguro que desea confirmar el alistamiento?',
    'traslados.enviarATransito': '¿Está seguro que desea enviar a transito?',
    'traslados.enviarAPlataformaDestino': '¿Está seguro que desea enviar a plataforma de destino?',
    'traslados.confirmarEntrega': '¿Está seguro que desea confirmar la entrega?',
    'traslados.cambiarEstado': 'Cambiar estado',
    'traslados.documento.CodigoDocumento': 'Código',
    'traslados.documento.DocumentoRelacionado': 'Documento relacionado',
    'traslados.documento.DocumentoPadre': 'Documento Padre',
    'traslados.documento.DescripcionProveedor': 'Proveedor',
    'traslados.documento.FechaCreacion': 'Fecha creación',
    'traslados.documento.EtapaDescripcion': 'Etapa',
    'traslados.documento.Observacion': 'Observación',
    'traslados.documentoDetalle.DescripcionItem': 'Producto',
    'traslados.documentoDetalle.CodigoBarras': 'Código de barras',
    'traslados.documentoDetalle.CantidadSolicitada': 'Cantidad solicitada',
    'traslados.documentoDetalle.CantidadRecibida': 'Cantidad trasladada',
    'traslados.documentoDetalle.DescripcionDivisionOrigen': 'División origen',
    'traslados.documentoDetalle.DescripcionDivisionDestino': 'División destino',
    'traslados.documentoDetalle.iniciarTraslados': 'Iniciar Traslados',
    'traslados.documentoDetalle.confirmarTraslados': 'Confirmar traslado',
    'traslados.documentoDetalle.editarDetalle': 'Editar detalle',
    'traslados.documentos.verDetalle': 'Ver detalle',
    'traslados.detalle': 'Detalle traslado',
    'traslados.documentos.alertaFiltroBodega': 'Por favor seleccione una bodega',
    'traslados.documentos.alertaFiltroProveedorDocumento':
        'Por favor seleccione un proveedor o ingrese un número de documento',
    'traslados.documentos.alertaLotes': 'Este producto requiere registrar un número de lote',
    'traslados.documentos.alertaSeriado': 'Este producto requiere registrar un número de Seriado',
    'traslados.documentos.alertaCantidadRecibida': 'La cantidad recibida debe ser mayor a la ingresada anteriormente',
    'traslados.documentos.alertaFIFO': 'Este producto requiere una fecha de vencimiento',
    'traslados.documentosdocumentoDetalle.edicionParcial': 'Parcial',
    'traslados.documentosdocumentoDetalle.edicionTotal': 'Total',
    'traslados.documentosdocumentoDetalle.traslado': 'Traslado entre bodegas',
    'traslados.documentosdocumentoDetalle.DivisionDestino': 'División de destino',
    'traslados.documentosdocumentoDetalle.CantidadRecibida': 'Cantidad',
    'traslados.documentosdocumentoDetalle.Lote': 'Lote',
    'traslados.documentosdocumentoDetalle.FechaVencimiento': 'Fecha vencimiento',
    'traslados.documentosdocumentoDetalle.Serial': 'Serial',
    'traslados.confirmacionInicioTraslado': '¿Está seguro que desea iniciar el traslado?',
    'traslados.confirmacionFinTraslado': '¿Está seguro que desea terminar el traslado?',

    ////Nuevo recursos Modal

    'ModalTraslados.TituloCreacion': 'Crear  Traslado',
    'ModalTraslados.TituloEdicion': 'Editar Traslado',
    'ModalTraslados.TituloVisualizacion': 'Ver Traslado',
    'ModalTraslados.TituloTablaDetalles': 'Detalles',
    'ModalTraslados.TituloTablaDetallesDocumentoBase': 'Detalles Documento Base',

    // mensajes
    'MensajeTraslados.confirmarAnularDocumento': '¿Está seguro que desea anular el documento?',
};
