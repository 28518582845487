import { createStyles, Theme } from '@material-ui/core';
import { coloresBase } from '@infotrack/presentacion-transversales/constantes';

const estilos = ({ spacing, transitions }: Theme) =>
    createStyles({
        contenedorModulos: {
            backgroundColor: coloresBase.paletaGeneral.grisClaro,
            flexGrow: 1,
            height: 'calc(100vh - 112px)',
            overflow: 'auto',
            padding: spacing(11, 3),
            transition: transitions.create('margin', {
                easing: transitions.easing.sharp,
                duration: transitions.duration.leavingScreen,
            }),
            marginLeft: -240,
        },
        contenedorModulosBarraAbierta: {
            transition: transitions.create('margin', {
                easing: transitions.easing.easeOut,
                duration: transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        divPrincipal: {
            display: 'flex',
        },
    });

export default estilos;
