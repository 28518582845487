import ModeloBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones';
import ModeloItems from 'Infotrack@Modelos/smartStock/items';

class EntidadesDetalleCM {
    private modeloBodegaDivision: ModeloBodegaDivisiones;
    private modeloItem: ModeloItems;

    constructor() {
        this.modeloBodegaDivision = new ModeloBodegaDivisiones();
        this.modeloItem = new ModeloItems();
    }

    public async consultarEntidadesDetalle(bodegaDestinoId: number, tipoDocumentoId: number,empresaId:string) {
        const respuestas = await Promise.all([
            this.modeloItem.consultarListaItemsCompuestosFiltro({EmpresaId:empresaId, Estado:1}),
            this.modeloBodegaDivision.consultarDivisionesDocumentosDef({
                BodegaId: bodegaDestinoId,
                TipoDocumentoId: tipoDocumentoId,
            }),
        ]);
        return {
            items: respuestas[0].data.Entidades,
            divisionesDestino: respuestas[1].data.Entidades,
        };
    }
}

export default EntidadesDetalleCM;
