import { Button, Dialog, DialogActions, DialogContent, Grid, withStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/WarningRounded';
import Texto from '@infotrack/presentacion-componentes/texto';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import { IDialogoGlobalProps } from '../interfaces';
import estilos from './estilos';

const DialogoGlobal: React.FunctionComponent<PropsEstilosMaterialUI<IDialogoGlobalProps>> = ({
    accionCancelar,
    accionConfirmar,
    classes,
    estado,
    mensaje,
    mostrarCancelar,
    reiniciarDialogo,
    tipoDialogo = 'Informativa',
    titulo,
}) => (
    <Dialog fullWidth open={estado} onExited={reiniciarDialogo} maxWidth="xs">
        <DialogContent className={classes.contenedor}>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={12}>
                    {titulo && <Texto fontWeight="bold" mensaje={titulo} />}
                </Grid>
                <Grid item xs={12}>
                    <div className={`${classes.indicadorTipoAlerta} ${classes[`alerta${tipoDialogo}`]}`}>
                        <Grid alignItems="center" container spacing={2}>
                            <Grid item xs="auto">
                                {tipoDialogo === 'Advertencia' && <WarningIcon />}
                                {tipoDialogo === 'Exito' && <CheckCircleIcon />}
                                {tipoDialogo === 'Error' && <ErrorIcon />}
                                {tipoDialogo === 'Informativa' && <InfoIcon />}
                            </Grid>
                            <Grid item xs="auto">
                                <Texto
                                    className={classes[`alerta${tipoDialogo}`]}
                                    fontWeight="bold"
                                    id={`titulo.alerta${tipoDialogo}`}
                                    variant="subtitle2"
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    {mensaje && <Texto mensaje={mensaje} variant="body2" />}
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            {mostrarCancelar ? (
                <>
                    <Button color="default" onClick={accionCancelar}>
                        <Texto id="boton.cancelar" color="inherit" />
                    </Button>
                    <Button className={classes[`boton${tipoDialogo}`]} color="default" onClick={accionConfirmar}>
                        <Texto id="boton.continuar" color="inherit" />
                    </Button>
                </>
            ) : (
                <Button color="default" onClick={accionConfirmar}>
                    <Texto id="boton.cerrar" color="inherit" />
                </Button>
            )}
        </DialogActions>
    </Dialog>
);

export default withStyles(estilos)(DialogoGlobal);
