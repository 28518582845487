import { Grid, ListItem, MenuItem, TextField } from '@material-ui/core';
import Texto from '@infotrack/presentacion-componentes/texto';
import moment from 'moment';
import React from 'react';
import { IItemDivisionVistaProps } from '../interfaces';

const ItemDivisionVista: React.FunctionComponent<IItemDivisionVistaProps> = ({
    alCambiarValorCantidades,
    alCambiarValorDivisionesDestino,
    classes,
    divisiones,
    divisionMultiple,
    itemDivision: { CantidadSeleccionada, CantidadDisponible, DivisionDescripcion, DivisionId, DivisionDestinoId },
    itemId,
}) => (
    <ListItem dense>
        <Grid alignItems="center" container justifyContent="space-between" spacing={2}>
            <Grid item md="auto" xs>
                <Texto className={classes.descripciones} id="selectItems.division" mensaje=":" fontWeight="bold" />
                <Texto mensaje={DivisionDescripcion} />
            </Grid>
            <Grid item md="auto" xs>
                <Texto
                    className={classes.descripciones}
                    id="selectItems.cantidadDisponible"
                    mensaje=":"
                    fontWeight="bold"
                />
                <Texto mensaje={CantidadDisponible} />
            </Grid>
            <Grid item md={4} xs>
                <Grid container justifyContent="space-between" spacing={2}>
                    {divisionMultiple && (
                        <Grid item xs>
                            <TextField
                                fullWidth
                                label={<Texto id="selectItems.divisionDestino" />}
                                onClick={(e) => e.stopPropagation()}
                                onChange={({ target: { name, value } }) =>
                                    alCambiarValorDivisionesDestino({
                                        Id: DivisionId,
                                        ItemId: itemId,
                                        NivelModificacion: name,
                                        ValorModificacion: Number(value),
                                    })
                                }
                                select
                                variant="outlined"
                                value={DivisionDestinoId ? DivisionDestinoId : ''}
                            >
                                {divisiones.length === 0 ? (
                                    <MenuItem value="">
                                        <Texto id="label.sinRegistros" />
                                    </MenuItem>
                                ) : (
                                    divisiones.map(
                                        ({ DivisionDescripcion: DivisionDestinoDescripcion, DivisionId: Id }) => (
                                            <MenuItem key={Id} value={Id}>
                                                {DivisionDestinoDescripcion}
                                            </MenuItem>
                                        )
                                    )
                                )}
                            </TextField>
                        </Grid>
                    )}
                    <Grid item md="auto" xs>
                        <TextField
                            fullWidth
                            name="Division"
                            label={<Texto id="selectItems.cantidad" />}
                            onClick={(e) => e.stopPropagation()}
                            onChange={({ target: { name, value } }) =>
                                alCambiarValorCantidades({
                                    Id: DivisionId,
                                    ItemId: itemId,
                                    NivelModificacion: name,
                                    ValorModificacion: Number(value),
                                })
                            }
                            variant="outlined"
                            value={CantidadSeleccionada}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </ListItem>
);

export default ItemDivisionVista;
