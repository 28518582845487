import moment from 'moment';

import ModeloMovimiento from 'Infotrack@Modelos/negocioRefactor/modelos/Movimiento/ModeloMovimiento';

class MovimientoCM {
    private modeloMovimiento: ModeloMovimiento;

    constructor() {
        this.modeloMovimiento = new ModeloMovimiento();
    }

    public async stockItem(idBodega: number, idItem: number) {
        const respuesta = await this.modeloMovimiento.stockItem(idBodega, idItem);
        return respuesta.data.Entidades.map((itemInventario, indice) => ({
            ...itemInventario,
            FechaVecimiento: moment(itemInventario.FechaVencimiento).format('YYYY-MM-DD'),
            ItemInventarioId: indice,
        }));
    }

    public async stockItemDisponible(idBodega: number, idItem: number) {
        const respuesta = await this.modeloMovimiento.stockItemDisponible(idBodega, idItem);
        return respuesta.data.Entidades.map((itemInventario, indice) => ({
            ...itemInventario,
            FechaVecimiento: moment(itemInventario.FechaVencimiento).format('YYYY-MM-DD'),
            ItemInventarioId: indice,
        }));
    }
}

export default MovimientoCM;
