const mensajes = {
    //PLantillaImpresionTabla
    'PlantillaImpresionTabla.Acciones': 'Actions',
    'PlantillaImpresionTabla.VerPlantillaImpresion': 'See printing template',
    'PlantillaImpresionTabla.EditarPlantillaImpresion': 'Edit printing template',
    'PlantillaImpresionTabla.EliminarPlantillaImpresion': 'Delete printing template',
    'PlantillaImpresionTabla.PlantillaImpresionDescripcion': 'Printing template',
    //ModalPlantillaImpresion
    'ModalPlantillaImpresion.TituloCreacion': 'Create printing template',
    'ModalPlantillaImpresion.TituloEdicion': 'Edit printing template',
    'ModalPlantillaImpresion.TituloVisualizacion': 'See printing template',
    'ModalPlantillaImpresion.BotonGuardar': 'Save',
    'ModalPlantillaImpresion.BotonEditar': 'Edit',
    'ModalPlantillaImpresion.Cancelar': 'Unsaved data will be lost, do you want to close the window?',
    //CampoPLantillaImpresionDescripcion
    'PlantillaImpresion.CampoPlantillaImpresionDescripcion.Nombre': 'Printing Template',
    'PlantillaImpresion.CampoComando.Nombre': 'Command',
    //Validaciones
    'PlantillaImpresion.ValidacionNoPlantillaImpresionDescripcion': 'You must enter a description for the template',
    'PlantillaImpresion.ValidacionNoComando': 'You must enter a command for the template',
};

export default mensajes;
