import { IRespuesta, IRespuestaPaginada } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocio } from '../../conexiones';
import IClienteCompuesto from './entidades/clienteCompuesto';
import IClientePaginador from './entidades/clientePaginador';
import IClientes from './entidades/clientes';

class ModeloClientes {
    public consultarClientes() {
        return manejadorRest<IRespuesta<IClientes>>(() => negocio.get('Cliente/ConsultarListaClientes'), true);
    }

    public consultarListaClientesPaginadaCompuesta(clientePaginador: IClientePaginador) {
        return manejadorRest<IRespuestaPaginada<IClienteCompuesto>>(
            () => negocio.post('Cliente/ConsultarListaClientePaginadaCompuesta', clientePaginador),
            true
        );
    }

    public crearClientes(clientes: IClientes) {
        return manejadorRest<IRespuesta<IClientes>>(() => negocio.post('Cliente/GuardarCliente', clientes), true);
    }

    public validarCreacionCliente(clientes: IClientes) {
        return manejadorRest<IRespuesta<IClientes>>(
            () => negocio.post('Cliente/ValidarCreacionCliente', clientes),
            true
        );
    }

    public editarClientes(clientes: IClientes) {
        return manejadorRest<IRespuesta<IClientes>>(() => negocio.put('Cliente/EditarCliente', clientes), true);
    }

    public eliminarClientes(clientes: IClientes) {
        return manejadorRest<IRespuesta<IClientes>>(() => negocio.delete('Cliente/EliminarCliente', clientes), true);
    }

    public async consultarClientesPorFiltro(filtroClientes: Partial<IClientes>) {
        return manejadorRest<IRespuesta<IClientes>>(
            () => negocio.post('Cliente/ConsultarListaClientesPorFiltro', filtroClientes),
            true
        );
    }
}

export default ModeloClientes;
