import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';

import ModeloAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/modelos/Auditoria/ModeloAuditoriaConteo';

class AuditoriaConteoCM {
    private modeloAuditoriaConteo: ModeloAuditoriaConteo;

    constructor() {
        this.modeloAuditoriaConteo = new ModeloAuditoriaConteo();
    }

    public async crearConteo(auditoriaConteo: IAuditoriaConteo) {
        if (!this.validarCamposObligatorioConteo(auditoriaConteo)) return { Entidades: [], Resultado: false };
        const respuesta = await this.modeloAuditoriaConteo.crearConteo(auditoriaConteo);
        return respuesta.data;
    }

    public async consultarListaConteo(filtro: Partial<IAuditoriaConteo>) {
        const respuesta = await this.modeloAuditoriaConteo.consultarListaConteo(filtro);
        return respuesta.data.Entidades;
    }

    public async consultarConteoAuditoria(auditoriaConteoId: string) {
        const respuesta = await this.modeloAuditoriaConteo.consultarConteoAuditoria(auditoriaConteoId);
        return respuesta.data.Entidades[0];
    }

    public async marcarConteo(auditoriaConteoId: string) {
        const respuesta = await this.modeloAuditoriaConteo.marcarConteo(auditoriaConteoId);
        return respuesta.data.Resultado;
    }

    private validarCamposObligatorioConteo(auditoriaConteo: IAuditoriaConteo) {
        if (!auditoriaConteo.DescripcionConteo) {
            notificacionGlobal('AuditoriaConteo.ValidacionNoDescripcionConteo', 3000, 'warning', true);
            return false;
        }
        if (!auditoriaConteo.IdTipoConteo) {
            notificacionGlobal('AuditoriaConteo.ValidacionNoIdTipoConteo', 3000, 'warning', true);
            return false;
        }
        if (!auditoriaConteo.ConteoPredecesorId) {
            notificacionGlobal('AuditoriaConteo.ValidacionNoConteoPredecesor', 3000, 'warning', true);
            return false;
        }
        return true;
    }
}

export default AuditoriaConteoCM;
