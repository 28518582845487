export default {
  'sidebar.despachos': 'Despatches',
  'despachos.NumeroDocumento': 'N° Document',
  'despachos.TipoDocumento': 'Type of Document',
  'despachos.FechaCreacionDocumento': 'Initial Date',
  'despachos.FechaVencimientoDocumento': 'Final Date',
  'despachos.PersonaCreacion': 'Associated people',
  'despachos.Bodega': 'Warehouse',
  'despachos.Cliente': 'Client',
  'despachos.modificarEtapa': 'Change stage',
  'despachos.BodegaOrigenDescripcion': 'Origin warehouse',
  'despachos.BodegaDestinoDescripcion': 'Destination warehouse',
  'despachos.EstadoDocumento': 'Document state',
  'despachos.documento.Documento': 'Document',
  'despachos.documento.DireccionEntrega': 'Delivery address',
  'despachos.documento.PersonaRelacionada': 'Related person',
  'despachos.documento.CodigoDocumento': 'Code',
  'despachos.documento.DocumentoRelacionado': 'Related document',
  'despachos.documento.CodigoDocumentoPadre': 'Parent documento',
  'despachos.documento.DescripcionProveedor': 'Provider',
  'despachos.documento.FechaEntrega': 'Creation date',
  'despachos.documento.EtapaDescripcion': 'Stage',
  'despachos.documento.Observacion': 'Observation',
  'despachos.documento.DocumentoPadreId': 'Parent document',
  'despachos.documento.DocumentoPadre': '¿It is a parent document?',
  'despachos.documento.tituloEstadoDespacho': 'Modify dispatch status',
  'despachos.documentoDetalle.CantidadRecibida': 'Request amount',
  'despachos.documentoDetalle.CantidadEntregada': 'Amount dispatched',
  'despachos.documentoDetalle.DescripcionItem': 'Item description',
  'despachos.documentoDetalle.CodigoBarras': 'Barcode',
  'despachos.documentoDetalle.CantidadSolicitada': 'Request amount',
  'despachos.documentoDetalle.DivisionDestino': 'Division',
  'despachos.documento.DescripcionCliente': 'Client',
  'despachos.documento.crearDocumento': 'Create document',
  'despachos.documento.editarDocumento': 'Edit document',
  'despachos.documento.BodegaOrigen': 'Origin winery',
  'despachos.documento.datosDocumento': 'Document data',
  'despachos.documentoDetalle.documentoDetalles': 'Document details',
  'despachos.alerta.fechaInvalida': 'The final date must be greater than the initial date',
  'despachos.alerta.fechaFaltante': 'Enter both dates to perform the search',
  'despachos.alerta.cambioEstadoDespachado': 'Are you sure you want to ship the order?',
  'despachos.alerta.cambioEstadoEntregaConfirmada': 'Are you sure you want to confirm delivery?'
};
