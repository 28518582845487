import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import { TipoAccion, TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';

import IItemsTiposPropiedades from 'Infotrack@Modelos/smartStock/itemTiposPropiedades/entidades/itemTiposPropiedad';
import PropiedadItem from 'Infotrack@Modelos/smartStock/itemTiposPropiedades/entidades/propiedadItem';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';

import EntidadesItemCM from '../controladorModelo/entidadesItemCM';
import { IFormularioPropiedadesProductoCVProps, IInputsItemPropiedades } from '../interfaces';
import { acciones, IEstadoGestionProducto } from '../reductorGestionProducto';
import FormularioPropiedadesProductosVista from '../vista/formularioPropiedadesProductoVista';

const entidadesItemCM = new EntidadesItemCM();

const FormularioPropiedadesProductoCV: FunctionComponent<IFormularioPropiedadesProductoCVProps> = ({ IdEmpresa }) => {
    const [
        { estadoFormularioPropiedades, itemPropiedad, tipoFormularioPropiedades, listaItem },
        dispatch,
    ] = useProveedor<IEstadoGestionProducto>();
    //   recordar cambiar propiedades a tipos propeidades
    const [itemTiposPropiedades, setItemTiposPropiedades] = useState<IItemsTiposPropiedades[]>([]);
    const [propiedad, setPropiedad] = useState<PropiedadItem>();
    const theme = useTheme();
    const esMovil = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        cargarPropiedades();
    }, []);

    const alCambiarEstadoFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_PROPIEDADES });
    };

    const alEditarGuardarItemPropiedad = () => {
        if (validarPropiedadesItems()) {
            dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_PROPIEDADES });
            if (tipoFormularioPropiedades === TipoFormulario.Creacion) {
                const { TipoPropiedad } = itemPropiedad;
                const ItemTipoPropiedadDescripcion = itemTiposPropiedades.find(
                    (props) =>
                        props.ItemTipoPropiedadId === Number(TipoPropiedad ? TipoPropiedad.ItemTipoPropiedadId : 0)
                )!;
                dispatch({
                    type: acciones.AGREGAR_ITEM_PROPIEDAD,
                    payload: {
                        ...itemPropiedad,
                        Accion: TipoAccion.guardado,
                        ItemTipoPropiedadDescripcion: ItemTipoPropiedadDescripcion.ItemTipoPropiedadDescripcion,
                    },
                });
            } else {
                dispatch({
                    type: acciones.EDITAR_ITEM_PROPIEDADES,
                    payload: { ...itemPropiedad, Accion: TipoAccion.editado },
                });
            }
        } else notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
    };

    const validarPropiedadesItems = () => {
        if (itemPropiedad.TipoPropiedad) {
            switch (propiedad) {
                case PropiedadItem.Bool:
                    return true;
                case PropiedadItem.Numero:
                    return Number(itemPropiedad.Cantidad) !== 0;
                case PropiedadItem.Texto:
                    return itemPropiedad.ItemPropiedadTexto.trim() !== '';
            }
        } else return false;
    };

    const alCambiarValor = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { checked, name, type, value } = target as EventTarget & HTMLInputElement;
        const valor = type === 'checkbox' ? checked : value;
        dispatch({ type: acciones.MODIFICAR_ITEM_PROPIEDADES, payload: { llave: name, valor } });
    };

    const alCambiarValorAutoComplete = (
        nuevoValor: IInputsItemPropiedades[keyof IInputsItemPropiedades],
        nombreValor: string
    ) => {
        dispatch({ type: acciones.MODIFICAR_ITEM_PROPIEDADES, payload: { llave: nombreValor, valor: nuevoValor } });
        if (nombreValor === 'TipoPropiedad') establecerPropiedades(nuevoValor as IItemsTiposPropiedades);
    };

    const cargarPropiedades = async () => {
        setItemTiposPropiedades(await entidadesItemCM.consultarTipoPropiedadesItem(IdEmpresa));
    };

    const establecerPropiedades = (itemTipoPropiedad: IItemsTiposPropiedades) => {
        if (itemTipoPropiedad) {
            const nuevaPropiedad = itemTiposPropiedades.find(
                ({ ItemTipoPropiedadId }) => ItemTipoPropiedadId === Number(itemTipoPropiedad.ItemTipoPropiedadId)
            )!.ItemTipoPropiedadDescripcion as PropiedadItem;
            setPropiedad(nuevaPropiedad);
        } else setPropiedad(undefined);
        dispatch({ type: acciones.REINICIAR_CAMPOS_ITEM_PROPIEDADES });
    };

    return (
        <FormularioPropiedadesProductosVista
            alCambiarValor={alCambiarValor}
            alCambiarValorAutoComplete={alCambiarValorAutoComplete}
            alCerrarFormulario={alCambiarEstadoFormulario}
            alEditarGuardarItemPropiedad={alEditarGuardarItemPropiedad}
            estadoFormulario={estadoFormularioPropiedades}
            esMovil={esMovil}
            itemPropiedades={itemPropiedad}
            itemTipoPropiedades={itemTiposPropiedades}
            tipoFormulario={tipoFormularioPropiedades}
            propiedad={propiedad}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion },
}: IEstadoGlobal): IFormularioPropiedadesProductoCVProps => ({
    IdEmpresa: usuarioInformacion!.IdEmpresa,
});
export default connect<IFormularioPropiedadesProductoCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(FormularioPropiedadesProductoCV);
