import { Grid, IconButton, Tooltip } from '@material-ui/core';
import {
    Block as BlockIcon,
    Edit as EditIcon,
    Remove as RemoveIcon,
    Visibility as VisibilityIcon,
    PictureAsPdf as PdfIcon,
} from '@material-ui/icons';
import moment from 'moment';
import React, { FunctionComponent, RefObject } from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';

import Tabla, { Column, Query, QueryResult } from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';

interface IDocumentosTablaProps {
    agregarDocumento: () => void;
    anularDocumento: (documentoId: string) => void;
    camposOcultar?: Array<keyof IDocumentoConsultaGeneral>;
    consulta: ({ page, pageSize, orderBy, orderDirection }: Query<any>) => Promise<QueryResult<any>>;
    consultarDocumentos: () => void;
    editarDocumento: (documentoId: string) => any;
    referenciaTabla: RefObject<any>;
    verDocumento: (documentoId: string) => any;
    descargarDocumentoPdf: (documento: IDocumentoConsultaGeneral) => any;
    esTraslado: boolean;
    descargarPlantilla?: () => void;
    abrirFormularioCargaMasiva?: () => void;
}

const DocumentosTabla: FunctionComponent<IDocumentosTablaProps & InjectedIntlProps> = ({
    agregarDocumento,
    anularDocumento,
    camposOcultar = [],
    consulta,
    consultarDocumentos,
    editarDocumento,
    referenciaTabla,
    verDocumento,
    descargarDocumentoPdf,
    intl,
    esTraslado,
    descargarPlantilla,
    abrirFormularioCargaMasiva,
}) => {
    const obtenerColumnas = () => {
        const columnas: Array<Column<IDocumentoConsultaGeneral>> = [];
        columnas.push({
            render: (documento: IDocumentoConsultaGeneral) => (
                <Grid container wrap="nowrap">
                    <Grid item>
                        <Tooltip title={<Texto id="TablaDocumentos.VerDocumento" />}>
                            <IconButton onClick={() => verDocumento(documento.DocumentoId)} size="small">
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={<Texto id="TablaDocumentos.EditarDocumento" />}>
                            <IconButton
                                disabled={!documento.ListaAcciones.PermiteEditarDocumento}
                                onClick={() => editarDocumento(documento.DocumentoId)}
                                size="small"
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={<Texto id="TablaDocumentos.AnularDocumento" />}>
                            <span>
                                <IconButton
                                    disabled={!documento.ListaAcciones.PermiteAnularDocumento}
                                    onClick={() => anularDocumento(documento.DocumentoId)}
                                    size="small"
                                >
                                    <BlockIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    {documento.PlantillaImpresionId !== null && (
                        <Grid item>
                            <Tooltip title={<Texto id="TablaDocumentos.DescargarDocumento" />}>
                                <IconButton onClick={() => descargarDocumentoPdf(documento)} size="small">
                                    <PdfIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            ),
            title: <Texto id="TablaDocumentos.Acciones" />,
        });
        if (!camposOcultar.includes('DescripcionTipoDocumentoId')) {
            columnas.push({
                field: 'DescripcionTipoDocumentoId',
                title: <Texto id="TablaDocumentos.DescripcionTipoDocumentoId" />,
            });
        }
        if (!camposOcultar.includes('DescripcionEtapaId')) {
            columnas.push({
                field: 'DescripcionEtapaId',
                title: <Texto id="TablaDocumentos.DescripcionEtapaId" />,
            });
        }
        if (!camposOcultar.includes('DescipcionProveedorId')) {
            columnas.push({
                field: 'DescipcionProveedorId',
                title: <Texto id="TablaDocumentos.DescripcionProveedorId" />,
            });
        }
        if (!camposOcultar.includes('CodigoDocumento')) {
            columnas.push({
                field: 'CodigoDocumento',
                title: <Texto id="TablaDocumentos.CodigoDocumento" />,
            });
        }
        if (!camposOcultar.includes('DireccionEntrega')) {
            columnas.push({
                field: 'DireccionEntrega',
                title: <Texto id="TablaDocumentos.DireccionEntrega" />,
            });
        }
        if (!camposOcultar.includes('FechaEntrega')) {
            columnas.push({
                field: 'FechaEntrega',
                render: (documento: IDocumentoConsultaGeneral) =>
                    documento.FechaEntrega ? moment(documento.FechaEntrega).format('DD/MM/YYYY') : <RemoveIcon />,
                title: <Texto id="TablaDocumentos.FechaEntrega" />,
            });
        }
        if (!camposOcultar.includes('CondicionComercial')) {
            columnas.push({
                field: 'CondicionComercial',
                title: <Texto id="TablaDocumentos.CondicionComercial" />,
            });
        }
        if (!camposOcultar.includes('DescripcionBodegaOrigen')) {
            columnas.push({
                field: 'DescripcionBodegaOrigen',
                title: <Texto id="TablaDocumentos.DescripcionBodegaOrigen" />,
            });
        }
        if (!camposOcultar.includes('DescripcionBodegaDestino')) {
            columnas.push({
                field: 'DescripcionBodegaDestino',
                title: <Texto id="TablaDocumentos.DescripcionBodegaDestino" />,
            });
        }
        if (!camposOcultar.includes('DocumentoRelacionado')) {
            columnas.push({
                field: 'DocumentoRelacionado',
                title: esTraslado ? (
                    <Texto id="TablaDocumentos.NumeroTicket" />
                ) : (
                    <Texto id="TablaDocumentos.DocumentoRelacionado" />
                ),
            });
        }
        if (!camposOcultar.includes('Observacion')) {
            columnas.push({
                field: 'Observacion',
                title: esTraslado ? (
                    <Texto id="TablaDocumentos.NumeroGuia" />
                ) : (
                    <Texto id="TablaDocumentos.Observacion" />
                ),
            });
        }
        if (!camposOcultar.includes('PersonaRelacionada')) {
            columnas.push({
                field: 'PersonaRelacionada',
                title: <Texto id="TablaDocumentos.PersonaRelacionada" />,
            });
        }
        if (!camposOcultar.includes('CodigoDocumentoPredecesor')) {
            columnas.push({
                field: 'CodigoDocumentoPredecesor',
                title: <Texto id="TablaDocumentos.CodigoDocumentoPredecesor" />,
            });
        }
        if (!camposOcultar.includes('FechaCreacion')) {
            columnas.push({
                field: 'FechaCreacion',
                render: (documento: IDocumentoConsultaGeneral) =>
                    moment(documento.FechaCreacion).format('DD/MM/YYYY HH:mm'),
                title: <Texto id="TablaDocumentos.FechaCreacion" />,
            });
        }
        if (!camposOcultar.includes('DescripcionClienteId')) {
            columnas.push({
                field: 'DescripcionClienteId',
                title: <Texto id="TablaDocumentos.DescripcionClienteId" />,
            });
        }
        if (!camposOcultar.includes('DescripcionEstado')) {
            columnas.push({
                field: 'DescripcionEstado',
                title: <Texto id="TablaDocumentos.DescripcionEstado" />,
            });
        }

        if (!camposOcultar.includes('OrigenExterno')) {
            columnas.push({
                field: 'OrigenExterno',
                render: (documento: IDocumentoConsultaGeneral) =>
                documento.OrigenExterno ?  <Texto id='Comunes.Si'></Texto>: <Texto id='Comunes.No'></Texto>,
                title: <Texto id="TablaDocumentos.OrigenExterno" />,
            });
        }
        return columnas;
    };

    return (
        <Tabla
            actions={[
                {
                    icon: 'refresh',
                    isFreeAction: true,
                    tooltip: intl.formatMessage({ id: 'boton.refrescar' }),
                    onClick: consultarDocumentos,
                },
                {
                    icon: 'add',
                    isFreeAction: true,
                    tooltip: intl.formatMessage({ id: 'boton.agregar' }),
                    onClick: agregarDocumento,
                },
                {
                    hidden:descargarPlantilla ===  undefined,
                    icon: () => (<GetAppIcon /> ),
                    onClick: descargarPlantilla ? descargarPlantilla : () => {},
                    isFreeAction: true,
                    tooltip: intl.formatMessage({ id: 'cargaMasiva.DescargarArchivo' }),
                },
                {
                    hidden:descargarPlantilla ===  undefined,
                    icon: () => (abrirFormularioCargaMasiva ? <PublishIcon /> : <div></div>),
                    onClick: abrirFormularioCargaMasiva?abrirFormularioCargaMasiva: () => {},
                    isFreeAction: true,
                    tooltip: intl.formatMessage({ id: 'cargaMasiva.CargarArchivo' }),
                },
            ]}
            columns={obtenerColumnas()}
            data={consulta}
            options={{ padding: 'dense' }}
            tableRef={referenciaTabla}
        />
    );
};

//export default DocumentosTabla;
export default injectIntl(DocumentosTabla);
