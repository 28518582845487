import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
    Toolbar,
} from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import React from 'react';
import TablaDetallesDevolucionesCV from '../controladorVista/tablaDetallesDevolucionesCV';
import { IFormularioDevolucionesVistaProps } from '../interfaces';

const CustomTextField: React.FunctionComponent<TextFieldProps> = (props) => (
    <TextField {...props} value={props.value ? props.value : ''} variant="outlined" />
);

const FormularioDevolucionesVista: React.FunctionComponent<IFormularioDevolucionesVistaProps> = ({
    alCambiarDocumento,
    alCerrarFormulario,
    confirmarGuardarEditarDocumento,
    documentos,
    documento,
    estadoFormulario,
    tipoFormularioDocumento,
    modificarEtapa,
}) => (
    <Dialog open={estadoFormulario} fullWidth maxWidth="lg">
        <AppBar position="static">
            <Toolbar>
                <Grid container justifyContent="center">
                    <Texto
                        align="center"
                        id={
                            tipoFormularioDocumento === TipoFormulario.Edicion
                                ? 'devolucion.documento.editarDocumento'
                                : 'devolucion.documento.crearDocumento'
                        }
                    />
                </Grid>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid alignItems="center" container spacing={2}>
                                <Grid item xs={12}>
                                    <Texto id="devolucion.documento.datosDocumento" fontWeight="bold" />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <Autocomplete
                                        disableClearable
                                        getOptionLabel={({ CodigoDocumento }) => CodigoDocumento!}
                                        onChange={(_: any, doc: any) => alCambiarDocumento(doc)}
                                        options={documentos}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label={<Texto id="devolucion.Documento" display="inline" />}
                                            />
                                        )}
                                        noOptionsText={<Texto id="label.sinRegistros" />}
                                        value={documento.Documento!== null ?documento.Documento:undefined}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <CustomTextField
                                        InputProps={{ readOnly: true }}
                                        label={<Texto display="inline" id="devolucion.documento.DireccionEntrega" />}
                                        fullWidth
                                        value={documento.DireccionEntrega}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <CustomTextField
                                        InputProps={{ readOnly: true }}
                                        label={<Texto display="inline" id="devolucion.documento.PersonaRelacionada" />}
                                        fullWidth
                                        value={documento.PersonaRelacionada}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <CustomTextField
                                        InputProps={{ readOnly: true }}
                                        fullWidth
                                        label={<Texto display="inline" id="devolucion.documento.BodegaOrigen" />}
                                        value={documento.DescripcionBodegaOrigen}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <CustomTextField
                                        InputProps={{ readOnly: true }}
                                        fullWidth
                                        label={<Texto display="inline" id="devolucion.documento.EtapaDescripcion" />}
                                        value={documento.EtapaDescripcion}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <CustomTextField
                                        InputProps={{ readOnly: true }}
                                        label={<Texto id="devolucion.documento.Observacion" />}
                                        fullWidth
                                        value={documento.Observacion}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <TablaDetallesDevolucionesCV />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button color="secondary" onClick={alCerrarFormulario} variant="contained">
                        <Texto id="boton.cancelar" />
                    </Button>
                </Grid>
                {/* tipoFormularioDocumento === TipoFormulario.Edicion && (
          <Grid item>
            <Button
              color="primary"
              disabled={documento.EtapaDescripcion === TiposEtapas.Cerrada}
              onClick={modificarEtapa}
              variant="contained"
            >
              <Texto id="devolucion.modificarEtapa" />
            </Button>
          </Grid>
        ) */}
                <Grid item>
                    <Button color="primary" onClick={confirmarGuardarEditarDocumento} variant="contained">
                        <Texto id="boton.guardar" />
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
);

export default FormularioDevolucionesVista;
