export default {
    'barraLateral.label.cerrarSesion': 'Logout',
    'barraLateral.label.lenguajes': 'Idioms',
    'barraLateral.label.selector': 'Application selector',
    'barraLateral.title.logo': 'Home',
    'barraLateral.title.perfil': 'Profile',
    'base.alerta.errorConexion': 'Could not connect to service.',
    'base.alerta.seleccionarAgencia': 'Select an agency to continue',
    'base.observadorRespuestaREST.mensajeSesionExpirada': 'Your session expired',
    'base.observadorRespuestaREST.tituloSesionExpirada': 'Notice',
    'button.paginaNoEncontrada': 'go back',
    'comunes.alerta.agenciaNoSeleccionada': 'Please select an agency',
    'comunes.alerta.camposIncompletos': 'Please enter all required fields (*)',
    'comunes.alerta.usuarioSinAgencia': 'This user does not have an assigned agency',
    'comunes.alerta.usuarioSinAplicacion': 'This user does not have an assigned application',
    'label.sinRegistros': 'No records found',
    'lenguajes.label.en': 'English',
    'lenguajes.label.es': 'Spanish',
    'login.inicioSesion': 'login',
    'login.placeHolderContrasena': 'Enter your password',
    'login.placeHolderUsuario': 'Enter your username',
    'login.sesion.invalida': 'Your session expires please enter again',
    'login.sesion.sesionExpirada': 'Your session expired due to inactivity',
    'selectorIdioma.tooltip': 'Change language',
    'sidebar.app': 'Aplication',
    'subtitle.paginaNoEncontrada': 'Page not found',
    'title.paginaNoEncontrada': '404',
    'label.inputImagen.limiteImagen': 'Image',
    'label.inputImagen.limiteImagenes': 'Images',
    'label.inputImagen.maximo': 'Máximo',
    /* labels tabla */
    'materialTable.emptyDataSourceMessage': 'No records to display.',
    'materialTable.saveTooltip': 'Save',
    'materialTable.cancelTooltip': 'Cancel',
    'materialTable.deleteText': 'Are you sure delete this row?	',
    'materialTable.addTooltip': 'Add',
    'materialTable.deleteTooltip': 'Delete',
    'materialTable.editTooltip': 'Edit',
    'materialTable.actions': 'Actions',
    'materialTable.groupedBy': 'Grouped',
    'materialTable.placeholder': 'Drag headers ...',
    'materialTable.firstTooltip': 'First Page',
    'materialTable.previousTootip': 'Previous Page',
    'materialTable.nextTooltip': 'Next Page',
    'materialTable.labelRowsPerPage': 'Rows per page',
    'materialTable.lastTooltip': 'Last page',
    'materialTable.labelRowsSelect': 'Rows',
    'materialTable.addRemoveColumns': 'Add or remove columns',
    'materialTable.nRowsSelected': 'row(s) selected',
    'materialTable.showColumnsTitle': 'Show Columns	s',
    'materialTable.showColumnsAriaLabel': 'Show Columns	',
    'materialTable.exportAriaLabel': 'Export',
    'materialTable.exportTitle': 'Export',
    'materialTable.exportCSVName': 'Export as CSV',
    'materialTable.exportPDFName': 'Export as PDF',
    'materialTable.searchTooltip': 'Search',
    'materialTable.labelDisplayedRows': 'of',
    'Comunes.ExportarXLSX': 'Export to Excel',
    'Comunes.Si': 'Yes',
    'Comunes.No': 'No',
    'Comunes.Activo': 'Active',
    'Comunes.Inactivo': 'Inactive',
};
