import React from 'react';
import FormularioBodegasPersonasCV from '../controladorVista/formularioBodegasPersonasCV';
import TablaBodegasPersonasCV from '../controladorVista/tablaBodegasPersonasCV';

const PersonasAsociadasCV: React.FunctionComponent = () => (
  <>
    <TablaBodegasPersonasCV />
    <FormularioBodegasPersonasCV />
  </>
);

export default PersonasAsociadasCV;
