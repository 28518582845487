enum TiposEstadosDetalles {
  Creada = 'Creada',
  Cerrada = 'Cerrada',
  Cancelada = 'Cancelada',
  Anulado = 'Anulado',
  Procesado = 'Procesado',
  EnAlistamiento = 'En Alistamiento',
  DespachoParcial = 'Despacho Parcial',
  Alistado = 'Alistado',
  Despachado = 'Despachado',
  RecepciónParcial = 'Recepción Parcial',
  Conteo = 'Conteo',
  EnAlmacenamiento = 'En Almacenamiento',
  Almacenado = 'Almacenado',
}

export default TiposEstadosDetalles;
