import React, { createRef, FunctionComponent, useEffect, useRef, useState } from 'react';
import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import IValidacionProducto from 'Infotrack@Modelos/smartStock/procesarArchivo/entidades/validacionProducto';
import Texto from '@infotrack/presentacion-componentes/texto';
interface ITablaErroresProps {
    listaErrores: IValidacionProducto[];
}

const TablaErrores: FunctionComponent<ITablaErroresProps> = ({ listaErrores }) => {
    const ConcatenarMensajesErroresArchivo = (listaErrores: string[]) => {
        let mensajes: string;
        mensajes = '';
        listaErrores.map((error) => (mensajes = mensajes + error + '\n'));
        return mensajes;
    };
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="center">
                        {' '}
                        <Texto id="cargaMasiva.Identificador" />
                    </TableCell>
                    <TableCell align="center">
                        {' '}
                        <Texto id="cargaMasiva.Error" style={{ textAlign: 'center' }} />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {listaErrores.map((validacionVehiculo) => (
                    <TableRow key={validacionVehiculo.DescripcionItem}>
                        <TableCell>{validacionVehiculo.DescripcionItem}</TableCell>
                        <TableCell>{ConcatenarMensajesErroresArchivo(validacionVehiculo.Errores)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default TablaErrores;
