const mensajes = {
    // SalidaInventario
    'OrdenTraslado.ValidacionNoTipoDocumentoId':'You must select a document type',
    'OrdenTraslado.ValidacionNoBodegaOrigen':'You must select an origin warehouse',
    'OrdenTraslado.ValidacionNoClienteId':  'You must select a client',
    // ModalSalidaInventario
    'ModalOrdenTraslado.TituloCreacion': 'Create Transfer Order',
    'ModalOrdenTraslado.TituloEdicion': 'Edit Transfer Order',
    'ModalOrdenTraslado.TituloVisualizacion': 'See Transfer Order',
    'ModalOrdenTraslado.TituloTablaDetalles': 'Details',
    'ModalOrdenTraslado.TituloTablaDetallesDocumentoBase': 'Details Base Document',
    'permiso.ordentraslado': 'Acceso',
    // mensajes
    'MensajeOrdenTraslado.confirmarAnularDocumento': '¿Are you sure you want to void the document??',
};

export default mensajes;
