import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    TextField,
    Toolbar,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Texto from '@infotrack/presentacion-componentes/texto';
import { esEnteroODecimal } from '@infotrack/utilitarios/expresionesRegulares';
import React from 'react';
import { IFormularioEdicionDetalleDocumentoVistaProps } from '../interfaces';

const FormularioEdicionDetalleAlmacenamientoVista: React.FunctionComponent<IFormularioEdicionDetalleDocumentoVistaProps> = ({
    documentoDetalle,
    tipoFormulario,
    estadoFormulario,
    esMovil,
    divisiones,
    alCambiarValor,
    alEditarDocumentoDetalle,
    CerrarFormularioDocumentoDetalle,
}) => (
    <Dialog maxWidth={esMovil ? 'lg' : 'sm'} open={estadoFormulario} fullWidth>
        <AppBar position="static">
            <Toolbar>
                <Grid container justifyContent="center">
                    <Texto align="center" id="almacenamiento.documentosdocumentoDetalle.almacenamiento" />
                </Grid>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Card>
                <CardContent>
                    <Grid alignItems="center" container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={
                                    <Texto
                                        display="inline"
                                        id="almacenamiento.documentosdocumentoDetalle.DivisionDestino"
                                    />
                                }
                                name="DivisionDestino"
                                required
                                select
                                onChange={alCambiarValor}
                                value={documentoDetalle.DivisionDestino}
                                variant="outlined"
                            >
                                {divisiones.length === 0 ? (
                                    <MenuItem value="">
                                        <Texto id="label.sinRegistros" />
                                    </MenuItem>
                                ) : (
                                    divisiones.map(({ DivisionDescripcion, DivisionId }) => (
                                        <MenuItem key={DivisionId} value={DivisionId}>
                                            {DivisionDescripcion}
                                        </MenuItem>
                                    ))
                                )}
                            </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={
                                    <Texto
                                        display="inline"
                                        id="almacenamiento.documentosdocumentoDetalle.CantidadRecibida"
                                    />
                                }
                                name="CantidadRecibida"
                                required
                                onChange={(e) =>
                                    esEnteroODecimal(e.target.value, { cantidadDecimales: 5, cantidadEnteros: 7 }) &&
                                    alCambiarValor(e)
                                }
                                value={documentoDetalle.CantidadRecibida}
                                variant="outlined"
                            />
                        </Grid>
                        {documentoDetalle.LotesItems && (
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label={<Texto id="almacenamiento.documentosdocumentoDetalle.Lote" />}
                                    name="Lote"
                                    required
                                    onChange={alCambiarValor}
                                    value={documentoDetalle.Lote}
                                    variant="outlined"
                                />
                            </Grid>
                        )}
                        {documentoDetalle.SeriadoItems && (
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label={<Texto id="almacenamiento.documentosdocumentoDetalle.Serial" />}
                                    name="Serial"
                                    required
                                    onChange={alCambiarValor}
                                    value={documentoDetalle.Serial}
                                    variant="outlined"
                                />
                            </Grid>
                        )}
                        {documentoDetalle.FEFO && (
                            <Grid item md={6} xs={12}>
                                <KeyboardDatePicker
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    label={
                                        <Texto
                                            display="inline"
                                            id="almacenamiento.documentosdocumentoDetalle.FechaVencimiento"
                                        />
                                    }
                                    autoOk
                                    variant="inline"
                                    required
                                    onChange={(fecha: any) => {
                                        // tslint:disable-next-line:no-object-literal-type-assertion
                                        alCambiarValor({
                                            target: { name: 'FechaVencimiento', value: fecha },
                                        } as React.ChangeEvent<HTMLInputElement>);
                                    }}
                                    value={documentoDetalle.FechaVencimiento}
                                    inputVariant="outlined"
                                />
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </DialogContent>
        <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xs="auto">
                    <Button color="secondary" fullWidth onClick={CerrarFormularioDocumentoDetalle} variant="contained">
                        <Texto id="boton.cancelar" />
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button color="primary" fullWidth onClick={() => alEditarDocumentoDetalle()} variant="contained">
                        <Texto id="boton.guardar" />
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
);

export default FormularioEdicionDetalleAlmacenamientoVista;
