import { AnyAction } from 'redux';

import IAccion from '@infotrack/presentacion-modulosbase/modelos/seguridad/autorizacion/entidades/IAccion'; //'../modelos/seguridad/autorizacion/entidades/IAccion';
import IAgencia from '@infotrack/presentacion-modulosbase/modelos/seguridad/agencia/entidades/IAgencia';
import IMenu from '@infotrack/presentacion-modulosbase/modelos/seguridad/autorizacion/entidades/IMenu';
import IUsuarioAplicacionAgencia from '@infotrack/presentacion-modulosbase/modelos/seguridad/usuario/entidades/IUsuarioAplicacionAgencia';
import IUsuarioInformacion from '@infotrack/presentacion-modulosbase/modelos/seguridad/autenticacion/entidades/IUsuarioInformacion';
import {
    CERRAR_SESION_AUTENTICACION,
    ESTABLECER_MENUS,
    ESTABLECER_PERMISOS,
    ESTABLECER_USUARIO_INFORMACION,
    SELECCIONAR_AGENCIA,
    SELECCIONAR_USUARIO_APLICACION_AGENCIA,
} from './tiposAcciones';

export const cerrarSesionAutenticacion = (): AnyAction => {
    return {
        type: CERRAR_SESION_AUTENTICACION,
    };
};

export const establecerMenus = (menus: IMenu[]): AnyAction => {
    return {
        payload: menus,
        type: ESTABLECER_MENUS,
    };
};

export const establecerPermisos = (permisos: IAccion[]): AnyAction => {
    return {
        payload: permisos,
        type: ESTABLECER_PERMISOS,
    };
};

export const establecerUsuarioInformacion = (usuarioInformacion: IUsuarioInformacion | null): AnyAction => {
    return {
        payload: usuarioInformacion,
        type: ESTABLECER_USUARIO_INFORMACION,
    };
};

export const seleccionarUsuarioAplicacionAgencia = (
    usuarioAplicacionAgencia: IUsuarioAplicacionAgencia | null
): AnyAction => ({
    payload: usuarioAplicacionAgencia,
    type: SELECCIONAR_USUARIO_APLICACION_AGENCIA,
});

export const seleccionarAgencia = (agencia: IAgencia | null): AnyAction => {
    return {
        payload: agencia,
        type: SELECCIONAR_AGENCIA,
    };
};
