import { Button, DialogActions, Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

interface IModalItemSeriadoAccionesProps {
    cancelar: () => void;
    guardar: () => void;
}

const ModalSerialesAcciones: FunctionComponent<IModalItemSeriadoAccionesProps> = ({ cancelar, guardar }) => {
    return (
        <DialogActions>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button onClick={cancelar} size="small" variant="contained">
                                <Texto id="boton.cancelar" />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary" onClick={guardar} size="small" variant="contained">
                                <Texto id="boton.guardar" />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DialogActions>
    );
};

export default ModalSerialesAcciones;
