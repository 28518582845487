import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';
import ModeloTipoDocumento from 'Infotrack@Modelos/negocioRefactor/modelos/Administracion/ModeloTipoDocumento';

class TipoDocumentoCM {
    private modeloTipoDocumento: ModeloTipoDocumento;

    constructor() {
        this.modeloTipoDocumento = new ModeloTipoDocumento();
    }

    public async consultarListaTipoDocumentoCompuesto(filtro: Partial<ITipoDocumento>) {
        const respuesta = await this.modeloTipoDocumento.consultarListaTipoDocumentoCompuesto(filtro);
        return respuesta.data.Entidades;
    }

    public async consultarListaTipoDocumentoCompuestoPorEmpresaId(EmpresaId: string) {
        return this.modeloTipoDocumento.consultarListaTipoDocumentoCompuesto({ EmpresaId: EmpresaId }).then(({ data: { Entidades } }) => Entidades);
    }

    public async guardarTipoDocumento(tipoDocumento: ITipoDocumento) {
        const respuesta = await this.modeloTipoDocumento.guardarTipoDocumento(tipoDocumento);
        return respuesta.data;
    }

    public async editarTipoDocumento(tipoDocumento: ITipoDocumento) {
        return this.modeloTipoDocumento.editarTipoDocumento(tipoDocumento);
    }

    public async eliminarTipoDocumento(filtro: Partial<ITipoDocumento>) {
        const respuesta = await this.modeloTipoDocumento.eliminarTipoDocumento(filtro);
        return respuesta.data.Resultado;
    }
}

export default TipoDocumentoCM;
