import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import IPlantillaImpresion from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IPlantillaImpresion';
import React, { FunctionComponent } from 'react';

interface IPlantillaImpresionTablaProps {
    crearPlantillaImpresion: () => void | null;
    editarPlantillaImpresion: (plantillaImpresion: IPlantillaImpresion) => any | null;
    eliminarPlantillaImpresion: (plantillaImpresion: number) => any | null;
    recargarTabla: () => any;
    plantillasImpresion: IPlantillaImpresion[];
    verPlantillaImpresion: (plantillaImpresion: IPlantillaImpresion) => any | null;
}

const PlantillaImpresionTabla: FunctionComponent<IPlantillaImpresionTablaProps> = ({
    crearPlantillaImpresion,
    editarPlantillaImpresion,
    eliminarPlantillaImpresion,
    recargarTabla,
    plantillasImpresion,
    verPlantillaImpresion,
}) => {
    return (
        <Tabla
            actions={[
                { icon: 'refresh', isFreeAction: true, onClick: recargarTabla },
                { icon: 'add', isFreeAction: true, onClick: crearPlantillaImpresion },
            ]}
            columns={[
                {
                    render: (plantillaImpresion: IPlantillaImpresion) => (
                        <Grid container wrap="nowrap">
                            <Grid item>
                                <Tooltip title={<Texto id="PlantillaImpresionTabla.VerPlantillaImpresion" />}>
                                    <IconButton onClick={() => verPlantillaImpresion(plantillaImpresion)} size="small">
                                        <Visibility />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={<Texto id="PlantillaImpresionTabla.EditarPlantillaImpresion" />}>
                                    <IconButton
                                        onClick={() => editarPlantillaImpresion(plantillaImpresion)}
                                        size="small"
                                    >
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={<Texto id="PlantillaImpresionTabla.EliminarPlantillaImpresion" />}>
                                    <IconButton
                                        onClick={() =>
                                            eliminarPlantillaImpresion(plantillaImpresion.PlantillaImpresionId)
                                        }
                                        size="small"
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ),
                    title: <Texto id="PlantillaImpresionTabla.Acciones" />,
                },
                {
                    field: 'PlantillaImpresionDescripcion',
                    title: <Texto id="PlantillaImpresionTabla.PlantillaImpresionDescripcion" />,
                },
                // {
                //     render: (plantillaImpresion) => {
                //         return `${comando} ${plantillaImpresion.PlantillaImpresionDescripcion}`;
                //     },
                //     title: <Texto id="PlantillaImpresionTabla.Comando" />,
                // },
            ]}
            data={plantillasImpresion}
            options={{ padding: 'dense' }}
        ></Tabla>
    );
};

export default PlantillaImpresionTabla;
