import { AnyAction } from 'redux';

import { ILLavesLenguaje } from 'Infotrack@Transversales/internacionalizacion';

import {
    CERRAR_SESION_CONFIGURACION,
    SELECCIONAR_LENGUAJE,
    SELECCIONAR_MENU,
    SELECCIONAR_RUTA_ACTIVA,
    SELECCIONAR_SUBMENU,
    SELECCIONAR_TEMA,
} from './tiposAcciones';
import { ILlavesTemas } from 'Infotrack@Transversales/constantes/temas';

export const cerrarSesionConfiguracion = (): AnyAction => {
    return {
        type: CERRAR_SESION_CONFIGURACION,
    };
};

export const seleccionarLenguaje = (payload: ILLavesLenguaje): AnyAction => {
    return {
        payload,
        type: SELECCIONAR_LENGUAJE,
    };
};

export const seleccionarMenu = (payload: number): AnyAction => {
    return {
        payload,
        type: SELECCIONAR_MENU,
    };
};

export const seleccionarRutaActiva = (payload: string): AnyAction => {
    return {
        payload,
        type: SELECCIONAR_RUTA_ACTIVA,
    };
};

export const seleccionarSubMenu = (payload: string): AnyAction => {
    return {
        payload,
        type: SELECCIONAR_SUBMENU,
    };
};

export const seleccionarTema = (payload: keyof ILlavesTemas): AnyAction => {
    return {
        payload,
        type: SELECCIONAR_TEMA,
    };
};
