import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import Thunk from 'redux-thunk';

import { IEstadoGlobal } from './interfacesReductores';
import reductoreAutenticacion from './reductorAutenticacion';
import reductorConfiguracionGlobal from './reductorConfiguracionGlobal';

const reductores = combineReducers<IEstadoGlobal>({
  estadoAutenticacion: reductoreAutenticacion,
  estadoConfiguracion: reductorConfiguracionGlobal,
});

export const store = createStore(reductores, {}, composeWithDevTools(applyMiddleware(Thunk)));

export const obtenerEstado = () => store.getState().estadoAutenticacion.usuarioInformacion!.TokenServicioSeguridad!;

export default reductores;
