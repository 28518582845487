const mensajes = {
    'sidebar.pedidos': 'Orders',
    'sidebar.gestionpedidos': 'Order Management',
    'gestionPedidos.NumeroDocumento': 'N° Document',
    'gestionPedidos.TipoDocumento': 'Type of Document',
    'gestionPedidos.FechaCreacionDocumento': 'Initial Date',
    'gestionPedidos.FechaVencimientoDocumento': 'Final Date',
    'gestionPedidos.PersonaCreacion': 'Associated people',
    'gestionPedidos.Bodega': 'Warehouse',
    'gestionPedidos.Cliente': 'Client',
    'gestionPedidos.BodegaOrigenDescripcion': 'Origin warehouse',
    'gestionPedidos.BodegaDestinoDescripcion': 'Destination warehouse',
    'gestionPedidos.EstadoDocumento': 'Document state',
    'gestionPedidos.documento.CodigoDocumento': 'Code',
    'gestionPedidos.documento.DocumentoRelacionado': 'Related document',
    'gestionPedidos.documento.DescripcionProveedor': 'Provider',
    'gestionPedidos.documento.FechaEntrega': 'Creation date',
    'gestionPedidos.documento.EtapaDescripcion': 'Stage',
    'gestionPedidos.documento.Observacion': 'Observation',
    'gestionPedidos.documento.DocumentoPadreId': 'Parent document',
    'gestionPedidos.documento.DocumentoPadre': '¿It is a parent document?',
    'gestionPedidos.documentoDetalle.DescripcionItem': 'Item description',
    'gestionPedidos.documentoDetalle.CodigoBarras': 'Barcode',
    'gestionPedidos.documentoDetalle.CantidadSolicitada': 'Request amount',
    'gestionPedidos.documentoDetalle.DivisionOrigen': 'Origin division',
    'gestionPedidos.documento.DescripcionCliente': 'Client',
    'gestionPedidos.documento.crearDocumento': 'Create document',
    'gestionPedidos.documento.editarDocumento': 'Edit document',
    'gestionPedidos.documento.BodegaOrigen': 'Origin winery',
    'gestionPedidos.documento.datosDocumento': 'Document data',
    'gestionPedidos.documentoDetalle.documentoDetalles': 'Document details',
    'gestionPedidos.alerta.fechaInvalida': 'The final date must be greater than the initial date',
    'gestionPedidos.alerta.fechaFaltante': 'Enter both dates to perform the search',
    'gestionPedidos.alerta.cantidadInsuficiente':
        'The warehouse/division does not contain the requested amount. Current amount:',
    'gestionPedidos.alerta.faltaBodegaOrigen': 'Please select the required document fields',
    'gestionPedidos.alerta.confirmarCambioBodega':
        'Are you sure you want to change the warehouse?, The added details are removed',
    'gestionPedidos.alerta.detalleEnAlistamiento':
        'The product is already ready, if you wish to edit it, request that the modification of the enlisted quantity',

    // ModalPedido
    'ModalPedido.TituloTablaDetalles': 'Order details',
};

export default mensajes;
