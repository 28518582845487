import ModeloTipoDocumento from '../../../../modelos/negocioRefactor/modelos/Administracion/ModeloTipoDocumento';
import ModeloBodegas from '../../../../modelos/smartStock/bodegas';
import ModeloEtapas from '../../../../modelos/smartStock/etapas';
import ModeloProveedor from '../../../../modelos/smartStock/proveedores';
import ModeloDivision from '../../../../modelos/smartStock/bodegasDivisiones';
import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';

class EntidadesDocumentoCM {
    private modeloTipoDocumento: ModeloTipoDocumento;
    private modeloEtapa: ModeloEtapas;
    private modeloProveedor: ModeloProveedor;
    private modeloBodega: ModeloBodegas;
    private modeloDivision: ModeloDivision;

    constructor() {
        this.modeloTipoDocumento = new ModeloTipoDocumento();
        this.modeloEtapa = new ModeloEtapas();
        this.modeloProveedor = new ModeloProveedor();
        this.modeloBodega = new ModeloBodegas();
        this.modeloDivision = new ModeloDivision();
    }

    public async consultarEntidadesDocumento(idEmpresa: string, idAgencia: string, idUsuario: string,estado?: number) {
        const respuestas = await Promise.all([
            this.modeloEtapa.ConsultarListaEtapasPorFiltro({ EmpresaId: idEmpresa,Estado:estado }),
            this.modeloProveedor.consultarListaProveedoresPorFiltro({ EmpresaId: idEmpresa,Estado:estado }),
            this.modeloBodega.consultarBodegasPersonasDoc({
                AgenciaId: idAgencia,
                UsuarioId: idUsuario,
                EmpresaId: idEmpresa,
                Estado:estado
            }),
        ]);
        return {
            etapas: respuestas[0].data.Entidades,
            proveedores: respuestas[1].data.Entidades,
            bodegasOrigen: respuestas[2].data.Entidades,
        };
    }

    public async consultarEntidadesDocumentoEdicion(
        idBodega: number,
        idEmpresa: string,
        idAgencia: string,
        idUsuario: string,
        tipoProcesoId: number,
        estado?: number
    ) {
        const respuestas = await Promise.all([
            this.modeloTipoDocumento.consultarTiposDocumentoFiltro({
                BodegaId: idBodega,
                Estado: estado,
                TipoProcesoId:tipoProcesoId
            }),
            this.modeloEtapa.ConsultarListaEtapasPorFiltro({ EmpresaId: idEmpresa,Estado:estado }),
            this.modeloProveedor.consultarListaProveedoresPorFiltro({ EmpresaId: idEmpresa,Estado:estado }),
            this.modeloBodega.consultarBodegasPersonasDoc({
                AgenciaId: idAgencia,
                UsuarioId: idUsuario,
                EmpresaId: idEmpresa,
                Estado:estado
            }),
        ]);
        return {
            tiposDocumento: respuestas[0].data.Entidades,
            etapas: respuestas[1].data.Entidades,
            proveedores: respuestas[2].data.Entidades,
            bodegasOrigen: respuestas[3].data.Entidades,
        };
    }

    public async consultarTipoDocumento(idBodega: number,estado?: number) {
        const respuestas = await Promise.all([
            this.modeloTipoDocumento.consultarTiposDocumentoFiltro({
                BodegaId: idBodega,
                TipoProcesoId: TiposProceso.SALIDA_POR_DEVOLUCION,
                Estado:estado
            }),
        ]);
        return respuestas[0].data.Entidades;
    }
}
export default EntidadesDocumentoCM;
