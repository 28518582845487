export default {
  'sidebar.administracion': 'Administration',
  'sidebar.tiporegimen': 'Type Regimen',
  'tipoRegimen.agregarTipoRegimen': 'Create Type Regimen',
  'tipoRegimen.confirmacionCreacion': 'Are you sure to create the registry?',
  'tipoRegimen.confirmacionEdicion': 'You are sure you want to edit the registry?',
  'tipoRegimen.confirmacionEliminacion': 'Are you sure you want to delete the registry?',
  'tipoRegimen.modificarTipoRegimen': 'Edit Type Regimen',
  'tipoRegimen.eliminarTipoRegimen': 'Delete Type Regimen',
  'tipoRegimen.codigoTipoRegimen': 'Code Type Regimen',
  'tipoRegimen.nombreTipoRegimen': 'Name Type Regimen',
  'tipoRegimen.estadoTipoRegimen': 'State',
  'tipoRegimen.tipoRegimen': 'Type Regimen',
};
