import React from 'react';
import { FormattedMessage } from 'react-intl';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';

import { Action, Column, Options } from '@infotrack/presentacion-componentes/tabla';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';

import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IBodegaCompuesta from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaCompuesta';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IBodegasPersonas from 'Infotrack@Modelos/smartStock/bodegasPersonas/entidades/bodegasPersonas';
import IBodegaPropiedades from 'Infotrack@Modelos/smartStock/bodegasPropiedades/entidades/bodegaPropiedades';
import IDivisionesDocumentosDefecto from 'Infotrack@Modelos/smartStock/divisionesDocumentosDefecto/entidades/divisionesDocumentosDefecto';
import { BotonAgregar, BotonRefrescar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import { IInputsBodegaPropiedades, IInputsDivisionesPropiedas } from '../interfaces';
import { Checkbox } from '@material-ui/core';

export const acionesTablaBodegas = (
    alAbrirFormulario: (bodega?: IBodegaCompuesta) => void,
    alEliminarBodega: (bodega: IBodega) => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string,
    recargarTabla: () => void
): Array<Action<IBodegaCompuesta>> => [
    {
        icon: 'refresh',
        onClick: () => recargarTabla(),
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.refrescar' }),
    },
    {
        icon: 'add',
        onClick: () => alAbrirFormulario(),
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
    },
    {
        icon: () => <EditIcon color="action" />,
        onClick: (_, valor) => alAbrirFormulario(valor as IBodegaCompuesta),
        tooltip: formatMessage({ id: 'boton.editar' }),
    },
    {
        icon: () => <DeleteIcon color="action" />,
        onClick: (_, valor) => alEliminarBodega(valor as IBodegaCompuesta),
        tooltip: formatMessage({ id: 'boton.eliminar' }),
    },
];

export const columnasTablaBodegas = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IBodegaCompuesta>> => [
    { field: 'BodegaCodigo', title: formatMessage({ id: 'gestionBodega.BodegaCodigo' }) },
    { field: 'BodegaDescripcion', title: formatMessage({ id: 'gestionBodega.BodegaDescripcion' }) },
    { field: 'TipoBodegaDescripcion', title: formatMessage({ id: 'gestionBodega.TipoBodegaDescripcion' }) },
    { field: 'DescripcionCiudad', title: formatMessage({ id: 'gestionBodega.DescripcionCiudad' }) },
    { field: 'Agencias', title: formatMessage({ id: 'gestionBodega.Agencias' }) },
    { field: 'Estado', title: formatMessage({ id: 'gestionBodega.Estado' }), type: 'boolean' },
    { field: 'DescripcionAgencia', title: formatMessage({ id: 'gestionBodega.Agencia' }) },
    
];

export const opcionesBodegas: Options<IBodegaCompuesta> = {
    maxBodyHeight: '50vh',
};

export const acionesTablaBodegaPropiedades = (
    alAbrirFormulario: (bodegaPropiedades?: IInputsBodegaPropiedades) => void,
    alEliminarBodegaPropiedades: (bodegaPropiedades: IInputsBodegaPropiedades) => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Action<IInputsBodegaPropiedades>> => [
    {
        component: BotonAgregar,
        icon: () => <AddIcon />,
        onClick: () => alAbrirFormulario(),
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
    },
    {
        icon: () => <EditIcon color="action" />,
        onClick: (_, valor) => alAbrirFormulario(valor as IInputsBodegaPropiedades),
        tooltip: formatMessage({ id: 'boton.editar' }),
    },
    {
        icon: () => <DeleteIcon color="action" />,
        onClick: (_, valor) => alEliminarBodegaPropiedades(valor as IInputsBodegaPropiedades),
        tooltip: formatMessage({ id: 'boton.eliminar' }),
    },
];

export const columnasPropiedadesBodegas = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IBodegaPropiedades>> => [
    {
        field: 'BodegaTipoPropiedadDescripcion',
        title: formatMessage({ id: 'gestionBodega.Propiedades.BodegaTipoPropiedadDescripcion' }),
    },
    {
        field: 'DescripcionItem',
        title: formatMessage({ id: 'gestionBodega.Propiedades.DescripcionItem' }),
    },
    {
        field: 'FamiliaDescripcion',
        title: formatMessage({ id: 'gestionBodega.Propiedades.FamiliaDescripcion' }),
    },
    {
        field: 'BodegaPropiedadTexto',
        title: formatMessage({ id: 'gestionBodega.Propiedades.BodegaPropiedadTexto' }),
    },
    {
        field: 'DescripcionUnidad',
        title: formatMessage({ id: 'gestionBodega.Propiedades.DescripcionUnidad' }),
    },
    {
        field: 'Cantidad',
        title: formatMessage({ id: 'gestionBodega.Propiedades.Cantidad' }),
    },
    {
        field: 'Estado',
        title: formatMessage({ id: 'gestionBodega.Propiedades.Estado' }),
        type: 'boolean',
    },
];

export const opcionesPropiedadesBodegas: Options<IBodegaPropiedades> = {
    maxBodyHeight: '30vh',
    showTitle: true,
};

export const acionesPropiedadesBodegas = (
    alAbrirFormulario: (bodegaProp?: IInputsBodegaPropiedades) => void,
    alEliminarBodegaPropiedades: (IdTemporal: number) => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Action<IInputsBodegaPropiedades>> => [
    {
        component: BotonAgregar,
        icon: () => <AddIcon />,
        onClick: () => alAbrirFormulario(),
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
    },
    {
        icon: () => <EditIcon color="action" />,
        onClick: (_, valor) => alAbrirFormulario(valor as IInputsBodegaPropiedades),
        tooltip: formatMessage({ id: 'boton.editar' }),
    },
    {
        icon: () => <DeleteIcon color="action" />,
        onClick: (_, valor: any) => alEliminarBodegaPropiedades(valor.IdTemporal),
        tooltip: formatMessage({ id: 'boton.eliminar' }),
    },
];

export const columnasBodegaPersona = (
    formatMessage: (params: FormattedMessage.MessageDescriptor) => string
): Array<Column<IBodegasPersonas>> => [
    {
        title: formatMessage({ id: 'bodegasPersonas.NombrePersona' }),
        field: 'NombrePersona',
    },
    {
        title: formatMessage({ id: 'bodegasPersonas.BodegaPrincipal' }),
        field: 'BodegaPrincipal',
        type: 'boolean',
    },
    {
        title: formatMessage({ id: 'bodegasPersonas.Estado' }),
        field: 'Estado',
        type: 'boolean',
    },
];

export const accionesBodegaPersona = (
    manejarFormulario: (tipoFormulario: TipoFormulario, bodegasPersonas?: IBodegasPersonas) => void,
    cargarBodegasPersonas: () => void,
    formatMessage: (params: FormattedMessage.MessageDescriptor) => string,
    confirmarEliminacion: (bodegasPersonas: IBodegasPersonas) => void
): Array<Action<IBodegasPersonas>> => [
    {
        component: BotonRefrescar,
        icon: () => <RefreshIcon />,
        onClick: cargarBodegasPersonas,
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.refrescar' }),
    },
    {
        component: BotonAgregar,
        icon: () => <AddIcon />,
        onClick: () => manejarFormulario(TipoFormulario.Creacion, undefined),
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
    },
    {
        icon: () => <EditIcon color="action" />,
        onClick: (_, value) => manejarFormulario(TipoFormulario.Edicion, value as IBodegasPersonas),
        tooltip: formatMessage({ id: 'boton.editar' }),
    },
    {
        icon: () => <DeleteIcon color="action" />,
        onClick: (_, value) => confirmarEliminacion(value as IBodegasPersonas),
        tooltip: formatMessage({ id: 'boton.eliminar' }),
    },
];

export const opcionesBodegaPersona: Options<IBodegasPersonas> = {
    maxBodyHeight: '50vh',
};

export const columnasDivisionesDefecto = (
    formatMessage: (params: FormattedMessage.MessageDescriptor) => string,
    confirmarActivarDivisionDefecto: (divisionesDocumentosDefectos: IDivisionesDocumentosDefecto) => void
): Array<Column<IDivisionesDocumentosDefecto>> => [
    {
        field: 'DivisionDefecto',
        title: formatMessage({ id: 'divisionesDocumentosDefecto.TituloDivisionDefecto' }),
        render: (divisionesDocumentosDefecto) => (
            <Checkbox
                checked={divisionesDocumentosDefecto.DivisionDefecto}
                onChange={() =>
                    !divisionesDocumentosDefecto.DivisionDefecto
                        ? confirmarActivarDivisionDefecto(divisionesDocumentosDefecto)
                        : undefined
                }
            />
        ),
    },
    {
        title: formatMessage({ id: 'divisionesDocumentosDefecto.TipoMovimientoDescripcion' }),
        field: 'TipoDocumentoDescripcion',
    },
    {
        title: formatMessage({ id: 'divisionesDocumentosDefecto.DivisionDescripcion' }),
        field: 'DivisionDescripcion',
    },
];

export interface IFormularioDivisionesDocumentosDefectoCVProps {
    IdEmpresa: string;
    IdAgencia: string;
}

export const accionesDivisionesDefecto = (
    manejarFormulario: () => void,
    cargarDivisionesDocumentosDefecto: () => void,
    formatMessage: (params: FormattedMessage.MessageDescriptor) => string,
    confirmarEliminacion: (divisionesDocumentosDefecto: IDivisionesDocumentosDefecto) => void
): Array<Action<IDivisionesDocumentosDefecto>> => [
    {
        component: BotonRefrescar,
        icon: () => <RefreshIcon />,
        onClick: cargarDivisionesDocumentosDefecto,
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.refrescar' }),
    },
    {
        component: BotonAgregar,
        icon: () => <AddIcon />,
        onClick: () => manejarFormulario(),
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
    },
    {
        icon: () => <DeleteIcon color="action" />,
        onClick: (_, value) => confirmarEliminacion(value as IDivisionesDocumentosDefecto),
        tooltip: formatMessage({ id: 'boton.eliminar' }),
    },
];

export const opcionesDivisionesDefecto: Options<IDivisionesDocumentosDefecto> = {
    maxBodyHeight: '30vh',
};

export const accionesDivisionesBodega = (
    alAbrirFormulario: (divisionBodega?: IBodegaCompuesta) => void,
    alEliminarBodega: (divisionBodega: IBodegaDivisiones) => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string,
    recargarTabla: () => void
): Array<Action<IBodegaCompuesta>> => [
    {
        component: BotonRefrescar,
        icon: () => <RefreshIcon color="action" />,
        onClick: () => recargarTabla(),
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.refrescar' }),
    },
    {
        component: BotonAgregar,
        icon: () => <AddIcon />,
        onClick: () => alAbrirFormulario(),
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
    },
    {
        icon: () => <EditIcon color="action" />,
        onClick: (_, valor) => alAbrirFormulario(valor as IBodegaCompuesta),
        tooltip: formatMessage({ id: 'boton.editar' }),
    },
    {
        icon: () => <DeleteIcon color="action" />,
        onClick: (_, valor) => alEliminarBodega(valor as any),
        tooltip: formatMessage({ id: 'boton.eliminar' }),
    },
];

export const opcionesDivisionesBodega: Options<IBodegaCompuesta> = {
    maxBodyHeight: '50vh',
};

export const columnasDivisionesBodega = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IBodegaDivisiones>> => [
    { field: 'DivisionCodigoBarras', title: formatMessage({ id: 'divisionBodega.DivisionCodigoBarras' }) },
    { field: 'TipoDivisionDescripcion', title: formatMessage({ id: 'divisionBodega.TipoDivision' }) },
    { field: 'DivisionDescripcion', title: formatMessage({ id: 'divisionBodega.DivisionDescripcion' }) },
    { field: 'Estado', title: formatMessage({ id: 'divisionBodega.Estado' }), type: 'boolean' },
];

export const acionesTablaDivisionesPropiedades = (
    alAbrirFormulario: (divisionesProps?: IInputsDivisionesPropiedas) => void,
    alEliminarDivisionesPropiedades: (divisionesProps: IInputsDivisionesPropiedas) => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Action<IInputsDivisionesPropiedas>> => [
    {
        component: BotonAgregar,
        icon: () => <AddIcon />,
        onClick: () => alAbrirFormulario(),
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
    },
    {
        icon: () => <EditIcon color="action" />,
        onClick: (_, valor) => alAbrirFormulario(valor as IInputsDivisionesPropiedas),
        tooltip: formatMessage({ id: 'boton.editar' }),
    },
    {
        icon: () => <DeleteIcon color="action" />,
        onClick: (_, valor) => alEliminarDivisionesPropiedades(valor as IInputsDivisionesPropiedas),
        tooltip: formatMessage({ id: 'boton.eliminar' }),
    },
];

export const columnasPropiedadesDivisiones = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IBodegaPropiedades>> => [
    {
        field: 'DivisionTipoPropiedadDescripcion',
        title: formatMessage({ id: 'divisionBodega.Propiedades.BodegaTipoPropiedadDescripcion' }),
    },
    { field: 'DescripcionItem', title: formatMessage({ id: 'divisionBodega.Propiedades.DescripcionItem' }) },
    { field: 'FamiliaDescripcion', title: formatMessage({ id: 'divisionBodega.Propiedades.FamiliaDescripcion' }) },
    {
        field: 'DivisionPropiedadTexto',
        title: formatMessage({ id: 'divisionBodega.Propiedades.BodegaPropiedadTexto' }),
    },
    { field: 'DescripcionUnidad', title: formatMessage({ id: 'divisionBodega.Propiedades.DescripcionUnidad' }) },
    { field: 'Cantidad', title: formatMessage({ id: 'divisionBodega.Propiedades.Cantidad' }) },
    { field: 'Estado', title: formatMessage({ id: 'divisionBodega.Propiedades.Estado' }), type: 'boolean' },
];

export const opcionesPropiedadesDivisiones: Options<IInputsDivisionesPropiedas> = {
    maxBodyHeight: '30vh',
    showTitle: true,
};
