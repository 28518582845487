import ModeloTipoDocumento from 'Infotrack@Modelos/negocioRefactor/modelos/Administracion/ModeloTipoDocumento';
import ModeloBodega from 'Infotrack@Modelos/smartStock/bodegas/';
import ModeloDivision from 'Infotrack@Modelos/negocioRefactor/modelos/Bodegas/ModeloDivision';
import ModeloFamilia from 'Infotrack@Modelos/negocioRefactor/modelos/Productos/ModeloFamilia';
import ModeloItem from 'Infotrack@Modelos/negocioRefactor/modelos/Productos/ModeloItem';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';
import { IEntidadesAuditoria } from '../ModalAuditoria/ModalAuditoria';
import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';

class EntidadesAuditoriaCM {
    private modeloBodega: ModeloBodega;
    private modeloTipoDocumento: ModeloTipoDocumento;
    private modeloDivision: ModeloDivision;
    private modeloFamilia: ModeloFamilia;
    private modeloItem: ModeloItem;

    constructor() {
        this.modeloBodega = new ModeloBodega();
        this.modeloTipoDocumento = new ModeloTipoDocumento();
        this.modeloDivision = new ModeloDivision();
        this.modeloFamilia = new ModeloFamilia();
        this.modeloItem = new ModeloItem();
    }

    public async consultarEntidadesAuditoria(
        idEmpresa: string,
        idAgencia: string,
        idTipoProceso: number,
        idUsuario?: string
    ): Promise<IEntidadesAuditoria> {
        const respuestas = await Promise.all([
            this.modeloBodega.consultarListaBodegasFiltro({
                EmpresaId: idEmpresa,
                AgenciaId: idAgencia,
                IdUsuario: idUsuario,
                Estado: estadoActivo,
            }),
            this.modeloFamilia.consultarListaFamiliasPorFiltro({ EmpresaId: idEmpresa }),
            this.modeloItem.consultarListaItemPorFiltro({ EmpresaId: idEmpresa }),
        ]);
        return {
            bodegas: respuestas[0].data.Entidades,
            tiposDocumento: [],
            divisiones: [],
            familias: respuestas[1].data.Entidades,
            items: respuestas[2].data.Entidades,
        };
    }

    public async consultarDivisionesAuditoria(idBodega: number) {
        return this.modeloDivision
            .consultarListaDivisionPorFiltro({ BodegaId: idBodega })
            .then(({ data: { Entidades } }) => Entidades);
    }

    public async consultarTipoDocumentoPorBodega(idBodega: number) {
        return this.modeloTipoDocumento
            .consultarTiposDocumentoFiltro({ BodegaId: idBodega, TipoProcesoId: TiposProceso.AUDITORIA, Estado: 1 })
            .then(({ data: { Entidades } }) => Entidades);
    }
}

export default EntidadesAuditoriaCM;
