import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import {
    manejadorDialogoGlobal,
    manejadorIndicadorCargaGlobal,
} from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IDocumentoDetalle from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalle';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import IProductoPrincipal from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/productoPrincipal';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import DocumentoDetalleCM from '../controladorModelo/documentoDetalleCM';
import EntidadesDocumentoDetalleCM from '../controladorModelo/entidadesDocumentoDetalleCM';
import { IFormularioDocumentoDetalleCVProps, IInputsDocumentoDetalle } from '../interfaces';
import { acciones, IEstadoGestionMovimientos } from '../reductorGestionMovimientos';
import FormularioDocumentoDetalleVista from '../vista/formularioDocumentoDetalleVista';

const valorInicialFormulario: IInputsDocumentoDetalle = {
    CantidadSolicitada: '',
    DivisionDestino: '',
    DivisionOrigen: '',
    FechaVencimiento: null,
    ItemId: '',
    Lote: '',
    Serial: '',
    DocumentoDetalleId: null,
};

const entidadesDocumentoDetalleCM = new EntidadesDocumentoDetalleCM();
const documentoDetalleCM = new DocumentoDetalleCM();

const FormularioDocumentoDetalleCV: React.FunctionComponent<IFormularioDocumentoDetalleCVProps> = ({ IdEmpresa }) => {
    const { setValor, reiniciar, valor } = useInputState(valorInicialFormulario);
    const [divisionesDestino, setDivisionesDestino] = React.useState<IBodegaDivisiones[]>([]);
    const [divisionesOrigen, setDivisionesOrigen] = React.useState<IBodegaDivisiones[]>([]);
    const [item, setItem] = React.useState<IProductoPrincipal>();

    const [
        {
            documento,
            estadoFormularioDetalle,
            entidadDocumentoDetalle,
            tipoFormularioDocumentoDetalle,
            tipoMovimiento,
            entidadDocumento,
        },
        dispatch,
    ] = useProveedor<IEstadoGestionMovimientos>();

    const theme = useTheme();
    const esMovil = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        if (documento.BodegaOrigen)
            cargarDivisiones(documento.BodegaOrigen ? Number(documento.BodegaOrigen.BodegaId) : 0, true);
    }, [documento.BodegaOrigen]);

    React.useEffect(() => {
        if (documento.BodegaDestino)
            cargarDivisiones(documento.BodegaDestino ? Number(documento.BodegaDestino.BodegaId) : 0, false);
    }, [documento.BodegaDestino]);

    React.useEffect(() => {
        if (entidadDocumentoDetalle) {
            const {
                CodigoBarras,
                DescripcionItem,
                DivisionDestino,
                DivisionOrigen,
                FechaVencimiento,
                ItemId,
            } = entidadDocumentoDetalle;
            setValor({
                ...entidadDocumentoDetalle,
                FechaVencimiento: FechaVencimiento ? FechaVencimiento : null,
                DivisionDestino: DivisionDestino ? DivisionDestino : '',
                DivisionOrigen: DivisionOrigen ? DivisionOrigen : '',
            } as any);
            setItem({ DescripcionItem, CodigoBarras, ItemId: Number(ItemId) } as any);
        }
    }, [entidadDocumentoDetalle]);

    const alCambiarValor = ({
        target,
    }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { checked, name, type, value } = target as EventTarget & HTMLInputElement;
        setValor({ ...valor, [name]: type === 'checkbox' ? checked : value });
    };

    const alSeleccionarItem = (itemSeleccionado?: IProductoPrincipal) => {
        setItem(itemSeleccionado);
    };

    const alCerrarReiniciarFormulario = (cerrar?: boolean) => {
        if (cerrar || tipoFormularioDocumentoDetalle === TipoFormulario.Edicion)
            dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
        reiniciar();
        setItem(undefined);
    };

    const cargarDivisiones = async (BodegaId: number, bodegaOrigen: boolean) => {
        const divisiones = await entidadesDocumentoDetalleCM.consultarListaDivisiones(BodegaId);
        if (bodegaOrigen) setDivisionesOrigen(divisiones);
        else setDivisionesDestino(divisiones);
    };

    const alGuardarDocumentoDetalle = () => {
        if (
            documento.EtapaDescripcion === TiposEtapas.Anulado ||
            documento.EtapaDescripcion === TiposEtapas.Revertido ||
            documento.EtapaDescripcion === TiposEtapas.EnDevolucion
        ) {
            notificacionGlobal('devolucion.alerta.agregarDetallesAnulado', 6000, 'warning', true);
        } else if (documento.EtapaDescripcion === TiposEtapas.Cerrada) {
            notificacionGlobal('devolucion.alerta.agregarDetallesCerrado', 6000, 'warning', true);
        } else {
            const nuevoDocumentoDetalle = mapearDetalle();
            if (documentoDetalleCM.validarDocumentoDetalle(nuevoDocumentoDetalle, tipoMovimiento!)) {
                if (tipoFormularioDocumentoDetalle === TipoFormulario.Creacion) {
                    if (entidadDocumento && entidadDocumento.DocumentoId) {
                        documentoDetalleCM.guardarDocumentoDetalleDevolucionProveedor(mapearDetalleGuardar());
                        dispatch({ type: acciones.AGREGAR_DOCUMENTO_DETALLE, payload: nuevoDocumentoDetalle });
                    } else {
                        dispatch({ type: acciones.AGREGAR_DOCUMENTO_DETALLE, payload: nuevoDocumentoDetalle });
                    }
                } else {
                    if (entidadDocumento && entidadDocumento.DocumentoId) {
                        documentoDetalleCM.editarDocumentoDetalleDevolucionProveedor(mapearDetalleGuardar());
                        dispatch({ type: acciones.EDITAR_DOCUMENTO_DETALLE, payload: nuevoDocumentoDetalle });
                    } else {
                        dispatch({ type: acciones.EDITAR_DOCUMENTO_DETALLE, payload: nuevoDocumentoDetalle });
                    }
                }
                alCerrarReiniciarFormulario();
            }
        }
    };

    const mapearDetalle = (): IDocumentoDetalleCompuesto => {
        const { CantidadSolicitada, DivisionOrigen, FechaVencimiento, DocumentoDetalleId } = valor;
        const DescripcionDivisionOrigen = DivisionOrigen
            ? divisionesOrigen.find(({ DivisionId }) => DivisionId === Number(DivisionOrigen))!.DivisionDescripcion
            : '';
        return {
            ...valor,
            DocumentoDetalleId,
            CodigoBarras: item ? item.CodigoBarras : '',
            CantidadSolicitada: Number(CantidadSolicitada),
            DescripcionItem: item ? item.DescripcionItem : '',
            DescripcionDivisionOrigen,
            DescripcionDivisionDestino: DescripcionDivisionOrigen,
            EmpresaId: IdEmpresa,
            ItemId: item ? item.ItemId : '',
            DivisionOrigen: DivisionOrigen ? Number(DivisionOrigen) : undefined,
            DivisionDestino: DivisionOrigen ? Number(DivisionOrigen) : undefined,
            FechaVencimiento: FechaVencimiento ? FechaVencimiento : '',
            SeriadoItems: item && item.Seriado,
            LotesItems: item && item.Lotes,
            FEFO: item && item.FEFO,
        } as any;
    };

    const mapearDetalleGuardar = (): IDocumentoDetalle => {
        const { CantidadSolicitada, DivisionOrigen, FechaVencimiento, Lote, Serial } = valor;
        return {
            ...entidadDocumentoDetalle,
            CantidadSolicitada: Number(CantidadSolicitada),
            CantidadEntregada: Number(CantidadSolicitada),
            DocumentoId: entidadDocumento ? entidadDocumento.DocumentoId : undefined,
            EmpresaId: IdEmpresa,
            ItemId: item ? item.ItemId : '',
            DivisionOrigen: DivisionOrigen ? Number(DivisionOrigen) : undefined,
            FechaVencimiento: FechaVencimiento ? FechaVencimiento : '',
            Lote: Lote ? Lote : '',
            Serial: Serial ? Serial : '',
        } as any;
    };

    return (
        <FormularioDocumentoDetalleVista
            alCerrarReiniciarFormulario={alCerrarReiniciarFormulario}
            alCambiarValor={alCambiarValor}
            alGuardarDocumentoDetalle={alGuardarDocumentoDetalle}
            alSeleccionarItem={alSeleccionarItem}
            tipoFormularioDocumentoDetalle={tipoFormularioDocumentoDetalle}
            esMovil={esMovil}
            estadoFormulario={Boolean(estadoFormularioDetalle)}
            documentoDetalle={valor}
            tipoMovimiento={tipoMovimiento}
            divisiones={{ origen: divisionesOrigen, destino: divisionesDestino }}
            item={item}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion },
}: IEstadoGlobal): IFormularioDocumentoDetalleCVProps => ({
    IdEmpresa: usuarioInformacion!.IdEmpresa,
});

export default connect<IFormularioDocumentoDetalleCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(FormularioDocumentoDetalleCV);
