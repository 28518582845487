import ProveedorEstado from '@infotrack/presentacion-componentes/proveedorEstado';
import React from 'react';
import FormularioGestionProductoCV from './controladorVista/formularioGestionProductoCV';
import TablaGestionProductoCV from './controladorVista/tablaGestionProductoCV';
import { estadoInicial, reductor } from './reductorGestionProducto';

const GestionProducto: React.FunctionComponent = () => (
    <ProveedorEstado estadoInicial={estadoInicial} reductor={reductor}>
        <TablaGestionProductoCV />
        <FormularioGestionProductoCV />
    </ProveedorEstado>
);

export default GestionProducto;
