import { TextField } from '@material-ui/core';
import Texto from '@infotrack/presentacion-componentes/texto';
import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';
import React, { FunctionComponent } from 'react';

interface ICampoDescipcionConteoProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampo: keyof IAuditoriaConteo,
        nuevoValor: IAuditoriaConteo[keyof IAuditoriaConteo]
    ) => any;
    valor: string;
}

const CampoDescripcionConteo: FunctionComponent<ICampoDescipcionConteoProps> = ({
    deshabilitado,
    manejarCambioCampo,
    valor,
}) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="AuditoriaConteo.CampoDescripcion.Nombre" />}
        onChange={(event) => manejarCambioCampo('DescripcionConteo', event.target.value)}
        required
        value={valor}
    />
);

export default CampoDescripcionConteo;
