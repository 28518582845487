//import DatosPersonales from '../administracion/datosPersonales/DatosPersonales';
import DatosPersonales from '@infotrack/presentacion-modulosbase/modulos/DatosPersonales/DatosPersonales';
import { seguridadToken } from 'Infotrack@Modelos/conexiones';
import React from 'react';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const DatosPersonal: React.FunctionComponent = () => {
    const history = useHistory();
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);
    const identificacionPersona = useSelector(
        (e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.Identificacion
    );
    const nombreAgencia = useSelector(
        (e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.NombreAgencia)

    const cerrar = () => {
        history.push('/app');
    };

    return (
        <DatosPersonales
            seguridad={seguridadToken}
            identificacionPersona={identificacionPersona}
            idUsuario={idUsuario}
            cerrar={cerrar}
            nombreAgencia = {nombreAgencia}
        />
    );
};

export default DatosPersonal;
