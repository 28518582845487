import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloDocumentoDetalle from 'Infotrack@Modelos/smartStock/documentosDetalle';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import IFiltrosDocumentoDetalle from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/filtrosDocumentoDetalle';

export default class DocumentoDetalleCM {
    private modeloDocumentoDetalle: ModeloDocumentoDetalle;

    constructor() {
        this.modeloDocumentoDetalle = new ModeloDocumentoDetalle();
    }

    public async consultaListaDocumentoDetallesPorFiltro(filtroDocumentoDetalle: IFiltrosDocumentoDetalle) {
        const {
            data: { Entidades },
        } = await this.modeloDocumentoDetalle.consultaListaDocumentoDetallesPorFiltro(filtroDocumentoDetalle);
        return Entidades;
    }

    public validarDocumentoDetalle(documentoDetalle: IDocumentoDetalleCompuesto) {
        const { CantidadSolicitada, CantidadEntregada, CantidadRecibida, ItemId } = documentoDetalle;
        if (!ItemId || (!CantidadSolicitada && !CantidadEntregada)) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else if (
            (CantidadRecibida && CantidadEntregada! > Number(CantidadRecibida)) ||
            (CantidadSolicitada && CantidadEntregada! > Number(CantidadSolicitada))
        ) {
            notificacionGlobal('despachos.alerta.cantidadEntregadaInvalida', 6000, 'warning', true);
            return false;
        } else return true;
    }
}
