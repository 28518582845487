import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import IDocumentoCompuesto from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuesto';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import EstadisticasDashboardCM from '../controladorModelo/estadisticasDashboardCM';
import { ITablaPedidosEstadosCVProps } from '../interfaces';
import { acciones, IEstadoMonitorPedidos } from '../reductorMonitorPedidos';
import TablaPedidosEstadosVista from '../vista/tablaPedidosEstadosVista';

const estadisticasDashboardCM = new EstadisticasDashboardCM();

const TablaPedidosEstadosCV: React.FunctionComponent<ITablaPedidosEstadosCVProps> = ({ EmpresaId }) => {
    const [{ filtrosEstadisticas, formularioEstadoPedidos }, dispatch] = useProveedor<IEstadoMonitorPedidos>();
    const [documentos, setDocumentos] = React.useState<IDocumentoCompuesto[]>([]);

    React.useEffect(() => {
        if (formularioEstadoPedidos) consultarDocumentos();
    }, [formularioEstadoPedidos]);

    const consultarDocumentos = async () => {
        setDocumentos(
            await estadisticasDashboardCM.consultarListaDocumentoEstadoPedidos({
                EtapaId: filtrosEstadisticas.etapaId,
                BodegaOrigen: filtrosEstadisticas.bodegaId,
                ClienteId: filtrosEstadisticas.clienteId,
                EmpresaId,
            } as any)
        );
    };

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_FORMULARIO_ESTADO_PEDIDOS });
        setDocumentos([]);
    };

    return (
        <TablaPedidosEstadosVista
            alCerrarFormulario={alCerrarFormulario}
            documentos={documentos}
            formularioEstadoPedidos={formularioEstadoPedidos}
        />
    );
};

const estadoAPropiedades = ({ estadoAutenticacion: { usuarioInformacion } }: IEstadoGlobal) => ({
    EmpresaId: usuarioInformacion!.IdEmpresa,
});

export default connect<ITablaPedidosEstadosCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(TablaPedidosEstadosCV);
