const PARAMETROS_IMPRESION: Array<string> = [
    '${CODIGOBARRAS}',
    '${DESCRIPCIONITEM}',
    '${DESCRIPCIONFAMILIA}',
    '${DECRIPCIONUNIDAD}',
    '${DESCRIPCIONCLASIFICACION}',
    '${CANTIDAD}',
];

export { PARAMETROS_IMPRESION };
