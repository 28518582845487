import React from 'react';
import { Card, CardContent, Grid, MenuItem, TextField } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import Texto from '@infotrack/presentacion-componentes/texto';

import { BotonBuscar, BotonLimpiar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import { IFormularioConsultaInventarioVistaProps } from '../interfaces';
import { Autocomplete } from '@material-ui/lab';

const FormularioConsultaInventarioVista: React.FunctionComponent<IFormularioConsultaInventarioVistaProps> = ({
    alAbrirDialogo,
    alLimpiarFiltros,
    entidadesFiltro,
    filtro,
    alCambiarValor,
    alCambiarValorAutocomplete,
    bodegaSelected
}) => (
    <Grid alignItems="flex-start" container spacing={2} justifyContent="center">
        <Grid item xs={12}>
            <Grid alignItems="center" container justifyContent="flex-end" spacing={2}>
                <Grid item md="auto" xs={6}>
                    <BotonLimpiar onClick={alLimpiarFiltros} />
                </Grid>
                <Grid item md="auto" xs={6}>
                    <BotonBuscar onClick={alAbrirDialogo} />
                </Grid>
            </Grid>
        </Grid>
        <Grid item md={7} xs={12}>
            <Grid alignItems="center" container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid alignItems="center" container spacing={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <Texto id="inventario.producto" fontWeight="bold" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="inventario.codigoBarrasProducto" />}
                                        name="CodigoBarras"
                                        onChange={alCambiarValor}
                                        value={filtro.CodigoBarras}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="inventario.productoDescripcion" />}
                                        name="DescripcionItem"
                                        onChange={alCambiarValor}
                                        value={filtro.DescripcionItem}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="inventario.productoSerial" />}
                                        name="Serial"
                                        onChange={alCambiarValor}
                                        value={filtro.Serial}
                                        variant="outlined"
                                        inputProps={{ style: { textTransform: 'uppercase' } }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="inventario.productoLote" />}
                                        name="Lote"
                                        onChange={alCambiarValor}
                                        value={filtro.Lote}
                                        variant="outlined"
                                        inputProps={{ style: { textTransform: 'uppercase' } }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="inventario.familiaIdProducto" />}
                                        name="FamiliaId"
                                        onChange={alCambiarValor}
                                        select
                                        value={filtro.FamiliaId}
                                        variant="outlined"
                                    >
                                        {entidadesFiltro.familias.map(({ FamiliaDescripcion, FamiliaId }) => (
                                            <MenuItem key={FamiliaId} value={FamiliaId}>
                                                {FamiliaDescripcion}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="inventario.clasificacionIdProducto" />}
                                        name="ClasificacionId"
                                        onChange={alCambiarValor}
                                        select
                                        value={filtro.ClasificacionId}
                                        variant="outlined"
                                    >
                                        {entidadesFiltro.clasificaciones.map(
                                            ({ ClasificacionDescripcion, ClasificacionId }) => (
                                                <MenuItem key={ClasificacionId} value={ClasificacionId}>
                                                    {ClasificacionDescripcion}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid alignItems="center" container spacing={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <Texto id="inventario.fecha" fontWeight="bold" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <KeyboardDatePicker
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        disableFuture
                                        autoOk
                                        variant="inline"
                                        label={<Texto id="inventario.FechaInicialMovimiento" />}
                                        name="FechaInicial"
                                        value={filtro.FechaInicial}
                                        onChange={(fecha: any) => {
                                            // tslint:disable-next-line:no-object-literal-type-assertion
                                            alCambiarValor({
                                                target: { name: 'FechaInicial', value: fecha.format('YYYY-MM-DD') },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        inputVariant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <KeyboardDatePicker
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        disableFuture
                                        autoOk
                                        variant="inline"
                                        label={<Texto id="inventario.FechaFinalMovimiento" />}
                                        name="FechaFinal"
                                        value={filtro.FechaFinal}
                                        onChange={(fecha: any) => {
                                            // tslint:disable-next-line:no-object-literal-type-assertion
                                            alCambiarValor({
                                                target: { name: 'FechaFinal', value: fecha.format('YYYY-MM-DD') },
                                            } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        inputVariant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
        <Grid item md={5} xs={12}>
            <Grid alignItems="center" container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid alignItems="center" container spacing={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <Texto id="inventario.ubicacion" fontWeight="bold" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="inventario.BodegaCodigo" />}
                                        name="BodegaDecripcion"
                                        onChange={alCambiarValor}
                                        value={filtro.BodegaDecripcion}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        getOptionLabel={({ BodegaDescripcion }: any) => BodegaDescripcion}
                                        onChange={(_: any, bodega: any) => alCambiarValorAutocomplete(bodega, 'BodegaId')}
                                        options={entidadesFiltro.bodegas}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                required
                                                label={<Texto display="inline" id="inventario.BodegaId" />}
                                            />
                                        )}
                                        noOptionsText={<Texto id="label.sinRegistros" />}
                                        value={bodegaSelected}
                                    /> 
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="inventario.DivisionId" />}
                                        name="DivisionId"
                                        onChange={alCambiarValor}
                                        select
                                        value={filtro.DivisionId}
                                        variant="outlined"
                                    >
                                        {entidadesFiltro.divisiones.length === 0 ? (
                                            <MenuItem value="">
                                                <Texto id="label.sinRegistros" />
                                            </MenuItem>
                                        ) : (
                                            entidadesFiltro.divisiones.map(({ DivisionDescripcion, DivisionId }) => (
                                                <MenuItem key={DivisionId} value={DivisionId}>
                                                    {DivisionDescripcion}
                                                </MenuItem>
                                            ))
                                        )}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

export default FormularioConsultaInventarioVista;
