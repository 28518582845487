import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';

interface ICampoFechaVencimientoProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampo: keyof IDocumentoDetalle,
        nuevoValor: IDocumentoDetalle[keyof IDocumentoDetalle]
    ) => any;
    valor: string | undefined;
}

const CampoFechaVencimiento: FunctionComponent<ICampoFechaVencimientoProps> = ({
    deshabilitado,
    manejarCambioCampo,
    valor,
}) => (
    <KeyboardDatePicker
        disabled={deshabilitado}
        format="DD/MM/YYYY"
        fullWidth
        label={<Texto id="DetallesFormulario.CampoFechaVencimiento.Nombre" />}
        onChange={(date) => {
            if (date) manejarCambioCampo('FechaVencimiento', date.format('YYYY-MM-DD'));
        }}
        value={valor ? moment(valor, 'YYYY-MM-DD') : null}
        required
    />
);

export default CampoFechaVencimiento;
