import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloAlmacenamiento from 'Infotrack@Modelos/smartStock/almacenamiento';
import ModeloDocumentoDetalles from 'Infotrack@Modelos/smartStock/documentosDetalle';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';

export default class DocumentoDetallesCM {
    private modeloDocumentoDetalles: ModeloDocumentoDetalles;
    private modeloAlmacenamiento: ModeloAlmacenamiento;

    constructor() {
        this.modeloDocumentoDetalles = new ModeloDocumentoDetalles();
        this.modeloAlmacenamiento = new ModeloAlmacenamiento();
    }

    public consultarDocumentosConDetalleCompuestos(DocumentoId: string) {
        return this.modeloDocumentoDetalles
            .consultarDocumentosConDetalleCompuestos(DocumentoId)
            .then(({ data: { Entidades } }) => Entidades[0]);
    }

    public async editarDocumentosDetallesParcial(documentoDetalle: IDocumentoDetalleCompuesto) {
        return this.modeloDocumentoDetalles.editarDocumentosDetallesParcial(documentoDetalle);
    }

    public async editarDocumentosDetallesTotal(documentoDetalle: IDocumentoDetalleCompuesto) {
        return this.modeloDocumentoDetalles.editarDocumentosDetallesTotal(documentoDetalle);
    }

    public async EditarAlmacenamientoDetallesParcial(documentoDetalle: IDocumentoDetalleCompuesto) {
        return this.modeloAlmacenamiento.EditarAlmacenamientoDetallesParcial(documentoDetalle);
    }

    public async EditarAlmacenamientoDetallesTotal(documentoDetalle: IDocumentoDetalleCompuesto) {
        return this.modeloAlmacenamiento.EditarAlmacenamientoDetallesTotal(documentoDetalle);
    }

    public async guardarDocumentosDetalles(documentoDetalle: IDocumentoDetalleCompuesto) {
        return this.modeloDocumentoDetalles.guardarDocumentosDetalles(documentoDetalle);
    }

    public consultarDocumentoCompuestoDetalles(documentoDetalleCompuesto: Partial<IDocumentoDetalleCompuesto>) {
        return this.modeloDocumentoDetalles
            .ConsultarDocumentoCompuestoDetalles(documentoDetalleCompuesto)
            .then(({ data: { Entidades } }) => Entidades);
    }
    public validarDocumentoDetalle(documentoDetalle: IDocumentoDetalleCompuesto) {
        const {
            CantidadRecibida,
            DivisionDestino,
            FechaVencimiento,
            FEFO,
            Lote,
            LotesItems,
            SeriadoItems,
            Serial,
        } = documentoDetalle;
        if (
            DivisionDestino === undefined ||
            !CantidadRecibida ||
            (FEFO && FechaVencimiento === '') ||
            (LotesItems && Lote === '') ||
            (SeriadoItems && Serial === '')
        ) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else return true;
    }
}
