import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloDocumento from 'Infotrack@Modelos/smartStock/documentos';
import IFiltroGeneralDocumentoConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/filtroGeneralDocumentoConsulta';
import IDocumentoFiltroPadre from 'Infotrack@Modelos/smartStock/documentos/entidades/filtrosDocumentoPadre';
import IDocumentoConDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoConDetalleCompuesto';

export default class DocumentoCM {
    private modeloDocumento: ModeloDocumento;

    constructor() {
        this.modeloDocumento = new ModeloDocumento();
    }

    public consultaListaDocumentosPorFiltro(filtroDocumento: IDocumentoFiltroPadre) {
        return this.modeloDocumento
            .consultaListaDocumentosCompuestaPadrePorFiltro(filtroDocumento)
            .then(({ data: { Entidades } }) => Entidades[0]);
    }

    public crearDocumentosDetallesAlmacenamiento(documentoDetalle: IDocumentoConDetalleCompuesto) {
        return this.modeloDocumento
            .crearDocumentosDetallesRecepcion(documentoDetalle)
            .then(({ data: { Entidades } }) => Entidades[0]);
    }

    public async consultaGeneralDocumentosFiltro(filtroDocumento: IFiltroGeneralDocumentoConsulta) {
        const {
            data: { Entidades },
        } = await this.modeloDocumento.consultaGeneralDocumentosFiltro(filtroDocumento);
        return Entidades[0];
    }

    public validarFiltroConsulta = ({
        BodegaDestino,
        CodigoDocumento,
        ProveedorId,
    }: IDocumentoFiltroPadre): boolean => {
        if (BodegaDestino === 0) {
            notificacionGlobal('almacenamiento.documentos.alertaFiltroBodega', 6000, 'warning', true);
            return false;
        } else if (CodigoDocumento === '' && ProveedorId === null) {
            notificacionGlobal('almacenamiento.documentos.alertaFiltroProveedorDocumento', 6000, 'warning', true);
            return false;
        } else return true;
        return true;
    };
}
