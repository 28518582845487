import Texto from '@infotrack/presentacion-componentes/texto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IPlantillaImpresion from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IPlantillaImpresion';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PlantillaImpresionCM from './ControladorModelo/PlantillaImpresionCM';
import { EstadosModalPlantillaImpresion } from './enumeraciones';
import ModalPlantillaImpresion from './ModalPlantillaImpresion/ModalPlantillaImpresion';
import PlantillaImpresionTabla from './PlantillaImpresionTabla/PlantillaImpresionTabla';

const PlantillaImpresion = () => {
    const plantillaImpresionCM = useMemo(() => new PlantillaImpresionCM(), []);

    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);

    const [plantillasImpresion, setPlantillasImpresion] = useState<IPlantillaImpresion[]>([]);
    const [plantillaImpresionModal, setPlantillaImpresionModal] = useState<IPlantillaImpresion | null>(null);
    const [estadoModal, setEstadoModal] = useState<EstadosModalPlantillaImpresion>(
        EstadosModalPlantillaImpresion.CERRADO
    );

    useEffect(() => {
        consultarPlantillasImpresion();
    }, []);

    useEffect(() => {
        alCambiarEstadoModal();
    }, [estadoModal]);

    const alCambiarEstadoModal = async () => {
        if (estadoModal === EstadosModalPlantillaImpresion.CERRADO) {
            consultarPlantillasImpresion();
        }
        if (estadoModal === EstadosModalPlantillaImpresion.CREACION) {
            setPlantillaImpresionModal(null);
        }
    };

    const abrirModalCreacion = () => {
        setEstadoModal(EstadosModalPlantillaImpresion.CREACION);
    };

    const abrirModalEdicion = (plantillaImpresionEditar: IPlantillaImpresion) => {
        setPlantillaImpresionModal(plantillaImpresionEditar);
        setEstadoModal(EstadosModalPlantillaImpresion.EDICION);
    };

    const consultarPlantillasImpresion = async () => {
        const respuesta = await plantillaImpresionCM.consultarListaPlantillaImpresionMovil(idEmpresa);
        setPlantillasImpresion(respuesta);
    };

    const abrirModalVisualizacion = (plantillaImpresionVer: IPlantillaImpresion) => {
        setPlantillaImpresionModal(plantillaImpresionVer);
        setEstadoModal(EstadosModalPlantillaImpresion.VISUALIZACION);
    };

    const cerrarModal = () => {
        setEstadoModal(EstadosModalPlantillaImpresion.CERRADO);
        manejadorDialogoGlobal({ estado: false });
    };

    const alCerrarFormulario = () => {
        if (estadoModal == EstadosModalPlantillaImpresion.VISUALIZACION) cerrarModal();
        else {
            manejadorDialogoGlobal({
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                accionConfirmar: () => {
                    cerrarModal();
                },
                estado: true,
                mensaje: <Texto id={'ModalPlantillaImpresion.Cancelar'} />,
                mostrarCancelar: true,
                tipoDialogo: 'Advertencia',
                titulo: <Texto id="titulo.advertencia" />,
            });
        }
    };

    const crearPlantillaImpresion = async (plantillaImpresion: IPlantillaImpresion) => {
        const respuesta = await plantillaImpresionCM.crearPlantillaImpresion({
            ...plantillaImpresion,
            TipoPlantillaId: 2,
            EmpresaId: idEmpresa,
        });
        if (respuesta.Resultado) {
            setPlantillaImpresionModal(respuesta.Entidades[0]);
            setEstadoModal(EstadosModalPlantillaImpresion.EDICION);
        }
    };

    const editarPlantillaImpresion = async (plantillaImpresion: IPlantillaImpresion) => {
        await plantillaImpresionCM.editarPlantillaImpresion(plantillaImpresion);
    };

    const confirmarEliminarPlantillaImpresion = async (plantillaImpresionId: number) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => eliminarPlantillaImpresion(plantillaImpresionId),
            estado: true,
            mensaje: <Texto id={'alerta.confirmacionEliminacion'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const eliminarPlantillaImpresion = async (plantillaImpresionId: number) => {
        manejadorDialogoGlobal({ estado: false });
        const eliminacionExitosa = await plantillaImpresionCM.eliminarPlantillaImpresion({
            PlantillaImpresionId: plantillaImpresionId,
        });
        if (eliminacionExitosa) await consultarPlantillasImpresion();
    };

    return (
        <>
            <PlantillaImpresionTabla
                crearPlantillaImpresion={abrirModalCreacion}
                editarPlantillaImpresion={(plantillaImpresionEditar: IPlantillaImpresion) =>
                    abrirModalEdicion(plantillaImpresionEditar)
                }
                eliminarPlantillaImpresion={confirmarEliminarPlantillaImpresion}
                recargarTabla={consultarPlantillasImpresion}
                plantillasImpresion={plantillasImpresion}
                verPlantillaImpresion={(plantillaImpresionVer: IPlantillaImpresion) =>
                    abrirModalVisualizacion(plantillaImpresionVer)
                }
            />
            <ModalPlantillaImpresion
                cerrar={alCerrarFormulario}
                crearPlantillaImpresion={crearPlantillaImpresion}
                editarPlantillaImpresion={editarPlantillaImpresion}
                estado={estadoModal}
                plantillaImpresionInicial={plantillaImpresionModal}
            />
        </>
    );
};

export default PlantillaImpresion;
