export default {
  'sidebar.reversion': 'Reversión movimientos',
  'sidebar.reversionmovimientos': 'Reversión movimientos',
  'permiso.reversion': 'Acceso',
  'reversion.numeroDocumento': 'N° Documento',
  'reversion.revertirDocumento': 'Revertir',
  'reversion.CodigoDocumento': 'Código documento',
  'reversion.DescripcionBodegaOrigen': 'Bodega origen',
  'reversion.DescripcionBodegaDestino': 'Bodega destino',
  'reversion.Bodega': 'Bodega',
  'reversion.TipoDocumentoDescripcion': 'Tipo documento',
  'reversion.EtapaDescripcion': 'Etapa descripción',
  'reversion.FechaCreacion': 'Fecha creación',
  'reversion.alerta.confirmarReversion': '¿Está seguro que desea revertir el documento?',
};
