export default {
  'sidebar.administracion': 'Administration',
  'sidebar.tipoidentificacion': 'Type Identification',
  'tipoIdentificacion.agregarTipoIdentificacion': 'Create Type Identification',
  'tipoIdentificacion.confirmacionCreacion': 'Are you sure to create the registry?',
  'tipoIdentificacion.confirmacionEdicion': 'You are sure you want to edit the registry?',
  'tipoIdentificacion.confirmacionEliminacion': 'Are you sure you want to delete the registry?',
  'tipoIdentificacion.modificarTipoIdentificacion': 'Edit Type Identification',
  'tipoIdentificacion.eliminarTipoIdentificacion': 'Delete Type Identification',
  'tipoIdentificacion.abreviatura': 'Abbreviation',
  'tipoIdentificacion.nombreTipoIdentificacion': 'Name Type Identification',
  'tipoIdentificacion.estadoTipoIdentificacion': 'State',
  'tipoIdentificacion.tipoIdentificacion': 'Type Identification',
};
