import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';
import ITipoConteoAuditoria from 'Infotrack@Modelos/smartStock/tipoConteoAuditoria/entidades/ITipoConteoAuditoria';
import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';

interface ICampoTipoConteoDescripcionProps {
    deshabilitado?: boolean;
    tiposConteo: ITipoConteoAuditoria[];
    manejarCambio: (nombreCampo: keyof IAuditoriaConteo, valor: IAuditoriaConteo[keyof IAuditoriaConteo]) => void;
    valor: number | undefined;
}

const CampoTipoConteoDescripcion: FunctionComponent<ICampoTipoConteoDescripcionProps> = ({
    deshabilitado,
    tiposConteo,
    manejarCambio,
    valor,
}) => {
    return (
        <Autocomplete
            disabled={deshabilitado}
            onChange={(_: ChangeEvent<{}>, value: ITipoConteoAuditoria | null) =>
                manejarCambio('IdTipoConteo', value ? value.IdTipoConteo : undefined)
            }
            options={tiposConteo}
            getOptionLabel={(i) => i.DescripcionTipoConteo}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    label={<Texto id="tipoConteoAuditoria.CampoTipoConteoDescripcion.Nombre" />}
                    required
                />
            )}
            value={tiposConteo ? tiposConteo.find((d) => d.IdTipoConteo === valor) || null : null}
        />
    );
};

export default CampoTipoConteoDescripcion;
