import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import IBodegas from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IDocumentoConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoConsulta';
import IFiltroGeneralDocumentoConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/filtroGeneralDocumentoConsulta';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import DocumentoCM from '../controladorModelo/documentoCM';
import EntidadesDocumentoCM from '../controladorModelo/entidadesDocumentoCM';
import { acciones, IEstadoAPropiedadesTabla, IEstadoDevoluciones, IInputsFiltros } from '../interfaces';
import TablaDevolucionesVista from '../vista/tablaDevolucionesVista';
import { useSelector } from 'react-redux';
const documentoCM = new DocumentoCM();
const entidadesDocumentoCM = new EntidadesDocumentoCM();
const filtros: IInputsFiltros = {
    BodegaOrigen: null,
    CodigoDocumento: '',
};

const TablaDevolucionesCV: React.FunctionComponent<IEstadoAPropiedadesTabla> = ({ AgenciaId, UsuarioId }) => {
    const idEmpresa = useSelector((estado: IEstadoGlobal) => estado.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const [bodegas, setBodegas] = React.useState<IBodegas[]>([]);
    const refTabla = React.createRef<any>();
    const { alCambiarValor, reiniciar, valor } = useInputState(filtros);
    const [{ recargarTabla }, dispatch] = useProveedor<IEstadoDevoluciones>();

    React.useEffect(() => {
        cargarEntidadesDocumento();
    }, []);

    React.useEffect(() => {
        if (recargarTabla && valor.BodegaOrigen) {
            consultarDocumentos();
            dispatch({ type: acciones.RECARGAR_TABLA });
        }
    }, [recargarTabla]);

    const alAbrirFormulario = (documento?: IDocumentoConsulta) => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
        dispatch({ type: acciones.ESTABLECER_ENTIDAD_DOCUMENTO, payload: documento });
    };

    const cargarEntidadesDocumento = async () => {
        setBodegas(
            await entidadesDocumentoCM.consultarEntidadesDocumento({ AgenciaId, UsuarioId, EmpresaId: idEmpresa })
        );
    };

    const consultarDocumentos = (limpiarFiltros?: boolean) => {
        if (limpiarFiltros) {
            reiniciar();
            refTabla.current.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
        } else {
            if (
                documentoCM.validarFiltros({
                    ...valor,
                    BodegaConsulta: valor.BodegaOrigen ? valor.BodegaOrigen.BodegaId : undefined,
                })
            ) {
                refTabla.current.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
            }
        }
    };

    const mapearFiltros = (): IFiltroGeneralDocumentoConsulta => ({
        BodegaOrigen: valor.BodegaOrigen ? valor.BodegaOrigen.BodegaId : undefined,
        CodigoDocumento: valor.CodigoDocumento,
    });

    const consultaDocumentos = paginacionRemota(
        documentoCM.consultaGeneralDocumentosFiltroPaginado,
        mapearFiltros(),
        'FechaCreacion',
        !Boolean(valor.BodegaOrigen)
    );

    return (
        <TablaDevolucionesVista
            alAbrirFormulario={alAbrirFormulario}
            alCambiarValor={alCambiarValor}
            consultarDocumentos={consultarDocumentos}
            entidadesFiltros={{ bodegas }}
            consultaDocumentos={consultaDocumentos}
            refTabla={refTabla}
            filtros={valor}
        />
    );
};

const estadoAPropiedades = ({ estadoAutenticacion }: IEstadoGlobal): IEstadoAPropiedadesTabla => ({
    AgenciaId: estadoAutenticacion.agenciaActual!.IdAgencia,
    UsuarioId: estadoAutenticacion.usuarioInformacion!.IdUsuario,
});

export default connect<IEstadoAPropiedadesTabla, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(TablaDevolucionesCV);
