import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, compose, Dispatch } from 'redux';

import { IMenu } from '@infotrack/presentacion-modulosbase';

import { seleccionarMenu, seleccionarSubMenu } from 'Infotrack@Acciones/accionesConfiguracionGlobal';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';

import MenuVista from '../vista/menuVista';

interface IEstadoAPropsMenuCV {
    menus: IMenu[];
    menuSeleccionado: number;
    subMenuSeleccionado: string;
}

interface IAccionesAPropsMenuCV {
    seleccionarMenuAccion: (payload: number) => AnyAction;
    seleccionarSubMenuAccion: (payload: string) => AnyAction;
}

const MenuCV: React.FunctionComponent<IEstadoAPropsMenuCV & IAccionesAPropsMenuCV & RouteComponentProps> = ({
    history,
    menus,
    menuSeleccionado,
    seleccionarMenuAccion,
    seleccionarSubMenuAccion,
    subMenuSeleccionado,
}) => {
    const accionSeleccionarMenu = (menu: number) => {
        if (menuSeleccionado === menu) seleccionarMenuAccion(0);
        else seleccionarMenuAccion(menu);
    };

    const accionSeleccionarSubMenu = (rutaSubmenu: string) => {
        seleccionarSubMenuAccion(rutaSubmenu);
        history.push(`${rutaSubmenu}`);
    };

    return (
        <MenuVista
            expanded={menuSeleccionado}
            menus={menus}
            seleccionarMenu={accionSeleccionarMenu}
            seleccionarSubMenu={accionSeleccionarSubMenu}
            subMenuSeleccionado={subMenuSeleccionado}
        />
    );
};

const estadoAProps = (estadoGlobal: IEstadoGlobal): IEstadoAPropsMenuCV => {
    return {
        menus: estadoGlobal.estadoAutenticacion.menus,
        menuSeleccionado: estadoGlobal.estadoConfiguracion.menuSeleccionado,
        subMenuSeleccionado: estadoGlobal.estadoConfiguracion.subMenuSeleccionado,
    };
};

const accionesAProps = (dispatch: Dispatch): IAccionesAPropsMenuCV => {
    return {
        seleccionarMenuAccion: (IdMenu: number) => dispatch(seleccionarMenu(IdMenu)),
        seleccionarSubMenuAccion: (RutaSubMenu: string) => dispatch(seleccionarSubMenu(RutaSubMenu)),
    };
};

export default compose(connect(estadoAProps, accionesAProps), withRouter)(MenuCV) as React.ComponentType;
