import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import IDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/documento';
import ModeloDocumentoDetalle from 'Infotrack@Modelos/smartStock/documentosDetalle';
import IDocumentoConDetallesAccion from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoConDetallesAccion';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import IFiltrosDocumentoDetallesAuditoria from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/filtrosDocumentoDetallesAuditoria';

export default class DocumentoDetalleCM {
    private modeloDocumentoDetalle: ModeloDocumentoDetalle;

    constructor() {
        this.modeloDocumentoDetalle = new ModeloDocumentoDetalle();
    }

    public consultarListaDocumentosDetallesAuditoria = (
        paginador: IPaginador,
        filtros: IFiltrosDocumentoDetallesAuditoria
    ) =>
        this.modeloDocumentoDetalle
            .consultarListaDocumentosDetallesAuditoria({ Paginador: paginador, Filtros: filtros })
            .then(({ data }) => data);

    public consultarDivisionDocumentoAuditoria(documento: IDocumento) {
        return this.modeloDocumentoDetalle
            .consultarDivisionDocumentoAuditoria(documento)
            .then(({ data: { Entidades } }) => Entidades[0]);
    }

    public editarDocumentosDetallesAuditoria(documentoConDetalle: IDocumentoConDetallesAccion) {
        return this.modeloDocumentoDetalle.editarDocumentosDetallesAuditoria(documentoConDetalle);
    }

    public validarDocumentoDetalle(
        {
            CantidadEntregada,
            CantidadRecibida,
            DivisionOrigen,
            FechaVencimiento,
            FEFO,
            ItemId,
            Lote,
            LotesItems,
            SeriadoItems,
            Serial,
        }: IDocumentoDetalleCompuesto,
        esPrimerConteo: boolean
    ) {
        if (
            !ItemId ||
            !DivisionOrigen ||
            (esPrimerConteo && CantidadRecibida === undefined) ||
            (!esPrimerConteo && CantidadEntregada === undefined) ||
            (LotesItems && !Lote) ||
            (SeriadoItems && !Serial) ||
            (FEFO && !FechaVencimiento)
        ) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else return true;
    }
}
