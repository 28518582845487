import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import RemoveIcon from '@material-ui/icons/Remove';
import { Action, Column, Options } from '@infotrack/presentacion-componentes/tabla';
import { paletaMaterialD } from '@infotrack/presentacion-transversales/constantes';
import IDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/documento';
import IDocumentoCompuestoMovimiento from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuestoMovimiento';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import IMovimientoCompuesto from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoCompuesta';
import { BotonAgregar, BotonRefrescar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const columnasGestionAuditorias = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IDocumentoCompuestoMovimiento>> => [
    { title: formatMessage({ id: 'gestionauditorias.NumeroDocumento' }), field: 'CodigoDocumento' },
    {
        title: formatMessage({ id: 'gestionauditorias.FechaCreacion' }),
        field: 'FechaCreacion',
        render: (rowData) => moment(rowData.FechaCreacion).format('DD/MM/YYYY/hh:mm A'),
    },
    { title: formatMessage({ id: 'gestionauditorias.BodegaOrigenDescripcion' }), field: 'BodegaOrigenDescripcion' },
    { title: formatMessage({ id: 'gestionauditorias.DocumentoRelacionado' }), field: 'DocumentoRelacionado' },
    {
        title: formatMessage({ id: 'gestionauditorias.ConteoUnico' }),
        render: (rowData) => (!rowData.BodegaOrigen || !rowData.BodegaDestino ? <CheckIcon /> : <RemoveIcon />),
    },
    { title: formatMessage({ id: 'gestionauditorias.EstadoDocumento' }), field: 'EtapaDescripcion' },
];

export const accionesGestionAuditorias = (
    alAbrirFormulario: (documento?: IDocumentoCompuestoMovimiento) => void,
    anularAuditoria: (documento: IDocumento) => void,
    recargarTabla: () => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Action<IDocumentoCompuestoMovimiento> | ((rowData: any) => Action<IDocumentoCompuestoMovimiento>)> => [
    {
        component: BotonAgregar,
        icon: () => <AddIcon />,
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
        onClick: () => alAbrirFormulario(),
    },
    (rowData) => ({
        disabled: rowData.EtapaDescripcion === TiposEtapas.Cerrada || rowData.EtapaDescripcion === TiposEtapas.Anulado,
        icon: () => <EditIcon color="action" />,
        onClick: (_, valor) => alAbrirFormulario(valor as IDocumentoCompuestoMovimiento),
        tooltip: formatMessage({ id: 'boton.editar' }),
    }),
    (rowData) => ({
        disabled: rowData.EtapaDescripcion === TiposEtapas.Cerrada || rowData.EtapaDescripcion === TiposEtapas.Anulado,
        icon: () => <CancelIcon color={rowData.EtapaDescripcion === TiposEtapas.Cerrada ? 'disabled' : 'action'} />,
        onClick: (_, valor) => anularAuditoria(valor as IDocumentoCompuestoMovimiento),
        tooltip: formatMessage({ id: 'boton.anular' }),
    }),
];

export const columnasDetallesAuditorias = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string,
    esUnicoConteo: boolean
): Array<Column<IDocumentoDetalleCompuesto>> => {
    const columnas: Array<Column<IDocumentoDetalleCompuesto>> = [
        { title: formatMessage({ id: 'gestionauditorias.Detalles.CodigoBarras' }), field: 'CodigoBarras' },
        {
            title: formatMessage({ id: 'gestionauditorias.Detalles.DescripcionItem' }),
            field: 'DescripcionItem',
        },
        {
            title: formatMessage({ id: 'gestionauditorias.Detalles.CantidadRecibida' }),
            field: 'CantidadRecibida',
        },
        {
            title: formatMessage({ id: 'gestionauditorias.Detalles.CantidadEntregada' }),
            field: 'CantidadEntregada',
        },
        {
            title: formatMessage({ id: 'gestionauditorias.Detalles.CantidadActual' }),
            field: 'CantidadActual',
            render: ({ CantidadActual }: any) => (CantidadActual ? CantidadActual : 0),
        },
        {
            title: formatMessage({ id: 'gestionauditorias.Detalles.DescripcionDivisionOrigen' }),
            field: 'DescripcionDivisionOrigen',
        },
        { title: formatMessage({ id: 'gestionauditorias.Detalles.Serial' }), field: 'Serial' },
        { title: formatMessage({ id: 'gestionauditorias.Detalles.Lote' }), field: 'Lote' },
        {
            title: formatMessage({ id: 'gestionauditorias.Detalles.FechaVencimiento' }),
            field: 'FechaVencimiento',
            render: ({ FechaVencimiento }: any) =>
                FechaVencimiento ? moment(FechaVencimiento).format('DD/MM/YYYY') : '',
        },
    ];
    return esUnicoConteo ? columnas.filter(({ field }) => field !== 'CantidadEntregada') : columnas;
};

export const accionesDetallesAuditorias = (
    alAbrirFormulario: (documentoDetalle?: IDocumentoDetalleCompuesto) => void,
    alRecargarTabla: () => void,
    alEliminarDocumentoDetalle: (documentoDetalle: IDocumentoDetalleCompuesto) => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Action<IDocumentoDetalleCompuesto> | ((rowData: any) => Action<IDocumentoDetalleCompuesto>)> => [
    {
        component: BotonRefrescar,
        icon: () => <RefreshIcon />,
        onClick: () => alRecargarTabla(),
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.refrescar' }),
    },
    {
        component: BotonAgregar,
        icon: () => <AddIcon />,
        onClick: () => alAbrirFormulario(),
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
    },
    (rowData) => ({
        disabled: rowData.EtapaDescripcion === TiposEtapas.Cerrada,
        icon: () => <EditIcon color="action" />,
        onClick: (_, valor) => alAbrirFormulario(valor as IDocumentoDetalleCompuesto),
        tooltip: formatMessage({ id: 'boton.editar' }),
    }),
    (rowData) => ({
        disabled: rowData.EtapaDescripcion === TiposEtapas.Cerrada,
        icon: () => <DeleteIcon color="action" />,
        onClick: (_, valor) => alEliminarDocumentoDetalle(valor as IDocumentoDetalleCompuesto),
        tooltip: formatMessage({ id: 'boton.eliminar' }),
    }),
];

export const opcionesDetalleAuditoria: Options<IDocumentoCompuestoMovimiento> = {
    rowStyle: (rowData) =>
        rowData.CantidadRecibida !== rowData.CantidadEntregada && rowData.DivisionDestino
            ? { backgroundColor: paletaMaterialD.red_200 }
            : {},
    maxBodyHeight: '50vh',
    showTitle: true,
};

export const opcionesDiferencias: Options<IDocumentoCompuestoMovimiento> = {
    showTitle: true,
    maxBodyHeight: '30vh',
};

export const accionesMovimientos = (
    alRecargarTabla: () => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
) => [
    {
        component: BotonRefrescar,
        icon: () => <RefreshIcon />,
        onClick: () => alRecargarTabla(),
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.refrescar' }),
    },
];

export const columnasMovimientos = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IMovimientoCompuesto>> => [
    { title: formatMessage({ id: 'gestionauditorias.Detalles.CodigoBarras' }), field: 'CodigoBarras' },
    {
        title: formatMessage({ id: 'gestionauditorias.Detalles.DescripcionItem' }),
        field: 'DescripcionItem',
    },
    {
        title: formatMessage({ id: 'gestionauditorias.Detalles.CantidadActual' }),
        field: 'CantidadTotal',
    },
    { title: formatMessage({ id: 'gestionauditorias.Detalles.Serial' }), field: 'Serial' },
    { title: formatMessage({ id: 'gestionauditorias.Detalles.Lote' }), field: 'Lote' },
    {
        title: formatMessage({ id: 'gestionauditorias.Detalles.FechaVencimiento' }),
        field: 'Fechavencimiento',
        render: ({ FechaVencimiento }: any) => (FechaVencimiento ? moment(FechaVencimiento).format('DD/MM/YYYY') : ''),
    },
];
