import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IClasificaciones from 'Infotrack@Modelos/smartStock/clasificaciones/entidades/clasificaciones';
import IFamilias from 'Infotrack@Modelos/smartStock/familias/entidades/familias';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import EntidadesFiltroInvetarioCM from '../controladorModelo/entidadesFiltroInvenitarioCM';
import { IFormularioConsultaInventarioCVProps, IInputsConsultaInventario } from '../interfaces';
import { acciones, IEstadoConsultaInventario } from '../reductorConsultaInventario';
import FormularioConsultaInventarioVista from '../vista/formularioConsultaInventarioVista';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';

const entidadesFiltroInvetarioCM = new EntidadesFiltroInvetarioCM();

const FormularioConsultaInventarioCV: React.FunctionComponent<IFormularioConsultaInventarioCVProps> = ({
    IdEmpresa,
}) => {
    const [bodegas, setBodegas] = React.useState<IBodega[]>([]);
    const [bodegaSelected, setBodegaSelected] = React.useState<IBodega | null>(null);
    const [familias, setFamilia] = React.useState<IFamilias[]>([]);
    const [clasificaciones, setClasificaciones] = React.useState<IClasificaciones[]>([]);
    const [divisiones, setDivisiones] = React.useState<IBodegaDivisiones[]>([]);
    const [{ estadoFormularioConsulta,filtros }, dispatch] = useProveedor<IEstadoConsultaInventario>();
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);
    

    React.useEffect(() => {
        setBodegaSelected(null);
    }, [estadoFormularioConsulta]);
    React.useEffect(() => {
        cargarEntidadesFiltros();
    }, []);

    React.useEffect(() => {
        if (filtros.BodegaId) cargarDivisionesporBodegaId();
        else setDivisiones([])
    }, [filtros.BodegaId]);

    const alCambiarfiltros = ({
        target: { name, value },
    }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        dispatch({ type: acciones.MODIFICAR_FILTROS_CONSULTA, payload: { llave: name, valor: value } });
    };

    const alAbrirDialogo = () => {
        if (filtros.BodegaId === '') {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
        } else {
            dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
        }
    };

    const alLimpiarFiltros = () => {
        setBodegaSelected(null);
        dispatch({ type: acciones.LIMPIAR_FILTROS });
    };

    const cargarEntidadesFiltros = () => {
        entidadesFiltroInvetarioCM
            .consultaEntidadesFiltro(IdEmpresa, idUsuario)
            .then(
                ({ bodegas: bodegasCargadas, familias: familiaCargada, clasificaciones: clasificacionesCargadas }) => {
                    setBodegas(bodegasCargadas);
                    setFamilia(familiaCargada);
                    setClasificaciones(clasificacionesCargadas);
                }
            );
    };

    const cargarDivisionesporBodegaId = async () => {
        setDivisiones(
            await entidadesFiltroInvetarioCM.cosultaDivisionesPorBodegaId(Number(filtros.BodegaId), IdEmpresa)
        );
    };
    const alCambiarValorAutocomplete = (nuevoValor: IBodega, nombreValor: string) => {
        setBodegaSelected(nuevoValor);
        dispatch({ type: acciones.MODIFICAR_FILTROS_CONSULTA, payload: { llave: nombreValor, valor: nuevoValor ? nuevoValor.BodegaId : '' } });
    };

    return (
        <FormularioConsultaInventarioVista
            alAbrirDialogo={alAbrirDialogo}
            alLimpiarFiltros={alLimpiarFiltros}
            entidadesFiltro={{ bodegas, familias, clasificaciones, divisiones }}
            filtro={filtros}
            alCambiarValor={alCambiarfiltros}
            alCambiarValorAutocomplete={alCambiarValorAutocomplete}
            bodegaSelected={bodegaSelected}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion },
}: IEstadoGlobal): IFormularioConsultaInventarioCVProps => ({ IdEmpresa: usuarioInformacion!.IdEmpresa });

export default connect<IFormularioConsultaInventarioCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(FormularioConsultaInventarioCV);
