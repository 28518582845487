import React, { FunctionComponent } from 'react';
import {
    AppBar,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Toolbar,
} from '@material-ui/core';
import Texto from '@infotrack/presentacion-componentes/texto';
import { ChangeEvent } from 'react';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import { Autocomplete } from '@material-ui/lab';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';
interface IFormularioCargaPlantillaInventario {
    estado: boolean;
    cerrar: () => void;
    listaBodegas: IBodega[];
    setBodega: (idBodega: number) => void;
    idBodegaSeleccionada: number;
    handleCargarArchivo: (event: ChangeEvent<HTMLInputElement>) => void;
    enviarArchivoCarga: () => void;
    listaTipoDocumento: ITipoDocumento[];
    setTipoDocumento: (idTipoDocumento: number) => void;
    idTipoDocumento: number;
}

const FormularioCargaPlantillaInventario: FunctionComponent<IFormularioCargaPlantillaInventario> = ({
    estado,
    cerrar,
    listaBodegas,
    setBodega,
    idBodegaSeleccionada,
    handleCargarArchivo,
    enviarArchivoCarga,
    listaTipoDocumento,
    idTipoDocumento,
    setTipoDocumento,
}) => {
    return (
        <Dialog onClose={cerrar} open={estado}>
            <AppBar position="static">
                <Toolbar>
                    <Grid container justify="center">
                        <Texto align="center" color="inherit" id={'cargaMasiva.CargarArchivo'} />
                    </Grid>
                </Toolbar>
            </AppBar>
            <DialogContent>
                {
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={12}>
                            <Autocomplete
                                getOptionLabel={(b) => b.BodegaDescripcion}
                                onChange={(_: ChangeEvent<{}>, bodega: IBodega | null) =>
                                    setBodega(bodega ? bodega.BodegaId! : 0)
                                }
                                options={listaBodegas}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={<Texto id="cargaMasiva.Bodega" />}
                                        required
                                    />
                                )}
                                value={listaBodegas.find((b) => b.BodegaId === idBodegaSeleccionada) || null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                getOptionLabel={(t) => t.TipoDocumentoDescripcion}
                                onChange={(_: ChangeEvent<{}>, tipoDocumento: ITipoDocumento | null) =>
                                    setTipoDocumento(tipoDocumento ? tipoDocumento.TipoDocumentoId! : 0)
                                }
                                options={listaTipoDocumento}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={<Texto id="cargaMasiva.TipoDocumento" />}
                                        required
                                    />
                                )}
                                value={listaTipoDocumento.find((b) => b.TipoDocumentoId === idTipoDocumento) || null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <input
                                accept=".xlsx"
                                onChange={(e) => handleCargarArchivo(e)}
                                //ref={refInputArchivo}
                                type="file"
                            />
                        </Grid>
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Grid container justify="flex-end" style={{ margin: '1px 1px 1px 1px' }} spacing={2}>
                    <Button color="primary" onClick={() => cerrar()} style={{ marginRight: '3px' }} variant="contained">
                        <Texto id={'boton.cancelar'} />
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => enviarArchivoCarga()}
                        style={{ marginRight: '3px' }}
                        variant="contained"
                    >
                        <Texto id={'boton.guardar'} />
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default FormularioCargaPlantillaInventario;
