import React from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

import IBodegasDivisionesPropiedades from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisionesPropiedades';
import IColumnas from 'Infotrack@Modelos/smartStock/columnas/entidades/columnas';
import IFilas from 'Infotrack@Modelos/smartStock/filas/entidades/filas';
import IPisos from 'Infotrack@Modelos/smartStock/pisos/entidades/pisos';
import ITiposDivisiones from 'Infotrack@Modelos/smartStock/tiposDivisiones/entidades/tiposDivisiones';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';

import BodegasDivisionesCM from '../controladorModelo/bodegasDivisionesCM';
import EntidadesBodegaDivisionCM from '../controladorModelo/entidadesBodegaDivisionCM';
import { IFormularioDivisionesBodegaCVProps, IInputsBodegasDivisiones } from '../interfaces';
import { acciones, IEstadoGestionBodegas } from '../reductorGestionBodegas';
import FormularioDivisionesVista from '../vista/formularioDivisionesBodegaVista';
import { estadoInactivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';

const divisionBodega: IInputsBodegasDivisiones = {
    DivisionId: 0,
    EmpresaId: '',
    TipoDivisionId: '',
    BodegaId: 0,
    FilaId: '',
    ColumnaId: '',
    PisoId: '',
    DivisionCodigoBarras: '',
    DivisionDescripcion: '',
    Ancho: '',
    Largo: '',
    Alto: '',
    Peso: '',
    VolumenDisponible: '0',
    PesoDisponible: '0',
    Estado: true,
};

const entidadesBodegaDivisionCM = new EntidadesBodegaDivisionCM();
const bodegasDivisionesCM = new BodegasDivisionesCM();

const FormularioDivisionesBodegaCV: React.FunctionComponent<IFormularioDivisionesBodegaCVProps> = ({ IdEmpresa }) => {
    const [
        {
            bodega,
            entidadBodegaDivision,
            estadoFormularioDivisionesBodega,
            tipoFormularioBodegaDivision,
            propiedadesDivisiones,
        },
        dispatch,
    ] = useProveedor<IEstadoGestionBodegas>();
    const [columnas, setColumnas] = React.useState<IColumnas[]>([]);
    const [filas, setFilas] = React.useState<IFilas[]>([]);
    const [pisos, setPisos] = React.useState<IPisos[]>([]);
    const [tiposDivisiones, setTiposDivisiones] = React.useState<ITiposDivisiones[]>([]);
    const { alCambiarValor, setValor, reiniciar, valor } = useInputState<IInputsBodegasDivisiones>(divisionBodega);
    const theme = useTheme();
    const esMovil = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        cargarEntidadesFormulario();
    }, []);

    React.useEffect(() => {
        if (entidadBodegaDivision) {
            setValor(entidadBodegaDivision);
            validarColumnaActiva();
        }
    }, [entidadBodegaDivision]);

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DIVISIONES_BODEGA });
    };

    const validarColumnaActiva = () => {
        if (columnas.find((x) => x.ColumnaId === parseInt(entidadBodegaDivision!.ColumnaId) && x.Estado === estadoInactivo)) {
            entidadBodegaDivision!.ColumnaId = '0';
        }
        if (
            tiposDivisiones.find(
                (x) => x.TipoDivisionId === parseInt(entidadBodegaDivision!.TipoDivisionId) && x.Estado === estadoInactivo
            )
        ) {
            entidadBodegaDivision!.TipoDivisionId = '0';
        }

        if (filas.find((x) => x.FilaId === parseInt(entidadBodegaDivision!.FilaId) && x.Estado === estadoInactivo)) {
            entidadBodegaDivision!.FilaId = '0';
        }

        if (pisos.find((x) => x.PisoId === parseInt(entidadBodegaDivision!.PisoId) && x.Estado === estadoInactivo)) {
            entidadBodegaDivision!.PisoId = '0';
        }

        setValor(entidadBodegaDivision!);
    };

    const confirmarCreacionEdicion = () => {
        const esEdicion = tipoFormularioBodegaDivision === TipoFormulario.Edicion;
        const divisionAGuardarEditar = mapearDatos();
        if (bodegasDivisionesCM.validarEntidad(divisionAGuardarEditar)) {
            manejadorDialogoGlobal({
                accionConfirmar: () => alGuardarEditarDivisionBodega(divisionAGuardarEditar, esEdicion),
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                estado: true,
                titulo: <Texto id="titulo.advertencia" />,
                tipoDialogo: 'Advertencia',
                mensaje: <Texto id={`${esEdicion ? 'alerta.confirmacionEdicion' : 'alerta.confirmacionCreacion'}`} />,
                mostrarCancelar: true,
            });
        }
    };

    const alGuardarEditarDivisionBodega = (
        divisionAGuardarEditar: IBodegasDivisionesPropiedades,
        esEdicion: boolean
    ) => {
        manejadorDialogoGlobal({ estado: false });
        const peticionAEjecutar = esEdicion
            ? bodegasDivisionesCM.editarBodegaDivisionesPropiedades(divisionAGuardarEditar)
            : bodegasDivisionesCM.guardarBodegaDivisionesPropiedades(divisionAGuardarEditar);
        peticionAEjecutar.then((respuesta) => {
            if (respuesta.data.Resultado) {
                alCerrarFormulario();
                dispatch({ type: acciones.RECARGAR_TABLA_BODEGA_DIVISIONES });
                dispatch({ type: acciones.RECARGAR_ENTIDADES_DIVISIONES_POR_DEFECTO });
            }
        });
    };

    const cargarEntidadesFormulario = () => {
        entidadesBodegaDivisionCM
            .consultarEntidadesBodegaDivision(IdEmpresa, tipoFormularioBodegaDivision === TipoFormulario.Creacion)
            .then(
                ({
                    columnas: columnasCargadas,
                    filas: filasCargadas,
                    pisos: pisosCargados,
                    tiposDivisiones: tiposDivisionesCargadas,
                }) => {
                    setColumnas(columnasCargadas);
                    setFilas(filasCargadas);
                    setPisos(pisosCargados);
                    setTiposDivisiones(tiposDivisionesCargadas);
                }
            );
    };

    const mapearDatos = (): IBodegasDivisionesPropiedades => {
        const {
            Alto,
            Ancho,
            ColumnaId,
            Estado,
            FilaId,
            Largo,
            Peso,
            PesoDisponible,
            PisoId,
            TipoDivisionId,
            VolumenDisponible,
        } = valor;
        return {
            Divisiones: {
                ...valor,
                BodegaId: bodega.BodegaId!,
                Alto: Number(Alto),
                Ancho: Number(Ancho),
                ColumnaId: Number(ColumnaId),
                EmpresaId: IdEmpresa,
                FilaId: Number(FilaId),
                Largo: Number(Largo),
                Peso: Number(Peso),
                PesoDisponible: Number(PesoDisponible),
                PisoId: Number(PisoId),
                TipoDivisionId: Number(TipoDivisionId),
                VolumenDisponible: Number(VolumenDisponible),
                Estado: Estado ? 1 : 0,
            },
            DivisionesPropiedades: propiedadesDivisiones.map(
                ({
                    Accion,
                    Cantidad,
                    DivisionId,
                    DivisionPropiedadId,
                    DivisionPropiedadTexto,
                    Estado: EstadoPropiedad,
                    FamiliaId,
                    ItemId,
                    TipoPropiedadId,
                    UnidadId,
                }) => ({
                    Accion,
                    Cantidad: Cantidad && Cantidad !== '' ? Number(Cantidad) : null,
                    DivisionId,
                    DivisionPropiedadId,
                    DivisionPropiedadTexto,
                    EmpresaId: IdEmpresa,
                    Estado: EstadoPropiedad ? 1 : 0,
                    FamiliaId: FamiliaId && FamiliaId !== '' ? Number(FamiliaId) : null,
                    ItemId: ItemId && ItemId !== '' ? Number(ItemId) : null,
                    TipoPropiedadId: Number(TipoPropiedadId),
                    UnidadId: UnidadId && UnidadId !== '' ? Number(UnidadId) : null,
                })
            ),
        };
    };

    const reiniciarCampos = () => {
        reiniciar();
        if (entidadBodegaDivision) dispatch({ type: acciones.ESTABLECER_BODEGA_DIVISION, payload: undefined });
    };

    return (
        <FormularioDivisionesVista
            alCambiarValor={alCambiarValor}
            alCerrarFormulario={alCerrarFormulario}
            confirmarCreacionEdicion={confirmarCreacionEdicion}
            esMovil={esMovil}
            divisionBodega={valor}
            entidadesFormulario={{ columnas, filas, pisos, tiposDivisiones }}
            estadoFormulario={estadoFormularioDivisionesBodega}
            tipoFormulario={tipoFormularioBodegaDivision}
            limpiarCampos={reiniciarCampos}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion },
}: IEstadoGlobal): IFormularioDivisionesBodegaCVProps => ({ IdEmpresa: usuarioInformacion!.IdEmpresa });

export default connect<IFormularioDivisionesBodegaCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(FormularioDivisionesBodegaCV);
