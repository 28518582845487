export default {
    'DatosPersonales.DatosPersona': 'Datos Personales ',
    'DatosPersonales.TipoIdentificacion': 'Tipo identificación',
    'DatosPersonales.Identificacion': 'Identificación',
    'DatosPersonales.PrimerNombre': 'Primer Nombre',
    'DatosPersonales.SegundoNombre': 'Segundo Nombre',
    'DatosPersonales.PrimerApellido': 'Primer Apellido',
    'DatosPersonales.SegundoApellido': 'Segundo Apellido',
    'DatosPersonales.Direccion': 'Direccion',
    'DatosPersonales.Telefono': 'Teléfono',
    'DatosPersonales.Celular': 'Celular',
    'DatosPersonales.CorreoPrincipal': 'Correo Principal',
    'DatosPersonales.DatosUsuario': 'Información Usuario',
    'DatosPersonales.Login': 'Login',
    'DatosPersonales.ClaveNueva': 'Clave Nueva',
    'DatosPersonales.ClaveConfirmacion': 'Clave Confirmación',
    'DatosPersonales.Sucursal': 'Sucursal',
    'Validacion.Contrasenia': 'La clave debe tener entre 8 y 20 caracteres.',
    'DatosPersonales.ContraseniaDiferentes': 'Las contraseñas no coinciden',
    'DatosPersonales.Perfil': 'Perfil',
    'sidebar.datospersonales': 'Datos Personales',
    'sidebar.usuario': 'Usuario',
};
