import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import AbreviaturasTiposDocumentos from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/abreviaturasTiposDocumento';
import { IRefSelectMasivo } from 'Infotrack@Transversales/componentes/selectMasivoItems';
import React from 'react';
import DocumentoCM from '../controladorModelo/documentoCM';
import DocumentoDetalleCM from '../controladorModelo/documentoDetalleCM';
import { acciones, IEstadoGestionMovimientos } from '../reductorGestionMovimientos';
import TablaDocumentoDetallesVista from '../vista/tablaDocumentoDetallesVista';
import Texto from '@infotrack/presentacion-componentes/texto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';

const documentoCM = new DocumentoCM();
const documentoDetalleCM = new DocumentoDetalleCM();

const TablaDocumentoDetallesCV: React.FunctionComponent = () => {
    const [
        { documento, documentosDetalles, estadoFormularioDetalle, entidadDocumento, tipoFormularioDocumento },
        dispatch,
    ] = useProveedor<IEstadoGestionMovimientos>();
    const ref = React.useRef<IRefSelectMasivo>();

    React.useEffect(() => {
        if (entidadDocumento) cargarDetalles();
    }, []);

    React.useEffect(() => {
        if (documento.TipoDocumento && ref.current) ref.current.reiniciarEstado();
    }, [documento.TipoDocumento]);

    const alAbrirFormulario = (documentoDetalle?: IDocumentoDetalleCompuesto) => {
        if (
            tipoFormularioDocumento === TipoFormulario.Visualizacion ||
            (documento.EtapaDescripcion !== 'Creada' && documento.EtapaDescripcion !== '') ||
            documento.DocumentoPadre
        ) {
            notificacionGlobal('gestionmovimientos.alerta.documentoPadreNoDetalle', 6000, 'warning', true);

            return;
        }
        if (documentoDetalle)
            dispatch({ type: acciones.ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE, payload: documentoDetalle });
        if (ref.current) {
            ref.current.reiniciarEstado();
        }
        const documentoAValidar = {
            ...documento,
            BodegaDestino: documento.BodegaDestino ? documento.BodegaDestino.BodegaId : 0,
            BodegaOrigen: documento.BodegaOrigen ? documento.BodegaOrigen.BodegaId : 0,
            TipoDocumentoId: documento.TipoDocumento ? documento.TipoDocumento.TipoDocumentoId : 0,
        };
        if (
            documentoCM.validarDocumentoAbrirFormulario(
                documentoAValidar as any,
                documento.TipoDocumento ? documento.TipoDocumento.TipoDocumentoAbreviatura : undefined
            )
        ) {
            dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
            if (documentoDetalle && ref.current) {
                ref.current.editarItem({
                    itemId: documentoDetalle.ItemId!,
                    divisionId: documentoDetalle.DivisionOrigen!,
                });
            }
        }
    };

    const cargarDetalles = async () => {
        const detallesExistentes = await documentoDetalleCM.consultarListaDocumentosDetalles(
            entidadDocumento!.DocumentoId!
        );
        dispatch({
            type: acciones.CARGAR_DOCUMENTOS_DETALLES,
            payload: detallesExistentes,
        });
        if (ref.current) ref.current.establecerItems(detallesExistentes);
    };

    const alEliminarDocumentoDetalle = (documentoDetalle: IDocumentoDetalleCompuesto) => {
        if (tipoFormularioDocumento === TipoFormulario.Creacion) {
            dispatch({ type: acciones.ELIMINAR_DOCUMENTO_DETALLE, payload: documentoDetalle });
            if (ref.current)
                ref.current.eliminarItem({
                    divisionId: documentoDetalle.DivisionOrigen!,
                    itemId: documentoDetalle.ItemId!,
                });
        } else {
            if (documentosDetalles.length === 1) {
                notificacionGlobal('gestionmovimientos.alerta.agregarDetalles', 6000, 'warning', true);
            } else {
                manejadorDialogoGlobal({
                    accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                    accionConfirmar: () => {
                        documentoDetalleCM.EliminarDocumentoDetalle(documentoDetalle).then((resultado) => {
                            if (resultado.data.Resultado) {
                                dispatch({ type: acciones.ELIMINAR_DOCUMENTO_DETALLE, payload: documentoDetalle });
                                if (ref.current)
                                    ref.current.eliminarItem({
                                        divisionId: documentoDetalle.DivisionOrigen!,
                                        itemId: documentoDetalle.ItemId!,
                                    });
                            }
                            manejadorDialogoGlobal({ estado: false });
                        });
                    },
                    estado: true,
                    mensaje: <Texto id="gestionmovimientos.alerta.cambioImportante" />,
                    mostrarCancelar: true,
                    tipoDialogo: 'Advertencia',
                    titulo: <Texto id="titulo.advertencia" />,
                });
            }
        }
    };

    const alCerrarFormulario = () => {
        if (ref.current) {
            ref.current.reiniciarEstado();
        }
        if (entidadDocumento) cargarDetalles();
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
    };

    const alModificarDetalles = (nuevosDocumentosDetalles: any[]) => {
        if (tipoFormularioDocumento === TipoFormulario.Creacion) {
            if (nuevosDocumentosDetalles.length === 0) {
                return notificacionGlobal('gestionmovimientos.alerta.sinSeleccion', 6000, 'warning', true);
            }
            let errorDestino = false;
            nuevosDocumentosDetalles.forEach((element) => {
                if (!element.DivisionDestinoId) {
                    errorDestino = true;
                }
            });
            if (errorDestino) {
                notificacionGlobal('gestionmovimientos.alerta.destino', 6000, 'warning', true);
            } else {
                alCerrarFormulario();
                if (
                    documento.TipoDocumento &&
                    documento.TipoDocumento.TipoDocumentoAbreviatura === AbreviaturasTiposDocumentos.SAL
                )
                    dispatch({
                        type: acciones.CARGAR_DOCUMENTOS_DETALLES,
                        payload: nuevosDocumentosDetalles.map((docDetalle) => ({
                            ...docDetalle,
                            DivisionDestino: docDetalle.DivisionOrigen,
                            DescripcionDivisionDestino: docDetalle.DescripcionDivisionOrigen,
                        })),
                    });
                else dispatch({ type: acciones.CARGAR_DOCUMENTOS_DETALLES, payload: nuevosDocumentosDetalles });
            }
        } else {
            if (nuevosDocumentosDetalles.length === 0) {
                return notificacionGlobal('gestionmovimientos.alerta.sinSeleccion', 6000, 'warning', true);
            }
            let documentosDetallesActual = [...documentosDetalles];
            let errorDestino = false;
            nuevosDocumentosDetalles.forEach((element) => {
                if (!element.DivisionDestinoId) {
                    errorDestino = true;
                }
            });
            if (errorDestino) {
                notificacionGlobal('gestionmovimientos.alerta.destino', 6000, 'warning', true);
            } else {
                nuevosDocumentosDetalles.forEach((element) => {
                    if (
                        documentosDetalles.find(
                            (x) => x.ItemId === element.ItemId && x.DivisionOrigen === element.DivisionOrigen
                        )
                    ) {
                        const index = documentosDetalles.findIndex(
                            (x) => x.ItemId === element.ItemId && x.DivisionOrigen === element.DivisionOrigen
                        );
                        documentosDetalles[index].Serial = element.Serial;
                        documentosDetalles[index].Lote = element.Lote;
                        documentosDetalles[index].FechaVencimiento = element.FechaVencimiento;
                        documentosDetalles[index].CantidadSolicitada = element.CantidadSolicitada;
                        documentosDetalles[index].DivisionDestino = element.DivisionDestino;
                        documentoDetalleCM.EditarDocumentoDetalle(documentosDetalles[index]);
                    } else {
                        let documentoGuardar = {
                            ...element,
                            DocumentoId: entidadDocumento ? entidadDocumento.DocumentoId : undefined,
                        } as any;
                        documentoDetalleCM.GuardarDocumentoDetalle(documentoGuardar).then((resultado) => {
                            if (resultado.data.Resultado) {
                                documentoGuardar.DocumentoDetalleId = resultado.data.Entidades[0].DocumentoDetalleId;
                                documentosDetallesActual.push(documentoGuardar);
                            }
                        });
                    }
                });
                cargarDetalles();
                dispatch({ type: acciones.CARGAR_DOCUMENTOS_DETALLES, payload: documentosDetallesActual });
                alCerrarFormulario();
            }
        }
    };

    return (
        <TablaDocumentoDetallesVista
            alAbrirFormulario={alAbrirFormulario}
            alEliminarDocumentoDetalle={alEliminarDocumentoDetalle}
            deshabilitarEdicion={
                documento.DocumentoPadre ||
                tipoFormularioDocumento === TipoFormulario.Visualizacion ||
                (documento.EtapaDescripcion !== TiposEtapas.Creada && typeof documento.EtapaDescripcion === null)
            }
            documentosDetalles={documentosDetalles}
            propiedadesSelectMaviso={{
                alCerrarFormulario,
                alModificarDetalles,
                tipoDocumentoId:
                    Number(documento.TipoDocumento ? documento.TipoDocumento.TipoDocumentoId : 0) || undefined,
                bodegaId: Number(documento.BodegaOrigen ? documento.BodegaOrigen.BodegaId : 0) || undefined,
                bodegaDestinoId: Number(documento.BodegaDestino ? documento.BodegaDestino.BodegaId : 0) || undefined,
                desactivarDivisionDestino: Boolean(
                    documento.TipoDocumento &&
                        (documento.TipoDocumento.TipoDocumentoAbreviatura === AbreviaturasTiposDocumentos.TRA ||
                            documento.TipoDocumento.TipoDocumentoAbreviatura === AbreviaturasTiposDocumentos.SAL)
                ),
                divisionMultiple: Boolean(
                    documento.TipoDocumento &&
                        documento.TipoDocumento.TipoDocumentoAbreviatura !== AbreviaturasTiposDocumentos.TRA &&
                        documento.TipoDocumento.TipoDocumentoAbreviatura !== AbreviaturasTiposDocumentos.SAL
                ),
                estadoFormulario: Boolean(
                    estadoFormularioDetalle &&
                        documento.TipoDocumento &&
                        documento.TipoDocumento.TipoDocumentoAbreviatura !== AbreviaturasTiposDocumentos.ENT
                ),
                customRef: ref,
            }}
        />
    );
};

export default TablaDocumentoDetallesCV;
