import { Checkbox } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Action, Column, Options } from '@infotrack/presentacion-componentes/tabla';
import IDocumentoCompuestoMovimiento from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuestoMovimiento';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import { BotonAgregar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';

export const columnas = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IDocumentoCompuestoMovimiento>> => [
    { title: formatMessage({ id: 'gestionmovimientos.NumeroDocumento' }), field: 'CodigoDocumento' },
    { title: formatMessage({ id: 'gestionmovimientos.DocumentoRelacionado' }), field: 'DocumentoRelacionado' },
    { title: formatMessage({ id: 'gestionmovimientos.TipoDocumento' }), field: 'TipoDocumentoDescripcion' },
    { title: formatMessage({ id: 'gestionmovimientos.TipoMovimiento' }), field: 'TipoMovimientoDescripcion' },
    { title: formatMessage({ id: 'gestionmovimientos.FechaCreacion' }), field: 'FechaCreacion' },
    { title: formatMessage({ id: 'gestionmovimientos.BodegaOrigenDescripcion' }), field: 'BodegaOrigenDescripcion' },
    {
        title: formatMessage({ id: 'gestionmovimientos.BodegaDestinoDescripcion' }),
        field: 'BodegaDestinoDescripcion',
        hidden: true,
    },
    { title: formatMessage({ id: 'gestionmovimientos.PersonaCreacion' }), field: 'PersonaCreacion' },
    { title: formatMessage({ id: 'gestionmovimientos.EstadoDocumento' }), field: 'EtapaDescripcion' },
];

export const acciones = (
    alAbrirFormulario: (documento?: IDocumentoCompuestoMovimiento) => void,
    alAnularDocumento: (documento?: IDocumentoCompuestoMovimiento) => void,
    recargarTabla: () => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Action<IDocumentoCompuestoMovimiento> | ((rowData: any) => Action<IDocumentoCompuestoMovimiento>)> => [
    {
        component: BotonAgregar,
        icon: () => <AddIcon />,
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
        onClick: () => alAbrirFormulario(),
    },
    {
        icon: () => <EditIcon color="action" />,
        tooltip: formatMessage({ id: 'boton.editar' }),
        onClick: (_: any, rowData: any) => alAbrirFormulario(rowData as IDocumentoCompuestoMovimiento),
    },
    (rowData: IDocumentoCompuestoMovimiento) => {
        const deshabilitarEdicion = rowData.EtapaDescripcion !== TiposEtapas.Creada;
        return {
            icon: () => <BlockIcon color={deshabilitarEdicion ? 'disabled' : 'action'} />,
            onClick: (_: any, value: any) => alAnularDocumento(value as IDocumentoCompuestoMovimiento),
            tooltip: formatMessage({ id: 'boton.anular' }),
            disabled: deshabilitarEdicion,
        };
    },
];

export const opciones: Options<IDocumentoCompuestoMovimiento> = {
    search: false,
    maxBodyHeight: '50vh',
};

export const accionesTablaDocumentoDetalle = (
    alAbrirFormulario: (documentoDetalle?: IDocumentoDetalleCompuesto) => void,
    alEliminarDetalle: (documentoDetalle: IDocumentoDetalleCompuesto) => void,
    deshabilitarEdicion: boolean
): Array<Action<IDocumentoDetalleCompuesto>> => [
    {
        component: BotonAgregar,
        isFreeAction: true,
        icon: () => <AddIcon />,
        onClick: () => alAbrirFormulario(),
        disabled: deshabilitarEdicion,
    },
    {
        icon: () => <EditIcon color={deshabilitarEdicion ? 'disabled' : 'action'} />,
        onClick: (_, value) => alAbrirFormulario(value as IDocumentoDetalleCompuesto),
        disabled: deshabilitarEdicion,
    },
    {
        icon: () => <DeleteIcon color={deshabilitarEdicion ? 'disabled' : 'action'} />,
        onClick: (_, value) => alEliminarDetalle(value as IDocumentoDetalleCompuesto),
        disabled: deshabilitarEdicion,
    },
];

export const columnasTablaDocumentoDetalle = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IDocumentoDetalleCompuesto>> => [
    { field: 'DescripcionItem', title: formatMessage({ id: 'gestionmovimientos.documentoDetalle.DescripcionItem' }) },
    { field: 'CodigoBarras', title: formatMessage({ id: 'gestionmovimientos.documentoDetalle.CodigoBarras' }) },
    {
        field: 'CantidadSolicitada',
        title: formatMessage({ id: 'gestionmovimientos.documentoDetalle.CantidadSolicitada' }),
    },
    {
        field: 'DescripcionDivisionOrigen',
        title: formatMessage({ id: 'gestionmovimientos.documentoDetalle.DivisionOrigen' }),
    },
    {
        field: 'FechaVencimiento',
        title: formatMessage({ id: 'gestionmovimientos.documentoDetalle.FechaVencimiento' }),
        render: (rowData: any) =>
            typeof rowData.FechaVencimiento !== 'object' || !rowData.FechaVencimiento
                ? rowData.FechaVencimiento
                : rowData.FechaVencimiento.format('DD/MM/YYYY'),
    },
    {
        field: 'ValorUnitarioItem',
        title: formatMessage({ id: 'gestionmovimientos.documentoDetalle.ValorUnitarioItem' }),
    },
    {
        field: 'Total',
        title: formatMessage({ id: 'gestionmovimientos.documentoDetalle.Total' }),
    },
];

export const opcionesTablaDocumentoDetalle: Options<IDocumentoDetalleCompuesto> = {
    maxBodyHeight: '30vh',
    showTitle: true,
};

export const columnasAlmacenamientoDetallesMasivo = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IDocumentoCompuestoMovimiento>> => [
    { title: formatMessage({ id: 'Item' }), field: 'ItemId' },
    { title: formatMessage({ id: 'Division Origen' }), field: 'DivisionOrigenId' },
    { title: formatMessage({ id: 'Division Destino' }), field: 'DivisionDestinoId' },
    { title: formatMessage({ id: 'Cantidad Disponible' }), field: 'CantidadDisponible' },
    { title: formatMessage({ id: 'Cantidad Solicitada' }), field: 'CantidadSolicitada' },
];

export const accionesAlmacenamientoDetallesMasivo = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Action<IDocumentoDetalleCompuesto>> => [
    {
        icon: () => <Checkbox />,
        tooltip: formatMessage({ id: 'recepcion.documentos.verDetalle' }),
        onClick: () => {},
    },
];
