import React, { FunctionComponent, useEffect, useState } from 'react';
import { Card, CardHeader } from '@material-ui/core';
import { Refresh as RefreshIcon, Remove as RemoveIcon } from '@material-ui/icons';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import Tabla, { Column } from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';

import moment from 'moment';
import IAuditoriaConteoDetalleComparacion from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaConteoDetalleComparacion';

interface IComparacionConteoDetallesTablaProps {
    detallesConteo: IAuditoriaConteoDetalleComparacion[];
    camposOcultar?: Array<keyof IAuditoriaConteoDetalleComparacion>;
    recargarTabla: () => any;
    descripcionPrimerConteo: string;
    descripcionSegundoConteo: string;
    descargarConteo: () => any;
}

const ComparacionConteoDetallesTabla: FunctionComponent<IComparacionConteoDetallesTablaProps> = ({
    detallesConteo: detallesConteoProp,
    camposOcultar = [],
    recargarTabla,
    descripcionPrimerConteo,
    descripcionSegundoConteo,
    descargarConteo,
}) => {
    const obtenerColumnas = () => {
        const columnas: Array<Column<IAuditoriaConteoDetalleComparacion>> = [];
        columnas.push({
            field: 'CodigoBarras',
            title: <Texto id="TablaComparacionConteoDetalles.CodigoBarras" />,
        });
        columnas.push({
            field: 'DescripcionItem',
            title: <Texto id="TablaComparacionConteoDetalles.DescripcionItem" />,
        });
        columnas.push({
            field: 'DescripcionDivision',
            title: <Texto id="TablaComparacionConteoDetalles.DescripcionDivision" />,
        });
        columnas.push({
            field: 'CantidadStock',
            title: <Texto id="TablaComparacionConteoDetalles.Stock" />,
        });
        columnas.push({
            field: 'CantidadPrimerConteo',
            title: `Cantidad "${descripcionPrimerConteo}"`,
        });
        if (!camposOcultar.includes('CantidadSegundoConteo'))
            columnas.push({
                field: 'CantidadSegundoConteo',
                title: `Cantidad "${descripcionSegundoConteo}" `,
            });
        columnas.push({
            field: 'DiferenciaStockPrimerConteo',
            title: `Diferencia Stock vs "${descripcionPrimerConteo}"`,
        });
        if (!camposOcultar.includes('DiferenciaStockSegundoConteo'))
            columnas.push({
                field: 'DiferenciaStockSegundoConteo',
                title: `Diferencia Stock vs "${descripcionSegundoConteo}"`,
            });
        if (!camposOcultar.includes('DiferenciaConteos'))
            columnas.push({
                field: 'DiferenciaConteos',
                title: <Texto id="TablaComparacionConteoDetalles.DiferenciaConteos" />,
            });
        columnas.push({
            render: (detalle: IAuditoriaConteoDetalleComparacion) => (detalle.Serial ? detalle.Serial : <RemoveIcon />),
            field: 'Serial',
            title: <Texto id="TablaComparacionConteoDetalles.Serial" />,
        });
        columnas.push({
            render: (detalle: IAuditoriaConteoDetalleComparacion) => (detalle.Lote ? detalle.Lote : <RemoveIcon />),
            field: 'Lote',
            title: <Texto id="TablaComparacionConteoDetalles.Lote" />,
        });
        columnas.push({
            render: (detalle: IAuditoriaConteoDetalleComparacion) =>
                detalle.FechaVencimiento ? moment(detalle.FechaVencimiento).format('DD/MM/YYYY') : <RemoveIcon />,
            field: 'FechaVencimiento',
            title: <Texto id="TablaComparacionConteoDetalles.FechaVencimiento" />,
        });
        columnas.push({
            render: (detalle: IAuditoriaConteoDetalleComparacion) =>
                detalle.FechaIngreso ? moment(detalle.FechaIngreso).format('DD/MM/YYYY') : <RemoveIcon />,
            field: 'FechaIngreso',
            title: <Texto id="TablaComparacionConteoDetalles.FechaIngreso" />,
        });
        return columnas;
    };

    return (
        <>
            <Card variant="outlined">
                <CardHeader
                    title={<Texto id="TablaComparacionConteoDetalles.DetallesConteo" />}
                    titleTypographyProps={{ variant: 'body1' }}
                />
                <Tabla
                    actions={[
                        { icon: () => <RefreshIcon color="action" />, isFreeAction: true, onClick: recargarTabla },
                        {
                            icon: () => <CloudDownloadIcon color="action" />,
                            isFreeAction: true,
                            onClick: descargarConteo,
                        },
                    ]}
                    columns={obtenerColumnas()}
                    data={detallesConteoProp}
                    options={{ padding: 'dense' }}
                />
            </Card>
        </>
    );
};

export default ComparacionConteoDetallesTabla;
