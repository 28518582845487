import { Grid, IconButton, Tooltip } from '@material-ui/core';
import {
    Check as CheckIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    Remove as RemoveIcon,
    Visibility as VisibilityIcon,
} from '@material-ui/icons';
import React, { FunctionComponent } from 'react';

import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';

import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';

interface ITiposDocumentoTablaProps {
    crearTipoDocumento: () => any;
    editarTipoDocumento: (tipoDocumento: ITipoDocumento) => any;
    eliminarTipoDocumento: (tipoDocumentoId: number) => any;
    recargarTabla: () => any;
    tiposDocumento: ITipoDocumento[];
    verTipoDocumento: (tipoDocumento: ITipoDocumento) => any;
}

const TiposDocumentoTabla: FunctionComponent<ITiposDocumentoTablaProps> = ({
    crearTipoDocumento,
    editarTipoDocumento,
    eliminarTipoDocumento,
    tiposDocumento,
    recargarTabla,
    verTipoDocumento,
}) => {
    return (
        <Tabla
            actions={[
                { icon: 'refresh', isFreeAction: true, onClick: recargarTabla },
                { icon: 'add', isFreeAction: true, onClick: crearTipoDocumento },
            ]}
            columns={[
                {
                    render: (tipoDocumento: ITipoDocumento) => (
                        <Grid container wrap="nowrap">
                            <Grid item>
                                <Tooltip title={<Texto id="TiposDocumentoTabla.VerTipoDocumento" />}>
                                    <IconButton onClick={() => verTipoDocumento(tipoDocumento)} size="small">
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={<Texto id="TiposDocumentoTabla.EditarTipoDocumento" />}>
                                    <IconButton onClick={() => editarTipoDocumento(tipoDocumento)} size="small">
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={<Texto id="TiposDocumentoTabla.EliminarTipoDocumento" />}>
                                    <IconButton
                                        onClick={() => eliminarTipoDocumento(tipoDocumento.TipoDocumentoId)}
                                        size="small"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ),
                    title: <Texto id="TiposDocumentoTabla.Acciones" />,
                },
                {
                    field: 'TipoDocumentoDescripcion',
                    title: <Texto id="TiposDocumentoTabla.TipoDocumentoDescripcion" />,
                },
                {
                    field: 'Abreviatura',
                    title: <Texto id="TiposDocumentoTabla.Abreviatura" />,
                },
                {
                    field: 'Consecutivo',
                    title: <Texto id="TiposDocumentoTabla.Consecutivo" />,
                },
                {
                    field: 'SincronizaMovil',
                    title: <Texto id="TiposDocumentoTabla.SincronizaMovil" />,
                    type: 'boolean',
                },
                {
                    render: (tipoDocumento: ITipoDocumento) =>
                        tipoDocumento.Estado === 1 ? <CheckIcon /> : <RemoveIcon />,
                    title: <Texto id="TiposDocumentoTabla.Estado" />,
                },
            ]}
            data={tiposDocumento}
            options={{ padding: 'dense' }}
        />
    );
};

export default TiposDocumentoTabla;
