import ModeloMunicipios from 'Infotrack@Modelos/administracion/municipio';
import ModeloBodega from 'Infotrack@Modelos/smartStock/bodegas';
import ModeloBodegasTiposPropiedades from 'Infotrack@Modelos/smartStock/bodegasTiposPropiedades';
import ModeloFamilias from 'Infotrack@Modelos/smartStock/familias';
import ModeloProductos from 'Infotrack@Modelos/smartStock/gestionProducto';
import IProductoPrincipal from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/productoPrincipal';
import ModeloTipoBodega from 'Infotrack@Modelos/smartStock/tiposBodegas';
import ModeloUnidades from 'Infotrack@Modelos/smartStock/unidades';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';

export default class EntidadesBodegaCM {
    private modeloBodega: ModeloBodega;
    private modeloBodegasTiposPropiedades: ModeloBodegasTiposPropiedades;
    private modeloFamilias: ModeloFamilias;
    private modeloMunicipios: ModeloMunicipios;
    private modeloProductos: ModeloProductos;
    private modeloTipoBodega: ModeloTipoBodega;
    private modeloUnidades: ModeloUnidades;

    constructor() {
        this.modeloBodega = new ModeloBodega();
        this.modeloBodegasTiposPropiedades = new ModeloBodegasTiposPropiedades();
        this.modeloFamilias = new ModeloFamilias();
        this.modeloMunicipios = new ModeloMunicipios();
        this.modeloProductos = new ModeloProductos();
        this.modeloTipoBodega = new ModeloTipoBodega();
        this.modeloUnidades = new ModeloUnidades();
    }

    public consultarEntidadBodega(EmpresaId: string, IdAgencia: string | null) {
        return Promise.all([
            this.modeloBodega.consultarListaBodegasCompuesta(IdAgencia),
            this.modeloMunicipios.consultarListaMunicipios(),
            this.modeloTipoBodega.consultarTiposBodegasPorFiltro({ EmpresaId,Estado:estadoActivo }),
        ]).then((entidades) => ({
            bodegas: entidades[0].data.Entidades,
            municipios: entidades[1].data.Entidades,
            tiposBodega: entidades[2].data.Entidades,
        }));
    }

    public async consultarEntidadBodegaPropiedades(EmpresaId: string) {
        const entidades = await Promise.all([
            this.modeloFamilias.consultarListaFamiliasPorFiltro({ EmpresaId }),
            this.modeloUnidades.consultarUnidadesFiltro({ EmpresaId }),
            this.modeloProductos.consultarListaItemsPorFiltro({ EmpresaId }),
            this.modeloBodegasTiposPropiedades.consultarBodegasTiposPropiedades(),
        ]);
        return {
            familias: entidades[0].data.Entidades,
            unidades: entidades[1].data.Entidades,
            productos: entidades[2].data.Entidades as IProductoPrincipal[],
            bodegasTiposPropiedades: entidades[3].data.Entidades,
        };
    }
}
