import { createStyles, Theme } from '@material-ui/core';

export const estilos = ({ transitions, spacing }: Theme) =>
  createStyles({
    barraNavegacion: {
      transition: transitions.create(['margin', 'width'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
    barraNavegacionMenuExpandido: {
      width: `calc(100% - ${240}px)`,
      marginLeft: 240,
      transition: transitions.create(['margin', 'width'], {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
    },
    botonMenu: {
      marginRight: spacing(2),
    },
    botonMenuOculto: {
      display: 'none',
    },
  });
