import ModeloBodegas from 'Infotrack@Modelos/smartStock/bodegas';
import IBodegaPersonaFiltro from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaPersonaFiltro';
import ModeloBodegasDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones';
import ModeloFamilia from 'Infotrack@Modelos/smartStock/familias';
import ModeloProducto from 'Infotrack@Modelos/smartStock/gestionProducto';

export default class EntidadesDocumentoCM {
    private modeloBodegas: ModeloBodegas;
    private modeloBodegasDivisiones: ModeloBodegasDivisiones;
    private modeloFamilia: ModeloFamilia;
    private modeloProducto: ModeloProducto;
    constructor() {
        this.modeloBodegas = new ModeloBodegas();
        this.modeloBodegasDivisiones = new ModeloBodegasDivisiones();
        this.modeloFamilia = new ModeloFamilia();
        this.modeloProducto = new ModeloProducto();
    }

    public consultarBodegasDocumento(bodegaPersonaFiltro: IBodegaPersonaFiltro) {
        return this.modeloBodegas
            .consultarBodegasPersonasDoc(bodegaPersonaFiltro)
            .then(({ data: { Entidades } }) => Entidades);
    }

    public consultarDivisionesPorBodega(BodegaId: number) {
        return this.modeloBodegasDivisiones
            .consultarDivisionesFiltro({ BodegaId, Estado: 1 })
            .then(({ data: { Entidades } }) => Entidades);
    }

    public consultaEntidadesFiltro(EmpresaId: string) {
        return Promise.all([
            this.modeloProducto.consultarListaItems(),
            this.modeloFamilia.consultarListaFamiliasPorFiltro({ EmpresaId }),
        ]).then((entidades) => ({
            items: entidades[0].data.Entidades,
            familias: entidades[1].data.Entidades,
        }));
    }
}
