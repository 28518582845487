import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import IDocumentoUsuarioLista from 'Infotrack@Modelos/smartStock/gestionOperadores/entidades/documentoUsuarioLista';
import AbreviaturasTiposDocumentos from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/abreviaturasTiposDocumento';
import { IRefSelectMasivo } from 'Infotrack@Transversales/componentes/selectMasivoItems';
import React from 'react';
import DocumentoCM from '../controladorModelo/documentoCM';
import DocumentoDetalleCM from '../controladorModelo/documentoDetalleCM';
import { acciones, IEstadoGestionMovimientos } from '../reductorGestionMovimientos';
import TablaDocumentoDetallesVista from '../vista/tablaDocumentoDetallesVista';
import IDocumentosUsuarios from 'Infotrack@Modelos/smartStock/gestionOperadores/entidades/documentosUsuarios';
import Usuario from 'Infotrack@Modulos/administracion/usuario';
import usuario from 'Infotrack@Transversales/internacionalizacion/idiomas/en_US/usuario';

const documentoCM = new DocumentoCM();
const documentoDetalleCM = new DocumentoDetalleCM();

const TablaDocumentoDetallesCV: React.FunctionComponent = () => {
    const [
        { documento, documentosDetalles, estadoFormularioDetalle, entidadDocumento, tipoFormularioDocumento },
        dispatch,
    ] = useProveedor<IEstadoGestionMovimientos>();
    const ref = React.useRef<IRefSelectMasivo>();

    React.useEffect(() => {
        if (entidadDocumento) cargarDetalles();
    }, []);

    React.useEffect(() => {
        if (documento.TipoDocumento && ref.current) ref.current.reiniciarEstado();
    }, [documento.TipoDocumento]);

    const alAbrirFormulario = (documentoDetalle?: IDocumentoUsuarioLista) => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
        dispatch({ type: acciones.ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE, payload: documentoDetalle });
    };

    const cargarDetalles = async () => {
        dispatch({
            type: acciones.CARGAR_DOCUMENTOS_DETALLES,
            payload: entidadDocumento!.Usuarios,
        });
    };

    const alEliminarDocumentoDetalle = (documentoDetalle: IDocumentoUsuarioLista) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => {
                if (tipoFormularioDocumento !== TipoFormulario.Creacion) {
                    documentoDetalleCM
                        .eliminarDocumentosUsuarios({
                            DocumentoId: entidadDocumento!.DocumentoId!,
                            EmpresaId: '',
                            DocumentoUsuarioId: documentoDetalle.DocumentoUsuarioId,
                            UsuarioId: documentoDetalle.UsuarioId,
                        })
                        .then((data) => {
                            if (data) {
                                dispatch({ type: acciones.ELIMINAR_DOCUMENTO_DETALLE, payload: documentoDetalle });
                                dispatch({ type: acciones.RECARGAR_TABLA });
                            }
                        });
                }

                manejadorDialogoGlobal({ estado: false });
            },
            estado: true,
            mensaje: <Texto id="alerta.confirmacionEliminacion" />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const manejadorSeleccionDocumentoDetalle = async (documentoDetalleSeleccionado: IDocumentoUsuarioLista) => {
        const documentoUsuario: IDocumentosUsuarios = {
            DocumentoUsuarioId: documentoDetalleSeleccionado.DocumentoUsuarioId,
            UsuarioId: documentoDetalleSeleccionado.UsuarioId,
            DocumentoId: entidadDocumento!.DocumentoId!,
            EmpresaId: entidadDocumento!.EmpresaId!,
            FinalizacionProceso: true,
        };
        await documentoDetalleCM.editarListaDocumentosUsuarios(documentoUsuario);
        const copiaDocumentosDetalles = [...documentosDetalles];
        copiaDocumentosDetalles.forEach((documentoDetalle) => {
            if (documentoDetalle.UsuarioId === documentoDetalleSeleccionado.UsuarioId)
                documentoDetalle.FinalizacionProceso = true;
            else documentoDetalle.FinalizacionProceso = false;
        });
        dispatch({ payload: copiaDocumentosDetalles, type: acciones.CARGAR_DOCUMENTOS_DETALLES });
        dispatch({ type: acciones.RECARGAR_TABLA });
    };

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
    };

    const alModificarDetalles = (nuevosDocumentosDetalles: any[]) => {
        let errorDestino = false;
        nuevosDocumentosDetalles.forEach((element) => {
            if (!element.DivisionDestinoId) {
                errorDestino = true;
            }
        });
        if (errorDestino) {
            notificacionGlobal('gestionmovimientos.alerta.destino', 6000, 'warning', true);
        } else {
            alCerrarFormulario();
            if (
                documento.TipoDocumento &&
                documento.TipoDocumento.TipoDocumentoAbreviatura === AbreviaturasTiposDocumentos.SAL
            )
                dispatch({
                    type: acciones.CARGAR_DOCUMENTOS_DETALLES,
                    payload: nuevosDocumentosDetalles.map((docDetalle) => ({
                        ...docDetalle,
                        DivisionDestino: docDetalle.DivisionOrigen,
                        DescripcionDivisionDestino: docDetalle.DescripcionDivisionOrigen,
                    })),
                });
            else dispatch({ type: acciones.CARGAR_DOCUMENTOS_DETALLES, payload: nuevosDocumentosDetalles });
        }
    };

    return (
        <TablaDocumentoDetallesVista
            alAbrirFormulario={alAbrirFormulario}
            alEliminarDocumentoDetalle={alEliminarDocumentoDetalle}
            deshabilitarEdicion={
                documento.DocumentoPadre ||
                tipoFormularioDocumento === TipoFormulario.Visualizacion ||
                documento.EtapaDescripcion === 'Cerrada' ||
                documento.EtapaDescripcion === 'Almacenado' ||
                documento.EtapaDescripcion === 'Anulado'
                //|| entidadDocumento!.Usuarios.every((usuario) => usuario.FinalizacionProceso === true) === true
            }
            documentosDetalles={documentosDetalles}
            manejadorSeleccionDocumentoDetalle={manejadorSeleccionDocumentoDetalle}
        />
    );
};

export default TablaDocumentoDetallesCV;
