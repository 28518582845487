import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoAccion } from '@infotrack/presentacion-transversales/interfacesComunes';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import IFiltrosDocumentoDetallesAuditoria from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/filtrosDocumentoDetallesAuditoria';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import { IInputsFiltrosDetallesAuditoria } from 'Infotrack@Modulos/procesos/auditorias/interfaces';
import React from 'react';
import DocumentoCM from '../controladorModelo/documentoCM';
import DocumentoDetalleCM from '../controladorModelo/documentoDetalleCM';
import EntidadesDocumentoDetalleCM from '../controladorModelo/entidadesDocumentoDetalleCM';
import MovimientoCM from '../controladorModelo/movimientoCM';
import { acciones, IEstadoGestionAuditorias } from '../reductorGestionAuditorias';
import TablaEdicionDetallesAuditoriasVista from '../vista/tablaEdicionDetallesAuditoriasVista';

const documentoCM = new DocumentoCM();
const documentoDetalleCM = new DocumentoDetalleCM();
const entidadesDocumentoDetalleCM = new EntidadesDocumentoDetalleCM();
const movimientoCM = new MovimientoCM();
const valorInicialFiltros: IInputsFiltrosDetallesAuditoria = {
    DivisionId: '',
    CodigoBarras: '',
    DescripcionItem: '',
};

const TablaEdicionDetallesAuditoriasCV: React.FunctionComponent = () => {
    const [
        { divisiones, divisionPorDefecto, documento, estadoTablaDetalles, recargarTablaDetalles },
        dispatch,
    ] = useProveedor<IEstadoGestionAuditorias>();
    const { alCambiarValor, reiniciar, valor } = useInputState(valorInicialFiltros);
    const [generarDiferencias, setGenerarDiferencias] = React.useState<boolean>(false);

    const refTabla = React.createRef<any>();
    const refTablaMovimientos = React.createRef<any>();

    React.useEffect(() => {
        if (documento && estadoTablaDetalles) {
            alCargarDivisiones();
        }
    }, [estadoTablaDetalles]);

    React.useEffect(() => {
        if (estadoTablaDetalles) validarDivisionPorDefecto();
    }, [estadoTablaDetalles]);

    React.useEffect(() => {
        if (recargarTablaDetalles) {
            if (generarDiferencias) cargarMovimientos();
            cargarDocumentosDetalles();
            dispatch({ type: acciones.RECARGAR_TABLA_DETALLES });
        }
    }, [recargarTablaDetalles]);

    const validarDivisionPorDefecto = () => {
        documentoDetalleCM
            .consultarDivisionDocumentoAuditoria({ DocumentoId: documento!.DocumentoId } as any)
            .then((docDetalle) => {
                dispatch({
                    type: acciones.ESTABLECER_DIVISION_POR_DEFECTO,
                    payload: docDetalle ? docDetalle.DivisionOrigen : undefined,
                });
            });
    };

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_TABLA_DETALLE });
        setGenerarDiferencias(false);
    };

    const mapearFiltros = (): IFiltrosDocumentoDetallesAuditoria => ({
        ...valor,
        DocumentoId: documento ? documento.DocumentoId! : '',
        DivisionId: valor.DivisionId ? Number(valor.DivisionId) : undefined,
    });

    const consultaDetallesAuditoria = paginacionRemota(
        documentoDetalleCM.consultarListaDocumentosDetallesAuditoria,
        mapearFiltros(),
        'DescripcionItem'
    );

    const consultaMovimientos = paginacionRemota(
        movimientoCM.consultarMovimientosSinDocumentoDetalle,
        {
            BodegaId: documento ? documento!.BodegaOrigen : 0,
            DocumentoId: documento ? documento.DocumentoId! : '',
            DivisionId: divisionPorDefecto,
        },
        'DescripcionItem'
    );

    const cargarDocumentosDetalles = (limpiarFiltros?: boolean) => {
        if (limpiarFiltros) reiniciar();
        refTabla.current!.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
    };

    const cargarMovimientos = () => {
        refTablaMovimientos.current!.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
    };

    const alAbrirFormularioDetalle = (documentoDetalle?: IDocumentoDetalleCompuesto) => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
        dispatch({ type: acciones.ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE, payload: documentoDetalle });
    };

    const alCargarDivisiones = async () => {
        dispatch({
            type: acciones.CARGAR_DIVISIONES,
            payload: await entidadesDocumentoDetalleCM.consultarListaDivisiones(documento!.BodegaOrigen!),
        });
    };

    const confimarEliminacionAuditoria = (documentoDetalle: IDocumentoDetalleCompuesto) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => alEliminarDetalle(documentoDetalle),
            estado: true,
            mensaje: <Texto id="alerta.confirmacionEliminacion" />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const alEliminarDetalle = (documentoDetalle: IDocumentoDetalleCompuesto) => {
        manejadorDialogoGlobal({ estado: false });
        documentoDetalleCM
            .editarDocumentosDetallesAuditoria({
                Documento: documento!,
                DocumentosDetalles: [{ ...documentoDetalle, Accion: TipoAccion.eliminado }],
            })
            .then(() => {
                dispatch({ type: acciones.RECARGAR_TABLA_DETALLES });
            });
    };

    const confirmarCambioDeEstadoAuditoria = () => {
        if (documento) {
            manejadorDialogoGlobal({
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                accionConfirmar: () => alModificarEstadoAuditoria(),
                estado: true,
                mensaje: <Texto id={obtenerDescripcionCambiarEtapa()} />,
                mostrarCancelar: true,
                tipoDialogo: 'Advertencia',
                titulo: <Texto id="titulo.advertencia" />,
            });
        }
    };

    const obtenerDescripcionCambiarEtapa = () => {
        if (documento!.EtapaDescripcion === TiposEtapas.Creada) return 'gestionauditorias.alerta.primerConteo';
        else if (
            documento &&
            documento.EtapaDescripcion === TiposEtapas.Conteo &&
            documento.BodegaOrigen &&
            documento.BodegaDestino
        )
            return 'gestionauditorias.alerta.segundoConteo';
        else return 'gestionauditorias.alerta.finalizarConteo';
    };

    const alModificarEstadoAuditoria = () => {
        manejadorDialogoGlobal({ estado: false });
        documentoCM.modificarEstadoAuditoria(documento!).then(async () => {
            dispatch({ type: acciones.RECARGAR_TABLA });
            dispatch({
                type: acciones.ESTABLECER_DOCUMENTO,
                payload: await documentoCM.consultaGeneralDocumentosFiltro({ DocumentoId: documento!.DocumentoId }),
            });
        });
    };

    const alGenerarDiferencias = () => {
        setGenerarDiferencias(true);
    };

    return (
        <TablaEdicionDetallesAuditoriasVista
            alCambiarValor={alCambiarValor}
            alAbrirFormularioDetalle={alAbrirFormularioDetalle}
            alCerrarFormulario={alCerrarFormulario}
            alEliminarDetalle={confimarEliminacionAuditoria}
            alGenerarDiferencias={alGenerarDiferencias}
            confirmarCambioDeEstadoAuditoria={confirmarCambioDeEstadoAuditoria}
            consultaDetallesAuditoria={consultaDetallesAuditoria}
            consultaMovimientos={consultaMovimientos}
            consultarDetallesAuditoria={cargarDocumentosDetalles}
            consultarMovimientos={cargarMovimientos}
            documento={documento}
            divisiones={divisiones}
            esUnicoConteo={documento ? documento.BodegaOrigen === null || documento.BodegaDestino === null : false}
            estadoFormularioDetalles={estadoTablaDetalles}
            filtros={valor}
            generarDiferencias={generarDiferencias}
            refTabla={refTabla}
            refTablaMovimientos={refTablaMovimientos}
        />
    );
};

export default TablaEdicionDetallesAuditoriasCV;
