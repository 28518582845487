import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ITablaDivisionesBodegaVistaProps } from '../interfaces';
import { accionesDivisionesBodega, columnasDivisionesBodega, opcionesDivisionesBodega } from './constantes';

const TablaDivisionesBodegaVista: React.FunctionComponent<ITablaDivisionesBodegaVistaProps & InjectedIntlProps> = ({
    alAbrirFormulario,
    alEliminarDivisiones,
    alRecargar,
    divisiones,
    intl: { formatMessage },
}) => (
    <Tabla
        actions={accionesDivisionesBodega(alAbrirFormulario, alEliminarDivisiones, formatMessage, alRecargar)}
        columns={columnasDivisionesBodega(formatMessage)}
        data={divisiones}
        style={{ width: '100%' }}
        options={opcionesDivisionesBodega}
    />
);

export default injectIntl(TablaDivisionesBodegaVista);
