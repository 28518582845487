import { Column } from '@infotrack/presentacion-componentes/tabla';
import IDocumentoCompuesto from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuesto';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

export const columnas = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IDocumentoCompuesto>> => [
    { field: 'CodigoDocumento', title: formatMessage({ id: 'monitorPedidos.CodigoDocumento' }) },
    {
        field: 'FechaCreacion',
        title: formatMessage({ id: 'monitorPedidos.FechaCreacion' }),
        render: (data: any) => moment(data.FechaCreacion).format('DD/MM/YYYY'),
    },
    { field: 'DescripcionCliente', title: formatMessage({ id: 'monitorPedidos.DescripcionCliente' }) },
    { field: 'Observacion', title: formatMessage({ id: 'monitorPedidos.Observacion' }) },
];
