import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';
import { negocio } from '../../conexiones';
import IBodegaDivisionDefectoFiltro from './entidades/bodegaDivisionDefectoFiltro';
import IBodegaDivisionFiltros from './entidades/bodegaDivisionFiltros';
import IBodegaDivisionInventario from './entidades/bodegaDivisionInventario';
import IBodegaDivisionInventarioFiltros from './entidades/bodegaDivisionInventarioFiltros';
import IDivisionTraslado from './entidades/bodegaDivisionTraslado';
import IBodegaDivisiones from './entidades/bodegasDivisiones';
import IBodegasDivisionesPropiedades from './entidades/bodegasDivisionesPropiedades';

export default class ModeloBodegaDivisiones {
    public guardarBodegaDivisionesPropiedades(bodegaDivisionesProp: IBodegasDivisionesPropiedades) {
        return manejadorRest<IRespuesta<IBodegasDivisionesPropiedades>>(
            () => negocio.post('Division/GuardarDivisionCompuestaPropiedades', bodegaDivisionesProp),
            true
        );
    }

    public editarBodegaDivisionesPropiedades(bodegaDivisionesProp: IBodegasDivisionesPropiedades) {
        return manejadorRest<IRespuesta<IBodegasDivisionesPropiedades>>(
            () => negocio.put('Division/EditarDivisionCompuestaPropiedades', bodegaDivisionesProp),
            true
        );
    }

    public eliminarDivisionesCompuesto(bodegaDivisiones: IBodegaDivisiones) {
        return manejadorRest<IRespuesta<IBodegasDivisionesPropiedades>>(
            () => negocio.delete('Division/EliminarDivisionCompuesto', bodegaDivisiones),
            true
        );
    }

    public consultarDivisionesFiltro(divisionFiltro: IBodegaDivisionFiltros) {
        return manejadorRest<IRespuesta<IBodegaDivisiones>>(
            () => negocio.post('Division/ConsultarDivisionCompuestaFiltro', divisionFiltro),
            true
        );
    }

    public consultarDivisionesDocumentosDef(bodegaDivisionDefectoFiltro: IBodegaDivisionDefectoFiltro) {
        return manejadorRest<IRespuesta<IBodegaDivisiones>>(
            () => negocio.post('Division/ConsultarDivisionesDocumentosDef', bodegaDivisionDefectoFiltro),
            true
        );
    }

    public consultarDivisionesPorItemEnInventario(filtros: IBodegaDivisionInventarioFiltros) {
        return manejadorRest<IRespuesta<IBodegaDivisionInventario>>(
            () => negocio.post('Division/ConsultarDivisionPorItemEnInventario', filtros),
            true
        );
    }

    public consultarDivisionesAuditoria(idAuditoria: string) {
        return manejadorRest<IRespuesta<IBodegaDivisiones>>(
            () => negocio.get(`Division/ConsultarDivisionesAuditoria/${idAuditoria}`),
            true
        );
    }

    public consultarDivisionesTrasladoDocumentosDef(divisionTraslado: IDivisionTraslado) {
        return manejadorRest<IRespuesta<IBodegaDivisiones>>(
            () => negocio.post('Division/ConsultarListaDivisionesTraslado', divisionTraslado),
            true
        );
    }

    public consultarDivisionesListaFiltro(divisionTraslado: Partial<IBodegaDivisiones>) {
        return manejadorRest<IRespuesta<IBodegaDivisiones>>(
            () => negocio.post('Division/ConsultarListaFiltro', divisionTraslado),
            true
        );
    }
}
