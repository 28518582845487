import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';

interface ICampoBodegaDestinoProps {
    bodegas: IBodega[];
    deshabilitado: boolean;
    manejarCambioCampo: (nombreCampo: keyof IDocumento, nuevoValor: IDocumento[keyof IDocumento]) => any;
    valor?: number;
}

const CampoBodegaDestino: FunctionComponent<ICampoBodegaDestinoProps> = ({
    bodegas,
    deshabilitado,
    manejarCambioCampo,
    valor,
}) => (
    <Autocomplete
        disabled={deshabilitado}
        getOptionLabel={(b) => b.BodegaDescripcion}
        onChange={(_: ChangeEvent<{}>, bodega: IBodega | null) => {
            manejarCambioCampo('BodegaDestino', bodega ? bodega.BodegaId! : 0);
        }}
        options={bodegas}
        renderInput={(params) => (
            <TextField
                {...params}
                fullWidth
                label={<Texto id="FormularioDocumento.CampoBodegaDestino.Nombre" />}
                required
            />
        )}
        value={bodegas.find((b) => b.BodegaId === valor) || null}
    />
);

export default CampoBodegaDestino;
