const mensajes = {
    // EntradaInventario
    'EntradaInventario.ValidacionNoTipoDocumentoId': 'You must select a document type',
    'EntradaInventario.ValidacionNoProveedorId': 'You must select a provider',
    'EntradaInventario.ValidacionNoBodegaDestino': 'You must select a destination warehouse',
    'EntradaInventario.ValidacionCantidadRecibidaCero': 'Received quantity must be greater than 0',
    'EntradaInventario.ValidacionPesoFraccionCero': 'The weight received must be greater than 0',
    // ModalEntradaInventario
    'ModalEntradaInventario.TituloCreacion': 'Create Entry Inventory',
    'ModalEntradaInventario.TituloEdicion': 'Edit Entry Inventory',
    'ModalEntradaInventario.TituloVisualizacion': 'View Entry Inventory',
    'ModalEntradaInventario.TituloTablaDetalleDocumento': 'Document Details',
    'ModalEntradaInventario.TituloTablaDetalleDocumentoBase': 'Base Document Details',
    'permiso.entradainventario': 'Access',
};

export default mensajes;
