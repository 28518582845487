const mensajes = {
    // TablaDocumentos
    'TablaDocumentos.Acciones': 'Acciones',
    'TablaDocumentos.VerDocumento': 'Ver documento',
    'TablaDocumentos.EditarDocumento': 'Editar documento',
    'TablaDocumentos.AnularDocumento': 'Anular documento',
    'TablaDocumentos.DescripcionTipoDocumentoId': 'Tipo documento',
    'TablaDocumentos.DescripcionEtapaId': 'Etapa',
    'TablaDocumentos.DescripcionProveedorId': 'Proveedor',
    'TablaDocumentos.CodigoDocumento': 'Código',
    'TablaDocumentos.DireccionEntrega': 'Dirección entrega',
    'TablaDocumentos.FechaEntrega': 'Fecha entrega',
    'TablaDocumentos.CondicionComercial': 'Condición comercial',
    'TablaDocumentos.DescripcionBodegaOrigen': 'Bodega origen',
    'TablaDocumentos.DescripcionBodegaDestino': 'Bodega destino',
    'TablaDocumentos.DocumentoRelacionado': 'Documento relacionado',
    'TablaDocumentos.Observacion': 'Observación',
    'TablaDocumentos.PersonaRelacionada': 'Persona relacionada',
    'TablaDocumentos.CodigoDocumentoPredecesor': 'Código documento predecesor',
    'TablaDocumentos.FechaCreacion': 'Fecha creación',
    'TablaDocumentos.DescripcionClienteId': 'Cliente',
    'TablaDocumentos.DescripcionEstado': 'Estado',
    'TablaDocumentos.DescargarDocumento': 'Visualizar PDF',
    'TablaDocumentos.OrigenExterno': 'Origen externo',
    'TablaDocumentos.NumeroTicket': 'Número Ticket',
    'TablaDocumentos.NumeroGuia': 'Numero Guia',
};

export default mensajes;
