import ProveedorEstado from '@infotrack/presentacion-componentes/proveedorEstado';
import React from 'react';
import FormularioDevolucionesCV from './controladorVista/formularioDevolucionesCV';
import TablaDevolucionesCV from './controladorVista/tablaDevolucionesCV';
import { estadoInicial, reductor } from './reductorDevoluciones';

const Devoluciones: React.FunctionComponent = () => (
    <ProveedorEstado estadoInicial={estadoInicial} reductor={reductor}>
        <TablaDevolucionesCV />
        <FormularioDevolucionesCV />
    </ProveedorEstado>
);

export default Devoluciones;
