export default {
  'sidebar.administracion': 'Administración',
  'sidebar.tipoidentificacion': 'Tipo Identificación',
  'tipoIdentificacion.agregarTipoIdentificacion': 'Crear Tipo Identificación',
  'tipoIdentificacion.confirmacionCreacion': '¿Está seguro que desea crear el registro?',
  'tipoIdentificacion.confirmacionEdicion': '¿Está seguro que desea editar el registro?',
  'tipoIdentificacion.confirmacionEliminacion': '¿Está seguro que desea eliminar el registro?',
  'tipoIdentificacion.modificarTipoIdentificacion': 'Editar Tipo Identificación',
  'tipoIdentificacion.abreviatura': 'Abreviatura',
  'tipoIdentificacion.nombreTipoIdentificacion': 'Nombre Tipo Identificación',
  'tipoIdentificacion.estadoTipoIdentificacion': 'Estado'
};
