import moment from 'moment';

import ModeloMovimiento from 'Infotrack@Modelos/negocioRefactor/modelos/Movimiento/ModeloMovimiento';
import IMovimientoItemPesoConsulta from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoItemPesoConsulta';
import IMovimientoItemBodegaConsulta from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoItemBodegaConsulta';

class MovimientoCM {
    private modeloMovimiento: ModeloMovimiento;

    constructor() {
        this.modeloMovimiento = new ModeloMovimiento();
    }

    public async stockItem(idBodega: number, idItem: number) {
        const respuesta = await this.modeloMovimiento.stockItem(idBodega, idItem);
        return respuesta.data.Entidades.map((itemInventario, indice) => ({
            ...itemInventario,
            ItemInventarioId: indice,
        }));
    }

    public async consultarItemSerial(idBodega: number, idItem: number, idEmpresa: string,) {
        const respuesta = await (
            await this.modeloMovimiento.consultarMovimientosItemSerial(idBodega, idItem, idEmpresa)
        ).data.Entidades;
        return respuesta;
    }

    public async consultarItemSerialPeso(movimientoConsulta: IMovimientoItemPesoConsulta) {
        const respuesta = await (
            await this.modeloMovimiento.consultarMovimientosItemSerialPeso(movimientoConsulta)
        ).data.Entidades;
        return respuesta;
    }

    public async consultarItemBodegaConsulta(movimientoConsulta: Partial<IMovimientoItemBodegaConsulta>) {
        const respuesta = await (
            await this.modeloMovimiento.consultarMovimientosItemBodega(movimientoConsulta)
        ).data.Entidades;
        return respuesta;
    }
}

export default MovimientoCM;
