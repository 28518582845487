export default {
  'sidebar.administracion': 'Administración',
  'sidebar.usuariospersona': 'Usuarios',
  'sidebar.agencias': 'Agencias',
  'sidebar.aplicacionestilo': 'Aplicacion Estilo',
  'aplicacionEstilo.agregarAplicacionEstilo': 'Crear Aplicación Estilo',
  'aplicacionEstilo.confirmacionCreacion': '¿Está seguro que desea crear el registro?',
  'aplicacionEstilo.confirmacionEdicion': '¿Está seguro que desea editar el registro?',
  'aplicacionEstilo.confirmacionEliminacion': '¿Está seguro que desea eliminar el registro?',
  'aplicacionEstilo.modificarAplicacionEstilo': 'Editar Aplicacion Estilo',
  'aplicacionEstilo.nombreEstilo': 'Nombre Estilo',
};
