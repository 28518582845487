import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    TextField,
    Toolbar,
} from '@material-ui/core';
import Tabla from '@infotrack/presentacion-componentes/tabla';
import VistaDocumentoDetalle from '../../../../transversales/componentes/tablaDocumentoDetalle/Vista/tablaDocumentoDetalleVista';
import Texto from '@infotrack/presentacion-componentes/texto';
import VistaDocumentoDetalles from '../../../../transversales/componentes/documentoDetalles/cardDocumentoDetallesVista';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { IModalDetalleDocumentoVistaVistaProps } from '../interfaces';
import FormularioEdicionDetalleAlmacenamientoVista from '../controladorVista/formularioEdicionDetalleAlmacenamientoCV';
import { makeStyles } from '@material-ui/core';
import { accionesTablaDetalleDocumento, columnasTablaDetalleDocumento } from './constantes';
const useStyles = makeStyles({
    listbox: {
        padding: '8px',
        '&:last-child': {
            paddingBottom: '8px',
        },
    },
});

const ModalDetalleDocumentoVista: React.FunctionComponent<IModalDetalleDocumentoVistaVistaProps &
    InjectedIntlProps> = ({
    alAbrirFormularioEdicion,
    alCerrarFormulario,
    confirmarCambioDeEstadoAlmacenaiento,
    documento,
    estadoFormulario,
    esMovil,
    intl: { formatMessage },
    terminoAlmacenamiento,
    documentoDetalleRecepcion,
    documentoDetalleSeleccionado,
    setDocumentoDetalleSeleccionado,
    abrirModalDocumentoDetalle,
    documentoDetalleModal,
    refrescarDocumentoDetalles,
    refrescarDocumentoDetalle,
    esRecepcion,
    tipoFormularioDocumentoDetalles,
    terminoRecepcion,
    modalDocumentoDetalleAbierto,
    cerrarModalDocumentoDetalle,
}) => {
    const estilos = useStyles();
    return (
        <div>
            <Dialog /*maxWidth={esMovil ? 'lg' : 'md'}*/ open={estadoFormulario} /*fullWidth*/ fullScreen={true}>
                <AppBar position="static">
                    <Toolbar>
                        <Grid container justifyContent="center">
                            <Texto align="center" id="almacenamiento.almacenamiento.detalle" />
                        </Grid>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent className={estilos.listbox}>
                                    <Grid container justifyContent="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <Texto id="almacenamiento.datosDocumento" fontWeight="bold" />
                                        </Grid>
                                        <Grid item md xs={12}>
                                            <TextField
                                                fullWidth
                                                label={<Texto id="almacenamiento.documento.CodigoDocumento" />}
                                                InputProps={{ readOnly: true }}
                                                name="CodigoDocumento"
                                                variant="outlined"
                                                value={documento.documento!.CodigoDocumento}
                                            />
                                        </Grid>
                                        <Grid item md xs={12}>
                                            <TextField
                                                fullWidth
                                                label={<Texto id="almacenamiento.bodegaOrigen" />}
                                                InputProps={{ readOnly: true }}
                                                name="BodegaOrigen"
                                                select
                                                variant="outlined"
                                                value={documento.documento!.BodegaOrigen}
                                            >
                                                <MenuItem value={documento.documento!.BodegaOrigen}>
                                                    {documento.documento!.DescripcionBodegaOrigen}
                                                </MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item md xs={12}>
                                            <TextField
                                                fullWidth
                                                label={<Texto id="almacenamiento.etapaDescripcion" />}
                                                InputProps={{ readOnly: true }}
                                                name="EtapaDescripcion"
                                                variant="outlined"
                                                value={documento.documento!.EtapaDescripcion}
                                            />
                                        </Grid>
                                        <Grid item md xs={12}>
                                            <TextField
                                                fullWidth
                                                label={<Texto id="almacenamiento.observaciones" />}
                                                name="Observacion"
                                                variant="outlined"
                                                value={documento.documento!.Observacion}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <VistaDocumentoDetalles
                                    documentoDetalles={documentoDetalleRecepcion /*documento.documentosDetalles*/}
                                    documentoDetalleSeleccionado={documentoDetalleSeleccionado}
                                    setDocumentoDetalleSeleccionado={setDocumentoDetalleSeleccionado}
                                    //documentoSeleccionadoRefrescar={documentoSeleccionadoRefrescar}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <VistaDocumentoDetalle
                                    documentoDetalle={documentoDetalleSeleccionado}
                                    abrirModalDocumentoDetalle={abrirModalDocumentoDetalle}
                                    refrescarDocumentoDetalles={refrescarDocumentoDetalles}
                                    refrescarDocumentoDetalle={refrescarDocumentoDetalle}
                                    //botonAgregarRecepcion={botonAgregarRecepcion}
                                    esRecepcion={true}
                                    terminoRecepcion={terminoRecepcion}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent={esMovil ? 'center' : 'flex-end'} spacing={2}>
                        {terminoAlmacenamiento ? (
                            <Grid item xs="auto">
                                <Button color="secondary" fullWidth onClick={alCerrarFormulario} variant="contained">
                                    <Texto id="boton.cerrar" />
                                </Button>
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs="auto">
                                    <Button
                                        color="secondary"
                                        fullWidth
                                        onClick={alCerrarFormulario}
                                        variant="contained"
                                    >
                                        <Texto id="boton.cancelar" />
                                    </Button>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button
                                        color="primary"
                                        fullWidth
                                        onClick={confirmarCambioDeEstadoAlmacenaiento}
                                        variant="contained"
                                    >
                                        <Texto id="almacenamiento.cambiarEtapa" />
                                    </Button>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button color="primary" fullWidth onClick={alCerrarFormulario} variant="contained">
                                        <Texto id="boton.guardar" />
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogActions>
            </Dialog>
            <FormularioEdicionDetalleAlmacenamientoVista
                abierto={modalDocumentoDetalleAbierto}
                documentoDetalle={documentoDetalleModal}
                cerrarModal={cerrarModalDocumentoDetalle}
                refrescarDocumentoDetalle={refrescarDocumentoDetalle}
                tipoFormulario={tipoFormularioDocumentoDetalles}
                documento={documento.documento}
            />
        </div>
    );
};

export default injectIntl(ModalDetalleDocumentoVista);
