import moment from 'moment';

import ModeloMovimiento from 'Infotrack@Modelos/negocioRefactor/modelos/Movimiento/ModeloMovimiento';
import IMovimientoItemPesoConsulta from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoItemPesoConsulta';


class MovimientoCM {
    private modeloMovimiento: ModeloMovimiento;

    constructor() {
        this.modeloMovimiento = new ModeloMovimiento();
    }

    public async consultarItemSerial(idBodega: number, idItem: number, idEmpresa: string,) {
        const respuesta = await (
            await this.modeloMovimiento.consultarMovimientosItemSerial(idBodega, idItem, idEmpresa,)
        ).data.Entidades;
        return respuesta;
    }

    public async consultarItemSerialPeso(movimientoConsulta: IMovimientoItemPesoConsulta) {
        const respuesta = await (
            await this.modeloMovimiento.consultarMovimientosItemSerialPeso(movimientoConsulta)
        ).data.Entidades;
        return respuesta;
    }
}

export default MovimientoCM;
