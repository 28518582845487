import { IRespuesta } from "@infotrack/presentacion-transversales/interfacesComunes";
import { seguridad } from "Infotrack@Modelos/conexiones";
import { IEmpresaAplicacion } from "./entidades/IEmpresaAplicacion";
import { IFiltroEmpresaAplicacion } from "./entidades/IFiltroEmpresaAplicacion";
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

export default class ModeloEmpresas {
    private NOMBRE_CONTROLADOR = 'EmpresaAplicacion';

    public async consultarEmpresaAplicacionPorFiltro(filtro: IFiltroEmpresaAplicacion) {
        const objeto = JSON.stringify(filtro);
        return manejadorRest<IRespuesta<IEmpresaAplicacion>>(() =>
        seguridad.postCifrado(`${this.NOMBRE_CONTROLADOR}/ConsultarEmpresaAplicacionPorFiltro`, objeto)
        );
    }
}