const mensajes = {
    // TablaDetallesPlantilla
    'TablaDetallesPlantilla.CodigoBarras': 'Código barras ítem',
    'TablaDetallesPlantilla.DescripcionItem': 'Descripción ítem',
    'TablaDetallesPlantilla.CantidadSolicitada': 'Cantidad solicitada',
    'TablaDetallesPlantilla.PesoFraccion': 'Peso',
    'TablaDetallesPlantilla.PesoFraccionSolicitado': 'Peso Solicitado',
    'TablaDetallesPlantilla.PesoFraccionRecibido': 'Peso Recibido',
    'TablaDetallesPlantilla.PesoFraccionStock': 'Peso Stock',
    "TablaDetallesPlantilla.CantidadStock":'Cantidad Stock',
    'TablaDetallesPlantilla.CantidadRecibida': 'Cantidad recibida',
    'TablaDetallesPlantilla.CantidadEntregada': 'Cantidad entregada',
    'TablaDetallesPlantilla.Cantidad': 'Cantidad',
    'TablaDetallesPlantilla.Lote': 'Lote',
    'TablaDetallesPlantilla.FechaVencimiento': 'Fecha vencimiento',
    'TablaDetallesPlantilla.DivisionOrigen': 'División origen',
    'TablaDetallesPlantilla.DivisionDestino': 'División destino',
    'TablaDetallesPlantilla.AsignarSeriales': 'Asignar seriales',
    'TablaDetallesPlantilla.ValidacionCantidadMayorASolicitada':
        'La cantidad no puede ser mayor a la cantidad solicitada',
    'TablaDetallesPlantilla.ValidacionNoFechaVencimiento': 'Este ítem requiere fecha de vencimiento',
    'TablaDetallesPlantilla.CantidadActual': 'Cantidad Actual',
    'TablaDetallesPlantilla.CantidadDisponible': 'Cantidad Disponible',
    'TablaDetallesPlantilla.FechaIngreso': 'Fecha Ingreso',
    'TablaDetallesPlantilla.Serial': 'Serial',
    'TablaDetallesPlantilla.SinDivisionDestino':'No hay divisiones para traslado',
    // CampoCantidad
    'TablaDetallesPlantilla.CampoCantidad.Nombre': 'Cantidad',
    "TablaDetallesPlantilla.CampoPesoSolicitado":'Peso solicitado',
    'TablaDetallesPlantilla.PesoFraccionEntregado':'Peso entregado',
    "TablaDetallesPlantilla.PesoDisponible":'Peso disponible'
    };

export default mensajes;
