import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import FormularioDocumentoDetalleCV from '../controladorVista/formularioDocumentoDetalleCV';
import { ITablaDetallesDevolucionesVistaProps } from '../interfaces';
import { accionesDetalle, columnasDetalles, opcionesDetalles } from './constantes';

const TablaDetallesDevolucionesVista: React.FunctionComponent<ITablaDetallesDevolucionesVistaProps &
    InjectedIntlProps> = ({ alAbrirFormulario, detalles, intl: { formatMessage }, tipoFormulario }) => (
    <>
        <Tabla
            actions={accionesDetalle(alAbrirFormulario, formatMessage, tipoFormulario)}
            columns={columnasDetalles(formatMessage)}
            data={detalles}
            title={<Texto fontWeight="bold" id="devolucion.documentoDetalle" />}
            options={opcionesDetalles}
        />
        <FormularioDocumentoDetalleCV />
    </>
);

export default injectIntl(TablaDetallesDevolucionesVista);
