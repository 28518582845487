import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import { IEntidadesFormularioDocumento } from 'Infotrack@Transversales/componentes/FormularioDocumento/FormularioDocumento';

// SalidaInventario

const CAMPOS_OCULTAR_DOCUMENTOS_FILTROS: Array<keyof IDocumentoFiltro> = [
    'BodegaDestino',
    'ProveedorId',
    'Proveedor' /*'CodigoDocumentoPredecesor'*/,
    'Serial'
];

const CAMPOS_OCULTAR_DOCUMENTOS_TABLA: Array<keyof IDocumentoConsultaGeneral> = [
    'DescripcionBodegaDestino',
    'CodigoDocumentoPredecesor',
    'DescipcionProveedorId',
    'ProveedorId',
    
];

// ModalSalidaInventario

const ENTIDADES_DOCUMENTO_POR_DEFECTO: Partial<IEntidadesFormularioDocumento> = {
    bodegasDestino: [],
    etapas: [],
    proveedores: [],
    tiposDocumento: [],
};

const CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO: Array<keyof IDocumentoConsultaGeneral> = [
    'EtapaId',
    'CodigoDocumento',
    'CodigoDocumentoPredecesor',
    'BodegaOrigen'
];

const CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_DOCUMENTO_BASE: Array<keyof IDocumentoConsultaGeneral> = [
    ...CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    'BodegaOrigen',
    'DescripcionClienteId',
    'DescipcionProveedorId',
    'ProveedorId',
    'ClienteId',
];

const CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION: Array<keyof IDocumentoConsultaGeneral> = [
    ...CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    'TipoDocumentoId',
    'BodegaOrigen',
    'ClienteId',
];

const CAMPOS_OCULTAR_DOCUMENTO_FORMULARIO: Array<keyof IDocumentoConsultaGeneral> = ['BodegaDestino', 'ProveedorId'];

const CAMPOS_OCULTAR_DETALLES_PLANTILLA: Array<keyof IDocumentoDetalleConsultaGeneral> = [
    'DescripcionDivisionDestino',
    'CantidadRecibida',
    'DivisionDestino',
    'PesoFraccionSolicitado'
  ];

const CAMPOS_EDITABLES_DETALLES_PLANTILLA: Array<keyof IDocumentoDetalleConsultaGeneral> = ['CantidadSolicitada'];

const CAMPOS_EDITABLES_DETALLES_INVENTARIO: Array<keyof IDocumentoDetalleConsultaGeneral> = ['CantidadSolicitada'];

const CAMPOS_DESHABILITAR_DETALLES_FORMULARIO: Array<keyof IDocumentoDetalle> = ['ItemId', 'DivisionOrigen', 'PesoFraccionRecibido','PesoFraccionSolicitado','Serial'];

const CAMPOS_OCULTAR_DETALLES_FORMULARIO: Array<keyof IDocumentoDetalle> = [
    'DivisionDestino',
    'CantidadSolicitada',
    'CantidadRecibida',
    'Valor',
    'PesoFraccion',
    'PesoFraccionSolicitado'
];

const CAMPOS_OCULTAR_DETALLES_INVENTARIO: Array<keyof IDocumentoDetalleConsultaGeneral> = [
    'DivisionDestino',
    'CantidadSolicitada',
    'CantidadRecibida',
    'PesoFraccion',
    'PesoFraccionRecibido'
];

const CAMPOS_OCULTAR_DETALLES_TABLA: Array<keyof IDocumentoDetalleConsultaGeneral> = [
    'DescripcionDivisionDestino',
    'CantidadSolicitada',
    'CantidadRecibida',
    'DivisionDestino',
    'CantidadRecibida',
    'PesoFraccionSolicitado'
];

const CAMPOS_DESHABILITAR_TABLA_INVENTARIO: Array<keyof IDocumentoDetalleConsultaGeneral> = [
    'PesoFraccion',
    'PesoFraccionRecibido',
    'PesoFraccionSolicitado',
    'CantidadEntregada',
    'DivisionDestino'
];

export {
    CAMPOS_DESHABILITAR_DETALLES_FORMULARIO,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_DOCUMENTO_BASE,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    CAMPOS_EDITABLES_DETALLES_PLANTILLA,
    CAMPOS_OCULTAR_DETALLES_FORMULARIO,
    CAMPOS_OCULTAR_DETALLES_PLANTILLA,
    CAMPOS_OCULTAR_DETALLES_TABLA,
    CAMPOS_OCULTAR_DOCUMENTO_FORMULARIO,
    CAMPOS_OCULTAR_DOCUMENTOS_FILTROS,
    CAMPOS_OCULTAR_DOCUMENTOS_TABLA,
    ENTIDADES_DOCUMENTO_POR_DEFECTO,
    CAMPOS_EDITABLES_DETALLES_INVENTARIO,
    CAMPOS_OCULTAR_DETALLES_INVENTARIO,
    CAMPOS_DESHABILITAR_TABLA_INVENTARIO
};
