import { Button, Card, CardContent, Checkbox, Dialog, DialogContent, Grid } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';

import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';

import IAccionEtapaDocumentoCompuesto from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/administracion/IAccionEtapaDocumentoCompuesto';
import IAccionEtapaDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAccionEtapaDocumento';
import IFlujo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IFlujo';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';
import ITipoProceso from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoProceso';

import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import FormularioTiposDocumentos from '../Componentes/FormularioTiposDocumento';

import { EstadosModalTipoDocumento } from '../enumeraciones';
import EtapasDocumento from '../Componentes/EtapasDocumento';
import IPlantillaImpresion from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IPlantillaImpresion';

interface IEntidadesTipoDocumento {
    flujos: IFlujo[];
    tiposProceso: ITipoProceso[];
    plantillaImpresion: IPlantillaImpresion[];
}

interface IModalTipoDocumentoProps {
    accionesEtapasIniciales: IAccionEtapaDocumentoCompuesto[];
    cerrar: () => any;
    crearTipoDocumento: (tipoDocumento: ITipoDocumento) => any;
    editarAccionesEtapas: (accionesEtapas: IAccionEtapaDocumento[]) => any;
    editarTipoDocumento: (tipoDocumento: ITipoDocumento) => any;
    entidadesTipoDocumento: IEntidadesTipoDocumento;
    estado: EstadosModalTipoDocumento;
    tipoDocumentoInicial: ITipoDocumento | null;
}

const TIPO_DOCUMENTO_POR_DEFECTO: ITipoDocumento = {
    Abreviatura: '',
    Consecutivo: 0,
    Estado: 0,
    FlujoId: 0,
    SincronizaMovil: false,
    TipoDocumentoDescripcion: '',
    TipoDocumentoId: 0,
    TipoProcesoId: 0,
};

const ModalTipoDocumento: FunctionComponent<IModalTipoDocumentoProps> = ({
    accionesEtapasIniciales,
    cerrar,
    crearTipoDocumento,
    editarAccionesEtapas,
    editarTipoDocumento,
    entidadesTipoDocumento,
    estado,
    tipoDocumentoInicial,
}) => {
    const [tipoDocumento, setTipoDocumento] = useState<ITipoDocumento>(TIPO_DOCUMENTO_POR_DEFECTO);
    const [accionesEtapas, setAccionesEtapas] = useState<IAccionEtapaDocumentoCompuesto[]>([]);

    useEffect(() => {
        if (tipoDocumentoInicial) setTipoDocumento(tipoDocumentoInicial);
    }, [tipoDocumentoInicial]);

    useEffect(() => {
        setAccionesEtapas(accionesEtapasIniciales);
    }, [accionesEtapasIniciales]);

    useEffect(() => {
        if (estado === EstadosModalTipoDocumento.CERRADO) {
            setTipoDocumento(TIPO_DOCUMENTO_POR_DEFECTO);
            setAccionesEtapas([]);
        }
    }, [estado]);

    const manejarCambioCampo = (
        nombreCampo: keyof ITipoDocumento,
        nuevoValor: ITipoDocumento[keyof ITipoDocumento]
    ) => {
        setTipoDocumento((tipoDocumentoActual) => ({ ...tipoDocumentoActual, [nombreCampo]: nuevoValor }));
    };

    const manejarCambioCampoAccionEtapa = (
        accionEtapaDocumentoId: number,
        nombreCampo: keyof IAccionEtapaDocumentoCompuesto,
        nuevoValor: IAccionEtapaDocumentoCompuesto[keyof IAccionEtapaDocumentoCompuesto]
    ) => {
        const copiaAccionesEtapas = [...accionesEtapas];
        const indiceAccionEtapa = copiaAccionesEtapas.findIndex(
            (a) => a.AccionEtapaDocumentoId === accionEtapaDocumentoId
        );
        if (indiceAccionEtapa === -1) return;
        if (nombreCampo === 'AccionMovil') {
            copiaAccionesEtapas[indiceAccionEtapa] = {
                ...copiaAccionesEtapas[indiceAccionEtapa],
                AccionMovil:
                    typeof nuevoValor === 'boolean' ? nuevoValor : copiaAccionesEtapas[indiceAccionEtapa].AccionMovil,
                EtapaMovil: nuevoValor === true ? nuevoValor : copiaAccionesEtapas[indiceAccionEtapa].EtapaMovil,
            };
        }
        copiaAccionesEtapas[indiceAccionEtapa] = {
            ...copiaAccionesEtapas[indiceAccionEtapa],
            [nombreCampo]: nuevoValor,
        };
        setAccionesEtapas(copiaAccionesEtapas);
    };

    const alCambiarValorAutocomplete = (nuevoValor: IPlantillaImpresion) => {
        setTipoDocumento((tipoDocumentoActual) => ({
            ...tipoDocumentoActual,
            PlantillaImpresionId: nuevoValor ? nuevoValor.PlantillaImpresionId : undefined,
        }));
    };

    return (
        <Dialog fullWidth maxWidth="md" open={estado !== EstadosModalTipoDocumento.CERRADO}>
            <ModalEncabezado
                cerrar={cerrar}
                titulo={
                    estado === EstadosModalTipoDocumento.CREACION ? (
                        <Texto id="ModalTipoDocumento.TituloCreacion" />
                    ) : estado === EstadosModalTipoDocumento.EDICION ? (
                        <Texto id="ModalTipoDocumento.TituloEdicion" />
                    ) : estado === EstadosModalTipoDocumento.VISUALIZACION ? (
                        <Texto id="ModalTipoDocumento.TituloVisualizacion" />
                    ) : (
                        undefined
                    )
                }
            />
            <DialogContent>
                <Grid container spacing={2}>
                    {
                        <FormularioTiposDocumentos
                            crearTipoDocumento={crearTipoDocumento}
                            editarTipoDocumento={editarTipoDocumento}
                            entidadesTipoDocumento={entidadesTipoDocumento}
                            estado={estado}
                            manejarCambioCampo={manejarCambioCampo}
                            tipoDocumento={tipoDocumento}
                            alCambiarValorAutocomplete={alCambiarValorAutocomplete}
                        />
                    }
                    {estado !== EstadosModalTipoDocumento.CREACION && (
                        <EtapasDocumento
                            accionesEtapas={accionesEtapas}
                            editarAccionesEtapas={editarAccionesEtapas}
                            estado={estado}
                            manejarCambioCampoAccionEtapa={manejarCambioCampoAccionEtapa}
                            tipoDocumento={tipoDocumento}
                        />
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export { IEntidadesTipoDocumento };

export default ModalTipoDocumento;
