import ModeloBodegasDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones';

export default class EntidadesDocumentoDetalleCM {
  private modeloBodegasDivisiones: ModeloBodegasDivisiones;

  constructor() {
    this.modeloBodegasDivisiones = new ModeloBodegasDivisiones();
  }

  public consultarListaDivisiones(BodegaId: number) {
    return this.modeloBodegasDivisiones
      .consultarDivisionesFiltro({ BodegaId, Estado: 1 })
      .then(({ data: { Entidades } }) => Entidades);
  }
}
