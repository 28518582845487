export default {
  'sidebar.monitorinventario': 'Monitor inventario',
  'monitorInventarios.Clasificaciones': 'Classifications',
  'monitorInventarios.Bodegas': 'Warehouses',
  'monitorInventarios.cantidad': 'Quantity',
  'monitorInventarios.stockMinimo': 'Minimum stock',
  'monitorInventarios.valorInventario': 'Inventory valuation',
  'monitorInventarios.alertaStockMinimo': 'Minimum stock alert',
  'monitorInventarios.productoMasAntiguedad': 'Older products in cellar',
  'monitorInventarios.top10MasRotacion': 'Top 10 biggest rotation',
  'monitorInventarios.top10MenorRotacion': 'Top 10 lowest rotación',
  'monitorInventarios.top10Clientes': 'Top 10 customer orders',
  'monitorInventarios.estadoPedidos': 'States requested',
  'monitorInventarios.utilizacionBodegas': 'Use warehouse/s',
  'monitorInventarios.CantidadDisponible': 'Quantity Available',
  'monitorInventarios.CantidadUtilizada': 'Amount used',
  'monitorInventarios.CantidadInventario': 'Inventory Quantity',
  'monitorInventarios.FechaCreacion': 'Creation date',
};
