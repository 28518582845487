import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';
import { negocio } from '../../conexiones';
import IFlujoEtapaDocumento from './/entidades/flujoEtapaDocumento';
import IflujoEtapaDocumentoCompuesto from './entidades/flujoEtapaDocumentoCompuesto';

export default class ModeloFlujoEtapaDocumento {
    private NOMBRE_CONTROLADOR = 'FlujoEtapaDocumento';

    public consultarFlujoEtapaDocumento() {
        return manejadorRest<IRespuesta<IFlujoEtapaDocumento>>(
            () => negocio.get(`${this.NOMBRE_CONTROLADOR}/ConsultarListaFlujoEtapaDocumento`),
            true
        );
    }

    public editarFlujoEtapaDocumento(flujoEtapaDocumento: IFlujoEtapaDocumento) {
        return manejadorRest<IRespuesta<IFlujoEtapaDocumento>>(
            () => negocio.put(`${this.NOMBRE_CONTROLADOR}/EditarFlujoEtapaDocumento`, flujoEtapaDocumento),
            true
        );
    }

    public eliminarFlujoEtapaDocumento(flujoEtapaDocumento: IFlujoEtapaDocumento) {
        return manejadorRest<IRespuesta<IFlujoEtapaDocumento>>(
            () => negocio.delete(`${this.NOMBRE_CONTROLADOR}/EliminarFlujoEtapaDocumento`, flujoEtapaDocumento),
            true
        );
    }

    public guardarFlujoEtapaDocumento(flujoEtapaDocumento: IFlujoEtapaDocumento) {
        return manejadorRest<IRespuesta<IFlujoEtapaDocumento>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/GuardarFlujoEtapaDocumento`, flujoEtapaDocumento),
            true
        );
    }

    public consultarFlujoEtapaDocumentoCompuesto(flujoEtapaDocumento: IFlujoEtapaDocumento) {
        return manejadorRest<IRespuesta<IflujoEtapaDocumentoCompuesto>>(
            () =>
                negocio.post(
                    `${this.NOMBRE_CONTROLADOR}/ConsultaListaFlujoEtapaDocumentoCompuesto`,
                    flujoEtapaDocumento
                ),
            true
        );
    }
}
