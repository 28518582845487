import AppLocaleData from 'react-intl/locale-data/en';
import inglesRecursos from '../idiomas/en_US';

const EnLeng = {
  data: AppLocaleData,
  locale: 'en-US',
  messages: {
    ...inglesRecursos
  }
};
export default EnLeng;
