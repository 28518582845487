import ModeloGestionBodegas from '../../../../modelos/smartStock/bodegas';
import IBodegaPersonaFiltro from '../../../../modelos/smartStock/bodegas/entidades/bodegaPersonaFiltro';

export default class EntidadesResolucionesFacturacionesCM {
    private modeloGestionBodegas: ModeloGestionBodegas;

    constructor() {
        this.modeloGestionBodegas = new ModeloGestionBodegas();
    }

    public consultarEntidadesFiltro(bodegaPersonaFiltro: IBodegaPersonaFiltro) {
        return Promise.all([this.modeloGestionBodegas.consultarBodegasPersonasDoc(bodegaPersonaFiltro)]).then(
            (entidades) => ({
                bodegas: entidades[0].data.Entidades,
            })
        );
    }
}
