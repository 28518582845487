import { useMediaQuery, useTheme } from '@material-ui/core';
import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IDocumentoCompuestoMovimiento from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuestoMovimiento';
import IDocumentoConDetallesAccion from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoConDetallesAccion';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import DocumentoDetalleCM from '../controladorModelo/documentoDetalleCM';
import DocumentosCM from '../controladorModelo/documentosCM';
import EntidadesDocumentoCM from '../controladorModelo/entidadesDocumentoCM';
import { IFormularioDespachosCVProps } from '../interfaces';
import { acciones, IEstadoDespachos } from '../reductorDespachos';
import FormularioDespachosVista from '../vista/formularioDespachosVista';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import DocumentoDetallesCM from '../controladorModelo/documentoDetalleCM';
import TiposEstadosDetalles from 'Infotrack@Modelos/smartStock/estadosDetalles/entidades/tiposEstadosDetalles';
import AbreviaturasTiposDocumentos from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/abreviaturasTiposDocumento';

const documentoDetallesCM = new DocumentoDetallesCM();
const documentosCM = new DocumentosCM();
const documentoDetalleCM = new DocumentoDetalleCM();
const entidadesDocumentoCM = new EntidadesDocumentoCM();

const FormularioDespachosCV: React.FunctionComponent<IFormularioDespachosCVProps> = ({ EmpresaId, UsuarioId }) => {
    const estadoInicialDocumentoDetalle: IDocumentoDetalleCompuesto = {
        CodigoBarras: '',
        CodigoDocumento: '',
        DescripcionItem: '',
        DescripcionProducto: '',
        DescripcionDivisionDestino: '',
        DescripcionDivisionOrigen: '',
        EmpresaId: '',
        Lote: '',
        Serial: '',
        DescripcionEstadoDetalle: TiposEstadosDetalles.Alistado,
    };
    const [refrescarDocumentoDetalles, setRefrescarDocumentoDetalle] = React.useState<boolean>();
    const [modalDocumentoDetalleAbierto, setModalDocumentoDetalleAbierto] = React.useState<boolean>(false);
    const [tipoFormularioDocumentoDetalles, setTipoFormularioDocumentoDetalles] = React.useState<TipoFormulario>();
    const [documentoDetalleModal, setDocumentoDetalleModal] = React.useState<IDocumentoDetalleCompuesto>();
    const [documentoDetalleRecepcion, setDocumentoDetalleRecepcion] = React.useState<IDocumentoDetalleCompuesto[]>([]);
    const [documentos, setDocumentos] = React.useState<IDocumentoCompuestoMovimiento[]>([]);
    const [
        { documento, documentosDetalles, documentoId, estadoFormulario, tipoFormularioDocumento },
        dispatch,
    ] = useProveedor<IEstadoDespachos>();
    const [documentoDetalleSeleccionado, setDocumentoDetalle] = React.useState<IDocumentoDetalleCompuesto>(
        estadoInicialDocumentoDetalle
    );

    React.useEffect(() => {
        if (estadoFormulario) cargarEntidadesDocumento();
    }, [estadoFormulario]);

    React.useEffect(() => {
        if (documento && tipoFormularioDocumento === TipoFormulario.Edicion)
            dispatch({ type: acciones.ESTABLECER_DOCUMENTO_ID, payload: documento.DocumentoRelacionado });
    }, [documento]);

    const theme = useTheme();
    const esMovil = useMediaQuery(theme.breakpoints.down('sm'));

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
    };

    const alCambiarValor = ({
        target,
    }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { checked, name, type, value } = target as EventTarget & HTMLInputElement;
        dispatch({
            type: acciones.MODIFICAR_DOCUMENTO,
            payload: { ...documento, [name]: type === 'checkbox' ? checked : value },
        });
    };

    const cargarEntidadesDocumento = () => {
        entidadesDocumentoCM
            .consultarEntidadesDocumento(EmpresaId, UsuarioId)
            .then(({ documentos: documentosCargados }) => {
                setDocumentos(documentosCargados);
            });
        cargarDocumentoConDetallesRecepcion();
    };
    const cargarDocumentoConDetallesRecepcion = async () => {
        const consultaDocumentoDetalles = await documentoDetallesCM.consultarDocumentoCompuestoDetalles({
            DocumentoId: documento!.DocumentoId,
            IdTipoDocumento: 1,
        });
        if (consultaDocumentoDetalles.length > 0) {
            setDocumentoDetalleRecepcion(consultaDocumentoDetalles[0].documentosDetalles);
        }
    };

    const confimarCreacionEditarDocumento = () => {
        const documentoACrearEditar = { Documento: documento!, DocumentosDetalles: documentosDetalles };
        if (documentosCM.validarDocumento(documentoACrearEditar)) {
            manejadorDialogoGlobal({
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                accionConfirmar: () => alGuardarDocumento(documentoACrearEditar),
                estado: true,
                mensaje: (
                    <Texto
                        id={
                            tipoFormularioDocumento === TipoFormulario.Edicion
                                ? 'alerta.confirmacionEdicion'
                                : 'alerta.confirmacionCreacion'
                        }
                    />
                ),
                mostrarCancelar: true,
                tipoDialogo: 'Advertencia',
                titulo: <Texto id="titulo.advertencia" />,
            });
        }
    };
    const abrirModalDocumentoDetalle = (
        tipoFormulario: TipoFormulario,
        documentoDetalle?: IDocumentoDetalleCompuesto
    ) => {
        if (tipoFormulario === TipoFormulario.Creacion) {
            setDocumentoDetalleModal({ ...documentoDetalleSeleccionado, CantidadRecibida: undefined });
        } else if (tipoFormulario === TipoFormulario.Edicion) {
            setDocumentoDetalleModal(documentoDetalle!);
        }
        setTipoFormularioDocumentoDetalles(tipoFormulario);
        setModalDocumentoDetalleAbierto(true);
    };

    const alGuardarDocumento = (documentoDetalles: IDocumentoConDetallesAccion) => {
        const peticionAEjecutar =
            tipoFormularioDocumento === TipoFormulario.Edicion
                ? documentoDetalleCM.modificarDocumentoDetalles(documentoDetalles)
                : (documentosCM.guardarDocumento(documentoDetalles.Documento) as any);
        manejadorDialogoGlobal({ estado: false });
        peticionAEjecutar.then(() => {
            alCerrarFormulario();
            dispatch({ type: acciones.RECARGAR_TABLA });
        });
    };
    const setDocumentoDetalleSeleccionado = (documentoSeleccionado: IDocumentoDetalleCompuesto) => {
        setDocumentoDetalle(documentoSeleccionado);
    };

    const alCambiarDocumentoId = (nuevoDocumentoId: any) => {
        dispatch({ type: acciones.ESTABLECER_DOCUMENTO_ID, payload: nuevoDocumentoId.DocumentoId });
        const nuevoDocumento = documentos.find(({ DocumentoId }) => DocumentoId === nuevoDocumentoId.DocumentoId);
        dispatch({ type: acciones.ESTABLECER_DOCUMENTO, payload: nuevoDocumento });
    };

    const confimarCambioEstadoPedido = () => {
        if (documento) {
            manejadorDialogoGlobal({
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                accionConfirmar: () => modificarEstadoDespacho(),
                estado: true,
                mensaje: <Texto id={consultarCambioEtapa(documento)} />,
                mostrarCancelar: true,
                tipoDialogo: 'Advertencia',
                titulo: <Texto id="titulo.advertencia" />,
            });
        }
    };
    const cerrarModalDocumentoDetalle = () => {
        setModalDocumentoDetalleAbierto(false);
    };
    const modificarEstadoDespacho = () => {
        manejadorDialogoGlobal({ estado: false });
        documentosCM.modificarEstadoDespacho(documento!).then(() => {
            dispatch({ type: acciones.RECARGAR_TABLA });
            dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
        });
    };

    const consultarCambioEtapa = (documentoAModificar: IDocumentoCompuestoMovimiento): string => {
        if (documentoAModificar.EtapaDescripcion === TiposEtapas.Creada)
            return 'despachos.alerta.cambioEstadoDespachado';
        if (documentoAModificar.EtapaDescripcion === TiposEtapas.Despachado)
            return 'despachos.alerta.cambioEstadoEntregaConfirmada';
        if (documentoAModificar.EtapaDescripcion === TiposEtapas.EntregaConfirmada)
            return 'despachos.alerta.cambioEstadoTransito';
        return '';
    };
    const refrescarDocumentoDetalle = (refrescar: boolean) => {
        setRefrescarDocumentoDetalle(refrescar);
    };

    return (
        <FormularioDespachosVista
            alCambiarValor={alCambiarValor}
            alCerrarFormulario={alCerrarFormulario}
            alCambiarDocumentoId={alCambiarDocumentoId}
            alGuardarDocumento={confimarCreacionEditarDocumento}
            confimarCambioEstadoPedido={confimarCambioEstadoPedido}
            documento={documento}
            documentoId={documentoId}
            entidadesDocumento={{ documentos }}
            estadoFormulario={estadoFormulario}
            esMovil={esMovil}
            tipoFormularioDocumento={tipoFormularioDocumento}
            documentoDetalleRecepcion={documentoDetalleRecepcion}
            documentoDetalleSeleccionado={documentoDetalleSeleccionado}
            setDocumentoDetalleSeleccionado={setDocumentoDetalleSeleccionado}
            abrirModalDocumentoDetalle={abrirModalDocumentoDetalle}
            documentoDetalleModal={documentoDetalleModal}
            tipoFormularioDocumentoDetalles={tipoFormularioDocumentoDetalles}
            modalDocumentoDetalleAbierto={modalDocumentoDetalleAbierto}
            refrescarDocumentoDetalles={refrescarDocumentoDetalles}
            refrescarDocumentoDetalle={refrescarDocumentoDetalle}
            esRecepcion={true}
            terminoRecepcion={Boolean(
                documento &&
                    documento.EtapaDescripcion === TiposEtapas.Cerrada &&
                    documento.TipoDocumentoAbreviatura === AbreviaturasTiposDocumentos.DES
            )}
            cerrarModalDocumentoDetalle={cerrarModalDocumentoDetalle}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion },
}: IEstadoGlobal): IFormularioDespachosCVProps => ({
    EmpresaId: usuarioInformacion!.IdEmpresa,
    UsuarioId: usuarioInformacion!.IdUsuario,
});

export default connect<IFormularioDespachosCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(FormularioDespachosCV);
