const mensajes = {
    // EntradaDevolucion
    'EntradaDevolucion.ValidacionNoTipoDocumentoId': 'You must select a document type',
    'EntradaDevolucion.ValidacionNoBodegaDestino': 'You must select a destination warehouse',
    'EntradaDevolucion.ValidacionNoClienteId': 'You must select a client',
    // ModalEntradaDevolucion
    'ModalEntradaDevolucion.TituloCreacion': 'Create Entry by Devolution',
    'ModalEntradaDevolucion.TituloEdicion': 'Edit Entry by Devolution',
    'ModalEntradaDevolucion.TituloVisualizacion': 'View Entry by Devolution',
    'ModalEntradaDevolucion.TituloTablaDetalleDocumento': 'Document Details',
    'ModalEntradaDevolucion.TituloTablaDetalleDocumentoBase': 'Base Document Details',
    'permiso.entradadevolucion': 'Access',
};

export default mensajes;
