import ModeloItemImagenes from 'Infotrack@Modelos/smartStock/itemImagenes';

export default class ItemImagenCM {
  private modeloItemImagenes: ModeloItemImagenes;
  constructor() {
    this.modeloItemImagenes = new ModeloItemImagenes();
  }
  public consultaItemImagenes(ItemId: number) {
    return this.modeloItemImagenes
      .consultarItemImagenes(ItemId)
      .then(({ data: { Entidades } }) =>
        Entidades.map((entidad, indice) => ({
          ...entidad,
          ArchivoImagen: `data:image/png;base64,${entidad.ArchivoImagen}`,
          IdTemporal: indice + 1,
        }))
      );
  }
}
