import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';

interface ICampoSerialProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampo: keyof IDocumentoDetalle,
        nuevoValor: IDocumentoDetalle[keyof IDocumentoDetalle]
    ) => any;
    valor: string;
}

const CampoSerial: FunctionComponent<ICampoSerialProps> = ({ deshabilitado, manejarCambioCampo, valor }) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="DetallesFormulario.CampoSerial.Nombre" />}
        onChange={(event) => manejarCambioCampo('Serial', event.target.value)}
        value={valor !== null ?valor.toUpperCase():""}
    />
);

export default CampoSerial;
