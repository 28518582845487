const mensajes = {
    'sidebar.tiposdocumento': 'Tipos Documento',

    'sidebar.ordenes': 'Órdenes',

    'sidebar.ordenentrada': 'Orden de Entrada',
    'sidebar.ordensalida': 'Orden de Salida',

    'sidebar.entradainventario': 'Entrada de Inventario',
    'sidebar.salidainventario': 'Salida de Inventario',
    'sidebar.entradadevolucion': 'Entrada Por Devolución',
    'sidebar.salidadevolucion': 'Salida Por Devolución',
    'sidebar.traslado': 'Traslado',

    'sidebar.auditoria': 'Auditoría',
    'sidebar.conteo': 'Gestión de Conteos',

    'sidebar.flujoetapas': 'Flujo Etapas',

    'sidebar.permisosperfil': 'Permisos',

    'sidebar.plantillaimpresion': 'Plantillas Impresión',
};

export default mensajes;
