import { useMediaQuery, useTheme } from '@material-ui/core';
import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IDocumentoAuditoria from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoAuditoria';
import IFamilias from 'Infotrack@Modelos/smartStock/familias/entidades/familias';
import IItem from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/item';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import DocumentoCM from '../controladorModelo/documentoCM';
import EntidadesDocumentoCM from '../controladorModelo/entidadesDocumentoCM';
import { IFormularioGestionAuditoriasCVProps, IInputsDocumentoAuditoria } from '../interfaces';
import { acciones, IEstadoGestionAuditorias } from '../reductorGestionAuditorias';
import FormularioGestionAuditoriasVista from '../vista/formularioGestionAuditoriasVista';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';

const estadoInicialFormulario: IInputsDocumentoAuditoria = {
    BodegaId: '',
    ConteoUnico: false,
    DivisionId: '',
    FamiliaId: '',
    ItemId: '',
    DocumentoRelacionado: '',
    GenerarSaldoActual: false,
    Observaciones: '',
    Bodega: null,
    Division: null,
    Familia: null,
    Item: null,
};

const documentoCM = new DocumentoCM();
const entidadesDocumentoCM = new EntidadesDocumentoCM();

const FormularioGestionAuditoriasCV: React.FunctionComponent<IFormularioGestionAuditoriasCVProps> = ({
    IdAgencia,
    IdEmpresa,
    IdUsuario,
}) => {
    const [{ estadoFormulario, tipoFormulario }, dispatch] = useProveedor<IEstadoGestionAuditorias>();
    const [bodegas, setBodegas] = React.useState<IBodega[]>([]);
    const [familias, setFamilias] = React.useState<IFamilias[]>([]);
    const [items, setItems] = React.useState<IItem[]>([]);
    const [divisiones, setDivisiones] = React.useState<IBodegaDivisiones[]>([]);
    const { alCambiarValor, reiniciar, setValor, valor } = useInputState(estadoInicialFormulario);

    const theme = useTheme();
    const esMovil = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        cargarEntidadesDocumento();
    }, []);

    React.useEffect(() => {
        if (valor.Bodega) cargarDivisionesPorBodega();
    }, [valor.Bodega]);

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
        reiniciar();
    };

    const cargarEntidadesDocumento = async () => {
        setBodegas(
            await entidadesDocumentoCM.consultarBodegasDocumento({
                AgenciaId: IdAgencia,
                UsuarioId: IdUsuario,
                EmpresaId: IdEmpresa,
                Estado:estadoActivo
            })
        );
        entidadesDocumentoCM.consultaEntidadesFiltro(IdEmpresa).then((data) => {
            setFamilias(data.familias);
            setItems(data.items);
        });
    };

    const alCambiarValorAutoComplete = (
        nuevoValor: IInputsDocumentoAuditoria[keyof IInputsDocumentoAuditoria],
        nombreValor: string
    ) => {
        setValor((valorAnterior) => ({
            ...valorAnterior,
            [nombreValor]: nuevoValor,
        }));
    };

    const cargarDivisionesPorBodega = async () => {
        setValor({ ...valor, ['DivisionId']: '', ['Division']: null });
        setDivisiones(
            valor.Bodega ? await entidadesDocumentoCM.consultarDivisionesPorBodega(Number(valor.Bodega.BodegaId)) : []
        );
    };

    const confirmarCreacionDocumento = () => {
        const documentoAuditoria = mapearDatos();
        if (documentoCM.validarDocumentoAuditoria(documentoAuditoria)) {
            manejadorDialogoGlobal({
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                accionConfirmar: () => crearDocumentoAuditoria(documentoAuditoria),
                estado: true,
                mensaje: <Texto id="alerta.confirmacionCreacion" />,
                mostrarCancelar: true,
                tipoDialogo: 'Advertencia',
                titulo: <Texto id="titulo.advertencia" />,
            });
        }
    };

    const crearDocumentoAuditoria = (documentoAuditoria: IDocumentoAuditoria) => {
        manejadorDialogoGlobal({ estado: false });
        documentoCM.crearDocumentoAuditoria(documentoAuditoria).then(() => {
            alCerrarFormulario();
            dispatch({ type: acciones.RECARGAR_TABLA });
        });
    };

    const mapearDatos = (): IDocumentoAuditoria => {
        const { Bodega, Division, Item, Familia } = valor;
        return {
            ...valor,
            BodegaId: Bodega ? Number(Bodega.BodegaId) : 0,
            DivisionId: Division ? Number(Division.DivisionId) : undefined,
            FamiliaId: Familia ? Number(Familia.FamiliaId) : undefined,
            ItemId: Item ? Number(Item.ItemId) : undefined,
            EmpresaId: IdEmpresa,
        };
    };

    return (
        <FormularioGestionAuditoriasVista
            alCambiarValor={alCambiarValor}
            alCambiarValorAutoComplete={alCambiarValorAutoComplete}
            alCerrarFormulario={alCerrarFormulario}
            alGuardarDocumento={confirmarCreacionDocumento}
            estadoFormulario={estadoFormulario}
            esMovil={esMovil}
            entidadesDocumento={{ bodegas, divisiones, familias, items }}
            tipoFormulario={tipoFormulario}
            documento={valor}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion, agenciaActual },
}: IEstadoGlobal): IFormularioGestionAuditoriasCVProps => ({
    IdEmpresa: usuarioInformacion!.IdEmpresa,
    IdAgencia: agenciaActual!.IdAgencia,
    IdUsuario: usuarioInformacion!.IdUsuario,
});

export default connect<IFormularioGestionAuditoriasCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(FormularioGestionAuditoriasCV);
