import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';
import ModeloGestionProducto from 'Infotrack@Modelos/smartStock/gestionProducto';
import IFitrosItem from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/filtrosItem';

export default class ItemsCM {
    private modeloGestionProducto: ModeloGestionProducto;

    constructor() {
        this.modeloGestionProducto = new ModeloGestionProducto();
    }

    public consultarListaProductoPrincipal = (paginador: IPaginador, filtros: IFitrosItem) =>
        this.modeloGestionProducto
            .consultarListaProductoPrincipal({ paginador, items: filtros })
            .then(({ data }) => data);

    public consultarItemPorCodigoBarras(CodigoBarras: string) {
        return this.modeloGestionProducto
            .consultarItemPorCodigoBarras(CodigoBarras)
            .then(({ data }) => data.Entidades[0]);
    }
}
