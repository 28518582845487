import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IDivision from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDivision';
import IFamilia from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IFamilia';
import IItem from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IItem';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';
import { EstadosModalAuditoria } from '../../enumeraciones';
import { Button, Card, CardContent, Grid, MenuItem, TextField } from '@material-ui/core';
import Texto from '@infotrack/presentacion-componentes/texto';
import CampoBodegaId from './Campos/CampoBodegaId';
import CampoTipoDocumentoId from './Campos/CampoTipoDocumentoId';
import CampoDivisionId from './Campos/CampoDivisionId';
import CampoFamiliaId from './Campos/CampoFamiliaId';
import CampoItemId from './Campos/CampoItem';
import CampoCodigoDocumento from './Campos/CampoCodigoDocumento';
import CampoEtapaId from './Campos/CampoEtapaId';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import EntidadesAuditoriaCM from '../../controladorModelo/EntidadesAuditoriaCM';
import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';
import AuditoriaConteoCM from '../../controladorModelo/AuditoriaConteoCM';
import FinalizarAuditoria from './FinalizarAuditoria';
import DivisionesDocumentosCM from 'Infotrack@Modelos/smartStock/divisionesDocumentosDefecto';

interface IEntidadesAuditoria {
    bodegas: IBodega[];
    tiposDocumento: ITipoDocumento[];
    divisiones: IDivision[] | undefined;
    familias: IFamilia[] | undefined;
    items: IItem[] | undefined;
    etapas?: IEtapas[];
}

interface IAuditoriaFormularioProps {
    cambiarEtapa: (etapaId: number, esEtapaFinalizado: boolean) => void;
    camposDeshabilitar?: Array<keyof IAuditoriaCompuesta>;
    camposOcultar?: Array<keyof IAuditoriaCompuesta>;
    auditoria: IAuditoriaCompuesta | null;
    entidadesAuditoria: IEntidadesAuditoria;
    estado?: EstadosModalAuditoria;
    etapasSiguientes: IEtapas[];
    crearAuditoria: (auditoria: IAuditoriaCompuesta) => void;
    cambioEstadoModalAuditoriaFinalizada: () => void;
    consultarTipoDocumentoPorBodega: (idBodega: number) => void;
}

const AUDITORIA_POR_DEFECTO: IAuditoriaCompuesta = {
    AuditoriaId: '',
    BodegaId: 0,
    Estado: 0,
    TipoDocumentoId: 0,
    EtapaId: 0,
    Divisiones: [],
    Familias: [],
    Items: [],
};

const AuditoriaFormulario: FunctionComponent<IAuditoriaFormularioProps> = ({
    cambiarEtapa,
    camposDeshabilitar = [],
    camposOcultar = [],
    auditoria: auditoriaProps,
    entidadesAuditoria,
    estado,
    etapasSiguientes,
    crearAuditoria,
    cambioEstadoModalAuditoriaFinalizada,
    consultarTipoDocumentoPorBodega,
}) => {
    const entidadesAuditoriaCM = useMemo(() => new EntidadesAuditoriaCM(), []);
    const auditoriaConteoCM = useMemo(() => new AuditoriaConteoCM(), []);
    const divisionesDocumentosCM = useMemo(() => new DivisionesDocumentosCM(), []);

    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);

    const [auditoria, setAuditoria] = useState<IAuditoriaCompuesta>(AUDITORIA_POR_DEFECTO);
    const [entidadesAuditoriaFormulario, setEntidadesAuditoriaFormulario] = useState<IEntidadesAuditoria>(
        entidadesAuditoria
    );
    const [conteos, setConteos] = useState<IAuditoriaConteo[]>([]);
    const [estadoModal, setEstadoModal] = useState<boolean>(false);
    const [finalizacionExitosa, setFinalizacionExitosa] = useState<boolean>(false);
    const [idEtapaFinalizado, setIdEtapaFinalizado] = useState<number>(0);
    useEffect(() => {
        if (auditoriaProps) setAuditoria(auditoriaProps);
        else setAuditoria(AUDITORIA_POR_DEFECTO);
    }, [auditoriaProps]);

    useEffect(() => {
        if (auditoria.BodegaId !== 0 && auditoria.TipoDocumentoId !== 0) {
            consultarDivisiones(auditoria.BodegaId, auditoria.TipoDocumentoId);
        }
    }, [auditoria.BodegaId, auditoria.TipoDocumentoId]);

    useEffect(() => {
        setEntidadesAuditoriaFormulario(entidadesAuditoria);
    }, [entidadesAuditoria]);

    useEffect(() => {
        if (estadoModal) {
            consultarListaConteo();
        }
    }, [estadoModal]);

    useEffect(() => {
        if (auditoria.BodegaId !==0) {
            consultarTipoDocumentoPorBodega(auditoria.BodegaId)
        }
    }, [auditoria.BodegaId]);

    useEffect(() => {
        if (finalizacionExitosa === true) {
            manejarCambioCampo('EtapaDocumento', 2);
        }
        if (finalizacionExitosa === false) {
            manejarCambioCampo('EtapaDocumento', 0);
        }
    }, [finalizacionExitosa]);
    const manejarCambioCampo = (
        nombreCampo: keyof IAuditoriaCompuesta,
        nuevoValor: IAuditoriaCompuesta[keyof IAuditoriaCompuesta]
    ) => {
        if ('BodegaId' === nombreCampo) consultarTipoDocumentoPorBodega(nuevoValor! as number);
        if(('BodegaId' === nombreCampo && nuevoValor ===0)||('TipoDocumentoId' === nombreCampo && nuevoValor ===0) ){
            setEntidadesAuditoriaFormulario((auditoriaActual) => ({
                ...auditoriaActual,
                divisiones: [],
            }));
            setAuditoria((auditoriaActual) => ({ ...auditoriaActual, TipoDocumentoId: 0 }));
            setAuditoria((auditoriaActual) => ({ ...auditoriaActual, Divisiones: [] }));
        }
        setAuditoria((auditoriaActual) => ({ ...auditoriaActual, [nombreCampo]: nuevoValor }));
    };

    const consultarDivisiones = async (idBodega: number, idTipoDocumento: number) => {
        const divisionesAuditoriaConsultadas = await divisionesDocumentosCM.consultaDivisionesPorDefectoCompuestaFiltrada(
            { BodegaId: idBodega, TipoDocumentoId: idTipoDocumento }
        );
        let divisiones: IDivision[] =[];


        if(divisionesAuditoriaConsultadas.data.Entidades.length >0){
            divisionesAuditoriaConsultadas.data.Entidades.map((d) => {
                divisiones.push({
                    DivisionId: d.DivisionId,
                    DivisionDescripcion: d.DivisionDescripcion!,
                    BodegaId: d.BodegaId,
                    ColumnaId: 0,
                    Estado: 1,
                    FilaId: 0,
                    PisoId: 0,
                    TipoDivisionId: 0,
                });
            });
        }
        

        setEntidadesAuditoriaFormulario((auditoriaActual) => ({
            ...auditoriaActual,
            divisiones: divisiones,
        }));
    };

    const obtenerItems = () => {
        if (auditoria.Familias.length > 0) {
            const idsFamilias = auditoria.Familias.map((f) => f.FamiliaId);
            return entidadesAuditoriaFormulario.items!.filter((i) => idsFamilias.includes(i.FamiliaId));
        }
        return entidadesAuditoriaFormulario.items!;
    };

    // Finalizar Auditoria
    const consultarListaConteo = async () => {
        const filtroConsultaListaConteo: Partial<IAuditoriaConteo> = {
            AuditoriaId: auditoria.AuditoriaId,
            EmpresaId: idEmpresa,
            AgenciaId: idAgencia,
            IdTipoConteo: 1,
        };
        const conteosConsultados = await auditoriaConteoCM.consultarListaConteo(filtroConsultaListaConteo);
        setConteos(conteosConsultados);
    };

    const validarEtapaFinalizado = (idEtapa: string) => {
        let etapaFinalizado: IEtapas | undefined;
        etapaFinalizado = etapasSiguientes.find((x) => {
            if (x.EtapaId === parseInt(idEtapa)) {
                if (x.EtapaDescripcion === 'Finalizado') {
                    setIdEtapaFinalizado(x.EtapaId);
                    return x;
                }
            }
        });
        return etapaFinalizado! ? true : false;
    };

    const cerraraFormularioFinalizado = () => {
        if (finalizacionExitosa === true) {
            manejarCambioCampo('DescripcionEtapaDocumento', 'Finalizado');
            manejarCambioCampo('EtapaDocumento', 'Finalizado');
        }
        if (finalizacionExitosa === false) {
            setAuditoria((auditoria) => ({ ...auditoria, EtapaId: 0 }));
        }
        setEstadoModal(false);
    };

    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <Grid alignItems="center" container spacing={2}>
                        <Grid item xs={12}>
                            <Texto id="Auditoria.Titulo" fontWeight="bold" />
                        </Grid>
                        <Grid alignItems="center" container spacing={2}>
                            {!camposOcultar.includes('BodegaId') && (
                                <Grid item xs={6}>
                                    <CampoBodegaId
                                        deshabilitado={camposDeshabilitar.includes('BodegaId')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        bodega={entidadesAuditoriaFormulario.bodegas}
                                        valor={auditoria.BodegaId}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('TipoDocumentoId') && (
                                <Grid item xs={6}>
                                    <CampoTipoDocumentoId
                                        deshabilitado={camposDeshabilitar.includes('TipoDocumentoId')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        tipoDocumento={entidadesAuditoriaFormulario.tiposDocumento}
                                        valor={auditoria.TipoDocumentoId}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('Divisiones') && (
                                <Grid item xs={6}>
                                    <CampoDivisionId
                                        deshabilitado={camposDeshabilitar.includes('Divisiones')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        divisiones={entidadesAuditoriaFormulario.divisiones!}
                                        divisionesSeleccionadas={auditoria.Divisiones}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('Familias') && (
                                <Grid item xs={6}>
                                    <CampoFamiliaId
                                        deshabilitado={camposDeshabilitar.includes('Familias')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        familias={entidadesAuditoriaFormulario.familias!}
                                        familiasSeleccionadas={auditoria.Familias}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('Items') && (
                                <Grid item xs={6}>
                                    <CampoItemId
                                        deshabilitado={camposDeshabilitar.includes('Items')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        // items={entidadesAuditoriaFormulario.items!.filter((x) =>
                                        //     auditoria.Familias
                                        //         ? entidadesAuditoriaFormulario.items!.filter((y) => y.FamiliaId === x.FamiliaId)
                                        //         : true
                                        // )}
                                        items={obtenerItems()}
                                        itemsSeleccionados={auditoria.Items}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('CodigoDocumento') && (
                                <Grid item xs={6}>
                                    <CampoCodigoDocumento
                                        deshabilitado={camposDeshabilitar.includes('CodigoDocumento')}
                                        valor={auditoria.CodigoDocumento!}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('EtapaDocumento') && (
                                <Grid item xs={6}>
                                    <CampoEtapaId
                                        deshabilitado={camposDeshabilitar.includes('EtapaDocumento')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        cambiarEtapa={cambiarEtapa}
                                        etapaSiguiente={etapasSiguientes || []}
                                        valor={auditoria.DescripcionEtapaDocumento}
                                    />
                                </Grid>
                            )}
                            {etapasSiguientes.length > 0 && estado !== EstadosModalAuditoria.VISUALIZACION && (
                                <Grid item xs={6}>
                                    <TextField
                                        disabled={false}
                                        fullWidth
                                        label={<Texto id="Auditoria.CampoCambioEtapaId.Nombre" />}
                                        onChange={(event) => {
                                            let esEtapaFinalizado: boolean = false;
                                            esEtapaFinalizado = validarEtapaFinalizado(event.target.value);
                                            if (esEtapaFinalizado) {
                                                setEstadoModal(true);
                                            }
                                            if (!esEtapaFinalizado) {
                                                manejarCambioCampo('EtapaDocumento', event.target.value);
                                                cambiarEtapa(Number(event.target.value), false);
                                            }
                                        }}
                                        select
                                        value={''}
                                    >
                                        {etapasSiguientes.map((etapa) => (
                                            <MenuItem key={etapa.EtapaId} value={etapa.EtapaId}>
                                                {etapa.EtapaDescripcion}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        {auditoria && !auditoria.AuditoriaId && (
                                            <Button
                                                color="primary"
                                                onClick={() => crearAuditoria(auditoria)}
                                                size="small"
                                                variant="contained"
                                            >
                                                <Texto id="boton.guardar" />
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <FinalizarAuditoria
                documentoId={auditoria.DocumentoId!}
                abierto={estadoModal}
                cerrar={cerraraFormularioFinalizado}
                conteos={conteos}
                cambiarEtapa={cambiarEtapa}
                cambioEstadoModalAuditoriaFinalizada={cambioEstadoModalAuditoriaFinalizada}
                setFinalizacionExitosa={() => setFinalizacionExitosa(true)}
                idEtapaFinalizado={idEtapaFinalizado}
            />
        </>
    );
};

export default AuditoriaFormulario;
