import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { urlNegocioCoimpresores } from 'Infotrack@Modelos/conexiones';

import IImpresora from './entidades/impresoras';

class ModeloImpresoras {
    public consultarImpresoras() {
        return manejadorRest<IRespuesta<IImpresora>>(() => urlNegocioCoimpresores.post(`Impresora`, {}), true);
    }
}

export default ModeloImpresoras;
