import { createStyles, Theme } from '@material-ui/core';

const estilos = ({ breakpoints, palette }: Theme) =>
  createStyles({
    contenedorFlex: {
      alignItems: 'center',
      background: `linear-gradient(bottom right, ${palette.primary.main}, ${palette.secondary.main})`,
      display: 'flex',
      height: 'calc(100% - 64px)',
      justifyContent: 'center',
      left: 0,
      position: 'absolute',
      top: '64px',
      width: '100%',
      [breakpoints.down('sm')]: {
        top: '56px'
      }
    },
    contenedorItems: {
      '& button': {
        marginTop: '3em'
      },
      textAlign: 'center'
    },
    iconoBoton: {
      marginLeft: '.5em'
    },
    subTitulo: {
      color: 'white',
      fontSize: '30px',
      fontWeight: 'lighter',
      textAlign: 'center',
      textShadow: '4px 4px 8px rgba(0,0,0,.5)'
    },
    titulo: {
      color: 'white',
      fontSize: '160px',
      fontWeight: 'lighter',
      lineHeight: 'normal',
      textAlign: 'center',
      textShadow: '10px 5px 4px rgba(0,0,0,.7)'
    }
  });

export default estilos;
