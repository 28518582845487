import { Card, CardContent, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import { BotonBuscar, BotonLimpiar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ITablaReversionMovimientosVistaProps } from '../interfaces';
import { acciones, columnas, opciones } from './constantes';

const TablaReversionMovimientosVista: React.FunctionComponent<ITablaReversionMovimientosVistaProps &
    InjectedIntlProps> = ({
    alCambiarValor,
    alCambiarValorAutocomplete,
    cargarDocumentos,
    consultar,
    consultaDocumentos,
    entidadesFiltros,
    filtros,
    intl: { formatMessage },
    refTabla,
    revertirDocumento,
}) => (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid alignItems="center" container justifyContent="center" spacing={2}>
                        <Grid item md={true} xs={12}>
                            <Autocomplete
                                disableClearable
                                getOptionLabel={({ BodegaDescripcion }) => BodegaDescripcion}
                                onChange={(_: any, bodega: any) => alCambiarValorAutocomplete('Bodega', bodega)}
                                options={entidadesFiltros.bodegas}
                                renderInput={(params) => (
                                    <TextField {...params} fullWidth label={<Texto id="reversion.Bodega" />} required />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={filtros.Bodega!==null?filtros.Bodega:undefined}
                            />
                        </Grid>
                        <Grid item md={true} xs={12}>
                            <Autocomplete
                                disableClearable
                                getOptionLabel={({ TipoDocumentoDescripcion }) => TipoDocumentoDescripcion}
                                onChange={(_: any, tipoDocumento: any) =>
                                    alCambiarValorAutocomplete('TipoDocumento', tipoDocumento)
                                }
                                options={entidadesFiltros.tiposDocumentos}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={<Texto id="reversion.TipoDocumentoDescripcion" />}
                                        required
                                    />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={filtros.TipoDocumento!==null?filtros.TipoDocumento:undefined}
                            />
                        </Grid>
                        <Grid item md={true} xs={12}>
                            <TextField
                                fullWidth
                                label={<Texto id="reversion.numeroDocumento" />}
                                name="CodigoDocumento"
                                onChange={alCambiarValor}
                                value={filtros.CodigoDocumento}
                            />
                        </Grid>
                        <Grid item md="auto" xs={6}>
                            <BotonLimpiar fullWidth onClick={() => cargarDocumentos(true)} />
                        </Grid>
                        <Grid item md="auto" xs={6}>
                            <BotonBuscar fullWidth onClick={() => cargarDocumentos()} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <Tabla
                actions={acciones(formatMessage, revertirDocumento)}
                columns={columnas(formatMessage)}
                data={consultar ? consultaDocumentos : []}
                title={<Texto id="recepcion.documentos" fontWeight="bold" />}
                tableRef={refTabla}
                options={opciones}
            />
        </Grid>
    </Grid>
);

export default injectIntl(TablaReversionMovimientosVista);
