import ModeloFamilia from 'Infotrack@Modelos/smartStock/familias';

export default class EntidadesFiltroItems {
  private modeloFamilia: ModeloFamilia;
  constructor() {
    this.modeloFamilia = new ModeloFamilia();
  }

  public consultarEntidadesFiltroItem = (EmpresaId: string) => {
    return Promise.all([this.modeloFamilia.consultarListaFamiliasPorFiltro({ EmpresaId })]).then((entidades) => ({
      familias: entidades[0].data.Entidades,
    }));
  };
}
