export default {
  'sidebar.administracion': 'Administración',
  'sidebar.modulo': 'Módulo',
  'modulo.agregarModulo': 'Crear Módulo',
  'modulo.confirmacionCreacion': '¿Está seguro que desea crear el registro?',
  'modulo.confirmacionEdicion': '¿Está seguro que desea editar el registro?',
  'modulo.confirmacionEliminacion': '¿Está seguro que desea eliminar el registro?',
  'modulo.modificarModulo': 'Editar Módulo',
  'modulo.nombreModulo': 'Nombre Módulo',
};
