import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import BarraLateral, { IBarraLateralProps } from 'Infotrack@Principal/baseGeneral/componentes/barraLateral';
import BarraNavegacion, { IBarraNavegacionProps } from 'Infotrack@Principal/baseGeneral/componentes/barraNavegacion';
import Encabezado from 'Infotrack@Principal/baseGeneral/componentes/encabezado';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import RutasCV from '../controladorVista/rutasCV';
import { IContenedorAplicacionVistaProps } from '../interfaces';
import estilos from './estilos';

const ContenedorAplicacionVista: React.FunctionComponent<
  PropsEstilosMaterialUI<IBarraLateralProps & IBarraNavegacionProps & IContenedorAplicacionVistaProps>
> = ({
  accionCerrar,
  accionBoton,
  componenteBarra: ComponenteBarra,
  componentePrincipal: ComponentePrincipal,
  classes,
  estadoBarra,
  esMovil,
  rutaActiva,
  rutaBaseActiva,
}) => (
  <div className={classes.divPrincipal}>
    <BarraNavegacion
      accionBoton={accionBoton}
      componente={ComponenteBarra}
      estadoBarra={estadoBarra}
      esMovil={esMovil}
    />
    <BarraLateral accionCerrar={accionCerrar} estadoBarra={estadoBarra} />
    <div
      className={clsx(classes.contenedorModulos, {
        [classes.contenedorModulosBarraAbierta]: estadoBarra || esMovil,
      })}
    >
      {location.pathname !== `/${rutaActiva}` && <Encabezado />}
      <RutasCV rutaBaseActiva={rutaBaseActiva} />
      {location.pathname === `/${rutaActiva}` && ComponentePrincipal && <ComponentePrincipal />}
    </div>
  </div>
);

export default withStyles(estilos)(ContenedorAplicacionVista);
