import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import Texto from '@infotrack/presentacion-componentes/texto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import { EstadosModalArchivoPDF } from 'Infotrack@Transversales/componentes/archivoPDF/enumeraciones';
import GenerarPDF from 'Infotrack@Transversales/componentes/archivoPDF/GenerarPDF';

import TiposProceso from '../../../modelos/negocioRefactor/enumeraciones/TiposProceso';
import { IEstadoGlobal } from '../../../reductores/interfacesReductores';
import Documentos, { IEntidadesDocumentos } from '../../../transversales/componentes/Documentos/Documentos';
import EntidadesDocumentoCM from './controladorModelo/EntidadesDocumentoCM';
import TrasladosCM from './controladorModelo/TrasladosCM';
import { EstadosModalTraslados } from './enumeraciones';
import ModalTraslado from './ModalTraslados';

const Traslado = () => {
    const entidadesDocumentoCM = useMemo(() => new EntidadesDocumentoCM(), []);
    const trasladosCM = useMemo(() => new TrasladosCM(), []);

    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);

    const [entidadesDocumento, setEntidadesDocumento] = useState<Partial<IEntidadesDocumentos>>({});
    const [estadoModal, setEstadoModal] = useState<EstadosModalTraslados>(EstadosModalTraslados.CERRADO);
    const [estadoArchivoPDF, setEstadoArchivoPDF] = useState<EstadosModalArchivoPDF>(EstadosModalArchivoPDF.CERRADO);
    const [documentoId, setDocumentoId] = useState<string | null>(null);
    const [ejecutarConsultarDocumentos, setEjecutarConsultarDocumentos] = useState<boolean>(false);
    const [archivoPDF, setArchivoPDF] = useState<string | null>(null);

    const esPrimeraCarga = useRef(true);

    useEffect(() => {
        consultarEntidadesFiltros();
    }, []);

    useEffect(() => {
        if (esPrimeraCarga.current) {
            esPrimeraCarga.current = false;
        } else {
            if (estadoModal === EstadosModalTraslados.CERRADO)
                setEjecutarConsultarDocumentos(
                    (ejecutarConsultarDocumentosActual) => !ejecutarConsultarDocumentosActual
                );
        }
    }, [estadoModal]);
    const consultarEntidadesFiltros = async () => {
        const entidadesDocumentoConsultadas = await entidadesDocumentoCM.consultarEntidadesDocumento(
            idEmpresa,
            idAgencia,
            idUsuario
        );
        setEntidadesDocumento(entidadesDocumentoConsultadas);
    };

    const generarArchivoPDF = async (documento: IDocumentoConsultaGeneral) => {
        const respuestaArchivoPDF = await trasladosCM.generarArchivoPDF(
            documento.DocumentoId,
            documento.TipoDocumentoId
        );
        setArchivoPDF(respuestaArchivoPDF);
        setEstadoArchivoPDF(EstadosModalArchivoPDF.VISUALIZACION);
    };

    const confirmarAnularDocumento = (documentoIdAnular: string) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => anularDocumento(documentoIdAnular),
            estado: true,
            mensaje: <Texto id={'MensajeTraslados.confirmarAnularDocumento'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const anularDocumento = async (documentoIdAnular: string) => {
        const anuladoExitoso = await trasladosCM.anularDocumento(documentoIdAnular);
        if (anuladoExitoso)
            await setEjecutarConsultarDocumentos(
                (ejecutarConsultarDocumentosActual) => !ejecutarConsultarDocumentosActual
            );
        manejadorDialogoGlobal({ estado: false });
    };

    const consultarTipoDocumentoBodega = (idBodega: number) => {};

    const consultarTipoDocumento = async (idBodega: number) => {
        const tiposDocumentosBodega = await entidadesDocumentoCM.consultarTipoDocumento(idBodega);
        setEntidadesDocumento({ ...entidadesDocumento, tiposDocumento: tiposDocumentosBodega });
    };

   
    return (
        <>
            <Documentos
                agregarDocumento={() => setEstadoModal(EstadosModalTraslados.CREACION)}
                anularDocumento={confirmarAnularDocumento}
                camposOcultarFiltros={['Cliente', 'ClienteId','ProveedorId']}
                camposOcultarTabla={['CodigoDocumentoPredecesor', 'DescripcionClienteId', 'ClienteId','DescipcionProveedorId']}
                consultarDocumentos={trasladosCM.consultarListaDocumentos}
                editarDocumento={(nuevoDocumentoId: string) => {
                    setDocumentoId(nuevoDocumentoId);
                    setEstadoModal(EstadosModalTraslados.EDICION);
                }}
                ejecutarConsultarDocumentos={ejecutarConsultarDocumentos}
                entidadesDocumento={entidadesDocumento}
                verDocumento={(nuevoDocumentoId: string) => {
                    setDocumentoId(nuevoDocumentoId);
                    setEstadoModal(EstadosModalTraslados.VISUALIZACION);
                }}
                descargarDocumentoPdf={generarArchivoPDF}
                consultarTipoDocumentoBodega={consultarTipoDocumento}
                limpiarFiltroTipoDocumento={()=> setEntidadesDocumento({ ...entidadesDocumento, tiposDocumento: [] })}
                esTraslado={true}
            />

            <ModalTraslado
                cerrar={() => setEstadoModal(EstadosModalTraslados.CERRADO)}
                documentoId={documentoId}
                establecerDocumentoId={(nuevoDocumentoId: string) => setDocumentoId(nuevoDocumentoId)}
                estado={estadoModal}
                descargarDocumentoPdf={generarArchivoPDF}
            />
            {archivoPDF && (
                <GenerarPDF
                    cerrar={() => setEstadoArchivoPDF(EstadosModalArchivoPDF.CERRADO)}
                    archivoPDF={archivoPDF}
                    estado={estadoArchivoPDF}
                />
            )}
        </>
    );
};

export default Traslado;
