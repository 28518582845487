import BodegaModelo from 'Infotrack@Modelos/smartStock/bodegas';
import IBodegasPersonaFiltro from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaPersonaFiltro';
import TiposDocumentosModelo from 'Infotrack@Modelos/smartStock/tiposDocumentos';
import ITipoDocumento from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/tipoDocumento';

export default class EntidadesReversionCM {
    private bodegaModelo: BodegaModelo;
    private tiposDocumentosModelo: TiposDocumentosModelo;

    constructor() {
        this.bodegaModelo = new BodegaModelo();
        this.tiposDocumentosModelo = new TiposDocumentosModelo();
    }

    public async consultarEntidadesReversion(
        bodegaPersonaFiltro: IBodegasPersonaFiltro,
        tipoDocFiltros: Partial<ITipoDocumento>
    ) {
        const respuesta = await Promise.all([
            this.bodegaModelo.consultarBodegasPersonasDoc(bodegaPersonaFiltro),
            this.tiposDocumentosModelo.consultarTiposDocumentosCompuestoFiltro(tipoDocFiltros),
        ]);
        return {
            bodegas: respuesta[0].data.Entidades,
            tiposDocumentos: respuesta[1].data.Entidades,
        };
    }
}
