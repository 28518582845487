import ModeloItemPropiedad from '../../../../modelos/smartStock/itemPropiedades';

export default class ItemPropiedadesCM {
    private modeloItemPropiedad: ModeloItemPropiedad;
    constructor() {
        this.modeloItemPropiedad = new ModeloItemPropiedad();
    }
    public consultarItemsPropiedades(ItemId: number){
        return this.modeloItemPropiedad
        .consultarItemsPropiedades(ItemId).
        then(({data: { Entidades }}) => Entidades.map((entidad, indice) => ({...entidad, IdTemporal: indice + 1})));
    }
}