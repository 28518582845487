import { useSelector } from 'react-redux';

import { UsuariosPersona } from '@infotrack/presentacion-modulosbase';

import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { seguridadToken } from 'Infotrack@Modelos/conexiones';
import React from 'react';

const Usuarios: React.FunctionComponent = () => {
    const idAplicacion = useSelector(
        (e: IEstadoGlobal) => e.estadoAutenticacion.usuarioAplicacionAgenciaActual!.IdAplicacion
    );
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    return (
        <UsuariosPersona
            idAplicacion={idAplicacion!}
            idEmpresa={idEmpresa!}
            seguridad={seguridadToken}
            idAgencia={idAgencia}
        />
    );
};

export default Usuarios;
