import { Agencias } from '@infotrack/presentacion-modulosbase';
import { seguridadToken } from 'Infotrack@Modelos/conexiones';
import React from 'react';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { useSelector } from 'react-redux';

const Agencia: React.FunctionComponent = () => {
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);

    return <Agencias  idEmpresa={idEmpresa}seguridad={seguridadToken} />;
};

export default Agencia;
