import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';
import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';

interface ICampoConteoFinalizacionProps {
    deshabilitado?: boolean;
    conteos: IAuditoriaConteo[];
    manejarCambio: (nombreCampo: keyof IAuditoriaConteo, nuevoValor: IAuditoriaConteo[keyof IAuditoriaConteo]) => void;
    valor: string | undefined;
    idRecursoTitulo: string;
}

const CampoConteoFinalizacion: FunctionComponent<ICampoConteoFinalizacionProps> = ({
    deshabilitado,
    conteos,
    manejarCambio,
    valor,
    idRecursoTitulo,
}) => {
    return (
        <Autocomplete
            disabled={deshabilitado}
            onChange={(_: ChangeEvent<{}>, value: IAuditoriaConteo | null) =>
                manejarCambio('AuditoriaConteoId', value ? value.AuditoriaConteoId : '')
            }
            options={conteos}
            getOptionLabel={(i) => i.DescripcionConteo}
            renderInput={(params) => (
                <TextField
                    helperText={conteos.length === 0 ? <Texto id="Auditoria.SinConteos" /> : ''}
                    {...params}
                    fullWidth
                    label={<Texto id={idRecursoTitulo} />}
                    required
                />
            )}
            value={conteos ? conteos.find((d) => d.AuditoriaConteoId === valor) || null : null}
        />
    );
};

export default CampoConteoFinalizacion;
