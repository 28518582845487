import ModeloAgencia from 'Infotrack@Modelos/administracion/agencia';

export default class AgenciasCM {
    private modeloAgencia: ModeloAgencia;

    constructor() {
        this.modeloAgencia = new ModeloAgencia();
    }

    public async consultarAgenciasFiltro(idEmpresa:string) {
        const respuesta = await this.modeloAgencia.agenciaFiltro({IdEmpresa:idEmpresa});
        return respuesta.data.Entidades;
    }

   
}
