export default {
  'sidebar.procesos': 'Procesos',
  'sidebar.almacenamiento': 'Traslado entre divisiones',
  'sidebar.trasladosdivisiones': 'Traslado entre divisiones',
  'almacenamiento.datosDocumento': 'Datos documento',
  'almacenamiento.detallesDelDocumento': 'Detalles del documento',
  'almacenamiento.etapaDescripcion': 'Etapa',
  'almacenamiento.cambiarEtapa': 'Cambiar etapa',
  'almacenamiento.bodega': 'Bodega',
  'almacenamiento.bodegaOrigen': 'Bodega origen',
  'almacenamiento.documentoRelacionado': 'Documento relacionado',
  'almacenamiento.observaciones': 'Observaciones',
  'almacenamiento.proveedor': 'Proveedor',
  'almacenamiento.confirmarAlmacenamiento': 'Confirmar almacenamiento',
  'almacenamiento.numeroDocumento': 'N° Documento',
  'almacenamiento.documentoPadre': 'Documento padre',
  'almacenamiento.almacenamiento': 'Traslado entre divisiones',
  'almacenamiento.cambiarEstado': 'Cambiar estado',
  'almacenamiento.documento.CodigoDocumento': 'Código',
  'almacenamiento.documento.DocumentoRelacionado': 'Documento relacionado',
  'almacenamiento.documento.DocumentoPadre': 'Documento padre',
  'almacenamiento.documento.DescripcionProveedor': 'Proveedor',
  'almacenamiento.documento.FechaCreacion': 'Fecha creación',
  'almacenamiento.documento.EtapaDescripcion': 'Etapa',
  'almacenamiento.documento.Observacion': 'Observación',
  'almacenamiento.documentoDetalle.DescripcionItem': 'Producto',
  'almacenamiento.documentoDetalle.CodigoBarras': 'Código de barras',
  'almacenamiento.documentoDetalle.CantidadSolicitada': 'Cantidad solicitada',
  'almacenamiento.documentoDetalle.CantidadRecibida': 'Cantidad almacenada',
  'almacenamiento.documentoDetalle.iniciarAlmacenamiento': 'Iniciar almacenamiento',
  'almacenamiento.documentoDetalle.confirmarAlmacenamiento': 'Confirmar almacenamiento',
  'almacenamiento.documentos.verDetalle': 'Ver detalle',
  'almacenamiento.almacenamiento.detalle': 'Detalle almacenamiento',
  'almacenamiento.documentos.alertaFiltroBodega': 'Por favor seleccione una bodega',
  'almacenamiento.documentos.alertaFiltroProveedorDocumento':
    'Por favor seleccione un proveedor o ingrese un número de documento',
  'almacenamiento.documentos.alertaLotes': 'Este producto requiere registrar un número de lote',
  'almacenamiento.documentos.alertaSeriado': 'Este producto requiere registrar un número de Seriado',
  'almacenamiento.documentos.alertaCantidadRecibida':
    'La cantidad recibida debe ser mayor a la ingresada anteriormente',
  'almacenamiento.documentos.alertaFIFO': 'Este producto requiere una fecha de vencimiento',
  'almacenamiento.documentosdocumentoDetalle.edicionParcial': 'Parcial',
  'almacenamiento.documentosdocumentoDetalle.edicionTotal': 'Total',
  'almacenamiento.documentosdocumentoDetalle.almacenamiento': 'Traslado entre divisiones',
  'almacenamiento.documentosdocumentoDetalle.DivisionDestino': 'División destino',
  'almacenamiento.documentosdocumentoDetalle.DivisionOrigen': 'División origen',
  'almacenamiento.documentosdocumentoDetalle.CantidadRecibida': 'Cantidad',
  'almacenamiento.documentosdocumentoDetalle.Lote': 'Lote',
  'almacenamiento.documentosdocumentoDetalle.FechaVencimiento': 'Fecha vencimiento',
  'almacenamiento.documentosdocumentoDetalle.Serial': 'Serial',
  'almacenamiento.confirmacionInicioAlmacenamiento': 'Está seguro que desea iniciar el almacenamiento?',
  'almacenamiento.confirmacionFinAlmacenamiento': 'Está seguro que desea terminar el almacenamiento?',
  'almacenamiento.iniciarAlistamiento': 'Está seguro que desea iniciar el alistamiento?',
  'almacenamiento.confirmarAlistamiento': 'Está seguro que desea confirmar el alistamiento?',
  'almacenamiento.iniciarTransito': 'Está seguro que desea enviar a tránsito?',
  'almacenamiento.iniciarAlmacenamiento': 'Está seguro que desea iniciar el almacenamiento?',
  'almacenamiento.enviarAPlataformaDestino': 'Está seguro que desea enviar a plataforma de destino?',
  'almacenamiento.confimarAlmacenamiento': 'Está seguro que desea confirmar el almacenamiento?',
  'CambiarEstado': 'Cambiar estado',
};
