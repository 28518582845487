import AddIcon from '@material-ui/icons/Add';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Action, Column, Options } from '@infotrack/presentacion-componentes/tabla';
import IFiltrosItem from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/filtrosItem';
import IItem from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/item';
import IProductoPrincipal from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/productoPrincipal';
import IItemsTiposPropiedades from 'Infotrack@Modelos/smartStock/itemTiposPropiedades/entidades/itemTiposPropiedad';
import { BotonAgregar, BotonExportar, BotonRefrescar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import { exportarExcel } from 'Infotrack@Transversales/utilitarios/funcionesGenerales';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IInputsItemPropiedades, IInputsItemUnidad } from '../interfaces';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import { IconButton } from '@material-ui/core';

export const columnas = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IItem>> => [
    { field: 'CodigoBarras', title: formatMessage({ id: 'gestionproducto.CodigoBarras' }) },
    {
        field: 'FamiliaDescripcion',
        title: formatMessage({ id: 'gestionproducto.FamiliaDescripcion' }),
    },
    {
        field: 'CodigoEmpresa',
        title: formatMessage({ id: 'gestionproducto.CodigoEmpresa' }),
    },
    { field: 'ClasificacionDescripcion', title: formatMessage({ id: 'gestionproducto.ClasificacionDescripcion' }) },
    { field: 'DescripcionItem', title: formatMessage({ id: 'gestionproducto.DescripcionItem' }) },
    { field: 'Abreviatura', title: formatMessage({ id: 'gestionproducto.Abreviatura' }) },

    {
        field: 'DescripcionUnidad',
        title: formatMessage({ id: 'gestionproducto.DescripcionUnidad' }),
    },
    {
        field: 'Lotes',
        title: formatMessage({ id: 'gestionproducto.Lotes' }),
        type: 'boolean',
    },
    {
        field: 'Seriado',
        title: formatMessage({ id: 'gestionproducto.Seriado' }),
        type: 'boolean',
    },
    {
        field: 'Serialconsecutivo',
        title: formatMessage({ id: 'gestionproducto.Serialconsecutivo' }),
        type: 'boolean',
    },
    {
        field: 'ManejaPeso',
        title: formatMessage({ id: 'gestionproducto.ManejaPeso' }),
        type: 'boolean',
    },
    {
        field: 'FIFO',
        title: formatMessage({ id: 'gestionproducto.FIFO' }),
        type: 'boolean',
    },
    {
        field: 'FEFO',
        title: formatMessage({ id: 'gestionproducto.FEFO' }),
        type: 'boolean',
    },
    { field: 'Estado', title: formatMessage({ id: 'gestionproducto.Estado' }), type: 'boolean' },
];

export const opciones: Options<IItem> = {
    search: false,
    maxBodyHeight: '50vh',
};

export const acciones = (
    alAbrirFormulario: (item?: IItem) => void,
    alEliminarItem: (item: IItem) => void,
    recargarTabla: () => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string,
    consultaProductosExportar: () => Promise<IProductoPrincipal[]>,
    descargarPlantilla: () => void,
    handleCargarArchivo: (event: React.ChangeEvent<HTMLInputElement>) => void
): Array<Action<IItem>> => [
    {
        icon: () => <EditIcon color="action" />,
        tooltip: formatMessage({ id: 'boton.editar' }),
        onClick: (_, valor) => alAbrirFormulario(valor as IItem),
    },
    {
        icon: () => <DeleteIcon color="action" />,
        tooltip: formatMessage({ id: 'boton.eliminar' }),
        onClick: (_, valor) => alEliminarItem(valor as IItem),
    },
    {
        icon: 'refresh',
        onClick: recargarTabla,
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.refrescar' }),
    },
    {
        //component: BotonAgregar,
        icon: 'add',
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
        onClick: () => alAbrirFormulario(),
    },
    {
        //component: BotonExportar,
        icon: () => <CloudDownloadIcon />,
        isFreeAction: true,
        tooltip: formatMessage({ id: 'Comunes.ExportarXLSX' }),
        onClick: () =>
            consultaProductosExportar().then((data) => {
                exportarExcel(
                    formatearProductosParaExportar(data, formatMessage),
                    formatMessage({ id: 'gestionproducto.nombreArchivoExcel' })
                );
            }),
    },

    {
        icon: () => <GetAppIcon />,
        onClick: descargarPlantilla,
        isFreeAction: true,
        tooltip: formatMessage({ id: 'cargaMasiva.DescargarArchivo' }),
    },
    {
        //icon: () => <PublishIcon />,
        icon: () => (
            <IconButton component="label" size="small" style={{ backgroundColor: 'transparent' }}>
                <PublishIcon />
                <input onChange={handleCargarArchivo} style={{ display: 'none' }} type="file" />
            </IconButton>
        ),
        onClick: () => () => {},
        isFreeAction: true,
        tooltip: formatMessage({ id: 'cargaMasiva.CargarArchivo' }),
    },
];

const formatearProductosParaExportar = (
    productos: IProductoPrincipal[],
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor, values?: object) => string
) => {
    const itemsFormateados = [];
    itemsFormateados.push([
        formatMessage({ id: 'gestionproducto.CodigoBarras' }),
        formatMessage({ id: 'gestionproducto.FamiliaDescripcion' }),
        formatMessage({ id: 'gestionproducto.ClasificacionDescripcion' }),
        formatMessage({ id: 'gestionproducto.DescripcionItem' }),
        formatMessage({ id: 'gestionproducto.Abreviatura' }),
        formatMessage({ id: 'gestionproducto.DescripcionUnidad' }),
        formatMessage({ id: 'gestionproducto.Lotes' }),
        formatMessage({ id: 'gestionproducto.Seriado' }),
        formatMessage({ id: 'gestionproducto.Serialconsecutivo' }),
        formatMessage({ id: 'gestionproducto.Manejapeso' }),
        formatMessage({ id: 'gestionproducto.Contenedor' }),
        formatMessage({ id: 'gestionproducto.Importado' }),
        formatMessage({ id: 'gestionproducto.FIFO' }),
        formatMessage({ id: 'gestionproducto.FEFO' }),
        formatMessage({ id: 'gestionproducto.Estado' }),
    ]);
    itemsFormateados.push(
        ...productos.map((item: IProductoPrincipal) => [
            item.CodigoBarras,
            item.FamiliaDescripcion,
            item.ClasificacionDescripcion,
            item.DescripcionItem,
            item.Abreviatura,
            item.DescripcionUnidad,
            item.Lotes ? formatMessage({ id: 'Comunes.Si' }) : formatMessage({ id: 'Comunes.No' }),
            item.Seriado ? formatMessage({ id: 'Comunes.Si' }) : formatMessage({ id: 'Comunes.No' }),
            item.Serialconsecutivo ? formatMessage({ id: 'Comunes.Si' }) : formatMessage({ id: 'Comunes.No' }),
            item.ManejaPeso ? formatMessage({ id: 'Comunes.Si' }) : formatMessage({ id: 'Comunes.No' }),
            item.Contenedor ? formatMessage({ id: 'Comunes.Si' }) : formatMessage({ id: 'Comunes.No' }),
            item.Importado ? formatMessage({ id: 'Comunes.Si' }) : formatMessage({ id: 'Comunes.No' }),
            item.FIFO ? formatMessage({ id: 'Comunes.Si' }) : formatMessage({ id: 'Comunes.No' }),
            item.FEFO ? formatMessage({ id: 'Comunes.Si' }) : formatMessage({ id: 'Comunes.No' }),
            item.Estado ? formatMessage({ id: 'Comunes.Activo' }) : formatMessage({ id: 'Comunes.Inactivo' }),
        ])
    );
    return itemsFormateados;
};

export const opcionesTablaFormulario: Options<IInputsItemPropiedades> = {
    search: false,
    showTitle: true,
    maxBodyHeight: '35vh',
};

export const accionesTablaFormulario = (
    alAbrirFormularioUnidades: (itemUnidad?: IInputsItemUnidad) => void,
    alEliminarUnidad: (IdTemporal: number) => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Action<IInputsItemUnidad>> => [
    {
        component: BotonAgregar,
        icon: () => <AddIcon />,
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
        onClick: () => alAbrirFormularioUnidades(),
    },
    {
        icon: () => <EditIcon color="action" />,
        tooltip: formatMessage({ id: 'boton.editar' }),
        onClick: (_, valor) => alAbrirFormularioUnidades(valor as IInputsItemUnidad),
    },
    {
        icon: () => <DeleteIcon color="action" />,
        tooltip: formatMessage({ id: 'boton.eliminar' }),
        onClick: (_, valor: any) => alEliminarUnidad(valor.IdTemporal),
    },
];
export const columnasTablaFormulario = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IItemsTiposPropiedades>> => [
    { title: formatMessage({ id: 'gestionproducto.DescripcionUnidad' }), field: 'DescripcionUnidad' },
    { title: formatMessage({ id: 'gestionproducto.Ancho' }), field: 'Ancho' },
    { title: formatMessage({ id: 'gestionproducto.Alto' }), field: 'Alto' },
    { title: formatMessage({ id: 'gestionproducto.Peso' }), field: 'Peso' },
    { title: formatMessage({ id: 'gestionproducto.Largo' }), field: 'Largo' },
    { field: 'Estado', title: formatMessage({ id: 'gestionproducto.Estado' }), type: 'boolean' },
];

export const columnasTablaFormularioPropiedades = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IItemsTiposPropiedades>> => [
    { title: formatMessage({ id: 'gestionproducto.Propiedades.ProductoPropiedadTexto' }), field: 'ItemPropiedadTexto' },
    { title: formatMessage({ id: 'gestionproducto.Propiedades.ProductoPropiedadCantidad' }), field: 'Cantidad' },
    {
        title: formatMessage({ id: 'gestionproducto.Propiedades.ProductoPropiedadEstado' }),
        field: 'Estado',
        type: 'boolean',
    },
    {
        title: formatMessage({ id: 'gestionproducto.Propiedades.ProductoTipoPropiedadDescripcion' }),
        field: 'ItemTipoPropiedadDescripcion',
    },
];

export const accionesTablaFormularioPropiedades = (
    alAbrirFormularioPropiedades: (itemPropiedad?: IInputsItemPropiedades) => void,
    alEliminarPropiedad: (IdTemporal: number) => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Action<IInputsItemPropiedades>> => [
    {
        component: BotonAgregar,
        icon: () => <AddIcon />,
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
        onClick: () => alAbrirFormularioPropiedades(),
    },
    {
        icon: () => <EditIcon color="action" />,
        tooltip: formatMessage({ id: 'boton.editar' }),
        onClick: (_, valor) => alAbrirFormularioPropiedades(valor as IInputsItemPropiedades),
    },
    {
        icon: () => <DeleteIcon color="action" />,
        tooltip: formatMessage({ id: 'boton.eliminar' }),
        onClick: (_, valor: any) => alEliminarPropiedad(valor.IdTemporal),
    },
];
