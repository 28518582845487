export default {
  'sidebar.administracion': 'Administration',
  'sidebar.usuariospersona': 'Users',
  'sidebar.agencias': 'Agencies',
  'sidebar.aplicacionestilo': 'Aplication Style',
  'aplicacionEstilo.agregarAplicacionEstilo': 'Create Aplication Style',
  'aplicacionEstilo.confirmacionCreacion': 'Are you sure to create the registry?',
  'aplicacionEstilo.confirmacionEdicion': 'You are sure you want to edit the registry?',
  'aplicacionEstilo.confirmacionEliminacion': 'Are you sure you want to delete the registry?',
  'aplicacionEstilo.modificarAplicacionEstilo': 'Edit Aplication Style',
  'aplicacionEstilo.eliminarAplicacionEstilo': 'Delete Aplication Style',
  'aplicacionEstilo.nombreEstilo': 'Name Style',
  'aplicacionEstilo.aplicacionEstilo': 'Style Aplication',
};
