enum TiposProceso {
    ORDEN_DE_ENTRADA = 1,
    ORDEN_DE_SALIDA = 2,
    ENTRADA_DE_INVENTARIO = 3,
    SALIDA_DE_INVENTARIO = 4,
    COMPRA = 5,
    VENTA = 6,
    ENTRADA_POR_DEVOLUCION = 7,
    SALIDA_POR_DEVOLUCION = 8,
    TRASLADO = 9,
    AUDITORIA = 10,
    ORDEN_TRASLADO = 11
}

export default TiposProceso;
