import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';

import ModeloBodegas from 'Infotrack@Modelos/smartStock/bodegas';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IBodegaFiltro from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaFiltro';
import IBodegaPropiedades from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaPropiedades';

export default class BodegasCM {
    private modeloBodegas: ModeloBodegas;

    constructor() {
        this.modeloBodegas = new ModeloBodegas();
    }

    public async consultarListaBodegasCompuestaFiltro(bodegaFiltro: IBodegaFiltro) {
        const respuesta = await this.modeloBodegas.consultarListaBodegasCompuestaFiltro(bodegaFiltro);
        return respuesta.data.Entidades;
    }

    public async guardarBodegaCompuesta(bodegaPropiedades: IBodegaPropiedades) {
        return this.modeloBodegas.guardarBodegaCompuesta(bodegaPropiedades);
    }

    public async editarBodegaCompuesta(bodegaPropiedades: IBodegaPropiedades) {
        return this.modeloBodegas.editarBodegaCompuesta(bodegaPropiedades);
    }

    public async eliminarBodegaCompuesto(bodega: IBodega) {
        return this.modeloBodegas.eliminarBodegaCompuesto(bodega);
    }

    public validarEntidad({
        Alto,
        Ancho,
        BodegaCodigo,
        BodegaDescripcion,
        CiudadId,
        Largo,
        Latitud,
        Longitud,
        TipoBodegaId,
        Direccion,
        AgenciaId,
    }: IBodega) {
        if (
            Alto === 0 ||
            Ancho === 0 ||
            BodegaCodigo === '' ||
            BodegaDescripcion === '' ||
            CiudadId === 0 ||
            Largo === 0 ||
            Latitud === 0 ||
            Longitud === 0 ||
            TipoBodegaId === 0 ||
            Direccion === ''||
            AgenciaId ===''
        ) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else if (Ancho * Alto * Largo > 9000000) {
            notificacionGlobal('gestionBodega.dimensionesInvalidas', 6000, 'warning', true);
            return false;
        } else if (!/^[-+]?([1-8]?\d(\.[0-9]{1,6})?|90(\.0{1,1})?)$/.test(Latitud.toString())) {
            notificacionGlobal('Latitud incorrecta', 6000, 'warning', true);
            return false;
        } else if (
            !/^(\+|-)?(?:180(?:(?:\.0{1,1})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/.test(
                Longitud.toString()
            )
        ) {
            notificacionGlobal('Longitud  incorrecta', 6000, 'warning', true);
            return false;
        } else return true;
    }
}
