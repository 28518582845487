import MomentUtils from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DialogoGlobal from 'Infotrack@Principal/baseGeneral/componentes/dialogoGlobal';
import IndicadorCargaGlobal from 'Infotrack@Principal/baseGeneral/componentes/indicadorCargaGlobal';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import Temas from 'Infotrack@Transversales/constantes/temas';
import Lenguajes from 'Infotrack@Transversales/internacionalizacion';
import moment from 'moment';
import 'moment/locale/es';
import React from 'react';
import { IntlProvider } from 'react-intl';
import 'react-notifications/lib/notifications.css';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import PersistorEstado from './componentes/persistorEstado';
import { IEstadoAPropiedadesContextoGeneral } from './interfaces';

const ContextoGeneral: React.FunctionComponent<IEstadoAPropiedadesContextoGeneral> = ({
    children,
    lenguajeActivo,
    temaActivo,
}) => {
    React.useEffect(() => {
        moment.locale(lenguajeActivo);
    });

    return (
        <MuiThemeProvider theme={Temas[temaActivo]}>
            <IntlProvider
                locale={Lenguajes[lenguajeActivo].locale}
                messages={Lenguajes[lenguajeActivo].messages}
                defaultLocale="es"
            >
                <Router>
                    <Switch>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={lenguajeActivo}>
                            <>
                                {children}
                                <PersistorEstado />
                                <DialogoGlobal />
                                <IndicadorCargaGlobal />
                            </>
                        </MuiPickersUtilsProvider>
                    </Switch>
                </Router>
            </IntlProvider>
        </MuiThemeProvider>
    );
};

const estadoAPropiedades = ({ estadoConfiguracion }: IEstadoGlobal): IEstadoAPropiedadesContextoGeneral => ({
    lenguajeActivo: estadoConfiguracion.lenguajeActivo,
    temaActivo: estadoConfiguracion.temaActivo,
});

export default connect<IEstadoAPropiedadesContextoGeneral, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(ContextoGeneral);
