import { Button, Card, CardContent, Checkbox, Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import IAccionEtapaDocumentoCompuesto from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/administracion/IAccionEtapaDocumentoCompuesto';
import IAccionEtapaDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAccionEtapaDocumento';
import { EstadosModalTipoDocumento } from '../enumeraciones';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';

interface IEtapasDocumentoProps {
    estado: EstadosModalTipoDocumento;
    manejarCambioCampoAccionEtapa: (
        accionEtapaDocumentoId: number,
        nombreCampo: keyof IAccionEtapaDocumentoCompuesto,
        nuevoValor: IAccionEtapaDocumentoCompuesto[keyof IAccionEtapaDocumentoCompuesto]
    ) => void;
    tipoDocumento: ITipoDocumento;
    accionesEtapas: IAccionEtapaDocumentoCompuesto[];
    editarAccionesEtapas: (accionesEtapas: IAccionEtapaDocumento[]) => any;
}

const EtapasDocumento: FunctionComponent<IEtapasDocumentoProps> = ({
    estado,
    manejarCambioCampoAccionEtapa,
    tipoDocumento,
    accionesEtapas,
    editarAccionesEtapas,
}) => {
    return (
        <Grid item xs={12}>
            <Card variant="outlined">
                <Grid container>
                    <Grid item xs={12}>
                        <Tabla
                            columns={[
                                {
                                    field: 'EtapaDescripcion',
                                    title: <Texto id="ModalTipoDocumento.EtapaDescripcion" />,
                                },
                                {
                                    render: (accionEtapaDocumento: IAccionEtapaDocumentoCompuesto) => (
                                        <Checkbox
                                            checked={accionEtapaDocumento.AfectaInventario}
                                            disabled={estado === EstadosModalTipoDocumento.VISUALIZACION}
                                            onChange={(_, checked) =>
                                                manejarCambioCampoAccionEtapa(
                                                    accionEtapaDocumento.AccionEtapaDocumentoId,
                                                    'AfectaInventario',
                                                    checked
                                                )
                                            }
                                        />
                                    ),
                                    title: <Texto id="ModalTipoDocumento.AfectaInventario" />,
                                },
                                {
                                    render: (accionEtapaDocumento: IAccionEtapaDocumentoCompuesto) => (
                                        <Checkbox
                                            checked={accionEtapaDocumento.PermiteEditarDocumento}
                                            disabled={estado === EstadosModalTipoDocumento.VISUALIZACION}
                                            onChange={(_, checked) =>
                                                manejarCambioCampoAccionEtapa(
                                                    accionEtapaDocumento.AccionEtapaDocumentoId,
                                                    'PermiteEditarDocumento',
                                                    checked
                                                )
                                            }
                                        />
                                    ),
                                    title: <Texto id="ModalTipoDocumento.PermiteEditarDocumento" />,
                                },
                                {
                                    render: (accionEtapaDocumento: IAccionEtapaDocumentoCompuesto) => (
                                        <Checkbox
                                            checked={accionEtapaDocumento.PermiteAnularDocumento}
                                            disabled={estado === EstadosModalTipoDocumento.VISUALIZACION}
                                            onChange={(_, checked) =>
                                                manejarCambioCampoAccionEtapa(
                                                    accionEtapaDocumento.AccionEtapaDocumentoId,
                                                    'PermiteAnularDocumento',
                                                    checked
                                                )
                                            }
                                        />
                                    ),
                                    title: <Texto id="ModalTipoDocumento.PermiteAnularDocumento" />,
                                },
                                {
                                    render: (accionEtapaDocumento: IAccionEtapaDocumentoCompuesto) => (
                                        <Checkbox
                                            checked={accionEtapaDocumento.PermiteAgregarDetalle}
                                            disabled={estado === EstadosModalTipoDocumento.VISUALIZACION}
                                            onChange={(_, checked) =>
                                                manejarCambioCampoAccionEtapa(
                                                    accionEtapaDocumento.AccionEtapaDocumentoId,
                                                    'PermiteAgregarDetalle',
                                                    checked
                                                )
                                            }
                                        />
                                    ),
                                    title: <Texto id="ModalTipoDocumento.PermiteAgregarDetalle" />,
                                },
                                {
                                    render: (accionEtapaDocumento: IAccionEtapaDocumentoCompuesto) => (
                                        <Checkbox
                                            checked={accionEtapaDocumento.PermiteEditarDetalle}
                                            disabled={estado === EstadosModalTipoDocumento.VISUALIZACION}
                                            onChange={(_, checked) =>
                                                manejarCambioCampoAccionEtapa(
                                                    accionEtapaDocumento.AccionEtapaDocumentoId,
                                                    'PermiteEditarDetalle',
                                                    checked
                                                )
                                            }
                                        />
                                    ),
                                    title: <Texto id="ModalTipoDocumento.PermiteEditarDetalle" />,
                                },
                                {
                                    render: (accionEtapaDocumento: IAccionEtapaDocumentoCompuesto) => (
                                        <Checkbox
                                            checked={accionEtapaDocumento.PermiteEliminarDetalle}
                                            disabled={estado === EstadosModalTipoDocumento.VISUALIZACION}
                                            onChange={(_, checked) =>
                                                manejarCambioCampoAccionEtapa(
                                                    accionEtapaDocumento.AccionEtapaDocumentoId,
                                                    'PermiteEliminarDetalle',
                                                    checked
                                                )
                                            }
                                        />
                                    ),
                                    title: <Texto id="ModalTipoDocumento.PermiteEliminarDetalle" />,
                                },
                                {
                                    render: (accionEtapaDocumento: IAccionEtapaDocumentoCompuesto) => (
                                        <Checkbox
                                            checked={accionEtapaDocumento.PermiteCambiarEtapa}
                                            disabled={estado === EstadosModalTipoDocumento.VISUALIZACION}
                                            onChange={(_, checked) =>
                                                manejarCambioCampoAccionEtapa(
                                                    accionEtapaDocumento.AccionEtapaDocumentoId,
                                                    'PermiteCambiarEtapa',
                                                    checked
                                                )
                                            }
                                        />
                                    ),
                                    title: <Texto id="ModalTipoDocumento.PermiteCambiarEtapa" />,
                                },
                                {
                                    render: (accionEtapaDocumento: IAccionEtapaDocumentoCompuesto) => (
                                        <Checkbox
                                            checked={accionEtapaDocumento.AccionMovil}
                                            disabled={
                                                estado === EstadosModalTipoDocumento.VISUALIZACION ||
                                                !tipoDocumento.SincronizaMovil
                                            }
                                            onChange={(_, checked) =>
                                                manejarCambioCampoAccionEtapa(
                                                    accionEtapaDocumento.AccionEtapaDocumentoId,
                                                    'AccionMovil',
                                                    checked
                                                )
                                            }
                                        />
                                    ),
                                    title: <Texto id="ModalTipoDocumento.AccionMovil" />,
                                },
                                {
                                    render: (accionEtapaDocumento: IAccionEtapaDocumentoCompuesto) => (
                                        <Checkbox
                                            checked={accionEtapaDocumento.EtapaMovil}
                                            disabled={
                                                estado === EstadosModalTipoDocumento.VISUALIZACION ||
                                                !tipoDocumento.SincronizaMovil
                                            }
                                            onChange={(_, checked) =>
                                                manejarCambioCampoAccionEtapa(
                                                    accionEtapaDocumento.AccionEtapaDocumentoId,
                                                    'EtapaMovil',
                                                    checked
                                                )
                                            }
                                        />
                                    ),
                                    title: <Texto id="ModalTipoDocumento.EtapaMovil" />,
                                },
                            ]}
                            data={accionesEtapas}
                            options={{ padding: 'dense' }}
                        />
                    </Grid>
                    {estado !== EstadosModalTipoDocumento.VISUALIZACION && (
                        <Grid item xs={12}>
                            <CardContent>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            onClick={() => editarAccionesEtapas(accionesEtapas)}
                                            size="small"
                                            variant="contained"
                                        >
                                            <Texto id="ModalTipoDocumento.BotonGuardar" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Grid>
                    )}
                </Grid>
            </Card>
        </Grid>
    );
};
export default EtapasDocumento;
