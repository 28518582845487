import React, { FunctionComponent, useMemo, useState } from 'react';

import { Button, Dialog, DialogContent, Grid } from '@material-ui/core';

import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import CampoConteoFinalizacion from './Campos/CampoConteoFinalizacion';
import AuditoriaConteoCM from '../../controladorModelo/AuditoriaConteoCM';
import AuditoriaCM from '../../controladorModelo/AuditoriaCM';
import Texto from '@infotrack/presentacion-componentes/texto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

interface IFinalizarAuditoriaProps {
    documentoId: string;
    abierto: boolean;
    cerrar: () => void;
    conteos: IAuditoriaConteo[];
    cambiarEtapa: (etapaId: number, esEtapaFinalizado: boolean) => void;
    cambioEstadoModalAuditoriaFinalizada: () => void;
    setFinalizacionExitosa: React.Dispatch<React.SetStateAction<boolean>>;
    idEtapaFinalizado: number;
}

const FinalizarAuditoria: FunctionComponent<IFinalizarAuditoriaProps> = ({
    documentoId,
    abierto,
    cerrar,
    conteos,
    cambiarEtapa,
    cambioEstadoModalAuditoriaFinalizada,
    setFinalizacionExitosa,
    idEtapaFinalizado,
}) => {
    const auditoriaConteoCM = useMemo(() => new AuditoriaConteoCM(), []);
    const auditoriaCM = useMemo(() => new AuditoriaCM(), []);

    const [conteoSeleccionado, setConteoSeleccionado] = useState<IAuditoriaConteo | null>(null);

    const manejarCambioConteo = (
        nombreCampo: keyof IAuditoriaConteo,
        nuevoValor: IAuditoriaConteo[keyof IAuditoriaConteo]
    ) => {
        setConteoSeleccionado((conteoActual) => ({ ...conteoActual!, [nombreCampo]: nuevoValor }));
    };

    const confirmarFinalizarConteo = () => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => finalizarConteo(),
            estado: true,
            mensaje: '',
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const finalizarConteo = async () => {
        const marcarConteoDeFinalizacion = await auditoriaConteoCM.marcarConteo(conteoSeleccionado!.AuditoriaConteoId);
        manejadorDialogoGlobal({ estado: false });

        if (marcarConteoDeFinalizacion) {
            cambiarEtapa(idEtapaFinalizado, true);
            cambioEstadoModalAuditoriaFinalizada();
            cerrar();
        }
    };

    return (
        <Dialog fullWidth maxWidth="xs" open={abierto}>
            <ModalEncabezado cerrar={cerrar} titulo={'Finalizar Auditoria'} />
            <DialogContent>
                <Grid alignItems="center" container spacing={2}>
                    <Grid container spacing={2}>
                        {conteos && (
                            <Grid item xs={12}>
                                <CampoConteoFinalizacion
                                    deshabilitado={conteos.length === 0}
                                    manejarCambio={manejarCambioConteo}
                                    conteos={conteos}
                                    valor={conteoSeleccionado ? conteoSeleccionado.AuditoriaConteoId : ''}
                                    idRecursoTitulo={'AuditoriaConteo.CampoConteoAFinalizar.Nombre'}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    {conteoSeleccionado && (
                                        <Button
                                            color="primary"
                                            onClick={() => confirmarFinalizarConteo()}
                                            size="small"
                                            variant="contained"
                                        >
                                            <Texto id="Finalizar" />
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default FinalizarAuditoria;
