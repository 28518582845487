import { Dialog, DialogContent, Grid } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Texto from '@infotrack/presentacion-componentes/texto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

import IDocumentoConsultaGeneral, {
    DOCUMENTO_CONSULTA_GENERAL_POR_DEFECTO,
} from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';
import IItemsConsulta from 'Infotrack@Modelos/smartStock/items/entidades/IItemsConsulta';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import DetallesFormulario from 'Infotrack@Transversales/componentes/DetallesFormulario/DetallesFormulario';
import EstadosDetallesFormulario from 'Infotrack@Transversales/componentes/DetallesFormulario/enumeraciones';
import DocumentoBuscador from 'Infotrack@Transversales/componentes/DocumentoBuscador/DocumentoBuscador';
import DocumentoValorTotal from 'Infotrack@Transversales/componentes/DocumentoValorTotal/DocumentoValorTotal';
import { EstadosFormularioDocumento } from 'Infotrack@Transversales/componentes/FormularioDocumento/enumeraciones';
import FormularioDocumento, {
    IEntidadesFormularioDocumento,
} from 'Infotrack@Transversales/componentes/FormularioDocumento/FormularioDocumento';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import TablaDetalles from 'Infotrack@Transversales/componentes/TablaDetalles/TablaDetalles';
import TablaDetallesPlantilla from 'Infotrack@Transversales/componentes/TablaDetallesPlantilla/TablaDetallesPlantilla';

import {
    CAMPOS_DESHABILITAR_DETALLES_FORMULARIO,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_DOCUMENTO_BASE,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION,
    CAMPOS_EDITABLES_DETALLES_PLANTILLA,
    CAMPOS_OCULTAR_DETALLES_FORMULARIO,
    CAMPOS_OCULTAR_DETALLES_PLANTILLA,
    CAMPOS_OCULTAR_DETALLES_TABLA,
    CAMPOS_OCULTAR_DOCUMENTO_FORMULARIO,
    ENTIDADES_DOCUMENTO_POR_DEFECTO,
} from './constantes';
import EntidadesDetalleCM from './controladorModelo/EntidadesDetalleCM';
import EntidadesDocumentoCM from './controladorModelo/EntidadesDocumentoCM';
import EntradaDevolucionCM from './controladorModelo/EntradaDevolucionCM';
import { EstadosModalEntradaDevolucion } from './enumeraciones';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';
interface IModalEntradaDevolucionProps {
    cerrar: () => void;
    documentoId: string | null;
    establecerDocumentoId: (documentoId: string) => void;
    estado: EstadosModalEntradaDevolucion;
    descargarDocumentoPdf: (documento: IDocumentoConsultaGeneral) => any;
    cambiarEstado: () => void;
}

const ModalEntradaDevolucion: FunctionComponent<IModalEntradaDevolucionProps> = ({
    cerrar,
    documentoId,
    establecerDocumentoId,
    estado,
    descargarDocumentoPdf,
    cambiarEstado,
}) => {
    const entidadesDocumentoCM = useMemo(() => new EntidadesDocumentoCM(), []);
    const entradaDevolucionCM = useMemo(() => new EntradaDevolucionCM(), []);
    const entidadesDetalleCM = useMemo(() => new EntidadesDetalleCM(), []);

    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);
    const [entidadesDocumento, setEntidadesDocumento] = useState<Partial<IEntidadesFormularioDocumento>>(
        ENTIDADES_DOCUMENTO_POR_DEFECTO
    );
    const [documento, setDocumento] = useState<IDocumentoConsultaGeneral | null>(null);
    const [etapasSiguientes, setEtapasSiguientes] = useState<IEtapas[]>([]);
    const [entidadesDetalle, setEntidadesDetalle] = useState<{
        items: IItemsConsulta[];
        divisionesDestino: IBodegaDivisiones[];
    }>({ items: [], divisionesDestino: [] });
    const [detalles, setDetalles] = useState<IDocumentoDetalleConsultaGeneral[]>([]);
    const [detalleInicialEdicion, setDetalleInicialEdicion] = useState<IDocumentoDetalle | null>(null);
    const [detallesDocumentoBase, setDetallesDocumentoBase] = useState<IDocumentoDetalleConsultaGeneral[]>([]);

    useEffect(() => {
        alCambiarEstado();
    }, [estado]);

    useEffect(() => {
        if (documento) alEstablecerDocumento();
    }, [documento]);

    const alCambiarEstado = async () => {
        if (estado === EstadosModalEntradaDevolucion.CERRADO) {
            setDocumento(null);
            setDetalles([]);
            setEntidadesDocumento(ENTIDADES_DOCUMENTO_POR_DEFECTO);
            setEtapasSiguientes([]);
            setEntidadesDetalle({ items: [], divisionesDestino: [] });
            setDetalleInicialEdicion(null);
            setDetallesDocumentoBase([]);
        }
        if (estado === EstadosModalEntradaDevolucion.CREACION) {
            await consultarEntidadesDocumento();
            setDetallesDocumentoBase([]);
        }
        if (
            estado === EstadosModalEntradaDevolucion.EDICION ||
            estado === EstadosModalEntradaDevolucion.VISUALIZACION
        ) {
            await consultarDocumento(documentoId!);
        }
    };

    const consultarEntidadesDocumento = async () => {
        const entidadesDocumentoConsultadas = await entidadesDocumentoCM.consultarEntidadesDocumento(
            idEmpresa,
            idAgencia,
            idUsuario,
            estadoActivo
        );
        setEntidadesDocumento(entidadesDocumentoConsultadas);
    };

    const consultarEntidadesDocumentoEdicion = async () => {
        const entidadesDocumentoConsultadas = await entidadesDocumentoCM.consultarEntidadesDocumentoEdicion(
            documento!.BodegaDestino!,
            idEmpresa,
            idAgencia,
            idUsuario,
            TiposProceso.ENTRADA_POR_DEVOLUCION,
            estadoActivo
        );
        setEntidadesDocumento(entidadesDocumentoConsultadas);
    };

    const consultarDocumento = async (documentoIdConsultar: string) => {
        const documentoConsultado = await entradaDevolucionCM.consultarEncabezadoDocumento({
            DocumentoId: documentoIdConsultar,
        });
        setDocumento(documentoConsultado);
    };

    const consultarDocumentoBase = async (codigoDocumentoBase: string) => {
        const documentoBase = await entradaDevolucionCM.consultarDocumentoBase({
            CodigoDocumento: codigoDocumentoBase,
            IdAgencia: idAgencia,
        });
        if (documentoBase) setDocumento(mapearDocumentoBase(documentoBase));
        return documentoBase;
    };

    const mapearDestallesBase = (detalles: IDocumentoDetalleConsultaGeneral[]): IDocumentoDetalleConsultaGeneral[] => {
        const detallesBase: IDocumentoDetalleConsultaGeneral[] = detalles.map((d) => ({
            DocumentoDetalleId: d.DocumentoDetalleId,
            DocumentoId: d.DocumentoId,
            ItemId: d.ItemId,
            DivisionDestino: d.DivisionOrigen,
            CantidadSolicitada: d.CantidadSolicitada,
            CantidadRecibida: d.CantidadRecibida,
            CantidadEntregada: d.CantidadEntregada,
            Valor: d.Valor,
            Serial: d.Serial,
            Lote: d.Lote,
            FechaVencimiento: d.FechaVencimiento,
            FechaIngreso: d.FechaIngreso,
            DocumentoDetallePredecesorId: d.DocumentoDetallePredecesorId,
            EmpresaId: d.EmpresaId,
            AgenciaId: d.AgenciaId,
            Estado: d.Estado,
            CodigoBarras: d.CodigoBarras,
            DescripcionDivisionDestino: d.DescripcionDivisionOrigen,
            DescripcionDivisionOrigen: d.DescripcionDivisionOrigen,
            DescripcionItem: d.DescripcionItem,
            CodigoDocumento: d.CodigoDocumento,
            DescripcionEstadoDetalle: d.DescripcionEstadoDetalle,
            FEFO: d.FEFO,
            FIFO: d.FIFO,
            Lotes: d.Lotes,
        }));
        return detallesBase;
    };

    const mapearDocumentoBase = (documentoBase: IDocumento): IDocumentoConsultaGeneral => {
        return {
            ...DOCUMENTO_CONSULTA_GENERAL_POR_DEFECTO,
            BodegaOrigen: documentoBase.BodegaOrigen,
            ClienteId: documentoBase.ClienteId,
            CodigoDocumentoPredecesor: documentoBase.CodigoDocumento,
            CondicionComercial: documentoBase.CondicionComercial,
            DireccionEntrega: documentoBase.DireccionEntrega,
            DocumentoPredecesorId: documentoBase.DocumentoId,
            DocumentoRelacionado: documentoBase.DocumentoRelacionado,
            FechaEntrega: documentoBase.FechaEntrega,
            Observacion: documentoBase.Observacion,
            ProveedorId: documentoBase.ProveedorId,
        };
    };

    const alEstablecerDocumento = async () => {
        if (estado === EstadosModalEntradaDevolucion.CREACION) {
            if (documento!.DocumentoId) {
                establecerDocumentoId(documento!.DocumentoId);
                await consultarEtapasSiguientes();
                await consultarEntidadesDetalle();
            }
        }
        if (estado === EstadosModalEntradaDevolucion.EDICION) {
            await consultarEtapasSiguientes();
            await consultarEntidadesDetalle();
            await consultarDetallesDocumentoBase();
            await consultarDetalles();
            await consultarEntidadesDocumentoEdicion();
        }
        if (estado === EstadosModalEntradaDevolucion.VISUALIZACION) {
            await consultarEntidadesDetalle();
            await consultarDetallesDocumentoBase();
            await consultarDetalles();
            await consultarEntidadesDocumentoEdicion();
        }
    };

    const consultarEntidadesDetalle = async () => {
        const entidadesDetalleConsultadas = await entidadesDetalleCM.consultarEntidadesDetalle(
            documento!.BodegaDestino!,
            documento!.TipoDocumentoId,
            idEmpresa,
            idAgencia,
            idUsuario
        );
        setEntidadesDetalle(entidadesDetalleConsultadas);
    };

    const consultarDetalles = async () => {
        const detallesConsultados = await entradaDevolucionCM.consultarDetallesDocumento({ DocumentoId: documentoId! });
        setDetalles(detallesConsultados);
    };

    const consultarDetallesDocumentoBase = async () => {
        const detallesDocumentoBaseConsultados = await entradaDevolucionCM.consultarDetallesDocumento({
            DocumentoId: documento!.DocumentoPredecesorId,
        });
        setDetallesDocumentoBase(mapearDestallesBase(detallesDocumentoBaseConsultados));
    };

    const consultarEtapasSiguientes = async () => {
        const etapasSiguientesConsultadas = await entradaDevolucionCM.consultarEtapas(documento!.DocumentoId);
        setEtapasSiguientes(etapasSiguientesConsultadas);
    };

    const crearDocumento = async (documentoCrear: IDocumento) => {
        const respuesta = await entradaDevolucionCM.guardarEncabezado(mapearDocumentoCrear(documentoCrear));
        if (respuesta.Resultado) {
            await consultarDocumento(respuesta.Entidades[0].DocumentoId);
            await consultarDetallesDocumentoBase();
            cambiarEstado();
        }
    };

    const mapearDocumentoCrear = (documentoCrear: IDocumento): IDocumento => {
        return {
            ...documentoCrear,
            EmpresaId: idEmpresa,
            AgenciaId: idAgencia,
        };
    };

    const editarDocumento = async (documentoEditar: IDocumento) => {
        const respuesta = await entradaDevolucionCM.editarEncabezado(documentoEditar);
        if (respuesta.Resultado) await consultarDocumento(documento!.DocumentoId);
    };

    const cambiarEtapa = async (etapaId: number) => {
        manejadorDialogoGlobal({ estado: false });
        const cambioEtapaExitoso = await entradaDevolucionCM.cambiarEtapa({ ...documento!, EtapaId: etapaId });
        if (cambioEtapaExitoso) {
            await consultarDocumento(documento!.DocumentoId);
        }
    };

    const confirmarCambiarEtapa = async (etapaId: number) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => cambiarEtapa(etapaId),
            estado: true,
            mensaje: <Texto id={'etapas.CambioEtapa'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const agregarDetalles = async (detallesAgregar: IDocumentoDetalle[]) => {
        return entradaDevolucionCM.guardarDetalle(
            detallesAgregar.map((detalle) => ({
                ...detalle,
                DocumentoDetalleId: '',
                DocumentoDetallePredecesorId: detalle.DocumentoDetalleId,
                DocumentoId: documento!.DocumentoId,
            }))
        );
    };

    const editarDetalle = async (detalle: IDocumentoDetalle) => {
        return entradaDevolucionCM.editarDetalle(detalle);
    };

    const eliminarDetalle = async (detalle: IDocumentoDetalle) => {
        manejadorDialogoGlobal({ estado: false });
        const respuesta = await entradaDevolucionCM.eliminarDetalle(detalle);
        if (respuesta) {
            alEliminarDetalle();
        }
    };

    const confirmarEliminarDetalle = async (detalle: IDocumentoDetalle) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => eliminarDetalle(detalle),
            estado: true,
            mensaje: <Texto id={'alerta.confirmacionEliminacion'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const alEliminarDetalle = async () => {
        setDetalleInicialEdicion(null);
        await consultarDetallesDocumentoBase();
        await consultarDetalles();
    };

    const consultarTipoDocumento = async (idBodega: number, nombreNodega?: string) => {
        if (nombreNodega === 'BodegaDestino') {
            const tiposDocumentosBodega = await entidadesDocumentoCM.consultarTipoDocumento(idBodega, estadoActivo);
            setEntidadesDocumento({ ...entidadesDocumento, tiposDocumento: tiposDocumentosBodega });
        }
    };

    return (
        <Dialog open={estado !== EstadosModalEntradaDevolucion.CERRADO} maxWidth="lg">
            <ModalEncabezado
                cerrar={cerrar}
                titulo={
                    estado === EstadosModalEntradaDevolucion.CREACION ? (
                        <Texto id="ModalEntradaDevolucion.TituloCreacion" />
                    ) : estado === EstadosModalEntradaDevolucion.EDICION ? (
                        <Texto id="ModalEntradaDevolucion.TituloEdicion" />
                    ) : estado === EstadosModalEntradaDevolucion.VISUALIZACION ? (
                        <Texto id="ModalEntradaDevolucion.TituloVisualizacion" />
                    ) : (
                        undefined
                    )
                }
            />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {(!documento || !documento.DocumentoId) && (
                            <DocumentoBuscador consultaDocumentoBase={consultarDocumentoBase} />
                        )}
                    </Grid>
                    {documento && (
                        <Grid item xs={12}>
                            <FormularioDocumento
                                cambiarEtapa={confirmarCambiarEtapa}
                                camposDeshabilitar={
                                    estado === EstadosModalEntradaDevolucion.CREACION
                                        ? CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO
                                        : CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION
                                }
                                camposOcultar={CAMPOS_OCULTAR_DOCUMENTO_FORMULARIO}
                                documento={documento}
                                editarDocumento={editarDocumento}
                                entidadesDocumento={entidadesDocumento}
                                estado={
                                    !documento || !documento.DocumentoId
                                        ? EstadosFormularioDocumento.CREACION
                                        : (estado === EstadosModalEntradaDevolucion.CREACION &&
                                              documento &&
                                              documento.DocumentoId) ||
                                          estado === EstadosModalEntradaDevolucion.EDICION
                                        ? EstadosFormularioDocumento.EDICION
                                        : EstadosFormularioDocumento.VISUALIZACION
                                }
                                etapasSiguientes={etapasSiguientes}
                                guardarDocumento={crearDocumento}
                                descargarDocumentoPdf={descargarDocumentoPdf}
                                consultarTipoDocumento={consultarTipoDocumento}
                                mostrarModalEvidencia={true}
                                esTraslado={false}
                            />
                        </Grid>
                    )}
                    {documento && documento.DocumentoId && (
                        <Grid item xs={12}>
                            <TablaDetallesPlantilla
                                agregarDetalles={agregarDetalles}
                                alAgregarDetalles={async () => {
                                    await consultarDetallesDocumentoBase();
                                    await consultarDetalles();
                                }}
                                camposOcultar={CAMPOS_OCULTAR_DETALLES_PLANTILLA}
                                //camposEditables={CAMPOS_EDITABLES_DETALLES_PLANTILLA}
                                deshabilitada={
                                    !documento ||
                                    !documento.ListaAcciones.PermiteAgregarDetalle ||
                                    estado === EstadosModalEntradaDevolucion.VISUALIZACION
                                }
                                detalles={detallesDocumentoBase}
                                entidades={entidadesDetalle}
                                tipoCantidad={'CantidadRecibida'}
                                titulo={
                                    <Texto
                                        fontWeight="bold"
                                        id="ModalEntradaDevolucion.TituloTablaDetallesDocumentoBase"
                                    />
                                }
                                seriadoConsulta={false}
                                manejaPeso={false}
                            />
                        </Grid>
                    )}
                    {estado !== EstadosModalEntradaDevolucion.VISUALIZACION && (
                        <Grid item xs={12}>
                            <DetallesFormulario
                                alEditarDetalle={async () => {
                                    setDetalleInicialEdicion(null);
                                    await consultarDetalles();
                                    await consultarDetallesDocumentoBase();
                                }}
                                camposDeshabilitar={CAMPOS_DESHABILITAR_DETALLES_FORMULARIO}
                                camposOcultar={CAMPOS_OCULTAR_DETALLES_FORMULARIO}
                                deshabilitado={!detalleInicialEdicion}
                                detalleInicialEdicion={detalleInicialEdicion}
                                documentoId={documento ? documento.DocumentoId : ''}
                                editarDetalle={editarDetalle}
                                entidades={entidadesDetalle}
                                estado={EstadosDetallesFormulario.EDICION}
                                tipoCantidad="CantidadRecibida"
                                seriadoConsulta={false}
                                esEntradaInventario={false}
                                documentoDetallesBase={detallesDocumentoBase}
                                bloquearCantidadSerial={true}
                                esOrdenTraslado={false}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TablaDetalles
                            camposOcultar={CAMPOS_OCULTAR_DETALLES_TABLA}
                            detalles={detalles}
                            editarDetalle={(detalle: IDocumentoDetalleConsultaGeneral) =>
                                setDetalleInicialEdicion(detalle)
                            }
                            editarDetalleDeshabilitado={
                                documento ? !documento.ListaAcciones.PermiteEditarDetalle : false
                            }
                            eliminarDetalle={confirmarEliminarDetalle}
                            eliminarDetalleDeshabilitado={
                                documento ? !documento.ListaAcciones.PermiteEliminarDetalle : false
                            }
                            mostrarAcciones={
                                estado === EstadosModalEntradaDevolucion.CREACION ||
                                estado === EstadosModalEntradaDevolucion.EDICION
                            }
                            recargarTabla={consultarDetalles}
                            titulo={
                                <Texto fontWeight="bold" id="ModalEntradaDevolucion.TituloTablaDetallesDocumento" />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DocumentoValorTotal detalles={detalles} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ModalEntradaDevolucion;
