import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IClasificaciones from 'Infotrack@Modelos/smartStock/clasificaciones/entidades/clasificaciones';
import IEstadisticasDashboard from 'Infotrack@Modelos/smartStock/estadisticasDashboard/entidades/estadisticasDashboard';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import EntidadesFiltroCM from '../controladorModelo/entidadesFiltrosCM';
import EstadisticasDashboardCM from '../controladorModelo/estadisticasDashboardCM';
import { IInputsFiltros, IMonitorInventarioCVProps } from '../interfaces';
import MonitorInventarioVista from '../vista/monitorInventarioVista';

const entidadesFiltroCM = new EntidadesFiltroCM();
const estadisticasDashboardCM = new EstadisticasDashboardCM();
const estadoInicialFiltros: IInputsFiltros = {
    Bodega: null,
    Clasificacion: null,
};

const MonitorInventarioCV: React.FunctionComponent<IMonitorInventarioCVProps> = ({
    AgenciaId,
    EmpresaId,
    UsuarioId,
}) => {
    const [bodegas, setBodegas] = React.useState<IBodega[]>([]);
    const [clasificaciones, setClasificaciones] = React.useState<IClasificaciones[]>([]);
    const [dataStockMinimo, setDataStockMinimo] = React.useState<IEstadisticasDashboard[]>([]);
    const [dataStockMasAntiguo, setDataStockMasAntiguo] = React.useState<IEstadisticasDashboard[]>([]);
    const [dataMayorRotacion, setDataMayorRotacion] = React.useState<IEstadisticasDashboard[]>([]);
    const [dataMenorRotacion, setDataMenorRotacion] = React.useState<IEstadisticasDashboard[]>([]);
    const [dataUtilizacionBodega, setDataUtilizacionBodega] = React.useState<IEstadisticasDashboard[]>([]);
    const [filtros, setFiltros] = React.useState(estadoInicialFiltros);

    const cargaInicial = React.useRef(true);

    React.useEffect(() => {
        consultarEntidadesFiltros();
    }, []);

    React.useEffect(() => {
        if (cargaInicial.current && filtros.Bodega) {
            consultarDataGraficas();
            cargaInicial.current = false;
        }
    }, [filtros]);

    const alCambiarValor = (valor: IInputsFiltros[keyof IInputsFiltros], filtro: string) => {
        setFiltros((filtrosAnteriores) => ({ ...filtrosAnteriores, [filtro]: valor }));
    };

    const consultarEntidadesFiltros = () => {
        entidadesFiltroCM
            .consultarEntidadesFiltros({ AgenciaId, UsuarioId, EmpresaId }, EmpresaId)
            .then(({ bodegas: bodegasCargadas, clasificaciones: clasificacionesCargadas }) => {
                setBodegas(bodegasCargadas);
                setClasificaciones(clasificacionesCargadas);
                if (bodegasCargadas[0]) setFiltros({ ...filtros, Bodega: bodegasCargadas[0] });
            });
    };

    const consultarDataGraficas = () => {
        estadisticasDashboardCM
            .consultarEstadisticasDashboard({
                EmpresaId,
                BodegaId: Number(filtros.Bodega!.BodegaId),
                ClasificacionId: filtros.Clasificacion ? Number(filtros.Clasificacion!.ClasificacionId) : undefined,
            })
            .then(
                ({
                    itemsEnStockMinimo,
                    itemsMasAntiguos,
                    itemsMayorRotacion,
                    itemsMenorRotacion,
                    utilizacionBodega,
                }) => {
                    setDataStockMinimo(itemsEnStockMinimo);
                    setDataStockMasAntiguo(itemsMasAntiguos);
                    setDataMayorRotacion(itemsMayorRotacion);
                    setDataMenorRotacion(itemsMenorRotacion);
                    setDataUtilizacionBodega(utilizacionBodega);
                }
            );
    };

    return (
        <MonitorInventarioVista
            alCambiarValor={alCambiarValor}
            alFiltrarRegistros={consultarDataGraficas}
            dataStockMinimo={dataStockMinimo}
            dataStockMasAntiguo={dataStockMasAntiguo}
            dataMayorRotacion={dataMayorRotacion}
            dataMenorRotacion={dataMenorRotacion}
            dataUtilizacionBodega={dataUtilizacionBodega}
            entidadesFiltros={{ bodegas, clasificaciones }}
            filtros={filtros}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion, agenciaActual },
}: IEstadoGlobal): IMonitorInventarioCVProps => ({
    AgenciaId: agenciaActual!.IdAgencia,
    EmpresaId: usuarioInformacion!.IdEmpresa,
    UsuarioId: usuarioInformacion!.IdUsuario,
});

export default connect<IMonitorInventarioCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(MonitorInventarioCV);
