import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import IProductoPrincipal from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/productoPrincipal';
import AbreviaturasTiposDocumentos from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/abreviaturasTiposDocumento';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import DocumentoDetalleCM from '../controladorModelo/documentoDetalleCM';
import EntidadesDocumentoDetalleCM from '../controladorModelo/entidadesDocumentoDetalleCM';
import { IFormularioDocumentoDetalleCVProps, IInputsDocumentoDetalle } from '../interfaces';
import { acciones, IEstadoGestionMovimientos } from '../reductorGestionMovimientos';
import FormularioDocumentoDetalleVista from '../vista/formularioDocumentoDetalleVista';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import Texto from '@infotrack/presentacion-componentes/texto';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';

const valorInicialFormulario: IInputsDocumentoDetalle = {
    CantidadSolicitada: '',
    DivisionDestino: '',
    DivisionOrigen: '',
    FechaVencimiento: null,
    ItemId: '',
    Lote: '',
    Serial: '',
};

const entidadesDocumentoDetalleCM = new EntidadesDocumentoDetalleCM();
const documentoDetalleCM = new DocumentoDetalleCM();

const FormularioDocumentoDetalleCV: React.FunctionComponent<IFormularioDocumentoDetalleCVProps> = ({ IdEmpresa }) => {
    const { setValor, reiniciar, valor } = useInputState(valorInicialFormulario);
    const [divisionesDestino, setDivisionesDestino] = React.useState<IBodegaDivisiones[]>([]);
    const [divisionesOrigen, setDivisionesOrigen] = React.useState<IBodegaDivisiones[]>([]);
    const [item, setItem] = React.useState<IProductoPrincipal>();

    const [
        {
            documento,
            estadoFormularioDetalle,
            entidadDocumentoDetalle,
            tipoFormularioDocumentoDetalle,
            tipoMovimiento,
            tipoFormularioDocumento,
            entidadDocumento,
            documentosDetalles,
        },
        dispatch,
    ] = useProveedor<IEstadoGestionMovimientos>();

    const theme = useTheme();
    const esMovil = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        if (documento.BodegaOrigen)
            cargarDivisiones(
                documento.BodegaOrigen ? Number(documento.BodegaOrigen.BodegaId) : 0,
                documento.TipoDocumento ? Number(documento.TipoDocumento.TipoDocumentoId) : 0,
                true
            );
    }, [documento.BodegaOrigen]);

    React.useEffect(() => {
        if (documento.BodegaDestino)
            cargarDivisiones(
                documento.BodegaDestino ? Number(documento.BodegaDestino.BodegaId) : 0,
                documento.TipoDocumento ? Number(documento.TipoDocumento.TipoDocumentoId) : 0,
                false
            );
    }, [documento.BodegaDestino]);

    React.useEffect(() => {
        if (entidadDocumentoDetalle) {
            const {
                CodigoBarras,
                DescripcionItem,
                DivisionDestino,
                DivisionOrigen,
                FechaVencimiento,
                ItemId,
                SeriadoItems,
                FEFO,
                LotesItems,
            } = entidadDocumentoDetalle;
            setValor({
                ...entidadDocumentoDetalle,
                FechaVencimiento: FechaVencimiento ? FechaVencimiento : null,
                DivisionDestino: DivisionDestino ? DivisionDestino : '',
                DivisionOrigen: DivisionOrigen ? DivisionOrigen : '',
            } as any);
            setItem({
                DescripcionItem,
                CodigoBarras,
                Seriado: SeriadoItems ? SeriadoItems : undefined,
                FEFO: FEFO ? FEFO : undefined,
                Lotes: LotesItems ? LotesItems : undefined,
                ItemId: Number(ItemId),
            } as any);
        }
    }, [entidadDocumentoDetalle]);

    const alCambiarValor = ({
        target,
    }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { checked, name, type, value } = target as EventTarget & HTMLInputElement;
        setValor({ ...valor, [name]: type === 'checkbox' ? checked : value });
    };

    const alSeleccionarItem = (itemSeleccionado?: IProductoPrincipal) => {
        setItem(itemSeleccionado);
    };

    const alCerrarReiniciarFormulario = (cerrar?: boolean) => {
        if (cerrar || tipoFormularioDocumentoDetalle === TipoFormulario.Edicion)
            dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
        reiniciar();
        setItem(undefined);
    };

    const cargarDivisiones = async (BodegaId: number, TipoDocumentoId: number, bodegaOrigen: boolean) => {
        const divisiones = await entidadesDocumentoDetalleCM.consultarListaDivisiones(BodegaId, TipoDocumentoId);
        if (bodegaOrigen) setDivisionesOrigen(divisiones);
        else setDivisionesDestino(divisiones);
    };

    const alGuardarDocumentoDetalle = () => {
        const nuevoDocumentoDetalle = mapearDetalle();
        if (documentoDetalleCM.validarDocumentoDetalle(nuevoDocumentoDetalle, tipoMovimiento!)) {
            if (tipoFormularioDocumentoDetalle === TipoFormulario.Creacion) {
                if (tipoFormularioDocumento === TipoFormulario.Creacion) {
                    dispatch({ type: acciones.AGREGAR_DOCUMENTO_DETALLE, payload: nuevoDocumentoDetalle });
                    alCerrarReiniciarFormulario();
                } else {
                    if (
                        nuevoDocumentoDetalle &&
                        documentosDetalles.find(
                            (x) =>
                                x.ItemId === nuevoDocumentoDetalle.ItemId &&
                                x.DivisionOrigen === nuevoDocumentoDetalle.DivisionOrigen
                        )
                    ) {
                        alCerrarReiniciarFormulario();
                        return notificacionGlobal('gestionmovimientos.alerta.detalleAsignado', 6000, 'warning', true);
                    }
                    manejadorDialogoGlobal({
                        accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                        accionConfirmar: () => {
                            documentoDetalleCM.GuardarDocumentoDetalle(nuevoDocumentoDetalle).then((resultado) => {
                                if (resultado.data.Resultado) {
                                    nuevoDocumentoDetalle.DocumentoDetalleId =
                                        resultado.data.Entidades[0].DocumentoDetalleId;
                                    dispatch({
                                        type: acciones.AGREGAR_DOCUMENTO_DETALLE,
                                        payload: nuevoDocumentoDetalle,
                                    });
                                    manejadorDialogoGlobal({ estado: false });
                                    alCerrarReiniciarFormulario();
                                } else {
                                    manejadorDialogoGlobal({ estado: false });
                                }
                            });
                        },
                        estado: true,
                        mensaje: <Texto id="gestionmovimientos.alerta.cambioImportante" />,
                        mostrarCancelar: true,
                        tipoDialogo: 'Advertencia',
                        titulo: <Texto id="titulo.advertencia" />,
                    });
                }
            } else {
                if (tipoFormularioDocumento === TipoFormulario.Creacion) {
                    dispatch({ type: acciones.EDITAR_DOCUMENTO_DETALLE, payload: nuevoDocumentoDetalle });
                    alCerrarReiniciarFormulario();
                } else {
                    manejadorDialogoGlobal({
                        accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                        accionConfirmar: () => {
                            documentoDetalleCM.EditarDocumentoDetalle(nuevoDocumentoDetalle).then((resultado) => {
                                if (resultado.data.Resultado) {
                                    nuevoDocumentoDetalle.DocumentoDetalleId =
                                        resultado.data.Entidades[0].DocumentoDetalleId;
                                    dispatch({
                                        type: acciones.EDITAR_DOCUMENTO_DETALLE,
                                        payload: nuevoDocumentoDetalle,
                                    });
                                    manejadorDialogoGlobal({ estado: false });
                                    alCerrarReiniciarFormulario();
                                } else {
                                    manejadorDialogoGlobal({ estado: false });
                                }
                            });
                        },
                        estado: true,
                        mensaje: <Texto id="gestionmovimientos.alerta.cambioImportante" />,
                        mostrarCancelar: true,
                        tipoDialogo: 'Advertencia',
                        titulo: <Texto id="titulo.advertencia" />,
                    });
                }
            }
        }
    };

    const mapearDetalle = (): IDocumentoDetalleCompuesto => {
        const { CantidadSolicitada, DivisionOrigen, FechaVencimiento } = valor;
        const DescripcionDivisionOrigen = DivisionOrigen
            ? divisionesOrigen.find(({ DivisionId }) => DivisionId === Number(DivisionOrigen))!.DivisionDescripcion
            : '';
        return {
            ...valor,
            CodigoBarras: item ? item.CodigoBarras : '',
            CantidadSolicitada: Number(CantidadSolicitada),
            CantidadRecibida: Number(CantidadSolicitada),
            DescripcionItem: item ? item.DescripcionItem : '',
            DescripcionDivisionOrigen,
            DescripcionDivisionDestino: DescripcionDivisionOrigen,
            EmpresaId: IdEmpresa,
            ItemId: item ? item.ItemId : '',
            DivisionOrigen: DivisionOrigen ? Number(DivisionOrigen) : undefined,
            DivisionDestino: DivisionOrigen ? Number(DivisionOrigen) : undefined,
            FechaVencimiento: FechaVencimiento ? FechaVencimiento : '',
            SeriadoItems: item && item.Seriado,
            LotesItems: item && item.Lotes,
            FEFO: item && item.FEFO,
            DocumentoId: entidadDocumento ? entidadDocumento.DocumentoId : undefined,
            DocumentoDetalleId: entidadDocumentoDetalle ? entidadDocumentoDetalle.DocumentoDetalleId : undefined,
        } as any;
    };

    return (
        <FormularioDocumentoDetalleVista
            alCerrarReiniciarFormulario={alCerrarReiniciarFormulario}
            alCambiarValor={alCambiarValor}
            alGuardarDocumentoDetalle={alGuardarDocumentoDetalle}
            alSeleccionarItem={alSeleccionarItem}
            tipoFormularioDocumentoDetalle={tipoFormularioDocumentoDetalle}
            esMovil={esMovil}
            estadoFormulario={Boolean(
                estadoFormularioDetalle &&
                    documento.TipoDocumento &&
                    (documento.TipoDocumento.TipoDocumentoAbreviatura === AbreviaturasTiposDocumentos.ENT ||
                        documento.TipoDocumento.TipoDocumentoAbreviatura === AbreviaturasTiposDocumentos.SAL)
            )}
            documentoDetalle={valor}
            tipoMovimiento={tipoMovimiento}
            divisiones={{ origen: divisionesOrigen, destino: divisionesDestino }}
            item={item}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion },
}: IEstadoGlobal): IFormularioDocumentoDetalleCVProps => ({
    IdEmpresa: usuarioInformacion!.IdEmpresa,
});

export default connect<IFormularioDocumentoDetalleCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(FormularioDocumentoDetalleCV);
