import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';
import { Action, Column, Options } from '@infotrack/presentacion-componentes/tabla';
import IDocumentoCompuesto from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuesto';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import { BotonAgregar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const columnasAuditorias = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IDocumentoCompuesto>> => [
    { title: formatMessage({ id: 'auditoriasinventario.NumeroDocumento' }), field: 'CodigoDocumento' },
    {
        title: formatMessage({ id: 'auditoriasinventario.FechaCreacion' }),
        field: 'FechaCreacion',
        render: (rowData: any) => moment(rowData.FechaCreacion).format('DD/MM/YYYY/hh:mm A'),
    },
    { title: formatMessage({ id: 'auditoriasinventario.BodegaOrigenDescripcion' }), field: 'BodegaOrigenDescripcion' },
    { title: formatMessage({ id: 'auditoriasinventario.DocumentoRelacionado' }), field: 'DocumentoRelacionado' },
    {
        title: formatMessage({ id: 'auditoriasinventario.ConteoUnico' }),
        render: (rowData) => (!rowData.BodegaOrigen || !rowData.BodegaDestino ? <CheckIcon /> : <RemoveIcon />),
    },
    { title: formatMessage({ id: 'auditoriasinventario.EstadoDocumento' }), field: 'EtapaDescripcion' },
];

export const accionesAuditorias = (
    alAbrirFormularioConteo: (documento: IDocumentoCompuesto) => void,
    recargarTabla: () => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Action<IDocumentoCompuesto>> => [
    {
        icon: () => <AssignmentIcon color="action" />,
        onClick: (_, valor) => alAbrirFormularioConteo(valor as IDocumentoCompuesto),
        tooltip: formatMessage({ id: 'auditoriasinventario.realizarConteo' }),
    },
];

export const columnasDetallesAuditorias = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string,
    documento?: IDocumentoCompuesto
): Array<Column<IDocumentoDetalleCompuesto>> => [
    { title: formatMessage({ id: 'auditoriasinventario.Detalles.CodigoBarras' }), field: 'CodigoBarras' },
    {
        title: formatMessage({ id: 'auditoriasinventario.Detalles.DescripcionItem' }),
        field: 'DescripcionItem',
    },
    {
        title: formatMessage({ id: 'auditoriasinventario.Detalles.CantidadRecibida' }),
        field:
            documento && documento.EtapaDescripcion === TiposEtapas.Conteo ? 'CantidadRecibida' : 'CantidadEntregada',
    },
    { title: formatMessage({ id: 'auditoriasinventario.Detalles.Serial' }), field: 'Serial' },
    { title: formatMessage({ id: 'auditoriasinventario.Detalles.Lote' }), field: 'Lote' },
    {
        title: formatMessage({ id: 'auditoriasinventario.Detalles.FechaVencimiento' }),
        field: 'FechaVencimiento',
        render: ({ FechaVencimiento }) => (FechaVencimiento ? moment(FechaVencimiento).format('DD/MM/YYYY') : ''),
    },
];

export const accionesDetallesAuditorias = (
    alAbrirFormulario: (documentoDetalle?: IDocumentoDetalleCompuesto) => void,
    alRecargarTabla: () => void,
    alEliminarDocumentoDetalle: (documentoDetalle: IDocumentoDetalleCompuesto) => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Action<IDocumentoDetalleCompuesto> | ((rowData: any) => Action<IDocumentoDetalleCompuesto>)> => [
    {
        component: BotonAgregar,
        icon: () => <AddIcon />,
        onClick: () => alAbrirFormulario(),
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
    },
    {
        icon: () => <EditIcon color="action" />,
        onClick: (_, valor) => alAbrirFormulario(valor as IDocumentoDetalleCompuesto),
        tooltip: formatMessage({ id: 'boton.editar' }),
    },
    {
        icon: () => <DeleteIcon color="action" />,
        onClick: (_, valor) => alEliminarDocumentoDetalle(valor as IDocumentoDetalleCompuesto),
        tooltip: formatMessage({ id: 'boton.eliminar' }),
    },
];

export const opcionesAuditoria: Options<IDocumentoCompuesto> = {
    maxBodyHeight: '40vh',
    showTitle: true,
    toolbar: false,
};

export const opciones: Options<IDocumentoCompuesto> = {
    maxBodyHeight: '40vh',
    showTitle: true,
};
