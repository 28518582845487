import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import Texto from '@infotrack/presentacion-componentes/texto';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/documento';
import IDocumentoCompuestoMovimiento from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuestoMovimiento';
import IFiltrosDocumentos from 'Infotrack@Modelos/smartStock/documentos/entidades/filtrosDocumentosMovimientos';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import DocumentoCM from '../controladorModelo/documentoCM';
import EntidadesFiltroDocumentoCM from '../controladorModelo/entidadesFiltroDocumentoCM';
import { IInputsFiltrosDocumento, ITablaGestionAuditoriasCVProps } from '../interfaces';
import { acciones, IEstadoGestionAuditorias } from '../reductorGestionAuditorias';
import TablaAuditoriasVista from '../vista/tablaAuditoriasVista';

const documentoCM = new DocumentoCM();
const entidadesFiltroDocumentoCM = new EntidadesFiltroDocumentoCM();
const valorInicialFiltros: IInputsFiltrosDocumento = {
    CodigoDocumento: '',
    TipoDocumentoId: '',
    BodegaConsulta: '',
    FechaInicial: null,
    FechaFinal: null,
    Etapa: null,
};

const TablaGestionAuditoriasCV: React.FunctionComponent<ITablaGestionAuditoriasCVProps> = ({
    IdAgencia,
    IdEmpresa,
    IdUsuario,
}) => {
    const [bodegas, setBodegas] = React.useState<IBodega[]>([]);
    const [etapas, setEtapas] = React.useState<IEtapas[]>([]);
    const { alCambiarValor, reiniciar, valor } = useInputState(valorInicialFiltros);
    const refTabla = React.createRef<any>();
    const [{ recargarTabla }, dispatch] = useProveedor<IEstadoGestionAuditorias>();

    React.useEffect(() => {
        cargarEntiadesFiltros();
    }, []);

    React.useEffect(() => {
        if (recargarTabla && valor.BodegaConsulta) {
            cargarDocumentos();
            dispatch({ type: acciones.RECARGAR_TABLA });
        }
    }, [recargarTabla]);

    const cargarEntiadesFiltros = () => {
        entidadesFiltroDocumentoCM
            .consultarEntidadesFiltro({ AgenciaId: IdAgencia, UsuarioId: IdUsuario, EmpresaId: IdEmpresa })
            .then(({ bodegas: bodegasCargadas, etapas: etapasCargadas }) => {
                setBodegas(bodegasCargadas);
                setEtapas(etapasCargadas);
            });
    };

    const mapearFiltros = (): IFiltrosDocumentos => {
        const { BodegaConsulta, Etapa } = valor;
        return {
            ...valor,
            EmpresaId: IdEmpresa,
            UsuarioId: IdUsuario,
            BodegaConsulta: BodegaConsulta ? Number(BodegaConsulta) : null,
            EtapaId: Etapa ? Number(Etapa.EtapaId) : null,
            TipoDocumentoId: null,
        };
    };

    const alAbrirFormulario = (documento?: IDocumentoCompuestoMovimiento) => {
        if (documento) {
            dispatch({ type: acciones.CAMBIAR_ESTADO_TABLA_DETALLE });
            dispatch({ type: acciones.ESTABLECER_DOCUMENTO, payload: documento });
        } else dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
    };

    const consultaDocumentos = paginacionRemota(
        documentoCM.consultarDocumentosGestionAuditoriaCompuesto,
        mapearFiltros(),
        'FechaCreacion',
        !Boolean(valor.BodegaConsulta)
    );

    const cargarDocumentos = async (limpiarFiltros?: boolean) => {
        if (limpiarFiltros) {
            reiniciar();
            refTabla.current.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
        } else if (documentoCM.validarFiltros(mapearFiltros()))
            refTabla.current.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
    };

    const confirmarAnulacionAuditoria = (documento: IDocumento) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => alAnularAuditoria(documento),
            estado: true,
            mensaje: <Texto id="alerta.confirmacionAnulación" />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const alAnularAuditoria = (documento: IDocumento) => {
        manejadorDialogoGlobal({ estado: false });
        documentoCM.anularDocumento(documento).then(() => {
            cargarDocumentos();
        });
    };

    const confirmarCambioDeEstadoAuditoria = (documento: IDocumentoCompuestoMovimiento) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => alModificarEstadoAuditoria(documento),
            estado: true,
            mensaje: (
                <Texto
                    id={
                        documento.EtapaDescripcion === TiposEtapas.Creada
                            ? 'gestionauditorias.alerta.primerConteo'
                            : documento.EtapaDescripcion === TiposEtapas.Conteo &&
                              documento &&
                              documento.BodegaOrigen &&
                              documento.BodegaDestino
                            ? 'gestionauditorias.alerta.segundoConteo'
                            : 'gestionauditorias.alerta.finalizarConteo'
                    }
                />
            ),
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const alModificarEstadoAuditoria = (documento: IDocumentoCompuestoMovimiento) => {
        manejadorDialogoGlobal({ estado: false });
        documentoCM.modificarEstadoAuditoria(documento).then(() => {
            dispatch({ type: acciones.RECARGAR_TABLA });
        });
    };

    return (
        <TablaAuditoriasVista
            alAbrirFormulario={alAbrirFormulario}
            alAnularAuditoria={confirmarAnulacionAuditoria}
            alCambiarValor={alCambiarValor}
            cargarDocumentos={cargarDocumentos}
            consultaDocumentos={consultaDocumentos}
            entidadesFiltros={{ bodegas, etapas }}
            filtrosDocumentos={valor}
            refTabla={refTabla}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion, agenciaActual },
}: IEstadoGlobal): ITablaGestionAuditoriasCVProps => ({
    IdEmpresa: usuarioInformacion!.IdEmpresa,
    IdAgencia: agenciaActual!.IdAgencia,
    IdUsuario: usuarioInformacion!.IdUsuario,
});

export default connect<ITablaGestionAuditoriasCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(TablaGestionAuditoriasCV);
