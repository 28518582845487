import { AnyAction } from 'redux';
import { IInputsConsultaInventario } from './interfaces';

export interface IEstadoConsultaInventario {
  estadoFormularioConsulta: boolean;
  filtros: IInputsConsultaInventario;
}

export const estadoInicial: IEstadoConsultaInventario = {
  filtros: {
    CodigoBarras: '',
    DescripcionItem: '',
    Serial: '',
    Lote: '',
    FamiliaId: '',
    ClasificacionId: '',
    BodegaDecripcion: '',
    BodegaId: '',
    DivisionId: '',
    FechaInicial: null,
    FechaFinal: null,
    IdUsuario: null
  },
  estadoFormularioConsulta: false,
};

export const acciones = {
  MODIFICAR_FILTROS_CONSULTA: 'MODIFICAR_FILTROS_CONSULTA',
  CAMBIAR_ESTADO_FORMULARIO: 'CAMBIAR_ESTADO_FORMULARIO',
  LIMPIAR_FILTROS: 'LIMPIAR_FILTROS',
};

export const reductor = (estado: IEstadoConsultaInventario = estadoInicial, { type, payload }: AnyAction) => {
  switch (type) {
    case acciones.MODIFICAR_FILTROS_CONSULTA: {
      const { llave, valor } = payload;
      return {
        ...estado,
        filtros: { ...estado.filtros, [llave]: valor },
      };
    }

    case acciones.CAMBIAR_ESTADO_FORMULARIO: {
      return { ...estado, estadoFormularioConsulta: !estado.estadoFormularioConsulta };
    }

    case acciones.LIMPIAR_FILTROS: {
      return { ...estado, filtros: estadoInicial.filtros };
    }

    default:
      return estado;
  }
};
