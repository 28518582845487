import { createStyles, Theme } from '@material-ui/core';
import { paletaMaterialD } from '@infotrack/presentacion-transversales/constantes';

export const estilos = (tema: Theme) =>
    createStyles({
        contenedorImagen: {
            padding: tema.spacing(0, 2, 2, 2),
        },
        inputFalso: {
            display: 'none',
        },
        botones: {
            marginRight: tema.spacing(1),
        },
        margenIcono: {
            marginRight: tema.spacing(1),
        },
        slideShow: {
            '& .carousel .slide p.legend': {
                backgroundColor: paletaMaterialD.white,
                borderRadius: 5,
                color: paletaMaterialD.black,
            },
        },
        limiteImagenes: {
            color: paletaMaterialD.red_500,
        },
    });
