export default {
  'sidebar.administracion': 'Administration',
  'sidebar.tipocuentabancaria': 'Bank Account Type',
  'tipoCuentaBancaria.agregarTipoCuentaBancaria': 'Create Bank Account Type',
  'tipoCuentaBancaria.confirmacionCreacion': 'Are you sure to create the registry?',
  'tipoCuentaBancaria.confirmacionEdicion': 'You are sure you want to edit the registry?',
  'tipoCuentaBancaria.confirmacionEliminacion': 'Are you sure you want to delete the registry?',
  'tipoCuentaBancaria.modificarTipoCuentaBancaria': 'Edit Bank Account Type',
  'tipoCuentaBancaria.eliminarTipoCuentaBancaria': 'Delete Bank Account Type',
  'tipoCuentaBancaria.codigoTipoCuentaBancaria': 'Code Bank Account Type',
  'tipoCuentaBancaria.nombreTipoCuentaBancaria': 'Name Bank Account Type',
  'tipoCuentaBancaria.estadoTipoCuentaBancaria': 'State',
  'tipoCuentaBancaria.tipoCuentaBancaria': 'Bank Account Type',
};
