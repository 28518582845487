export default {
  'sidebar.administracion': 'Administración',
  'sidebar.pais': 'País',
  'pais.agregarPais': 'Crear País',
  'pais.confirmacionCreacion': '¿Está seguro que desea crear el registro?',
  'pais.confirmacionEdicion': '¿Está seguro que desea editar el registro?',
  'pais.confirmacionEliminacion': '¿Está seguro que desea eliminar el registro?',
  'pais.modificarPais': 'Editar País',
  'pais.codigoPais': 'Código País',
  'pais.nombrePais': 'Nombre País',
  'pais.estadoPais': 'Estado País',
};
