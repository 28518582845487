import ProveedorEstado from '@infotrack/presentacion-componentes/proveedorEstado';
import React from 'react';
import MonitorPedidosCV from './controladorVista/monitorPedidosCV';
import TablaCumplientoPedidosCV from './controladorVista/tablaCumplientoPedidosCV';
import TablaPedidosEstadosCV from './controladorVista/tablaPedidosEstadosCV';
import { estadoInicial, reductor } from './reductorMonitorPedidos';

const MonitorPedidos: React.FunctionComponent = () => (
    <ProveedorEstado estadoInicial={estadoInicial} reductor={reductor}>
        <MonitorPedidosCV />
        <TablaPedidosEstadosCV />
        <TablaCumplientoPedidosCV />
    </ProveedorEstado>
);

export default MonitorPedidos;
