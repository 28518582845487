import UndoIcon from '@material-ui/icons/Undo';
import { Action, Column, Options } from '@infotrack/presentacion-componentes/tabla';
import IDocumentoConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoConsulta';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const acciones = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string,
    revertirDocumento: (documento: IDocumentoConsulta) => void
): Array<Action<IDocumentoConsulta>> => [
    {
        icon: () => <UndoIcon color="action" />,
        tooltip: formatMessage({ id: 'reversion.revertirDocumento' }),
        onClick: (_, rowData: any) => revertirDocumento(rowData),
    },
];

export const columnas = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IDocumentoConsulta>> => [
    { title: formatMessage({ id: 'reversion.CodigoDocumento' }), field: 'CodigoDocumento' },
    { title: formatMessage({ id: 'reversion.DescripcionBodegaOrigen' }), field: 'DescripcionBodegaOrigen' },
    { title: formatMessage({ id: 'reversion.DescripcionBodegaDestino' }), field: 'DescripcionBodegaDestino' },
    {
        title: formatMessage({ id: 'reversion.TipoDocumentoDescripcion' }),
        field: 'TipoDocumentoDescripcion',
    },
    { title: formatMessage({ id: 'reversion.EtapaDescripcion' }), field: 'EtapaDescripcion' },
    {
        title: formatMessage({ id: 'reversion.FechaCreacion' }),
        field: 'FechaCreacion',
        render: (rowData) => (rowData.FechaCreacion ? moment(rowData.FechaCreacion).format('DD/MM/YYYY/hh:mm A') : ''),
    },
];

export const opciones: Options<IDocumentoConsulta> = {
    maxBodyHeight: '50vh',
    toolbar: false,
};
