import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IDocumentoCompuesto from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuesto';
import IFiltrosDocumentos from 'Infotrack@Modelos/smartStock/documentos/entidades/filtrosDocumentosMovimientos';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import DocumentoCM from '../controladorModelo/documentoCM';
import EntidadesFiltroDocumentoCM from '../controladorModelo/entidadesFiltroDocumentoCM';
import { IInputsFiltrosDocumento, ITablaAuditoriasCVProps } from '../interfaces';
import { acciones, IEstadoAuditorias } from '../reductorAuditorias';
import TablaAuditoriasVista from '../vista/tablaAuditoriasVista';

const documentoCM = new DocumentoCM();
const entidadesFiltroDocumentoCM = new EntidadesFiltroDocumentoCM();
const valorInicialFiltros: IInputsFiltrosDocumento = {
    CodigoDocumento: '',
    TipoDocumentoId: '',
    BodegaConsulta: '',
    FechaInicial: null,
    FechaFinal: null,
    EtapaId: '',
};

const TablaAuditoriasCV: React.FunctionComponent<ITablaAuditoriasCVProps> = ({ IdAgencia, IdEmpresa, IdUsuario }) => {
    const [bodegas, setBodegas] = React.useState<IBodega[]>([]);
    const [etapas, setEtapas] = React.useState<IEtapas[]>([]);
    const { alCambiarValor, reiniciar, valor } = useInputState(valorInicialFiltros);
    const refTabla = React.createRef<any>();
    const [{ recargarTabla }, dispatch] = useProveedor<IEstadoAuditorias>();

    React.useEffect(() => {
       cargarEntiadesFiltros();
    }, []);

    React.useEffect(() => {
        if (recargarTabla && valor.BodegaConsulta) {
            cargarDocumentos();
            dispatch({ type: acciones.RECARGAR_TABLA });
        }
    }, [recargarTabla]);

    const cargarEntiadesFiltros = () => {
        entidadesFiltroDocumentoCM
            .consultarEntidadesFiltro({ AgenciaId: IdAgencia, UsuarioId: IdUsuario, EmpresaId: IdEmpresa })
            .then(({ bodegas: bodegasCargadas, etapas: etapasCargadas }) => {
                setBodegas(bodegasCargadas);
                setEtapas(etapasCargadas);
            });
    };

    const mapearFiltros = (): IFiltrosDocumentos => {
        const { BodegaConsulta, EtapaId } = valor;
        return {
            ...valor,
            EmpresaId: IdEmpresa,
            UsuarioId: IdUsuario,
            BodegaConsulta: BodegaConsulta ? Number(BodegaConsulta) : null,
            EtapaId: EtapaId ? Number(EtapaId) : null,
            TipoDocumentoId: null,
        };
    };

    const alAbrirFormulario = (documento: IDocumentoCompuesto) => {
        if (documento.EtapaDescripcion === TiposEtapas.Creada) {
            notificacionGlobal('auditoriasinventario.alerta.auditoriaCreada', 6000, 'warning', true);
        } else dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO, payload: documento });
    };

    const consultaDocumentos = paginacionRemota(
        documentoCM.consultarListaDocumentosAuditoria,
        mapearFiltros(),
        'FechaCreacion',
        !Boolean(valor.BodegaConsulta)
    );

    const cargarDocumentos = async (limpiarFiltros?: boolean) => {
        if (limpiarFiltros) {
            reiniciar();
            refTabla.current.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
        } else if (documentoCM.validarFiltros(mapearFiltros()))
            refTabla.current.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
    };

    return (
        <TablaAuditoriasVista
            alAbrirFormularConteo={alAbrirFormulario}
            alCambiarValor={alCambiarValor}
            cargarDocumentos={cargarDocumentos}
            consultaDocumentos={consultaDocumentos}
            entidadesFiltros={{ bodegas, etapas }}
            filtrosDocumentos={valor}
            refTabla={refTabla}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion, agenciaActual },
}: IEstadoGlobal): ITablaAuditoriasCVProps => ({
    IdEmpresa: usuarioInformacion!.IdEmpresa,
    IdAgencia: agenciaActual!.IdAgencia,
    IdUsuario: usuarioInformacion!.IdUsuario,
});

export default connect<ITablaAuditoriasCVProps, null, any, IEstadoGlobal>(estadoAPropiedades, null)(TablaAuditoriasCV);
