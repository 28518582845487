import ModeloBodegas from 'Infotrack@Modelos/smartStock/bodegas';
import IBodegaPersonaFiltro from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaPersonaFiltro';
import ModeloClasificaciones from 'Infotrack@Modelos/smartStock/clasificaciones';

export default class EntidadesFiltrosCM {
    private modeloBodegas: ModeloBodegas;
    private modeloClasificaciones: ModeloClasificaciones;
    constructor() {
        this.modeloBodegas = new ModeloBodegas();
        this.modeloClasificaciones = new ModeloClasificaciones();
    }

    public consultarEntidadesFiltros(bodegaPersonaFiltro: IBodegaPersonaFiltro, EmpresaId: string) {
        return Promise.all([
            this.modeloBodegas.consultarBodegasPersonasDoc(bodegaPersonaFiltro),
            this.modeloClasificaciones.consultarListaClasificacionesPorFiltro({ EmpresaId }),
        ]).then((entidades) => ({
            bodegas: entidades[0].data.Entidades,
            clasificaciones: entidades[1].data.Entidades,
        }));
    }
}
