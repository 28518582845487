enum EnumAlmacenamientoEstado {
  Alistado = 'Alistado',
  Almacenado = 'Almacenado',
  Anulado = 'Anulado',
  AtenciónEnSitio = 'Atención en Sitio',
  Cancelada = 'Cancelada',
  Cerrada = 'Cerrada',
  CerradoConParciales = 'Cerrado con Parciales',
  Consolidado = 'Consolidado',
  Conteo = 'Conteo',
  Conteo2 = 'Conteo 2',
  Creada = 'Creada',
  Despachado = 'Despachado',
  DespachoParcial = 'Despacho Parcial',
  EnAlistamiento = 'En Alistamiento',
  EnAlmacenamiento = 'En Almacenamiento',
  EntregaConfirmada = 'Entrega Confirmada',
  EntregaPlaneada = 'Entrega Planeada',
  EntregaTránsito = 'Entrega Tránsito',
  Fallida = 'Fallida',
  PlataformaDestino = 'Plataforma Destino',
  PlataformaOrigen = 'Plataforma Origen',
  Procesado = 'Procesado',
  Reasignada = 'Reasignada',
  RecepciónParcial = 'Recepción Parcial',
  RecogidaConfirmada = 'Recogida Confirmada',
  RecogidaPlaneada = 'Recogida Planeada',
  Transito = 'Tránsito',
}

export default EnumAlmacenamientoEstado;