import ModeloItemUnidad from 'Infotrack@Modelos/smartStock/itemUnidad';

export default class ItemUnidadCM {
  private modeloItemUnidad: ModeloItemUnidad;
  constructor() {
    this.modeloItemUnidad = new ModeloItemUnidad();
  }
  public consultarItemsUnidadesPorUnidad(ItemId: number) {
    return this.modeloItemUnidad.consultarItemsUnidadesPorUnidad(ItemId).then(({ data: { Entidades } }) => Entidades);
  }
}
