export default {
  'sidebar.gestionoperador': 'Operator management',
  'gestionOperadores.Asignacion': 'Assigned',
  'gestionOperadores.Varios': 'Various',
  'gestionoperadores.documentosusuarios': 'Document users',
  'gestionoperadores.NombreUsuario': 'User name',
  'gestionoperadores.crearDocumentoUsuario': 'Assign document user',
  'gestionoperadores.editarDocumentoUsuario': 'Edit document user',
  'boton.asignar': 'To assign',
};
