import { createStyles, Theme } from '@material-ui/core';

const estilos = ({ palette, spacing }: Theme) =>
  createStyles({
    avatar: {
      '& div': {
        '&:hover': {
          transform: 'scale(1.1)'
        },
        backgroundColor: palette.primary.main,
        border: '3px solid white',
        boxShadow: '0px 3px 3px 3px rgba(0,0,0,.5)',
        height: 70,
        margin: '1em 0',
        transition: 'transform .5s linear',
        width: 70
      },
      '&:hover': {
        cursor: 'pointer',
      },
      textAlign: 'center'
    },
    barraLateral: {
      '& div:nth-of-type(3)': {
        alignItems: 'center',
      },
      width: 240,
    },
    barraLateralPapper: {
      width: 240,
    },
    btnCerrarSesion: {
      transform: 'rotate(180deg)'
    },
    contenedorBoton: {
      bottom: 15,
      position: 'absolute'
    },
    contenedorBotonVolver: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    logo: {
      '& img': {
        width: '100%'
      },
      '&:hover': {
        cursor: 'pointer'
      },
      margin: '.5em auto',
      width: '90%'
    },
    divisorBarra: {
      height: 2,
      width: '100%'
    },
    textoPerfil: {
      fontWeight: 'bolder',
      marginBottom: '1em'
    },
    numeroVersion: {
      margin: spacing(2, 0)
    }
  });

export default estilos;
