import ModeloEstadisticasDashboard from 'Infotrack@Modelos/smartStock/estadisticasDashboard';
import IFiltrosConsultasDashboard from 'Infotrack@Modelos/smartStock/estadisticasDashboard/entidades/filtrosConsultasDashboard';

export default class EstadisticasDashBoardCM {
  private modeloEstadisticasDashboard: ModeloEstadisticasDashboard;
  constructor() {
    this.modeloEstadisticasDashboard = new ModeloEstadisticasDashboard();
  }

  public consultarEstadisticasDashboard(filtros: IFiltrosConsultasDashboard) {
    return Promise.all([
      this.modeloEstadisticasDashboard.ConsultarRotacionItems({ ...filtros, OrdenarMayorAMenor: true }),
      this.modeloEstadisticasDashboard.ConsultarRotacionItems({ ...filtros, OrdenarMayorAMenor: false }),
      this.modeloEstadisticasDashboard.consultarItemsEnStockMinino(filtros),
      this.modeloEstadisticasDashboard.consultarItemsMasAntiguos(filtros),
      this.modeloEstadisticasDashboard.consultarUtilizacionBodega(filtros),
    ]).then((entidades) => ({
      itemsMayorRotacion: entidades[0].data.Entidades.map((entidad) => ({
        ...entidad,
        CantidadComparativa: entidad.CantidadComparativa
          ? ((entidad.Cantidad - entidad.CantidadComparativa) / entidad.CantidadComparativa) * 100
          : 0,
      })),
      itemsMenorRotacion: entidades[1].data.Entidades.map((entidad) => ({
        ...entidad,
        CantidadComparativa: entidad.CantidadComparativa
          ? ((entidad.Cantidad - entidad.CantidadComparativa) / entidad.CantidadComparativa) * 100
          : 0,
      })),
      itemsEnStockMinimo: entidades[2].data.Entidades,
      itemsMasAntiguos: entidades[3].data.Entidades,
      utilizacionBodega: entidades[4].data.Entidades,
    }));
  }
}
