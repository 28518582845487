import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloDocumentos from 'Infotrack@Modelos/smartStock/documentos';
import IDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/documento';
import IFiltrosDocumentos from 'Infotrack@Modelos/smartStock/documentos/entidades/filtrosDocumentosMovimientos';
import IDocumentoConDetallesAccion from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoConDetallesAccion';
import moment from 'moment';

export default class DocumentosCM {
    private modeloDocumentos: ModeloDocumentos;

    constructor() {
        this.modeloDocumentos = new ModeloDocumentos();
    }

    public consultarListaDocumentosPedidos = (paginador: IPaginador, documentos: IFiltrosDocumentos) =>
        this.modeloDocumentos
            .consultaListaDocumentosDespachosPaginados({ paginador, documentos })
            .then(({ data }) => data);

    public validarFiltros({ BodegaConsulta, FechaInicial, FechaFinal }: IFiltrosDocumentos) {
        if (!BodegaConsulta) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else if (moment(FechaFinal).isBefore(FechaInicial)) {
            notificacionGlobal('gestionPedidos.alerta.fechaInvalida', 6000, 'warning', true);
        } else if ((FechaInicial && !FechaFinal) || (FechaFinal && !FechaInicial)) {
            notificacionGlobal('gestionPedidos.alerta.fechaFaltante', 6000, 'warning', true);
        }
        return true;
    }

    public validarDocumento(documentoPedido: IDocumentoConDetallesAccion) {
        if (!documentoPedido.Documento) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else if (!documentoPedido.Documento.DireccionEntrega || !documentoPedido.Documento.PersonaRelacionada) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        }
        return true;
    }

    public async guardarDocumento(documentoPedido: IDocumento) {
        return this.modeloDocumentos.crearDocumentoDetallesDespachos(documentoPedido);
    }

    public anularDocumento(documento: IDocumento) {
        return this.modeloDocumentos.anularDocumentoDespachos(documento);
    }

    public modificarEstadoDespacho(documento: IDocumento) {
        return this.modeloDocumentos.modificarEstadoDespacho(documento);
    }
}
