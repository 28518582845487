export default {
  'sidebar.monitorpedidos': 'Monitor pedidos',
  'monitorPedidos.Bodegas': 'Bodegas',
  'monitorPedidos.Clientes': 'Clientes',
  'monitorPedidos.estadoPedidos': 'Estado pedidos',
  'monitorPedidos.cumplimientoPedidos': 'Cumplimiento pedidos',
  'monitorPedidos.cumplimientoPedidosHistorico': 'Cumplimiento pedidos histórico',
  'monitorPedidos.top10PedidosClientes': 'Top 10 pedidos clientes',
  'monitorPedidos.cantidad': 'Cantidad',
  'monitorPedidos.Creada': 'Creados',
  'monitorPedidos.Alistado': 'Alistados',
  'monitorPedidos.EnZonaDespacho': 'En zona despacho',
  'monitorPedidos.Atrasados': 'Atrasados',
  'monitorPedidos.Planeados': 'Planeados',
  'monitorPedidos.ProximosAVencer': 'Próximos a vencer',
  'monitorPedidos.CodigoDocumento': 'Código documento',
  'monitorPedidos.FechaCreacion': 'Fecha creación',
  'monitorPedidos.DescripcionCliente': 'Cliente',
  'monitorPedidos.Observacion': 'Observación',
  'monitorPedidos.documentos': 'Documentos',
  'monitorPedidos.reporte': 'Ver reporte',
  'monitorPedidos.Anulado': 'Anulado',
  'monitorPedidos.EnAlistamiento': 'En alistamiento',
  'monitorPedidos.Tránsito': 'Tránsito',
};
