import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import {  middlewareCoimpresores } from 'Infotrack@Modelos/conexiones';
import ImprimirItemEtiqueta from './entidades/IImprimirItemEtiqueta';

import ImpresionEtiquetas from './entidades/IImprimirItemEtiqueta';

class ModeloItems {
    public async imprimirEtiquetaMiddleware(itemsImpresion : ImprimirItemEtiqueta) {
        return manejadorRest<IRespuesta<ImpresionEtiquetas>>(
            () => middlewareCoimpresores.post(`Etiqueta/ImprimirEtiqueta`, itemsImpresion),
            true
        );
    }
}

export default ModeloItems;
