import ModeloClasesTecnicas from 'Infotrack@Modelos/smartStock/clasesTecnicas';
import ModeloClasificaciones from 'Infotrack@Modelos/smartStock/clasificaciones';
import ModeloFabricantes from 'Infotrack@Modelos/smartStock/fabricantes';
import ModeloFamilia from 'Infotrack@Modelos/smartStock/familias';
import IItemsPropiedades from 'Infotrack@Modelos/smartStock/itemPropiedades/entidades/itemPropiedad';
import ModeloItemTiposPropiedad from 'Infotrack@Modelos/smartStock/itemTiposPropiedades'
import IItemsTiposPropiedades from 'Infotrack@Modelos/smartStock/itemTiposPropiedades/entidades/itemTiposPropiedad';
import ModeloMarcas from 'Infotrack@Modelos/smartStock/marcas';
import ModeloReferencias from 'Infotrack@Modelos/smartStock/referencias';
import ModeloUnidades from 'Infotrack@Modelos/smartStock/unidades';
import IUnidades from 'Infotrack@Modelos/smartStock/unidades/entidades/unidades';
import { IEntidadesProducto } from '../interfaces';
import ModeloItemImagenes from 'Infotrack@Modelos/smartStock/itemImagenes';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';

export default class EntidadesItemCM {
  private modeloClasesTecnicas: ModeloClasesTecnicas;
  private modeloClasificaciones: ModeloClasificaciones;
  private modeloFabricantes: ModeloFabricantes;
  private modeloFamilia: ModeloFamilia;
  private modeloMarcas: ModeloMarcas;
  private modeloReferencias: ModeloReferencias;
  private modeloUnidades: ModeloUnidades;
  private modeloItemTiposPropiedades: ModeloItemTiposPropiedad;

  constructor() {
    this.modeloClasesTecnicas = new ModeloClasesTecnicas();
    this.modeloClasificaciones = new ModeloClasificaciones();
    this.modeloFabricantes = new ModeloFabricantes();
    this.modeloFamilia = new ModeloFamilia();
    this.modeloMarcas = new ModeloMarcas();
    this.modeloReferencias = new ModeloReferencias();
    this.modeloUnidades = new ModeloUnidades();
    this.modeloItemTiposPropiedades = new ModeloItemTiposPropiedad();
  }

  public consultarEntidadItem(EmpresaId: string): Promise<IEntidadesProducto> {
    return Promise.all([
      this.modeloClasesTecnicas.consultarListaClasesTecnicasPorFiltro({ EmpresaId,Estado:estadoActivo }),
      this.modeloClasificaciones.consultarListaClasificacionesPorFiltro({ EmpresaId,Estado:estadoActivo }),
      this.modeloFabricantes.consultarListaFabricantesPorFiltro({ EmpresaId,Estado:estadoActivo }),
      this.modeloFamilia.consultarListaFamiliasPorFiltro({ EmpresaId,Estado:estadoActivo }),
      this.modeloMarcas.consultarListaMarcasPorFiltro({ EmpresaId,Estado:estadoActivo  }),
      this.modeloReferencias.consultarListaReferenciasPorFiltro({ EmpresaId,Estado:estadoActivo }),
      this.modeloUnidades.consultarUnidadesFiltro({ EmpresaId,Estado:estadoActivo }),
    ]).then((entidades) => ({
      clasesTecnicas: entidades[0].data.Entidades,
      clasificaciones: entidades[1].data.Entidades,
      fabricantes: entidades[2].data.Entidades,
      familias: entidades[3].data.Entidades,
      marcas: entidades[4].data.Entidades,
      referencias: entidades[5].data.Entidades,
      unidades: entidades[6].data.Entidades,
    }));
  }

  public consultarTipoPropiedadesItem(EmpresaId: string): Promise<IItemsTiposPropiedades[]>{

    return this.modeloItemTiposPropiedades.consultarItemsTiposPropiedades({EmpresaId})
    .then(({ data: { Entidades }}) => Entidades);
  }

  public consultarUnidadesPorTipoUnidad(TipoUnidadId: number, EmpresaId: string): Promise<IUnidades[]> {
    return this.modeloUnidades
      .consultarUnidadesFiltro({
        EmpresaId,
        TipoUnidadId,
        Estado: 1,
      })
      .then(({ data: { Entidades } }) => Entidades);
  }
}
