import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloDocumento from 'Infotrack@Modelos/smartStock/documentos';
import IDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/documento';
import IFiltroGeneralDocumentoConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/filtroGeneralDocumentoConsulta';
import IFiltrosDocumentos from 'Infotrack@Modelos/smartStock/documentos/entidades/filtrosDocumentosMovimientos';
import IDocumentoConDetallesAccion from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoConDetallesAccion';

export default class DocumentoCM {
    private modeloDocumento: ModeloDocumento;

    constructor() {
        this.modeloDocumento = new ModeloDocumento();
    }

    public consultaGeneralDocumentosFiltroPaginado = (
        Paginador: IPaginador,
        Filtros: IFiltroGeneralDocumentoConsulta
    ) => this.modeloDocumento.consultaDocumentosDevoluciones({ Filtros, Paginador }).then(({ data }) => data);

    public async consultarDespachosDevoluciones(EmpresaId: string) {
        const {
            data: { Entidades },
        } = await this.modeloDocumento.consultarDespachosDevoluciones(EmpresaId);
        return Entidades;
    }

    public async consultaGeneralDocumentosFiltro(filtroDocumento: IFiltroGeneralDocumentoConsulta) {
        const {
            data: { Entidades },
        } = await this.modeloDocumento.consultaGeneralDocumentosFiltro(filtroDocumento);
        return Entidades[0];
    }

    public async crearDocumentoDevoluciones(documento: IDocumento) {
        await this.modeloDocumento.crearDocumentoDevoluciones(documento);
    }

    public async cambiarEtapaDevolucion(documento: IDocumento) {
        await this.modeloDocumento.cambiarEtapaDevolucion(documento);
    }

    public async modificarDocumentoDetallesDevoluciones(documento: IDocumentoConDetallesAccion) {
        await this.modeloDocumento.modificarDocumentoDetallesDevoluciones(documento);
    }

    public validarFiltros({ BodegaConsulta }: IFiltrosDocumentos) {
        if (!BodegaConsulta) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        }
        return true;
    }
}
