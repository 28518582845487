import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import IFlujoEtapaDocumento from '../../../../modelos/smartStock/flujoEtapaDocumento/entidades/flujoEtapaDocumento';
import ModeloFlujoAtapaDocumento from '../../../../modelos/smartStock/flujoEtapaDocumento/index';

class FlujoAtapaDocumentoCM {
    private modeloFlujoEtapaDocumento: ModeloFlujoAtapaDocumento;

    constructor() {
        this.modeloFlujoEtapaDocumento = new ModeloFlujoAtapaDocumento();
    }
    public async editarFlujoEtapaDocumento(flujoEtapaDocumento: IFlujoEtapaDocumento) {
        const respuesta = await this.modeloFlujoEtapaDocumento.editarFlujoEtapaDocumento(flujoEtapaDocumento);
        return respuesta.data;
    }

    public async guardarFlujoEtapaDocumento(flujoEtapaDocumento: IFlujoEtapaDocumento) {
        const respuesta = await this.modeloFlujoEtapaDocumento.guardarFlujoEtapaDocumento(flujoEtapaDocumento);
        return respuesta.data;
    }

    public async eliminarFlujoEtapaDocumento(flujoEtapaDocumento: IFlujoEtapaDocumento) {
        const respuesta = await this.modeloFlujoEtapaDocumento.eliminarFlujoEtapaDocumento(flujoEtapaDocumento);
        return respuesta;
    }

    public async consultarListaFlujoEtapaDocumento() {
        const respuesta = await this.modeloFlujoEtapaDocumento.consultarFlujoEtapaDocumento();
        return respuesta.data.Entidades;
    }

    public async consultarListaFlujoEtapaDocumentoComuesto(flujoEtapaDocumento: IFlujoEtapaDocumento) {
        const respuesta = await this.modeloFlujoEtapaDocumento.consultarFlujoEtapaDocumentoCompuesto(
            flujoEtapaDocumento
        );
        return respuesta.data.Entidades;
    }
    public validarEntidad(flujoEtapaDocumento: IFlujoEtapaDocumento): boolean {
        if (flujoEtapaDocumento.EtapaActual === 0 && flujoEtapaDocumento.EtapaSiguiente === 0) {
            notificacionGlobal('Por favor ingrese todos los campos requeridos (*)', 6000, 'warning', true);
            return false;
        }
        return true;
    }
}
export default FlujoAtapaDocumentoCM;
