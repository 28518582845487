import ModeloBodegaDivisiones from '../../../../modelos/smartStock/bodegasDivisiones/index';
import ModeloItems from '../../../../modelos/smartStock/items';
import Bodega from '../../../../modelos/smartStock/bodegas';

class EntidadesDetalleCM {
    private modeloBodegaDivision: ModeloBodegaDivisiones;
    private modeloItem: ModeloItems;
    private modeloBodega: Bodega;

    constructor() {
        this.modeloBodegaDivision = new ModeloBodegaDivisiones();
        this.modeloItem = new ModeloItems();
        this.modeloBodega = new Bodega();
    }

    public async consultarEntidadesDetalle(
        bodegaDestinoId: number,
        tipoDocumentoId: number,
        bodegaOrigenId: number,
        EmpresaId: string,
        AgenciaId: string,
        UsuarioId: string,
       
    ) {
         
        const respuestas = await Promise.all([
            this.modeloItem.consultarListaItemsCompuestosFiltro({EmpresaId:EmpresaId}),
            this.modeloBodegaDivision.consultarDivisionesDocumentosDef({
                BodegaId: bodegaOrigenId,
                TipoDocumentoId: tipoDocumentoId,
            }),
            this.modeloBodega.consultarBodegasPersonasDoc({ AgenciaId, UsuarioId, EmpresaId }), 
            this.modeloBodegaDivision.consultarDivisionesListaFiltro({
                BodegaId: bodegaDestinoId,
                Estado:1
            }),
        ]);
        return {
            items: respuestas[0].data.Entidades,
            divisionesOrigen: respuestas[1].data.Entidades,
            bodegasOrigen: respuestas[2].data.Entidades,
            divisionesDestino: respuestas[3].data.Entidades,
        };
    }
}
export default EntidadesDetalleCM;
