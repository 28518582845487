import { Fab, Grid } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';

import IItemsConsulta from 'Infotrack@Modelos/smartStock/items/entidades/IItemsConsulta';

import CampoItemCodigoBarras from './CampoItemCodigoBarras';
import CampoItemDescripcion from './CampoItemDescripcion';

interface IDetallesInventarioFiltroItemProps {
    aplicarFiltroItem: (item: IItemsConsulta) => void;
    deshabilitado: boolean;
    items: IItemsConsulta[];
    itemSeleccionado: IItemsConsulta | null;
    seleccionarItem: (item: IItemsConsulta | null) => void;
}

const DetallesInventarioFiltroItem: FunctionComponent<IDetallesInventarioFiltroItemProps> = ({
    aplicarFiltroItem,
    deshabilitado,
    items,
    itemSeleccionado,
    seleccionarItem,
}) => (
    <Grid container spacing={1}>
        <Grid item>
            <CampoItemCodigoBarras
                deshabilitado={deshabilitado}
                items={items}
                manejarCambio={(nuevoValor: IItemsConsulta | null) => seleccionarItem(nuevoValor)}
                valor={itemSeleccionado}
            />
        </Grid>
        <Grid item>
            <CampoItemDescripcion
                deshabilitado={deshabilitado}
                items={items}
                manejarCambio={(nuevoValor: IItemsConsulta | null) => seleccionarItem(nuevoValor)}
                valor={itemSeleccionado}
            />
        </Grid>
        <Grid item>
            <Fab
                color="primary"
                onClick={() => aplicarFiltroItem(itemSeleccionado!)}
                disabled={deshabilitado || !itemSeleccionado}
                size="small"
            >
                <SearchIcon />
            </Fab>
        </Grid>
    </Grid>
);

export default DetallesInventarioFiltroItem;
