import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    contenedorFiltrosEstilos: {
        paddingBottom: 0,
    },
    campo: {
        width: '200px',
    },
});

export default useStyles;
