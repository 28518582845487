import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import Texto from '@infotrack/presentacion-componentes/texto';
import { Button, Grid, TextField } from '@material-ui/core';
import IEtiqueta from 'Infotrack@Modelos/smartStock/etiquetas/entidades/etiqueta';
import React, { FunctionComponent } from 'react';

interface IInformacionProductosProps {
    mostrarBoton: boolean;
    abrirTablaEtiquetasItems?: () => void;
    etiqueta: IEtiqueta;
}

const InformacionProductos: FunctionComponent<IInformacionProductosProps> = ({
    mostrarBoton,
    abrirTablaEtiquetasItems,
    etiqueta,
}) => {
    const validarEtiqueta = () => {
        if (etiqueta === undefined) {
            notificacionGlobal('TablaImpresion.SeleccioneEtiqueta', 6000, 'warning', true);
            return false;
        } else {
            abrirTablaEtiquetasItems!();
        }
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item lg={mostrarBoton ? 2 : 3} xs={12}>
                        <TextField
                            disabled={true}
                            fullWidth
                            value={etiqueta ? etiqueta.NombreEtiqueta : ''}
                            label={<Texto id="TablaImpresion.DescripcionEtiqueta" />}
                        />
                    </Grid>
                    <Grid item lg={mostrarBoton ? 2 : 3} md={3} sm={6} xs={12}>
                        <TextField
                            disabled={true}
                            fullWidth
                            value={etiqueta ? etiqueta.PapelTamanio : ''}
                            label={<Texto id="TablaImpresion.Tamano" />}
                        />
                    </Grid>
                    <Grid item lg={2} md={1} sm={6} xs={8}>
                        <TextField
                            disabled={true}
                            fullWidth
                            value={etiqueta ? etiqueta.Clasificacion : ''}
                            label={<Texto id="TablaImpresion.Clasificacion" />}
                        />
                    </Grid>
                    <Grid item lg={2} md={1} sm={6} xs={8}>
                        <TextField
                            disabled={true}
                            fullWidth
                            value={etiqueta ? etiqueta.Margenes : ''}
                            label={<Texto id="TablaImpresion.Margenes" />}
                        />
                    </Grid>
                    <Grid item lg={2} md={1} sm={6} xs={8}>
                        <TextField
                            disabled={true}
                            fullWidth
                            label={<Texto id="TablaImpresion.EsPallet" />}
                            value={etiqueta ? (etiqueta.Pallet ? 'Si' : 'No') : ''}
                        />
                    </Grid>
                    {mostrarBoton && (
                        <Grid item lg={2} md={3} sm={6} xs={12}>
                            <Button
                                color="primary"
                                onClick={() => {
                                    validarEtiqueta();
                                }}
                                size="small"
                                variant="contained"
                            >
                                <Texto id="boton.AbrirFoemularioItemEtiqueta" />
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
export default InformacionProductos;
