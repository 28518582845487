export default {
  'sidebar.devoluciones': 'Devoluciones cliente',
  'devolucion.modificarEtapa': 'Cambiar etapa',
  'permiso.devolucion': 'Acceso',
  'devolucion.numeroDocumento': 'N° Documento',
  'devolucion.revertirDocumento': 'Revertir',
  'devolucion.CodigoDocumento': 'Código documento',
  'devolucion.DescripcionBodega': 'Bodega',
  'devolucion.DescripcionBodegaOrigen': 'Bodega origen',
  'devolucion.DescripcionBodegaDestino': 'Bodega destino',
  'devolucion.Bodega': 'Bodega',
  'devolucion.Documento': 'Documento',
  'devolucion.TipoDocumentoDescripcion': 'Tipo documento',
  'devolucion.EtapaDescripcion': 'Etapa descripción',
  'devolucion.FechaCreacion': 'Fecha creación',
  'devolucion.documento.DireccionEntrega': 'Dirección entrega',
  'devolucion.documento.PersonaRelacionada': 'Persona relacionada',
  'devolucion.documento.CodigoDocumento': 'Código',
  'devolucion.documento.BodegaOrigen': 'Bodega',
  'devolucion.documento.DocumentoRelacionado': 'Documento relacionado',
  'devolucion.documento.DescripcionProveedor': 'Proveedor',
  'devolucion.documento.FechaCreacion': 'Fecha creación',
  'devolucion.documento.EtapaDescripcion': 'Etapa',
  'devolucion.documento.Observacion': 'Observación',
  'devolucion.documento.editarDocumento': 'Editar devolución',
  'devolucion.documento.crearDocumento': 'Crear devolución',
  'devolucion.documento.datosDocumento': 'Datos documento',
  'devolucion.documentoDetalle': 'Detalles del documento',
  'devolucion.documentoDetalle.editarDetalleDocumento': 'Editar detalle',
  'devolucion.documentoDetalle.CantidadRecibida': 'Cantidad solicitada',
  'devolucion.documentoDetalle.CantidadEntregada': 'Cantidad devuelta',
  'devolucion.documentoDetalle.DescripcionItem': 'Descripción ítem',
  'devolucion.documentoDetalle.CodigoBarras': 'Código de barras',
  'devolucion.documentoDetalle.CantidadSolicitada': 'Cantidad solicitada',
  'devolucion.documentoDetalle.DivisionDestino': 'División',
};
