/*import AES256 from '@infotrack/utilitarios/aes256';
import { IEstadoPersistido } from 'Infotrack@Principal/contextoGeneral/interfaces';
import { llaveGlobal } from 'Infotrack@Transversales/constantes/crendeciales';
import XLSX from 'xlsx';

export const obtenerConfiguracionApp = (): IEstadoPersistido => {
    const aes256 = new AES256();
    const estado = sessionStorage.getItem('estadoApp');
    if (estado) {
        return JSON.parse(aes256.descifrar(estado, llaveGlobal));
    } else {
        return {
            aplicaciones: [],
            idAplicacionSeleccionada: undefined,
            lenguajeActivo: undefined,
            temaActivo: undefined,
            token: undefined,
            subMenuSeleccionado: undefined,
            rutaActiva: 'app',
            usuario: {
                NombreCompleto: '',
                NombreUsuario: '',
                CorreoElectronico: '',
                IdUsuario: '',
                Foto: '',
                IdEmpresa: '',
                Token: '',
            },
        };
    }
};

export const modificarTituloIconoAplicacion = (titulo: string) => {
    window.document.title = titulo;
    const favIconAnterior = document.getElementById('favicon') as HTMLLinkElement;
    const nuevoFavIcon = document.createElement('link');
    nuevoFavIcon.id = 'favicon';
    nuevoFavIcon.rel = 'shortcut icon';
    nuevoFavIcon.href = `${titulo.replace(/ /g, '').toLowerCase()}.ico`;
    if (favIconAnterior) {
        document.head.removeChild(favIconAnterior);
    }
    document.head.appendChild(nuevoFavIcon);
};

export const comprimirImagen = (base64: string): Promise<string> => {
    const canvas = document.createElement('canvas');
    const img = document.createElement('img');
    img.src = base64;

    return new Promise((resolve) => {
        img.onload = () => {
            if (img.width > img.height) {
                const relWidth = img.width / 16;
                const relHeigth = img.height / 9;
                if (relWidth === relHeigth) {
                    canvas.width = 768;
                    canvas.height = 432;
                } else {
                    canvas.width = 800;
                    canvas.height = 600;
                }
            } else {
                const relHeigth = img.height / 16;
                const relWidth = img.width / 9;
                if (relWidth === relHeigth) {
                    canvas.width = 432;
                    canvas.height = 768;
                } else {
                    canvas.width = 600;
                    canvas.height = 800;
                }
            }
            const contexto = canvas.getContext('2d');
            contexto!.drawImage(img, 0, 0, canvas.width, canvas.height);
            resolve(canvas.toDataURL('image/jpeg', 0.9));
        };
    });
};

export function exportarExcel<T>(datos: T[][], nombreArchivo: string) {
    const datosFormateados = datos.map((fila) =>
        fila.map((celda) => (typeof celda === 'number' ? celda.toString() : celda))
    );
    const hojaCalculo = XLSX.utils.aoa_to_sheet(datosFormateados);
    const libro = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(libro, hojaCalculo, nombreArchivo);
    const numeroColumnas = XLSX.utils.decode_range(hojaCalculo['!ref']!).e.c;
    hojaCalculo['!cols'] = [];
    for (let i = 0; i <= numeroColumnas; i++) hojaCalculo['!cols']!.push({ wpx: 120 });
    XLSX.writeFile(libro, `${nombreArchivo}.xlsx`);

}*/

import AES256 from '@infotrack/utilitarios/aes256';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { llaveGlobal } from 'Infotrack@Transversales/constantes/crendeciales';
import { useLocation } from 'react-router';
import XLSX from 'xlsx';

export const obtenerConfiguracionApp = (): IEstadoGlobal => {
    const aes256 = new AES256();
    const estado = sessionStorage.getItem('estadoApp');
    if (estado) {
        return JSON.parse(aes256.descifrar(estado, llaveGlobal));
    } else {
        return {
            estadoAutenticacion: {
                agenciaActual: null,
                menus: [],
                permisos: [],
                usuarioAplicacionAgenciaActual: null,
                usuarioInformacion: null,
            },
            estadoConfiguracion: {
                lenguajeActivo: 'es',
                lenguajes: ['es', 'en'],
                menuSeleccionado: 0,
                rutaActiva: 'app',
                subMenuSeleccionado: '',
                temaActivo: 'SmartStock',
            },
        };
    }
};
export const modificarTituloIconoAplicacion = (titulo: string) => {
    window.document.title = titulo;
    const favIconAnterior = document.getElementById('favicon') as HTMLLinkElement;
    const nuevoFavIcon = document.createElement('link');
    nuevoFavIcon.id = 'favicon';
    nuevoFavIcon.rel = 'shortcut icon';
    nuevoFavIcon.href = `${titulo.replace(/ /g, '').toLowerCase()}.ico`;
    if (favIconAnterior) {
        document.head.removeChild(favIconAnterior);
    }
    document.head.appendChild(nuevoFavIcon);
};

export function exportarExcel<T>(datos: T[][], nombreArchivo: string) {
    const datosFormateados = datos.map((fila) =>
        fila.map((celda) => (typeof celda === 'number' ? celda.toString() : celda))
    );
    const hojaCalculo = XLSX.utils.aoa_to_sheet(datosFormateados);
    const libro = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(libro, hojaCalculo, nombreArchivo);
    const numeroColumnas = XLSX.utils.decode_range(hojaCalculo['!ref']!).e.c;
    hojaCalculo['!cols'] = [];
    for (let i = 0; i <= numeroColumnas; i++) hojaCalculo['!cols']!.push({ wpx: 120 });
    XLSX.writeFile(libro, `${nombreArchivo}.xlsx`);
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const comprimirImagen = (base64: string): Promise<string> => {
    const canvas = document.createElement('canvas');
    const img = document.createElement('img');
    img.src = base64;

    return new Promise((resolve) => {
        img.onload = () => {
            if (img.width > img.height) {
                const relWidth = img.width / 16;
                const relHeigth = img.height / 9;
                if (relWidth === relHeigth) {
                    canvas.width = 768;
                    canvas.height = 432;
                } else {
                    canvas.width = 800;
                    canvas.height = 600;
                }
            } else {
                const relHeigth = img.height / 16;
                const relWidth = img.width / 9;
                if (relWidth === relHeigth) {
                    canvas.width = 432;
                    canvas.height = 768;
                } else {
                    canvas.width = 600;
                    canvas.height = 800;
                }
            }
            const contexto = canvas.getContext('2d');
            contexto!.drawImage(img, 0, 0, canvas.width, canvas.height);
            resolve(canvas.toDataURL('image/jpeg', 0.9));
        };
    });
};

export const comprimirImagenDocumentoEvidencia = (base64: string,esPdf: boolean): Promise<string> => {
    const canvas = document.createElement('canvas');
    const img = document.createElement('img');
    let imagenPDF = '../../../../../public/imagenPDF.png'
    img.src = base64;
    //img.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHsAAAB7CAMAAABjGQ9NAAAA8FBMVEX///8uLi6+vr4gICCvr68dHR2np6ddXV1kZGQyMjIaGhq2trYTExMrKyvz8/P29vZFRUVMTEzIyMjz2dj9//oAAADvho3d3d39ISv4Ex5XV1fyEyr2mp///P/r6+v9IDPseoIuMzc9PT3T09P2///1IzT14+NdKioYEBGYmJh+fn5sbGwkLzAAFRmBVFRSGBfBoqXwr7nZABX0xMTvKizkQFL1pKTtq6j2OEH409nstrv3ubrrBBrnR0/uycvvhYP3AADxWWH0ko7mlJToOD3mN0nrZG/kU2D94ejnoKTulonFITCyLDXbX2TPAADgHyvaF+ZoAAADC0lEQVRoge3ZDVPaMBjA8acgQlhb3jGhlDpsy3Sv0LoxYFgUGG46vv+3WTrolI1W3CWy857feSdnvftfSBNKC4AQQgghhBBCCP2Xyi8ekNJlpbOZ/EO0IznpQ5UoSQinKHLiOU0plmI1SjytEa3YktHOKmo54XCLkPZBjaQbMto5RU0lHD5SiAanKknn9tMuwFmGZM721IbXaaIe7qmtNzSS+H+PUk1sm9HxdZufcwrJnwhqm+a6/ebttvHYtrnRhqMaUTRBK80GtxJ613n/obKFC5vtX3EiZHc17e7ICDmc8Tfa8/5oQypPtJKYdp3RWJZPWTNqKzoUQnCqkbSI860KdWZZ1HF83xitfvuM+pZj+Py1Zdxrk0aEkPSBqLZDj92Ku/DO2UfXXVQ+ObTPJ9p1PYdGbShq5N7nirA2NVgdPg/G4PaG4Hku6KMvZmEwHnetu3ZLqakRRVibzytvXwTBwJ4M7X4QdO3Lvl4JgoDdawOclCMlge+5xdtXzrQCX4bQt9gMvKXu1uvN2Ub7TlYR2rbDE3gYDGHGJxu8mc7/UJ3TJ2mDV2/OHTaAKWVzGM5MdzmdWXfnudT2RcCYxbpw7nxdwKSvLwKLkz5uI2xfMsex6GoTuw5mhUVADSr9PQ8Lk+aE72IWnTe/XV5MGe3X6zQcN1/gstvGagvls8vWOywLdzv542ZOeE6tZ5et6sbqVUhmuxnwrdtwvt/c3B5vM5DWNm24/uEvl8vO9muHrQS1w/i4N/KXnZevnrpthj/67WgP7fBirFqF6lVvD+OODDqPuPYV3ObfBffXzkbtcpKC1LauqfFqLSntnLI61/S2RmLJaq/HXcjG3wEotWWNez3fBT0ByG3v4Fm2y6kEctZY1G6RdDxpa2y9vtsJt9hktxvFeET2GksCcts7eJbtcir+Y0zyGtOJGn8TW/4ae/rPsahd1OJJbu8C29jG9j+1k54TyW1rSiO3o7aY+4q/hc8FE3a0DYSoYh8QNvL5zI7ymVOhaYDU4a4ORD2sQQghhBBCCCGExPkJtt58pK57F0MAAAAASUVORK5CYII='
    return new Promise((resolve) => {
        img.onload = () => {
            if (img.width > img.height) {
                const relWidth = img.width / 16;
                const relHeigth = img.height / 9;
                if (relWidth === relHeigth) {
                    canvas.width = 768;
                    canvas.height = 432;
                } else {
                    canvas.width = 800;
                    canvas.height = 600;
                }
            } else {
                const relHeigth = img.height / 16;
                const relWidth = img.width / 9;
                if (relWidth === relHeigth) {
                    canvas.width = 432;
                    canvas.height = 768;
                } else {
                    canvas.width = 600;
                    canvas.height = 800;
                }
            }
            const contexto = canvas.getContext('2d');
            contexto!.drawImage(img, 0, 0, canvas.width, canvas.height);
            resolve(canvas.toDataURL('image/jpeg', 0.9));
        };
    });
};
