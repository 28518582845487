import ModeloDocumentos from 'Infotrack@Modelos/smartStock/documentos';

export default class EntidadesDocumentoCM {
  private modeloDocumentos: ModeloDocumentos;

  constructor() {
    this.modeloDocumentos = new ModeloDocumentos();
  }

  public consultarEntidadesDocumento(EmpresaId: string, UsuarioId: string) {
    return Promise.all([
      this.modeloDocumentos.consultaListaDocumentosEnZonaDespachoPorFiltro({ EmpresaId, UsuarioId }),
    ]).then((entidades) => ({
      documentos: entidades[0].data.Entidades,
    }));
  }
}
