import ModeloBodegas from 'Infotrack@Modelos/smartStock/bodegas';
import IBodegaPersonaFiltro from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaPersonaFiltro';
import ModeloClientes from 'Infotrack@Modelos/smartStock/clientes';
import ModeloEtapas from 'Infotrack@Modelos/smartStock/etapas';

export default class EntidadesFiltrosDocumentoCM {
    private modeloBodegas: ModeloBodegas;
    private modeloClientes: ModeloClientes;
    private modeloEtapas: ModeloEtapas;

    constructor() {
        this.modeloBodegas = new ModeloBodegas();
        this.modeloClientes = new ModeloClientes();
        this.modeloEtapas = new ModeloEtapas();
    }

    public async consultarEntidadesFiltrosDocumento(bodegaPersonaFiltro: IBodegaPersonaFiltro) {
        const entidades = await Promise.all([
            this.modeloBodegas.consultarBodegasPersonasDoc(bodegaPersonaFiltro),
            this.modeloClientes.consultarClientes(),
            this.modeloEtapas.consultarEtapas(),
        ]);
        return {
            bodegas: entidades[0].data.Entidades,
            clientes: entidades[1].data.Entidades,
            etapas: entidades[2].data.Entidades,
        };
    }
}
