import ModeloFlujo from 'Infotrack@Modelos/smartStock/flujos';
import ModeloTipoProceso from 'Infotrack@Modelos/negocioRefactor/modelos/Administracion/ModeloTipoProceso';
import ModeloIPlantillaImpresion from 'Infotrack@Modelos/negocioRefactor/modelos/Administracion/ModeloPlantillaImpresion';

import { IEntidadesTipoDocumento } from '../ModalTipoDocumento/ModalTipoDocumento';

class EntidadesTipoDocumentoCM {
    private modeloFlujo: ModeloFlujo;
    private modeloTipoProceso: ModeloTipoProceso;
    private modeloIPlantillaImpresion: ModeloIPlantillaImpresion;

    constructor() {
        this.modeloFlujo = new ModeloFlujo();
        this.modeloTipoProceso = new ModeloTipoProceso();
        this.modeloIPlantillaImpresion = new ModeloIPlantillaImpresion();
    }

    public async consultarEntidadesTipoDocumento(idEmpresa: string, estado?: number): Promise<IEntidadesTipoDocumento> {
        const respuestas = await Promise.all([
            this.modeloFlujo.consultarFlujosPorFiltro({ EmpresaId: idEmpresa, Estado: estado }),
            this.modeloTipoProceso.consultarListaTipoProceso(),
            this.modeloIPlantillaImpresion.consultarListaPlantillaImpresionWeb(idEmpresa),
        ]);
        return {
            flujos: respuestas[0].data.Entidades,
            tiposProceso: respuestas[1].data.Entidades,
            plantillaImpresion: respuestas[2].data.Entidades,
        };
    }
}

export default EntidadesTipoDocumentoCM;
