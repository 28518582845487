import Texto from '@infotrack/presentacion-componentes/texto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import {
    comprimirImagen,
    comprimirImagenDocumentoEvidencia,
} from 'Infotrack@Transversales/utilitarios/funcionesGenerales';
import React from 'react';
import { IImagen, IInputImagenDocumentoCVProps } from '../interfaces';
import InputImagenVista from '../vista/inputImagenDocumentoVista';
import DocumentoEvidenciaCM from '../controladorModelo/documentoEvidenciaCM';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { useSelector } from 'react-redux';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';

const InputImagenDocumentoCV: React.FunctionComponent<IInputImagenDocumentoCVProps> = ({
    alCargarImagen: alCargarImagenProp,
    alEliminarImagen: alEliminarImagenProp,
    estadoImagen,
    idTooltip,
    limiteImagenes,
    ocultarTitulo,
    idDocumento,
    estadoFormulario,
    deshabilitarBoton,
}) => {
    const token = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.TokenServicioSeguridad);
    const empresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const [estado, setEstado] = React.useState<boolean>(false);
    const [estadoCarga, setEstadoCarga] = React.useState<boolean>(false);
    const [reiniciarGaleria, setReiniciarGaleria] = React.useState<boolean>(false);
    const [imagenes64, setImagenes64] = React.useState<IImagen[]>([]);
    const [indiceGaleria, setIndiceGaleria] = React.useState<number>(0);
    const [cantidadImagenesSubidas, setCantidadImagenesSubidas] = React.useState<number>(-1);
    const documentoEvidenciaCM = new DocumentoEvidenciaCM();

    React.useEffect(() => {
        if (imagenes64.length === cantidadImagenesSubidas) setEstadoCarga(false);
    }, [imagenes64]);

    React.useEffect(() => {
        if (estado) {
            consultarEvidenciaDocumento();
        }
    }, [estado]);

    React.useEffect(() => {
        if (estadoImagen) {
            if (estadoImagen.length === 0) setImagenes64([]);
            else {
                const imagenesACargar: IImagen[] = [];
                estadoImagen.forEach((imagen) => {
                    comprimirImagenDocumentoEvidencia(imagen.imagen as string, false).then((imgArray) => {
                        setImagenes64([
                            ...imagenesACargar,
                            { imagen: imgArray, propiedadesImagen: imagen.propiedadesImagen },
                        ]);
                        imagenesACargar.push({ imagen: imgArray, propiedadesImagen: imagen.propiedadesImagen });
                    });
                });
            }
        }
    }, [estadoImagen]);

    const alAbrirCerrarDialogo = () => {
        setImagenes64([]);
        setEstado(!estado);
    };

    const alRecibirImagen = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
        if (files) {
            if (limiteImagenes && files.length > limiteImagenes) {
                notificacionGlobal('documentoEvidencias.alerta.limiteImagenes', 6000, 'warning', true);
                setEstadoCarga(false);
                return;
            }
            const imagenes: IImagen[] = [...imagenes64.map((imagen) => ({ ...imagen }))];
            setCantidadImagenesSubidas(files.length);
            setEstadoCarga(true);
            for (let indice = 0; indice < files.length; indice++) {
                const archivo = files.item(indice);
                if (!archivo!.type.includes('image') && archivo!.type !== 'application/pdf') {
                    notificacionGlobal('documentoEvidencias.alerta.archivoInvalido', 6000, 'warning', true);
                    setEstadoCarga(false);
                    return;
                }
                if (files.length > 0 && validarPesoPdf(archivo!)) {
                    guardarDocumentoEvidencia(archivo!);
                }
            }
        }
    };

    const guardarDocumentoEvidencia = (archivo: File | null) => {
        const imagenes: IImagen[] = [...imagenes64.map((imagen) => ({ ...imagen }))];
        let documentoidEvidencia = crearGuid();
        obtenerBase64(archivo!).then((data) => {
            documentoEvidenciaCM.GuardarDocumentoEvidencia(
                {
                    NombreArchivo: archivo!.name,
                    DocumentoId: idDocumento,
                    DocumentoEvidenciaId: documentoidEvidencia,
                },
                archivo!,
                token!
            );
            const nuevasImagenes = [
                ...imagenes,
                {
                    imagen: data as string,
                    propiedadesImagen: archivo!,
                    idDocumentoEvidencia: documentoidEvidencia,
                },
            ];
            setImagenes64(nuevasImagenes);
            alCargarImagenProp(nuevasImagenes);
            imagenes.push({
                imagen: data as string,
                propiedadesImagen: archivo!,
            });
        });
    };

    const validarPesoPdf = (file: File) => {
        if (file.size > 15728640) {
            notificacionGlobal('documentoEvidencias.PesoPdfInvalido', 6000, 'warning', true);
            return false;
        } else {
            return true;
        }
    };

    const crearGuid = () => {
        var dt = new Date().getTime();
        var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
        return guid;
    };

    const consultarEvidenciaDocumento = async () => {
        const imagenes: IImagen[] = [...imagenes64.map((imagen) => ({ ...imagen }))];
        const respuesta = await documentoEvidenciaCM.ConsultarDocumentoEvidencia({ DocumentoId: idDocumento });
        respuesta.map((x) => {
            let file = obtenerArchivoBase64(x.Archivo, x.NombreArchivo, x.TipoMime);
            const nuevasImagenes = [
                ...imagenes,
                {
                    imagen: x.Archivo,
                    propiedadesImagen: file,
                    idDocumentoEvidencia: x.DocumentoEvidenciaId,
                },
            ];
            setImagenes64(nuevasImagenes);
            alCargarImagenProp(nuevasImagenes);
            imagenes.push({
                imagen: x.Archivo,
                propiedadesImagen: file,
                idDocumentoEvidencia: x.DocumentoEvidenciaId,
            });
        });
    };

    function obtenerBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    const rotarImagen = (derecha: boolean) => {
        const canvas = document.createElement('canvas');
        const contexto = canvas.getContext('2d');
        const img = new Image();
        setReiniciarGaleria(true);
        img.src = imagenes64[indiceGaleria!]!.imagen as string;
        canvas.height = img.width;
        canvas.width = img.height;

        if (derecha) {
            contexto!.rotate((90 * Math.PI) / 180);
            contexto!.translate(0, -canvas.width);
        } else {
            contexto!.rotate((-90 * Math.PI) / 180);
            contexto!.translate(-canvas.height, 0);
        }
        contexto!.drawImage(img, 0, 0);
        const nuevaImagen = canvas.toDataURL('image/jpeg', 0.9);
        const nuevasImagenes = [...imagenes64];
        nuevasImagenes[indiceGaleria!].imagen = nuevaImagen;
        setImagenes64(nuevasImagenes);
        alCargarImagenProp(nuevasImagenes);
        setTimeout(() => {
            setReiniciarGaleria(false);
        }, 200);
    };

    const alPasarImagen = (nuevoIndiceImagen: number) => {
        setIndiceGaleria(nuevoIndiceImagen);
    };

    const confirmarEliminacionImagen = () => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: alEliminarImagen,
            estado: true,
            mensaje: <Texto id="gestionproducto.alerta.eliminarImagen" />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const alEliminarImagen = () => {
        manejadorDialogoGlobal({ estado: false });
        const imagenAEliminar = imagenes64[indiceGaleria];
        if (imagenAEliminar) {
            setIndiceGaleria(0);
            documentoEvidenciaCM.EliminarDocumentoEvidencia({
                DocumentoEvidenciaId: imagenAEliminar.idDocumentoEvidencia!,
                DocumentoId: idDocumento,
            });
            const imagenes = imagenes64.filter((x) => x.idDocumentoEvidencia !== imagenAEliminar.idDocumentoEvidencia);
            setImagenes64(imagenes);
        }
    };

    const obtenerArchivoBase64 = (string64: string, fileName: string, typeFile: string) => {
        const buffer = new ArrayBuffer(string64.length);
        const view = new Uint8Array(buffer);
        for (let n = 0; n < string64.length; n++) {
            view[n] = string64.charCodeAt(n);
        }
        const type = typeFile;
        const blob = new Blob([buffer], { type });
        const file = new File([blob], fileName, { lastModified: new Date().getTime(), type });
        return file;
    };

    return (
        <InputImagenVista
            alAbrirCerrarDialogo={alAbrirCerrarDialogo}
            alEliminarImagen={confirmarEliminacionImagen}
            alPasarImagen={alPasarImagen}
            alRecibirImagen={alRecibirImagen}
            estado={estado}
            estadoCarga={estadoCarga}
            idTooltip={idTooltip}
            imagenes={imagenes64}
            indiceGaleria={indiceGaleria}
            limiteImagenes={limiteImagenes}
            reiniciarGaleria={reiniciarGaleria}
            rotarImagen={rotarImagen}
            ocultarTitulo={ocultarTitulo}
            documentoId={idDocumento}
            estadoFormulario={estadoFormulario}
            deshabilitarBoton={deshabilitarBoton}
        />
    );
};

export default InputImagenDocumentoCV;
