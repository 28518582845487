import { Card, Fab, withStyles } from '@material-ui/core';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import FullScreenExitIcon from '@material-ui/icons/FullscreenExit';
import clsx from 'clsx';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { IReportesPowerVistaProps } from '../interfaces';
import { estilos } from './estilos';

const ReportesPowerVista: React.FunctionComponent<PropsEstilosMaterialUI<IReportesPowerVistaProps>> = ({
    alCambiarPantallaCompleta,
    classes,
    esPantallaCompleta,
    parametrosReporte,
}) => {
    return (
        <Card
            className={clsx(classes.contenedorIframe, {
                [classes.contenedorIframePantallaCompleta]: esPantallaCompleta,
            })}
        >
            <PowerBIEmbed
                embedConfig={{
                    type: 'report',
                    id: parametrosReporte.ReportId,
                    embedUrl: parametrosReporte.EmbedUrl,
                    accessToken: parametrosReporte.EmbedToken,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            },
                        },
                        background: models.BackgroundType.Transparent,
                    },
                }}
                cssClassName={`report-style-class ${clsx(classes.estilosIframe, {
                    [classes.estilosIframePantallaCompleta]: esPantallaCompleta,
                })}`}
            />
            <Fab
                className={classes.botonExpandirPantalla}
                color="primary"
                onClick={alCambiarPantallaCompleta}
                size="medium"
            >
                {esPantallaCompleta ? <FullScreenExitIcon /> : <FullScreenIcon />}
            </Fab>
        </Card>
    );
};

export default withStyles(estilos)(ReportesPowerVista);
