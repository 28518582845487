import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';

interface ICampoBodegaOrigenProps {
    bodegasOrigen: IBodega[];
    manejarCambioFiltro: (
        nombreFiltro: keyof IDocumentoFiltro,
        nuevoValor: IDocumentoFiltro[keyof IDocumentoFiltro]
    ) => any;
    valor?: number;
}

const CampoBodegaOrigen: FunctionComponent<ICampoBodegaOrigenProps> = ({
    bodegasOrigen,
    manejarCambioFiltro,
    valor,
}) => (
    <Autocomplete
        getOptionLabel={(b) => b.BodegaDescripcion}
        onChange={(_: object, value: IBodega | null) => manejarCambioFiltro('BodegaOrigen', value ? value.BodegaId : 0)}
        options={bodegasOrigen}
        renderInput={(params) => (
            <TextField {...params} fullWidth label={<Texto id="Documentos.CampoBodegaOrigen.Nombre" />} />
        )}
        value={bodegasOrigen.find((b) => b.BodegaId === valor) || null}
    />
);

export default CampoBodegaOrigen;
