import ModeloBodega from 'Infotrack@Modelos/smartStock/bodegas';
import IBodegaPersonaFiltro from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaPersonaFiltro';

export default class EntidadesDocumentoCM {
    private modeloBodega: ModeloBodega;

    constructor() {
        this.modeloBodega = new ModeloBodega();
    }

    public async consultarEntidadesDocumento(bodegaPersonaFiltro: IBodegaPersonaFiltro) {
        const {
            data: { Entidades },
        } = await this.modeloBodega.consultarBodegasPersonasDoc(bodegaPersonaFiltro);
        return Entidades;
    }
}
