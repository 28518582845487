import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import Texto from '@infotrack/presentacion-componentes/texto';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IClasificaciones from 'Infotrack@Modelos/smartStock/clasificaciones/entidades/clasificaciones';
import IFamilias from 'Infotrack@Modelos/smartStock/familias/entidades/familias';
import IFiltrosItem from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/filtrosItem';
import IItem from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/item';
import IUnidades from 'Infotrack@Modelos/smartStock/unidades/entidades/unidades';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import EntidadesFiltroCM from '../controladorModelo/entidadesFiltroCM';
import GestionProductoCM from '../controladorModelo/gestionProductoCM';
import { IInputsFiltrosItem, ITablaGestionProductoCVProps } from '../interfaces';
import { acciones, IEstadoGestionProducto } from '../reductorGestionProducto';
import TablaGestionProductoVista from '../vista/tablaGestionProductoVista';
import * as XLSX from 'xlsx';
import { ChangeEvent } from 'react';
import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import ModeloCargarPlantilla from 'Infotrack@Modelos/smartStock/procesarArchivo';
import TablaErrores from 'Infotrack@Transversales/componentes/CargaMasiva/TablaErrores/TablaErrores';
import IPlantilla from 'Infotrack@Modelos/smartStock/plantilla/entidades/IPlantilla';
import ModeloDescargarPlantilla from '../controladorModelo/plantillaServicioCM';

const entidadesFiltroCM = new EntidadesFiltroCM();
const gestionProductoCM = new GestionProductoCM();
const modeloCargarPlantilla = new ModeloCargarPlantilla();
const modeloDescargarPlantilla = new ModeloDescargarPlantilla();
const estadoInicialFiltro: IInputsFiltrosItem = {
    Clasificacion: null,
    CodigoBarras: '',
    Familia: null,
    Unidad: null,
    DescripcionItem: '',
};

const TablaGestionProductoCV: React.FunctionComponent<ITablaGestionProductoCVProps> = ({ IdEmpresa, IdAgencia }) => {
    const [{ recargarTabla }, dispatch] = useProveedor<IEstadoGestionProducto>();
    const { alCambiarValor, reiniciar, setValor, valor } = useInputState(estadoInicialFiltro);
    const [clasificaciones, setClasificaciones] = React.useState<IClasificaciones[]>([]);
    const [familias, setFamilias] = React.useState<IFamilias[]>([]);
    const [unidades, setUnidades] = React.useState<IUnidades[]>([]);
    const [items, setItems] = React.useState<IItem[]>([]);
    const [archivoProducto, setArchivoProducto] = React.useState<File>();
    const refTabla = React.createRef<any>();

    React.useEffect(() => {
        if (archivoProducto) CargarPlantillaVehiculo(archivoProducto);
    }, [archivoProducto]);

    React.useEffect(() => {
        cargarEntidadesFiltro();
    }, []);

    React.useEffect(() => {
        if (recargarTabla) {
            cargarProductos();
            dispatch({ type: acciones.RECARGAR_TABLA });
        }
    }, [recargarTabla]);

    const alAbrirFormulario = (item?: IItem) => {
        dispatch({
            type: acciones.CAMBIAR_ESTADO_FORMULARIO,
            payload: item ? { Item: item, Unidades: [], Propiedades: [] } : null,
        });
    };

    const mapearFiltros = (): IFiltrosItem => ({
        ClasificacionId: valor.Clasificacion ? Number(valor.Clasificacion.ClasificacionId) : undefined,
        CodigoBarras: valor.CodigoBarras,
        DescripcionItem: valor.DescripcionItem,
        EmpresaId: IdEmpresa.toUpperCase(),
        FamiliaId: valor.Familia ? Number(valor.Familia.FamiliaId) : undefined,
        UnidadId: valor.Unidad ? Number(valor.Unidad.UnidadId) : undefined,
    });

    const consultaProductos = paginacionRemota(
        gestionProductoCM.consultarListaProductoPrincipal,
        mapearFiltros(),
        'DescripcionItem'
    );

    const cargarProductos = async (limpiarFiltros?: boolean) => {
        if (limpiarFiltros) reiniciar();
        refTabla.current!.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
    };

    const confirmarEliminacionItem = (item: IItem) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => eliminarItem(item),
            estado: true,
            mensaje: <Texto id={'alerta.confirmacionEliminacion'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const alCambiarValorAutoComplete = (
        nuevoValor: IInputsFiltrosItem[keyof IInputsFiltrosItem],
        nombreValor: string
    ) => {
        setValor((valorAnterior) => ({ ...valorAnterior, [nombreValor]: nuevoValor }));
    };

    const eliminarItem = (item: IItem) => {
        gestionProductoCM.eliminarItem(item).then(() => {
            cargarProductos();
        });
        manejadorDialogoGlobal({ estado: false });
    };

    const cargarEntidadesFiltro = () => {
        entidadesFiltroCM
            .consultaEntidadesFiltro(IdEmpresa)
            .then(
                ({
                    clasificaciones: clasificacionesCargadas,
                    familias: familiasCargadas,
                    unidades: unidadesCargadas,
                }) => {
                    setClasificaciones(clasificacionesCargadas);
                    setFamilias(familiasCargadas);
                    setUnidades(unidadesCargadas);
                }
            );
    };

    const consultaProductosExportar = () => {
        return gestionProductoCM.consultarListaItemsCompuestosFiltro(mapearFiltros()).then((data) => {
            return data.Entidades;
        });
    };

    const handleCargarArchivo = (event: ChangeEvent<HTMLInputElement>) => {
        const archivo = event.target.files![0];
        setArchivoProducto(archivo);
        const lector = new FileReader();

        lector.onload = function(e) {
            const contenidoArchivo = e.target!.result;
            const libro = XLSX.read(contenidoArchivo, { type: 'array' });
            const hoja = libro.SheetNames[0];
            const datos = XLSX.utils.sheet_to_json(libro.Sheets[hoja]);
            const datosBinarios = XLSX.write(libro, { type: 'array', bookType: 'xlsx' });
            const archivoBinario = new Blob([datosBinarios], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
        };
        lector.readAsBinaryString(archivo);
    };

    const descargarArchivoExcel = async () => {
        const parametros: IPlantilla = {
            Extension: 'xlsx',
            NombreArchivo: 'Formatoproductos',
        };
        const respuesta = await modeloDescargarPlantilla.DescargarPlantilla(parametros);
        const blob = new Blob([respuesta], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const urlArchivo = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlArchivo;
        link.download = 'PlantillaProductos.xlsx';
        link.click();
        URL.revokeObjectURL(urlArchivo);
    };

    const CargarPlantillaVehiculo = async (archivo: File) => {
        const formData = new FormData();
        formData.append('Archivo', archivo, archivo.name);
        formData.append(
            'Datos',
            JSON.stringify({
                IdEmpresa: IdEmpresa,
                IdAgencia: IdAgencia,
            })
        );

        const validacionesCargaProducto = (await modeloCargarPlantilla.CargarPlantillaProducto(formData)).data
            .Entidades;

        if (validacionesCargaProducto.length >= 1) {
            manejadorDialogoGlobal({
                estado: true,
                mostrarCancelar: false,
                accionConfirmar: () => manejadorDialogoGlobal({ estado: false }),
                mensaje: (
                    <div>
                        <TablaErrores listaErrores={validacionesCargaProducto} />
                    </div>
                ),
                tipoDialogo: 'Error',
                titulo: <Texto id="cargaMasiva.Errores" style={{ textAlign: 'center' }} />,
            });
        } else {
            dispatch({ type: acciones.RECARGAR_TABLA });
        }
    };
    return (
        <TablaGestionProductoVista
            alAbrirFormulario={alAbrirFormulario}
            alCambiarValor={alCambiarValor}
            alCambiarValorAutoComplete={alCambiarValorAutoComplete}
            alEliminarItem={confirmarEliminacionItem}
            consultaProductos={consultaProductos}
            consultaProductosExportar={consultaProductosExportar}
            entidadesFiltro={{ clasificaciones, familias, unidades, items }}
            filtros={valor}
            recargarTabla={cargarProductos}
            refTabla={refTabla}
            descargarPlantilla={descargarArchivoExcel}
            handleCargarArchivo={handleCargarArchivo}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion, agenciaActual },
}: IEstadoGlobal): ITablaGestionProductoCVProps => ({
    IdEmpresa: usuarioInformacion!.IdEmpresa,
    IdAgencia: agenciaActual!.IdAgencia,
});

export default connect<ITablaGestionProductoCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(TablaGestionProductoCV);
