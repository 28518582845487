const mensajes = {
    // ModalSeriales
    'ModalSeriales.InicioSerialesConsecutivos': 'Consecutive serial start',
    'ModalSeriales.ValidacionNoSerialesRepetidos': 'There can be no repeating serials',
    'ModalSeriales.SerialesIncompletos': 'The serial list is incomplete, are you sure to continue?',
    'ModalSeriales.Base': 'Base',
    'ModalSeriales.Peso': 'Weight',
    'ModalSeriales.ValidacionSerialesIncompletos':'One or more serials have not selected',
    // ModalSerialesEncabezado
    'ModalSerialesEncabezado.Titulo': 'Assign serials',
    // InputSerial
    'InputSerial.Serial': 'Serial',
    'InputSerial.ErrorSerialRepetido': 'Repeated serial',
};

export default mensajes;
