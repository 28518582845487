import Texto from '@infotrack/presentacion-componentes/texto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import { comprimirImagen } from 'Infotrack@Transversales/utilitarios/funcionesGenerales';
import React from 'react';
import { IImagen, IInputImagenCVProps } from '../interfaces';
import InputImagenVista from '../vista/inputImagenVista';

const InputImagenCV: React.FunctionComponent<IInputImagenCVProps> = ({
    alCargarImagen: alCargarImagenProp,
    alEliminarImagen: alEliminarImagenProp,
    estadoImagen,
    idTooltip,
    limiteImagenes,
    ocultarTitulo,
}) => {
    const [estado, setEstado] = React.useState<boolean>(false);
    const [estadoCarga, setEstadoCarga] = React.useState<boolean>(false);
    const [reiniciarGaleria, setReiniciarGaleria] = React.useState<boolean>(false);
    const [imagenes64, setImagenes64] = React.useState<IImagen[]>([]);
    const [indiceGaleria, setIndiceGaleria] = React.useState<number>(0);
    const [cantidadImagenesSubidas, setCantidadImagenesSubidas] = React.useState<number>(-1);

    React.useEffect(() => {
        if (imagenes64.length === cantidadImagenesSubidas) setEstadoCarga(false);
    }, [imagenes64]);

    React.useEffect(() => {
        if (estadoImagen) {
            if (estadoImagen.length === 0) setImagenes64([]);
            else {
                const imagenesACargar: IImagen[] = [];
                estadoImagen.forEach((imagen) => {
                    comprimirImagen(imagen.imagen as string).then((imgArray) => {
                        setImagenes64([
                            ...imagenesACargar,
                            { imagen: imgArray, propiedadesImagen: imagen.propiedadesImagen},
                        ]);
                        imagenesACargar.push({ imagen: imgArray, propiedadesImagen: imagen.propiedadesImagen});
                    });
                });
            }
        }
    }, [estadoImagen]);

    const alAbrirCerrarDialogo = () => {
        setEstado(!estado);
    };

    const alRecibirImagen = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
        if (files) {
            if (limiteImagenes && files.length > limiteImagenes) {
                notificacionGlobal('gestionproducto.alerta.limiteImagenes', 6000, 'warning', true);
                setEstadoCarga(false);
                return;
            }
            const imagenes: IImagen[] = [...imagenes64.map((imagen) => ({ ...imagen }))];
            setCantidadImagenesSubidas(files.length);
            setEstadoCarga(true);
            for (let indice = 0; indice < files.length; indice++) {
                const lector = new FileReader();
                const archivo = files.item(indice);
                if (!archivo!.type.includes('image')) {
                    notificacionGlobal('gestionproducto.alerta.archivoInvalido', 6000, 'warning', true);
                    setEstadoCarga(false);
                    return;
                }
                lector.readAsDataURL(archivo!);
                lector.onloadend = () => {
                    comprimirImagen(lector.result as string).then((arr64) => {
                        if (!imagenes.find(({ imagen }) => imagen === lector.result)) {
                            const nuevasImagenes = [...imagenes, { imagen: arr64, propiedadesImagen: archivo!}];
                            setImagenes64(nuevasImagenes);
                            alCargarImagenProp(nuevasImagenes);
                            imagenes.push({ imagen: arr64, propiedadesImagen: archivo!});
                        }
                    });
                };
            }
        }
    };

    const rotarImagen = (derecha: boolean) => {
        const canvas = document.createElement('canvas');
        const contexto = canvas.getContext('2d');
        const img = new Image();

        setReiniciarGaleria(true);

        img.src = imagenes64[indiceGaleria!]!.imagen as string;
        canvas.height = img.width;
        canvas.width = img.height;

        if (derecha) {
            contexto!.rotate((90 * Math.PI) / 180);
            contexto!.translate(0, -canvas.width);
        } else {
            contexto!.rotate((-90 * Math.PI) / 180);
            contexto!.translate(-canvas.height, 0);
        }
        contexto!.drawImage(img, 0, 0);
        const nuevaImagen = canvas.toDataURL('image/jpeg', 0.9);
        const nuevasImagenes = [...imagenes64];
        nuevasImagenes[indiceGaleria!].imagen = nuevaImagen;
        setImagenes64(nuevasImagenes);
        alCargarImagenProp(nuevasImagenes);
        setTimeout(() => {
            setReiniciarGaleria(false);
        }, 200);
    };

    const alPasarImagen = (nuevoIndiceImagen: number) => {
        setIndiceGaleria(nuevoIndiceImagen);
    };

    const confirmarEliminacionImagen = () => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: alEliminarImagen,
            estado: true,
            mensaje: <Texto id="gestionproducto.alerta.eliminarImagen" />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const alEliminarImagen = () => {
        manejadorDialogoGlobal({ estado: false });
        const imagenAEliminar = imagenes64[indiceGaleria];
        if (imagenAEliminar) {
            alEliminarImagenProp(imagenAEliminar.propiedadesImagen.name);
            setIndiceGaleria(0);
        }
    };

    return (
        <InputImagenVista
            alAbrirCerrarDialogo={alAbrirCerrarDialogo}
            alEliminarImagen={confirmarEliminacionImagen}
            alPasarImagen={alPasarImagen}
            alRecibirImagen={alRecibirImagen}
            estado={estado}
            estadoCarga={estadoCarga}
            idTooltip={idTooltip}
            imagenes={imagenes64}
            indiceGaleria={indiceGaleria}
            limiteImagenes={limiteImagenes}
            reiniciarGaleria={reiniciarGaleria}
            rotarImagen={rotarImagen}
            ocultarTitulo={ocultarTitulo}
        />
    );
};

export default InputImagenCV;
