import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IBodegas from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IDocumentoCompuestoMovimiento from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuestoMovimiento';
import IFiltrosDocumentos from 'Infotrack@Modelos/smartStock/documentos/entidades/filtrosDocumentosMovimientos';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';
import ITipoDocumento from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/tipoDocumento';
import DocumentoCM from 'Infotrack@Modulos/inventario/devolucionProveedor/controladorModelo/documentoCM';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import EntidadesFiltroDocumentoMovimientoCM from '../controladorModelo/entidadesFiltroDocumentoMovimientoCM';
import { IInputsFiltrosDocumentos, ITablaGestionMovimientosCVProps } from '../interfaces';
import { acciones, IEstadoGestionMovimientos } from '../reductorGestionMovimientos';
import TablaGestionMovimientosVista from '../vista/tablaGestionMovimientosVista';
import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import { estadoInactivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';


const estadoInicialFiltro: IInputsFiltrosDocumentos = {
    CodigoDocumento: '',
    TipoDocumentoId: '1024',
    TipoMovimientoDescripcion: '',
    BodegaDescripcion: '',
    FechaCreacion: null,
    FechaVencimiento: null,
    Bodega: null,
    Etapa: null,
};
const entidadesFiltroDocumentoMovimientoCM = new EntidadesFiltroDocumentoMovimientoCM();
const documentoCM = new DocumentoCM();
const TablaGestionMovimientosCV: React.FunctionComponent<ITablaGestionMovimientosCVProps> = ({
    IdEmpresa,
    IdAgencia,
    IdUsuario,
}) => {
    const [{ recargarTabla }, dispatch] = useProveedor<IEstadoGestionMovimientos>();
    const { alCambiarValor, reiniciar, setValor, valor } = useInputState(estadoInicialFiltro);
    const [tipoDocumento, setTipoDocumento] = React.useState<ITipoDocumento[]>([]);
    const [etapas, setEtapas] = React.useState<IEtapas[]>([]);
    const [bodegas, setBodegas] = React.useState<IBodegas[]>([]);

    const refTabla = React.createRef<any>();

    React.useEffect(() => {
        cargarEntidadesFiltro();
    }, []);

    React.useEffect(() => {
        if (recargarTabla && valor.Bodega) {
            cargarDocumentos();
            dispatch({ type: acciones.RECARGAR_TABLA });
        }
    }, [recargarTabla]);

    const cargarDocumentos = async (limpiarFiltros?: boolean) => {
        if (limpiarFiltros) reiniciar();
        else {
            if (
                documentoCM.validarFiltros({
                    ...valor,
                    BodegaConsulta: valor.Bodega ? valor.Bodega.BodegaId : undefined,
                } as any)
            )
                refTabla.current.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
        }
    };

    const mapearFiltros = (): IFiltrosDocumentos => ({
        UsuarioId: IdUsuario,
        CodigoDocumento: valor.CodigoDocumento ? valor.CodigoDocumento : null,
        EmpresaId: IdEmpresa,
        TipoDocumentoId: valor.TipoDocumentoId ? Number(valor.TipoDocumentoId) : null,
        BodegaConsulta: valor.Bodega ? Number(valor.Bodega.BodegaId) : null,
        EtapaId: valor.Etapa ? Number(valor.Etapa.EtapaId) : null,
        FechaInicial: valor.FechaCreacion ? valor.FechaCreacion : null,
        FechaFinal: valor.FechaVencimiento ? valor.FechaVencimiento : null,
    });

    const consultaDocumentos = paginacionRemota(
        documentoCM.consultarDocumentos,
        mapearFiltros(),
        'FechaCreacion',
        !Boolean(valor.Bodega)
    );

    const cargarEntidadesFiltro = () => {
        entidadesFiltroDocumentoMovimientoCM
            .consultarListaEntidadesFiltros({ AgenciaId: IdAgencia, UsuarioId: IdUsuario, EmpresaId: IdEmpresa,Estado:estadoInactivo })
            .then(({ tipoDocumentos: tipoDocumentosCargados, etapas: etapasCargadas, bodegas: bodegasCargadas }) => {
                setTipoDocumento(tipoDocumentosCargados);
                setEtapas(etapasCargadas);
                setBodegas(bodegasCargadas);
            });
    };

    const confirmarConsultaDocumentos = () => {
        if (documentoCM.validarFiltros(mapearFiltros())) {
            cargarDocumentos();
            dispatch({ type: acciones.RECARGAR_TABLA });
        }
    };

    const alAbrirFormulario = (documento?: IDocumentoCompuestoMovimiento) => {
        if (documento) dispatch({ type: acciones.ESTABLECER_ENTIDAD_DOCUMENTO, payload: documento });
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
    };
    const alAnularDocumento = (documento?: IDocumentoCompuestoMovimiento) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => {
                documentoCM.anularDevolucionProveedor({ ...documento } as any).then(() => {
                    manejadorDialogoGlobal({ estado: false });
                    dispatch({ type: acciones.RECARGAR_TABLA });
                });
            },
            estado: true,
            mensaje: <Texto id="alerta.confirmacionAnulación" />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const alCambiarValorAutocomplete = (
        nombreValor: string,
        nuevoValor: IInputsFiltrosDocumentos[keyof IInputsFiltrosDocumentos]
    ) => {
        setValor({ ...valor, [nombreValor]: nuevoValor });
    };

    return (
        <TablaGestionMovimientosVista
            alAbrirFormulario={alAbrirFormulario}
            alAnularDocumento={alAnularDocumento}
            alCambiarValor={alCambiarValor}
            alCambiarValorAutocomplete={alCambiarValorAutocomplete}
            accionValidacion={confirmarConsultaDocumentos}
            consultaDocumentos={consultaDocumentos}
            filtros={valor}
            entidadesFiltro={{ tipoDocumento, etapas, bodegas }}
            recargarTabla={cargarDocumentos}
            refTabla={refTabla}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion, agenciaActual },
}: IEstadoGlobal): ITablaGestionMovimientosCVProps => ({
    IdEmpresa: usuarioInformacion!.IdEmpresa,
    IdAgencia: agenciaActual!.IdAgencia,
    IdUsuario: usuarioInformacion!.IdUsuario,
});

export default connect<ITablaGestionMovimientosCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(TablaGestionMovimientosCV);
