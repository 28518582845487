import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import ModeloEntradaInventario from 'Infotrack@Modelos/negocioRefactor/modelos/EntradaInventario/ModeloEntradaInventario';
import IDocumentoBaseConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoBaseConsulta';

class EntradaInventarioCM {
    private modeloEntradaInventario: ModeloEntradaInventario;

    constructor() {
        this.modeloEntradaInventario = new ModeloEntradaInventario();
    }

    public async editarDetalle(detalle: IDocumentoDetalle) {
        if (!this.validarDetalle(detalle)) return false;
        const respuesta = await this.modeloEntradaInventario.editarDetalle(detalle);
        return respuesta.data.Resultado;
    }

    public async eliminarDetalle(detalle: IDocumentoDetalle) {
        const respuesta = await this.modeloEntradaInventario.eliminarDetalle(detalle);
        return respuesta.data.Resultado;
    }

    public async guardarDetalle(detalles: IDocumentoDetalle[]) {
        if (detalles.some((detalle) => !this.validarDetalle(detalle))) return false;
        const respuesta = await this.modeloEntradaInventario.guardarDetalle(detalles);
        return respuesta.data.Resultado;
    }

    public async guardarEncabezado(documento: IDocumento) {
        if (!this.validarDocumento(documento)) return { Entidades: [], Resultado: false };
        const respuesta = await this.modeloEntradaInventario.guardarEncabezado(documento);
        return respuesta.data;
    }

    public async editarEncabezado(documento: IDocumento) {
        if (!this.validarDocumento(documento)) return { Entidades: [], Resultado: false };
        const respuesta = await this.modeloEntradaInventario.editarEncabezado(documento);
        return respuesta.data;
    }

    public async anularDocumento(documentoId: string) {
        const respuesta = await this.modeloEntradaInventario.anularDocumento(documentoId);
        return respuesta.data.Resultado;
    }

    public consultarListaDocumentos = async (paginador: IPaginador, filtro: IDocumentoFiltro) => {
        const respuesta = await this.modeloEntradaInventario.consultarListaDocumentos({
            Filtro: filtro,
            Paginador: paginador,
        });
        return respuesta.data;
    };

    public async consultarDetallesDocumento(filtro: Partial<IDocumentoDetalle>) {
        const respuesta = await this.modeloEntradaInventario.consultarDetallesDocumento(filtro);
        return respuesta.data.Entidades;
    }

    public async consultarEncabezadoDocumento(filtro: Partial<IDocumento>) {
        const respuesta = await this.modeloEntradaInventario.consultarEncabezadoDocumento(filtro);
        return respuesta.data.Entidades[0];
    }

    public async consultarEtapas(documentoId: string) {
        const respuesta = await this.modeloEntradaInventario.consultarEtapas(documentoId);
        return respuesta.data.Entidades;
    }

    public async cambiarEtapa(documento: IDocumento) {
        const respuesta = await this.modeloEntradaInventario.cambiarEtapa(documento);
        return respuesta.data.Resultado;
    }

    public async consultarDocumentoBase(documentoBase: IDocumentoBaseConsulta) {
        const respuesta = await this.modeloEntradaInventario.consultarDocumentoBase(documentoBase);
        return respuesta.data.Entidades[0];
    }

    public async generarArchivoPDF(documentoId: string, TipoDocumentoId: number) {
        const respuesta = await this.modeloEntradaInventario.generarArchivoPDF(documentoId, TipoDocumentoId);
        return respuesta.data as string;
    }

    private validarDocumento(documento: IDocumento) {
        if (!documento.TipoDocumentoId) {
            notificacionGlobal('EntradaInventario.ValidacionNoTipoDocumentoId', 3000, 'warning', true);
            return false;
        }
        if (!documento.ProveedorId) {
            notificacionGlobal('EntradaInventario.ValidacionNoProveedorId', 3000, 'warning', true);
            return false;
        }
        if (!documento.BodegaDestino) {
            notificacionGlobal('EntradaInventario.ValidacionNoBodegaDestino', 3000, 'warning', true);
            return false;
        }
        return true;
    }

    private validarDetalle(detalle: IDocumentoDetalle) {
        if (!detalle.DivisionDestino) {
            notificacionGlobal('ModalRecepcion.ValidacionNoDivisionDestino', 3000, 'warning', true);
            return false;
        }
        const PesoFraccion = Number(detalle.PesoFraccion);
        if (PesoFraccion === 0) {
            notificacionGlobal('EntradaInventario.ValidacionPesoFraccionCero', 3000, 'warning', true);
            return false;
        }
        const cantidadRecibida = Number(detalle.CantidadRecibida);
        if (cantidadRecibida === 0) {
            notificacionGlobal('EntradaInventario.ValidacionCantidadRecibidaCero', 3000, 'warning', true);
            return false;
        }
        if (detalle.CantidadSolicitada! > 0 && detalle.CantidadRecibida! > detalle.CantidadSolicitada!) {
            notificacionGlobal('TablaDetallesPlantilla.ValidacionCantidadMayorASolicitada', 3000, 'warning', true);
            return false;
        }
        return true;
    }
}

export default EntradaInventarioCM;
