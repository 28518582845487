import React, { useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    LinearProgress,
    Toolbar,
    Tooltip,
    withStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CollectionsIcon from '@material-ui/icons/Collections';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Texto from '@infotrack/presentacion-componentes/texto';

import { BotonEliminar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';

import { IInputImagenVistaProps } from '../interfaces';
import { estilos } from './estilos';

const InputImagenVista: React.FunctionComponent<PropsEstilosMaterialUI<IInputImagenVistaProps & InjectedIntlProps>> = ({
    alAbrirCerrarDialogo,
    alEliminarImagen,
    alPasarImagen,
    alRecibirImagen,
    classes,
    estado,
    estadoCarga,
    idTooltip,
    imagenes,
    intl: { formatMessage },
    indiceGaleria,
    limiteImagenes,
    reiniciarGaleria,
    rotarImagen,
    ocultarTitulo,
}) => {
    const textoBoton =
        limiteImagenes === 1
            ? formatMessage({
                id: 'label.inputImagen.limiteImagen',
            })
            : formatMessage({
                id: 'label.inputImagen.limiteImagenes',
            });

    const [indice, setIndice] = useState(indiceGaleria);

    return (
        <>
            {imagenes && imagenes.length > 0 && !estadoCarga ? (
                <Grid alignItems="center" container justifyContent="center">
                    <Grid item xs={10}>
                        <Button
                            className={classes.botones}
                            color="primary"
                            fullWidth
                            endIcon={<CollectionsIcon />}
                            onClick={alAbrirCerrarDialogo}
                            variant="contained"
                        >
                            <Texto mensaje={textoBoton} />
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <>
                    {
                        <Tooltip
                            disableFocusListener={!idTooltip}
                            disableHoverListener={!idTooltip}
                            disableTouchListener={!idTooltip}
                            title={idTooltip ? formatMessage({ id: idTooltip }) : ''}
                        >
                            <Button
                                className={classes.botones}
                                component="label"
                                color="primary"
                                disabled={estadoCarga}
                                fullWidth
                                variant="contained"
                            >
                                <Texto className={classes.margenIcono} mensaje={textoBoton} />
                                <AddAPhotoIcon />
                                <input
                                    accept="image/*"
                                    className={classes.inputFalso}
                                    onChange={alRecibirImagen}
                                    type="file"
                                    multiple
                                />
                            </Button>
                        </Tooltip>
                    }
                    {limiteImagenes && (
                        <Texto
                            fontWeight="bold"
                            mensaje={`*${formatMessage({
                                id: 'label.inputImagen.maximo',
                            })} ${limiteImagenes} ${textoBoton}`}
                            variant="caption"
                        />
                    )}
                    {estadoCarga && <LinearProgress />}
                </>
            )}
            <Dialog open={estado} fullWidth maxWidth="md">
                <AppBar position="static">
                    <Toolbar>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Texto align="center" mensaje="Visualización" />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Card>
                        <CardContent>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item xs="auto">
                                    <Tooltip
                                        disableFocusListener={!idTooltip}
                                        disableHoverListener={!idTooltip}
                                        disableTouchListener={!idTooltip}
                                        title={idTooltip ? formatMessage({ id: idTooltip }) : ''}
                                    >
                                        <Button
                                            color="primary"
                                            component="label"
                                            disabled={limiteImagenes === 1 && imagenes.length === 1}
                                            variant="contained"
                                        >
                                            <Texto className={classes.margenIcono} id="boton.agregar" />
                                            <AddIcon />
                                            <input
                                                accept="image/*"
                                                className={classes.inputFalso}
                                                onChange={(e) => {
                                                    alRecibirImagen(e)
                                                    e.target.value = ''

                                                }}
                                                type="file"
                                                multiple
                                            />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs="auto">
                                    <BotonEliminar
                                        disabled={imagenes.length === 0}
                                        onClick={() => alEliminarImagen()}
                                    />
                                </Grid>
                                {imagenes && imagenes.length > 0 ? (
                                    <Grid item xs={12}>
                                        {!reiniciarGaleria && (
                                            <Card>
                                                <CardContent>
                                                    <Carousel
                                                        className={classes.slideShow}
                                                        showArrows={true}
                                                        showThumbs={true}
                                                        showStatus={false}
                                                        useKeyboardArrows
                                                        infiniteLoop
                                                        onChange={(i) => alPasarImagen(i)}
                                                    >
                                                        {imagenes.map(({ imagen, propiedadesImagen }) => {
                                                            return (
                                                                <div>
                                                                    <img
                                                                        src={imagen ? (imagen as string) : undefined}
                                                                        alt={propiedadesImagen.name}
                                                                    />
                                                                    <figcaption>{propiedadesImagen.name}</figcaption>
                                                                </div>
                                                            );
                                                        })}
                                                    </Carousel>
                                                </CardContent>
                                            </Card>
                                        )}
                                    </Grid>
                                ) : (
                                    <Grid item xs={12}>
                                        <Texto align="center" id="gestionproducto.productoSinImagenes" variant="h6" />
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" spacing={2}>
                        { <Grid item>
                            <Tooltip title={<Texto id="gestionproducto.rotarIzquierda" />}>
                                <IconButton onClick={() => rotarImagen(false)}>
                                    <RotateLeftIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={<Texto id="gestionproducto.rotarDerecha" />}>
                                <IconButton onClick={() => rotarImagen(true)}>
                                    <RotateRightIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid> }
                        <Grid item>
                            <Button color="primary" onClick={alAbrirCerrarDialogo} variant="contained">
                                <Texto id="boton.cerrar" />
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withStyles(estilos)(injectIntl(InputImagenVista));
