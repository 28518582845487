import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

interface ICampoCodigoDocumentoProps {
    deshabilitado: boolean;
    valor: string;
}

const CampoCodigoDocumento: FunctionComponent<ICampoCodigoDocumentoProps> = ({ deshabilitado, valor }) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="Auditoria.CampoCodigoDocumento.Nombre" />}
        value={valor}
    />
);

export default CampoCodigoDocumento;
