import ModeloColumnas from 'Infotrack@Modelos/smartStock/columnas';
import ModeloDivisionesTiposPropiedades from 'Infotrack@Modelos/smartStock/divisionesTiposPropiedades';
import ModeloFamilia from 'Infotrack@Modelos/smartStock/familias';
import ModeloFilas from 'Infotrack@Modelos/smartStock/filas';
import ModeloItem from 'Infotrack@Modelos/smartStock/gestionProducto';
import IProductoPrincipal from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/productoPrincipal';
import ModeloPisos from 'Infotrack@Modelos/smartStock/pisos';
import ModeloTiposDivisiones from 'Infotrack@Modelos/smartStock/tiposDivisiones';
import ModeloUnidades from 'Infotrack@Modelos/smartStock/unidades';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';

export default class EntidadesBodegaDivisionCM {
  private modeloColumnas: ModeloColumnas;
  private modeloDivisionesTiposPropiedades: ModeloDivisionesTiposPropiedades;
  private modeloFamilia: ModeloFamilia;
  private modeloFilas: ModeloFilas;
  private modeloItem: ModeloItem;
  private modeloPisos: ModeloPisos;
  private modeloTiposDivisiones: ModeloTiposDivisiones;
  private modeloUnidades: ModeloUnidades;

  constructor() {
    this.modeloColumnas = new ModeloColumnas();
    this.modeloDivisionesTiposPropiedades = new ModeloDivisionesTiposPropiedades();
    this.modeloFamilia = new ModeloFamilia();
    this.modeloFilas = new ModeloFilas();
    this.modeloItem = new ModeloItem();
    this.modeloPisos = new ModeloPisos();
    this.modeloTiposDivisiones = new ModeloTiposDivisiones();
    this.modeloUnidades = new ModeloUnidades();
  }

  public consultarEntidadesBodegaDivision(EmpresaId: string,estadoFormularioCreacion:boolean) {
        return Promise.all([
      this.modeloColumnas.consultarListaColumnasPorFiltro({ EmpresaId , Estado:estadoActivo}),
      this.modeloFilas.consultarListaFilasPorFiltro({ EmpresaId,Estado:estadoActivo }),
      this.modeloPisos.consultarListaPisosPorFiltro({ EmpresaId, Estado:estadoActivo }),
      this.modeloTiposDivisiones.consultarListaTiposDivisionesPorFiltro({ EmpresaId,Estado:estadoActivo }),
    ]).then((entidades) => ({
      columnas: entidades[0].data.Entidades,
      filas: entidades[1].data.Entidades,
      pisos: entidades[2].data.Entidades,  
      tiposDivisiones: entidades[3].data.Entidades,
    }));
  }

  public consultarEntidadesPropiedadesDivision(EmpresaId: string) {
    return Promise.all([
      this.modeloDivisionesTiposPropiedades.consultarDivisionesTiposPropiedades(),
      this.modeloFamilia.consultarListaFamiliasPorFiltro({ EmpresaId }),
      this.modeloItem.consultarListaItemsPorFiltro({ EmpresaId }),
      this.modeloUnidades.consultarUnidadesFiltro({ EmpresaId, Estado: 1 }),
    ]).then((entidades) => ({
      divisionesTiposProps: entidades[0].data.Entidades,
      familias: entidades[1].data.Entidades,
      productos: entidades[2].data.Entidades as IProductoPrincipal[],
      unidades: entidades[3].data.Entidades,
    }));
  }
}
