export default {
  'sidebar.reversion': 'Reversión movements',
  'permiso.reversion': 'Access',
  'reversion.numeroDocumento': 'N° Document',
  'reversion.revertirDocumento': 'Reverse',
  'reversion.CodigoDocumento': 'Document code',
  'reversion.DescripcionBodegaOrigen': 'Origin winery',
  'reversion.DescripcionBodegaDestino': 'Destination winery',
  'reversion.Bodega': 'Warehouse',
  'reversion.TipoDocumentoDescripcion': 'Document type',
  'reversion.EtapaDescripcion': 'Stage description',
  'reversion.FechaCreacion': 'Creation date',
  'reversion.alerta.confirmarReversion': 'Are you sure you want to reverse the document?',
};
