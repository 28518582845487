import React from 'react';
import { IBarraNavegacionProps } from './interfaces';
import BarraNavegacionVista from './vista/barraNavegacionVista';

const BarraNavegacion: React.FunctionComponent<IBarraNavegacionProps> = (props) => (
  <BarraNavegacionVista {...props} />
);

export { IBarraNavegacionProps };

export default BarraNavegacion;
