export default {
    'sidebar.crearrecalada': 'Gestión Compras',
    'sidebar.compras': 'Compras',
    'sidebar.gestioncompras': 'Gestión Compras',
    'boton.desvincular': 'Desvincular',
    'crearrecalada.NumeroDocumento': 'N° Documento',
    'crearrecalada.TipoDocumento': 'Tipo documento',
    'crearrecalada.FechaCreacionDocumento': 'Fecha inicial',
    'crearrecalada.FechaVencimientoDocumento': 'Fecha final',
    'crearrecalada.PersonaCreacion': 'Personas asociadas',
    'crearrecalada.Bodega': 'Bodegas',
    'crearrecalada.BodegaOrigenDescripcion': 'Bodega origen',
    'crearrecalada.BodegaDestinoDescripcion': 'Bodega destino',
    'crearrecalada.EstadoDocumento': 'Estado documento',
    'crearrecalada.TipoMovimiento': 'Tipo movimiento',
    'crearrecalada.DocumentoRelacionado': 'Documento Relacionado',
    'crearrecalada.FechaCreacion': 'Fecha Creación',
    'crearrecalada.Accion': 'Acción',
    'crearrecalada.documento.TipoDocumentoId': 'Tipo documento',
    'crearrecalada.documento.ProveedorId': 'Proveedor',
    'crearrecalada.documento.BodegaOrigen': 'Bodega origen',
    'crearrecalada.documento.BodegaDestino': 'Bodega destino',
    'crearrecalada.documento.DocumentoRelacionado': 'Documento relacionado',
    'crearrecalada.documento.Observacion': 'Observaciones',
    'crearrecalada.documento.DocumentoPadre': '¿Es documento padre?',
    'crearrecalada.documento.DocumentoPadreId': 'Documento padre',
    'crearrecalada.documento.crearDocumento': 'Crear documento',
    'crearrecalada.documento.visualizarDocumento': 'Visualizar documento',
    'crearrecalada.documento.datosDocumento': 'Datos documento',
    'crearrecalada.documentoDetalle.crearDetalleDocumento': 'Crear detalle documento',
    'crearrecalada.documentoDetalle.editarDetalleDocumento': 'Editar detalle documento',
    'crearrecalada.documentoDetalle.documentoDetalles': 'Detalles del documento',
    'crearrecalada.documentoDetalle.DescripcionItem': 'Descripción producto',
    'crearrecalada.documentoDetalle.CantidadSolicitada': 'Cantidad',
    'crearrecalada.documentoDetalle.CodigoBarras': 'Código de barras',
    'crearrecalada.documentoDetalle.DivisionOrigen': 'División origen',
    'crearrecalada.documentoDetalle.DivisionDestino': 'División destino',
    'crearrecalada.documentoDetalle.Lote': 'Lote',
    'crearrecalada.documentoDetalle.Serial': 'Serial',
    'crearrecalada.documentoDetalle.FechaVencimiento': 'Fecha de vencimiento',
    'crearrecalada.fechaInvalida': 'La fecha final debe ser mayor a la fecha inicial',
    'crearrecalada.fechaIncompleta': 'Ingrese las dos fechas para poder realizar la búsqueda',
    'crearrecalada.documentoDetalle.ValorUnitarioItem': 'Valor unitario ítem',
    'crearrecalada.documentoDetalle.Total': 'Total',
    'crearrecalada.documentoDetalle.totalSuma': 'Total $:',
    'crearrecalada.alerta.alCrearDetalle': 'Por favor ingrese los campos requeridos en el documento (*)',
    'crearrecalada.alerta.agregarDetalles': 'Los documentos no padres deben contener al menos un detalle',
    'crearrecalada.alerta.confirmacionReinicioDocumentoDetalle':
        'Si modifica este campo se eliminaran los detalles del documento. ¿Desea continuar?',
    'crearrecalada.alerta.agregarDetallesAnulado':
        'No es posible modificar la información del documento en la etapa actual, por favor verifique.',
    'crearrecalada.alerta.agregarDetallesCerrado':
        'No es posible modificar información de un documento cerrado, por favor verifique.',

    'CrearRecaladas.VerDetalle': 'Ver detalle',

    // FormularioDetalleCompras
    'FormularioDetalleCompras.Titulo': 'Agregar detalle',
    'FormularioDetalleCompras.CantidadSolicitada': 'Cantidad',
    'FormularioDetalleCompras.ValidacionNoItem': 'Debe seleccionar un ítem',
    'FormularioDetalleCompras.ValidacionNoCantidad': 'La cantidad debe ser mayor a 0',
};
