import { createStyles, Theme } from '@material-ui/core';
import { paletaMaterialD } from '@infotrack/presentacion-transversales/constantes';

export const estilosGenerales = (tema: Theme) =>
    createStyles({
        encabezadosFormulario: {
            backgroundColor: tema.palette.primary.main,
            color: paletaMaterialD.white,
            padding: tema.spacing(1),
            marginBottom: tema.spacing(1),
        },
    });
