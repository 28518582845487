import { Button, Card, CardContent, Grid, MenuItem, TextField } from '@material-ui/core';
import { Edit as EditIcon, Save as SaveIcon, PictureAsPdf as PdfIcon } from '@material-ui/icons';
import React, { FunctionComponent, useEffect, useState } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IClientes from 'Infotrack@Modelos/smartStock/clientes/entidades/clientes';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';
import IProveedores from 'Infotrack@Modelos/smartStock/proveedores/entidades/proveedores';

import CampoBodegaDestino from './Campos/CampoBodegaDestino';
import CampoBodegaOrigen from './Campos/CampoBodegaOrigen';
import CampoClienteId from './Campos/CampoClienteId';
import CampoCodigoDocumento from './Campos/CampoCodigoDocumento';
import CampoCodigoDocumentoBase from './Campos/CampoCodigoDocumentoBase';
import CampoCondicionComercial from './Campos/CampoCondicionComercial';
import CampoDireccionEntrega from './Campos/CampoDireccionEntrega';
import CampoDocumentoRelacionado from './Campos/CampoDocumentoRelacionado';
import CampoEtapaId from './Campos/CampoEtapaId';
import CampoFechaEntrega from './Campos/CampoFechaEntrega';
import CampoObservacion from './Campos/CampoObservacion';
import CampoProveedorId from './Campos/CampoProveedorId';
import CampoTipoDocumentoId from './Campos/CampoTipoDocumentoId';
import InputImagenDocumento from 'Infotrack@Transversales/componentes/inputImagenDocumento';
import { EstadosFormularioDocumento } from './enumeraciones';
import IDocumentoEvidencia from 'Infotrack@Modelos/smartStock/documentoEvidencia/entidades/documentoEvidencia';

interface IEntidadesFormularioDocumento {
    bodegasDestino?: IBodega[];
    bodegasOrigen?: IBodega[];
    clientes?: IClientes[];
    etapas: IEtapas[];
    proveedores?: IProveedores[];
    tiposDocumento: ITipoDocumento[];
}

interface IFormularioDocumentoProps {
    cambiarEtapa: (etapaId: number) => void;
    camposDeshabilitar?: Array<keyof IDocumentoConsultaGeneral>;
    camposOcultar?: Array<keyof IDocumentoConsultaGeneral>;
    documento: IDocumentoConsultaGeneral | null;
    editarDocumento: (documento: IDocumento) => void;
    entidadesDocumento: Partial<IEntidadesFormularioDocumento>;
    estado?: EstadosFormularioDocumento;
    etapasSiguientes: IEtapas[];
    guardarDocumento: (documento: IDocumento) => void;
    descargarDocumentoPdf: (documento: IDocumentoConsultaGeneral) => any;
    consultarTipoDocumento: (idBodega: number, nombreNodega?: string) => Promise<void>;
    AgregarEvidencia?: (documentoEvidencia: IDocumentoEvidencia) => void;
    mostrarModalEvidencia: boolean;
    esTraslado:boolean;
}

const DOCUMENTO_POR_DEFECTO: IDocumentoConsultaGeneral = {
    CodigoDocumento: '',
    CodigoDocumentoPredecesor: '',
    CondicionComercial: '',
    DescipcionProveedorId: '',
    DescripcionBodegaDestino: '',
    DescripcionBodegaOrigen: '',
    DescripcionClienteId: '',
    DescripcionEstado: '',
    DescripcionEtapaId: '',
    DescripcionTipoDocumentoId: '',
    DireccionEntrega: '',
    DocumentoId: '',
    DocumentoRelacionado: '',
    Estado: 0,
    EtapaId: 0,
    FechaCreacion: '',
    FlujoId: 0,
    ListaAcciones: {
        AccionEtapaDocumentoId: 0,
        AfectaInventario: false,
        Estado: 0,
        EtapaId: 0,
        PermiteAgregarDetalle: true,
        PermiteAnularDocumento: true,
        PermiteCambiarEtapa: true,
        PermiteEditarDetalle: true,
        PermiteEditarDocumento: true,
        PermiteEliminarDetalle: true,
        AccionMovil: false,
        EtapaMovil: false,
        TipoDocumentoId: 0,
    },
    Observacion: '',
    PersonaRelacionada: '',
    SincronizacionMovil: false,
    TipoDocumentoId: 0,
    PlantillaImpresionId: 0,
};

const FormularioDocumento: FunctionComponent<IFormularioDocumentoProps> = ({
    cambiarEtapa,
    camposDeshabilitar = [],
    camposOcultar = [],
    documento: documentoProp,
    editarDocumento,
    entidadesDocumento,
    estado = EstadosFormularioDocumento.VISUALIZACION,
    etapasSiguientes,
    guardarDocumento,
    descargarDocumentoPdf,
    consultarTipoDocumento,
    mostrarModalEvidencia,
    esTraslado
}) => {
    const [documento, setDocumento] = useState<IDocumentoConsultaGeneral>(DOCUMENTO_POR_DEFECTO);
    useEffect(() => {
        if (documentoProp) setDocumento(documentoProp);
    }, [documentoProp]);
    const manejarCambioCampo = async (
        nombreCampo: keyof IDocumentoConsultaGeneral,
        nuevoValor: IDocumentoConsultaGeneral[keyof IDocumentoConsultaGeneral]
    ) => {
        setDocumento((documentoActual) => ({ ...documentoActual, [nombreCampo]: nuevoValor }));
        if (nombreCampo === 'BodegaOrigen' || nombreCampo === 'BodegaDestino')
            consultarTipoDocumento(nuevoValor as number, nombreCampo);
    };

    const deshabilitado =
        (estado === EstadosFormularioDocumento.EDICION && !documento.ListaAcciones.PermiteEditarDocumento) ||
        estado === EstadosFormularioDocumento.VISUALIZACION;

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid alignItems="center" container spacing={2}>
                    <Grid item xs={12}>
                        <Texto id="FormularioDocumento.Titulo" fontWeight="bold" />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid alignItems="center" container spacing={1}>
                            {!camposOcultar.includes('BodegaOrigen') && (
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <CampoBodegaOrigen
                                        bodegas={entidadesDocumento.bodegasOrigen || []}
                                        deshabilitado={deshabilitado || camposDeshabilitar.includes('BodegaOrigen')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        valor={documento.BodegaOrigen}
                                    />
                                </Grid>
                            )}

                            {!camposOcultar.includes('BodegaDestino') && (
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <CampoBodegaDestino
                                        bodegas={entidadesDocumento.bodegasDestino || []}
                                        deshabilitado={deshabilitado || camposDeshabilitar.includes('BodegaDestino')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        valor={documento.BodegaDestino}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('TipoDocumentoId') && (
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <CampoTipoDocumentoId
                                        deshabilitado={deshabilitado || camposDeshabilitar.includes('TipoDocumentoId')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        tiposDocumento={entidadesDocumento.tiposDocumento || []}
                                        valor={documento.TipoDocumentoId}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('EtapaId') && documento.DocumentoId && (
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <CampoEtapaId
                                        deshabilitado={deshabilitado || camposDeshabilitar.includes('EtapaId')}
                                        etapas={entidadesDocumento.etapas || []}
                                        manejarCambioCampo={manejarCambioCampo}
                                        valor={documento.EtapaId}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('ProveedorId') && (
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <CampoProveedorId
                                        deshabilitado={deshabilitado || camposDeshabilitar.includes('ProveedorId')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        proveedores={entidadesDocumento.proveedores || []}
                                        valor={documento.ProveedorId}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('CodigoDocumentoPredecesor') && (
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <CampoCodigoDocumentoBase
                                        deshabilitado={
                                            deshabilitado || camposDeshabilitar.includes('CodigoDocumentoPredecesor')
                                        }
                                        manejarCambioCampo={manejarCambioCampo}
                                        valor={documento.CodigoDocumentoPredecesor}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('CodigoDocumento') && documento.DocumentoId && (
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <CampoCodigoDocumento
                                        deshabilitado={deshabilitado || camposDeshabilitar.includes('CodigoDocumento')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        valor={documento.CodigoDocumento}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('DireccionEntrega') && (
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <CampoDireccionEntrega
                                        deshabilitado={deshabilitado || camposDeshabilitar.includes('DireccionEntrega')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        valor={documento.DireccionEntrega}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('FechaEntrega') && (
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <CampoFechaEntrega
                                        deshabilitado={deshabilitado || camposDeshabilitar.includes('FechaEntrega')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        valor={documento.FechaEntrega}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('CondicionComercial') && (
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <CampoCondicionComercial
                                        deshabilitado={
                                            deshabilitado || camposDeshabilitar.includes('CondicionComercial')
                                        }
                                        manejarCambioCampo={manejarCambioCampo}
                                        valor={documento.CondicionComercial}
                                    />
                                </Grid>
                            )}

                            {!camposOcultar.includes('DocumentoRelacionado') && (
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <CampoDocumentoRelacionado
                                        deshabilitado={
                                            deshabilitado || camposDeshabilitar.includes('DocumentoRelacionado')
                                        }
                                        manejarCambioCampo={manejarCambioCampo}
                                        valor={documento.DocumentoRelacionado}
                                        esTraslado={esTraslado}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('Observacion') && (
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <CampoObservacion
                                        deshabilitado={deshabilitado || camposDeshabilitar.includes('Observacion')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        valor={documento.Observacion}
                                        esTraslado={esTraslado}
                                    />
                                </Grid>
                            )}
                            {!camposOcultar.includes('ClienteId') && (
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <CampoClienteId
                                        clientes={entidadesDocumento.clientes || []}
                                        deshabilitado={deshabilitado || camposDeshabilitar.includes('ClienteId')}
                                        manejarCambioCampo={manejarCambioCampo}
                                        valor={documento.ClienteId}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {estado === EstadosFormularioDocumento.CREACION && (
                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Button
                                        color="primary"
                                        endIcon={<SaveIcon />}
                                        onClick={() => guardarDocumento(documento)}
                                        size="small"
                                        variant="contained"
                                    >
                                        <Texto id="boton.guardar" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <>
                        {etapasSiguientes.length > 0 && estado === EstadosFormularioDocumento.EDICION && (
                            <Grid item lg={2} md={3} sm={6} xs={12}>
                                <TextField
                                    disabled={!documento.ListaAcciones.PermiteCambiarEtapa}
                                    fullWidth
                                    label={<Texto id="FormularioDocumento.CambiarEtapa" />}
                                    onChange={(event) => cambiarEtapa(Number(event.target.value))}
                                    select
                                    value=""
                                >
                                    {etapasSiguientes.map((etapa) => (
                                        <MenuItem key={etapa.EtapaId} value={etapa.EtapaId}>
                                            {etapa.EtapaDescripcion}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container spacing={2} justifyContent="flex-end">
                                {mostrarModalEvidencia && documento.DocumentoId !== '' && (
                                    <Grid item lg={2} md={3} sm={6} xs={12}>
                                        <InputImagenDocumento
                                            alCargarImagen={() => {}}
                                            alEliminarImagen={() => {}}
                                            limiteImagenes={3}
                                            estadoImagen={[]}
                                            idDocumento={documento.DocumentoId}
                                            estadoFormulario={estado}
                                            deshabilitarBoton={deshabilitado}
                                        />
                                    </Grid>
                                )}
                                {documento.PlantillaImpresionId !== null &&
                                    estado === EstadosFormularioDocumento.EDICION && (
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                disabled={deshabilitado}
                                                endIcon={<PdfIcon />}
                                                onClick={() => descargarDocumentoPdf(documento)}
                                                size="small"
                                                variant="contained"
                                            >
                                                <Texto id="FormularioDocumento.DescargarDocumento" />
                                            </Button>
                                        </Grid>
                                    )}
                                {estado === EstadosFormularioDocumento.EDICION && (
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            disabled={deshabilitado}
                                            endIcon={<EditIcon />}
                                            onClick={() => editarDocumento(documento)}
                                            size="small"
                                            variant="contained"
                                        >
                                            <Texto id="boton.editar" />
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </>
                    {/* )} */}
                </Grid>
            </CardContent>
        </Card>
    );
};

export { IEntidadesFormularioDocumento };

export default FormularioDocumento;
