import { Query, QueryResult } from '@infotrack/presentacion-componentes/tabla';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/documento';
import IDocumentoConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoConsulta';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';

export interface IInputsDocumentos {
    DescripcionBodegaOrigen: string;
    DireccionEntrega: string;
    DocumentoId?: string;
    Documento: IDocumento | null;
    EtapaDescripcion: string;
    PersonaRelacionada: string;
    Observacion: string;
}

export interface IInputsDocumentoDetalle {
    CantidadEntregada: string;
    CantidadRecibida: string;
    CantidadSolicitada: string;
    DescripcionItem: string;
}

export interface IInputsFiltros {
    BodegaOrigen: IBodega | null;
    CodigoDocumento: string;
}

export interface ITablaDevolucionesVistaProps {
    alAbrirFormulario: (documento?: IDocumentoConsulta) => void;
    alCambiarValor: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    consultarDocumentos: (limpiarFiltros?: boolean) => void;
    consultaDocumentos: (query: Query<IDocumentoConsulta>) => Promise<QueryResult<IDocumentoConsulta>>;
    entidadesFiltros: { bodegas: IBodega[] };
    filtros: IInputsFiltros;
    refTabla: React.RefObject<any>;
}

export interface ITablaDetallesDevolucionesVistaProps {
    alAbrirFormulario: (detalle: IDocumentoDetalleCompuesto) => void;
    detalles: IDocumentoDetalleCompuesto[];
    tipoFormulario: TipoFormulario;
}

export interface IFormularioDevolucionesVistaProps {
    alCambiarDocumento: (documento: IDocumento) => void;
    alCerrarFormulario: () => void;
    confirmarGuardarEditarDocumento: () => void;
    documento: IInputsDocumentos;
    documentos: IDocumento[];
    estadoFormulario: boolean;
    tipoFormularioDocumento: TipoFormulario;
    modificarEtapa: () => void;
}

export interface IFormularioDetallesVistaProps {
    alCambiarValor: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    alCerrarFormulario: () => void;
    alGuardarDocumentoDetalle: () => void;
    documentoDetalle: IInputsDocumentoDetalle;
    estadoFormulario: boolean;
    esMovil: boolean;
}

/* Reductor y Redux */

export enum acciones {
    CAMBIAR_ESTADO_FORMULARIO = 'CAMBIAR_ESTADO_FORMULARIO',
    CAMBIAR_ESTADO_FORMULARIO_DETALLE = 'CAMBIAR_ESTADO_FORMULARIO_DETALLE',
    CARGAR_DETALLES = 'CARGAR_DETALLES',
    EDITAR_DOCUMENTO_DETALLE = 'EDITAR_DOCUMENTO_DETALLE',
    ESTABLECER_DOCUMENTO = 'ESTABLECER_DOCUMENTO',
    ESTABLECER_ENTIDAD_DOCUMENTO = 'ESTABLECER_ENTIDAD_DOCUMENTO',
    ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE = 'ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE',
    RECARGAR_TABLA = 'RECARGAR_TABLA',
}

export interface IEstadoDevoluciones {
    detalles: IDocumentoDetalleCompuesto[];
    documento: IDocumento | null;
    entidadDocumento?: IDocumentoConsulta;
    entidadDocumentoDetalle?: IDocumentoDetalleCompuesto;
    estadoFormulario: boolean;
    estadoFormularioDetalle: boolean;
    recargarTabla: boolean;
    tipoFormulario: TipoFormulario;
}

export interface IEstadoAPropiedades {
    EmpresaId: string;
}

export interface IEstadoAPropiedadesTabla {
    AgenciaId: string;
    UsuarioId: string;
}
