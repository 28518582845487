import React from 'react';
import SelectMasivoItemsCV from './controladorVista/selectMasivoItemsCV';
import { IParametrosBusquedaItem, IRefSelectMasivo, ISelectMasivoItemsCVProps } from './interfaces';

const SelectMasivoItems: React.FunctionComponent<ISelectMasivoItemsCVProps> = (props) => (
  <SelectMasivoItemsCV {...props} />
);

export { ISelectMasivoItemsCVProps as ISelectMasivoItemsProps, IParametrosBusquedaItem, IRefSelectMasivo };

export default SelectMasivoItems;
