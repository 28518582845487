const mensajes = {
    // DetallesFormulario
    'DetallesFormulario.TituloCreacion': 'Add details',
    'DetallesFormulario.TituloEdicion': 'Edit detail',
    'DetallesFormulario.TooltipAsignarSeriales': 'Assign serials',
    'DetallesFormulario.ValidacionNoItem': 'You have to select an item',
    'DetallesFormulario.ValidacionNoCantidad': 'Requested quantity must be greater than 0',
    'DetallesFormulario.ValidacionNoDivisionOrigen': 'You have to select an origin division',
    'DetallesFormulario.ValidacionNoDivisionDestino': 'You have to select a destination division',
    'DetallesFormulario.ValidacionNoLote': 'This item requires lot',
    'DetallesFormulario.ValidacionNoPeso': 'This item requires weight',
    'DetallesFormulario.ValidacionNoFechaVencimiento': 'This item requires expiration date',
    'DetallesFormulario.ValidacionSerialesIncompletos': 'You have not entered any serial number, please verify',
    // CampoCodigoBarrasItem
    'DetallesFormulario.CampoCodigoBarrasItem.Nombre': 'Item barcode',
    // CampoDescripcionItem
    'DetallesFormulario.CampoDescripcionItem.Nombre': 'Item desc.',
    // CampoDivisionOrigen
    'DetallesFormulario.CampoDivisionOrigen.Nombre': 'Origin division',
    // CampoDivisionDestino
    'DetallesFormulario.CampoDivisionDestino.Nombre': 'Destination division',
    // CampoCantidadSolicitada
    'DetallesFormulario.CampoCantidadSolicitada.Nombre': 'Requested quantity',
    // CampoCantidadRecibida
    'DetallesFormulario.CampoCantidadRecibida.Nombre': 'Received quantity',
    // CampoCantidadEntregada
    'DetallesFormulario.CampoCantidadEntregada.Nombre': 'Delivered quantity',
    // CampoValor
    'DetallesFormulario.CampoValor.Nombre': 'Value',
    // CampoLote
    'DetallesFormulario.CampoLote.Nombre': 'Lot',
    // CampoPeso
    'DetallesFormulario.CampoPeso.Nombre': 'Weight',
    // CampoFechaVencimiento
    'DetallesFormulario.CampoFechaVencimiento.Nombre': 'Expiration date',
    // CampoSerial
    'DetallesFormulario.CampoSerial.Nombre': 'Serial',
    'DetallesFormulario.ValidacionPeso':'The weight must be less than the requested weight.',
    'DetallesFormulario.ValidacionCantidadNoValidaPesoRecibido':'The quantity received is greater than the quantity requested.',
    'DetallesFormulario.ValidacionCantidadNoValidaPesoSolicitado':'The delivered quantity is greater than the requested quantity.'
};

export default mensajes;
