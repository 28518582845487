const mensajes = {
    // SalidaInventario
    'SalidaInventario.ValidacionNoTipoDocumentoId': 'You must select a document type',
    'SalidaInventario.ValidacionNoBodegaOrigen': 'You must select an origin warehouse',
    'SalidaInventario.ValidacionNoClienteId': 'You must select a client',
    // ModalSalidaInventario
    'ModalSalidaInventario.TituloCreacion': 'Create Inventory Output',
    'ModalSalidaInventario.TituloEdicion': 'Edit Inventory Output',
    'ModalSalidaInventario.TituloVisualizacion': 'View Inventory Output',
    'ModalSalidaInventario.TituloTablaDetalles': 'Details',
    'ModalSalidaInventario.TituloTablaDetallesDocumentoBase': 'Base document details',
    'permiso.salidainventario': 'Access',
};

export default mensajes;
