export default {
  'persona.municipios': 'City',
  'persona.cargos': 'Position',
  'persona.numeroIdentificacion': 'Identification number',
  'persona.primerNombre': 'First name',
  'persona.segundoNombre': 'Second name',
  'persona.primerApellido': 'Surname',
  'persona.segundoApellido': 'Second surname',
  'persona.direccion': 'Address',
  'persona.telefonos': 'Phone',
  'persona.emailPrincipal': 'Email',
  'persona.estado': 'State',
  'persona.celular': 'Mobile',
  'persona.alerta.emailIncorrecto': 'The email entered is invalid.',
  'persona.alerta.numeroDocumentoIncorrecto': 'The identification number is invalid.'
};
