import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';

const CAMPOS_OCULTAR_FORMULARIO_AUDITORIA_CREACION: Array<keyof IAuditoriaCompuesta> = [
    'EtapaDocumento',
    'CodigoDocumento',
];

const CAMPOS_DESHABILITAR_FORMULARIO_AUDITORIA: Array<keyof IAuditoriaCompuesta> = [
    'BodegaId',
    'TipoDocumentoId',
    'Divisiones',
    'Familias',
    'Items',
    'CodigoDocumento',
    'EtapaDocumento',
];

const CAMPOS_DESHABILITAR_FORMULARIO_AUDITORIA_VISUALIZACION: Array<keyof IAuditoriaCompuesta> = [
    'EtapaDocumento',
    'CodigoDocumento',
];

const ETAPAS_DESHABILITAR_ACCIONES: Array<string> = ['Finalizado', 'Cerrado', 'Anulado'];

export {
    CAMPOS_OCULTAR_FORMULARIO_AUDITORIA_CREACION,
    CAMPOS_DESHABILITAR_FORMULARIO_AUDITORIA,
    CAMPOS_DESHABILITAR_FORMULARIO_AUDITORIA_VISUALIZACION,
    ETAPAS_DESHABILITAR_ACCIONES,
};
