export default {
  'sidebar.despachos': 'Despachos',
  'despachos.NumeroDocumento': 'N° Documento',
  'despachos.TipoDocumento': 'Tipo documento',
  'despachos.FechaCreacionDocumento': 'Fecha inicial',
  'despachos.FechaVencimientoDocumento': 'Fecha final',
  'despachos.PersonaCreacion': 'Personas asociadas',
  'despachos.EstadoDocumento': 'Estado documento',
  'despachos.Bodega': 'Bodegas',
  'despachos.Cliente': 'Cliente',
  'despachos.modificarEtapa': 'Cambiar etapa',
  'despachos.documento.Documento': 'Documento',
  'despachos.documento.DireccionEntrega': 'Dirección entrega',
  'despachos.documento.PersonaRelacionada': 'Persona relacionada',
  'despachos.documento.CodigoDocumento': 'Código',
  'despachos.documento.DocumentoRelacionado': 'Documento relacionado',
  'despachos.documento.CodigoDocumentoPadre': 'Documento Padre',
  'despachos.documento.DescripcionProveedor': 'Proveedor',
  'despachos.documento.FechaCreacion': 'Fecha creación',
  'despachos.documento.EtapaDescripcion': 'Etapa',
  'despachos.documento.Observacion': 'Observación',
  'despachos.documento.DocumentoPadreId': 'Documento padre',
  'despachos.documento.DocumentoPadre': '¿Es documento padre?',
  'despachos.documento.crearDocumento': 'Crear documento',
  'despachos.documento.editarDocumento': 'Editar documento',
  'despachos.documento.BodegaOrigen': 'Bodega origen',
  'despachos.documento.tituloEstadoDespacho': 'Modificar estado despacho',
  'despachos.documentoDetalle.CantidadRecibida': 'Cantidad solicitada',
  'despachos.documentoDetalle.CantidadEntregada': 'Cantidad despachada',
  'despachos.documentoDetalle.DescripcionItem': 'Descripción ítem',
  'despachos.documentoDetalle.CodigoBarras': 'Código de barras',
  'despachos.documentoDetalle.CantidadSolicitada': 'Cantidad solicitada',
  'despachos.documentoDetalle.DivisionDestino': 'División',
  'despachos.documentoDetalle.crearDetalleDocumento': 'Crear detalle',
  'despachos.documentoDetalle.editarDetalleDocumento': 'Editar detalle',
  'despachos.documento.datosDocumento': 'Datos documento',
  'despachos.documentoDetalle.documentoDetalles': 'Detalles del documento',
  'despachos.documento.DescripcionCliente': 'Cliente',
  'despachos.alerta.fechaInvalida': 'La fecha final debe ser mayor a la fecha inicial',
  'despachos.alerta.fechaFaltante': 'Ingrese las dos fechas para poder realizar la búsqueda',
  'despachos.alerta.cantidadEntregadaInvalida': 'La cantidad entregada no puede ser mayor a la recibida',
  'despachos.alerta.cambioEstadoDespachado': '¿Está seguro que desea despachar el pedido?',
  'despachos.alerta.cambioEstadoEntregaConfirmada': '¿Está seguro que desea confirmar la entrega?',
};
