import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import IPlantillaImpresion from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IPlantillaImpresion';
import ModeloPlantillaImpresion from 'Infotrack@Modelos/negocioRefactor/modelos/Administracion/ModeloPlantillaImpresion';

class PlantillaImpresionCM {
    private modeloPlantillaImpresion: ModeloPlantillaImpresion;

    constructor() {
        this.modeloPlantillaImpresion = new ModeloPlantillaImpresion();
    }

    public async consultarListaPlantillaImpresionMovil(idEmpresa: string) {
        const respuesta = await this.modeloPlantillaImpresion.consultarListaPlantillaImpresionMovil(idEmpresa);
        return respuesta.data.Entidades;
    }

    public async crearPlantillaImpresion(plantillaImpresion: IPlantillaImpresion) {
        if (!this.validarPlantillaImpresion(plantillaImpresion)) return { Entidades: [], Resultado: false };
        const respuesta = await this.modeloPlantillaImpresion.crearPlantillaImpresion(plantillaImpresion);
        return respuesta.data;
    }

    public async editarPlantillaImpresion(plantillaImpresion: IPlantillaImpresion) {
        if (!this.validarPlantillaImpresion(plantillaImpresion)) return { Entidades: [], Resultado: false };
        const respuesta = await this.modeloPlantillaImpresion.editarPlantillaImpresion(plantillaImpresion);
        return respuesta.data.Entidades;
    }

    public async eliminarPlantillaImpresion(filtro: Partial<IPlantillaImpresion>) {
        const respuesta = await this.modeloPlantillaImpresion.eliminarPlantillaImpresion(filtro);
        return respuesta.data.Resultado;
    }

    private validarPlantillaImpresion(plantillaImpresion: IPlantillaImpresion) {
        if (!plantillaImpresion.PlantillaImpresionDescripcion) {
            notificacionGlobal('PlantillaImpresion.ValidacionNoPlantillaImpresionDescripcion', 3000, 'warning', true);
            return false;
        }
        if (!plantillaImpresion.Comando) {
            notificacionGlobal('PlantillaImpresion.ValidacionNoComando', 3000, 'warning', true);
            return false;
        }
        return true;
    }
}

export default PlantillaImpresionCM;
