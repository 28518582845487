import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';

interface ICampoClienteProps {
    manejarCambioFiltro: (
        nombreFiltro: keyof IDocumentoFiltro,
        nuevoValor: IDocumentoFiltro[keyof IDocumentoFiltro]
    ) => any;
    valor: string;
}

const CampoCliente: FunctionComponent<ICampoClienteProps> = ({ manejarCambioFiltro, valor }) => (
    <TextField
        fullWidth
        label={<Texto id="Documentos.CampoCliente.Nombre" />}
        onChange={(event) => manejarCambioFiltro('Cliente', event.target.value)}
        value={valor}
    />
);

export default CampoCliente;
