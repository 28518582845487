export default {
  'sidebar.devoluciones': 'Client return',
  'permiso.reversion': 'Access',
  'devolucion.modificarEtapa': 'Change stage',
  'devolucion.numeroDocumento': 'N° Document',
  'devolucion.revertirDocumento': 'Reverse',
  'devolucion.CodigoDocumento': 'Document code',
  'devolucion.DescripcionBodegaOrigen': 'Origin winery',
  'devolucion.DescripcionBodegaDestino': 'Destination winery',
  'devolucion.Bodega': 'Warehouse',
  'devolucion.Documento': 'Document',
  'devolucion.TipoDocumentoDescripcion': 'Document type',
  'devolucion.EtapaDescripcion': 'Stage description',
  'devolucion.FechaCreacion': 'Creation date',
  'devolucion.documento.DireccionEntrega': 'Delivery address',
  'devolucion.documento.PersonaRelacionada': 'Related person',
  'devolucion.documento.CodigoDocumento': 'Code',
  'devolucion.documento.DocumentoRelacionado': 'Related document',
  'devolucion.documento.BodegaOrigen': 'Winery',
  'devolucion.documento.DescripcionProveedor': 'Provider',
  'devolucion.documento.FechaEntrega': 'Creation date',
  'devolucion.documento.EtapaDescripcion': 'Stage',
  'devolucion.documento.Observacion': 'Observation',
  'devoluciones.documentoDetalle': 'Document details',
  'devolucion.documentoDetalle.CantidadRecibida': 'Request amount',
  'devolucion.documentoDetalle.CantidadEntregada': 'Amount back',
  'devolucion.documentoDetalle.DescripcionItem': 'Item description',
  'devolucion.documentoDetalle.CodigoBarras': 'Barcode',
  'devolucion.documentoDetalle.editarDetalleDocumento': 'Edit detail',
  'devolucion.documentoDetalle.CantidadSolicitada': 'Request amount',
  'devolucion.documentoDetalle.DivisionDestino': 'Division',
  'devolucion.documento.editarDocumento': 'Edit return',
  'devolucion.documento.crearDocumento': 'Create return',
  'devolucion.documento.datosDocumento': 'Document data',
  'devolucion.alerta.confirmarReversion': 'Are you sure you want to reverse the document?',
};
