import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogContent, Grid } from '@material-ui/core';

import Texto from '@infotrack/presentacion-componentes/texto';

import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import DetallesFormulario from 'Infotrack@Transversales/componentes/DetallesFormulario/DetallesFormulario';
import IAuditoriaConteoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteoDetalle';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import EstadosDetallesFormulario from 'Infotrack@Transversales/componentes/DetallesFormulario/enumeraciones';
import IItemsConsulta from 'Infotrack@Modelos/smartStock/items/entidades/IItemsConsulta';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';
import AuditoriaCM from 'Infotrack@Modulos/smartStock/Conteo/controladorModelo/AuditoriaCM';
import AuditoriaConteoCM from 'Infotrack@Modulos/smartStock/Conteo/controladorModelo/AuditoriaConteoCM';
import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';
import IAuditoriaConteoDetalleCompuesto from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaConteoDetalleCompuesto';

import ConteoDetallesTabla from './Conteo/ConteoDetallesTabla';
import AuditoriaConteoDetalleCM from '../controladorModelo/AuditoriaConteoDetalleCM';
import EntidadesConteoDetalleCM from '../controladorModelo/EntidadesConteoDetalleCM';
import TipoConteoAuditoriaCM from '../../../parametrizacion/tipoConteoAuditoria/controladorModelo/tipoConteoAuditoriaCM';
import AuditoriaConteoFormulario from 'Infotrack@Transversales/componentes/Auditoria/AuditoriaConteo/AuditoriaConteoFormulario';
import { useParams } from 'react-router-dom';
import ITipoConteoAuditoria from 'Infotrack@Modelos/smartStock/tipoConteoAuditoria/entidades/ITipoConteoAuditoria';

const ModalConteo = () => {
    const auditoriaCM = useMemo(() => new AuditoriaCM(), []);
    const auditoriaConteoCM = useMemo(() => new AuditoriaConteoCM(), []);
    const auditoriaConteoDetalleCM = useMemo(() => new AuditoriaConteoDetalleCM(), []);
    const entidadesConteoDetalleCM = useMemo(() => new EntidadesConteoDetalleCM(), []);
    const tipoConteoAuditoriaCM = useMemo(() => new TipoConteoAuditoriaCM(), []);

    const { auditoriaId, auditoriaConteoId } = useParams<{ auditoriaId: string; auditoriaConteoId: string }>();

    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);

    const [auditoria, setAuditoria] = useState<IAuditoriaCompuesta | null>(null);
    const [auditoriaConteo, setAuditoriaConteo] = useState<IAuditoriaConteo | null>(null);
    const [conteoDetalles, setConteoDetalles] = useState<IAuditoriaConteoDetalleCompuesto[]>([]);
    const [tiposConteo, setTiposConteo] = useState<ITipoConteoAuditoria[]>([]);
    const [conteos, setConteos] = useState<IAuditoriaConteo[]>([]);

    const [entidadesDetalle, setEntidadesDetalle] = useState<{
        items: IItemsConsulta[];
        divisionesDestino: IBodegaDivisiones[];
    }>({ items: [], divisionesDestino: [] });
    const [refrescarDetalles, setRefrescarDetalles] = useState<boolean>(false);

    const estadoDetallesFormulario = EstadosDetallesFormulario.CREACION;

    useEffect(() => {
        consultarInformacionModalConteo();
    }, [auditoriaId]);

    useEffect(() => {
        if (conteos.length > 0 && auditoriaConteo !== null) {
            let c = conteos.find((x) => x.AuditoriaConteoId === auditoriaConteo.AuditoriaConteoId)!.ConteoFinalizado;
            let a = auditoriaConteo;
            a.ConteoFinalizado = !c;
            setAuditoriaConteo(a);
        }
    }, [conteos]);

    useEffect(() => {
        if (auditoria && auditoria.AuditoriaId) {
            consultaConteoTablaDetalles();
        }
    }, [auditoria]);

    useEffect(() => {
        if (refrescarDetalles) {
            consultaConteoTablaDetalles();
            setRefrescarDetalles(false);
        }
    }, [refrescarDetalles]);

    const consultarInformacionModalConteo = async () => {
        await consultarAuditoria(auditoriaId);
        await consultarConteoAuditoria(auditoriaConteoId);
        await consultarListaConteo();
        await consultarListaTiposConteo();
    };

    const consultaConteoTablaDetalles = async () => {
        await consultarEntidadesDetalle();
        await consultarConteoDetalles();
    };

    const consultarAuditoria = async (auditoriaId: string) => {
        const documentoConsultado = await auditoriaCM.consultarAuditoria({ AuditoriaId: auditoriaId });
        setAuditoria(documentoConsultado);
    };

    const consultarConteoAuditoria = async (auditoriaConteoId: string) => {
        let auditoriaConteoConsultado = await auditoriaConteoCM.consultarConteoAuditoria(auditoriaConteoId);
        setAuditoriaConteo(auditoriaConteoConsultado);
    };

    const consultarConteoDetalles = async () => {
        const conteoDetallesConsultados = await auditoriaConteoDetalleCM.consultarListaAuditoriaConteoDetalle(
            auditoriaConteoId
        );
        setConteoDetalles(conteoDetallesConsultados);
    };

    const consultarListaTiposConteo = async () => {
        const tiposConteosConsultados = await tipoConteoAuditoriaCM.consultarTipoConteoAuditoria();
        setTiposConteo(tiposConteosConsultados);
    };

    const consultarListaConteo = async () => {
        const filtroConsultaListaConteo: Partial<IAuditoriaConteo> = {
            AuditoriaId: auditoriaId,
            EmpresaId: idEmpresa,
            AgenciaId: idAgencia,
        };
        const conteosConsultados = await auditoriaConteoCM.consultarListaConteo(filtroConsultaListaConteo);
        setConteos(conteosConsultados);
    };

    const consultarEntidadesDetalle = async () => {
        const entidadesDetalleConsultadas = await entidadesConteoDetalleCM.consultarEntidadesDetalle(
            auditoria!.AuditoriaId
        );
        setEntidadesDetalle(entidadesDetalleConsultadas);
    };

    const agregarDetallesConteo = async (detalleConteoAgregar: IDocumentoDetalle[]) => {
        const respuesta = await auditoriaConteoDetalleCM.crearConteoDetalle(mapearConteoDetalle(detalleConteoAgregar));
        if (respuesta) {
            await consultarConteoDetalles();
            setRefrescarDetalles(true);
        }
        return respuesta;
    };

    const editarConteoDetalle = async (detalleConteoEditar: IAuditoriaConteoDetalle[]) => {
        const respuesta = await auditoriaConteoDetalleCM.editarConteoDetalle(detalleConteoEditar);
        if (respuesta) {
            await consultarConteoDetalles();
            setRefrescarDetalles(true);
        }
        return respuesta;
    };

    const mapearConteoDetalle = (detalle: IDocumentoDetalle[]): IAuditoriaConteoDetalle[] => {
        const nuevosDetalles: IAuditoriaConteoDetalle[] = detalle.map((d) => ({
            ...CONTEO_DETALLE_POR_DEFECTO,
            AuditoriaConteoId: auditoriaConteo!.AuditoriaConteoId,
            ItemId: d.ItemId!,
            Division: d.DivisionDestino!,
            CantidadBase: 0,
            Cantidad: d.CantidadRecibida!,
            Serial: d.Serial,
            Lote: d.Lote,
            FechaVencimiento: d.FechaVencimiento,
        }));
        return nuevosDetalles;
    };

    const CONTEO_DETALLE_POR_DEFECTO: IAuditoriaConteoDetalle = {
        AuditoriaConteoDetalleId: '',
        AuditoriaConteoId: '',
        ItemId: 0,
        Division: 0,
        CantidadBase: 0,
        Cantidad: 0,
        Serial: '',
        Lote: '',
        FechaVencimiento: '',
        FechaIngreso: '',
        FechaSistema: '',
        UsuarioId: idUsuario,
        EmpresaId: idEmpresa,
        AgenciaId: idAgencia,
        Estado: 1,
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <AuditoriaConteoFormulario
                    conteo={auditoriaConteo}
                    crearConteo={() => false}
                    camposDeshabilitar={['DescripcionConteo', 'Observacion', 'IdTipoConteo']}
                    conteos={conteos}
                    tiposConteo={tiposConteo}
                />
            </Grid>
            <Grid item xs={12}>
                <DetallesFormulario
                    agregarDetalles={agregarDetallesConteo}
                    alAgregarDetalles={consultarConteoDetalles}
                    alEditarDetalle={async () => {
                        //setDetalleInicialEdicion(null);
                        await consultarConteoDetalles();
                    }}
                    camposDeshabilitar={['DivisionOrigen', 'CantidadEntregada', 'CantidadSolicitada']}
                                      camposOcultar={['DivisionOrigen', 'CantidadEntregada', 'CantidadSolicitada', 'Valor','PesoFraccion']}
                    deshabilitado={
                        auditoriaConteo !== null && auditoriaConteo.ConteoFinalizado !== null
                            ? auditoriaConteo.ConteoFinalizado
                            : false
                    }
                    detalleInicialEdicion={null}
                    documentoId={auditoriaConteoId}
                    entidades={entidadesDetalle}
                    estado={estadoDetallesFormulario}
                    tipoCantidad="CantidadRecibida"
                    seriadoConsulta={false}
                   esEntradaInventario={false}
                    bloquearCantidadSerial={false}
                    esOrdenTraslado={false}
                />
            </Grid>
            <Grid item xs={12}>
                <ConteoDetallesTabla
                    editarDetallesConteo={editarConteoDetalle}
                    detallesConteo={conteoDetalles}
                    recargarTabla={consultarConteoDetalles}
                    camposAOcultar={['CantidadBase']}
                    conteo={auditoriaConteo}
                />
            </Grid>
        </Grid>
    );
};

export default ModalConteo;
