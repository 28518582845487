const mensajes = {
    // EntradaDevolucion
    'EntradaDevolucion.ValidacionNoTipoDocumentoId': 'Debe seleccionar un tipo de documento',
    'EntradaDevolucion.ValidacionNoBodegaDestino': 'Debe seleccionar una bodega de destino',
    'EntradaDevolucion.ValidacionNoClienteId': 'Debe seleccionar un cliente',
    // ModalEntradaDevolucion
    'ModalEntradaDevolucion.TituloCreacion': 'Crear Entrada por Devolucion',
    'ModalEntradaDevolucion.TituloEdicion': 'Editar Entrada por Devolucion',
    'ModalEntradaDevolucion.TituloVisualizacion': 'Ver Entrada por Devolucion',
    'ModalEntradaDevolucion.TituloTablaDetallesDocumento': 'Detalles Documento',
    'ModalEntradaDevolucion.TituloTablaDetallesDocumentoBase': 'Detalles Documento Base',
    'permiso.entradadevolucion': 'Acceso',
    // mensajes
    'MensajeEntradaDevolucion.confirmarAnularDocumento': '¿Está seguro que desea anular el documento?',
};

export default mensajes;
