import ProveedorEstado from '@infotrack/presentacion-componentes/proveedorEstado';
import React from 'react';
import ModalDetalleAlmacenamientoCV from './controladorVista/modalDetalleAlmacenamientoCV';
import TablaAlmacenamientoCV from './controladorVista/tablaAlmacenamientoCV';
import { estadoInicial, reductor } from './reductorAlmacenamiento';

const Almacenamiento = () => (
    <ProveedorEstado estadoInicial={estadoInicial} reductor={reductor}>
        <TablaAlmacenamientoCV />
        <ModalDetalleAlmacenamientoCV />
    </ProveedorEstado>
);

export default Almacenamiento;
