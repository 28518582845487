import { createStyles } from '@material-ui/core';

const estilos = createStyles({
  contenedorPaginador: {
    padding: '0 1em',
  },
  elementosPaginador: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export default estilos;
