import ModeloBodegasPropiedades from 'Infotrack@Modelos/smartStock/bodegasPropiedades';
import IBodegaPropiedadesFiltro from 'Infotrack@Modelos/smartStock/bodegasPropiedades/entidades/bodegaPropiedadesFiltro';

export default class BodegasPropiedadesCM {
  private modeloBodegasPropiedades: ModeloBodegasPropiedades;

  constructor() {
    this.modeloBodegasPropiedades = new ModeloBodegasPropiedades();
  }

  public consultaDivisionPropiedadesCompuestaFiltro(bodegaPropiedades: IBodegaPropiedadesFiltro) {
    return this.modeloBodegasPropiedades
      .consultarBodegasTiposPropiedades(bodegaPropiedades)
      .then(({ data: { Entidades } }) => Entidades.map((entidad, indice) => ({ ...entidad, IdTemporal: indice + 1 })));
  }
}
