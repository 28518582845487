export default {
  'sidebar.procesos': 'Processes',
  'sidebar.traslados': 'Transfers',
  'traslados.bodega': 'Warehouse',
  'traslados.proveedor': 'Provider',
  'traslados.cambiarEtapa': 'Change stage',
  'traslados.numeroDocumento': 'N° Document',
  'traslados.confirmarTraslados': 'Confirm transfer',
  'traslados.cambiarEstado': 'Change Status',
  'traslados.documentos': 'Documents',
  'traslados.documento': 'Document',
  'traslados.documentoPadre': 'Parent document',
  'traslados.bodegaOrigen': 'Origin warehouse',
  'traslados.observaciones': 'Observations',
  'traslados.traslado': 'Transfers',
  'traslados.aprobarOrigen': 'Are you sure you want to approve the origin?',
  'traslados.aprobarDestino': 'Are you sure you want to approve the destination?',
  'traslados.iniciarAlistamiento': 'Are you sure you want to start enlistment?',
  'traslados.confirmarAlistamiento': 'Are you sure you want to confirm the enlistment?',
  'traslados.enviarATransito': 'Are you sure you want to send transit?',
  'traslados.enviarAPlataformaDestino': 'Are you sure you want to send to the destination platform?',
  'traslados.confirmarEntrega': 'Are you sure you want to confirm delivery?',
  'traslados.documento.CodigoDocumento': 'Code',
  'traslados.documento.DocumentoRelacionado': 'Related document',
  'traslados.documento.DocumentoPadre': 'Parent document',
  'traslados.documento.DescripcionProveedor': 'Provider',
  'traslados.documentoDetalle.DescripcionDivisionOrigen': 'Origin division',
  'traslados.documentoDetalle.DescripcionDivisionDestino': 'Destination division',
  'traslados.documento.FechaEntrega': 'Creation date',
  'traslados.documento.EtapaDescripcion': 'Stage',
  'traslados.documento.Observacion': 'Observation',
  'traslados.documentoDetalle.DescripcionItem': 'Product',
  'traslados.documentoDetalle.CodigoBarras': 'Barcode',
  'traslados.documentoDetalle.CantidadSolicitada': 'Requested amount',
  'traslados.documentoDetalle.CantidadRecibida': 'Moved amount',
  'traslados.documentoDetalle.iniciarTraslados': 'Start transfer',
  'traslados.documentoDetalle.confirmarTraslados': 'Confirm transfer',
  'traslados.documentoDetalle.editarDetalle': 'Edit detail',
  'traslados.documentos.verDetalle': 'See detail',
  'traslados.documentos.detalle': 'Detail document',
  'traslados.detalle': 'Transfer detail',
  'traslados.documentos.alertaFiltroBodega': 'Please select a warehouse',
  'traslados.documentos.alertaFiltroProveedorDocumento': 'Please select a provider or enter a document number',
  'traslados.documentos.alertaLotes': 'This product requires a serial number',
  'traslados.documentos.alertaSeriado': 'This product requires registering a lot number',
  'traslados.documentos.alertaCantidadRecibida': 'The amount received must be greater than previously entered',
  'traslados.documentos.alertaFIFO': 'This product requires an expiration date',
  'traslados.documentosdocumentoDetalle.edicionParcial': 'Partial',
  'traslados.documentosdocumentoDetalle.edicionTotal': 'Total',
  'traslados.documentosdocumentoDetalle.traslados': 'Reception',
  'traslados.documentosdocumentoDetalle.DivisionDestino': 'Destination division',
  'traslados.documentosdocumentoDetalle.CantidadRecibida': 'Amount',
  'traslados.documentosdocumentoDetalle.Lote': 'Lot',
  'traslados.documentosdocumentoDetalle.FechaVencimiento': 'Expiration date',
  'traslados.documentosdocumentoDetalle.Serial': 'Serial',
  'traslados.confirmacionInicioTraslados': 'Are you sure you want to start receiving?',
  'traslados.confirmacionFinTraslados': 'Are you sure you want to finish the reception?',
};
