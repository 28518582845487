import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';

interface ICampoCodigoDocumentoBaseProps {
    deshabilitado: boolean;
    manejarCambioCampo: (nombreCampo: keyof IDocumentoConsultaGeneral, nuevoValor: string) => any;
    valor: string;
}

const CampoCodigoDocumentoBase: FunctionComponent<ICampoCodigoDocumentoBaseProps> = ({
    deshabilitado,
    manejarCambioCampo,
    valor,
}) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="FormularioDocumento.CampoCodigoDocumentoBase.Nombre" />}
        onChange={(event) => manejarCambioCampo('CodigoDocumentoPredecesor', event.target.value)}
        value={valor || ''}
    />
);

export default CampoCodigoDocumentoBase;
