import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';

import useStyles from '../tablaDetallesPlantillaEstilos';

interface ICampoFechaVencimientoProps {
    deshabilitado: boolean;
    detalle: IDocumentoDetalle;
    manejarCambioCampo: (detalleId: string, nombreCampo: keyof IDocumentoDetalle, nuevoValor: any) => void;
}

const CampoFechaVencimiento: FunctionComponent<ICampoFechaVencimientoProps> = ({
    deshabilitado,
    detalle,
    manejarCambioCampo,
}) => {
    const classes = useStyles();

    return (
        <KeyboardDatePicker
            className={classes.campo}
            disabled={deshabilitado}
            disablePast
            format="DD/MM/YYYY"
            fullWidth
            label={<Texto id="TablaDetallesPlantilla.FechaVencimiento" />}
            onChange={(date) => {
                if (date) manejarCambioCampo(detalle.DocumentoDetalleId, 'FechaVencimiento', date.format('YYYY-MM-DD'));
            }}
            size="small"
            value={detalle.FechaVencimiento || null}
        />
    );
};

export default CampoFechaVencimiento;
