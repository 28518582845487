import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Button, Card, CardContent, Grid } from '@material-ui/core';

import Texto from '@infotrack/presentacion-componentes/texto';

import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';
import ITipoConteoAuditoria from 'Infotrack@Modelos/smartStock/tipoConteoAuditoria/entidades/ITipoConteoAuditoria';

import { EstadosModalAuditoria } from '../../../../modulos/smartStock/Auditoria/enumeraciones';
import CampoDescripcionConteo from './Campos/CampoDescripcionConteo';
import CampoObservacion from './Campos/CampoObservacion';
import CampoTipoConteoDescripcion from './Campos/CampoTipoConteoDescripcion';
import CampoConteoPredecesor from './Campos/CampoConteoPredecesor';

interface IAuditoriaConteoProps {
    conteo: IAuditoriaConteo | null;
    estado?: EstadosModalAuditoria;
    crearConteo: (conteo: IAuditoriaConteo) => void;
    camposDeshabilitar?: Array<keyof IAuditoriaConteo>;
    tiposConteo?: ITipoConteoAuditoria[];
    conteos?: IAuditoriaConteo[];
}

enum EnumTipoConteo {
    ConteoInicial = 1,
    Reconteo = 2,
}

const CONTEO_POR_DEFECTO: IAuditoriaConteo = {
    AuditoriaConteoId: '',
    AuditoriaId: '',
    DescripcionConteo: '',
    Observacion: '',
    Estado: 0,
    Fecha: '',
    ConteoFinalizado:false
};

const AuditoriaConteoFormulario: FunctionComponent<IAuditoriaConteoProps> = ({
    conteo,
    estado,
    crearConteo,
    camposDeshabilitar = [],
    tiposConteo = [],
    conteos = [],
}) => {
    const [auditoriaConteo, setAuditoriaConteo] = useState<IAuditoriaConteo>(CONTEO_POR_DEFECTO);
    const [deshabilitarCampoConteoPredecesor, setEstadoCampoConteoPredecesor] = useState<boolean>(true);

    useEffect(() => {
        if (conteo && conteo.AuditoriaConteoId) {
            setAuditoriaConteo(conteo);
        } else setAuditoriaConteo(CONTEO_POR_DEFECTO);
    }, [conteo]);

    const manejarCambioCampo = (
        nombreCampo: keyof IAuditoriaConteo,
        nuevoValor: IAuditoriaConteo[keyof IAuditoriaConteo]
    ) => {
        if (nombreCampo === 'IdTipoConteo') {
            if (nuevoValor == EnumTipoConteo.Reconteo) {
                setEstadoCampoConteoPredecesor(false);
            } else {
                let conteoPredecesor: keyof IAuditoriaConteo = 'ConteoPredecesorId';
                setAuditoriaConteo((auditoriaConteoActual) => ({
                    ...auditoriaConteoActual,
                    [conteoPredecesor]: nuevoValor,
                }));
                setEstadoCampoConteoPredecesor(true);
            }
        }
        setAuditoriaConteo((auditoriaConteoActual) => ({ ...auditoriaConteoActual, [nombreCampo]: nuevoValor }));
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid alignItems="center" container spacing={2}>
                    <Grid item xs={12}>
                        <Texto id="AuditoriaConteo.Titulo" fontWeight="bold" />
                    </Grid>
                    <Grid alignItems="center" container spacing={4}>
                        <Grid item xs={6}>
                            <CampoDescripcionConteo
                                deshabilitado={camposDeshabilitar.includes('DescripcionConteo')}
                                manejarCambioCampo={manejarCambioCampo}
                                valor={auditoriaConteo.DescripcionConteo}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CampoObservacion
                                deshabilitado={camposDeshabilitar.includes('Observacion')}
                                manejarCambioCampo={manejarCambioCampo}
                                valor={auditoriaConteo.Observacion!}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CampoTipoConteoDescripcion
                                deshabilitado={camposDeshabilitar.includes('IdTipoConteo')}
                                manejarCambio={manejarCambioCampo}
                                tiposConteo={tiposConteo}
                                valor={auditoriaConteo.IdTipoConteo}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CampoConteoPredecesor
                                deshabilitado={deshabilitarCampoConteoPredecesor}
                                manejarCambio={manejarCambioCampo}
                                conteos={conteos.filter((x) => x.IdTipoConteo != EnumTipoConteo.Reconteo)}
                                valor={auditoriaConteo.ConteoPredecesorId}
                                idRecursoTitulo={'AuditoriaConteo.CampoConteoPredecesor.Nombre'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    {estado === EstadosModalAuditoria.CREACION && (
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                crearConteo(auditoriaConteo);
                                                setAuditoriaConteo(CONTEO_POR_DEFECTO);
                                            }}
                                            size="small"
                                            variant="contained"
                                        >
                                            <Texto id="boton.guardar" />
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default AuditoriaConteoFormulario;
