const mensajes = {
    // ModalSeriales
    'ModalSeriales.InicioSerialesConsecutivos': 'Inicio seriales consecutivos',
    'ModalSeriales.Base': 'Base',
    'ModalSeriales.Peso': 'Peso',
    'ModalSeriales.ValidacionNoSerialesRepetidos': 'No pueden haber seriales repetidos',
    'ModalSeriales.SerialesIncompletos': 'El listado de seriales esta incompleto, ¿Está seguro de continuar?',
    'ModalSeriales.ValidacionSerialesIncompletos':'Uno o varios seriales no se han seleccionado',
    // ModalSerialesEncabezado
    'ModalSerialesEncabezado.Titulo': 'Asignar seriales',
    // InputSerial
    'InputSerial.Serial': 'Serial',
    'InputSerial.ErrorSerialRepetido': 'Serial repetido',
};

export default mensajes;
