enum enumTiposDocumentos {
  Auditoria = 'Auditoria',
  Despacho = 'Despacho',
  EntradaDeInventario = 'Entrada de inventario',
  OrdenDeCompra = 'Orden de compra',
  Pedido = 'Pedido',
  Recalada = 'Recalada',
  Recepción = 'Recepción',
  SalidaDeInventario = 'Salida de inventario',
  TrasladoEntreBodegas = 'Traslado entre bodegas',
  TrasladoEntreDivisiones = 'Traslado entre divisiones',
}

export default enumTiposDocumentos;
