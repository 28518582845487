import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import IAccionEtapaDocumentoCompuesto from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/administracion/IAccionEtapaDocumentoCompuesto';
import IAccionEtapaDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAccionEtapaDocumento';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';

import AccionEtapaDocumentoCM from './controladorModelo/AccionEtapaDocumentoCM';
import EntidadesTipoDocumentoCM from './controladorModelo/EntidadesTipoDocumentoCM';
import TipoDocumentoCM from './controladorModelo/TipoDocumentoCM';
import { EstadosModalTipoDocumento } from './enumeraciones';
import ModalTipoDocumento, { IEntidadesTipoDocumento } from './ModalTipoDocumento/ModalTipoDocumento';
import TiposDocumentoTabla from './TiposDocumentoTabla/TiposDocumentoTabla';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import Texto from '@infotrack/presentacion-componentes/texto';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';

const ENTIDADES_TIPO_DOCUMENTO_POR_DEFECTO: IEntidadesTipoDocumento = {
    flujos: [],
    tiposProceso: [],
    plantillaImpresion: [],
};

const TiposDocumento = () => {
    const tipoDocumentoCM = useMemo(() => new TipoDocumentoCM(), []);
    const entidadesTipoDocumentoCM = useMemo(() => new EntidadesTipoDocumentoCM(), []);
    const accionEtapaDocumentoCM = useMemo(() => new AccionEtapaDocumentoCM(), []);

    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);

    const [tiposDocumento, setTiposDocumento] = useState<ITipoDocumento[]>([]);
    const [estadoModal, setEstadoModal] = useState<EstadosModalTipoDocumento>(EstadosModalTipoDocumento.CERRADO);
    const [tipoDocumentoModal, setTipoDocumentoModal] = useState<ITipoDocumento | null>(null);
    const [entidadesTipoDocumento, setEntidadesTipoDocumento] = useState<IEntidadesTipoDocumento>(
        ENTIDADES_TIPO_DOCUMENTO_POR_DEFECTO
    );
    const [accionesEtapas, setAccionesEtapas] = useState<IAccionEtapaDocumentoCompuesto[]>([]);

    useEffect(() => {
        consultarTiposDocumento();
    }, []);

    useEffect(() => {
        alCambiarEstadoModal();
    }, [estadoModal]);

    const consultarTiposDocumento = async () => {
        const tiposDocumentoConsultados = await tipoDocumentoCM.consultarListaTipoDocumentoCompuestoPorEmpresaId(idEmpresa);
        setTiposDocumento(tiposDocumentoConsultados);
    };

    const alCambiarEstadoModal = async () => {
        if (estadoModal === EstadosModalTipoDocumento.CERRADO) {
            consultarTiposDocumento();
        }
        if (estadoModal === EstadosModalTipoDocumento.CREACION) {
            await consultarEntidadesTipoDocumento();
        }
        if (
            estadoModal === EstadosModalTipoDocumento.EDICION ||
            estadoModal === EstadosModalTipoDocumento.VISUALIZACION
        ) {
            await consultarEntidadesTipoDocumento();
            await consultarAccionesEtapasTipoDocumento(tipoDocumentoModal!.TipoDocumentoId);
        }
    };

    const abrirModalCreacion = () => {
        setEstadoModal(EstadosModalTipoDocumento.CREACION);
    };

    const abrirModalEdicion = (tipoDocumentoEditar: ITipoDocumento) => {
        setTipoDocumentoModal(tipoDocumentoEditar);
        setEstadoModal(EstadosModalTipoDocumento.EDICION);
    };

    const abrirModalVisualizacion = (tipoDocumentoVer: ITipoDocumento) => {
        setTipoDocumentoModal(tipoDocumentoVer);
        setEstadoModal(EstadosModalTipoDocumento.VISUALIZACION);
    };

    const cerrarModal = () => {
        setEstadoModal(EstadosModalTipoDocumento.CERRADO);
        setTipoDocumentoModal(null);
        manejadorDialogoGlobal({ estado: false });
    };

    const alCerrarFormulario = () => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => {
                cerrarModal();
            },
            estado: true,
            mensaje: <Texto id={'ModalTipoDocumento.Cancelar'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const consultarEntidadesTipoDocumento = async () => {
        const entidadesTipoDocumentoConsultadas = await entidadesTipoDocumentoCM.consultarEntidadesTipoDocumento(
            idEmpresa, estadoActivo
        );
        setEntidadesTipoDocumento(entidadesTipoDocumentoConsultadas);
    };

    const consultarAccionesEtapasTipoDocumento = async (tipoDocumentoId: number) => {
        const accionesEtapasTipoDocumentoConsultadas = await accionEtapaDocumentoCM.consultarAccionesDocumento(
            tipoDocumentoId
        );
        setAccionesEtapas(accionesEtapasTipoDocumentoConsultadas);
    };

    const crearTipoDocumento = async (tipoDocumento: ITipoDocumento) => {
        const respuesta = await tipoDocumentoCM.guardarTipoDocumento({
            ...tipoDocumento,
            AgenciaId: idAgencia,
            Consecutivo: Number(tipoDocumento.Consecutivo),
            EmpresaId: idEmpresa,
        });
        if (respuesta.Resultado) {
            setTipoDocumentoModal(respuesta.Entidades[0]);
            setEstadoModal(EstadosModalTipoDocumento.EDICION);
        }
    };

    const editarTipoDocumento = async (tipoDocumento: ITipoDocumento) => {
        await tipoDocumentoCM.editarTipoDocumento(tipoDocumento);
        await consultarAccionesEtapasTipoDocumento(tipoDocumentoModal!.TipoDocumentoId);
    };

    const editarAccionesEtapas = async (accionesEtapasGuardar: IAccionEtapaDocumento[]) => {
        await accionEtapaDocumentoCM.guardarAccionesEtapaDocumento(accionesEtapasGuardar);
    };

    const confirmarEliminarTipoDocumento = async (tipoDocumentoId: number) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => eliminarTipoDocumento(tipoDocumentoId),
            estado: true,
            mensaje: <Texto id={'alerta.confirmacionEliminacion'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const eliminarTipoDocumento = async (tipoDocumentoId: number) => {
        manejadorDialogoGlobal({ estado: false });
        const eliminacionExitosa = await tipoDocumentoCM.eliminarTipoDocumento({
            TipoDocumentoId: tipoDocumentoId,
        });
        if (eliminacionExitosa) await consultarTiposDocumento();
    };

    return (
        <>
            <TiposDocumentoTabla
                crearTipoDocumento={abrirModalCreacion}
                editarTipoDocumento={(tipoDocumentoEditar: ITipoDocumento) => abrirModalEdicion(tipoDocumentoEditar)}
                eliminarTipoDocumento={confirmarEliminarTipoDocumento}
                recargarTabla={consultarTiposDocumento}
                tiposDocumento={tiposDocumento}
                verTipoDocumento={(tipoDocumentoVer: ITipoDocumento) => abrirModalVisualizacion(tipoDocumentoVer)}
            />
            <ModalTipoDocumento
                accionesEtapasIniciales={accionesEtapas}
                cerrar={alCerrarFormulario}
                crearTipoDocumento={crearTipoDocumento}
                editarAccionesEtapas={editarAccionesEtapas}
                editarTipoDocumento={editarTipoDocumento}
                entidadesTipoDocumento={entidadesTipoDocumento}
                estado={estadoModal}
                tipoDocumentoInicial={tipoDocumentoModal}
            />
        </>
    );
};

export default TiposDocumento;
