import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IItemsConsulta from 'Infotrack@Modelos/smartStock/items/entidades/IItemsConsulta';

import useStyles from './campoItemDescripcionEstilos';

interface ICampoItemDescripcionProps {
    deshabilitado?: boolean;
    items: IItemsConsulta[];
    manejarCambio: (nuevoValor: IItemsConsulta | null) => void;
    valor: IItemsConsulta | null;
}

const CampoItemDescripcion: FunctionComponent<ICampoItemDescripcionProps> = ({
    deshabilitado,
    items,
    manejarCambio,
    valor,
}) => {
    const classes = useStyles();

    return (
        <Autocomplete
            classes={{ root: classes.campoItemDescripcion }}
            disabled={deshabilitado}
            onChange={(_: ChangeEvent<{}>, value: IItemsConsulta | null) => manejarCambio(value)}
            options={items}
            getOptionLabel={(i) => i.DescripcionItem}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    label={<Texto id="DetallesInventario.CampoItemDescripcion.Nombre" />}
                />
            )}
            value={valor}
        />
    );
};

export default CampoItemDescripcion;
