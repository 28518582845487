import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloDivisionesPropiedades from 'Infotrack@Modelos/smartStock/bodegasDivisiones';
import IBodegaDivisionFiltros from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegaDivisionFiltros';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IBodegasDivisionesPropiedades from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisionesPropiedades';

export default class BodegasDivisionesCM {
    private modeloDivisionesPropiedades: ModeloDivisionesPropiedades;

    constructor() {
        this.modeloDivisionesPropiedades = new ModeloDivisionesPropiedades();
    }

    public async guardarBodegaDivisionesPropiedades(bodegaDivisionesProp: IBodegasDivisionesPropiedades) {
        return this.modeloDivisionesPropiedades.guardarBodegaDivisionesPropiedades(bodegaDivisionesProp);
    }

    public async editarBodegaDivisionesPropiedades(bodegaDivisionesProp: IBodegasDivisionesPropiedades) {
        return this.modeloDivisionesPropiedades.editarBodegaDivisionesPropiedades(bodegaDivisionesProp);
    }

    public consultarDivisionesFiltro(divisionFiltro: IBodegaDivisionFiltros) {
        return this.modeloDivisionesPropiedades
            .consultarDivisionesFiltro(divisionFiltro)
            .then(({ data: { Entidades } }) => Entidades);
    }

    public async eliminarDivisionesCompuesto(bodegaDivisiones: IBodegaDivisiones) {
        return this.modeloDivisionesPropiedades.eliminarDivisionesCompuesto(bodegaDivisiones);
    }

    public validarEntidad(bodegaDivisionesProp: IBodegasDivisionesPropiedades): boolean {
        const { Alto, Ancho, ColumnaId, FilaId, Largo, Peso, PisoId, TipoDivisionId } = bodegaDivisionesProp.Divisiones;
        if (
            Alto === 0 ||
            Ancho === 0 ||
            ColumnaId === 0 ||
            FilaId === 0 ||
            Largo === 0 ||
            Peso === 0 ||
            PisoId === 0 ||
            TipoDivisionId === 0
        ) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else if (Ancho * Alto * Largo > 9000000) {
            notificacionGlobal('gestionBodega.dimensionesInvalidas', 6000, 'warning', true);
            return false;
        }
        return true;
    }
}
