import ModeloMunicipios from 'Infotrack@Modelos/administracion/municipio';
import ModeloClientes from 'Infotrack@Modelos/smartStock/tiposClientes';
import { estadoInactivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';

export default class EntidadesClientesCM {
    private modeloMunicipios: ModeloMunicipios;
    private modeloClientes: ModeloClientes;

    constructor() {
        this.modeloMunicipios = new ModeloMunicipios();
        this.modeloClientes = new ModeloClientes();
    }

    public consultarEntidadesCliente(EmpresaId: string, Estado?: number) {
        return Promise.all([
            this.modeloMunicipios.consultarListaMunicipios(),
            this.modeloClientes.consultarTiposClientesPorFiltro({ EmpresaId, Estado:estadoInactivo }),
        ]).then((entidades) => ({
            municipios: entidades[0].data.Entidades,
            tiposClientes: entidades[1].data.Entidades,
        }));
    }
}
