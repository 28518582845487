import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
    Toolbar,
} from '@material-ui/core';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { esEnteroODecimal } from '@infotrack/utilitarios/expresionesRegulares';
import React from 'react';
import { IFormularioDocumentosDetalleVistaProps } from '../interfaces';

const FormularioDocumentosDetalleVista: React.FunctionComponent<IFormularioDocumentosDetalleVistaProps> = ({
    alCambiarValor,
    alCerrarFormulario,
    //alGuardarDocumentoDetalle,
    documentoDetalle,
    estadoFormulario,
    esMovil,
    tipoFormularioDocumentoDetalle,
    alEditarDocumentoDetalle,
}) => (
    <Dialog fullWidth maxWidth={esMovil ? 'lg' : 'sm'} open={estadoFormulario}>
        <AppBar position="static">
            <Toolbar>
                <Grid container justifyContent="center">
                    <Texto
                        align="center"
                        id={
                            tipoFormularioDocumentoDetalle === TipoFormulario.Creacion
                                ? 'despachos.documentoDetalle.crearDetalleDocumento'
                                : 'despachos.documentoDetalle.editarDetalleDocumento'
                        }
                    />
                </Grid>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Card>
                <CardContent>
                    <Grid alignItems="center" container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                InputProps={{ readOnly: true }}
                                fullWidth
                                label={<Texto display="inline" id="despachos.documentoDetalle.DescripcionItem" />}
                                value={documentoDetalle.DescripcionItem}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                InputProps={{ readOnly: true }}
                                label={<Texto display="inline" id="despachos.documentoDetalle.CantidadRecibida" />}
                                fullWidth
                                value={
                                    documentoDetalle.CantidadRecibida !== null
                                        ? documentoDetalle.CantidadRecibida
                                        : documentoDetalle.CantidadSolicitada
                                }
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={<Texto display="inline" id="despachos.documentoDetalle.CantidadEntregada" />}
                                name="CantidadEntregada"
                                required
                                onChange={(e) =>
                                    esEnteroODecimal(e.target.value, { cantidadDecimales: 5, cantidadEnteros: 7 }) &&
                                    alCambiarValor(e)
                                }
                                value={documentoDetalle.CantidadEntregada}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </DialogContent>
        <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button color="secondary" fullWidth onClick={alCerrarFormulario} variant="contained">
                        <Texto id="boton.cancelar" />
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" fullWidth onClick={() => alEditarDocumentoDetalle()} variant="contained">
                        <Texto
                            id={
                                tipoFormularioDocumentoDetalle === TipoFormulario.Creacion
                                    ? 'boton.agregar'
                                    : 'boton.editar'
                            }
                        />
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
);

export default FormularioDocumentosDetalleVista;
