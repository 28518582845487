import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';

import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IDocumentoCompuesto from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuesto';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import TiposEstadosDetalles from 'Infotrack@Modelos/smartStock/estadosDetalles/entidades/tiposEstadosDetalles';

import DocumentoDetallesCM from '../controladorModelo/documentoDetallesCM';
import EntidadesDocumentoDetalleCM from '../controladorModelo/entidadesDocumentoDetalleCM';
import { IInputsDocumentoDetalle, IInputsDocumentoDetalles } from '../interfaces';
import { acciones, IEstadoAlmacenamiento } from '../reductorAlmacenamiento';
import FormularioEdicionDetalleAlmacenamientoVista from '../vista/formularioEdicionDetalleAlmacenamientoVista';

interface IFormularioEdicionDetalleAlmacenamientoCVProps {
    abierto: boolean;
    documentoDetalle: IDocumentoDetalleCompuesto | undefined;
    cerrarModal: () => void;
    refrescarDocumentoDetalle: (refrescar: boolean) => void;
    tipoFormulario: TipoFormulario | undefined;
    documento: IDocumentoCompuesto | undefined;
}

const documentoDetallesCM = new DocumentoDetallesCM();
const entidadesDocumentoDetalleCM = new EntidadesDocumentoDetalleCM();

const estadoInicialDocumentoDetalle: IDocumentoDetalleCompuesto = {
    CodigoBarras: '',
    CodigoDocumento: '',
    DescripcionItem: '',
    DescripcionProducto: '',
    DescripcionDivisionDestino: '',
    DescripcionDivisionOrigen: '',
    EmpresaId: '',
    Lote: '',
    Serial: '',
    DescripcionEstadoDetalle: TiposEstadosDetalles.Alistado,
};

const FormularioEdicionDetalleAlmacenamientoCV: React.FunctionComponent<IFormularioEdicionDetalleAlmacenamientoCVProps> = ({
    abierto,
    refrescarDocumentoDetalle,
    cerrarModal,
    documentoDetalle,
    tipoFormulario,
    documento,
}) => {
    const { alCambiarValor, setValor, valor, reiniciar } = useInputState<IInputsDocumentoDetalles>(
        estadoInicialDocumentoDetalle
    );
    const [{ bodegaId, estadoFormularioEdicion, tipoDocumentoId }, dispatch] = useProveedor<IEstadoAlmacenamiento>();
    const [divisiones, setDivisiones] = React.useState<IBodegaDivisiones[]>([]);
    /* const { alCambiarValor, setValor, valor } = useInputState<IInputsDocumentoDetalle>({
    CantidadRecibida: '',
    DivisionDestino: undefined,
    FechaVencimiento: null,
    Lote: '',
    LotesItems: false,
    SeriadoItems: false,
    Serial: '',
  });*/
    const theme = useTheme();
    const esMovil = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        if (abierto && divisiones.length === 0) cargarDivisiones();
    }, [estadoFormularioEdicion, abierto]);

    React.useEffect(() => {
        if (documentoDetalle) setValor(mapearDocumentoDetalleInput(documentoDetalle));
    }, [documentoDetalle, abierto]);

    const mapearDocumentoDetalleInput = (
        documentoDetalleCompuesto: IDocumentoDetalleCompuesto
    ): IInputsDocumentoDetalles => ({
        ...documentoDetalleCompuesto,
        CantidadRecibidaActual: documentoDetalleCompuesto.CantidadRecibida,
    });

    const cargarDivisiones = async () => {
        setDivisiones(
            await entidadesDocumentoDetalleCM.consultarEntidadesDocumentoDetalle({
                BodegaId: bodegaId,
                TipoDocumentoId: tipoDocumentoId,
            })
        );
    };

    const mapearInputsAlmacenamientoDetalle = (): IInputsDocumentoDetalle => {
        const {
            CantidadRecibida,
            DivisionDestino,
            FechaVencimiento,
            FEFO,
            FIFO,
            Lote,
            LotesItems,
            SeriadoItems,
            Serial,
        } = documentoDetalle!;
        return {
            ...documentoDetalle,
            CantidadRecibida: CantidadRecibida !== null ? CantidadRecibida!.toString() : '',
            DivisionDestino: DivisionDestino ? DivisionDestino : undefined,
            FechaVencimiento: FechaVencimiento ? FechaVencimiento : null,
            FEFO,
            FIFO,
            Lote,
            LotesItems: LotesItems ? LotesItems : false,
            SeriadoItems: SeriadoItems ? SeriadoItems : false,
            Serial,
        };
    };

    const mapearAlmacenamientoDetalle = (): IDocumentoDetalleCompuesto => {
        const { CantidadRecibida, DivisionDestino, FechaVencimiento, Lote, Serial } = valor;
        return {
            ...documentoDetalle!,
            CantidadRecibida: Number(CantidadRecibida),
            DivisionDestino: DivisionDestino ? Number(DivisionDestino) : 0,
            FechaVencimiento: FechaVencimiento ? FechaVencimiento : '',
            Lote,
            Serial,
        };
    };

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_EDICION });
    };

    const confirmarEdicionAlmacenamientoDetalle = () => {
        const docDetalle = mapearAlmacenamientoDetalle();
        if (documentoDetallesCM.validarDocumentoDetalle(docDetalle)) {
            manejadorDialogoGlobal({
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                accionConfirmar: () => alEditarDocumentoDetalle(docDetalle),
                estado: true,
                mensaje: <Texto id="alerta.confirmacionEdicion" />,
                mostrarCancelar: true,
                tipoDialogo: 'Advertencia',
                titulo: <Texto id="titulo.advertencia" />,
            });
        }
    };

    const alEditarDocumentoDetalle = async (docDetalle: IDocumentoDetalleCompuesto) => {
        if (tipoFormulario === TipoFormulario.Creacion) {
            manejadorDialogoGlobal({ estado: false });
            docDetalle.DocumentoId = documento ? documento.DocumentoId : '';
            docDetalle.DocumentoDetalleId = '';
            await documentoDetallesCM.guardarDocumentosDetalles(docDetalle);
        }
        if (tipoFormulario === TipoFormulario.Edicion) {
            manejadorDialogoGlobal({ estado: false });
            await documentoDetallesCM.editarDocumentosDetallesParcial(docDetalle);
        }
        refrescarDocumentoDetalle(true);
        CerrarFormularioDocumentoDetalle();
    };

    const alEditarAlmacenamientoDetalle = (docDetalle: IDocumentoDetalleCompuesto) => {
        manejadorDialogoGlobal({ estado: false });
        documentoDetallesCM.editarDocumentosDetallesParcial(docDetalle).then(() => {
            alCerrarFormulario();
            dispatch({ type: acciones.CARGAR_DOCUMENTO_DETALLE });
        });
    };

    const CerrarFormularioDocumentoDetalle = () => {
        refrescarDocumentoDetalle(true);
        cerrarModal();
        reiniciar();
    };

    return (
        <FormularioEdicionDetalleAlmacenamientoVista
            alCambiarValor={alCambiarValor}
            //alEditarDocumentoDetalle={alEditarDocumentoDetalle}
            alEditarDocumentoDetalle={confirmarEdicionAlmacenamientoDetalle}
            documentoDetalle={valor}
            divisiones={divisiones}
            estadoFormulario={abierto}
            esMovil={esMovil}
            CerrarFormularioDocumentoDetalle={CerrarFormularioDocumentoDetalle}
            tipoFormulario={tipoFormulario}
        />
    );
};

export default FormularioEdicionDetalleAlmacenamientoCV;
