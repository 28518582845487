import Observador from '@infotrack/presentacion-utilitarios/emisorEventos';
import React from 'react';
import IndicadorProgresoGlobalVista from '../vista/indicadorCargaGlobalVista';

const IndicadorCargaGlobalCV = () => {
    const [llamados, setLLamados] = React.useState<any>({ referencia: '', estado: false });
    const [contador, setContador] = React.useState<number>(1);
    const cargaInicial = React.useRef(true);

    React.useEffect(() => {
        return () => {
            Observador.eliminarObservador('MANEJAR_INDICADOR_CARGA');
        };
    }, []);

    React.useEffect(() => {
        if (!llamados.estado && contador - 1 < 0) return;
        setContador(llamados.estado ? contador + 1 : contador - 1);
    }, [llamados]);

    const manejarEstadoIndicador = (nuevoEstado: boolean) => {
        setTimeout(() => {
            setLLamados({ referencia: Date.now(), estado: nuevoEstado });
        });
    };

    if (cargaInicial.current) {
        Observador.agregarObservador('MANEJAR_INDICADOR_CARGA', manejarEstadoIndicador);
        cargaInicial.current = false;
    }

    return <IndicadorProgresoGlobalVista estado={contador > 0} />;
};

export default IndicadorCargaGlobalCV;
