import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import IDocumentoDetalleCompuesto from '../../../modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import { Card, CardContent, Grid, MenuItem, TextField, Theme } from '@material-ui/core';
import Texto from '@infotrack/presentacion-componentes/texto';
import { ListSubheader, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles({
    listbox: {
        padding: '8px',
        '&:last-child': {
            paddingBottom: '8px',
        },
    },
    tarjetaDocumentoDetalle: {
        maxHeight: '400px',
        overflowY: 'auto',
    },
    tarjetaDocumentoDetalleSelecciona: {
        backgroundColor: /*'#fafafa',*/ '#039BE5' /*, //'#F6891f'*/,
    },
});

interface IInputsDocumentoDetalle {
    filtro: string;
}

interface ITablaDocumentoDetallesProps {
    documentoDetalles: IDocumentoDetalleCompuesto[];
    documentoDetalleSeleccionado?: Partial<IDocumentoDetalleCompuesto>;
    setDocumentoDetalleSeleccionado: (documentoSeleccionado: IDocumentoDetalleCompuesto) => void;
    //documentoSeleccionadoRefrescar: IDocumentoDetalleCompuesto;
}

const VistaDocumentoDetalles: React.FunctionComponent<ITablaDocumentoDetallesProps> = ({
    documentoDetalles,
    documentoDetalleSeleccionado,
    setDocumentoDetalleSeleccionado,
    //documentoSeleccionadoRefrescar,
}) => {
    const estilo = useStyles();

    const { alCambiarValor, valor } = useInputState<IInputsDocumentoDetalle>({ filtro: '' });

    return (
        <div>
            <Card>
                <CardContent className={estilo.listbox}>
                    <TextField
                        label={<Texto id="cardDocumentoDetalle.Busqueda" display="inline" />}
                        name="filtro"
                        onChange={alCambiarValor}
                        value={valor.filtro}
                        variant="outlined"
                        fullWidth
                    />
                </CardContent>
            </Card>
            <Card>
                <CardContent className={estilo.listbox}>
                    <Grid container spacing={1} className={estilo.tarjetaDocumentoDetalle}>
                        {documentoDetalles
                            .filter(
                                (documentoDetalle) =>
                                    (documentoDetalle.DescripcionItem
                                        ? documentoDetalle.DescripcionItem.toUpperCase().includes(
                                              valor.filtro.toUpperCase()
                                          )
                                        : true) ||
                                    (documentoDetalle.CodigoBarras
                                        ? documentoDetalle.CodigoBarras.includes(valor.filtro)
                                        : true)
                            )
                            .map((documentoDetalle) => (
                                <Grid item key={documentoDetalle.DocumentoDetalleId} xs={12}>
                                    <Card
                                        className={
                                            documentoDetalleSeleccionado &&
                                            documentoDetalle.DocumentoId === documentoDetalleSeleccionado.DocumentoId &&
                                            documentoDetalle.ItemId === documentoDetalleSeleccionado.ItemId &&
                                            documentoDetalle.Lote === documentoDetalleSeleccionado.Lote &&
                                            documentoDetalle.Serial ===
                                                documentoDetalleSeleccionado.Serial /*&&
                                            documentoDetalle.CantidadRecibida ===
                                                documentoDetalleSeleccionado.CantidadRecibida*/
                                                ? estilo.tarjetaDocumentoDetalleSelecciona
                                                : ''
                                        }
                                        variant="outlined"
                                        onClick={() => {
                                            setDocumentoDetalleSeleccionado(documentoDetalle);
                                        }}
                                    >
                                        <CardContent className={estilo.listbox}>
                                            <Grid alignItems="flex-start" container justifyContent="space-between" spacing={0}>
                                                <Grid item xs={12}>
                                                    <Texto
                                                        mensaje={documentoDetalle.DescripcionItem}
                                                        variant="body1"
                                                        fontWeight="bold"
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Texto
                                                        id="documentoDetalle.CodigoBarras"
                                                        mensaje=": "
                                                        variant="body2"
                                                        display="inline"
                                                        fontWeight="bold"
                                                    />

                                                    <Texto
                                                        variant="body2"
                                                        mensaje={documentoDetalle.CodigoBarras}
                                                        display="inline"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Texto
                                                        id="documentoDetalle.cantidadSolicitada"
                                                        mensaje=": "
                                                        variant="body2"
                                                        display="inline"
                                                        fontWeight="bold"
                                                    />
                                                    <Texto
                                                        mensaje={documentoDetalle.CantidadSolicitada}
                                                        variant="body2"
                                                        display="inline"
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Texto
                                                        id="documentoDetalle.cantidadRecibida"
                                                        mensaje=": "
                                                        variant="body2"
                                                        display="inline"
                                                        fontWeight="bold"
                                                    />
                                                    <Texto
                                                        mensaje={documentoDetalle.CantidadRecibida}
                                                        variant="body2"
                                                        display="inline"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

export default injectIntl(VistaDocumentoDetalles);
