import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';
import ModeloTipoDocumento from 'Infotrack@Modelos/negocioRefactor/modelos/Administracion/ModeloTipoDocumento';
import ModeloBodegas from 'Infotrack@Modelos/smartStock/bodegas';
import ModeloClientes from 'Infotrack@Modelos/smartStock/clientes';
import ModeloEtapas from 'Infotrack@Modelos/smartStock/etapas';
import ModeloProveedores from 'Infotrack@Modelos/smartStock/proveedores';

class EntidadesDocumentoCM {
    private modeloTipoDocumento: ModeloTipoDocumento;
    private modeloEtapa: ModeloEtapas;
    private modeloProveedor: ModeloProveedores;
    private modeloBodega: ModeloBodegas;
    private modeloClientes: ModeloClientes;

    constructor() {
        this.modeloTipoDocumento = new ModeloTipoDocumento();
        this.modeloEtapa = new ModeloEtapas();
        this.modeloProveedor = new ModeloProveedores();
        this.modeloBodega = new ModeloBodegas();
        this.modeloClientes = new ModeloClientes();
    }

    public async consultarEntidadesDocumento(
        idEmpresa: string,
        idAgencia: string,
        idUsuario: string,
        estado?: number
    ) {
        const respuestas = await Promise.all([
            this.modeloEtapa.ConsultarListaEtapasPorFiltro({ EmpresaId: idEmpresa, Estado: estado }),
            this.modeloProveedor.consultarListaProveedoresPorFiltro({ EmpresaId: idEmpresa, Estado: estado }),
            this.modeloBodega.consultarBodegasPersonasDoc({
                AgenciaId: idAgencia,
                UsuarioId: idUsuario,
                EmpresaId: idEmpresa,
                Estado: estado,
            }),
            this.modeloClientes.consultarClientesPorFiltro({
                EmpresaId: idEmpresa,
                Estado: estado
            }),
        ]);
        return {
            etapas: respuestas[0].data.Entidades,
            proveedores: respuestas[1].data.Entidades,
            bodegasDestino: respuestas[2].data.Entidades,
            clientes: respuestas[3].data.Entidades,
        };
    }

    public async consultarEntidadesDocumentoEdicion(
        idBodega: number,
        idEmpresa: string,
        idAgencia: string,
        idUsuario: string,
        tipoProcesoId: number,
        estado?: number
    ) {
        const respuestas = await Promise.all([
            this.modeloTipoDocumento.consultarTiposDocumentoFiltro({
                BodegaId: idBodega,
                Estado: estado,
                TipoProcesoId:tipoProcesoId
            }),
            this.modeloEtapa.ConsultarListaEtapasPorFiltro({ EmpresaId: idEmpresa, Estado: estado }),
            this.modeloProveedor.consultarListaProveedoresPorFiltro({ EmpresaId: idEmpresa, Estado: estado }),
            this.modeloBodega.consultarBodegasPersonasDoc({
                AgenciaId: idAgencia,
                UsuarioId: idUsuario,
                EmpresaId: idEmpresa,
                Estado: estado,
            }),
            this.modeloClientes.consultarClientesPorFiltro({
                EmpresaId: idEmpresa,
                Estado: estado,
            }),
        ]);
        return {
            tiposDocumento: respuestas[0].data.Entidades,
            etapas: respuestas[1].data.Entidades,
            proveedores: respuestas[2].data.Entidades,
            bodegasDestino: respuestas[3].data.Entidades,
            clientes: respuestas[4].data.Entidades,
        };
    }

    public async consultarTipoDocumento(idBodega: number, estado?: number) {
        const respuestas = await Promise.all([
            this.modeloTipoDocumento.consultarTiposDocumentoFiltro({
                BodegaId: idBodega,
                TipoProcesoId: TiposProceso.ENTRADA_POR_DEVOLUCION,
                Estado: estado
            }),
        ]);
        return respuestas[0].data.Entidades;
    }
}

export default EntidadesDocumentoCM;
