export default {
  'permiso.almacenamiento': 'Access',
  'permiso.auditoriasinventario': 'Access',
  'permiso.banco': 'Access',
  'permiso.bodegastipospropiedades': 'Access',
  'permiso.clasestecnicas': 'Access',
  'permiso.clasificaciones': 'Access',
  'permiso.clientes': 'Access',
  'permiso.columnas': 'Access',
  'permiso.consultainventario': 'Access',
  'permiso.despachos': 'Access',
  'permiso.divisionestipospropiedades': 'Access',
  'permiso.estadosdetalles': 'Access',
  'permiso.etapas': 'Access',
  'permiso.fabricantes': 'Access',
  'permiso.familias': 'Access',
  'permiso.filas': 'Access',
  'permiso.flujos': 'Access',
  'permiso.flujosetapas': 'Access',
  'permiso.gestionauditorias': 'Access',
  'permiso.gestionbodegas': 'Access',
  'permiso.gestioncompras': 'Access',
  'permiso.gestionmovimientos': 'Access',
  'permiso.gestionpedidos': 'Access',
  'permiso.gestionproducto': 'Access',
  'permiso.grupoactividadeconomica': 'Access',
  'permiso.marcas': 'Access',
  'permiso.modulo': 'Access',
  'permiso.monitorpedidos': 'Access',
  'permiso.pais': 'Access',
  'permiso.perfil': 'Access',
  'permiso.pisos': 'Access',
  'permiso.procesopedidos': 'Access',
  'permiso.productos': 'Access',
  'permiso.proveedores': 'Access',
  'permiso.recepcion': 'Access',
  'permiso.referencias': 'Access',
  'permiso.resolucionesfacturaciones': 'Access',
  'permiso.tipocontribuyente': 'Access',
  'permiso.tipocuentabancaria': 'Access',
  'permiso.tipoidentificacion': 'Access',
  'permiso.tiporegimen': 'Access',
  'permiso.tiposbodegas': 'Access',
  'permiso.tiposclientes': 'Access',
  'permiso.tiposdivisiones': 'Access',
  'permiso.tiposdocumentos': 'Access',
  'permiso.tiposmovimientos': 'Access',
  'permiso.tiposociedad': 'Access',
  'permiso.tiposunidades': 'Access',
  'permiso.traslados': 'Access',
  'permiso.unidades': 'Access',
  'permiso.usuario': 'Access',
  'permiso.monitorinventario': 'Access',
  'permiso.devolucionproveedor': 'Access',
  'permiso.historicoinventario': 'Access',
  'permiso.reportegeneral': 'Access',
  'permiso.gestionoperador': 'Access',
};
