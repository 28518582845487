const mensajes = {
    'sidebar.pedidos': 'Pedidos',
    'sidebar.gestionpedidos': 'Gestión pedidos',
    'gestionPedidos.NumeroDocumento': 'N° Documento',
    'gestionPedidos.TipoDocumento': 'Tipo documento',
    'gestionPedidos.FechaCreacionDocumento': 'Fecha inicial',
    'gestionPedidos.FechaVencimientoDocumento': 'Fecha final',
    'gestionPedidos.PersonaCreacion': 'Personas asociadas',
    'gestionPedidos.EstadoDocumento': 'Estado documento',
    'gestionPedidos.Bodega': 'Bodega',
    'gestionPedidos.Cliente': 'Cliente',
    'gestionPedidos.documento.CodigoDocumento': 'Código',
    'gestionPedidos.documento.DocumentoRelacionado': 'Documento relacionado',
    'gestionPedidos.documento.DescripcionProveedor': 'Proveedor',
    'gestionPedidos.documento.FechaCreacion': 'Fecha creación',
    'gestionPedidos.documento.EtapaDescripcion': 'Etapa',
    'gestionPedidos.documento.Observacion': 'Observación',
    'gestionPedidos.documento.DocumentoPadreId': 'Documento padre',
    'gestionPedidos.documento.DocumentoPadre': '¿Es documento padre?',
    'gestionPedidos.documento.crearDocumento': 'Crear documento',
    'gestionPedidos.documento.editarDocumento': 'Editar documento',
    'gestionPedidos.documento.BodegaOrigen': 'Bodega origen',
    'gestionPedidos.documentoDetalle.DescripcionItem': 'Descripción ítem',
    'gestionPedidos.documentoDetalle.CodigoBarras': 'Código de barras',
    'gestionPedidos.documentoDetalle.CantidadSolicitada': 'Cantidad solicitada',
    'gestionPedidos.documentoDetalle.DivisionOrigen': 'División de origen',
    'gestionPedidos.documento.datosDocumento': 'Datos documento',
    'gestionPedidos.documentoDetalle.documentoDetalles': 'Detalles del documento',
    'gestionPedidos.documento.DescripcionCliente': 'Cliente',
    'gestionPedidos.documentoDetalle.crearDetalleDocumento': 'Crear detalle',
    'gestionPedidos.documentoDetalle.editarDetalleDocumento': 'Editar detalle',
    'gestionPedidos.alerta.fechaInvalida': 'La fecha final debe ser mayor a la fecha inicial',
    'gestionPedidos.alerta.fechaFaltante': 'Ingrese las dos fechas para poder realizar la búsqueda',
    'gestionPedidos.alerta.cantidadInsuficiente':
        'La bodega/división no contiene la cantidad solicitada. Cantidad actual:',
    'gestionPedidos.alerta.faltaBodegaOrigen': 'Por favor seleccione los campos requeridos del documento',
    'gestionPedidos.alerta.confirmarCambioBodega':
        '¿Está seguro que desea cambiar la bodega?, se eliminaran los detalles agregados',
    'gestionPedidos.alerta.detalleEnAlistamiento':
        'El producto ya se encuentra en alistamiento, si desea editarlo solicite que la modificación de la cantidad alistada',

    // ModalPedido
    'ModalPedido.TituloCreacion': 'Crear pedido',
    'ModalPedido.TituloEdicion': 'Editar pedido',
    'ModalPedido.TituloTablaDetalles': 'Detalles pedido',
};

export default mensajes;
