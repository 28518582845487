// Autenticación

export const CERRAR_SESION_AUTENTICACION = 'CERRAR_SESION_AUTENTICACION';
export const ESTABLECER_MENUS = 'ESTABLECER_MENUS';
export const ESTABLECER_PERMISOS = 'ESTABLECER_PERMISOS';
export const ESTABLECER_USUARIO_INFORMACION = 'ESTABLECER_USUARIO_INFORMACION';
export const SELECCIONAR_AGENCIA = 'SELECCIONAR_AGENCIA';
export const SELECCIONAR_USUARIO_APLICACION_AGENCIA = 'SELECCIONAR_USUARIO_APLICACION_AGENCIA';

// Configuración

export const CERRAR_SESION_CONFIGURACION = 'CERRAR_SESION_CONFIGURACION';
export const SELECCIONAR_LENGUAJE = 'SELECCIONAR_LENGUAJE';
export const SELECCIONAR_MENU = 'SELECCIONAR_MENU';
export const SELECCIONAR_RUTA_ACTIVA = 'SELECCIONAR_RUTA_ACTIVA';
export const SELECCIONAR_SUBMENU = 'SELECCIONAR_SUBMENU';
export const SELECCIONAR_TEMA = 'SELECCIONAR_TEMA';
