import { Dialog, DialogContent, Grid } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import IflujoEtapaDocumentoCompuesto from 'Infotrack@Modelos/smartStock/flujoEtapaDocumento/entidades/flujoEtapaDocumentoCompuesto';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { EstadosFlujo, EstadosFlujoEtapasFormulario, EstadosModalFlujos } from '../enumeraciones';
import FlujoFormulario from '../flujoEtapasModal/FlujoFormulario';
import IFlujoEtapaDocumento from '../../../../modelos/smartStock/flujoEtapaDocumento/entidades/flujoEtapaDocumento';
import FlujoEtapaDocumentoCM from '../controladorModelo/flujoEtapaDocumentoCM';
import IFlujo from '../../../../modelos/negocioRefactor/entidades/repositorio/IFlujo';
import FlujoCM from '../controladorModelo/flujosCM';
import EtapaCM from '../controladorModelo/etapasCM';
import FlujoEtapasFormulario from './FlujoEtapaFormulario';
import IEtapa from '../../../../modelos/smartStock/etapas/entidades/etapas';
import FlujoEtapasTabla from './FlujoEtapasTabla';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';
import flujosEtapas from 'Infotrack@Transversales/internacionalizacion/idiomas/es_ES/flujosEtapas';
import { Console } from 'console';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';

interface IModalFlujoProps {
    cerrar: () => void;
    estado: EstadosModalFlujos;
    flujoEdicion: IFlujo;
    recargarTabla: () => Promise<void>;
}

const ModalFlujo: FunctionComponent<IModalFlujoProps> = ({ cerrar, estado, flujoEdicion, recargarTabla }) => {
    const flujoEtapaDocumentoCM = useMemo(() => new FlujoEtapaDocumentoCM(), []);
    const flujoCM = useMemo(() => new FlujoCM(), []);
    const etapaCM = useMemo(() => new EtapaCM(), []);
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const [estadoFormularioFlujo, setEstadoFormularioFlujo] = useState<EstadosFlujo>(EstadosFlujo.VISUALIZACION);
    const [estadoFlujoEtapasFormulario, setFlujoEtapasFormulario] = useState<EstadosFlujoEtapasFormulario>(
        EstadosFlujoEtapasFormulario.CREACION
    );
    const [etapas, setEtapas] = useState<IEtapa[]>([]);
    const [flujo, setflujo] = useState<IFlujo>({ Estado: 1, FlujoDescripcion: '', FlujoId: 0 });
    const [refrescarCampos, setRefrescarCampos] = useState<boolean>(false);
    const [flujoEtapaDocumentoCompuesto, setflujoEtapaDocumentoCompuesto] = useState<IflujoEtapaDocumentoCompuesto[]>(
        []
    );
    let estadoInicialFlujoDocumento: IFlujoEtapaDocumento = {
        Estado: 1,
        EtapaActual: 0,
        EtapaSiguiente: 0,
        FlujoEtapaDocumentoId: 0,
        FlujoId: 0,
    };
    const [flujoEtapaDocumento, SetFlujoEtapaDocumento] = useState<IFlujoEtapaDocumento>(estadoInicialFlujoDocumento);

    useEffect(() => {
        SetFlujoEtapaDocumento((flujoEtapasActual) => ({
            ...flujoEtapasActual,
            FlujoId: flujo.FlujoId,
        }));
    }, [flujo]);

    useEffect(() => {
        consultarListaEtapas();
        if (estado === EstadosModalFlujos.CREACION) {
            setEstadoFormularioFlujo(EstadosFlujo.CREACION);
            SetFlujoEtapaDocumento((flujoEtapasActual) => ({
                ...flujoEtapasActual,
                EtapaActual: etapas.find((x) => {
                    if (x.EtapaDescripcion === 'Creado') {
                        return x.EtapaId;
                    }
                })!.EtapaId!,
            }));
        }
        if (estado === EstadosModalFlujos.EDICION) {
            setEstadoFormularioFlujo(EstadosFlujo.EDICION);
        }
    }, [estado]);

    useEffect(() => {
        if (flujoEdicion) {
            setflujo(flujoEdicion);
            SetFlujoEtapaDocumento((flujoEtapasActual) => ({
                ...flujoEtapasActual,
                FlujoId: flujoEdicion.FlujoId,
            }));
            consultarFlujoEtapasCompuestoEdicion(flujoEdicion.FlujoId);
        }
    }, [flujoEdicion]);

    const editarFlujoEtapaDocumento = async (flujoEtapa: IFlujoEtapaDocumento) => {
        if (flujoEtapa.EtapaActual !== 0 && flujoEtapa.EtapaSiguiente !== 0) {
            const respuesta = await flujoEtapaDocumentoCM.editarFlujoEtapaDocumento(flujoEtapa);
            if (respuesta.Resultado) {
                await consultarFlujoEtapasCompuesto();
                setFlujoEtapasFormulario(EstadosFlujoEtapasFormulario.CREACION);
                setRefrescarCampos((refrescarCamposActual) => !refrescarCamposActual);
                SetFlujoEtapaDocumento((flujoDocumentoEtapa) => ({
                    ...flujoDocumentoEtapa,
                    EtapaSiguiente: 0,
                    EtapaActual: 0,
                }));
            }
        }
    };

    const eliminarFlujoEtapaDocumento = async (flujoEtapa: IFlujoEtapaDocumento) => {
        if (validarEtapaCreadaEliminacion(flujoEtapa)) {
            const respuesta = await flujoEtapaDocumentoCM.eliminarFlujoEtapaDocumento(flujoEtapa);
            await consultarFlujoEtapasCompuesto();
            if (respuesta.data.Resultado) {
                setRefrescarCampos(true);
            }
        }
    };

    const validarEtapaCreadaEliminacion = (flujoEtapa: IFlujoEtapaDocumento) => {
        let esEtapaCreada = etapas.some((x) => {
            if (x.EtapaId === flujoEtapa.EtapaActual && x.EtapaDescripcion === 'Creado') {
                return true;
            }
        });
        let etapasInicialCreada = flujoEtapaDocumentoCompuesto.filter(
            (x) =>
                esEtapaCreada &&
                x.EtapaActualDescripcion === 'Creado' &&
                x.Estado === 1 &&
                x.FlujoEtapaDocumentoId !== flujoEtapa.FlujoEtapaDocumentoId
        );
        let respuestValidacion = !esEtapaCreada ? true : etapasInicialCreada.length > 0 ? true : false;
        if (!respuestValidacion) {
            notificacionGlobal('flujoEtapa.EtapasInicialCreadaEliminacion', 6000, 'warning', true);
        }
        return respuestValidacion;
    };
    const guardarFlujoEtapaDocumento = async (flujoEtapa: IFlujoEtapaDocumento) => {
        let flujoGuardado: IFlujo = { Estado: 0, FlujoDescripcion: '', FlujoId: 0 };
        if (estadoFormularioFlujo === EstadosFlujo.CREACION) {
            flujoGuardado = await guardarFlujo();
        }
        if (
            estadoFormularioFlujo === EstadosFlujo.CREACION
                ? flujoGuardado.FlujoId !== 0
                : true && flujoEtapaDocumentoCM.validarEntidad(flujoEtapa)
        ) {
            await SetFlujoEtapaDocumento((flujoEtapasActual) => ({
                ...flujoEtapasActual,
                FlujoId: flujoGuardado.FlujoId !== 0 ? flujoGuardado.FlujoId : flujo.FlujoId,
            }));
            const respuesta = await flujoEtapaDocumentoCM.guardarFlujoEtapaDocumento(
                MapearFlujoEtapa(
                    flujoEtapaDocumento,
                    flujoGuardado.FlujoId !== 0 ? flujoGuardado.FlujoId : flujo.FlujoId
                )
            );
            if (respuesta.Resultado) {
                await consultarFlujoEtapasCompuesto(
                    flujoGuardado.FlujoId !== 0 ? flujoGuardado.FlujoId : flujo.FlujoId
                );
                setRefrescarCampos((refrescarCamposActual) => !refrescarCamposActual);
            }
        }
    };

    const guardarFlujo = async () => {
        let respuesta: IFlujo[] = [];
        if (flujoCM.validarEntidadCreacionCompuesta(flujo, flujoEtapaDocumento)) {
            respuesta = await flujoCM.crearFlujos(MapearFlujo(flujo));
            if (respuesta.length > 0) {
                await setEstadoFormularioFlujo(EstadosFlujo.EDICION);
                await SetFlujoEtapaDocumento((flujoEtapasActual) => ({
                    ...flujoEtapasActual,
                    FlujoId: respuesta[0].FlujoId,
                }));
                await SetFlujoEtapaDocumento((flujoEtapasActual) => ({
                    ...flujoEtapasActual,
                    FlujoId: respuesta[0].FlujoId,
                }));
                await setflujo(respuesta[0]);
            }
            await recargarTabla();
        }
        return respuesta[0];
    };

    const editarFlujo = async (flujo: IFlujo) => {
        if (flujoCM.validarEntidad(flujo)) {
            const respuesta = await flujoCM.editarFlujos(flujo);
            await SetFlujoEtapaDocumento((flujoEtapasActual) => ({
                ...flujoEtapasActual,
                FlujoId: flujo.FlujoId,
            }));
            await setEstadoFormularioFlujo(EstadosFlujo.EDICION);
            await consultarFlujoEtapasCompuesto();
            await recargarTabla();
        }
    };

    const consultarListaEtapas = async () => {
        const respuesta = await etapaCM.consultarListaEtapa(idEmpresa, estadoActivo);
        if (respuesta.length > 0) setEtapas(respuesta);
    };

    const MapearFlujo = (flujo: IFlujo): IFlujo => ({
        ...flujo,
        FlujoId: flujo.FlujoId,
        Estado: flujo.Estado,
        AgenciaId: idAgencia,
        EmpresaId: idEmpresa,
    });

    const MapearFlujoEtapa = (flujoEtapa: IFlujoEtapaDocumento, idFlujo: number): IFlujoEtapaDocumento => ({
        ...flujoEtapa,
        AgenciaId: idAgencia,
        EmpresaId: idEmpresa,
        FlujoId: idFlujo,
    });

    const consultarFlujoEtapasCompuesto = async (idFlujo?: number) => {
        const respuesta = await flujoEtapaDocumentoCM.consultarListaFlujoEtapaDocumentoComuesto({
            ...flujoEtapaDocumento,
            EmpresaId: idEmpresa,
            FlujoId: idFlujo !== undefined ? idFlujo : flujoEtapaDocumento.FlujoId,
        });
        setflujoEtapaDocumentoCompuesto(respuesta);
    };

    const consultarFlujoEtapasCompuestoEdicion = async (idFlujo: number) => {
        const respuesta = await flujoEtapaDocumentoCM.consultarListaFlujoEtapaDocumentoComuesto({
            ...flujoEtapaDocumento,
            FlujoId: idFlujo,
            EmpresaId: idEmpresa
        });
        setflujoEtapaDocumentoCompuesto(respuesta);
    };

    const cerrarModal = () => {
        cerrar();
        setflujoEtapaDocumentoCompuesto([]);
        SetFlujoEtapaDocumento(estadoInicialFlujoDocumento);
        setflujo({ Estado: 0, FlujoDescripcion: '', FlujoId: 0 });
        setFlujoEtapasFormulario(EstadosFlujoEtapasFormulario.CREACION);
    };

    const manejarCambioCampo = async (nombreCampo: keyof IFlujo, nuevoValor: IFlujo[keyof IFlujo]) => {
        setflujo((flujo) => ({ ...flujo, [nombreCampo]: nuevoValor }));
    };

    const manejarCambioCampoFlujoEtapa = async (
        nombreCampo: keyof IFlujoEtapaDocumento,
        nuevoValor: IFlujoEtapaDocumento[keyof IFlujoEtapaDocumento],
        idFlujoEtapaDocumento: number
    ) => {
        const idEtapaCreada = etapas.find((x) => x.EtapaDescripcion === 'Creado')!.EtapaId;
        const validacionCampoEtapaInicial =
            nombreCampo === 'EtapaActual' &&
            estadoFlujoEtapasFormulario === EstadosFlujoEtapasFormulario.EDICION &&
            nuevoValor !== idEtapaCreada
                ? validacionEdicionCampoEtapaInicialCreado(idFlujoEtapaDocumento)
                : true;

        const validacionCampoEstado =
            nombreCampo === 'Estado' &&
            estadoFlujoEtapasFormulario === EstadosFlujoEtapasFormulario.EDICION &&
            nuevoValor === 0
                ? validacionEdicionCampoEstadoCreado(idFlujoEtapaDocumento)
                : true;
        if (validacionCampoEtapaInicial && validacionCampoEstado) {
            SetFlujoEtapaDocumento((flujoDocumentoEtapa) => ({
                ...flujoDocumentoEtapa,
                [nombreCampo]: nuevoValor,
            }));
        } else {
            setFlujoEtapasFormulario(EstadosFlujoEtapasFormulario.CREACION);
            SetFlujoEtapaDocumento((flujoDocumentoEtapa) => ({
                ...flujoDocumentoEtapa,
                EtapaSiguiente: 0,
                EtapaActual: 0,
            }));
        }
    };

    const validacionEdicionCampoEtapaInicialCreado = (idFlujoEtapaDocumento: number) => {
        const flujoEtapaActualCreado = flujoEtapaDocumentoCompuesto.filter(
            (x) => x.FlujoEtapaDocumentoId === idFlujoEtapaDocumento && x.EtapaActualDescripcion === 'Creado'
        );
        const validacion =
            flujoEtapaActualCreado.length > 0
                ? flujoEtapaDocumentoCompuesto.filter((x) => x.EtapaActualDescripcion === 'Creado').length > 1
                : true;
        if (!validacion) {
            notificacionGlobal('flujoEtapa.EtapasInicialCreadaEdicion', 6000, 'warning', true);
        }
        return validacion;
    };

    const validacionEdicionCampoEstadoCreado = (idFlujoEtapaDocumento: number) => {
        const flujoEtapaActualCreado = flujoEtapaDocumentoCompuesto.filter(
            (x) => x.FlujoEtapaDocumentoId === idFlujoEtapaDocumento && x.EtapaActualDescripcion === 'Creado'
        );
        const validacion =
            flujoEtapaActualCreado.length > 0
                ? flujoEtapaDocumentoCompuesto.filter((x) => x.EtapaActualDescripcion === 'Creado' && x.Estado === 1)
                      .length > 1
                : true;
        if (!validacion) {
            notificacionGlobal('flujoEtapa.EtapasInicialCreadaEstado', 6000, 'warning', true);
        }
        return validacion;
    };

    return (
        <Dialog open={estado !== EstadosModalFlujos.CERRADO} maxWidth="lg" fullWidth>
            <ModalEncabezado cerrar={cerrarModal} titulo={<Texto id="sidebar.flujoetapas" />} />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FlujoFormulario
                            EditarFlujo={editarFlujo}
                            estado={estadoFormularioFlujo}
                            guardarFlujo={guardarFlujo}
                            flujoEdicion={flujo}
                            manejarCambioCampo={manejarCambioCampo}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FlujoEtapasFormulario
                            editarEtapas={editarFlujoEtapaDocumento}
                            etapas={etapas}
                            flujoDocumento={flujoEtapaDocumento}
                            guardarEtapas={guardarFlujoEtapaDocumento}
                            estado={estadoFlujoEtapasFormulario}
                            refrescarCampos={refrescarCampos}
                            manejarCambioCampoFlujoEtapa={manejarCambioCampoFlujoEtapa}
                            estadoFormularioFlujo={estadoFormularioFlujo}
                        />
                    </Grid>
                    {Boolean(flujoEtapaDocumento.FlujoId) && (
                        <Grid item xs={12}>
                            <FlujoEtapasTabla
                                editarFlujoEtapas={(flujoEtapaEdicion: IFlujoEtapaDocumento) => {
                                    SetFlujoEtapaDocumento(flujoEtapaEdicion);
                                    setFlujoEtapasFormulario(EstadosFlujoEtapasFormulario.EDICION);
                                }}
                                eliminarFlujoEtapas={eliminarFlujoEtapaDocumento}
                                listaFlujoEtapas={flujoEtapaDocumentoCompuesto}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ModalFlujo;
