import { TextField } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { Autocomplete } from '@material-ui/lab';

import Texto from '@infotrack/presentacion-componentes/texto';

import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';

interface ICampoBodegaIdProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampo: keyof IAuditoriaCompuesta,
        nuevoValor: IAuditoriaCompuesta[keyof IAuditoriaCompuesta]
    ) => any;
    bodega: IBodega[];
    valor: number;
}

const CampoBodegaId: FunctionComponent<ICampoBodegaIdProps> = ({
    deshabilitado,
    manejarCambioCampo,
    bodega,
    valor,
}) => (
    <Autocomplete
        disabled={deshabilitado}
        getOptionLabel={(b) => b.BodegaDescripcion}
        onChange={(_: ChangeEvent<{}>, bodega: IBodega | null) =>
            manejarCambioCampo('BodegaId', bodega ? bodega.BodegaId : 0)
        }
        options={bodega}
        renderInput={(params) => (
            <TextField {...params} fullWidth label={<Texto id="Auditoria.CampoBodegaId.Nombre" />} required />
        )}
        value={bodega.find((x) => x.BodegaId === valor) || null}
    />
);

export default CampoBodegaId;
