export default {
  'sidebar.procesos': 'Processes',
  'sidebar.almacenamiento': 'Storage',
  'almacenamiento.bodega': 'warehouse',
  'almacenamiento.proveedor': 'Provider',
  'almacenamiento.etapaDescripcion': 'Stage',
  'almacenamiento.cambiarEtapa': 'Change stage',
  'almacenamiento.numeroDocumento': 'N° Document',
  'almacenamiento.confirmarAlmacenamiento': 'Confirm storage',
  'almacenamiento.documentos': 'Documents',
  'almacenamiento.cambiarEstado': 'Change status',
  'almacenamiento.documentoPadre': 'Parent document',
  'almacenamiento.bodegaOrigen': 'Origin warehouse',
  'almacenamiento.observaciones': 'Observations',
  'almacenamiento.documentoRelacionado': 'Related document',
  'almacenamiento.documento.CodigoDocumento': 'Code',
  'almacenamiento.documento.DocumentoRelacionado': 'Related document',
  'almacenamiento.documento.DocumentoPadre': 'Parent document',
  'almacenamiento.documento.DescripcionProveedor': 'Provider',
  'almacenamiento.documento.FechaEntrega': 'Creation date',
  'almacenamiento.documento.EtapaDescripcion': 'Stage',
  'almacenamiento.documento.Observacion': 'Observation',
  'almacenamiento.documentoDetalle.DescripcionItem': 'Product',
  'almacenamiento.documentoDetalle.CodigoBarras': 'Barcode',
  'almacenamiento.documentoDetalle.CantidadSolicitada': 'Requested amount',
  'almacenamiento.documentoDetalle.CantidadRecibida': 'Storage amount',
  'almacenamiento.documentoDetalle.iniciarAlmacenamiento': 'Start storage',
  'almacenamiento.documentoDetalle.confirmarAlmacenamiento': 'Confirm storage',
  'almacenamiento.documentos.verDetalle': 'See detail',
  'almacenamiento.documentos.detalle': 'Detail document',
  'almacenamiento.documentos.alertaFiltroBodega': 'Please select a warehouse',
  'almacenamiento.documentos.alertaFiltroProveedorDocumento': 'Please select a provider or enter a document number',
  'almacenamiento.documentos.alertaLotes': 'This product requires a serial number',
  'almacenamiento.documentos.alertaSeriado': 'This product requires registering a lot number',
  'almacenamiento.documentos.alertaCantidadRecibida': 'The amount received must be greater than previously entered',
  'almacenamiento.documentos.alertaFIFO': 'This product requires an expiration date',
  'almacenamiento.documentosdocumentoDetalle.edicionParcial': 'Partial',
  'almacenamiento.documentosdocumentoDetalle.edicionTotal': 'Total',
  'almacenamiento.documentosdocumentoDetalle.almacenamiento': 'Reception',
  'almacenamiento.documentosdocumentoDetalle.DivisionDestino': 'Destination division',
  'almacenamiento.documentosdocumentoDetalle.DivisionOrigen': 'Origin division',
  'almacenamiento.documentosdocumentoDetalle.CantidadRecibida': 'Amount',
  'almacenamiento.documentosdocumentoDetalle.Lote': 'Lot',
  'almacenamiento.documentosdocumentoDetalle.FechaVencimiento': 'Expiration date',
  'almacenamiento.documentosdocumentoDetalle.Serial': 'Serial',
  'almacenamiento.confirmacionInicioAlmacenamiento': 'Are you sure you want to start receiving?',
  'almacenamiento.confirmacionFinAlmacenamiento': 'Are you sure you want to finish the reception?',
  'almacenamiento.iniciarAlistamiento': '¿Are you sure you want to start enlistment?',
  'almacenamiento.confirmarAlistamiento': '¿Are you sure you want to confirm the enlistment?',
  'almacenamiento.iniciarTransito': '¿Are you sure you want to send in transit?',
  'almacenamiento.enviarAPlataformaDestino': 'Are you sure you want to send to the destination platform?',
  'almacenamiento.iniciarAlmacenamiento': '¿Are you sure you want to start storage?',
  'almacenamiento.confimarAlmacenamiento': '¿Are you sure you want to confirm storage?',
  'CambiarEstado': 'Change status',
};
