import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    contenedorSeriales: {
        overflowY: 'auto',
        maxHeight: '400px',
    },
});

export default useStyles;
