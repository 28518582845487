import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocio } from '../../conexiones';
import ITipoDocumento from './entidades/tipoDocumento';
import ITipoDocumentoCompuesto from './entidades/tipoDocumentoCompuesto';
import ITipoDocumentoFiltros from './entidades/tipoDocumentoFiltros';

export default class ModeloTiposDocumentos {
    private NOMBRE_CONTROLADOR = 'TipoDocumento';

    public consultarListaTiposDocumentos() {
        return manejadorRest<IRespuesta<ITipoDocumento>>(
            () => negocio.get(`${this.NOMBRE_CONTROLADOR}/ConsultarTiposDocumentosPorTiposMovimiento`),
            true
        );
    }

    public ConsultarTipoDocumentoFiltro(filtro: Partial<ITipoDocumento>) {
        return manejadorRest<IRespuesta<ITipoDocumento>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/ConsultarListaFiltro`, filtro),
            true
        );
    }

    public consultarTiposDocumentosCompuestoFiltro(filtro: Partial<ITipoDocumento>) {
        return manejadorRest<IRespuesta<ITipoDocumentoCompuesto>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/ConsultarTiposDocumentosCompuestoFiltro`, filtro),
            true
        );
    }

    public ConsultarListaTiposDocumentosMovimientoFiltro(tipoDocFiltros: ITipoDocumentoFiltros) {
        return manejadorRest<IRespuesta<ITipoDocumentoCompuesto>>(
            () =>
                negocio.post(
                    `${this.NOMBRE_CONTROLADOR}/ConsultarListaTiposDocumentosMovimientoFiltro`,
                    tipoDocFiltros
                ),
            true
        );
    }

    public ConsultarListaTiposDocumentosMovimiento() {
        return manejadorRest<IRespuesta<ITipoDocumento>>(() =>
            negocio.get(`${this.NOMBRE_CONTROLADOR}/ConsultarListaTiposDocumentosMovimiento`)
        );
    }

    public ConsultarListaTiposDocumentosGestion() {
        return manejadorRest<IRespuesta<ITipoDocumento>>(() =>
            negocio.get(`${this.NOMBRE_CONTROLADOR}/ConsultarListaTiposDocumentosGestion`)
        );
    }

    public crearTiposDocumentos(tiposDocumentos: ITipoDocumento) {
        return manejadorRest<IRespuesta<ITipoDocumento>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/GuardarTiposDocumentos`, tiposDocumentos),
            true
        );
    }

    public editarTiposDocumentos(tiposDocumentos: ITipoDocumento) {
        return manejadorRest<IRespuesta<ITipoDocumento>>(
            () => negocio.put(`${this.NOMBRE_CONTROLADOR}/EditarTipoDocumento`, tiposDocumentos),
            true
        );
    }

    public eliminarTiposDocumentos(tiposDocumentos: ITipoDocumento) {
        return manejadorRest<IRespuesta<ITipoDocumento>>(
            () => negocio.delete(`${this.NOMBRE_CONTROLADOR}/EliminarTipoDocumento`, tiposDocumentos),
            true
        );
    }
}
