import { MenuItem, TextField } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';
import IDivision from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDivision';
import { Autocomplete } from '@material-ui/lab';
import IDivisionAuditoria from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IDivisionAuditoria';

interface ICampoDivisionIdProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampo: keyof IAuditoriaCompuesta,
        nuevoValor: IAuditoriaCompuesta[keyof IAuditoriaCompuesta]
    ) => any;
    divisiones: IDivision[];
    divisionesSeleccionadas: IDivisionAuditoria[];
}

const CampoDivisionId: FunctionComponent<ICampoDivisionIdProps> = ({
    deshabilitado,
    manejarCambioCampo,
    divisiones,
    divisionesSeleccionadas,
}) => (
    <Autocomplete
        multiple
        disabled={deshabilitado}
        getOptionLabel={(d: IDivision) => d.DivisionDescripcion}
        onChange={(_: ChangeEvent<{}>, divisiones: IDivision[] | null) => {
            if (divisiones) {
                manejarCambioCampo(
                    'Divisiones',
                    divisiones.map((division) => ({
                        DivisionId: division.DivisionId,
                        DescripcionDivision: division.DivisionDescripcion,
                    }))
                );
            }
        }}
        noOptionsText={<Texto id="Auditoria.SeleccioneBodegaTipoDocumento" />}
        options={divisiones}
        renderInput={(params) => (
            <TextField {...params} fullWidth label={<Texto id="Auditoria.CampoDivisionId.Nombre" /> } />
        )}
        value={divisiones.filter((division) =>
            divisionesSeleccionadas.find(
                (divisionSeleccionada) => divisionSeleccionada.DivisionId === division.DivisionId
            )
        )}
    />
);

export default CampoDivisionId;
