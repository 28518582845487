import React from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';

import { IFormularioGestionBodegasCVProps } from '../interfaces';
import { acciones, IEstadoGestionBodegas } from '../reductorGestionBodegas';
import FormularioGestionBodegasVista from '../vista/formularioGestionBodegasVista';

const FormularioGestionBodegaCV: React.FunctionComponent<IFormularioGestionBodegasCVProps> = () => {
    const [
        { bodegaCreada, estadoFormulario, tipoFormulario, listaDivisionesDocumentosDefecto },
        dispatch,
    ] = useProveedor<IEstadoGestionBodegas>();
    const [indiceTab, setIndiceTab] = React.useState<number>(0);
    const theme = useTheme();
    const esMovil = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        if (estadoFormulario) {
            setTimeout(() => {
                dispatch({ type: acciones.CAMBIAR_ESTADO_PRIMERA_CARGA });
            }, 6000);
            if (tipoFormulario === TipoFormulario.Edicion) {
                dispatch({ type: acciones.RECARGAR_TABLA_BODEGA_DIVISIONES });
                dispatch({ type: acciones.RECARGAR_TABLA_BODEGA_PERSONA });
                dispatch({ type: acciones.RECARGAR_TABLA_DIVISIONES_POR_DEFECTO });
            }
        }
    }, [estadoFormulario]);

    const alCerrarFormulario = () => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => {
                dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
                setIndiceTab(0);
                manejadorDialogoGlobal({ estado: false });
            },
            estado: true,
            mensaje: <Texto id={'gestionBodega.cancelar'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const alCambiarTab = (_: React.ChangeEvent<{}>, siguienteTab: number) => {
        setIndiceTab(siguienteTab);
    };

    const validarDivisionPorDefecto = (): boolean => {
        if (listaDivisionesDocumentosDefecto.some((div) => div.DivisionDefecto === true)) {
            return true;
        } else {
            notificacionGlobal('Se debe marcar una division defecto en los documentos', 6000, 'warning', true);
            return false;
        }
    };

    return (
        <FormularioGestionBodegasVista
            alCambiarTab={alCambiarTab}
            alCerrarFormulario={alCerrarFormulario}
            bodegaCreada={bodegaCreada}
            esMovil={esMovil}
            estado={estadoFormulario}
            indiceTab={indiceTab}
            tipoFormulario={tipoFormulario}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion, agenciaActual },
}: IEstadoGlobal): IFormularioGestionBodegasCVProps => ({
    IdEmpresa: usuarioInformacion!.IdEmpresa,
    IdAgencia: agenciaActual!.IdAgencia,
});

export default connect<IFormularioGestionBodegasCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(FormularioGestionBodegaCV);
