import { createStyles, Theme } from '@material-ui/core';

const estilos = createStyles({
  contenedor: {
    width: 250
  },
  correo: {
    margin: '0 auto',
    padding: '1em 0',
    wordBreak: 'break-all'
  },
  iconosLista: {
    marginRight: '.5em',
  },
});

export default estilos;
