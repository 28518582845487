import { Collapse, List, ListItem, ListItemText, withStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Fragment } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';
import { IMenu, ISubMenu } from '@infotrack/presentacion-modulosbase';

import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';

import { IMenuVistaProps } from '../interfaces';
import estilos from './estilos';

const MenuVista: React.FunctionComponent<PropsEstilosMaterialUI<IMenuVistaProps>> = ({
    classes,
    expanded,
    menus,
    seleccionarMenu,
    seleccionarSubMenu,
    subMenuSeleccionado,
}) => {
    return (
        <List className={classes.contenedorMenu}>
            {menus.map((menu: IMenu) => (
                <Fragment key={'lista' + menu.IdMenu}>
                    <ListItem
                        button
                        divider
                        onClick={() => seleccionarMenu(menu.IdMenu)}
                        key={'menu' + menu.IdMenu}
                        selected={false}
                        className={menu.IdMenu === expanded ? classes.menuSeleccionado : ''}
                    >
                        <ListItemText
                            primary={
                                <Texto
                                    id={menu.TituloMenu}
                                    className={
                                        menu.IdMenu === expanded ? classes.textoMenuSeleccionado : classes.textoMenu
                                    }
                                />
                            }
                        />
                        {menu.IdMenu === expanded ? (
                            <ExpandMoreIcon className={classes.textoMenuSeleccionado} />
                        ) : (
                            <ChevronRightIcon color="disabled" />
                        )}
                    </ListItem>
                    {menu && (
                        <Collapse in={menu.IdMenu === expanded}>
                            <List disablePadding className={classes.menu}>
                                {menu.SubMenu.map((subMenu: ISubMenu) => (
                                    <ListItem
                                        button
                                        dense
                                        key={'submenu' + subMenu.TituloSubMenu}
                                        onClick={() => seleccionarSubMenu(subMenu.RutaSubMenu)}
                                        selected={subMenu.RutaSubMenu === subMenuSeleccionado}
                                    >
                                        <ListItemText
                                            primary={
                                                <Texto
                                                    id={subMenu.TituloSubMenu}
                                                    variant="subtitle2"
                                                    className={classes.textoSubmenus}
                                                    color="textSecondary"
                                                />
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    )}
                </Fragment>
            ))}
        </List>
    );
};

export default withStyles(estilos)(MenuVista);
