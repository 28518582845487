import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';
import ModeloMovimientos from 'Infotrack@Modelos/smartStock/movimientos';
import IMovimientoFechaFiltro from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoFechaFiltro';

export default class MovimientoCM {
    private modeloMovimientos: ModeloMovimientos;

    constructor() {
        this.modeloMovimientos = new ModeloMovimientos();
    }

    public consultarMovimientosSinDocumentoDetalle = (paginador: IPaginador, filtro: IMovimientoFechaFiltro) =>
        this.modeloMovimientos.consultarMovimientosSinDocumentoDetalle({ paginador, filtro }).then(({ data }) => data);
}
