import { seleccionarLenguaje } from 'Infotrack@Acciones/accionesConfiguracionGlobal';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { ILLavesLenguaje } from 'Infotrack@Transversales/internacionalizacion';
import React from 'react';
import { connect } from 'react-redux';
import { IAccionesAPropiedades, IEstadoAPropiedades } from '../interfaces';
import SelectorIdiomaVista from '../vista/selectorIdiomaVista';

const SelectorIdiomaCV: React.FunctionComponent<IAccionesAPropiedades & IEstadoAPropiedades> = ({
  seleccionarLenguaje: seleccionarLenguajeAccion,
  lenguajes,
  lenguajeActivo
}) => {
  const [refElem, setRefElem] = React.useState<HTMLElement | null>(null);

  const abrirSelector = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setRefElem(e.currentTarget);
  };

  const cerrarSelector = () => {
    setRefElem(null);
  };

  const alSeleccionarLenguaje = (lenguaje: ILLavesLenguaje) => {
    cerrarSelector();
    seleccionarLenguajeAccion(lenguaje);
  };

  return (
    <SelectorIdiomaVista
      refElem={refElem}
      abrirSelector={abrirSelector}
      cerrarSelector={cerrarSelector}
      lenguajes={lenguajes}
      lenguajeActivo={lenguajeActivo}
      seleccionarLenguaje={alSeleccionarLenguaje}
    />
  );
};

const estadoAPropiedades = ({
  estadoConfiguracion: { lenguajeActivo, lenguajes },
}: IEstadoGlobal): IEstadoAPropiedades => ({
  lenguajes,
  lenguajeActivo,
});

export default connect<IEstadoAPropiedades, IAccionesAPropiedades, any, IEstadoGlobal>(
  estadoAPropiedades,
  { seleccionarLenguaje }
)(SelectorIdiomaCV);
