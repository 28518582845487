import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';

import AuditoriaConteoDetalleCM from '../controladorModelo/AuditoriaConteoDetalleCM';

import ComparacionConteoDetallesTabla from './ComparacionConteoTabla';

import IAuditoriaConteoDetalleComparacion from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaConteoDetalleComparacion';
import { useQuery } from 'Infotrack@Transversales/utilitarios/funcionesGenerales';

const CAMPOS_OCULTAR_CONTEO_DETALLES_TABLA: Array<keyof IAuditoriaConteoDetalleComparacion> = [
    'CantidadSegundoConteo',
    'DiferenciaStockSegundoConteo',
    'DiferenciaConteos',
];

const ModalConteo = () => {
    const auditoriaConteoDetalleCM = useMemo(() => new AuditoriaConteoDetalleCM(), []);

    const [conteoDetallesComparacion, setConteoDetallesComparacion] = useState<IAuditoriaConteoDetalleComparacion[]>(
        []
    );

    const query = useQuery();
    const idPrimerConteo = useMemo(() => query.get('primerConteo'), []);
    const idSegundoConteo = useMemo(() => query.get('segundoConteo'), []);
    const [refrescarDetalles, setRefrescarDetalles] = useState<boolean>(false);
    const [descripcionPrimerConteo, setDescripcionPrimerConteo] = useState<string>('');
    const [descripcionSegundoConteo, setDescripcionSegundoConteo] = useState<string>('');

    useEffect(() => {
        if (idPrimerConteo && idSegundoConteo) {
            consultarConteoDetalles();
        } else compararConteoUnico();
    }, [idPrimerConteo]);

    useEffect(() => {
        if (refrescarDetalles) {
            setRefrescarDetalles(false);
        }
    }, [refrescarDetalles]);

    const consultarConteoDetalles = async () => {
        const conteoDetallesConsultados = await auditoriaConteoDetalleCM.compararConteos(
            idPrimerConteo!,
            idSegundoConteo!
        );
        setDescripcionPrimerConteo(conteoDetallesConsultados[0].DescripcionPrimerConteo);
        setDescripcionSegundoConteo(conteoDetallesConsultados[0].DescripcionSegundoConteo);
        setConteoDetallesComparacion(conteoDetallesConsultados);
    };

    const compararConteoUnico = async () => {
        const compararConteoUnico = await auditoriaConteoDetalleCM.compararConteoUnico(idPrimerConteo!);
        setDescripcionPrimerConteo(compararConteoUnico[0].DescripcionPrimerConteo);
        setConteoDetallesComparacion(compararConteoUnico);
    };

    const convertirArchivoDescargado = async () => {
        let respuesta: string = '';
        let nombre: string = '';
        if (idSegundoConteo) {
            respuesta = await auditoriaConteoDetalleCM.descargarComparacionConteos(idPrimerConteo!, idSegundoConteo);
            nombre = 'ComparacionConteos.xlsx';
        } else {
            respuesta = await auditoriaConteoDetalleCM.descargarComparacionConteo(idPrimerConteo!);
            nombre = 'ComparacionConteo.xlsx';
        }
        const nombreArchivo = nombre;
        const blob = new Blob([respuesta]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = nombreArchivo;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ComparacionConteoDetallesTabla
                    detallesConteo={conteoDetallesComparacion}
                    recargarTabla={consultarConteoDetalles}
                    camposOcultar={!idSegundoConteo ? CAMPOS_OCULTAR_CONTEO_DETALLES_TABLA : []}
                    descripcionPrimerConteo={descripcionPrimerConteo}
                    descripcionSegundoConteo={descripcionSegundoConteo}
                    descargarConteo={convertirArchivoDescargado}
                />
            </Grid>
        </Grid>
    );
};

export default ModalConteo;
