import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoAccion } from '@infotrack/presentacion-transversales/interfacesComunes';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import IFiltrosDocumentoDetallesAuditoria from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/filtrosDocumentoDetallesAuditoria';
import React from 'react';
import DocumentoDetalleCM from '../controladorModelo/documentoDetalleCM';
import EntidadesDocumentoDetalleCM from '../controladorModelo/entidadesDocumentoDetalleCM';
import { IInputsFiltrosDetallesAuditoria } from '../interfaces';
import { acciones, IEstadoAuditorias } from '../reductorAuditorias';
import TablaDetallesAuditoriasVista from '../vista/tablaDetallesAuditoriasVista';

const documentoDetalleCM = new DocumentoDetalleCM();
const entidadesDocumentoDetalleCM = new EntidadesDocumentoDetalleCM();

const valorInicialFiltros: IInputsFiltrosDetallesAuditoria = {
    DivisionId: '',
    CodigoBarras: '',
    DescripcionItem: '',
};

const TablaDetallesAuditoriasCV: React.FunctionComponent = () => {
    const [{ divisiones, documento, estadoFormulario, recargarTablaDetalles }, dispatch] = useProveedor<
        IEstadoAuditorias
    >();
    const { alCambiarValor, reiniciar, valor } = useInputState(valorInicialFiltros);
    const refTabla = React.createRef<any>();

    React.useEffect(() => {
        if (estadoFormulario) {
            validarDivisionPorDefecto();
            cargarDivisiones();
        }
    }, [estadoFormulario]);

    React.useEffect(() => {
        if (recargarTablaDetalles) {
            cargarDocumentosDetalles();
            dispatch({ type: acciones.RECARGAR_TABLA_DETALLES });
        }
    }, [recargarTablaDetalles]);

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
        dispatch({ type: acciones.CARGAR_DOCUMENTOS_DETALLES, payload: [] });
        reiniciar();
    };

    const mapearFiltros = (): IFiltrosDocumentoDetallesAuditoria => ({
        ...valor,
        DocumentoId: documento ? documento.DocumentoId! : '',
        DivisionId: valor.DivisionId ? Number(valor.DivisionId) : undefined,
    });

    const consultaDetallesAuditoria = paginacionRemota(
        documentoDetalleCM.consultarListaDocumentosDetallesAuditoria,
        mapearFiltros(),
        'DescripcionItem'
    );

    const cargarDocumentosDetalles = async (limpiarFiltros?: boolean) => {
        if (limpiarFiltros) reiniciar();
        refTabla.current!.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
    };

    const cargarDivisiones = async () => {
        dispatch({
            type: acciones.CARGAR_DIVISIONES,
            payload: await entidadesDocumentoDetalleCM.consultarListaDivisiones(documento!.BodegaOrigen!),
        });
    };

    const validarDivisionPorDefecto = () => {
        documentoDetalleCM
            .consultarDivisionDocumentoAuditoria({ DocumentoId: documento!.DocumentoId } as any)
            .then((docDetalle) => {
                dispatch({
                    type: acciones.ESTABLECER_DIVISION_POR_DEFECTO,
                    payload: docDetalle ? docDetalle.DivisionOrigen : undefined,
                });
            });
    };

    const alAbrirFormularioDetalle = (documentoDetalle?: IDocumentoDetalleCompuesto) => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
        dispatch({ type: acciones.ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE, payload: documentoDetalle });
    };

    const confimarEliminacionAuditoria = (documentoDetalle: IDocumentoDetalleCompuesto) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => alEliminarDetalle(documentoDetalle),
            estado: true,
            mensaje: <Texto id="alerta.confirmacionEliminacion" />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const alEliminarDetalle = (documentoDetalle: IDocumentoDetalleCompuesto) => {
        manejadorDialogoGlobal({ estado: false });
        documentoDetalleCM
            .editarDocumentosDetallesAuditoria({
                Documento: documento!,
                DocumentosDetalles: [{ ...documentoDetalle, Accion: TipoAccion.eliminado }],
            })
            .then(() => {
                dispatch({ type: acciones.RECARGAR_TABLA_DETALLES });
            });
    };

    return (
        <TablaDetallesAuditoriasVista
            alCambiarValor={alCambiarValor}
            alAbrirFormularioDetalle={alAbrirFormularioDetalle}
            alCerrarFormulario={alCerrarFormulario}
            alEliminarDetalle={confimarEliminacionAuditoria}
            consultaDetallesAuditoria={consultaDetallesAuditoria}
            consultarDetallesAuditoria={cargarDocumentosDetalles}
            divisiones={divisiones}
            documento={documento}
            estadoFormulario={estadoFormulario}
            refTabla={refTabla}
            filtros={valor}
        />
    );
};

export default TablaDetallesAuditoriasCV;
