import ProveedorEstado from '@infotrack/presentacion-componentes/proveedorEstado';
import React from 'react';
import FormularioConsultaInvenitarioCV from './controladorVista/formularioConsultaInvenitarioCV';
import TablaConsultaInventarioCV from './controladorVista/tablaConsultaInventarioCV';
import { estadoInicial, reductor } from './reductorConsultaInventario';

const ConsultaInventario: React.FunctionComponent = () => (
    <ProveedorEstado estadoInicial={estadoInicial} reductor={reductor}>
        <FormularioConsultaInvenitarioCV />
        <TablaConsultaInventarioCV />
    </ProveedorEstado>
);

export default ConsultaInventario;
