import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloDocumento from 'Infotrack@Modelos/smartStock/documentos';
import IDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/documento';
import IDocumentoConDetalles from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoConDetalle';
import IFiltroGeneralDocumentoConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/filtroGeneralDocumentoConsulta';
import IFiltrosDocumentos from 'Infotrack@Modelos/smartStock/documentos/entidades/filtrosDocumentosMovimientos';
import AbreviaturasTiposDocumento from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/abreviaturasTiposDocumento';
import moment from 'moment';

export default class ControladorModeloDocumento {
    private modeloDocumento: ModeloDocumento;
    constructor() {
        this.modeloDocumento = new ModeloDocumento();
    }
    public consultarDocumentos = (paginador: IPaginador, filtros: IFiltrosDocumentos) =>
        this.modeloDocumento
            .consultarDocumentosMovimientoEntradas({ paginador, documentos: filtros })
            .then(({ data }) => ({
                ...data,
                Entidades: data.Entidades.map((documento) => ({
                    ...documento,
                    FechaCreacion: moment(documento.FechaCreacion).format('DD/MM/YYYY/hh:mm A'),
                    FechaVencimiento: moment(documento.FechaFinal).format('DD/MM/YYYY/hh:mm A'),
                })),
            }));

    public guardarDocumentoConDetalle(documentoConDetalle: IDocumentoConDetalles) {
        return this.modeloDocumento.guardarDocumentoConDetalle(documentoConDetalle);
    }

    public editarDocumento(documento: IDocumento) {
        return this.modeloDocumento.editarDocumento(documento);
    }

    public async consultarGenerarDocumentos(filtroDocumento: IFiltroGeneralDocumentoConsulta) {
        const {
            data: { Entidades },
        } = await this.modeloDocumento.consultaGeneralDocumentosFiltro(filtroDocumento);
        return Entidades[0];
    }

    public cambiarEtapaEntrada = async (documento: IDocumento) => {
        const {
            data: { Entidades },
        } = await this.modeloDocumento.modificarEstadoEntrada(documento);
        return Entidades[0];
    };

    public cambiarEtapaSalida = async (documento: IDocumento) => {
        const {
            data: { Entidades },
        } = await this.modeloDocumento.modificarEstadoSalida(documento);
        return Entidades[0];
    };

    public validarDocumentoAbrirFormulario(documento: IDocumento, tipoDocumento?: string) {
        if (!documento.TipoDocumentoId || !documento.BodegaDestino || !documento.BodegaOrigen) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else if (
            documento.BodegaOrigen &&
            documento.BodegaDestino &&
            documento.BodegaOrigen !== documento.BodegaDestino &&
            tipoDocumento === AbreviaturasTiposDocumento.ALM
        ) {
            notificacionGlobal('gestionmovimientos.alerta.transladoDivisionesInvalido', 6000, 'warning', true);
            return false;
        } else if (
            documento.BodegaOrigen &&
            documento.BodegaDestino &&
            documento.BodegaOrigen === documento.BodegaDestino &&
            tipoDocumento === AbreviaturasTiposDocumento.TRA
        ) {
            notificacionGlobal('gestionmovimientos.alerta.transladoBodegasInvalido', 6000, 'warning', true);
            return false;
        } else return true;
    }

    public validarDocumento(documentoConDetalle: IDocumentoConDetalles, tipoDocumento?: string) {
        if (this.validarDocumentoAbrirFormulario(documentoConDetalle.documento, tipoDocumento)) {
            if (!documentoConDetalle.documento.DocumentoPadre && documentoConDetalle.documentosDetalles.length === 0) {
                notificacionGlobal('gestionmovimientos.alerta.agregarDetalles', 6000, 'warning', true);
                return false;
            } else return true;
        } else return false;
    }

    public validarFiltros({ BodegaConsulta, FechaInicial, FechaFinal }: IFiltrosDocumentos) {
        if (!BodegaConsulta) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else if (FechaInicial || FechaFinal) {
            if (moment(FechaInicial).isSameOrAfter(FechaFinal)) {
                notificacionGlobal('gestionmovimientos.fechaInvalida', 6000, 'warning', true);
                return false;
            }
            if ((FechaInicial && !FechaFinal) || (FechaFinal && !FechaInicial)) {
                notificacionGlobal('gestionmovimientos.fechaIncompleta', 6000, 'warning', true);
                return false;
            }
        }
        return true;
    }
    public anularDocumento = async (documento: IDocumento) => {
        const {
            data: { Entidades },
        } = await this.modeloDocumento.anularDocumento(documento);
        return Entidades[0];
    };
}
