import { MenuItem, TextField } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { Autocomplete } from '@material-ui/lab';

import Texto from '@infotrack/presentacion-componentes/texto';

import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';
import IItem from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IItem';
import IItemAuditoria from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IItemAuditoria';

interface ICampoItemIdProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampor: keyof IAuditoriaCompuesta,
        nuevoValor: IAuditoriaCompuesta[keyof IAuditoriaCompuesta]
    ) => any;
    items: IItem[];
    itemsSeleccionados: IItemAuditoria[];
}

const CampoItemId: FunctionComponent<ICampoItemIdProps> = ({
    deshabilitado,
    manejarCambioCampo,
    items,
    itemsSeleccionados,
}) => (
    <Autocomplete
        multiple
        disabled={deshabilitado}
        getOptionLabel={(d: IItem) => d.DescripcionItem}
        onChange={(_: ChangeEvent<{}>, items: IItem[] | null) => {
            if (items) {
                manejarCambioCampo(
                    'Items',
                    items.map((item) => ({
                        ItemId: item.ItemId,
                        DescripcionItem: item.DescripcionItem,
                    }))
                );
            }
        }}
        options={items}
        renderInput={(params) => (
            <TextField {...params} fullWidth label={<Texto id="Auditoria.CampoItemId.Nombre" />} />
        )}
        value={
            items.length > 0
                ? items.filter((item) =>
                      itemsSeleccionados.find((itemSeleccionado) => itemSeleccionado.ItemId === item.ItemId)
                  )
                : []
        }
    />
);

export default CampoItemId;
