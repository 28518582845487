import { TipoAccion, TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IDocumentoCompuesto from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuesto';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import { AnyAction } from 'redux';

export interface IEstadoAuditorias {
    divisionPorDefecto?: number;
    documento?: IDocumentoCompuesto;
    divisiones: IBodegaDivisiones[];
    estadoFormulario: boolean;
    estadoFormularioDetalle: boolean;
    entidadDocumentoDetalle?: IDocumentoDetalleCompuesto;
    tipoFormularioDocumentoDetalle: TipoFormulario;
    recargarTabla: boolean;
    recargarTablaDetalles: boolean;
}

export const estadoInicial: IEstadoAuditorias = {
    divisionPorDefecto: undefined,
    documento: { BodegaOrigen: '', BodegaOrigenDescripcion: '', EtapaDescripcion: '', Observacion: '' } as any,
    divisiones: [],
    estadoFormulario: false,
    estadoFormularioDetalle: false,
    entidadDocumentoDetalle: undefined,
    tipoFormularioDocumentoDetalle: TipoFormulario.Creacion,
    recargarTabla: true,
    recargarTablaDetalles: false,
};

export const acciones = {
    CARGAR_DOCUMENTOS_DETALLES: 'CARGAR_DOCUMENTOS_DETALLES',
    CARGAR_DIVISIONES: 'CARGAR_DIVISIONES',
    CAMBIAR_ESTADO_FORMULARIO: 'CAMBIAR_ESTADO_FORMULARIO',
    CAMBIAR_ESTADO_FORMULARIO_DETALLE: 'CAMBIAR_ESTADO_FORMULARIO_DETALLE',
    CAMBIAR_TIPO_FORMULARIO: 'CAMBIAR_TIPO_FORMULARIO',
    ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE: 'ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE',
    ESTABLECER_DIVISION_POR_DEFECTO: 'ESTABLECER_DIVISION_POR_DEFECTO',
    RECARGAR_TABLA: 'RECARGAR_TABLA',
    RECARGAR_TABLA_DETALLES: 'RECARGAR_TABLA_DETALLES',
};

export const reductor = (estado: IEstadoAuditorias = estadoInicial, { type, payload }: AnyAction) => {
    switch (type) {
        case acciones.CARGAR_DOCUMENTOS_DETALLES: {
            return {
                ...estado,
                documentosDetalles: payload,
            };
        }

        case acciones.CARGAR_DIVISIONES: {
            return {
                ...estado,
                divisiones: payload,
            };
        }

        case acciones.CAMBIAR_ESTADO_FORMULARIO: {
            return {
                ...estado,
                estadoFormulario: !estado.estadoFormulario,
                documento: payload ? payload : estadoInicial.documento,
            };
        }

        case acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE: {
            return {
                ...estado,
                estadoFormularioDetalle: !estado.estadoFormularioDetalle,
            };
        }

        case acciones.CAMBIAR_TIPO_FORMULARIO: {
            return {
                ...estado,
                tipoFormulario: payload,
            };
        }

        case acciones.ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE: {
            return {
                ...estado,
                entidadDocumentoDetalle: payload,
                tipoFormularioDocumentoDetalle: payload ? TipoFormulario.Edicion : TipoFormulario.Creacion,
            };
        }

        case acciones.ESTABLECER_DIVISION_POR_DEFECTO: {
            return {
                ...estado,
                divisionPorDefecto: payload,
            };
        }

        case acciones.RECARGAR_TABLA: {
            return {
                ...estado,
                recargarTabla: !estado.recargarTabla,
            };
        }

        case acciones.RECARGAR_TABLA_DETALLES: {
            return {
                ...estado,
                recargarTablaDetalles: !estado.recargarTablaDetalles,
            };
        }
    }
};
