import ModeloBodegasDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones';
import ModeloBodegasPersonas from 'Infotrack@Modelos/smartStock/bodegasPersonas';
import IBodegasPersonasFiltro from 'Infotrack@Modelos/smartStock/bodegasPersonas/entidades/bodegasPersonasFiltro';

export default class EntidadesDocumentoDetalleCM {
  private modeloBodegasDivisiones: ModeloBodegasDivisiones;
  private modeloBodegasPersonas: ModeloBodegasPersonas;

  constructor() {
    this.modeloBodegasDivisiones = new ModeloBodegasDivisiones();
    this.modeloBodegasPersonas = new ModeloBodegasPersonas();
  }

  public consultarListaDivisiones(BodegaId: number) {
    return this.modeloBodegasDivisiones
      .consultarDivisionesFiltro({ BodegaId, Estado: 1 })
      .then(({ data: { Entidades } }) => Entidades);
  }

  public consultarBodegasPersonasCompuesta(bodegasPersonas: IBodegasPersonasFiltro) {
    return this.modeloBodegasPersonas
      .consultarBodegasPersonasCompuesta(bodegasPersonas)
      .then(({ data: { Entidades } }) => Entidades);
  }
}
