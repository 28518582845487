import React, { FunctionComponent, useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
//import '@react-pdf-viewer/core/lib/styles/index.css';
import Texto from '@infotrack/presentacion-componentes/texto';

import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';

import { EstadosModalArchivoPDF } from './enumeraciones';

interface IArchivoPDFProps {
    archivoPDF: string;
    cerrar: () => void;
    estado: EstadosModalArchivoPDF;
}

const GenerarPDF: FunctionComponent<IArchivoPDFProps> = ({ archivoPDF, cerrar, estado }) => {
    return (
        <Dialog open={estado == EstadosModalArchivoPDF.VISUALIZACION} maxWidth="xl">
            <ModalEncabezado cerrar={cerrar} titulo={<Texto id="Visualizador PDF" />} />
            <iframe
                height="800px"
                width="800px"
                src={URL.createObjectURL(new Blob([archivoPDF], { type: 'application/pdf' }))}
            />
        </Dialog>
    );
};

export default GenerarPDF;
