const mensajes = {
    //PLantillaImpresionTabla
    'PlantillaImpresionTabla.Acciones': 'Acciones',
    'PlantillaImpresionTabla.VerPlantillaImpresion': 'Ver plantilla impresión',
    'PlantillaImpresionTabla.EditarPlantillaImpresion': 'Editar plantilla impresión',
    'PlantillaImpresionTabla.EliminarPlantillaImpresion': 'Eliminar plantilla impresión',
    'PlantillaImpresionTabla.PlantillaImpresionDescripcion': 'Plantilla impresión',
    //ModalPlantillaImpresion
    'ModalPlantillaImpresion.TituloCreacion': 'Crear Plantilla Impresión',
    'ModalPlantillaImpresion.TituloEdicion': 'Editar Plantilla Impresión',
    'ModalPlantillaImpresion.TituloVisualizacion': 'Ver Plantilla Impresión',
    'ModalPlantillaImpresion.BotonGuardar': 'Guardar',
    'ModalPlantillaImpresion.BotonEditar': 'Editar',
    'ModalPlantillaImpresion.Cancelar': 'Los datos no guardados se perderán, ¿Desea cerrar la ventana?',
    //CampoPLantillaImpresionDescripcion
    'PlantillaImpresion.CampoPlantillaImpresionDescripcion.Nombre': 'Plantilla Impresión',
    'PlantillaImpresion.CampoComando.Nombre': 'Comando',
    //Validaciones
    'PlantillaImpresion.ValidacionNoPlantillaImpresionDescripcion': 'Debe ingresar una descripción para la plantilla',
    'PlantillaImpresion.ValidacionNoComando': 'Debe ingresar un comando para la plantilla',
};

export default mensajes;
