import { Button, ButtonProps } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

const BotonAgregar: FunctionComponent<ButtonProps> = (props) => (
    <Button color="primary" endIcon={<AddIcon />} size="small" variant="contained" {...props}>
        <Texto id="boton.agregar" />
    </Button>
);

export default BotonAgregar;
