import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IDocumentoCompuesto from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuesto';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import { AnyAction } from 'redux';

export interface IEstadoGestionAuditorias {
    divisionPorDefecto?: number;
    divisiones: IBodegaDivisiones[];
    documento?: IDocumentoCompuesto;
    estadoFormulario: boolean;
    estadoFormularioDetalles: boolean;
    estadoTablaDetalles: boolean;
    entidadDocumentoDetalle?: IDocumentoDetalleCompuesto;
    tipoFormulario: TipoFormulario;
    tipoFormularioDocumentoDetalle: TipoFormulario;
    recargarTabla: boolean;
    recargarTablaDetalles: boolean;
}

export const estadoInicial: IEstadoGestionAuditorias = {
    divisionPorDefecto: undefined,
    divisiones: [],
    documento: {} as any,
    estadoFormulario: false,
    estadoFormularioDetalles: false,
    estadoTablaDetalles: false,
    tipoFormulario: TipoFormulario.Creacion,
    tipoFormularioDocumentoDetalle: TipoFormulario.Creacion,
    recargarTabla: true,
    recargarTablaDetalles: false,
};

export const acciones = {
    CARGAR_DIVISIONES: 'CARGAR_DIVISIONES',
    CAMBIAR_ESTADO_FORMULARIO: 'CAMBIAR_ESTADO_FORMULARIO',
    CAMBIAR_ESTADO_TABLA_DETALLE: 'CAMBIAR_ESTADO_TABLA_DETALLE',
    CAMBIAR_ESTADO_FORMULARIO_DETALLE: 'CAMBIAR_ESTADO_FORMULARIO_DETALLE',
    CAMBIAR_TIPO_FORMULARIO: 'CAMBIAR_TIPO_FORMULARIO',
    ESTABLECER_DOCUMENTO: 'ESTABLECER_DOCUMENTO',
    ESTABLECER_DIVISION_POR_DEFECTO: 'ESTABLECER_DIVISION_POR_DEFECTO',
    ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE: 'ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE',
    RECARGAR_TABLA: 'RECARGAR_TABLA',
    RECARGAR_TABLA_DETALLES: 'RECARGAR_TABLA_DETALLES',
};

export const reductor = (estado: IEstadoGestionAuditorias = estadoInicial, { type, payload }: AnyAction) => {
    switch (type) {
        case acciones.CARGAR_DIVISIONES: {
            return {
                ...estado,
                divisiones: payload,
            };
        }

        case acciones.CAMBIAR_ESTADO_FORMULARIO: {
            return {
                ...estado,
                estadoFormulario: !estado.estadoFormulario,
            };
        }

        case acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE: {
            return {
                ...estado,
                estadoFormularioDetalles: !estado.estadoFormularioDetalles,
            };
        }

        case acciones.CAMBIAR_ESTADO_TABLA_DETALLE: {
            return {
                ...estado,
                estadoTablaDetalles: !estado.estadoTablaDetalles,
            };
        }

        case acciones.CAMBIAR_TIPO_FORMULARIO: {
            return {
                ...estado,
                tipoFormulario: payload,
            };
        }

        case acciones.ESTABLECER_DOCUMENTO: {
            return {
                ...estado,
                documento: payload,
            };
        }

        case acciones.ESTABLECER_DIVISION_POR_DEFECTO: {
            return {
                ...estado,
                divisionPorDefecto: payload,
            };
        }

        case acciones.ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE: {
            return {
                ...estado,
                entidadDocumentoDetalle: payload,
                tipoFormularioDocumentoDetalle: payload ? TipoFormulario.Edicion : TipoFormulario.Creacion,
            };
        }

        case acciones.RECARGAR_TABLA: {
            return {
                ...estado,
                recargarTabla: !estado.recargarTabla,
            };
        }

        case acciones.RECARGAR_TABLA_DETALLES: {
            return {
                ...estado,
                recargarTablaDetalles: !estado.recargarTablaDetalles,
            };
        }

        default:
            return estado;
    }
};
