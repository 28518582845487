import { IRespuesta, IRespuestaPaginada } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocio } from '../../conexiones';
import IDocumentoConDetalleCompuesto from '../documentosDetalle/entidades/documentoConDetalleCompuesto';
import IDocumentoConDetallesAccion from '../documentosDetalle/entidades/documentoConDetallesAccion';
import IDocumentoDetalle from '../documentosDetalle/entidades/documentoDetalle';
import IDocumento from './entidades/documento';
import IDocumentoAuditoria from './entidades/documentoAuditoria';
import IDocumentoCompuesto from './entidades/documentoCompuesto';
import IDocumentoCompuestoMovimiento from './entidades/documentoCompuestoMovimiento';
import IDocumentoConDetalle from './entidades/documentoConDetalle';
import IDocumentoConsulta from './entidades/documentoConsulta';
import IDocumentoPadreCompuesto from './entidades/documentoPadreCompuesto';
import IFiltroGeneralDocumentoConsulta from './entidades/filtroGeneralDocumentoConsulta';
import IFiltroGeneralDocumentosPaginador from './entidades/filtroGeneralDocumentosPaginador';
import IFiltrosDocumento from './entidades/filtrosDocumento';
import IFiltrosDocumentoPadre from './entidades/filtrosDocumentoPadre';
import IFiltrosDocumentos from './entidades/filtrosDocumentosMovimientos';
import IParametrosPaginador from './entidades/parametrosDocumentoMovimiento';

export default class ModeloDocumento {
    public consultaListaDocumentosCompuestaPadrePorFiltro(filtroDocumento: IFiltrosDocumentoPadre) {
        return manejadorRest<IRespuesta<IDocumentoPadreCompuesto>>(
            () => negocio.post('Documentos/ConsultarListaDocumentosCompuestaPadre', filtroDocumento),
            true
        );
    }

    public consultaListaDocumentosPorFiltro(filtroDocumento: IFiltrosDocumento) {
        return manejadorRest<IRespuesta<IDocumentoCompuesto>>(
            () => negocio.post('Documentos/ConsultarDocumentosCompuestaFiltro', filtroDocumento),
            true
        );
    }

    public consultaGeneralDocumentosFiltro(filtroDocumento: IFiltroGeneralDocumentoConsulta) {
        return manejadorRest<IRespuesta<IDocumentoConsulta>>(
            () => negocio.post('Documentos/ConsultaGeneralDocumentosFiltro', filtroDocumento),
            true
        );
    }

    public consultaDocumentosReversibles(filtroDocumento: IFiltroGeneralDocumentosPaginador) {
        return manejadorRest<IRespuestaPaginada<IDocumentoConsulta>>(
            () => negocio.post('Reversiones/ConsultaDocumentosReversibles', filtroDocumento),
            true
        );
    }

    public consultaDocumentosDevoluciones(filtroDocumento: IFiltroGeneralDocumentosPaginador) {
        return manejadorRest<IRespuestaPaginada<IDocumentoConsulta>>(
            () => negocio.post('Devoluciones/ConsultaDocumentosDevoluciones', filtroDocumento),
            true
        );
    }

    public consultarDespachosDevoluciones(EmpresaId: string) {
        return manejadorRest<IRespuestaPaginada<IDocumentoConsulta>>(
            () => negocio.post('Devoluciones/ConsultarDespachosDevoluciones', { EmpresaId }),
            true
        );
    }

    public crearDocumentoDevoluciones(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post('Devoluciones/CrearDocumentoDevoluciones', documento),
            true
        );
    }

    public modificarDocumentoDetallesDevoluciones(documento: IDocumentoConDetallesAccion) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.put('Devoluciones/ModificarDocumentoDetallesDevoluciones', documento),
            true
        );
    }

    public cambiarEtapaDevolucion(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.put('Devoluciones/CambiarEtapaDevolucion', documento),
            true
        );
    }

    public consultarListaDocumentosAuditoriaCompuestos(parametros: IParametrosPaginador) {
        return manejadorRest<IRespuestaPaginada<IDocumentoCompuestoMovimiento>>(
            () => negocio.post('GestionDocumentosAuditoria/ConsultarListaDocumentosAuditoriaCompuesto', parametros),
            true
        );
    }

    public consultarDocumentosMovimientoEntradas(parametros: IParametrosPaginador) {
        return manejadorRest<IRespuestaPaginada<IDocumentoCompuestoMovimiento>>(
            () => negocio.post('GestionDocumentosMovimiento/ConsultarDocumentosMovimientoEntradas', parametros),
            true
        );
    }

    public consultaListaDocumentosDespachosPaginados(parametros: IParametrosPaginador) {
        return manejadorRest<IRespuestaPaginada<IDocumentoCompuestoMovimiento>>(
            () => negocio.post('DocumentosDespachos/ConsultaListaDocumentosDespachosPaginados', parametros),
            true
        );
    }

    public consultaListaDocumentosEnZonaDespachoPorFiltro(Documento: IFiltrosDocumentos) {
        return manejadorRest<IRespuestaPaginada<IDocumentoCompuestoMovimiento>>(
            () => negocio.post('DocumentosDespachos/ConsultaListaDocumentosEnZonaDespachoPorFiltro', Documento),
            true
        );
    }

    public crearDocumentosDetallesRecepcion(documentoDetalle: IDocumentoConDetalleCompuesto) {
        return manejadorRest<IRespuesta<IDocumentoConDetalleCompuesto>>(
            () => negocio.post('Documentos/GuardarDocumentosDetallesRecepcion', documentoDetalle),
            true
        );
    }

    public guardarDocumentoConDetalle(documentoConDetalle: IDocumentoConDetalle) {
        return manejadorRest<IRespuesta<IDocumentoConDetalle>>(
            () => negocio.post('Documentos/GuardarDocumentosDetallesCompuesto', documentoConDetalle),
            true
        );
    }

    public guardarDocumentosDetallesCompras(documentoDetalles: IDocumentoDetalle) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.post('DocumentosCompras/GuardarDocumentosDetallesCompras', documentoDetalles),
            true
        );
    }

    public crearDocumentoAuditoria(documentoAuditoria: IDocumentoAuditoria) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post('GestionDocumentosAuditoria/CrearDocumentoAuditoria', documentoAuditoria),
            true
        );
    }

    public crearDocumentoDetallesPedidos(documentoPedido: IDocumentoConDetallesAccion) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post('DocumentosPedidos/GuardarDocumentoDetallesPedidos', documentoPedido),
            true
        );
    }

    public crearDocumentoDetallesDespachos(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post('DocumentosDespachos/CrearDocumentoDespacho', documento),
            true
        );
    }

    public modificarEstadoAuditoria(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.put('GestionDocumentosAuditoria/ModificarEstadoAuditoria', documento),
            true
        );
    }

    public modificarEstadoEntrada(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post('Entrada/CambiarEtapaEntrada', documento),
            true
        );
    }

    public modificarEstadoSalida(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(() => negocio.post('Salida/CambiarEtapaSalida', documento), true);
    }

    public modificarEstadoPedido(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.put('DocumentosPedidos/ModificarEstadoPedido', documento),
            true
        );
    }

    public modificarEstadoDespacho(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.put('DocumentosDespachos/ModificarEstadoDespacho', documento),
            true
        );
    }

    public revertirDocumento(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post('Reversiones/RevertirDocumento', documento),
            true
        );
    }

    public anularDocumentoAuditoria(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.put('GestionDocumentosAuditoria/AnularDocumento', documento),
            true
        );
    }

    public anularDocumentoDespachos(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.put('DocumentosDespachos/AnularDocumentoDespacho', documento),
            true
        );
    }

    public cambiarEtapaDevolucionProveedor(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post('DevolucionProveedor/CambiarEtapaDevolucionProveedor', documento),
            true
        );
    }

    public guardarDocumentoDevolucionProveedor(documentoConDetalle: IDocumentoConDetalle) {
        return manejadorRest<IRespuesta<IDocumentoConDetalle>>(
            () => negocio.post('DevolucionProveedor/GuardarDocumentoDevolucionProveedor', documentoConDetalle),
            true
        );
    }

    public editarDevolucionProveedor(documentoConDetalle: IDocumentoConDetalle) {
        return manejadorRest<IRespuesta<IDocumentoConDetalle>>(
            () => negocio.post('DevolucionProveedor/EditarDevolucionProveedor', documentoConDetalle),
            true
        );
    }

    public anularDevolucionProveedor(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post('DevolucionProveedor/AnularDevolucionProveedor', documento),
            true
        );
    }

    public consultarListaDocumentosCompuestos(parametros: IParametrosPaginador) {
        return manejadorRest<IRespuestaPaginada<IDocumentoCompuestoMovimiento>>(
            () => negocio.post('GestionDocumentosMovimiento/ConsultarListaDocumentosCompuestos', parametros),
            true
        );
    }

    public editarDocumento(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(() => negocio.put('Documentos/EditarDocumentos', documento), true);
    }

    public anularDocumento(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumentoConDetalle>>(
            () => negocio.post('Documentos/AnularDocumento', documento),
            true
        );
    }
}
