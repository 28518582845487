import { Card, CardContent, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import { BotonBuscar, BotonLimpiar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ITablaGestionProductoVistaProps } from '../interfaces';
import { acciones, columnas, opciones } from './constantes';

const TablaGestionProductoVista: React.FunctionComponent<ITablaGestionProductoVistaProps & InjectedIntlProps> = ({
    alAbrirFormulario,
    alEliminarItem,
    alCambiarValor,
    alCambiarValorAutoComplete,
    consultaProductos,
    consultaProductosExportar,
    entidadesFiltro,
    filtros,
    intl: { formatMessage },
    recargarTabla,
    refTabla,
    descargarPlantilla,
    handleCargarArchivo
}) => (
    <Grid alignItems="center" container spacing={2} justifyContent="center">
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid alignItems="center" container spacing={2} justifyContent="space-between">
                        <Grid item md={true} xs={12}>
                            <TextField
                                fullWidth
                                label={<Texto id="gestionproducto.CodigoBarras" />}
                                name="CodigoBarras"
                                onChange={alCambiarValor}
                                variant="outlined"
                                value={filtros.CodigoBarras}
                            />
                        </Grid>
                        <Grid item md={true} xs={12}>
                            <TextField
                                fullWidth
                                label={<Texto id="gestionproducto.DescripcionItem" />}
                                name="DescripcionItem"
                                onChange={alCambiarValor}
                                variant="outlined"
                                value={filtros.DescripcionItem}
                            />
                        </Grid>
                        <Grid item md={true} xs={12}>
                            <Autocomplete
                                getOptionLabel={({ FamiliaDescripcion }) => FamiliaDescripcion}
                                onChange={(_: any, familia: any) => alCambiarValorAutoComplete(familia, 'Familia')}
                                options={entidadesFiltro.familias}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                renderInput={(params) => (
                                    <TextField {...params} fullWidth label={<Texto id="gestionproducto.Familia" />} />
                                )}
                                value={filtros.Familia}
                            />
                        </Grid>
                        <Grid item md={true} xs={12}>
                            <Autocomplete
                                getOptionLabel={({ DescripcionUnidad }) => DescripcionUnidad}
                                onChange={(_: any, unidad: any) => alCambiarValorAutoComplete(unidad, 'Unidad')}
                                options={entidadesFiltro.unidades}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                renderInput={(params) => (
                                    <TextField {...params} fullWidth label={<Texto id="gestionproducto.Unidad" />} />
                                )}
                                value={filtros.Unidad}
                            />
                        </Grid>
                        <Grid item md={true} xs={12}>
                            <Autocomplete
                                getOptionLabel={({ ClasificacionDescripcion }) => ClasificacionDescripcion}
                                onChange={(_: any, clasificacion: any) =>
                                    alCambiarValorAutoComplete(clasificacion, 'Clasificacion')
                                }
                                options={entidadesFiltro.clasificaciones}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={<Texto id="gestionproducto.Clasificacion" />}
                                    />
                                )}
                                value={filtros.Clasificacion}
                            />
                        </Grid>
                        <Grid item md="auto" xs={6}>
                            <BotonLimpiar onClick={() => recargarTabla(true)} />
                        </Grid>
                        <Grid item md="auto" xs={6}>
                            <BotonBuscar onClick={() => recargarTabla()} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <Tabla
                actions={acciones(
                    alAbrirFormulario,
                    alEliminarItem,
                    recargarTabla,
                    formatMessage,
                    consultaProductosExportar,
                    descargarPlantilla,
                    handleCargarArchivo
                )}
                columns={columnas(formatMessage)}
                data={consultaProductos}
                tableRef={refTabla}
                options={opciones}
            />
        </Grid>
    </Grid>
);

export default injectIntl(TablaGestionProductoVista);
