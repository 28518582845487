import { Card, Grid, IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Editcon from '@material-ui/icons/Edit';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';

import DocumentoDetalleCM from '../controladorModelo/documentoDetalleCM';

const useStyles = makeStyles({
    listbox: {
        padding: '8px',
        '&:last-child': {
            paddingBottom: '8px',
        },
    },
    tarjetaDocumentoDetalle: {
        maxHeight: '400px',
        overflowY: 'auto',
    },
    centrarBotonesAccion: {
        padding: '100px',
    },
});

interface ITablaDocumentoDetalleProps {
    abrirModalDocumentoDetalle: (
        tipoFormulario: TipoFormulario,
        documentoDetalle?: IDocumentoDetalleCompuesto | undefined
    ) => void;
    documentoDetalle: Partial<IDocumentoDetalleCompuesto> | undefined;
    eliminarDocumentoDetalle?: (documentoDetalleCompuesto: IDocumentoDetalleCompuesto) => Promise<void>;
    esRecepcion: boolean;
    refrescarDocumentoDetalle: (refrescar: boolean) => void;
    refrescarDocumentoDetalles: boolean | undefined;
    terminoRecepcion: boolean;
}

const VistaDocumentoDetalle: FunctionComponent<ITablaDocumentoDetalleProps> = ({
    abrirModalDocumentoDetalle,
    documentoDetalle,
    eliminarDocumentoDetalle: eliminarDocumentoDetalleProp,
    refrescarDocumentoDetalle,
    refrescarDocumentoDetalles,
    terminoRecepcion,
}) => {
    const documentoDetalleCM = useMemo(() => new DocumentoDetalleCM(), []);

    const documentoConsulta = documentoDetalle ? documentoDetalle : {};

    const [documentosDetalles, setdocumentosDetalles] = useState<IDocumentoDetalleCompuesto[]>([]);

    const cargaInicial = useRef(true);

    const estilo = useStyles();

    if (documentoConsulta === null) {
        manejadorDialogoGlobal({
            estado: true,
            mensaje: <Texto id={'alerta.confirmacionEdicion'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.confirmacion" />,
        });
    }

    useEffect(() => {
        if (cargaInicial.current === true) {
            cargaInicial.current = false;
            return;
        } else {
            consultarVehiculos(documentoConsulta);
        }
    }, [documentoDetalle]);

    useEffect(() => {
        if (refrescarDocumentoDetalles) {
            consultarVehiculos(documentoConsulta);
            refrescarDocumentoDetalle(false);
        }
    }, [refrescarDocumentoDetalles]);

    const consultarVehiculos = async (documentoConsulta: Partial<IDocumentoDetalleCompuesto>) => {
        documentoConsulta.IdTipoDocumento = 0;
        const consultaDocumentoDetalle = await documentoDetalleCM.ConsultardocumentoDetalle(
            documentoConsulta ? documentoConsulta : {}
        );
        if (consultaDocumentoDetalle.length > 0) {
            setdocumentosDetalles(consultaDocumentoDetalle[0].documentosDetalles);
        }
    };

    const EliminarDocumentoDetalle = async (documento: IDocumentoDetalleCompuesto) => {
        manejadorDialogoGlobal({ estado: false });
        // TODO: Eliminar else
        if (eliminarDocumentoDetalleProp) await eliminarDocumentoDetalleProp(documento);
        else await documentoDetalleCM.eliminarDocumentosDetalles(documento);
        consultarVehiculos(documento);
        refrescarDocumentoDetalle(true);
    };

    return (
        <Card className={estilo.listbox}>
            <Grid container spacing={1} className={estilo.tarjetaDocumentoDetalle}>
                {documentosDetalles.map((documentoDetalleActual) => (
                    <Grid item key={documentoDetalleActual.DocumentoDetalleId} xs={12}>
                        <Card className={estilo.listbox} variant="outlined">
                            <Grid alignItems="flex-end" container justifyContent="space-between">
                                <Grid container spacing={1} justifyContent="flex-end">
                                    {terminoRecepcion !== true && (
                                        <Grid item xs={1}>
                                            {
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        abrirModalDocumentoDetalle(
                                                            TipoFormulario.Edicion,
                                                            documentoDetalleActual
                                                        )
                                                    }
                                                >
                                                    <Editcon />
                                                </IconButton>
                                            }
                                        </Grid>
                                    )}
                                    {terminoRecepcion !== true && (
                                        <Grid item xs={1} className={estilo.tarjetaDocumentoDetalle}>
                                            <IconButton
                                                size="small"
                                                onClick={() => EliminarDocumentoDetalle(documentoDetalleActual)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    )}
                                </Grid>
                                {documentoDetalleActual.FechaVencimiento !== null && (
                                    <Grid item xs={10}>
                                        <Texto
                                            id="documentoDetalle.fechaVencimiento"
                                            mensaje=": "
                                            variant="body2"
                                            display="inline"
                                            fontWeight="bold"
                                        />
                                        <Texto
                                            variant="body2"
                                            mensaje={moment(documentoDetalleActual.FechaVencimiento).format(
                                                'DD-MM-YYYY'
                                            )}
                                            display="inline"
                                        />
                                    </Grid>
                                )}
                                {documentoDetalleActual.Serial !== null && documentoDetalleActual.Serial !== '' && (
                                    <Grid item xs={12}>
                                        <Texto
                                            id={'documentoDetalle.serial'}
                                            mensaje=":  "
                                            fontWeight="bold"
                                            variant="body2"
                                            display="inline"
                                        />
                                        <Texto
                                            mensaje={documentoDetalleActual.Serial}
                                            variant="body2"
                                            display="inline"
                                        />
                                    </Grid>
                                )}

                                {documentoDetalleActual.Lote !== null && documentoDetalleActual.Lote !== '' && (
                                    <Grid item xs={12}>
                                        <Texto
                                            id="documentoDetalle.lote"
                                            mensaje=": "
                                            variant="body2"
                                            display="inline"
                                            fontWeight="bold"
                                        />
                                        <Texto mensaje={documentoDetalleActual.Lote} variant="body2" display="inline" />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Texto
                                        id="documentoDetalle.cantidadRecibida"
                                        mensaje=": "
                                        variant="body2"
                                        display="inline"
                                        fontWeight="bold"
                                    />
                                    <Texto
                                        mensaje={
                                            documentoDetalleActual.CantidadRecibida != null
                                                ? documentoDetalleActual.CantidadRecibida
                                                : 0
                                        }
                                        variant="body2"
                                        display="inline"
                                    />
                                </Grid>

                                {documentoDetalleActual.DescripcionDivisionDestino !== null && (
                                    <Grid item xs={12}>
                                        <Texto
                                            id="documentoDetalle.divisionDestino"
                                            mensaje=": "
                                            variant="body2"
                                            display="inline"
                                            fontWeight="bold"
                                        />
                                        <Texto
                                            mensaje={documentoDetalleActual.DescripcionDivisionDestino}
                                            variant="body2"
                                            display="inline"
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Card>
    );
};

export default VistaDocumentoDetalle;
