import ModeloBodegas from 'Infotrack@Modelos/smartStock/bodegas';
import IBodegasPersonaFiltro from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaPersonaFiltro';
import ModeloEtapas from 'Infotrack@Modelos/smartStock/etapas';
import ModeloTiposDocumentos from 'Infotrack@Modelos/smartStock/tiposDocumentos';

export default class ControladorEntidadesFiltro {
    private modeloTiposDocumentos: ModeloTiposDocumentos;
    private modeloEtapas: ModeloEtapas;
    private modeloBodegas: ModeloBodegas;

    constructor() {
        this.modeloTiposDocumentos = new ModeloTiposDocumentos();
        this.modeloEtapas = new ModeloEtapas();
        this.modeloBodegas = new ModeloBodegas();
    }

    public consultarListaEntidadesFiltros(bodegasPersonas: IBodegasPersonaFiltro) {
        return Promise.all([
            this.modeloBodegas.consultarBodegasPersonasDoc(bodegasPersonas),
            this.modeloEtapas.ConsultarListaEtapasPorFiltro({EmpresaId:bodegasPersonas.EmpresaId}),
            //this.modeloTiposDocumentos.ConsultarListaTiposDocumentosMovimiento(),
        ]).then((entidades) => ({
            bodegas: entidades[0].data.Entidades,
            etapas: entidades[1].data.Entidades,
            //tipoDocumentos: entidades[2].data.Entidades,
        }));
    }
}
