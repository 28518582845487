import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';

interface ICampoFechaInicialProps {
    manejarCambioFiltro: (
        nombreFiltro: keyof IDocumentoFiltro,
        nuevoValor: IDocumentoFiltro[keyof IDocumentoFiltro]
    ) => any;
    valor?: string;
}

const CampoFechaInicial: FunctionComponent<ICampoFechaInicialProps> = ({ manejarCambioFiltro, valor }) => (
    <KeyboardDatePicker
        format="DD/MM/YYYY"
        fullWidth
        inputVariant="outlined"
        label={<Texto id="Documentos.CampoFechaInicial.Nombre" />}
        onChange={(date) => {
            if (date) manejarCambioFiltro('FechaInicial', date.format('YYYY-MM-DD'));
        }}
        value={valor ? moment(valor, 'YYYY-MM-DD') : null}
    />
);

export default CampoFechaInicial;
