import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Toolbar,
    withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import { BotonBuscar, BotonLimpiar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ISelectTablaItemsVistaProps } from '../interfaces';
import { columnas, opciones } from './constantes';
import { estilos } from './estilos';

const SelectTablaItemsVista: React.FunctionComponent<PropsEstilosMaterialUI<
    ISelectTablaItemsVistaProps & InjectedIntlProps
>> = ({
    alAbrirCerrarSelect,
    alCambiarCodigoBarras,
    alCambiarValor,
    alQuitarItem,
    alSeleccionarItem,
    classes,
    codigoBarras,
    consultaItems,
    consultarItemPorCodigoBarras,
    estadoSelect,
    esMovil,
    familias,
    filtros,
    intl: { formatMessage },
    item,
    recargarTabla,
    refInputItem,
    refTabla,
}) => (
    <>
        <Card className={classes.contenedorTextField}>
            <Grid alignItems="flex-start" container justifyContent="center" spacing={2}>
                <Grid item md={true} xs={12}>
                    <form method="POST" onSubmit={consultarItemPorCodigoBarras}>
                        <TextField
                            autoComplete="off"
                            autoFocus
                            fullWidth
                            label={<Texto display="inline" id="gestionproducto.itemSeleccionado" />}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={alQuitarItem}>
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{ shrink: true }}
                            inputRef={refInputItem}
                            name={item ? 'DescripcionItem' : 'codigoBarras'}
                            onChange={alCambiarCodigoBarras}
                            required
                            value={item ? `${item.DescripcionItem}.` : codigoBarras}
                            variant="outlined"
                        />
                    </form>
                </Grid>
                <Grid item xs="auto">
                    <BotonBuscar onClick={alAbrirCerrarSelect} />
                </Grid>
            </Grid>
        </Card>
        <Dialog maxWidth={esMovil ? 'lg' : 'md'} fullWidth open={estadoSelect}>
            <AppBar position="static">
                <Toolbar>
                    <Grid container justifyContent="center">
                        <Texto align="center" id="gestionproducto.seleccionarItem" />
                    </Grid>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Grid alignItems="center" container justifyContent="space-between" spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Grid alignItems="center" container justifyContent="space-between" spacing={2}>
                                    <Grid item md={true} xs={12}>
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            label={<Texto id="gestionproducto.CodigoBarras" />}
                                            onChange={alCambiarValor}
                                            name="CodigoBarras"
                                            value={filtros.CodigoBarras}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={true} xs={12}>
                                        <TextField
                                            fullWidth
                                            label={<Texto id="gestionproducto.DescripcionItem" />}
                                            onChange={alCambiarValor}
                                            name="DescripcionItem"
                                            value={filtros.DescripcionItem}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={true} xs={12}>
                                        <Autocomplete
                                            disableClearable
                                            getOptionLabel={({ FamiliaDescripcion }) => FamiliaDescripcion}
                                            onChange={(_: any, familia: any) =>
                                                alCambiarValor({ target: { name: 'Familia', value: familia } } as any)
                                            }
                                            options={familias}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label={formatMessage({ id: 'gestionproducto.Familia' })}
                                                />
                                            )}
                                            noOptionsText={<Texto id="label.sinRegistros" />}
                                            value={filtros.Familia!==null?filtros.Familia:undefined}
                                        />
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Grid alignItems="center" container justifyContent="center" spacing={2}>
                                            <Grid item>
                                                <BotonLimpiar onClick={() => recargarTabla(true)} />
                                            </Grid>
                                            <Grid item>
                                                <BotonBuscar onClick={() => recargarTabla()} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Tabla
                            columns={columnas(alSeleccionarItem, formatMessage, item && item.ItemId)}
                            data={consultaItems}
                            options={opciones}
                            tableRef={refTabla}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Button color="primary" onClick={alAbrirCerrarSelect} variant="contained">
                            <Texto id="boton.cerrar" />
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    </>
);

export default withStyles(estilos)(injectIntl(SelectTablaItemsVista));
