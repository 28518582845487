import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    IconButton,
    List,
    TextField,
    Toolbar,
    withStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Texto from '@infotrack/presentacion-componentes/texto';
import Paginador from 'Infotrack@Transversales/componentes/paginadorLocal/index';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import { ISelectMasivoItemsVistaProps } from '../interfaces';
import { estilosDesplegableItems } from './estilos';
import FiltrosItemMovimientosVista from './filtrosItemMovimientosVista';
import ItemDivisionVista from './itemDivisionVista';

const SelectMasivoItemsVista: React.FunctionComponent<PropsEstilosMaterialUI<ISelectMasivoItemsVistaProps>> = ({
    alAgregarItems,
    alCambiarValor,
    alCambiarValorCantidades,
    alCambiarValorDivisionesDestino,
    alCambiarValorDivisionDestino,
    alCerrarFormulario,
    alExpandirItem,
    consultarItemMovimientos,
    classes,
    desactivarDivisionDestino,
    divisiones,
    divisionesDestino,
    divisionDestino,
    divisionMultiple,
    enEdicion,
    estadoFormulario,
    filtrosItem,
    itemsMovimientos,
    itemSeleccionado,
    seleccionMasiva,
    opcionesPaginacion,
}) => {
    return (
        <Dialog className={classes.contenedorSelectMasivo} open={estadoFormulario} fullWidth maxWidth="lg">
            <AppBar position="static">
                <Toolbar className={classes.tituloSelect}>
                    <Texto align="center" id="selectItems.productos" />
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Grid container justifyContent="space-between" spacing={2}>
                    <FiltrosItemMovimientosVista
                        alCambiarValor={alCambiarValor}
                        alCambiarValorDivisionDestino={alCambiarValorDivisionDestino}
                        classes={classes}
                        consultarItemMovimientos={consultarItemMovimientos}
                        desactivarDivisionDestino={desactivarDivisionDestino}
                        divisiones={divisiones}
                        divisionesDestino={divisionesDestino}
                        divisionDestino={divisionDestino}
                        enEdicion={enEdicion}
                        filtrosItem={filtrosItem}
                    />
                    <Grid className={classes.contenedorItems} item xs={12}>
                        {itemsMovimientos.length === 0 ? (
                            <Texto
                                align="center"
                                className={classes.textoSinRegistros}
                                id="label.sinRegistros"
                                variant="h6"
                            />
                        ) : (
                            itemsMovimientos.map(
                                ({
                                    CantidadDisponible: CantidadDisponibleBodega,
                                    CantidadSeleccionada: CantidadSeleccionadaBodega,
                                    DescripcionItem,
                                    Divisiones,
                                    ItemId,
                                }) => (
                                    <ExpansionPanel
                                        key={ItemId}
                                        className={
                                            Divisiones.reduce(
                                                (acumulador, { CantidadSeleccionada }) =>
                                                    (acumulador += CantidadSeleccionada),
                                                0
                                            ) > 0
                                                ? classes.itemSeleccionado
                                                : undefined
                                        }
                                        expanded={itemSeleccionado === ItemId}
                                        TransitionProps={{ unmountOnExit: true }}
                                        onChange={() => alExpandirItem(ItemId)}
                                    >
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid alignItems="center" container justifyContent="center" spacing={2}>
                                                <Grid item xs>
                                                    <Texto
                                                        className={classes.descripciones}
                                                        id="selectItems.producto"
                                                        mensaje=":"
                                                        fontWeight="bold"
                                                    />
                                                    <Texto mensaje={DescripcionItem} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Texto
                                                        className={classes.descripciones}
                                                        id="selectItems.cantidadDisponible"
                                                        mensaje=":"
                                                        fontWeight="bold"
                                                    />
                                                    <Texto mensaje={CantidadDisponibleBodega} />
                                                </Grid>
                                                <Grid item md={4} xs="auto">
                                                    <Grid alignItems="center" container spacing={2} wrap="nowrap">
                                                        <Grid item xs>
                                                            <TextField
                                                                name="Item"
                                                                onClick={(e) => e.stopPropagation()}
                                                                onChange={({ target: { name, value } }) =>
                                                                    alCambiarValorCantidades({
                                                                        Id: ItemId,
                                                                        ItemId,
                                                                        NivelModificacion: name,
                                                                        ValorModificacion: Number(value),
                                                                    })
                                                                }
                                                                variant="outlined"
                                                                value={CantidadSeleccionadaBodega}
                                                            />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <IconButton
                                                                size="small"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    seleccionMasiva(ItemId);
                                                                }}
                                                            >
                                                                <CheckIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <List className={classes.listaDivisiones}>
                                                {Divisiones.map((division) => (
                                                    <ItemDivisionVista
                                                        alCambiarValorCantidades={alCambiarValorCantidades}
                                                        alCambiarValorDivisionesDestino={
                                                            alCambiarValorDivisionesDestino
                                                        }
                                                        classes={classes}
                                                        divisiones={divisionesDestino}
                                                        divisionMultiple={divisionMultiple}
                                                        key={division.DivisionId}
                                                        itemDivision={division}
                                                        itemId={ItemId}
                                                    />
                                                ))}
                                            </List>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )
                            )
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Paginador {...opcionesPaginacion} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Button color="secondary" onClick={alCerrarFormulario} variant="contained">
                            <Texto id="boton.cancelar" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button color="primary" variant="contained">
                            <Texto id="boton.agregar" onClick={() => alAgregarItems(true)} />
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(estilosDesplegableItems)(SelectMasivoItemsVista);
