import ModeloEtapa from '../../../../modelos/smartStock/etapas/index';
import IEtapas from '../../../../modelos/smartStock/etapas/entidades/etapas';

class EtapaCM {
    private modeloEtapa: ModeloEtapa;

    constructor() {
        this.modeloEtapa = new ModeloEtapa();
    }

    public async consultarListaEtapa(empresaId: string, estado?: number) {
        const respuesta = await this.modeloEtapa.ConsultarListaEtapasPorFiltro({EmpresaId: empresaId, Estado: estado});
        return respuesta.data.Entidades;
    }

    // public async consultarListaEtapa() {
    //     const respuesta = await this.modeloEtapa.consultarEtapas();
    //     return respuesta.data.Entidades;
    // }
}
export default EtapaCM;
