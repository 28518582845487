import ProveedorEstado from '@infotrack/presentacion-componentes/proveedorEstado';
import React from 'react';
import DetallesAuditoriaCV from './controladorVista/detallesAuditoriaCV';
import TablaAuditoriasCV from './controladorVista/tablaAuditoriasCV';
import { estadoInicial, reductor } from './reductorAuditorias';

const Auditorias: React.FunctionComponent = () => (
    <ProveedorEstado estadoInicial={estadoInicial} reductor={reductor}>
        <TablaAuditoriasCV />
        <DetallesAuditoriaCV />
    </ProveedorEstado>
);

export default Auditorias;
