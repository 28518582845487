import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import DocumentosModelo from 'Infotrack@Modelos/smartStock/documentos';
import IDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/documento';
import IFiltroGeneralDocumentoConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/filtroGeneralDocumentoConsulta';

export default class DocumentoCM {
    private documentosModelo: DocumentosModelo;

    constructor() {
        this.documentosModelo = new DocumentosModelo();
    }

    public consultarDocumentos = (Paginador: IPaginador, Filtros: IFiltroGeneralDocumentoConsulta) =>
        this.documentosModelo.consultaDocumentosReversibles({ Filtros, Paginador }).then(({ data }) => data);

    public async revertirDocumento(documento: IDocumento) {
        await this.documentosModelo.revertirDocumento(documento);
    }

    public validarFiltros(Filtros: IFiltroGeneralDocumentoConsulta) {
        if ((!Filtros.BodegaOrigen && !Filtros.BodegaDestino) || !Filtros.TipoDocumentoId) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else return true;
    }
}
