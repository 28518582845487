import ProveedorEstado from '@infotrack/presentacion-componentes/proveedorEstado';
import React from 'react';
import FormularioDocumentosCV from './controladorVista/formularioDocumentosCV';
import TablaGestionMovimientosCV from './controladorVista/tablaGestionMovimientosCV';
import { estadoInicial, reductor } from './reductorGestionMovimientos';

const GestionMovimientos: React.FunctionComponent = () => (
    <ProveedorEstado estadoInicial={estadoInicial} reductor={reductor}>
        <TablaGestionMovimientosCV />
        <FormularioDocumentosCV />
    </ProveedorEstado>
);

export default GestionMovimientos;
