import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';

import useStyles from '../tablaDetallesPlantillaEstilos';

interface ICampoLoteProps {
    deshabilitado: boolean;
    detalle: IDocumentoDetalle;
    manejarCambioCampo: (detalleId: string, nombreCampo: keyof IDocumentoDetalle, nuevoValor: any) => void;
}

const CampoLote: FunctionComponent<ICampoLoteProps> = ({ deshabilitado, detalle, manejarCambioCampo }) => {
    const classes = useStyles();

    return (
        <TextField
            classes={{ root: classes.campo }}
            disabled={deshabilitado}
            fullWidth
            label={<Texto id="TablaDetallesPlantilla.Lote" />}
            onChange={(event) =>
                manejarCambioCampo(detalle.DocumentoDetalleId, 'Lote', event.target.value.toUpperCase())
            }
            size="small"
            value={detalle.Lote}
        />
    );
};

export default CampoLote;
