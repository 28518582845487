import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';

interface ICampoCantidadRecibidaProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampo: keyof IDocumentoDetalle,
        nuevoValor: IDocumentoDetalle[keyof IDocumentoDetalle]
    ) => any;
    valor: number | undefined;
}

const CampoCantidadRecibida: FunctionComponent<ICampoCantidadRecibidaProps> = ({
    deshabilitado,
    manejarCambioCampo,
    valor,
}) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="DetallesFormulario.CampoCantidadRecibida.Nombre" />}
        onChange={(event) => {
            if (/^\d*$/.test(event.target.value)) {
                manejarCambioCampo('CantidadRecibida', event.target.value);
            }
        }}
        value={valor}
        required
    />
);

export default CampoCantidadRecibida;
