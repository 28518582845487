import { ServiciosRest } from '@infotrack/utilitarios/servicios';
import { obtenerEstado } from 'Infotrack@Reductores/index';

const URL_SERVICIO_SEGURIDAD = process.env.REACT_APP_URL_SEGURIDAD;
const URL_SERVICIO_NEGOCIO = process.env.REACT_APP_URL_NEGOCIO;
const URL_SERVICIO_REPORTES = process.env.REACT_APP_URL_REPORTE;
const URL_MIDDLEWARE_COIMPRESORES = process.env.REACT_APP_URL_MIDDLEWARE_COIMPRESORES;
const URL_NEGOCIO_COIMPRESORES = process.env.REACT_APP_URL_NEGOCIO_COIMPRESORES;

const seguridad = new ServiciosRest(URL_SERVICIO_SEGURIDAD!, '_1nf0tr4ck*');
const seguridadToken = new ServiciosRest(URL_SERVICIO_SEGURIDAD!, '_1nf0tr4ck*', obtenerEstado);
const negocio = new ServiciosRest(URL_SERVICIO_NEGOCIO!, undefined, obtenerEstado);
const reportes = new ServiciosRest(URL_SERVICIO_REPORTES!);
const middlewareCoimpresores  = new ServiciosRest(URL_MIDDLEWARE_COIMPRESORES!,undefined, obtenerEstado);
const urlNegocioCoimpresores = new ServiciosRest(URL_NEGOCIO_COIMPRESORES!,undefined, obtenerEstado);


export { URL_SERVICIO_NEGOCIO, seguridad, negocio, seguridadToken,reportes,middlewareCoimpresores,urlNegocioCoimpresores };
