import React from 'react';

import FormularioDivisionesBodegaCV from './formularioDivisionesBodegaCV';
import TablaDivisionesBodegaCV from './tablaDivisionesBodegaCV';

const DivisionesBodegaCV: React.FunctionComponent = () => {
    return (
        <>
            <TablaDivisionesBodegaCV />
            <FormularioDivisionesBodegaCV />
        </>
    );
};

export default DivisionesBodegaCV;
