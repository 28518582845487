const mensajes = {
    // DocumentosFiltros
    'Documentos.CampoTipoDocumentoId.Nombre': 'Tipo documento',
    'Documentos.CampoEtapaId.Nombre': 'Etapa',
    'Documentos.CampoProveedorId.Nombre': 'Proveedor',
    'Documentos.CampoProveedor.Nombre': 'Descripción proveedor',
    'Documentos.CampoCodigoDocumento.Nombre': 'Codigo documento',
    'Documentos.CampoFechaEntrega.Nombre': 'Fecha entrega',
    'Documentos.CampoBodegaOrigen.Nombre': 'Bodega origen',
    'Documentos.CampoBodegaDestino.Nombre': 'Bodega destino',
    'Documentos.CampoDocumentoRelacionado.Nombre': 'Documento relacionado',
    'Documentos.CampoClienteId.Nombre': 'Cliente',
    'Documentos.CampoCliente.Nombre': 'Descripción cliente',
    'Documentos.CampoFechaInicial.Nombre': 'Fecha inicial',
    'Documentos.CampoFechaFinal.Nombre': 'Fecha final',
    'Documentos.CampoCodigoDocumentoPredecesor': 'Código documento predecesor',
    'Documentos.CampoSerial': 'Serial',
    "Documentos.SinRegistros": 'No se encontraron registros',
    "Documentos.CampoDocumentoRelacionado.NumeroTicket":"Número Ticket"
};

export default mensajes;
