import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloMovimiento from 'Infotrack@Modelos/smartStock/movimientos';
import IItemMovimientoFiltros from 'Infotrack@Modelos/smartStock/movimientos/entidades/itemMovimientoFiltros';

export default class MovimientoCM {
    private modeloMovimiento: ModeloMovimiento;
    constructor() {
        this.modeloMovimiento = new ModeloMovimiento();
    }

    public async consultarItemsMovimientosFiltro(filtros: IItemMovimientoFiltros, paginador: IPaginador) {
        const {
            data: { Entidades, Paginador },
        } = await this.modeloMovimiento.consultarItemsMovimientosFiltro({ Paginador: paginador, ItemFiltro: filtros });
        return { Entidades, Paginador };
    }

    public validarFiltros(filtros: IItemMovimientoFiltros) {
        if (!filtros.DivisionId) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else return true;
    }
}
