import IDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/documento';
import ModeloEstadisticasDashboard from 'Infotrack@Modelos/smartStock/estadisticasDashboard';
import IDocumentoPedidoFiltrosDashboard from 'Infotrack@Modelos/smartStock/estadisticasDashboard/entidades/documentoPedidoFiltrosDashboard';
import IFiltrosConsultasDashboard from 'Infotrack@Modelos/smartStock/estadisticasDashboard/entidades/filtrosConsultasDashboard';

export default class EstadisticasDashBoardCM {
  private modeloEstadisticasDashboard: ModeloEstadisticasDashboard;
  constructor() {
    this.modeloEstadisticasDashboard = new ModeloEstadisticasDashboard();
  }

  public consultarEstadisticasDashboard(filtros: IFiltrosConsultasDashboard) {
    return Promise.all([
      this.modeloEstadisticasDashboard.consultarEstadosPedidos(filtros),
      this.modeloEstadisticasDashboard.consultarCumplimientoPedidosVigentes(filtros),
      this.modeloEstadisticasDashboard.consultarCumplimientoPedidosHistorico(filtros),
      this.modeloEstadisticasDashboard.consultarTop10PedidosClientes(filtros),
    ]).then((entidades) => ({
      estadosPedidos: entidades[0].data.Entidades,
      pedidosVigentes: entidades[1].data.Entidades,
      pedidosHistoricos: entidades[2].data.Entidades,
      pedidosClientes: entidades[3].data.Entidades,
    }));
  }

  public consultarListaDocumentoEstadoPedidos(documento: IDocumento) {
    return this.modeloEstadisticasDashboard
      .consultarListaDocumentoEstadoPedidos(documento)
      .then(({ data: { Entidades } }) => Entidades);
  }

  public consultarListaCumplimientoPedidos(documento: IDocumentoPedidoFiltrosDashboard) {
    return this.modeloEstadisticasDashboard
      .consultarListaCumplimientoPedidos(documento)
      .then(({ data: { Entidades } }) => Entidades);
  }
}
