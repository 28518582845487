import { TipoFormulario, TipoAccion } from '@infotrack/presentacion-transversales/interfacesComunes';
import { AnyAction } from 'redux';
import { acciones, IEstadoDevoluciones } from './interfaces';

export const estadoInicial: IEstadoDevoluciones = {
    detalles: [],
    documento: null,
    entidadDocumento: undefined,
    entidadDocumentoDetalle: undefined,
    estadoFormulario: false,
    estadoFormularioDetalle: false,
    recargarTabla: true,
    tipoFormulario: TipoFormulario.Creacion,
};

export const reductor = (estado: IEstadoDevoluciones = estadoInicial, { type, payload }: AnyAction) => {
    switch (type) {
        case acciones.CAMBIAR_ESTADO_FORMULARIO:
            return { ...estado, estadoFormulario: !estado.estadoFormulario };

        case acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE:
            return { ...estado, estadoFormularioDetalle: !estado.estadoFormularioDetalle };

        case acciones.CARGAR_DETALLES:
            return { ...estado, detalles: payload };

        case acciones.EDITAR_DOCUMENTO_DETALLE: {
            const nuevosDocumentosDetalles = [...estado.detalles];
            const indiceAEditar = nuevosDocumentosDetalles.findIndex(
                ({ DocumentoDetalleId }) => DocumentoDetalleId === payload.DocumentoDetalleId
            );
            nuevosDocumentosDetalles[indiceAEditar] = {
                ...payload,
                Accion: TipoAccion.editado,
            };
            return {
                ...estado,
                detalles: nuevosDocumentosDetalles,
            };
        }

        case acciones.ESTABLECER_DOCUMENTO:
            return { ...estado, documento: payload };

        case acciones.ESTABLECER_ENTIDAD_DOCUMENTO:
            return {
                ...estado,
                entidadDocumento: payload,
                tipoFormulario: payload ? TipoFormulario.Edicion : TipoFormulario.Creacion,
            };

        case acciones.ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE:
            return { ...estado, entidadDocumentoDetalle: payload };

        case acciones.RECARGAR_TABLA:
            return { ...estado, recargarTabla: !estado.recargarTabla };

        default:
            return estado;
    }
};
