import React, { useState, useEffect, useRef } from 'react';
import FlujoCM from './controladorModelo/flujosCM';
import TablaFlujoEtapas from './flujoTabla/FlujoTabla';
import ModalFlujos from './flujoEtapasModal/ModalFlujos';
import { EstadosModalFlujos } from './enumeraciones';
import IFlujo from '../../../modelos/negocioRefactor/entidades/repositorio/IFlujo';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import Texto from '@infotrack/presentacion-componentes/texto';
import { useSelector } from 'react-redux';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';

const FlujoEtapas = () => {
    const esPrimeraCarga = useRef(true);
    const flujoCM = new FlujoCM();
    const idEmpresa = useSelector((estado: IEstadoGlobal) => estado.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const [flujos, setFlujo] = useState<IFlujo[]>([]);
    const [flujoEdicion, setFlujoflujoEdicion] = useState<IFlujo>();
    const [estadoModal, setEstadoModal] = useState<EstadosModalFlujos>(EstadosModalFlujos.CERRADO);
    useEffect(() => {
        consultarListaFlujoEtapas();
    }, [esPrimeraCarga]);

    const eliminarFlujoEtapaDocumento = (flujoEtapa: IFlujo) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: async () => {
                manejadorDialogoGlobal({ estado: false });
                await flujoCM.eliminarFlujos(flujoEtapa);
                await consultarListaFlujoEtapas();
            },
            estado: true,
            mensaje: <Texto id={'flujosEtapas.AdvertenciaEliminarFlujo'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const editarFlujoEtapas = async (flujoEtapa: IFlujo) => {
        setEstadoModal(EstadosModalFlujos.EDICION);
        setFlujoflujoEdicion(flujoEtapa);
    };

    const consultarListaFlujoEtapas = async () => {
        const flujo = await flujoCM.consultarFlujosPorFiltro(idEmpresa);
        setFlujo(flujo);
    };

    const cerrarModal = async () => {
        setEstadoModal(EstadosModalFlujos.CERRADO);
        consultarListaFlujoEtapas();
    };

    return (
        <>
            <TablaFlujoEtapas
                eliminarFlujo={eliminarFlujoEtapaDocumento}
                listaFlujo={flujos}
                recargarTabla={consultarListaFlujoEtapas}
                agregarFlujo={() => setEstadoModal(EstadosModalFlujos.CREACION)}
                editarFlujo={editarFlujoEtapas}
            />
            <ModalFlujos
                cerrar={cerrarModal}
                estado={estadoModal}
                flujoEdicion={flujoEdicion!}
                recargarTabla={consultarListaFlujoEtapas}
            />
        </>
    );
};

export default FlujoEtapas;
