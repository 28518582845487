import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    TextField,
    Toolbar,
    withStyles,
    makeStyles,
} from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import DocumentosDetallesCV from '../controladorVista/documentosDetallesCV';
import { IFormularioDespachosVistaProps } from '../interfaces';
import { estilos } from './estilos';
import { useMediaQuery } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import VistaDocumentoDetalles from '../../../../transversales/componentes/documentoDetalles/cardDocumentoDetallesVista';
import VistaDocumentoDetalle from '../../../../transversales/componentes/tablaDocumentoDetalle/Vista/tablaDocumentoDetalleVista';
import FormularioDocumentoDetalleCV from '../controladorVista/formularioDocumentoDetalleCV';

const useStyles = makeStyles({
    listbox: {
        padding: '8px',
        '&:last-child': {
            paddingBottom: '8px',
        },
    },
});
const CustomTextField: React.FunctionComponent<TextFieldProps> = (props) => (
    <TextField {...props} value={props.value ? props.value : ''} variant="outlined" />
);

const FormularioDespachosVista: React.FunctionComponent<PropsEstilosMaterialUI<IFormularioDespachosVistaProps>> = ({
    alCerrarFormulario,
    alCambiarValor,
    alCambiarDocumentoId,
    alGuardarDocumento,
    confimarCambioEstadoPedido,
    classes,
    documento,
    documentoId,
    entidadesDocumento,
    estadoFormulario,
    tipoFormularioDocumento,
    documentoDetalleRecepcion,
    documentoDetalleSeleccionado,
    setDocumentoDetalleSeleccionado,
    abrirModalDocumentoDetalle,
    documentoDetalleModal,
    modalDocumentoDetalleAbierto,
    tipoFormularioDocumentoDetalles,
    refrescarDocumentoDetalle,
    refrescarDocumentoDetalles,
    esRecepcion,
    terminoRecepcion,
    cerrarModalDocumentoDetalle,
}) => {
    const estilos = useStyles();
    return (
        <div>
            <Dialog open={estadoFormulario} fullScreen={true}>
                <AppBar position="static">
                    <Toolbar>
                        <Grid container justifyContent="center">
                            <Texto
                                align="center"
                                id={
                                    tipoFormularioDocumento === TipoFormulario.Edicion
                                        ? 'despachos.documento.editarDocumento'
                                        : 'despachos.documento.crearDocumento'
                                }
                            />
                        </Grid>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Grid alignItems="center" container spacing={2}>
                                        <Grid item xs={12}>
                                            <Texto id="despachos.documento.datosDocumento" fontWeight="bold" />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            {tipoFormularioDocumento !== TipoFormulario.Edicion && (
                                                <Autocomplete
                                                    disableClearable
                                                    getOptionLabel={({ CodigoDocumento }) => CodigoDocumento!}
                                                    onChange={(_: any, doc: any) => alCambiarDocumentoId(doc)}
                                                    options={entidadesDocumento.documentos}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            label={
                                                                <Texto
                                                                    id="despachos.documento.Documento"
                                                                    display="inline"
                                                                />
                                                            }
                                                        />
                                                    )}
                                                    noOptionsText={<Texto id="label.sinRegistros" />}
                                                    value={documento}
                                                />
                                            )}
                                            {tipoFormularioDocumento === TipoFormulario.Edicion && (
                                                <CustomTextField
                                                    InputProps={{ readOnly: true }}
                                                    required
                                                    label={
                                                        <Texto display="inline" id="despachos.documento.Documento" />
                                                    }
                                                    fullWidth
                                                    value={documento!.CodigoDocumento}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <CustomTextField
                                                InputProps={{
                                                    readOnly:
                                                        documento && documento.EtapaDescripcion !== TiposEtapas.Creada,
                                                }}
                                                label={
                                                    <Texto display="inline" id="despachos.documento.DireccionEntrega" />
                                                }
                                                required
                                                name="DireccionEntrega"
                                                onChange={alCambiarValor}
                                                fullWidth
                                                value={documento && documento.DireccionEntrega}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <CustomTextField
                                                InputProps={{
                                                    readOnly:
                                                        documento && documento.EtapaDescripcion !== TiposEtapas.Creada,
                                                }}
                                                label={
                                                    <Texto
                                                        display="inline"
                                                        id="despachos.documento.PersonaRelacionada"
                                                    />
                                                }
                                                required
                                                name="PersonaRelacionada"
                                                onChange={alCambiarValor}
                                                fullWidth
                                                value={documento && documento.PersonaRelacionada}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <CustomTextField
                                                InputProps={{ readOnly: true }}
                                                fullWidth
                                                label={
                                                    <Texto
                                                        display="inline"
                                                        id="despachos.documento.DescripcionCliente"
                                                    />
                                                }
                                                value={documento && documento.DescripcionCliente}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <CustomTextField
                                                InputProps={{ readOnly: true }}
                                                fullWidth
                                                label={<Texto display="inline" id="despachos.documento.BodegaOrigen" />}
                                                value={documento && documento.BodegaOrigenDescripcion}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <CustomTextField
                                                InputProps={{ readOnly: true }}
                                                fullWidth
                                                label={
                                                    <Texto display="inline" id="despachos.documento.EtapaDescripcion" />
                                                }
                                                value={documento && documento.EtapaDescripcion}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <CustomTextField
                                                label={<Texto id="despachos.documento.Observacion" />}
                                                fullWidth
                                                value={documento && documento.Observacion}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        {/*
                    <Grid item xs={12}>
                        <DocumentosDetallesCV />
                    </Grid>
                */}

                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <VistaDocumentoDetalles
                                    documentoDetalles={documentoDetalleRecepcion /*documento.documentosDetalles*/}
                                    documentoDetalleSeleccionado={documentoDetalleSeleccionado}
                                    setDocumentoDetalleSeleccionado={setDocumentoDetalleSeleccionado}
                                    //documentoSeleccionadoRefrescar={documentoSeleccionadoRefrescar}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <VistaDocumentoDetalle
                                    documentoDetalle={documentoDetalleSeleccionado}
                                    abrirModalDocumentoDetalle={abrirModalDocumentoDetalle}
                                    refrescarDocumentoDetalles={refrescarDocumentoDetalles}
                                    refrescarDocumentoDetalle={refrescarDocumentoDetalle}
                                    ///botonAgregarRecepcion={botonAgregarRecepcion}
                                    esRecepcion={esRecepcion}
                                    terminoRecepcion={terminoRecepcion}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" spacing={2}>
                        {tipoFormularioDocumento === TipoFormulario.Visualizacion ? (
                            <Grid item>
                                <Button color="primary" onClick={alCerrarFormulario} variant="contained">
                                    <Texto id="boton.cerrar" />
                                </Button>
                            </Grid>
                        ) : (
                            <>
                                <Grid item>
                                    <Button color="secondary" onClick={alCerrarFormulario} variant="contained">
                                        <Texto id="boton.cancelar" />
                                    </Button>
                                </Grid>
                                {tipoFormularioDocumento === TipoFormulario.Edicion && (
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            disabled={
                                                documento &&
                                                documento.EtapaDescripcion === TiposEtapas.EntregaConfirmada
                                            }
                                            onClick={confimarCambioEstadoPedido}
                                            variant="contained"
                                        >
                                            <Texto id="despachos.modificarEtapa" />
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button color="primary" onClick={alGuardarDocumento} variant="contained">
                                        <Texto id="boton.guardar" />
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogActions>
            </Dialog>
            <FormularioDocumentoDetalleCV
                abierto={modalDocumentoDetalleAbierto}
                documentoDetalle={documentoDetalleModal}
                cerrarModal={cerrarModalDocumentoDetalle}
                refrescarDocumentoDetalle={refrescarDocumentoDetalle}
                tipoFormulario={tipoFormularioDocumentoDetalles}
                //documento={documento.documento}
            />
        </div>
    );
};

export default withStyles(estilos)(FormularioDespachosVista);
