import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloDocumentoDetalle from 'Infotrack@Modelos/smartStock/documentosDetalle';
import IDocumentoDetalle from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalle';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import TipoMovimientoDescripcion from 'Infotrack@Modelos/smartStock/tiposMovimientos/entidades/tipoMovimientoDescripcion';

export default class ControladorModeloDocumento {
    private modeloDocumentoDetalle: ModeloDocumentoDetalle;
    constructor() {
        this.modeloDocumentoDetalle = new ModeloDocumentoDetalle();
    }

    public async consultarListaDocumentosDetalles(DocumentoId: string) {
        const {
            data: { Entidades },
        } = await this.modeloDocumentoDetalle.consultaListaDocumentoDetallesPorFiltro({ DocumentoId });
        return Entidades.map((entidad) => ({ ...entidad, Accion: 0 }));
    }

    public validarDocumentoDetalle(
        documentoDetalle: IDocumentoDetalleCompuesto,
        tipoDocumento: TipoMovimientoDescripcion
    ) {
        const {
            CantidadSolicitada,
            DivisionDestino,
            DivisionOrigen,
            FechaVencimiento,
            FEFO,
            ItemId,
            SeriadoItems,
            Serial,
            LotesItems,
            Lote,
        } = documentoDetalle;
        if (
            !ItemId ||
            !CantidadSolicitada ||
            (tipoDocumento === 'Entrada' && !DivisionDestino) ||
            (tipoDocumento === 'Salida' && !DivisionOrigen) ||
            (tipoDocumento !== 'Entrada' && tipoDocumento !== 'Salida' && (!DivisionDestino || !DivisionOrigen)) ||
            (LotesItems && !Lote) ||
            (SeriadoItems && !Serial) ||
            (FEFO && !FechaVencimiento)
        ) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else return true;
    }

    public AlmacenamientoDetallesMasivo(documentoDetalle: IDocumentoDetalle) {
        return this.modeloDocumentoDetalle
            .AlmacenamientoDetallesMasivo(documentoDetalle)
            .then(({ data: { Entidades } }) => Entidades);
    }

    public EditarDocumentoDetalle(documentoDetalle: IDocumentoDetalle) {
        return this.modeloDocumentoDetalle.editarDocumentosDetalles(documentoDetalle);
    }

    public GuardarDocumentoDetalle(documentoDetalle: IDocumentoDetalle) {
        return this.modeloDocumentoDetalle.guardarDocumentosDetalles(documentoDetalle);
    }

    public EliminarDocumentoDetalle(documentoDetalle: IDocumentoDetalle) {
        return this.modeloDocumentoDetalle.eliminarDocumentosDetalles(documentoDetalle);
    }
}
