import { TextField } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';

import useStyles from '../DetallesInventarioEstilos';
import { Autocomplete } from '@material-ui/lab';

interface ICampoDivisionDestinoProps {
    deshabilitado: boolean;
    divisiones: IBodegaDivisiones[];
    manejarCambio: (nuevoValor: any) => void;
    valor?: number;
}

const CampoDivisionDestino: FunctionComponent<ICampoDivisionDestinoProps> = ({
    deshabilitado,
    divisiones,
    manejarCambio,
    valor,
}) => {
    const classes = useStyles();
    console.log(divisiones,"CampoDivisiones");
    return (
        <Autocomplete
            classes={{ root: classes.campo }}
            disabled={deshabilitado}
            onChange={(_: ChangeEvent<{}>, value: IBodegaDivisiones | null) =>
                manejarCambio(value ? value.DivisionId : undefined)
            }
            options={divisiones || []}
            getOptionLabel={(d) => d.DivisionDescripcion}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    label={<Texto id="TablaDetallesPlantilla.DivisionDestino" />}
                    required
                />
            )}
            value={divisiones ? divisiones.find((d) => d.DivisionId === valor) || null : null}
            noOptionsText={<Texto id="TablaDetallesPlantilla.SinDivisionDestino" />}
        />
    );
};

export default CampoDivisionDestino;
