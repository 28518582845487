import ModeloBodegasDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones';
import IBodegaDivisionFiltros from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegaDivisionFiltros';
import ModeloTiposDocumentos from 'Infotrack@Modelos/smartStock/tiposDocumentos';
import ITipoDocumento from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/tipoDocumento';

export default class EntidadesDivisionesDocumentoCM {
    private modeloBodegasDivisiones: ModeloBodegasDivisiones;
    private modeloTiposDocumentos: ModeloTiposDocumentos;

    constructor() {
        this.modeloBodegasDivisiones = new ModeloBodegasDivisiones();
        this.modeloTiposDocumentos = new ModeloTiposDocumentos();
    }

    public consultarDivisionesFiltro(divisionFiltro: IBodegaDivisionFiltros, tipoDocFiltros: Partial<ITipoDocumento>) {
        return Promise.all([
            this.modeloBodegasDivisiones.consultarDivisionesFiltro(divisionFiltro),
            this.modeloTiposDocumentos.ConsultarTipoDocumentoFiltro(tipoDocFiltros),
        ]).then((entidades) => ({
            divisiones: entidades[0].data.Entidades,
            tiposDocumentos: entidades[1].data.Entidades,
        }));
    }
}
