import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocio } from 'Infotrack@Modelos/conexiones';
import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';

class ModeloAuditoriaConteo {
    private NOMBRE_CONTROLADOR = 'AuditoriaConteo';

    public async crearConteo(auditoriaConteo: IAuditoriaConteo) {
        return manejadorRest<IRespuesta<IAuditoriaConteo>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/Crear`, auditoriaConteo),
            true
        );
    }

    public async consultarListaConteo(filtro: Partial<IAuditoriaConteo>) {
        return manejadorRest<IRespuesta<IAuditoriaConteo>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/ConsultarLista`, filtro),
            true
        );
    }

    public async consultarConteoAuditoria(auditoriaConteoId: string) {
        return manejadorRest<IRespuesta<IAuditoriaConteo>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/Consultar/${auditoriaConteoId}`, {}),
            true
        );
    }

    public async marcarConteo(auditoriaConteoId: string) {
        return manejadorRest<IRespuesta<IAuditoriaConteo>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/MarcarConteo/${auditoriaConteoId}`, {}),
            true
        );
    }
}

export default ModeloAuditoriaConteo;
