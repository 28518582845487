export default {
  'sidebar.administracion': 'Administration',
  'sidebar.grupoactividadeconomica': 'Economic Activity Group',
  'grupoActividadEconomica.agregarGrupoActividadEconomica': 'Create Economic Activity Group',
  'grupoActividadEconomica.confirmacionCreacion': 'Are you sure to create the registry?',
  'grupoActividadEconomica.confirmacionEdicion': 'You are sure you want to edit the registry?',
  'grupoActividadEconomica.confirmacionEliminacion': 'Are you sure you want to delete the registry?',
  'grupoActividadEconomica.modificarGrupoActividadEconomica': 'Edit Economic Activity Group',
  'grupoActividadEconomica.eliminarGrupoActividadEconomica': 'Delete Economic Activity Group',
  'grupoActividadEconomica.codigoGrupoActividadEconomica': 'Code Economic Activity Group',
  'grupoActividadEconomica.nombreGrupoActividadEconomica': 'Name Economic Activity Group',
  'grupoActividadEconomica.estadoGrupoActividadEconomica': 'State',
  'grupoActividadEconomica.grupoActividadEconomica': 'Economic Activity Group',
};
