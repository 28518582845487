export default {
  'persona.municipios': 'Ciudad',
  'persona.cargos': 'Cargo',
  'persona.numeroIdentificacion': 'Número identificación',
  'persona.primerNombre': 'Primer nombre',
  'persona.segundoNombre': 'Segundo nombre',
  'persona.primerApellido': 'Primer apellido',
  'persona.segundoApellido': 'Segundo apellido',
  'persona.direccion': 'Dirección',
  'persona.telefonos': 'Teléfono',
  'persona.estado': 'Estado',
  'persona.emailPrincipal': 'Correo electrónico',
  'persona.celular': 'Celular',
  'persona.alerta.emailIncorrecto': 'El correo ingresado no es válido.',
  'persona.alerta.numeroDocumentoIncorrecto': 'El número de documento debe ser númerico, por favor verifique.'
};
