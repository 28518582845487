import { Card, CardContent, Fab, Grid } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import React, { FunctionComponent, useState, useEffect } from 'react';

import CampoCodigoDocumentoBase from './CampoCodigoDocumentoBase';

interface IDocumentoBuscadorProps {
    consultaDocumentoBase: (codigoDocumentoBase: string) => void;
    refrescarCampo?: boolean;
}

const DocumentoBuscador: FunctionComponent<IDocumentoBuscadorProps> = ({ consultaDocumentoBase, refrescarCampo }) => {
    const [codigoDocumentoBase, setCodigoDocumentoBase] = useState<string>('');

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item>
                        <CampoCodigoDocumentoBase
                            manejarCambio={(nuevoValor) => {
                                //nuevoValor = nuevoValor.replace(/ /g, '')
                                setCodigoDocumentoBase(nuevoValor.replace(/ /g, ''));
                            }}
                            valor={codigoDocumentoBase}
                            refrescar={refrescarCampo}
                        />
                    </Grid>
                    <Grid item>
                        <Fab
                            color="primary"
                            onClick={() => consultaDocumentoBase(codigoDocumentoBase)}
                            size="small"
                            disabled={codigoDocumentoBase === ''}
                        >
                            <SearchIcon />
                        </Fab>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
export default DocumentoBuscador;
