import ModeloBodegas from 'Infotrack@Modelos/smartStock/bodegas';
import IBodegaPersonaFiltro from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaPersonaFiltro';
import ModeloEtapas from 'Infotrack@Modelos/smartStock/etapas';

export default class EntidadesFiltroDocumentoCM {
    private modeloBodegas: ModeloBodegas;
    private modeloEtapas: ModeloEtapas;
    constructor() {
        this.modeloBodegas = new ModeloBodegas();
        this.modeloEtapas = new ModeloEtapas();
    }

    public consultarEntidadesFiltro(bodegaPersonaFiltro: IBodegaPersonaFiltro) {
        return Promise.all([
            this.modeloBodegas.consultarBodegasPersonasDoc(bodegaPersonaFiltro),
            this.modeloEtapas.consultarEtapas(),
        ]).then((entidades) => ({ bodegas: entidades[0].data.Entidades, etapas: entidades[1].data.Entidades }));
    }
}
