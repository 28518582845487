import ModeloBodegas from 'Infotrack@Modelos/smartStock/bodegas';
import IBodegaPersonaFiltro from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaPersonaFiltro';
import ModeloClientes from 'Infotrack@Modelos/smartStock/clientes';

export default class EntidadesFiltrosCM {
    private modeloBodegas: ModeloBodegas;
    private modeloClientes: ModeloClientes;
    constructor() {
        this.modeloBodegas = new ModeloBodegas();
        this.modeloClientes = new ModeloClientes();
    }

    public consultarEntidadesFiltros(bodegaPersonaFiltro: IBodegaPersonaFiltro) {
        return Promise.all([
            this.modeloBodegas.consultarBodegasPersonasDoc(bodegaPersonaFiltro),
            this.modeloClientes.consultarClientes(),
        ]).then((entidades) => ({
            bodegas: entidades[0].data.Entidades,
            clientes: entidades[1].data.Entidades.filter((cliente) => ({
                ...cliente,
                DescripcionCliente: cliente.DescripcionSucursal
                    ? `${cliente.DescripcionCliente} | ${cliente.DescripcionSucursal}`
                    : cliente.DescripcionCliente,
            })),
        }));
    }
}
