import { IRespuesta, IRespuestaPaginada } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocio } from 'Infotrack@Modelos/conexiones';
import IDocumentoFiltroPaginador from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltroPaginador';
import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import IDocumentoBaseConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoBaseConsulta';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';

class ModeloSalidaInventario {
    private NOMBRE_CONTROLADOR = 'SalidaInventario';

    public async editarDetalle(detalle: IDocumentoDetalle) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.put(`${this.NOMBRE_CONTROLADOR}/EditarDetalle`, detalle),
            true
        );
    }

    public async eliminarDetalle(detalle: IDocumentoDetalle) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.delete(`${this.NOMBRE_CONTROLADOR}/EliminarDetalle`, detalle),
            true
        );
    }

    public async guardarDetalle(detalles: IDocumentoDetalle[]) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/GuardarDetalle`, detalles),
            true
        );
    }

    public async consultarDocumentoBaseSalidaInventario(documentoBase: IDocumentoBaseConsulta) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/ConsultaDocumentoBase`,documentoBase),
            true
        );
    }

    public async guardarEncabezado(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/GuardarEncabezado`, documento),
            true
        );
    }

    public async editarEncabezado(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/EditarEncabezado`, documento),
            true
        );
    }

    public async anularDocumento(documentoId: string) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/AnularDocumento/${documentoId}`, {}),
            true
        );
    }

    public async consultarListaDocumentos(documentoFiltroPaginador: IDocumentoFiltroPaginador) {
        return manejadorRest<IRespuestaPaginada<IDocumentoConsultaGeneral>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/ConsultarListaDocumentos`, documentoFiltroPaginador),
            true
        );
    }

    public async consultarDetallesDocumento(filtro: Partial<IDocumentoDetalle>) {
        return manejadorRest<IRespuesta<IDocumentoDetalleConsultaGeneral>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/ConsultarDetallesDocumento`, filtro),
            true
        );
    }

    public async consultarEncabezadoDocumento(filtro: Partial<IDocumento>) {
        return manejadorRest<IRespuesta<IDocumentoConsultaGeneral>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/ConsultarEncabezadoDocumento`, filtro),
            true
        );
    }

    public async consultarEtapas(documentoId: string) {
        return manejadorRest<IRespuesta<IEtapas>>(
            () => negocio.get(`${this.NOMBRE_CONTROLADOR}/ConsultarEtapas/${documentoId}`),
            true
        );
    }

    public async cambiarEtapa(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocio.put(`${this.NOMBRE_CONTROLADOR}/CambiarEtapa`, documento),
            true
        );
    }

    public async generarArchivoPDF(documentoId: string, TipoDocumentoId: number) {
        return negocio.get(`${this.NOMBRE_CONTROLADOR}/GenerarReporte/${documentoId}/${TipoDocumentoId}`, undefined, {
            responseType: 'arraybuffer',
        });
    }
}
export default ModeloSalidaInventario;
