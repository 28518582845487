import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IPlantillaImpresion from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IPlantillaImpresion';

interface ICampoPlantillaImpresionDescripcionProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampo: keyof IPlantillaImpresion,
        nuevoValor: IPlantillaImpresion[keyof IPlantillaImpresion]
    ) => any;
    valor: string;
}

const CampoPlantillaImpresionDescripcion: FunctionComponent<ICampoPlantillaImpresionDescripcionProps> = ({
    deshabilitado,
    manejarCambioCampo,
    valor,
}) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="PlantillaImpresion.CampoPlantillaImpresionDescripcion.Nombre" />}
        onChange={(event) => manejarCambioCampo('PlantillaImpresionDescripcion', event.target.value)}
        required
        value={valor}
    />
);

export default CampoPlantillaImpresionDescripcion;
