import { Dialog, DialogContent, Grid } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Texto from '@infotrack/presentacion-componentes/texto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';
import IItemsConsulta from 'Infotrack@Modelos/smartStock/items/entidades/IItemsConsulta';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import DetallesFormulario from 'Infotrack@Transversales/componentes/DetallesFormulario/DetallesFormulario';
import EstadosDetallesFormulario from 'Infotrack@Transversales/componentes/DetallesFormulario/enumeraciones';
import DocumentoValorTotal from 'Infotrack@Transversales/componentes/DocumentoValorTotal/DocumentoValorTotal';
import { EstadosFormularioDocumento } from 'Infotrack@Transversales/componentes/FormularioDocumento/enumeraciones';
import FormularioDocumento, {
    IEntidadesFormularioDocumento,
} from 'Infotrack@Transversales/componentes/FormularioDocumento/FormularioDocumento';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import TablaDetalles from 'Infotrack@Transversales/componentes/TablaDetalles/TablaDetalles';

import {
    CAMPOS_DESHABILITAR_DETALLES_FORMULARIO_EDICION,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION,
    CAMPOS_OCULTAR_DETALLES_FORMULARIO,
    CAMPOS_OCULTAR_DETALLES_TABLA,
    CAMPOS_OCULTAR_DOCUMENTO_FORMULARIO,
    ENTIDADES_DOCUMENTO_POR_DEFECTO,
} from './constantes';
import EntidadesDetalleCM from './controladorModelo/EntidadesDetalleCM';
import EntidadesDocumentoCM from './controladorModelo/EntidadesDocumentoCM';
import OrdenEntradaCM from './controladorModelo/OrdenEntradaCM';
import { EstadosModalOrdenEntrada } from './enumeraciones';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';
interface IModalOrdenEntradaProps {
    cerrar: () => void;
    documentoId: string | null;
    estado: EstadosModalOrdenEntrada;
    descargarDocumentoPdf: (documento: IDocumentoConsultaGeneral) => any;
}

const ModalOrdenEntrada: FunctionComponent<IModalOrdenEntradaProps> = ({
    cerrar,
    documentoId,
    estado,
    descargarDocumentoPdf,
}) => {
    const entidadesDocumentoCM = useMemo(() => new EntidadesDocumentoCM(), []);
    const ordenEntradaCM = useMemo(() => new OrdenEntradaCM(), []);
    const entidadesDetalleCM = useMemo(() => new EntidadesDetalleCM(), []);

    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);

    const [entidadesDocumento, setEntidadesDocumento] = useState<Partial<IEntidadesFormularioDocumento>>(
        ENTIDADES_DOCUMENTO_POR_DEFECTO
    );
    const [documento, setDocumento] = useState<IDocumentoConsultaGeneral | null>(null);
    const [etapasSiguientes, setEtapasSiguientes] = useState<IEtapas[]>([]);
    const [entidadesDetalle, setEntidadesDetalle] = useState<{
        items: IItemsConsulta[];
        divisionesDestino: IBodegaDivisiones[];
    }>({ items: [], divisionesDestino: [] });
    const [detalles, setDetalles] = useState<IDocumentoDetalleConsultaGeneral[]>([]);
    const [detalleInicialEdicion, setDetalleInicialEdicion] = useState<IDocumentoDetalle | null>(null);
    const [ejecutarConsultarDocumentos, setEjecutarConsultarDocumentos] = useState<boolean>(false);
    const [estadoDetallesFormulario, setEstadoDetallesFormulario] = useState<EstadosDetallesFormulario>(
        EstadosDetallesFormulario.CREACION
    );

    useEffect(() => {
        alCambiarEstado();
    }, [estado]);
    useEffect(() => {
        consultarDetalles();
    }, [ejecutarConsultarDocumentos]);

    useEffect(() => {
        if (documento) alEstablecerDocumento();
    }, [documento]);

    const alCambiarEstado = async () => {
        if (estado === EstadosModalOrdenEntrada.CERRADO) {
            setDocumento(null);
            setDetalles([]);
            setEntidadesDocumento(ENTIDADES_DOCUMENTO_POR_DEFECTO);
            setEtapasSiguientes([]);
            setEntidadesDetalle({ items: [], divisionesDestino: [] });
            setDetalleInicialEdicion(null);
        }
        if (estado === EstadosModalOrdenEntrada.CREACION) {
            await consultarEntidadesDocumento();
        }
        if (estado === EstadosModalOrdenEntrada.EDICION || estado === EstadosModalOrdenEntrada.VISUALIZACION) {
            await consultarDocumento(documentoId!);
            await consultarEntidadesDocumentoEdicion();
        }
    };

    const consultarEntidadesDocumento = async () => {
        const entidadesDocumentoConsultadas = await entidadesDocumentoCM.consultarEntidadesDocumento(
            idEmpresa,
            idAgencia,
            idUsuario,
            estadoActivo
        );
        setEntidadesDocumento(entidadesDocumentoConsultadas);
    };

    const consultarEntidadesDocumentoEdicion = async () => {
        const entidadesDocumentoConsultadas = await entidadesDocumentoCM.consultarEntidadesDocumentoEdicion(
            documento !== null ? documento.BodegaDestino! : 0, ///documento!.BodegaDestino!,
            idEmpresa,
            idAgencia,
            idUsuario,
            TiposProceso.ORDEN_DE_ENTRADA,
            estadoActivo
        );
        setEntidadesDocumento(entidadesDocumentoConsultadas);
    };

    const consultarDocumento = async (documentoIdConsultar: string) => {
        if (documentoIdConsultar !== null) {
            const documentoConsultado = await ordenEntradaCM.consultarEncabezadoDocumento({
                DocumentoId: documentoIdConsultar,
            });
            setDocumento(documentoConsultado);
        }
    };

    const alEstablecerDocumento = async () => {
        if (estado === EstadosModalOrdenEntrada.CREACION) {
            await consultarEtapasSiguientes();
            await consultarEntidadesDetalle();
        }
        if (estado === EstadosModalOrdenEntrada.EDICION) {
            await consultarEtapasSiguientes();
            await consultarEntidadesDetalle();
            await consultarDetalles();
            await consultarEntidadesDocumentoEdicion();
        }
        if (estado === EstadosModalOrdenEntrada.VISUALIZACION) {
            await consultarDetalles();
            await consultarEntidadesDocumentoEdicion();
        }
    };

    const consultarEtapasSiguientes = async () => {
        const etapasSiguientesConsultadas = await ordenEntradaCM.consultarEtapas(documento!.DocumentoId);
        setEtapasSiguientes(etapasSiguientesConsultadas);
    };

    const consultarEntidadesDetalle = async () => {
        const entidadesDetalleConsultadas = await entidadesDetalleCM.consultarEntidadesDetalle(
            documento!.BodegaDestino!,
            documento!.TipoDocumentoId,
            idEmpresa
        );
        setEntidadesDetalle(entidadesDetalleConsultadas);
    };

    const consultarDetalles = async () => {
        if (documento !== null) {
            const detallesConsultados = await ordenEntradaCM.consultarDetallesDocumento({
                DocumentoId: documento!.DocumentoId!,
            });
            setDetalles(detallesConsultados);
        }
    };

    const crearDocumento = async (documentoCrear: IDocumento) => {
        const respuesta = await ordenEntradaCM.guardarEncabezado(mapearDocumentoCrear(documentoCrear));
        if (respuesta.Resultado) await consultarDocumento(respuesta.Entidades[0].DocumentoId);
    };

    const mapearDocumentoCrear = (documentoCrear: IDocumento): IDocumento => {
        return {
            ...documentoCrear,
            EmpresaId: idEmpresa,
            AgenciaId: idAgencia,
        };
    };

    const editarDocumento = async (documentoEditar: IDocumento) => {
        const respuesta = await ordenEntradaCM.editarEncabezado(documentoEditar);
        if (respuesta.Resultado) await consultarDocumento(documento!.DocumentoId);
    };

    const cambiarEtapa = async (etapaId: number) => {
        manejadorDialogoGlobal({ estado: false });
        const cambioEtapaExitoso = await ordenEntradaCM.cambiarEtapa({ ...documento!, EtapaId: etapaId });
        if (cambioEtapaExitoso) {
            await consultarDocumento(documento!.DocumentoId);
        }
    };

    const confirmarCambiarEtapa = async (etapaId: number) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => cambiarEtapa(etapaId),
            estado: true,
            mensaje: <Texto id={'etapas.CambioEtapa'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const agregarDetalles = async (detallesAgregar: IDocumentoDetalle[]) => {
        return ordenEntradaCM.guardarDetalle(
            detallesAgregar.map((detalle) => ({
                ...detalle,
                FechaIngreso: '',
            }))
        );
    };

    const editarDetalle = async (detalle: IDocumentoDetalle) => {
        setEstadoDetallesFormulario(EstadosDetallesFormulario.CREACION);
        return ordenEntradaCM.editarDetalle(detalle);
    };

    const confirmarEliminarDetalle = async (detalle: IDocumentoDetalle) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => eliminarDetalle(detalle),
            estado: true,
            mensaje: <Texto id={'alerta.confirmacionEliminacion'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const eliminarDetalle = async (detalle: IDocumentoDetalle) => {
        manejadorDialogoGlobal({ estado: false });
        const respuesta = await ordenEntradaCM.eliminarDetalle(detalle);
        if (respuesta) {
            if (
                detalleInicialEdicion !== null &&
                detalle.DocumentoDetalleId === detalleInicialEdicion.DocumentoDetalleId
            ) {
                setDetalleInicialEdicion({
                    DocumentoDetalleId: '',
                    Estado: 0,
                    Lote: '',
                    Serial: '',
                    DocumentoId: '',
                    AgenciaId: '',
                    CantidadEntregada: 0,
                    CantidadRecibida: 0,
                    CantidadSolicitada: 0,
                    DivisionDestino: 0,
                    DivisionOrigen: 0,
                    DocumentoDetallePredecesorId: '',
                    EmpresaId: '',
                    FechaIngreso: '',
                    FechaVencimiento: '',
                    ItemId: 0,
                    Valor: 0,
                });
                setEstadoDetallesFormulario(EstadosDetallesFormulario.CREACION);
            }

            await consultarDetalles();
        }
    };

    const consultarTipoDocumento = async (idBodega: number, nombreNodega?: string) => {
        if (nombreNodega === 'BodegaDestino') {
            const tiposDocumentosBodega = await entidadesDocumentoCM.consultarTipoDocumento(idBodega, estadoActivo);
            setEntidadesDocumento({ ...entidadesDocumento, tiposDocumento: tiposDocumentosBodega });
        }
    };

    return (
        <Dialog open={estado !== EstadosModalOrdenEntrada.CERRADO} maxWidth="lg">
            <ModalEncabezado
                cerrar={() => {
                    cerrar(), setEstadoDetallesFormulario(EstadosDetallesFormulario.CREACION);
                }}
                titulo={
                    estado === EstadosModalOrdenEntrada.CREACION ? (
                        <Texto id="ModalOrdenEntrada.TituloCreacion" />
                    ) : estado === EstadosModalOrdenEntrada.EDICION ? (
                        <Texto id="ModalOrdenEntrada.TituloEdicion" />
                    ) : estado === EstadosModalOrdenEntrada.VISUALIZACION ? (
                        <Texto id="ModalOrdenEntrada.TituloVisualizacion" />
                    ) : (
                        undefined
                    )
                }
            />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormularioDocumento
                            cambiarEtapa={confirmarCambiarEtapa}
                            camposDeshabilitar={
                                (estado === EstadosModalOrdenEntrada.CREACION && documento) ||
                                estado === EstadosModalOrdenEntrada.EDICION
                                    ? CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION
                                    : CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO
                            }
                            camposOcultar={CAMPOS_OCULTAR_DOCUMENTO_FORMULARIO}
                            documento={documento}
                            editarDocumento={editarDocumento}
                            entidadesDocumento={entidadesDocumento}
                            estado={
                                !documento
                                    ? EstadosFormularioDocumento.CREACION
                                    : (estado === EstadosModalOrdenEntrada.CREACION && documento) ||
                                      estado === EstadosModalOrdenEntrada.EDICION
                                    ? EstadosFormularioDocumento.EDICION
                                    : EstadosFormularioDocumento.VISUALIZACION
                            }
                            etapasSiguientes={etapasSiguientes}
                            guardarDocumento={crearDocumento}
                            descargarDocumentoPdf={descargarDocumentoPdf}
                            consultarTipoDocumento={consultarTipoDocumento}
                            mostrarModalEvidencia={false}
                            esTraslado={false}
                        />
                    </Grid>
                    {estado !== EstadosModalOrdenEntrada.VISUALIZACION && (
                        <Grid item xs={12}>
                            <DetallesFormulario
                                agregarDetalles={agregarDetalles}
                                alAgregarDetalles={consultarDetalles}
                                alEditarDetalle={async () => {
                                    setDetalleInicialEdicion(null);
                                    await consultarDetalles();
                                }}
                                camposDeshabilitar={
                                    detalleInicialEdicion && detalleInicialEdicion.ItemId !== 0
                                        ? CAMPOS_DESHABILITAR_DETALLES_FORMULARIO_EDICION
                                        : []
                                }
                                camposOcultar={CAMPOS_OCULTAR_DETALLES_FORMULARIO}
                                deshabilitado={
                                    !documento ||
                                    (estadoDetallesFormulario === EstadosDetallesFormulario.CREACION &&
                                        !documento.ListaAcciones.PermiteAgregarDetalle)
                                }
                                detalleInicialEdicion={detalleInicialEdicion}
                                documentoId={documento ? documento.DocumentoId : ''}
                                editarDetalle={editarDetalle}
                                entidades={entidadesDetalle}
                                estado={estadoDetallesFormulario}
                                tipoCantidad="CantidadSolicitada"
                                seriadoConsulta={false}
                                esEntradaInventario={false}
                                bloquearCantidadSerial={false}
                                esOrdenTraslado={false}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TablaDetalles
                            camposOcultar={CAMPOS_OCULTAR_DETALLES_TABLA}
                            detalles={detalles}
                            editarDetalle={(detalle: IDocumentoDetalleConsultaGeneral) => {
                                setDetalleInicialEdicion(detalle);
                                setEstadoDetallesFormulario(EstadosDetallesFormulario.EDICION);
                            }}
                            editarDetalleDeshabilitado={
                                documento ? !documento.ListaAcciones.PermiteEditarDetalle : false
                            }
                            eliminarDetalle={confirmarEliminarDetalle}
                            eliminarDetalleDeshabilitado={
                                documento ? !documento.ListaAcciones.PermiteEliminarDetalle : false
                            }
                            mostrarAcciones={
                                (estado === EstadosModalOrdenEntrada.CREACION && Boolean(documento)) ||
                                estado === EstadosModalOrdenEntrada.EDICION
                            }
                            recargarTabla={consultarDetalles}
                            titulo={<Texto fontWeight="bold" id="ModalOrdenEntrada.TituloTablaDetalles" />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DocumentoValorTotal detalles={detalles} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ModalOrdenEntrada;
