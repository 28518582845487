import ProveedorEstado from '@infotrack/presentacion-componentes/proveedorEstado';
import React from 'react';
import FormularioAuditoriasCV from './controladorVista/formularioAuditoriasCV';
import TablaAuditoriasCV from './controladorVista/tablaAuditoriasCV';
import { estadoInicial, reductor } from './reductorGestionAuditorias';

const GestionAuditorias: React.FunctionComponent = () => (
    <ProveedorEstado estadoInicial={estadoInicial} reductor={reductor}>
        <TablaAuditoriasCV />
        <FormularioAuditoriasCV />
    </ProveedorEstado>
);

export default GestionAuditorias;
