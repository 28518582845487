import { ServiciosRest } from "@infotrack/utilitarios/servicios";
import ModeloReporte from "Infotrack@Modelos/smartStock/reportes";
import IReporteConsulta from "Infotrack@Modelos/smartStock/reportes/entidades/IReporteConsulta";


class ReporteCM {
    private modeloReporte: ModeloReporte;

    constructor(negocio: ServiciosRest) {
        this.modeloReporte = new ModeloReporte(negocio);
    }


    public async consultarParametrosReportes(nombreRepoprte: string, idAplicacion : string) {
        const respuesta = await this.modeloReporte.consultarParametrosReporte(nombreRepoprte, idAplicacion);
        return respuesta.data.Entidades[0];
    }
}

export default ReporteCM;
