import ModeloDocumentoDetalle from '../../../../modelos/documentoDetalle/index';
import IDocumentoDetalleCompuesto from '../../../../modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';

export default class DocumentoDetalleCM {
    private documentoDetalleCM: ModeloDocumentoDetalle;

    constructor() {
        this.documentoDetalleCM = new ModeloDocumentoDetalle();
    }

    public ConsultardocumentoDetalle(documentoDetalle: Partial<IDocumentoDetalleCompuesto>) {
        return this.documentoDetalleCM
            .ConsultarDocumentoDetalle(documentoDetalle)
            .then(({ data: { Entidades } }) => Entidades);
    }
    public async eliminarDocumentosDetalles(documentoDetalle: IDocumentoDetalleCompuesto) {
        return this.documentoDetalleCM.eliminarDocumentosDetalles(documentoDetalle);
    }
}
