export default {
    'sidebar.procesos': 'Procesos',
    'sidebar.recepcion': 'Recepción',
    'recepcion.bodega': 'Bodega',
    'recepcion.proveedor': 'Proveedor',
    'recepcion.confirmarRecepcion': 'Confirmar recepción',
    'recepcion.numeroDocumento': 'N° Documento',
    'recepcion.documentoPadre': 'Documento padre',
    'recepcion.documentos': 'Documentos',
    'recepcion.datosDocumento': 'Datos documento',
    'recepcion.detallesDocumento': 'Detalles del documento',
    'recepcion.bodegaDestino': 'Bodega destino',
    'recepcion.etapaDescripcion': 'Etapa',
    'recepcion.observaciones': 'Observaciones',
    'recepcion.documentoDetalle.DescripcionItem': 'Producto',
    'recepcion.documentoDetalle.CodigoBarras': 'Código de barras',
    'recepcion.documentoDetalle.CantidadSolicitada': 'Cantidad solicitada',
    'recepcion.documentoDetalle.CantidadRecibida': 'Cantidad recibida',
    'recepcion.documentosdocumentoDetalle.CantidadActual': ' Cantidad actual',
    'recepcion.documentoDetalle.iniciarRecepcion': 'Iniciar recepción',
    'recepcion.documentoDetalle.confirmarRecepcion': 'Confirmar recepción',
    'recepcion.documentos.verDetalle': 'Ver detalle',
    'recepcion.documentos.detalle': 'Detalle documento',
    'recepcion.documentos.alertaFiltroBodega': 'Por favor seleccione una bodega',
    'recepcion.documentos.alertaFiltroProveedorDocumento': 'Por favor seleccione un campo de búsqueda',
    'recepcion.documentos.alertaFiltroProveedorSinBodega': 'Para filtrar por proveedor debe seleccionar una bodega',
    'recepcion.documentos.alertaLotes': 'Este producto requiere registrar un número de lote',
    'recepcion.documentos.alertaSeriado': 'Este producto requiere registrar un número de Seriado',
    'recepcion.documentos.alertaCantidadRecibida': 'La cantidad recibida debe ser mayor a la ingresada anteriormente',
    'recepcion.documentos.alertaFIFO': 'Este producto requiere una fecha de vencimiento',
    'recepcion.documentosdocumentoDetalle.edicionParcial': 'Parcial',
    'recepcion.documentosdocumentoDetalle.edicionTotal': 'Total',
    'recepcion.documentosdocumentoDetalle.recepcion': 'Recepción',
    'recepcion.documentoDetalle.DivisionDestino': 'División de destino',
    'recepcion.documentosdocumentoDetalle.CantidadRecibida': 'Cantidad',
    'recepcion.documentosdocumentoDetalle.Lote': 'Lote',
    'recepcion.documentosdocumentoDetalle.FechaVencimiento': 'Fecha vencimiento',
    'recepcion.documentosdocumentoDetalle.Serial': 'Serial',
    'recepcion.confirmacionInicioRecepcion': '¿Está seguro que desea iniciar la recepción?',
    'recepcion.confirmacionFinRecepcion': '¿Está seguro que desea terminar la recepción?',
    'recepcion.procesos.campoBodegaObligatorio': 'El campo bodega es obligatorio',
    'recepcion.CantidadSolicitada': 'Cantidad Solicitada',
    'recepcion.CantidadRecibida': 'Cantidad Recibida ',

    // TablaRecepcion
    'TablaRecepcion.CodigoDocumentoPredecesor': 'Código',
    'TablaRecepcion.DocumentoRelacionadoPredecesor': 'Documento relacionado',
    'TablaRecepcion.CodigoDocumento': 'Código documento hijo',
    'TablaRecepcion.FechaCreacion': 'Fecha creación',
    'TablaRecepcion.DescripcionProveedor': 'Proveedor',
    'TablaRecepcion.EtapaDescripcionDocumentoPredecesor': 'Etapa',
    'TablaRecepcion.ObservacionDocumentoPredecesor': 'Observación',
    'TablaRecepcion.BotonGestionarRecepcion': 'Gestionar recepción',
    'TablaRecepcion.BotonAnular': 'Anular',
    'TablaRecepcion.MensajeConfirmacionAnularDocumento': '¿Está seguro que desea anular el documento?',
    // ModalRecepcion
    'ModalRecepcion.TituloTablaDetallesPlantilla': 'Detalles orden compra',
    'ModalRecepcion.ValidacionNoCantidad': 'La cantidad debe ser mayor a 0',
    'ModalRecepcion.ValidacionNoPeso': 'El peso debe ser mayor a 0',
    'ModalRecepcion.ValidacionNoDivisionOrigen': 'Debe seleccionar una división de origen',
    'ModalRecepcion.ValidacionNoDivisionDestino': 'Debe seleccionar una división de destino',
    'ModalRecepcion.ValidacionNoLote': 'Debe especificar un lote',
    'ModalRecepcion.ValidacionSerialesIncompletos': 'Debe completar todos los seriales',
    'ModalRecepcion.TituloTablaDetalles': 'Detalles recepción',
    'ModalRecepcion.BotonConfirmarRecepcion': 'Confirmar recepción',
    'ModalRecepcion.ConfirmacionFinalizarRecepcion': '¿Está seguro que desea finalizar la recepción?',
    // FormularioDetallesRecepcion
    'FormularioDetallesRecepcion.Titulo': 'Agregar detalles',
    'FormularioDetallesRecepcion.CantidadRecibida': 'Cantidad',
    'FormularioDetallesRecepcion.DivisionDestino': 'División destino',
    'FormularioDetallesRecepcion.Lote': 'Lote',
    'FormularioDetallesRecepcion.Valor': 'Peso',
    'FormularioDetallesRecepcion.FechaVencimiento': 'Fecha vencimiento',
    'FormularioDetallesRecepcion.TooltipAsignarSeriales': 'Asignar seriales',
};
