export default {
  'sidebar.monitorpedidos': 'Monitor orders',
  'monitorPedidos.Bodegas': 'warehouses',
  'monitorPedidos.Clientes': 'Clients',
  'monitorPedidos.estadoPedidos': 'Order Status',
  'monitorPedidos.cumplimientoPedidos': 'Order fulfillment',
  'monitorPedidos.cumplimientoPedidosHistorico': 'Historic Order Fulfillment',
  'monitorPedidos.top10PedidosClientes': 'Top 10 customer orders',
  'monitorPedidos.Creada': 'Created',
  'monitorPedidos.Alistado': 'Enlisted',
  'monitorPedidos.EnZonaDespacho': 'In office area',
  'monitorPedidos.Atrasados': 'Overdue',
  'monitorPedidos.Planeados': 'Planned',
  'monitorPedidos.ProximosAVencer': 'Next to beat',
  'monitorPedidos.CodigoDocumento': 'Document code',
  'monitorPedidos.FechaCreacion': 'Creation date',
  'monitorPedidos.DescripcionCliente': 'Client',
  'monitorPedidos.Observacion': 'Observation',
  'monitorPedidos.documentos': 'Documents',
  'monitorPedidos.reporte': 'View report',
  'monitorPedidos.Anulado': 'Anulado',
  'monitorPedidos.EnAlistamiento': 'En alistamiento',
  'monitorPedidos.Tránsito': 'Tránsito',
};
