import ModeloClasificaciones from 'Infotrack@Modelos/smartStock/clasificaciones';
import ModeloFamilia from 'Infotrack@Modelos/smartStock/familias';
import ModeloProducto from 'Infotrack@Modelos/smartStock/gestionProducto';
import IParametrosItem from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/parametrosItem';
import ModeloUnidades from 'Infotrack@Modelos/smartStock/unidades';

export default class EntidadesFiltroCM {
  private modeloClasificaciones: ModeloClasificaciones;
  private modeloFamilia: ModeloFamilia;
  private modeloUnidades: ModeloUnidades; 

  constructor() {
    this.modeloClasificaciones = new ModeloClasificaciones();
    this.modeloFamilia = new ModeloFamilia();
    this.modeloUnidades = new ModeloUnidades();
  }

  public consultaEntidadesFiltro(EmpresaId: string) {
    return Promise.all([
      this.modeloClasificaciones.consultarListaClasificacionesPorFiltro({ EmpresaId }),
      this.modeloFamilia.consultarListaFamiliasPorFiltro({ EmpresaId }),
      this.modeloUnidades.consultarUnidadesFiltro({ EmpresaId}),
    ]).then((entidades) => ({
      clasificaciones: entidades[0].data.Entidades,
      familias: entidades[1].data.Entidades,
      unidades: entidades[2].data.Entidades,
    }));
  }
}
