import { Divider, Grid, Toolbar, withStyles } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Texto from '@infotrack/presentacion-componentes/texto';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import estilos from './estilos';

const EncabezadoVista: React.FunctionComponent<PropsEstilosMaterialUI> = ({ classes }) => {
    const rutaDivida = location.pathname.split('/').map((ruta) => ruta.toLowerCase());
    const app = rutaDivida[1];
    const moduloActual = rutaDivida[2];
    const subActual = rutaDivida[3];
    return (
        <Toolbar disableGutters className={classes.encabezadoAplicacion}>
            <Grid container justifyContent="space-between" alignItems="center">
                {subActual && <Texto id={`sidebar.${subActual}`} variant="h5" />}
                {!subActual && moduloActual && <Texto id={`sidebar.${moduloActual}`} variant="h5" />}
                <div>
                    <Grid container alignItems="center">
                        {app && <Texto id={`sidebar.${app}`} variant="body1" />}
                        {moduloActual && (
                            <>
                                <KeyboardArrowRightIcon />
                                <Texto id={`sidebar.${moduloActual}`} />
                            </>
                        )}
                        {subActual && (
                            <>
                                <KeyboardArrowRightIcon />
                                <Texto id={`sidebar.${subActual}`} fontWeight="bold" />
                            </>
                        )}
                    </Grid>
                </div>
            </Grid>
            <Divider variant="fullWidth" />
        </Toolbar>
    );
};

export default withStyles(estilos)(EncabezadoVista);
