import { IModulo, ISubModulo } from '@infotrack/presentacion-transversales/interfacesComunes';
import PaginaNoEncontrada from 'Infotrack@Principal/baseGeneral/componentes/paginaNoEncontrada';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IRutasVistaProps } from '../interfaces';

const RutasVista: React.FunctionComponent<IRutasVistaProps> = ({ rutaActiva, rutaBaseActiva, modulos }) => (
    <Switch>
        {modulos.reduce((rutas: React.ReactNode[], ruta: IModulo<React.ReactNode>) => {
            rutas.push(
                <Route
                    exact
                    key={ruta.rutaModulo}
                    render={() => <Redirect to={`${ruta.rutaModulo}/${ruta.rutaSubModuloPorDefecto}`} />}
                    path={`/${rutaActiva}/${ruta.rutaModulo}`}
                />
            );
            rutas = rutas.concat(
                ruta.subModulos.length !== 0 &&
                    ruta.subModulos.map((subModulo: ISubModulo<React.ReactNode>) => (
                        <Route
                            exact
                            key={subModulo.rutaSubModulo}
                            component={subModulo.componente as any}
                            path={`/${rutaActiva}/${ruta.rutaModulo}/${subModulo.rutaSubModulo}`}
                        />
                    ))
            );
            return rutas;
        }, [])}
        {!rutaBaseActiva && <Route component={PaginaNoEncontrada} />}
    </Switch>
);

export default RutasVista;
