import { createStyles, Theme } from '@material-ui/core';
import { paletaMaterialD } from '@infotrack/presentacion-transversales/constantes';

const estilos = ({ spacing }: Theme) =>
    createStyles({
        alertaAdvertencia: { backgroundColor: paletaMaterialD.orange_50, color: paletaMaterialD.orange_500 },
        alertaError: { backgroundColor: paletaMaterialD.red_50, color: paletaMaterialD.red_500 },
        alertaExito: { backgroundColor: paletaMaterialD.green_50, color: paletaMaterialD.green_500 },
        alertaInformativa: { backgroundColor: paletaMaterialD.blue_50, color: paletaMaterialD.blue_500 },
        botonAdvertencia: {
            backgroundColor: paletaMaterialD.orange_500,
            color: paletaMaterialD.white,
            '&:hover': { backgroundColor: paletaMaterialD.orange_700 },
        },
        botonError: {
            backgroundColor: paletaMaterialD.red_500,
            color: paletaMaterialD.white,
            '&:hover': { backgroundColor: paletaMaterialD.red_700 },
        },
        botonExito: {
            backgroundColor: paletaMaterialD.green_500,
            color: paletaMaterialD.white,
            '&:hover': { backgroundColor: paletaMaterialD.green_700 },
        },
        botonInformativa: {
            backgroundColor: paletaMaterialD.blue_500,
            color: paletaMaterialD.white,
            '&:hover': { backgroundColor: paletaMaterialD.blue_700 },
        },
        contenedor: {
            minWidth: '200px',
            padding: spacing(1, 2),
        },
        indicadorTipoAlerta: {
            padding: spacing(0, 1),
        },
    });

export default estilos;
