import { CircularProgress, withStyles } from '@material-ui/core';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import estilos from './estilos';

export interface IIndicadorCargaGlobalVistaProps {
  estado: boolean;
}

const IndicadorCargaGlobalVista: React.FunctionComponent<PropsEstilosMaterialUI<IIndicadorCargaGlobalVistaProps>> = ({
  classes,
  estado,
}) => <div className={estado ? classes.indicadorProgreso : ''}>{estado && <CircularProgress />}</div>;

export default withStyles(estilos)(IndicadorCargaGlobalVista);
