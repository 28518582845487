import { Dialog, DialogContent, Grid, TextField } from '@material-ui/core';
import React, { createRef, FunctionComponent, useEffect, useMemo, useState } from 'react';

import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import Texto from '@infotrack/presentacion-componentes/texto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

import InputSerial from './InputSerial/InputSerial';
import InputSerialConsulta from './InputSerialConsulta/InputSerialConsulta';
import ModalSerialesAcciones from './ModalSerialesAcciones/ModalSerialesAcciones';
import ModalSerialesEncabezado from './ModalSerialesEncabezado/ModalSerialesEncabezado';
import useStyles from './modalSerialesEstilos';
import MovimientoCM from './controladorModelo/MovimientoCM';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import { useSelector } from 'react-redux';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import IMovimientoSerialItemDivision from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoSerialItemDivision';
import moment from 'moment';
import IMovimientoItemPesoConsulta from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoItemPesoConsulta';

interface IModalSerialesProps {
    abierto: boolean;
    cerrar: () => void;
    guardarSeriales: (seriales: string[]) => void;
    serialesConsecutivos: boolean;
    serialesIniciales: string[];
    detalle?: IDocumentoDetalleConsultaGeneral | null;
    seriadoConsulta: boolean;
    mostrarPeso: boolean;
    guardarPesoSerial?: (peso: string) => void;
    pesoSerial?: string;
}

const ModalSeriales: FunctionComponent<IModalSerialesProps> = ({
    abierto,
    cerrar: cerrarProp,
    guardarSeriales,
    serialesConsecutivos,
    serialesIniciales,
    detalle,
    seriadoConsulta,
    mostrarPeso,
    guardarPesoSerial,
    pesoSerial,
}) => {
    const [peso, setPeso] = useState<string>('');
    const [seriales, setSeriales] = useState<string[]>([]);
    const [inicioSerialesConsecutivos, setInicioSerialesConsecutivos] = useState<string>('');
    const [baseSerialesConsecutivos, setBaseSerialesConsecutivos] = useState<string>('');
    const movimientoCM = useMemo(() => new MovimientoCM(), []);
    const referenciasInputs = Array.from({ length: serialesIniciales.length }, createRef);
    const [serialesItems, setSerialesItems] = useState<IMovimientoSerialItemDivision[]>([]);
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const classes = useStyles();

    useEffect(() => {
        if (abierto) {
            setSeriales(serialesIniciales);
        }
        if (seriadoConsulta && detalle !== null && detalle !== undefined) {
            consultarItemSerial(detalle.DivisionOrigen!, detalle.ItemId!, idEmpresa, detalle.PesoFraccionRecibido!);
        }
        setSeriales(serialesIniciales);
    }, [abierto]);

    useEffect(() => {
        establecerSerialesConsecutivos();
    }, [inicioSerialesConsecutivos, baseSerialesConsecutivos]);

    useEffect(() => {
        if (pesoSerial || pesoSerial === '') {
            setPeso(pesoSerial);
        }
    }, [pesoSerial]);

    const manejarCambioSerial = (indice: number, nuevoValor: string) => {
        console.log(nuevoValor);
        const copiaSeriales = [...seriales];
        copiaSeriales[indice] = nuevoValor;
        setSeriales(copiaSeriales);
    };

    const establecerSerialesConsecutivos = () => {
        const primerSerial = parseInt(inicioSerialesConsecutivos, 10);
        if (isNaN(primerSerial)) {
            setSeriales(serialesIniciales);
            return;
        }
        const nuevosSeriales = [];
        for (let i = primerSerial; i < primerSerial + seriales.length; i++) {
            nuevosSeriales.push(baseSerialesConsecutivos + i.toString());
        }
        setSeriales(nuevosSeriales);
    };

    const calcularSerialRepetido = (indice: number, serial: string) => {
        if (serial === '' || serial === null) return false;
        const copiaSeriales = [...seriales];
        copiaSeriales.splice(indice, 1);
        return copiaSeriales.includes(serial);
    };

    const alEnviar = (indice: number) => {
        if (indice === seriales.length - 1) return;
        (referenciasInputs[indice + 1].current as HTMLInputElement).focus();
    };

    const validarSeriales = async (serialesValidar: string[]) => {
        return new Promise((resolve) => {
            if (
                !serialesConsecutivos &&
                serialesValidar.some((serial, indice) => calcularSerialRepetido(indice, serial))
            ) {
                notificacionGlobal('ModalSeriales.ValidacionNoSerialesRepetidos', 3000, 'warning', true);
                resolve(false);
            } else if (
                !serialesConsecutivos &&
                serialesValidar.some((serial) => serial !== '') &&
                serialesValidar.some((serial) => serial === '')
            ) {
                manejadorDialogoGlobal({
                    accionCancelar: () => {
                        manejadorDialogoGlobal({ estado: false });
                        resolve(false);
                    },
                    accionConfirmar: () => {
                        manejadorDialogoGlobal({ estado: false });
                        resolve(true);
                    },
                    mostrarCancelar: true,
                    estado: true,
                    mensaje: <Texto id={'ModalSeriales.SerialesIncompletos'} />,
                });
            } else {
                resolve(true);
            }
        });
    };

    const validarSerialesValidos = (serialesValidar: string[]) => {
        let esValido: boolean = true;
        serialesValidar.find((x) => {
            if (x === '') {
                esValido = false;
            }
        });
        if (!esValido) {
            notificacionGlobal('ModalSeriales.ValidacionSerialesIncompletos', 3000, 'warning', true);
        }
        return esValido;
    };

    const cerrar = () => {
        setInicioSerialesConsecutivos('');
        setBaseSerialesConsecutivos('');
        cerrarProp();
        //setPeso('');
    };

    const consultarItemSerial = async (idDivision: number, idItem: number, idEmpresa: string, peso: number) => {
        let serialesConsulta = await movimientoCM.consultarItemSerialPeso({
            IdDivision: idDivision,
            IdItem: idItem,
            Peso: peso,
            IdEmpresa: idEmpresa,
        });
        let consultaSerialesFiltro = filtroListaSerialesConsultado(serialesConsulta);
        setSerialesItems(consultaSerialesFiltro);
    };

    const filtroListaSerialesConsultado = (serialesConsulta: IMovimientoSerialItemDivision[]) => {
        return serialesConsulta.filter((serial) => {
            if (detalle !== null && detalle !== undefined) {
                return (
                    ((detalle.FechaIngreso !== '' &&
                        moment(serial.FechaIngreso).format('YYYY-MM-DD') ===
                            moment(detalle.FechaIngreso).format('YYYY-MM-DD')) ||
                        detalle.FechaIngreso === '') &&
                    ((detalle.FechaVencimiento !== '' &&
                        moment(serial.FechaVencimiento).format('YYYY-MM-DD') ===
                            moment(detalle.FechaVencimiento).format('YYYY-MM-DD')) ||
                        detalle.FechaVencimiento === '') &&
                    ((detalle.Lote !== '' && serial.Lote === detalle.Lote) || detalle.Lote === '')
                );
            }
        });
    };

    return (
        <Dialog open={abierto}>
            <ModalSerialesEncabezado cerrar={cerrar} />
            <DialogContent>
                <Grid container spacing={2}>
                    {serialesConsecutivos && (
                        <>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label={<Texto id="ModalSeriales.Base" />}
                                    onChange={(event) => {
                                        if (/^\S*$/.test(event.target.value)) {
                                            setBaseSerialesConsecutivos(event.target.value.toUpperCase());
                                        }
                                    }}
                                    value={baseSerialesConsecutivos}
                                    inputProps={{ maxLength: 20 }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label={<Texto id="ModalSeriales.InicioSerialesConsecutivos" />}
                                    onChange={(event) => {
                                        if (/^\d*$/.test(event.target.value)) {
                                            setInicioSerialesConsecutivos(event.target.value);
                                        }
                                    }}
                                    value={inicioSerialesConsecutivos}
                                    inputProps={{ maxLength: 5 }}
                                />
                            </Grid>
                        </>
                    )}
                    {!seriadoConsulta && (
                        <Grid classes={{ root: classes.contenedorSeriales }} item xs={12}>
                            <Grid container spacing={1}>
                                {mostrarPeso && (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={<Texto id="ModalSeriales.Peso" />}
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                if (/^\d*$/.test(event.target.value)) {
                                                    guardarPesoSerial ? guardarPesoSerial(value) : '';
                                                }
                                            }}
                                            value={peso}
                                            inputProps={{ maxLength: 20 }}
                                        />
                                    </Grid>
                                )}
                                {seriales.map((serial, indice) => (
                                    <Grid item key={indice} xs={12}>
                                        <InputSerial
                                            alEnviar={alEnviar}
                                            deshabilitado={serialesConsecutivos}
                                            indice={indice}
                                            manejarCambio={manejarCambioSerial}
                                            referencia={referenciasInputs[indice]}
                                            serialRepetido={calcularSerialRepetido(indice, serial)}
                                            valor={serial}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}

                    {seriadoConsulta && (
                        <Grid classes={{ root: classes.contenedorSeriales }} item xs={12}>
                            <Grid container spacing={1}>
                                {mostrarPeso && (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={<Texto id="ModalSeriales.Peso" />}
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                if (/^\d*$/.test(event.target.value)) {

                                                    guardarPesoSerial ? guardarPesoSerial(value) : '';
                                                }
                                            }}
                                            value={peso}
                                            inputProps={{ maxLength: 20 }}
                                        />
                                    </Grid>
                                )}
                                {seriales.map((serial, indice) => (
                                    <Grid item key={indice} xs={12}>
                                        <InputSerialConsulta
                                            alEnviar={alEnviar}
                                            deshabilitado={serialesConsecutivos}
                                            indice={indice}
                                            manejarCambio={manejarCambioSerial}
                                            referencia={referenciasInputs[indice]}
                                            serialRepetido={calcularSerialRepetido(indice, serial)}
                                            valor={serial}
                                            listaSeriales={serialesItems.map((x) => x.Serial)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <ModalSerialesAcciones
                cancelar={cerrar}
                guardar={async () => {
                    if (validarSerialesValidos(seriales)) {
                        const validacionExitosa = await validarSeriales(seriales);
                        if (!validacionExitosa) return;
                        const serialesFiltrados = seriales.filter((serial) => serial);
                        guardarSeriales(serialesFiltrados);
                        cerrar();
                    }
                }}
            />
        </Dialog>
    );
};

export default ModalSeriales;
