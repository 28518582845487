import ModeloBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones';
import ModeloItems from 'Infotrack@Modelos/smartStock/items';

class EntidadesConteoDetalleCM {
    private modeloBodegaDivision: ModeloBodegaDivisiones;
    private modeloItem: ModeloItems;

    constructor() {
        this.modeloBodegaDivision = new ModeloBodegaDivisiones();
        this.modeloItem = new ModeloItems();
    }

    public async consultarEntidadesDetalle(idAuditoria: string) {
        const respuestas = await Promise.all([
            this.modeloItem.consultarListaItemsAuditoria(idAuditoria),
            this.modeloBodegaDivision.consultarDivisionesAuditoria(idAuditoria),
        ]);
        return {
            items: respuestas[0].data.Entidades,
            divisionesDestino: respuestas[1].data.Entidades,
        };
    }
}

export default EntidadesConteoDetalleCM;
