import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';
import { negocio } from '../../conexiones';
import IFiltrosItemsTiposPropiedades from './entidades/filtrosTiposPropiedad';
import IItemTiposPropiedad from './entidades/itemTiposPropiedad';
export default class ModeloItemTiposPropiedad {
    public consultarItemsTiposPropiedades(filtrosItemsTiposPropiedades: IFiltrosItemsTiposPropiedades) {
        return manejadorRest<IRespuesta<IItemTiposPropiedad>>(
            () =>
                negocio.post('ItemTipoPropiedad/ConsultarItemTipoPropiedadFiltro', {
                    filtrosItemsTiposPropiedades,
                }),
            true
        );
    }
}
