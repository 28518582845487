import { createStyles } from '@material-ui/core';

const estilos = createStyles({
  indicadorProgreso: {
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.8)',
    bottom: '0px',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    margin: '0 auto',
    overflow: 'hidden',
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 1301,
  },
});

export default estilos;
