import React, { useState } from 'react';
import MovimientoCM from '../controladorModelo/movimientoCM';
import TablaConsultaInventarioVista from '../vista/tablaConsultaInventarioVista';
import { acciones, IEstadoConsultaInventario } from '../reductorConsultaInventario';
import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';
import IMovimientoCompuesto from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoCompuesta';
import moment from 'moment';
const movimientoCM = new MovimientoCM();
const TablaConsultaInventarioCV: React.FunctionComponent = () => {
    const [{ estadoFormularioConsulta, filtros }, dispatch] = useProveedor<IEstadoConsultaInventario>();
    const refTabla = React.createRef<any>();

    React.useEffect(() => {
        if (refTabla.current) cargarMovimientos();
    }, [refTabla.current]);

    const consultaMovimientos = paginacionRemota(
        movimientoCM.consultarMovimientosCompuestoFiltro,
        filtros,
        'FechaMovimiento'
    );

    const consultarMovimientosParaDescarga = async () => {
        const paginador: IPaginador = { Pagina: 0, PropiedadesDeOrdenamiento: [{ NombrePropiedad: "FechaMovimiento", Orden: 1 }], Registros: refTabla.current.state.query.totalCount };
        const consulta: IMovimientoCompuesto[] = await (await movimientoCM.consultarMovimientosCompuestoFiltro(paginador, filtros)).Entidades;
        return consulta.map(x => {
            return {
                "Bodega": x.BodegaDescripcion,
                "División": x.DivisionDescripcion,
                "Producto": x.DescripcionItem,
                "Tipo Movimiento": x.TipoMovimientoDescripcion,
                "Documento": x.CodigoDocumento,
                "Cantidad": x.Cantidad,
                "Cantidad Total": x.CantidadTotal,
                "Serial": x.Serial,
                "Lote": x.Lote,
                "Fecha De Vencimiento": x.FechaVencimiento != null ? moment(x.FechaVencimiento).format('YYYY-MM-DD HH:mm') : "",
                "Fecha de Movimiento": x.FechaMovimiento != null ? moment(x.FechaMovimiento).format('YYYY-MM-DD HH:mm') : "",
                "Fecha de Ingreso": x.FechaIngreso != null ? moment(x.FechaIngreso).format('YYYY-MM-DD HH:mm') : "",
                "Valor": x.Valor,
                "Peso": x.Peso,
                "Estado": x.Estado == 1 ? "Activo" : "Inactivo"
            }
        });
    }

    const alCerrarDialogo = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
        dispatch({ type: acciones.LIMPIAR_FILTROS });
    };

    const cargarMovimientos = () => {
        refTabla.current!.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
    };

    return (
        <TablaConsultaInventarioVista
            alCerrarDialogo={alCerrarDialogo}
            consultaMovimientos={consultaMovimientos}
            estadoDialogo={estadoFormularioConsulta}
            refTabla={refTabla}
            consultarMovimientosParaDescarga={consultarMovimientosParaDescarga}
        />
    );
};

export default TablaConsultaInventarioCV;
