import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';

import ValorEnPesos from '../ValorEnPesos/ValorEnPesos';
import useStyles from './documentoValorTotalEstilos';

interface IDocumentoValorTotalProps {
    detalles: IDocumentoDetalleConsultaGeneral[];
}

const DocumentoValorTotal: FunctionComponent<IDocumentoValorTotalProps> = ({ detalles }) => {
    const classes = useStyles();

    return (
        <Card variant="outlined">
            <CardHeader
                classes={{ root: classes.titulo }}
                title={<Texto fontWeight="bold" id="DocumentoValorTotal.Titulo" variant="body1" />}
            />
            <CardContent>
                <Grid container justifyContent="center">
                    <Grid item>
                        <ValorEnPesos
                            valor={detalles.reduce(
                                (valorTotalDocumento, detalle) =>
                                    detalle.Valor ? valorTotalDocumento + detalle.Valor : 0,
                                0
                            )}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default DocumentoValorTotal;
