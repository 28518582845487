import DatosDocumentoEvidencia from '../../../../modelos/smartStock/documentoEvidencia/entidades/datosDocumentoEvidencia';
import ModeloDocumentoEvidencia from '../../../../modelos/smartStock/documentoEvidencia';
import IDocumentoEvidencia from '../../../../modelos/smartStock/documentoEvidencia/entidades/documentoEvidencia';

export default class IDocumentoEvidenciaCM {
    private modeloDocumentoEvidencia: ModeloDocumentoEvidencia;
    constructor() {
        this.modeloDocumentoEvidencia = new ModeloDocumentoEvidencia();
    }
    public GuardarDocumentoEvidencia(documentoEvidencia: Partial<IDocumentoEvidencia>, archivo: File, token: string) {
        return this.modeloDocumentoEvidencia.GuardarDocumentoEvidencia(documentoEvidencia, archivo, token);
    }

    public EliminarDocumentoEvidencia(documentoEvidencia: Partial<DatosDocumentoEvidencia>) {
        return this.modeloDocumentoEvidencia
            .EliminarDocumentoEvidencia(documentoEvidencia)
            .then(({ data: { Entidades } }) => Entidades);
    }

    public ConsultarDocumentoEvidencia(documentoEvidencia: Partial<DatosDocumentoEvidencia>) {
        return this.modeloDocumentoEvidencia
            .ConsultarDocumentoEvidencia(documentoEvidencia)
            .then(({ data: { Entidades } }) =>
                Entidades.map((entidad, indice) => ({
                    ...entidad,
                    Archivo: `data:${entidad.TipoMime};base64,${entidad.Archivo}`,
                }))
            );
    }

    validarTipoDoumento = (archivoTipo: string) => {
        let tipoArchivo: string = '';

        if (archivoTipo.includes('image')) {
            tipoArchivo = 'application/pdf';
        }
        if (archivoTipo.includes('pdf')) {
            tipoArchivo = 'application/pdf';
        }

        return tipoArchivo;
    };
}
