export default {
  'sidebar.gestionoperador': 'Gestión operador',
  'gestionOperadores.Asignacion': 'Asignado',
  'gestionOperadores.Varios': 'Varios',
  'gestionoperadores.documentosusuarios': 'Usuarios del documento',
  'gestionoperadores.NombreUsuario': 'Nombre usuario',
  'gestionoperadores.crearDocumentoUsuario': 'Asignar usuario documento',
  'gestionoperadores.editarDocumentoUsuario': 'Editar usuario documento',
  'boton.asignar': 'Asignar',

  'gestionoperadores.alerta.ResponsableFinalizacion': 'Debe asignar un usuario como responsable de finalización',
};
