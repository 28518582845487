import { CambioContrasenia } from '@infotrack/presentacion-modulosbase';
import { seguridadToken } from 'Infotrack@Modelos/conexiones';
import React from 'react';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const CambioContraseniaModulo: React.FunctionComponent = () => {
    const nombreAplicacion = useSelector(
        (e: IEstadoGlobal) => e.estadoAutenticacion.usuarioAplicacionAgenciaActual!.NombreAplicacion
    );
    const estado = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.Estado);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);
    const aceptaTerminos = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.AceptaTerminos);
    const history = useHistory();  
  
    const Cerrar = () => {
        history.push(`/${nombreAplicacion}`);
    };
    return (
        <CambioContrasenia
            seguridad={seguridadToken}
            aceptaTerminosCondiciones={aceptaTerminos}
            estado={estado}
            idUsuario={idUsuario}
            cerrar={Cerrar}
        />
    );
};

export default CambioContraseniaModulo;
