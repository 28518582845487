import ModeloBodegas from 'Infotrack@Modelos/smartStock/bodegas';
import IBodegaPersonaFiltro from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaPersonaFiltro';
import ModeloDocumentos from 'Infotrack@Modelos/smartStock/documentos';
import IFiltrosDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/filtrosDocumento';
import ModeloProveedores from 'Infotrack@Modelos/smartStock/proveedores';
import ModeloTiposDocumentos from 'Infotrack@Modelos/smartStock/tiposDocumentos';

export default class EntidadesDocumentoCM {
    private modeloBodegas: ModeloBodegas;
    private modeloDocumentos: ModeloDocumentos;
    private modeloProveedores: ModeloProveedores;
    private modeloTiposDocumentos: ModeloTiposDocumentos;

    constructor() {
        this.modeloBodegas = new ModeloBodegas();
        this.modeloDocumentos = new ModeloDocumentos();
        this.modeloProveedores = new ModeloProveedores();
        this.modeloTiposDocumentos = new ModeloTiposDocumentos();
    }

    public consultarListaEntidadesDocumento({
        AgenciaId,
        EmpresaId,
        UsuarioId,
    }: IBodegaPersonaFiltro & { EmpresaId: string }) {
        return Promise.all([
            this.modeloBodegas.consultarBodegasPersonasDoc({ UsuarioId, AgenciaId, EmpresaId }),
            this.modeloProveedores.consultarListaProveedoresPorFiltro({ EmpresaId }),
            //this.modeloTiposDocumentos.ConsultarListaTiposDocumentosMovimientoFiltro({ EmpresaId }),
        ]).then((entidades) => ({
            bodegas: entidades[0].data.Entidades,
            proveedores: entidades[1].data.Entidades,
            //tiposDocumentos: entidades[2].data.Entidades,
        }));
    }

    public consultarListaDocumentosPadre({ TipoDocumentoId }: IFiltrosDocumento) {
        return this.modeloDocumentos
            .consultaListaDocumentosPorFiltro({ DocumentoPadre: 1, Estado: 1, TipoDocumentoId })
            .then(({ data: { Entidades } }) => Entidades);
    }
}
