import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';

import { EstadosModalAuditoria } from './enumeraciones';
import ModalAuditoria, { IEntidadesAuditoria } from './ModalAuditoria/ModalAuditoria';
import AuditoriaCM from './controladorModelo/AuditoriaCM';
import AuditoriaTabla from './AuditoriaTabla/AuditoriaTabla';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import Texto from '@infotrack/presentacion-componentes/texto';
import IInformacionAuditoria from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IInformacionAuditoria';

const AUDITORIA_POR_DEFECTO: IAuditoriaCompuesta = {
    AuditoriaId: '',
    BodegaId: 0,
    Estado: 0,
    TipoDocumentoId: 0,
    EtapaId: 0,
    Divisiones: [],
    Familias: [],
    Items: [],
};

const Auditoria = () => {
    const auditoriaCM = useMemo(() => new AuditoriaCM(), []);

    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);

    const [auditorias, setAuditorias] = useState<IAuditoriaCompuesta[]>([]);

    const [estadoModal, setEstadoModal] = useState<EstadosModalAuditoria>(EstadosModalAuditoria.CERRADO);
    const [auditoriaInicial, setAuditoriaInicial] = useState<IAuditoriaCompuesta>(AUDITORIA_POR_DEFECTO);

    const esPrimeraCarga = useRef(true);

    useEffect(() => {
        consultarAuditorias();
    }, []);

    useEffect(() => {
        if (esPrimeraCarga.current) {
            esPrimeraCarga.current = false;
        } else {
            if (estadoModal == EstadosModalAuditoria.CERRADO) {
                consultarAuditorias();
            }
        }
    }, [estadoModal]);

    const consultarAuditorias = async () => {
        const filtroConsultaAuditoria: Partial<IInformacionAuditoria> = {
            EmpresaId: idEmpresa,
            AgenciaId: idAgencia,
            IdUsuario: idUsuario
        };
        const auditoriaConsultados = await auditoriaCM.consultarListaAuditoria(filtroConsultaAuditoria);
        setAuditorias(auditoriaConsultados);
    };

    const abrirModalCreacion = () => {
        setEstadoModal(EstadosModalAuditoria.CREACION);
    };

    const abrirModalVisualizacion = (auditoriaVer: IAuditoriaCompuesta) => {
        setEstadoModal(EstadosModalAuditoria.VISUALIZACION);
        setAuditoriaInicial(auditoriaVer);
    };

    const abrirModalGestion = (auditoriaGestionar: IAuditoriaCompuesta) => {
        setEstadoModal(EstadosModalAuditoria.GESTION);
        setAuditoriaInicial(auditoriaGestionar);
    };

    const cerrarModal = () => {
        setEstadoModal(EstadosModalAuditoria.CERRADO);
        setAuditoriaInicial(AUDITORIA_POR_DEFECTO);
    };

    const confirmarAnularDocumento = (documentoIdAnular: string) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => anularDocumento(documentoIdAnular),
            estado: true,
            mensaje: <Texto id={'MensajeAuditoria.confirmarAnularDocumento'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const anularDocumento = async (documentoId: string) => {
        const anuladoExitoso = await auditoriaCM.anularAuditoria(documentoId);
        if (anuladoExitoso) {
            consultarAuditorias();
            manejadorDialogoGlobal({ estado: false });
        }
    };

    return (
        <>
            <AuditoriaTabla
                crearAuditoria={abrirModalCreacion}
                verAuditoria={abrirModalVisualizacion}
                gestionarAuditoria={abrirModalGestion}
                anularAuditoria={confirmarAnularDocumento}
                listaAuditoria={auditorias}
                recargarTabla={consultarAuditorias}
            />
            <ModalAuditoria
                auditoriaInicial={auditoriaInicial}
                cerrar={cerrarModal}
                estado={estadoModal}
                cambioEstadoModalAuditoriaFinalizada={() => setEstadoModal(EstadosModalAuditoria.VISUALIZACION)}
            />
        </>
    );
};

export default Auditoria;
