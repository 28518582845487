export default {
  'sidebar.administracion': 'Administración',
  'sidebar.tipocontribuyente': 'Tipo Contribuyente',
  'tipoContribuyente.agregarTipoContribuyente': 'Crear Tipo Contribuyente',
  'tipoContribuyente.confirmacionCreacion': '¿Está seguro que desea crear el registro?',
  'tipoContribuyente.confirmacionEdicion': '¿Está seguro que desea editar el registro?',
  'tipoContribuyente.confirmacionEliminacion': '¿Está seguro que desea eliminar el registro?',
  'tipoContribuyente.modificarTipoContribuyente': 'Editar Tipo Contribuyente',
  'tipoContribuyente.codigoTipoContribuyente': 'Código Tipo Contribuyente',
  'tipoContribuyente.nombreTipoContribuyente': 'Nombre Tipo Contribuyente',
  'tipoContribuyente.estadoTipoContribuyente': 'Estado'
};
