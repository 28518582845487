export default {
  'sidebar.administracion': 'Administration',
  'sidebar.modulo': 'Module',
  'modulo.agregarModulo': 'Create Module',
  'modulo.confirmacionCreacion': 'Are you sure to create the registry?',
  'modulo.confirmacionEdicion': 'You are sure you want to edit the registry?',
  'modulo.confirmacionEliminacion': 'Are you sure you want to delete the registry?',
  'modulo.modificarModulo': 'Edit Module',
  'modulo.eliminarModulo': 'Delete Module',
  'modulo.nombreModulo': 'Name Module',
  'modulo.modulo': 'Module',
};
