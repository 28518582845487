import React from 'react';
import FormularioDivisionesDocumentosDefectoCV from './formularioDivisionesDocumentosDefectoCV';
import TablaDivisionesDocumentosDefectoCV from './tablaDivisionesDocumentosDefectoCV';

const DivisionesPorDefectoCV: React.FunctionComponent = () => {
  return (
    <>
      <TablaDivisionesDocumentosDefectoCV />
      <FormularioDivisionesDocumentosDefectoCV />
    </>
  );
};

export default DivisionesPorDefectoCV;
