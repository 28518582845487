import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Grid, TextField, Card, CardContent, FormControlLabel, Checkbox } from '@material-ui/core';
import Texto from '@infotrack/presentacion-componentes/texto';
import IFlujo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IFlujo';
import { EstadosFlujo } from '../enumeraciones';
import { Edit as EditIcon, Save as SaveIcon } from '@material-ui/icons';

interface IFormularioFlujoProps {
    guardarFlujo: (flujo: IFlujo) => void;
    EditarFlujo: (flujo: IFlujo) => void;
    estado: EstadosFlujo;
    flujoEdicion: IFlujo;
    manejarCambioCampo: (nombreCampo: keyof IFlujo, nuevoValor: IFlujo[keyof IFlujo]) => Promise<void>;
}

const FLUJO_POR_DEFECTO: IFlujo = {
    AgenciaId: '',
    Estado: 0,
    EmpresaId: '',
    FlujoDescripcion: '',
    FlujoId: 0,
};

const FormularioFlujo: FunctionComponent<IFormularioFlujoProps> = ({
    estado,
    EditarFlujo,
    guardarFlujo,
    flujoEdicion,
    manejarCambioCampo
     
}) => {
    const [flujo, setFlujo] = useState<IFlujo>(FLUJO_POR_DEFECTO);
    useEffect(() => {
        if (flujoEdicion) {
            setFlujo(flujoEdicion);
        }
    }, [flujoEdicion]);
  
    return (
        <Card variant="outlined">
            <CardContent>
                <Grid alignItems="flex-end" container spacing={4}>
                    <Grid item xs={12}>
                        <Texto
                            id={
                                estado === EstadosFlujo.CREACION
                                    ? 'FormularioFlujo.GuardarFlujo'
                                    : 'FormularioFlujo.EditarFlujo'
                            }
                            fontWeight="bold"
                        />
                    </Grid>
                    <Grid alignItems="center" container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={<Texto id="FormularioFlujo.flujoDescripcion" display="inline" />}
                                name="FlujoDescripcion"
                                onChange={(event) => manejarCambioCampo('FlujoDescripcion', event.target.value)}
                                required
                                value={flujo.FlujoDescripcion}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={flujo.Estado === 1}
                                        onChange={(_, checked) => manejarCambioCampo('Estado', checked ? 1 : 0)}
                                    />
                                }
                                label={<Texto id="FormularioFlujo.Estado" display="inline" />}
                                name="Estado"
                            />
                        </Grid>
                    </Grid>
                    {/*estado === EstadosFlujo.CREACION && (
                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Button
                                        color="primary"
                                        endIcon={<SaveIcon />}
                                        onClick={() => guardarFlujo(flujo)}
                                        size="small"
                                        variant="contained"
                                    >
                                        <Texto id="boton.guardar" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )*/}
                    {estado === EstadosFlujo.EDICION && (
                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Button
                                        color="primary"
                                        endIcon={<EditIcon />}
                                        onClick={() => EditarFlujo(flujo)}
                                        size="small"
                                        variant="contained"
                                    >
                                        <Texto id="boton.editar" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default FormularioFlujo;
