export default {
    'sidebar.gestionmovimientos': 'Gestión movimientos',
    'boton.desvincular': 'Desvincular',
    'gestionmovimientos.NumeroDocumento': 'N° Documento',
    'gestionmovimientos.TipoDocumento': 'Tipo documento',
    'gestionmovimientos.FechaCreacionDocumento': 'Fecha inicial',
    'gestionmovimientos.FechaVencimientoDocumento': 'Fecha final',
    'gestionmovimientos.PersonaCreacion': 'Personas asociadas',
    'gestionmovimientos.Bodega': 'Bodegas',
    'gestionmovimientos.BodegaOrigenDescripcion': 'Bodega origen',
    'gestionmovimientos.BodegaDestinoDescripcion': 'Bodega destino',
    'gestionmovimientos.EstadoDocumento': 'Estado documento',
    'gestionmovimientos.TipoMovimiento': 'Tipo movimiento',
    'gestionmovimientos.DocumentoRelacionado': 'Documento Relacionado',
    'gestionmovimientos.FechaCreacion': 'Fecha Creación',
    'gestionmovimientos.Accion': 'Acción',
    'gestionmovimientos.documento.TipoDocumentoId': 'Tipo documento',
    'gestionmovimientos.documento.ProveedorId': 'Proveedor',
    'gestionmovimientos.documento.BodegaOrigen': 'Bodega origen',
    'gestionmovimientos.documento.BodegaDestino': 'Bodega destino',
    'gestionmovimientos.documento.DocumentoRelacionado': 'Documento relacionado',
    'gestionmovimientos.documento.Observacion': 'Observaciones',
    'gestionmovimientos.documento.DocumentoPadre': '¿Es documento padre?',
    'gestionmovimientos.documento.DocumentoPadreId': 'Documento padre',
    'gestionmovimientos.documento.EtapaDescripcion': 'Etapa',
    'gestionmovimientos.documento.crearDocumento': 'Crear documento',
    'gestionmovimientos.documento.visualizarDocumento': 'Visualizar documento',
    'gestionmovimientos.documento.editarDocumento': 'Editar documento',
    'gestionmovimientos.documento.datosDocumento': 'Datos documento',
    'gestionmovimientos.documento.cambiarEtapa': 'Cambiar etapa',
    'gestionmovimientos.documentoDetalle.crearDetalleDocumento': 'Crear detalle documento',
    'gestionmovimientos.documentoDetalle.editarDetalleDocumento': 'Editar detalle documento',
    'gestionmovimientos.documentoDetalle.documentoDetalles': 'Detalles',
    'gestionmovimientos.documentoDetalle.DescripcionItem': 'Descripción producto',
    'gestionmovimientos.documentoDetalle.CantidadSolicitada': 'Cantidad solicitada',
    'gestionmovimientos.documentoDetalle.CodigoBarras': 'Código de barras',
    'gestionmovimientos.documentoDetalle.DivisionOrigen': 'División',
    'gestionmovimientos.documentoDetalle.DivisionDestino': 'División destino',
    'gestionmovimientos.documentoDetalle.Lote': 'Lote',
    'gestionmovimientos.documentoDetalle.Serial': 'Serial',
    'gestionmovimientos.documentoDetalle.CantidadDisponible': 'Cantidad disponible',
    'gestionmovimientos.documentoDetalle.CantidadSeleccionada': 'Cantidad seleccionada',
    'gestionmovimientos.documentoDetalle.FechaVencimiento': 'Fecha de vencimiento',
    'gestionmovimientos.fechaInvalida': 'La fecha final debe ser mayor a la fecha inicial',
    'gestionmovimientos.fechaIncompleta': 'Ingrese las dos fechas para poder realizar la búsqueda',
    'gestionmovimientos.documentoDetalle.ValorUnitarioItem': 'Valor unitario ítem',
    'gestionmovimientos.documentoDetalle.Total': 'Total',
    'gestionmovimientos.documentoDetalle.totalSuma': 'Total $:',
    'gestionmovimientos.alerta.alCrearDetalle': 'Por favor ingrese los campos requeridos en el documento (*)',
    'gestionmovimientos.alerta.agregarDetalles': 'Los documentos no padres deben contener al menos un detalle',
    'gestionmovimientos.alerta.confirmacionReinicioDocumentoDetalle':
        'Si modifica este campo se eliminaran los detalles del documento. ¿Desea continuar?',
    'gestionmovimientos.alerta.filtrosInvalidos': 'Debe ingresar al menos un filtro para realizar la búsqueda',
    'gestionmovimientos.alerta.transladoDivisionesInvalido':
        'Debe seleccionar la misma bodega de origen y destino para realizar el traslado',
    'gestionmovimientos.alerta.transladoBodegasInvalido':
        'Debe seleccionar 2 bodegas diferentes para realizar el traslado',
    'gestionmovimientos.alerta.documentoPadreNoDetalle': 'Un documento padre no posee Detalle!',
    /* Select items */
    'selectItems.productos': 'Productos',
    'selectItems.producto': 'Producto',
    'selectItems.division': 'División',
    'selectItems.divisionOrigen': 'División origen',
    'selectItems.divisionDestino': 'División destino ',
    'selectItems.descripcionProducto': 'Descripción',
    'selectItems.codigoBarasProducto': 'Código barras',
    'selectItems.cantidadDisponible': 'Disponible',
    'selectItems.cantidadSeleccionada': 'Seleccionada',
    'selectItems.lote': 'Lote',
    'selectItems.serial': 'Serial',
    'selectItems.fechaVencimiento': 'Vencimiento',
    'selectItems.cantidad': 'Cantidad',
    'gestionmovimientos.alerta.destino': 'Existen productos sin división destino, por favor verifique.',
    'gestionmovimientos.alerta.sinSeleccion': 'Error al seleccionar producto, por favor verifique.',
    'gestionmovimientos.alerta.cambioImportante': 'Esta a punto de realizar un cambio importante, ¿desea continuar?.',
    'gestionmovimientos.alerta.detalleAsignado':
        'El item y división seleccionados ya se encuentran en el documento, por favor verifique.',
};
