import { MenuItem, TextField } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { Autocomplete } from '@material-ui/lab';

import Texto from '@infotrack/presentacion-componentes/texto';

import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';
import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';

interface ICampoTipoDocumentoIdProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampo: keyof IAuditoriaCompuesta,
        nuevoValor: IAuditoriaCompuesta[keyof IAuditoriaCompuesta]
    ) => any;
    tipoDocumento: ITipoDocumento[];
    valor: number;
}

const CampoTipoDocumento: FunctionComponent<ICampoTipoDocumentoIdProps> = ({
    deshabilitado,
    manejarCambioCampo,
    tipoDocumento,
    valor,
}) => (
    <Autocomplete
        disabled={deshabilitado}
        getOptionLabel={(t) => t.TipoDocumentoDescripcion}
        onChange={(_: ChangeEvent<{}>, tipoDocumento: ITipoDocumento | null) =>
            manejarCambioCampo('TipoDocumentoId', tipoDocumento ? tipoDocumento.TipoDocumentoId! : 0)
        }
        options={tipoDocumento}
        renderInput={(params) => (
            <TextField {...params} fullWidth label={<Texto id="Auditoria.CampoTipoDocumentoId.Nombre" />} required />
        )}
        value={tipoDocumento.find((x) => x.TipoDocumentoId === valor) || null}
        noOptionsText={<Texto id="Auditoria.SeleccioneBodega" />}
    />
);

export default CampoTipoDocumento;
