import { IRespuesta, IRespuestaPaginada } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocio } from '../../conexiones';
import IDocumento from '../documentos/entidades/documento';
import IDocumentoConDetalleCompuesto from './entidades/documentoConDetalleCompuesto';
import IDocumentoConDetallesAccion from './entidades/documentoConDetallesAccion';
import IDocumentoDetalle from './entidades/documentoDetalle';
import IDocumentoDetalleCompuesto from './entidades/documentoDetalleCompuesto';
import IDocumentoDetalleCompuestoAuditoria from './entidades/documentoDetalleCompuestoAuditoria';
import IFiltrosDocumentoDetalle from './entidades/filtrosDocumentoDetalle';
import IParametrosConsultaAuditoriaDetalles from './entidades/parametrosConsultaAuditoriaDetalles';

class ModeloDocumentoDetalle {
    public consultaListaDocumentoDetallesPorFiltro(filtroDocumentoDetalle: IFiltrosDocumentoDetalle) {
        return manejadorRest<IRespuesta<IDocumentoDetalleCompuesto>>(
            () => negocio.post('DocumentosDetalles/ConsultarDocumentosDetallesCompuestaFiltro', filtroDocumentoDetalle),
            true
        );
    }

    public consultarDocumentosConDetalleCompuestos(DocumentoId: string) {
        return manejadorRest<IRespuesta<IDocumentoConDetalleCompuesto>>(
            () => negocio.get(`DocumentosDetalles/ConsultarDocumentosDetalle?documentoId=${DocumentoId}`),
            true
        );
    }

    public consultarDivisionDocumentoAuditoria(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.post('GestionDocumentosAuditoria/ConsultarDivisionDocumentoAuditoria', documento),
            true
        );
    }

    public consultarListaDocumentosDetallesAuditoria(
        parametrosConsultaAuditoriaDetalles: IParametrosConsultaAuditoriaDetalles
    ) {
        return manejadorRest<IRespuestaPaginada<IDocumentoDetalleCompuestoAuditoria>>(
            () =>
                negocio.post(
                    'DocumentosDetalles/ConsultarListaDocumentosDetallesAuditoria',
                    parametrosConsultaAuditoriaDetalles
                ),
            true
        );
    }

    public editarDocumentosDetallesTotal(documentoDetalle: IDocumentoDetalleCompuesto) {
        return manejadorRest<IRespuesta<IDocumentoDetalleCompuesto>>(
            () => negocio.put('DocumentosDetalles/EditarDocumentosDetallesTotal', documentoDetalle),
            true
        );
    }

    public editarDocumentosDetallesParcial(documentoDetalle: IDocumentoDetalleCompuesto) {
        return manejadorRest<IRespuesta<IDocumentoDetalleCompuesto>>(
            () => negocio.put('DocumentosDetalles/EditarDocumentosDetallesParcial', documentoDetalle),
            true
        );
    }

    public editarDocumentosDetallesAuditoria(documentoConDetalle: IDocumentoConDetallesAccion) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.put('GestionDocumentosAuditoria/EditarDetallesAuditoria', documentoConDetalle),
            true
        );
    }

    public modificarDocumentoDetallesPedidos(documentoConDetalle: IDocumentoConDetallesAccion) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.put('DocumentosPedidos/ModificarDocumentoDetallesPedidos', documentoConDetalle),
            true
        );
    }

    public modificarDocumentoDetallesDespachos(documentoConDetalle: IDocumentoConDetallesAccion) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.put('DocumentosDespachos/ModificarDocumentoDetallesDespachos', documentoConDetalle),
            true
        );
    }

    public AlmacenamientoDetallesMasivo(documentoConDetalle: IDocumentoDetalle) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.post('DocumentosDetalles/AlmacenamientoDetallesMasivo', documentoConDetalle),
            true
        );
    }

    public editarDocumentoDetalleDevolucionProveedor(documentoConDetalle: IDocumentoDetalle) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.post('DevolucionProveedor/EditarDetalleDevolucionProveedor', documentoConDetalle),
            true
        );
    }

    public guardarDocumentoDetalleDevolucionProveedor(documentoConDetalle: IDocumentoDetalle) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.post('DevolucionProveedor/GuardarDetalleDevolucionProveedor', documentoConDetalle),
            true
        );
    }

    public guardarDocumentosDetalles(documentoDetalle: IDocumentoDetalle) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.post('DocumentosDetalles/GuardarDocumentosDetalles', documentoDetalle),
            true
        );
    }

    public editarDocumentosDetalles(documentoConDetalle: IDocumentoDetalle) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.put('DocumentosDetalles/EditarDocumentosDetalles', documentoConDetalle),
            true
        );
    }

    public eliminarDocumentosDetalles(documentoConDetalle: IDocumentoDetalle) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocio.delete('DocumentosDetalles/EliminarDocumentosDetalles', documentoConDetalle),
            true
        );
    }
    public ConsultarDocumentoDetalles(documentoDetalleCompuesto: Partial<IDocumentoDetalleCompuesto>) {
        return manejadorRest<IRespuesta<IDocumentoDetalleCompuesto>>(
            () => negocio.post('DocumentosDetalles/ConsultarDocumentosDetalleAgrupado', documentoDetalleCompuesto),
            true
        );
    }

    public ConsultarDocumentoCompuestoDetalles(documentoDetalleCompuesto: Partial<IDocumentoDetalleCompuesto>) {
        return manejadorRest<IRespuesta<IDocumentoConDetalleCompuesto>>(
            () => negocio.post('DocumentosDetalles/ConsultarDocumentosDetalles', documentoDetalleCompuesto),
            true
        );
    }
}

export default ModeloDocumentoDetalle;
