import { createStyles, Theme } from '@material-ui/core';
import { paletaMaterialD } from '@infotrack/presentacion-transversales/constantes';

export const estilos = (tema: Theme) =>
    createStyles({
        contenedorListas: {
            backgroundColor: paletaMaterialD.white,
            maxHeight: 287,
            overflowY: 'auto',
        },
        titulosGraficas: {
            borderBottom: '1px solid grey',
            textAlign: 'center',
            marginBottom: tema.spacing(1),
        },
        tituloValorTotal: {
            padding: tema.spacing(1),
        },
        porcentaje: {
            '& span': {
                marginRight: '.5em',
            },
            '& svg': {
                fontSize: 'inherit',
            },
        },
        textoVerde: {
            color: paletaMaterialD.green_400,
        },
        textoRojo: {
            color: paletaMaterialD.red_400,
        },
        textoNaranja: {
            color: paletaMaterialD.orange_400,
        },
        textoAzul: {
            color: paletaMaterialD.light_blue_400,
        },
    });

export const estilosTooltip = createStyles({
    contenedor: {
        padding: '10px',
        backgroundColor: 'white',
        border: '1px solid grey',
        whiteSpace: 'nowrap',
        '& > p': { margin: '2px' },
    },
});
