import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';

import useStyles from '../DetallesInventarioEstilos';

interface ICampoFechaVencimientoProps {
    deshabilitado: boolean;
    detalle: IDocumentoDetalleConsultaGeneral;
    manejarCambioCampo: (
        documentoDetalleId: string,
        nombreCampo: keyof IDocumentoDetalleConsultaGeneral,
        nuevoValor: any
    ) => void;
}

const CampoFechaVencimiento: FunctionComponent<ICampoFechaVencimientoProps> = ({
    deshabilitado,
    detalle,
    manejarCambioCampo,
}) => {
    const classes = useStyles();

    return (
        <KeyboardDatePicker
            className={classes.campo}
            disabled={deshabilitado}
            disablePast
            format="DD/MM/YYYY"
            fullWidth
            label={<Texto id="TablaDetallesPlantilla.FechaVencimiento" />}
            onChange={(date) => {
                if (date) manejarCambioCampo(detalle.DocumentoDetalleId, 'FechaVencimiento', date.format('YYYY-MM-DD'));
            }}
            size="small"
            value={detalle.FechaVencimiento !== null? detalle.FechaVencimiento:""}
            
        />
    );
};

export default CampoFechaVencimiento;
