import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';
import ModeloAuditoria from 'Infotrack@Modelos/negocioRefactor/modelos/Auditoria/ModeloAuditoria';

class AuditoriaCM {
    private modeloAuditoria: ModeloAuditoria;

    constructor() {
        this.modeloAuditoria = new ModeloAuditoria();
    }

    public async consultarAuditoria(filtro: Partial<IAuditoriaCompuesta>) {
        const respuesta = await this.modeloAuditoria.consultarAuditoria(filtro);
        return respuesta.data.Entidades[0];
    }
}

export default AuditoriaCM;
