import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import IFamilias from 'Infotrack@Modelos/smartStock/familias/entidades/familias';
import IFitrosItem from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/filtrosItem';
import IProductoPrincipal from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/productoPrincipal';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import EntidadesFiltroItems from '../controladorModelo/entidadesFiltroItems';
import ItemsCM from '../controladorModelo/itemsCM';
import { IEstadoAPropiedades, IInputsFiltrosItem, ISelectTablaItemsCVProps } from '../interfaces';
import SelectTablaItemsVista from '../vista/selectTablaItemsVista';

const entidadesFiltroItems = new EntidadesFiltroItems();
const itemsCM = new ItemsCM();
const valorInicialFiltros: IInputsFiltrosItem = {
    CodigoBarras: '',
    DescripcionItem: '',
    Familia: null,
};

const SelectTablaItemsCV: React.FunctionComponent<ISelectTablaItemsCVProps & IEstadoAPropiedades> = ({
    alSeleccionarItem,
    IdEmpresa,
    item,
}) => {
    const [estadoSelect, setEstadoSelect] = React.useState<boolean>(false);
    const [familias, setFamilias] = React.useState<IFamilias[]>([]);
    const { alCambiarValor, reiniciar, valor } = useInputState(valorInicialFiltros);
    const [codigoBarras, setCodigoBarras] = React.useState<string>('');
    const [itemActual, setItemActual] = React.useState<IProductoPrincipal>();
    const cargarItem = React.useRef(true);
    const refInputItem = React.createRef<any>();

    const theme = useTheme();
    const esMovil = useMediaQuery(theme.breakpoints.down('sm'));

    const refTabla = React.createRef<any>();

    React.useEffect(() => {
        cargarEntidadesFiltros();
    }, []);

    React.useEffect(() => {
        if (item && cargarItem.current) {
            setItemActual(item);
            cargarItem.current = false;
        }
    }, [item]);

    const alAbrirCerrarSelect = () => {
        setEstadoSelect(!estadoSelect);
        reiniciar();
    };

    const cargarEntidadesFiltros = () => {
        entidadesFiltroItems.consultarEntidadesFiltroItem(IdEmpresa).then(({ familias: familiasCargadas }) => {
            setFamilias(familiasCargadas);
        });
    };

    const alCambiarCodigoBarras = ({
        target: { name, value },
    }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
        const nuevoCodigo = name === 'codigoBarras' ? value : codigoBarras + value.split('.')[1];
        setCodigoBarras(nuevoCodigo);
    };

    const mapearFiltros = (): IFitrosItem => ({
        ...valor,
        FamiliaId: valor.Familia ? Number(valor.Familia.FamiliaId) : undefined,
        EmpresaId: IdEmpresa,
        Estado: 1,
    });

    const consultaProductos = paginacionRemota(
        itemsCM.consultarListaProductoPrincipal,
        mapearFiltros(),
        'DescripcionItem'
    );

    const recargarTabla = async (limpiarFiltros?: boolean) => {
        if (limpiarFiltros) reiniciar();
        refTabla.current!.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
    };

    const consultarItemPorCodigoBarras = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (codigoBarras && !itemActual) {
            itemsCM.consultarItemPorCodigoBarras(codigoBarras).then((producto) => {
                if (producto) {
                    alSeleccionarItem(producto);
                    setItemActual(producto);
                }
            });
        } else if (itemActual && itemActual.CodigoBarras === codigoBarras) {
            alSeleccionarItem(itemActual);
        } else if (codigoBarras && itemActual)
            notificacionGlobal('gestionproducto.alerta.codigoBarrasInvalido', 6000, 'warning', true);
        setCodigoBarras('');
        refInputItem.current!.focus();
    };

    const alQuitarItem = () => {
        alSeleccionarItem(undefined);
        setItemActual(undefined);
        setCodigoBarras('');
        refInputItem.current!.focus();
    };

    return (
        <SelectTablaItemsVista
            alAbrirCerrarSelect={alAbrirCerrarSelect}
            alCambiarCodigoBarras={alCambiarCodigoBarras}
            alCambiarValor={alCambiarValor}
            alQuitarItem={alQuitarItem}
            alSeleccionarItem={alSeleccionarItem}
            codigoBarras={codigoBarras}
            consultaItems={consultaProductos}
            consultarItemPorCodigoBarras={consultarItemPorCodigoBarras}
            familias={familias}
            filtros={valor}
            esMovil={esMovil}
            estadoSelect={estadoSelect}
            item={item}
            recargarTabla={recargarTabla}
            refTabla={refTabla}
            refInputItem={refInputItem}
        />
    );
};

const estadoAPropiedades = ({ estadoAutenticacion: { usuarioInformacion } }: IEstadoGlobal): IEstadoAPropiedades => ({
    IdEmpresa: usuarioInformacion!.IdEmpresa,
});

export default connect<IEstadoAPropiedades, null, any, IEstadoGlobal>(estadoAPropiedades, null)(SelectTablaItemsCV);
