import Tabla from '@infotrack/presentacion-componentes/tabla';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ITablaGestionBodegasVistaProps } from '../interfaces';
import { acionesTablaBodegas, columnasTablaBodegas, opcionesBodegas } from './constantes';

const TablaGestionBodegasVista: React.FunctionComponent<ITablaGestionBodegasVistaProps & InjectedIntlProps> = ({
    alAbrirFormulario,
    alEliminarBodega,
    bodegas,
    intl: { formatMessage },
    recargarTabla,
}) => (
    <Tabla
        actions={acionesTablaBodegas(alAbrirFormulario, alEliminarBodega, formatMessage, recargarTabla)}
        columns={columnasTablaBodegas(formatMessage)}
        data={bodegas}
        options={opcionesBodegas}
    />
);

export default injectIntl(TablaGestionBodegasVista);
