import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';
import { negocio } from 'Infotrack@Modelos/conexiones';
import ITipoProceso from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoProceso';

class ModeloTipoProceso {
    private NOMBRE_CONTROLADOR = 'TipoProceso';

    public async consultarListaTipoProceso() {
        return manejadorRest<IRespuesta<ITipoProceso>>(
            () => negocio.get(`${this.NOMBRE_CONTROLADOR}/ConsultarListaTipoProceso`),
            true
        );
    }
}

export default ModeloTipoProceso;
