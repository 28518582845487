export default {
    'sidebar.administracion': 'Administración',
    'sidebar.perfil': 'Perfil',
    'perfil.agregarPerfil': 'Crear Perfil',
    'perfil.confirmacionCreacion': '¿Está seguro que desea crear el registro?',
    'perfil.confirmacionEdicion': '¿Está seguro que desea editar el registro?',
    'perfil.confirmacionEliminacion': '¿Está seguro que desea eliminar el registro?',
    'perfil.modificarPerfil': 'Editar Perfil',
    'perfil.idPerfil': 'Id Perfil',
    'perfil.idAplicacion': 'Id Aplicación',
    'perfil.nombrePerfil': 'Nombre Perfil',
    'perfil.descripcion': 'Descripción',
    'perfil.permisos': 'Editar permisos',
    'perfil.editarPermiso': 'Editar Permisos',
    'perfil.estado': 'Estado',
    'perfil.alerta.confimarCambios': '¿Está seguro que desea modificar los permisos?',
};
