import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import IDocumentoCompuestoMovimiento from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuestoMovimiento';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import IDocumentosUsuariosCompuesto from 'Infotrack@Modelos/smartStock/gestionOperadores/entidades/documentosUsuariosCompuesto';
import IDocumentoUsuarioLista from 'Infotrack@Modelos/smartStock/gestionOperadores/entidades/documentoUsuarioLista';
import TipoMovimientoDescripcion from 'Infotrack@Modelos/smartStock/tiposMovimientos/entidades/tipoMovimientoDescripcion';
import { AnyAction } from 'redux';
import { IInputsDocumento } from './interfaces';

export interface IEstadoGestionMovimientos {
    documento: IInputsDocumento;
    documentosDetalles: Array<IDocumentoUsuarioLista & { IdTemporal: number }>;
    entidadDocumento?: IDocumentosUsuariosCompuesto;
    entidadDocumentoDetalle?: IDocumentoUsuarioLista;
    estadoFormulario: boolean;
    estadoFormularioDetalle: boolean;
    recargarTabla: boolean;
    tipoFormularioDocumento: TipoFormulario;
    tipoFormularioDocumentoDetalle: TipoFormulario;
    tipoMovimiento?: TipoMovimientoDescripcion;
}

export const estadoInicial: IEstadoGestionMovimientos = {
    documento: {
        BodegaDestino: null,
        BodegaOrigen: null,
        DocumentoPadre: false,
        DocumentoPadreId: '',
        DocumentoRelacionado: '',
        EtapaDescripcion: '',
        Observacion: '',
        TipoDocumento: null,
        Proveedor: null,
    },
    documentosDetalles: [],
    entidadDocumento: undefined,
    entidadDocumentoDetalle: undefined,
    estadoFormulario: false,
    estadoFormularioDetalle: false,
    recargarTabla: false,
    tipoFormularioDocumento: TipoFormulario.Creacion,
    tipoFormularioDocumentoDetalle: TipoFormulario.Creacion,
    tipoMovimiento: undefined,
};

export const acciones = {
    AGREGAR_DOCUMENTO_DETALLE: 'AGREGAR_DOCUMENTO_DETALLE',
    CARGAR_DOCUMENTOS_DETALLES: 'CARGAR_DOCUMENTOS_DETALLES',
    CAMBIAR_ESTADO_FORMULARIO: 'CAMBIAR_ESTADO_FORMULARIO',
    CAMBIAR_ESTADO_FORMULARIO_DETALLE: 'CAMBIAR_ESTADO_FORMULARIO_DETALLE',
    EDITAR_DOCUMENTO_DETALLE: 'EDITAR_DOCUMENTO_DETALLE',
    ELIMINAR_DOCUMENTO_DETALLE: 'ELIMINAR_DOCUMENTO_DETALLE',
    ESTABLECER_TIPO_MOVIMIENTO: 'TIPO_MOVIMIENTO',
    ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE: 'ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE',
    ESTABLECER_ENTIDAD_DOCUMENTO: 'ESTABLECER_ENTIDAD_DOCUMENTO',
    REINICIAR_DOCUMENTO_DETALLES: 'REINICIAR_DOCUMENTO_DETALLES',
    RECARGAR_TABLA: 'RECARGAR_TABLA',
    MODIFICAR_DOCUMENTO: 'MODIFICAR_DOCUMENTO',
};

export const reductor = (estado: IEstadoGestionMovimientos = estadoInicial, { type, payload }: AnyAction) => {
    switch (type) {
        case acciones.AGREGAR_DOCUMENTO_DETALLE: {
            const IdTemporal =
                estado.documentosDetalles.length > 0
                    ? estado.documentosDetalles[estado.documentosDetalles.length - 1].IdTemporal + 1
                    : 1;
            return {
                ...estado,
                documentosDetalles: [...estado.documentosDetalles, { IdTemporal, ...payload }],
            };
        }

        case acciones.CARGAR_DOCUMENTOS_DETALLES: {
            return {
                ...estado,
                documentosDetalles: payload,
            };
        }

        case acciones.CAMBIAR_ESTADO_FORMULARIO: {
            if (estado.estadoFormulario) {
                return estadoInicial;
            } else
                return {
                    ...estado,
                    estadoFormulario: true,
                };
        }

        case acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE: {
            return {
                ...estado,
                estadoFormularioDetalle: !estado.estadoFormularioDetalle,
                entidadDocumentoDetalle: estado.estadoFormularioDetalle ? undefined : estado.entidadDocumentoDetalle,
            };
        }

        case acciones.EDITAR_DOCUMENTO_DETALLE: {
            const nuevosDocumentosDetalles = [...estado.documentosDetalles];
            const indiceAEditar = nuevosDocumentosDetalles.findIndex(
                ({ IdTemporal }) => IdTemporal === payload.IdTemporal
            );
            nuevosDocumentosDetalles[indiceAEditar] = payload;
            return {
                ...estado,
                documentosDetalles: nuevosDocumentosDetalles,
            };
        }

        case acciones.ELIMINAR_DOCUMENTO_DETALLE: {
            return {
                ...estado,
                documentosDetalles: estado.documentosDetalles.filter(
                    ({ UsuarioId }) => !(UsuarioId === payload.UsuarioId)
                ),
            };
        }

        case acciones.ESTABLECER_TIPO_MOVIMIENTO: {
            return {
                ...estado,
                tipoMovimiento: payload,
            };
        }

        case acciones.ESTABLECER_ENTIDAD_DOCUMENTO: {
            return {
                ...estado,
                entidadDocumento: payload,
                tipoFormularioDocumento: payload ? TipoFormulario.Edicion : TipoFormulario.Creacion,
            };
        }

        case acciones.ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE: {
            return {
                ...estado,
                entidadDocumentoDetalle: payload,
                tipoFormularioDocumentoDetalle: payload ? TipoFormulario.Edicion : TipoFormulario.Creacion,
            };
        }

        case acciones.RECARGAR_TABLA: {
            return {
                ...estado,
                recargarTabla: !estado.recargarTabla,
            };
        }

        case acciones.REINICIAR_DOCUMENTO_DETALLES: {
            return {
                ...estado,
                documentosDetalles: [],
            };
        }

        case acciones.MODIFICAR_DOCUMENTO: {
            return {
                ...estado,
                documento: payload,
            };
        }

        default:
            return estado;
    }
};
