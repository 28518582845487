import { Grid } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Texto from '@infotrack/presentacion-componentes/texto';
import React from 'react';
import { IRegistroConPorcentajeProps } from '../interfaces';

export const RegistroConPorcentaje: React.FunctionComponent<IRegistroConPorcentajeProps> = ({
    classes,
    cantidadComparativa,
}: any) => (
    <Grid
        item
        xs="auto"
        className={`${
            cantidadComparativa === 0
                ? classes.textoAzul
                : cantidadComparativa > 0
                ? classes.textoVerde
                : classes.textoRojo
        } ${classes.porcentaje}`}
    >
        <Texto mensaje={`${cantidadComparativa < 0 ? Math.abs(cantidadComparativa) : cantidadComparativa}%`} />
        {cantidadComparativa < 0 ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
    </Grid>
);
