export default {
    'sidebar.administracion': 'Administration',
    'sidebar.perfil': 'Profile',
    'perfil.agregarPerfil': 'Create Profile',
    'perfil.confirmacionCreacion': 'Are you sure to create the registry?',
    'perfil.confirmacionEdicion': 'You are sure you want to edit the registry?',
    'perfil.confirmacionEliminacion': 'Are you sure you want to delete the registry?',
    'perfil.modificarPerfil': 'Edit Profile',
    'perfil.eliminarPerfil': 'Delete Profile',
    'perfil.idAplicacion': 'Id Aplication',
    'perfil.nombrePerfil': 'Name Profile',
    'perfil.descripcion': 'Description',
    'perfil.permisos': 'Edit permissions',
    'perfil.editarPermiso': 'Edit Permissions',
    'perfil.estado': 'State',
    'perfil.perfil': 'Profile',
    'perfil.alerta.confimarCambios': 'Are you sure you want to modify the permissions?',
};
