export default {
  'sidebar.administracion': 'Administration',
  'sidebar.tiposociedad': 'Type of Society',
  'tipoSociedad.agregarTipoSociedad': 'Create Type of Society',
  'tipoSociedad.confirmacionCreacion': 'Are you sure to create the registry?',
  'tipoSociedad.confirmacionEdicion': 'You are sure you want to edit the registry?',
  'tipoSociedad.confirmacionEliminacion': 'Are you sure you want to delete the registry?',
  'tipoSociedad.modificarTipoSociedad': 'Edit Type of Society',
  'tipoSociedad.eliminarTipoSociedad': 'Delete Type of Society',
  'tipoSociedad.codigoTipoSociedad': 'Code Type of Society',
  'tipoSociedad.nombreTipoSociedad': 'Name Type of Society',
  'tipoSociedad.estadoTipoSociedad': 'State',
  'tipoSociedad.tipoSociedad': 'Type of Society',
};
