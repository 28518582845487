import ModeloBodegaDivision from 'Infotrack@Modelos/smartStock/bodegasDivisiones';
import IBodegaDivisionDefectoFiltro from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegaDivisionDefectoFiltro';

export default class EntidadesDocumentoDetalleCM {
  private modeloBodegaDivision: ModeloBodegaDivision;
  constructor() {
    this.modeloBodegaDivision = new ModeloBodegaDivision();
  }

  public consultarEntidadesDocumentoDetalle(bodegaDivisionDefectoFiltro: IBodegaDivisionDefectoFiltro) {
    return this.modeloBodegaDivision
      .consultarDivisionesDocumentosDef(bodegaDivisionDefectoFiltro)
      .then(({ data: { Entidades } }) => Entidades);
  }
}
