import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import Texto from '@infotrack/presentacion-componentes/texto';

import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import Documentos, { IEntidadesDocumentos } from 'Infotrack@Transversales/componentes/Documentos/Documentos';
import { EstadosModalArchivoPDF } from 'Infotrack@Transversales/componentes/archivoPDF/enumeraciones';
import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import GenerarPDF from 'Infotrack@Transversales/componentes/archivoPDF/GenerarPDF';

import { CAMPOS_OCULTAR_DOCUMENTOS_FILTROS, CAMPOS_OCULTAR_DOCUMENTOS_TABLA } from './constantes';
import EntidadesDocumentoCM from './controladorModelo/EntidadesDocumentoCM';
import OrdenSalidaCM from './controladorModelo/OrdenTrasladoCM';
import { EstadosModalOrdenTraslado } from './enumeraciones';
import ModalOrdenTraslado from './ModalOrdenTraslado';

const OrdenSalida = () => {
    const entidadesDocumentoCM = useMemo(() => new EntidadesDocumentoCM(), []);
    const ordenSalidaCM = useMemo(() => new OrdenSalidaCM(), []);

    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);

    const [entidadesDocumento, setEntidadesDocumento] = useState<Partial<IEntidadesDocumentos>>({});
    const [estadoModal, setEstadoModal] = useState<EstadosModalOrdenTraslado>(EstadosModalOrdenTraslado.CERRADO);
    const [estadoArchivoPDF, setEstadoArchivoPDF] = useState<EstadosModalArchivoPDF>(EstadosModalArchivoPDF.CERRADO);
    const [documentoId, setDocumentoId] = useState<string | null>(null);
    const [ejecutarConsultarDocumentos, setEjecutarConsultarDocumentos] = useState<boolean>(false);
    const [archivoPDF, setArchivoPDF] = useState<string | null>(null);

    const esPrimeraCarga = useRef(true);

    useEffect(() => {
        consultarEntidadesFiltros();
    }, []);

    useEffect(() => {
        if (esPrimeraCarga.current) {
            esPrimeraCarga.current = false;
        } else {
            if (estadoModal === EstadosModalOrdenTraslado.CERRADO)
                setEjecutarConsultarDocumentos(
                    (ejecutarConsultarDocumentosActual) => !ejecutarConsultarDocumentosActual
                );
        }
    }, [estadoModal]);

    const consultarEntidadesFiltros = async () => {
        const entidadesDocumentoConsultadas = await entidadesDocumentoCM.consultarEntidadesDocumento(
            idEmpresa,
            idAgencia,
            idUsuario
        );
        setEntidadesDocumento(entidadesDocumentoConsultadas);
    };

    const verDocumento = (nuevoDocumentoId: string) => {
        setDocumentoId(nuevoDocumentoId);
        setEstadoModal(EstadosModalOrdenTraslado.VISUALIZACION);
    };

    const editarDocumento = (nuevoDocumentoId: string) => {
        setDocumentoId(nuevoDocumentoId);
        setEstadoModal(EstadosModalOrdenTraslado.EDICION);
    };

    const generarArchivoPDF = async (documento: IDocumentoConsultaGeneral) => {
        const respuestaArchivoPDF = await ordenSalidaCM.generarArchivoPDF(
            documento.DocumentoId,
            documento.TipoDocumentoId
        );
        setArchivoPDF(respuestaArchivoPDF);
        setEstadoArchivoPDF(EstadosModalArchivoPDF.VISUALIZACION);
    };

    const confirmarAnularDocumento = (documentoIdAnular: string) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => anularDocumento(documentoIdAnular),
            estado: true,
            mensaje: <Texto id={'MensajeOrdenSalida.confirmarAnularDocumento'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const anularDocumento = async (documentoIdAnular: string) => {
        const anuladoExitoso = await ordenSalidaCM.anularDocumento(documentoIdAnular);
        if (anuladoExitoso) {
            setEjecutarConsultarDocumentos(!ejecutarConsultarDocumentos);
            manejadorDialogoGlobal({ estado: false });
        }
    };

    const consultarTipoDocumento = async (idBodega: number) => {
        const tiposDocumentosBodega = await entidadesDocumentoCM.consultarTipoDocumento(idBodega);
        setEntidadesDocumento({ ...entidadesDocumento, tiposDocumento: tiposDocumentosBodega });
    };

    return (
        <>
            <Documentos
                agregarDocumento={() => setEstadoModal(EstadosModalOrdenTraslado.CREACION)}
                anularDocumento={confirmarAnularDocumento}
                camposOcultarFiltros={CAMPOS_OCULTAR_DOCUMENTOS_FILTROS}
                camposOcultarTabla={CAMPOS_OCULTAR_DOCUMENTOS_TABLA}
                consultarDocumentos={ordenSalidaCM.consultarListaDocumentos}
                editarDocumento={editarDocumento}
                ejecutarConsultarDocumentos={ejecutarConsultarDocumentos}
                entidadesDocumento={entidadesDocumento}
                verDocumento={verDocumento}
                descargarDocumentoPdf={generarArchivoPDF}
                consultarTipoDocumentoBodega={consultarTipoDocumento}
                limpiarFiltroTipoDocumento={()=> setEntidadesDocumento({ ...entidadesDocumento, tiposDocumento: [] })}
                esTraslado={false}
            />
            <ModalOrdenTraslado
                cerrar={() => setEstadoModal(EstadosModalOrdenTraslado.CERRADO)}
                documentoId={documentoId}
                establecerDocumentoId={(nuevoDocumentoId: string) => setDocumentoId(nuevoDocumentoId)}
                estado={estadoModal}
                descargarDocumentoPdf={generarArchivoPDF}
            />
            {archivoPDF && (
                <GenerarPDF
                    cerrar={() => setEstadoArchivoPDF(EstadosModalArchivoPDF.CERRADO)}
                    archivoPDF={archivoPDF}
                    estado={estadoArchivoPDF}
                />
            )}
        </>
    );
};

export default OrdenSalida;
