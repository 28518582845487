import { AppBar, Grid, IconButton, Toolbar, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MenuTwoTone';
import clsx from 'clsx';
import SelectorIdioma from 'Infotrack@Principal/baseGeneral/componentes/selectorIdioma';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import { IBarraNavegacionProps } from '../interfaces';
import { estilos } from './estilos';

const BarraNavegacionVista: React.FunctionComponent<PropsEstilosMaterialUI<IBarraNavegacionProps>> = ({
  accionBoton,
  classes,
  componente: Componente,
  estadoBarra,
  esMovil,
}) => (
  <AppBar
    color="primary"
    className={clsx(classes.barraNavegacion, {
      [classes.barraNavegacionMenuExpandido]: estadoBarra && !esMovil,
    })}
    position="fixed"
  >
    <Toolbar variant="regular">
      <Grid alignItems="center" container justifyContent="space-between">
        {!estadoBarra && (
          <Grid item className={clsx(classes.botonMenu, estadoBarra && classes.botonMenuOculto)}>
            <IconButton color="inherit" onClick={accionBoton}>
              <MenuIcon />
            </IconButton>
          </Grid>
        )}
        <Grid item xs={true}>
          <Grid alignItems="center" container justifyContent="flex-end" spacing={2}>
            <Grid item md={true} xs={true}>
              {Componente && <Componente />}
            </Grid>
            <Grid item xs="auto">
              <SelectorIdioma />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
);

export default withStyles(estilos)(BarraNavegacionVista);
