import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import Texto from '@infotrack/presentacion-componentes/texto';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IAlmacenamiento from 'Infotrack@Modelos/smartStock/almacenamiento/entidades/almacenamiento';
import EnumAlmacenamientoEstado from 'Infotrack@Modelos/smartStock/almacenamiento/entidades/enumAlmacenamientoEstado';
import IAlmacenamientoTablaFiltro from 'Infotrack@Modelos/smartStock/almacenamiento/entidades/filtrosTablaAlmacenamiento';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IDocumentoPadreCompuesto from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoPadreCompuesto';
import IProveedores from 'Infotrack@Modelos/smartStock/proveedores/entidades/proveedores';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import AlmacenamientoCM from '../controladorModelo/almacenamientoCM';
import EntidadesFiltroDocumentosCM from '../controladorModelo/entidadesFiltroDocumentosCM';
import { IInputsDocumentoFiltro, ITablaAlmacenamientoCVProps } from '../interfaces';
import { acciones, IEstadoAlmacenamiento } from '../reductorAlmacenamiento';
import TablaAlmacenamientoVista from '../vista/tablaAlmacenamientoVista';
import { useSelector } from 'react-redux';

const entidadesFiltroDocumentosCM = new EntidadesFiltroDocumentosCM();
const almacenamientoCM = new AlmacenamientoCM();

const TablaAlmacenamientoCV: React.FunctionComponent<ITablaAlmacenamientoCVProps> = ({ IdAgencia, IdUsuario }) => {
    const idEmpresa = useSelector((estado: IEstadoGlobal) => estado.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const [bodegas, setBodegas] = React.useState<IBodega[]>([]);
    const [documentos, setDocumentos] = React.useState<IDocumentoPadreCompuesto>({ Lista: [], ListaPadre: [] });
    const { alCambiarValor, setValor, reiniciar, valor } = useInputState<IInputsDocumentoFiltro>({
        BodegaDestino: null,
        CodigoDocumento: '',
    });
    const [{ recargarTabla }, dispatch] = useProveedor<IEstadoAlmacenamiento>();
    const refTabla = React.createRef<any>();

    React.useEffect(() => {
        cargarEntidadesFiltroDocumentos();
    }, []);

    React.useEffect(() => { 
        if (recargarTabla && valor.BodegaDestino) {
            cargarDocumentos();
            dispatch({ type: acciones.RECARGAR_TABLA });
        }
    }, [recargarTabla]);

    const cargarEntidadesFiltroDocumentos = () => {
        entidadesFiltroDocumentosCM
            .consultarEntidadesFiltro({ AgenciaId: IdAgencia, UsuarioId: IdUsuario, EmpresaId: idEmpresa })
            .then(({ bodegas: bodegasCargadas }) => {
                setBodegas(bodegasCargadas);
            });
    };

    const confirmarAnularAlmacenaiento = (almacenamiento: IAlmacenamiento) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => alAnularAlmacenamiento(almacenamiento),
            estado: true,
            mensaje: (
                <Texto
                    id={
                        almacenamiento.EtapaDescripcion === EnumAlmacenamientoEstado.Creada
                            ? 'gestionauditorias.alerta.anular'
                            : almacenamiento.EtapaDescripcion === EnumAlmacenamientoEstado.Conteo &&
                              almacenamiento &&
                              almacenamiento.BodegaOrigen &&
                              almacenamiento.BodegaDestino
                            ? 'gestionauditorias.alerta.anular'
                            : 'gestionauditorias.alerta.anular'
                    }
                />
            ),
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const alAnularAlmacenamiento = (almacenamiento: IAlmacenamiento) => {
        manejadorDialogoGlobal({ estado: false });
        almacenamientoCM.AnularAlmacenamiento(almacenamiento).then(() => {
            dispatch({ type: acciones.RECARGAR_TABLA });
        });
    };

    const cargarDocumentos = async (limpiarFiltros?: boolean) => {
        if (limpiarFiltros) {
            reiniciar();
            refTabla.current!.onQueryChange({ BodegaDestino: 0, ProveedorId: 0, CodigoDocumento: '' });
        } else {
            if (almacenamientoCM.validarFiltroConsulta(valor as any))
                refTabla.current!.onQueryChange({ BodegaDestino: 0, ProveedorId: 0, CodigoDocumento: '' });
        }
    };

    const mapearFiltro = (): IAlmacenamientoTablaFiltro => {
        const { BodegaDestino, CodigoDocumento } = valor;
        return {
            BodegaDestino: BodegaDestino ? Number(BodegaDestino.BodegaId) : null,
            CodigoDocumento,
        };
    };

    const consultaAlmacenamiento = paginacionRemota(
        almacenamientoCM.ConsultarListaAlmacenamientoFiltro,
        mapearFiltro(),
        'CodigoDocumento',
        !Boolean(valor.BodegaDestino)
    );

    const alAbrirFormularioDetalle = (DocumentoId: string) => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE, payload: DocumentoId });
    };

    const alCambiarValorAutocomplete = (
        nombreValor: string,
        nuevoValor: IInputsDocumentoFiltro[keyof IInputsDocumentoFiltro]
    ) => {
        setValor({ ...valor, [nombreValor]: nuevoValor });
    };

    return (
        <TablaAlmacenamientoVista
            alAbrirFormularioDetalle={alAbrirFormularioDetalle}
            alCambiarValor={alCambiarValor}
            alCambiarValorAutocomplete={alCambiarValorAutocomplete}
            alAnularAlmacenamiento={confirmarAnularAlmacenaiento}
            documentos={documentos}
            documentosFiltro={valor}
            entidadesFiltro={{ bodegas }}
            recargarTabla={cargarDocumentos}
            refTabla={refTabla}
            consultaAlmacenamiento={consultaAlmacenamiento}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion, agenciaActual },
}: IEstadoGlobal): ITablaAlmacenamientoCVProps => ({
    IdAgencia: agenciaActual!.IdAgencia,
    IdUsuario: usuarioInformacion!.IdUsuario,
});

export default connect<ITablaAlmacenamientoCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(TablaAlmacenamientoCV);
