export default {
    'sidebar.consultainventario': 'Consulta inventario',
    'sidebar.historicoinventario': 'Histórico inventario',
    'inventario.titulo': 'Inventario',
    'inventario.producto': 'Producto',
    'inventario.ubicacion': 'Ubicación',
    'inventario.fecha': 'Fecha',
    'inventario.codigoBarrasProducto': 'Código de Barras',
    'inventario.productoDescripcion': 'Producto descripción',
    'inventario.productoSerial': 'Serial producto',
    'inventario.productoLote': 'Lote producto',
    'inventario.familiaIdProducto': 'Familia producto',
    'inventario.clasificacionIdProducto': 'Clasificación producto',
    'inventario.BodegaCodigo': 'Código bodega',
    'inventario.BodegaId': 'Bodega',
    'inventario.DivisionId': 'División',
    'inventario.FechaInicialMovimiento': 'Fecha inicial',
    'inventario.FechaFinalMovimiento': 'Fecha final',
    'inventario.tablaBodega': 'Bodega',
    'inventario.tablaDivision': 'División',
    'inventario.tablaProducto': 'Producto',
    'inventario.tablaPesoFraccion': 'Peso Recibido',
    'inventario.tablaTipoMovimiento': 'Tipo Movimiento',
    'inventario.tablaDocumento': 'Documento',
    'inventario.tablaCantidad': 'Cantidad',
    'inventario.tablaCantidadTotal': 'Cantidad Total',
    'inventario.tablaSerial': 'Serial',
    'inventario.tablaLote': 'Lote',
    'inventario.tablaFechaVencimiento': 'Fecha De Vencimiento',
    'inventario.tablaFechaMovimiento':'Fecha de Movimiento',
    'inventario.tablaFechaIngreso': 'Fecha de Ingreso',
    'inventario.tablaValor': 'Valor',
    'inventario.tablaPeso': 'Peso',
    'inventario.tablaEstado': 'Estado',
    'inventario.CodigoProducto':'Código producto'
};
