enum EstadosFlujo {
    CERRADO = 'CERRADO',
    CREACION = 'CREACION',
    EDICION = 'EDICION',
    VISUALIZACION = 'VISUALIZACION',
}

enum EstadosFlujoEtapasFormulario {
    CREACION = 'CREACION',
    EDICION = 'EDICION',
}

enum EstadosModalFlujos {
    CERRADO = 'CERRADO',
    CREACION = 'CREACION',
    EDICION = 'EDICION',
}
export { EstadosFlujo, EstadosFlujoEtapasFormulario, EstadosModalFlujos };
