import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloAlmacenamiento from 'Infotrack@Modelos/smartStock/almacenamiento';
import IAlmacenamiento from 'Infotrack@Modelos/smartStock/almacenamiento/entidades/almacenamiento';
import IDocumentoFiltroPadre from 'Infotrack@Modelos/smartStock/documentos/entidades/filtrosDocumentoPadre';

export default class AlmacenamientoCM {
    private modeloAlmacenamiento: ModeloAlmacenamiento;

    constructor() {
        this.modeloAlmacenamiento = new ModeloAlmacenamiento();
    }

    public ConsultarListaAlmacenamientoFiltro = (paginador: IPaginador, almacenamiento: IAlmacenamiento) =>
        this.modeloAlmacenamiento
            .ConsultarListaAlmacenamientoFiltro({ paginador, almacenamiento })
            .then(({ data }) => data);

    public ModificarFlujoEtapaAlmacenamiento = (almacenamiento: IAlmacenamiento) => {
        return this.modeloAlmacenamiento.ModificarFlujoEtapaAlmacenamiento(almacenamiento);
    };

    public AnularAlmacenamiento = (almacenamiento: IAlmacenamiento) => {
        return this.modeloAlmacenamiento.AnularAlmacenamiento(almacenamiento);
    };

    public validarFiltroConsulta = ({ BodegaDestino }: IDocumentoFiltroPadre): boolean => {
        if (!BodegaDestino) {
            notificacionGlobal('recepcion.procesos.campoBodegaObligatorio', 6000, 'warning', true);
            return false;
        } else return true;
    };
}
