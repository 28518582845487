import { PermisosPerfil as PermisosPerfilModulosBase } from '@infotrack/presentacion-modulosbase';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { useSelector } from 'react-redux';
import { seguridadToken } from 'Infotrack@Modelos/conexiones';
import React from 'react';

const PermisosPerfil: React.FunctionComponent = () => {
    const idAplicacion = useSelector(
        (e: IEstadoGlobal) => e.estadoAutenticacion.usuarioAplicacionAgenciaActual!.IdAplicacion
    );
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    return <PermisosPerfilModulosBase idAplicacion={idAplicacion!} idEmpresa={idEmpresa} seguridad={seguridadToken} />;
};

export default PermisosPerfil;
