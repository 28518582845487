import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';

import ModeloMovimiento from 'Infotrack@Modelos/smartStock/movimientos';
import IMovimientoFechaFiltro from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoFechaFiltro';

export default class MovimientoCM {
    private modeloMovimiento: ModeloMovimiento;

    constructor() {
        this.modeloMovimiento = new ModeloMovimiento();
    }

    public consultarSaldoCompuestoFiltro = (paginador: IPaginador, filtros: IMovimientoFechaFiltro) => {
        if (!!filtros.Serial) filtros.Serial = filtros.Serial.toUpperCase();
        if (!!filtros.Lote) filtros.Lote = filtros.Lote.toUpperCase();
        return this.modeloMovimiento
            .consultarSaldoCompuestoFiltro({ paginador, filtro: filtros })
            .then(({ data }) => data);
    };
}
