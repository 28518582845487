import Button, { ButtonProps } from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import Texto from '@infotrack/presentacion-componentes/texto';
import React from 'react';

export const BotonRefrescar: React.FunctionComponent<ButtonProps> = (props) => (
    <Button
        color="secondary"
        endIcon={<RefreshIcon />}
        style={{ margin: '0 .5em', textTransform: 'capitalize' }}
        variant="contained"
        {...props}
    >
        <Texto id="boton.refrescar" />
    </Button>
);

export const BotonAgregar: React.FunctionComponent<ButtonProps & { component?: string }> = (props) => (
    <Button
        color="primary"
        endIcon={<AddIcon />}
        style={{ margin: '0 .5em', textTransform: 'capitalize' }}
        variant="contained"
        {...props}
    >
        {props.children}
        <Texto id="boton.agregar" />
    </Button>
);

export const BotonEliminar: React.FunctionComponent<ButtonProps> = (props) => (
    <Button
        color="primary"
        endIcon={<DeleteIcon />}
        style={{ margin: '0 .5em', textTransform: 'capitalize' }}
        variant="contained"
        {...props}
    >
        <Texto id="boton.eliminar" />
    </Button>
);

export const BotonBuscar: React.FunctionComponent<ButtonProps> = (props) => (
    <Button color="primary" endIcon={<SearchIcon />} variant="contained" {...props}>
        <Texto id="boton.buscar" />
    </Button>
);

export const BotonLimpiar: React.FunctionComponent<ButtonProps> = (props) => (
    <Button color="secondary" endIcon={<DeleteSweepIcon />} variant="contained" {...props}>
        <Texto id="boton.limpiar" />
    </Button>
);

export const BotonExportar: React.FunctionComponent<ButtonProps> = (props) => (
    <Button color="primary" endIcon={<CloudDownloadIcon />} variant="contained" {...props}>
        <Texto id="Comunes.ExportarXLSX" />
    </Button>
);
