import { AppBar, Card, Grow, List, ListItem, Popover, Toolbar, withStyles } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AppsIcon from '@material-ui/icons/Apps';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Security from '@material-ui/icons/Security';
import Texto from '@infotrack/presentacion-componentes/texto';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import { IDialogoPerfilProps } from '../interfaces';
import estilos from './estilos';

const DialogoPerfilVista: React.FunctionComponent<PropsEstilosMaterialUI<IDialogoPerfilProps>> = ({
    classes,
    cerrarDialogo,
    cerrarSesion,
    correo,
    irAPerfil,
    irAselector,
    refElem,
    irCambioContrasenia,
    irDatosPersonales,
}) => (
    <Popover
        anchorEl={refElem}
        open={Boolean(refElem)}
        onClose={() => cerrarDialogo(null)}
        transformOrigin={{
            horizontal: 'center',
            vertical: 'top',
        }}
        anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
        }}
    >
        <Grow in={Boolean(refElem)}>
            <Card className={classes.contenedor}>
                <AppBar color="primary" position="static">
                    <Toolbar variant="dense">
                        <Texto className={classes.correo} color="inherit" mensaje={correo ? correo : ''} />
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItem button onClick={irDatosPersonales}>
                        <AccountBoxIcon color="disabled" className={classes.iconosLista} />
                        <Texto id="barraLateral.title.perfil" />
                    </ListItem>
                    <ListItem button onClick={irAselector}>
                        <AppsIcon color="disabled" className={classes.iconosLista} />
                        <Texto id="barraLateral.label.selector" />
                    </ListItem>
                    <ListItem button onClick={cerrarSesion}>
                        <PowerSettingsNewIcon color="disabled" className={classes.iconosLista} />
                        <Texto id="barraLateral.label.cerrarSesion" />
                    </ListItem>
                    <ListItem button onClick={irCambioContrasenia}>
                        <Security color="disabled" className={classes.iconosLista} />
                        <Texto
                            id="Actualizar
                        "
                        />
                    </ListItem>
                </List>
            </Card>
        </Grow>
    </Popover>
);

export default withStyles(estilos)(DialogoPerfilVista);
