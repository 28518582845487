const mensajes = {
    // DocumentosFiltros
    'Documentos.CampoTipoDocumentoId.Nombre': 'Document type',
    'Documentos.CampoEtapaId.Nombre': 'Stage',
    'Documentos.CampoProveedorId.Nombre': 'Supplier',
    'Documentos.CampoProveedor.Nombre': 'Supplier description',
    'Documentos.CampoCodigoDocumento.Nombre': 'Document code',
    'Documentos.CampoFechaEntrega.Nombre': 'Delivery date',
    'Documentos.CampoBodegaOrigen.Nombre': 'Origin warehouse',
    'Documentos.CampoBodegaDestino.Nombre': 'Destination warehouse',
    'Documentos.CampoDocumentoRelacionado.Nombre': 'Related document',
    'Documentos.CampoClienteId.Nombre': 'Client',
    'Documentos.CampoCliente.Nombre': 'Client description',
    'Documentos.CampoFechaInicial.Nombre': 'Initial date',
    'Documentos.CampoFechaFinal.Nombre': 'Final date',
    'Documentos.CampoCodigoDocumentoPredecesor': 'Base document code',
    'Documentos.CampoSerial': 'Serial',
    "Documentos.SinRegistros": 'No records found',
    'Documentos.CampoDocumentoRelacionado.NumeroTicket': 'Ticket Number',
};

export default mensajes;
