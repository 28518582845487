import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Dialog, DialogContent, Grid } from '@material-ui/core';

import Texto from '@infotrack/presentacion-componentes/texto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IDivision from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDivision';
import IFamilia from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IFamilia';
import IItem from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IItem';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';
import ITipoConteoAuditoria from 'Infotrack@Modelos/smartStock/tipoConteoAuditoria/entidades/ITipoConteoAuditoria';
import AuditoriaConteoFormulario from 'Infotrack@Transversales/componentes/Auditoria/AuditoriaConteo/AuditoriaConteoFormulario';

import { EstadosModalAuditoria } from '../enumeraciones';
import AuditoriaCM from '../controladorModelo/AuditoriaCM';
import AuditoriaFormulario from './AuditoriaFormulario/AuditoriaFormulario';
import {
    CAMPOS_DESHABILITAR_FORMULARIO_AUDITORIA,
    CAMPOS_DESHABILITAR_FORMULARIO_AUDITORIA_VISUALIZACION,
    CAMPOS_OCULTAR_FORMULARIO_AUDITORIA_CREACION,
    ETAPAS_DESHABILITAR_ACCIONES,
} from './constantes';
import EntidadesAuditoriaCM from '../controladorModelo/EntidadesAuditoriaCM';
import AuditoriaConteoCM from '../controladorModelo/AuditoriaConteoCM';
import TipoConteoAuditoriaCM from '../../../parametrizacion/tipoConteoAuditoria/controladorModelo/tipoConteoAuditoriaCM';
import AuditoriaConteoTabla from './AuditoriaFormulario/AuditoriaConteoTabla';

const ENTIDADES_AUDITORIA_POR_DEFECTO: IEntidadesAuditoria = {
    bodegas: [],
    tiposDocumento: [],
    divisiones: [],
    familias: [],
    items: [],
};

interface IEntidadesAuditoria {
    bodegas: IBodega[];
    tiposDocumento: ITipoDocumento[];
    divisiones: IDivision[] | undefined;
    familias: IFamilia[] | undefined;
    items: IItem[] | undefined;
}

interface IModalAuditoriaProps {
    auditoriaInicial: IAuditoriaCompuesta;
    cerrar: () => any;
    estado: EstadosModalAuditoria;
    cambioEstadoModalAuditoriaFinalizada: () => void;
}

const AUDITORIA_POR_DEFECTO: IAuditoriaCompuesta = {
    AuditoriaId: '',
    BodegaId: 0,
    Estado: 0,
    TipoDocumentoId: 0,
    EtapaId: 0,
    Divisiones: [],
    Familias: [],
    Items: [],
};

const CONTEO_POR_DEFECTO: IAuditoriaConteo = {
    AuditoriaConteoId: '',
    AuditoriaId: '',
    DescripcionConteo: '',
    Observacion: '',
    Estado: 0,
    IdTipoConteo: 0,
    ConteoPredecesorId: '',
    Fecha: '',
    ConteoFinalizado:false
};

const ModalAuditoria: FunctionComponent<IModalAuditoriaProps> = ({
    auditoriaInicial,
    cerrar,
    estado,
    cambioEstadoModalAuditoriaFinalizada,
}) => {
    const entidadesAuditoriaCM = useMemo(() => new EntidadesAuditoriaCM(), []);
    const auditoriaCM = useMemo(() => new AuditoriaCM(), []);
    const auditoriaConteoCM = useMemo(() => new AuditoriaConteoCM(), []);
    const tipoConteoAuditoriaCM = useMemo(() => new TipoConteoAuditoriaCM(), []);

    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);

    const [entidadesAuditoria, setEntidadesAuditoria] = useState<IEntidadesAuditoria>(ENTIDADES_AUDITORIA_POR_DEFECTO);
    const [auditoriaModal, setAuditoriaModal] = useState<IAuditoriaCompuesta | null>(null);
    const [etapasSiguientes, setEtapasSiguientes] = useState<IEtapas[]>([]);
    const [auditoriaConteo, setAuditoriaConteo] = useState<IAuditoriaConteo>(CONTEO_POR_DEFECTO);
    const [conteos, setConteos] = useState<IAuditoriaConteo[]>([]);
    const [tiposConteo, setTiposConteos] = useState<ITipoConteoAuditoria[]>([]);

    const [estadoModalAuditoria, setEstadoModalAuditoria] = useState<EstadosModalAuditoria>(estado);

    useEffect(() => {
        alCambiarEstado();
    }, [estado]);

    useEffect(() => {
        if (auditoriaModal && auditoriaModal.AuditoriaId) {
            consultarInformacionAuditoria();
        }
    }, [auditoriaModal]);

    const consultarInformacionAuditoria = async () => {
        await consultarEtapasSiguientes();
        await consultarListaConteo();
        await consultarListaTiposConteo();
    };

    const alCambiarEstado = async () => {
        if (estado === EstadosModalAuditoria.CERRADO) {
            await setAuditoriaModal(AUDITORIA_POR_DEFECTO);
            setEntidadesAuditoria(ENTIDADES_AUDITORIA_POR_DEFECTO);
            setEtapasSiguientes([]);
            setConteos([]);
            return;
        }
        if (estado === EstadosModalAuditoria.CREACION) {
            await consultarEntidadesAuditoria();
            setEstadoModalAuditoria(EstadosModalAuditoria.CREACION);
        }
        if (estado === EstadosModalAuditoria.VISUALIZACION || estado === EstadosModalAuditoria.GESTION) {
            await consultarEntidadesAuditoria();
            await setAuditoriaModal(auditoriaInicial);
        }
    };

    const consultarTipoDocumento = async (idBodega: number) => {
        if (idBodega !== 0) {
            const tiposDocumentosBodega = await entidadesAuditoriaCM.consultarTipoDocumentoPorBodega(idBodega);
            setEntidadesAuditoria({
                ...entidadesAuditoria,
                tiposDocumento: tiposDocumentosBodega,
            });
        } else {
            setEntidadesAuditoria({ ...entidadesAuditoria, tiposDocumento: [], divisiones: [] });
        }
    };

    const consultarEntidadesAuditoria = async () => {
        const entidadesAuditoriaConsultadas = await entidadesAuditoriaCM.consultarEntidadesAuditoria(
            idEmpresa,
            idAgencia,
            10,
            idUsuario
        );
        setEntidadesAuditoria(entidadesAuditoriaConsultadas);
    };

    const crearAuditoria = async (auditoria: IAuditoriaCompuesta) => {
        const respuesta = await auditoriaCM.crearAuditoria({
            ...auditoria,
            EmpresaId: idEmpresa,
            AgenciaId: idAgencia,
        });
        if (respuesta.Resultado) {
            setAuditoriaModal(respuesta.Entidades[0]);
            setEstadoModalAuditoria(EstadosModalAuditoria.GESTION);
        }
    };

    const crearAuditoriaConteo = async (auditoriaConteo: IAuditoriaConteo) => {
        const respuesta = await auditoriaConteoCM.crearConteo({
            ...auditoriaConteo,
            AuditoriaId: auditoriaModal!.AuditoriaId,
            EmpresaId: idEmpresa,
            AgenciaId: idAgencia,
        });
        if (respuesta.Resultado) {
            await consultarListaConteo();
            await consultarEtapasSiguientes();
        }
    };

    const consultarAuditoria = async (auditoriaId: string) => {
        const documentoConsultado = await auditoriaCM.consultarAuditoria({ AuditoriaId: auditoriaId });
        setAuditoriaModal(documentoConsultado);
    };

    const consultarConteoAuditoria = async (auditoriaConteoId: string) => {
        const auditoriaConteoConsultado = await auditoriaConteoCM.consultarConteoAuditoria(auditoriaConteoId);
        setAuditoriaConteo(auditoriaConteoConsultado);
    };

    const consultarListaConteo = async () => {
        const filtroConsultaListaConteo: Partial<IAuditoriaConteo> = {
            AuditoriaId: auditoriaModal!.AuditoriaId,
            EmpresaId: idEmpresa,
            AgenciaId: idAgencia,
        };
        const conteosConsultados = await auditoriaConteoCM.consultarListaConteo(filtroConsultaListaConteo);
        setConteos(conteosConsultados);
    };

    const consultarListaTiposConteo = async () => {
        const tiposConteosConsultados = await tipoConteoAuditoriaCM.consultarTipoConteoAuditoria();
        setTiposConteos(tiposConteosConsultados);
    };

    const consultarEtapasSiguientes = async () => {
        const etapasSiguienteConsultadas = await auditoriaCM.consultarEtapas(auditoriaModal!.DocumentoId!);
        setEtapasSiguientes(etapasSiguienteConsultadas);
    };

    const cambiarEtapa = async (etapaId: number) => {
        manejadorDialogoGlobal({ estado: false });
        const cambioEtapaExitoso = await auditoriaCM.cambiarEtapa(auditoriaModal!.DocumentoId!, etapaId);
        if (cambioEtapaExitoso) {
            await consultarAuditoria(auditoriaModal!.AuditoriaId!);
        }
    };

    const confirmarCambiarEtapa = async (etapaId: number, esEtapaFinalizado: boolean) => {
        if (esEtapaFinalizado) {
            cambiarEtapa(etapaId);
        } else {
            manejadorDialogoGlobal({
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                accionConfirmar: () => cambiarEtapa(etapaId),
                estado: true,
                mensaje: <Texto id={'etapas.CambioEtapa'} />,
                mostrarCancelar: true,
                tipoDialogo: 'Advertencia',
                titulo: <Texto id="titulo.advertencia" />,
            });
        }
    };

    return (
        <Dialog fullWidth maxWidth="md" open={estado !== EstadosModalAuditoria.CERRADO}>
            <ModalEncabezado
                cerrar={cerrar}
                titulo={
                    estado == EstadosModalAuditoria.CREACION ? (
                        <Texto id="ModalAuditoria.TituloCreacion" />
                    ) : estado == EstadosModalAuditoria.VISUALIZACION ? (
                        <Texto id={'ModalAuditoria.TituloVisualizacion'} />
                    ) : estado == EstadosModalAuditoria.GESTION ? (
                        <Texto id={'ModalAuditoria.TituloGestion'} />
                    ) : (
                        undefined
                    )
                }
            />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AuditoriaFormulario
                            cambiarEtapa={confirmarCambiarEtapa}
                            camposDeshabilitar={
                                estado === EstadosModalAuditoria.GESTION ||
                                estado === EstadosModalAuditoria.VISUALIZACION ||
                                estadoModalAuditoria === EstadosModalAuditoria.GESTION
                                    ? CAMPOS_DESHABILITAR_FORMULARIO_AUDITORIA
                                    : CAMPOS_DESHABILITAR_FORMULARIO_AUDITORIA_VISUALIZACION
                            }
                            camposOcultar={
                                auditoriaModal && !auditoriaModal.AuditoriaId
                                    ? CAMPOS_OCULTAR_FORMULARIO_AUDITORIA_CREACION
                                    : []
                            }
                            etapasSiguientes={etapasSiguientes}
                            auditoria={auditoriaModal}
                            entidadesAuditoria={entidadesAuditoria}
                            crearAuditoria={crearAuditoria}
                            estado={estado}
                            cambioEstadoModalAuditoriaFinalizada={cambioEstadoModalAuditoriaFinalizada}
                            consultarTipoDocumentoPorBodega={consultarTipoDocumento}
                        />
                    </Grid>
                    {auditoriaModal && auditoriaModal.AuditoriaId && estado !== EstadosModalAuditoria.VISUALIZACION && (
                        <Grid item xs={12}>
                            <AuditoriaConteoFormulario
                                conteo={auditoriaConteo}
                                estado={EstadosModalAuditoria.CREACION}
                                crearConteo={crearAuditoriaConteo}
                                conteos={conteos}
                                tiposConteo={tiposConteo}
                            />
                        </Grid>
                    )}
                    {auditoriaModal && auditoriaModal.AuditoriaId && (
                        <Grid item xs={12}>
                            <AuditoriaConteoTabla
                                listaConteo={conteos}
                                recargarTabla={consultarListaConteo}
                                deshabilitar={
                                    ETAPAS_DESHABILITAR_ACCIONES.includes(auditoriaModal.DescripcionEtapaDocumento!)
                                        ? true
                                        : false
                                }
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export { IEntidadesAuditoria };

export default ModalAuditoria;
