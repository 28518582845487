import React, { FunctionComponent } from 'react';
import NumberFormat from 'react-number-format';

interface IValorEnPesosProps {
    valor: string | number;
}

const ValorEnPesos: FunctionComponent<IValorEnPesosProps> = ({ valor }) => (
    <NumberFormat
        displayType="text"
        decimalScale={2}
        fixedDecimalScale
        prefix="$"
        thousandSeparator=","
        value={valor}
    />
);

export default ValorEnPesos;
