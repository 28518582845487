import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import useStyles from '../DetallesInventarioEstilos';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';

interface ICampoPesoSolicitadoProps {
    deshabilitado: boolean;
    detalle: IDocumentoDetalle;
    manejarCambioCampo: (detalleId: string, nombreCampo: keyof IDocumentoDetalle, nuevoValor: any) => void;
}

const CampoPesoSolicitado: FunctionComponent<ICampoPesoSolicitadoProps> = ({ deshabilitado,detalle,manejarCambioCampo }) => {
    const classes = useStyles();

    return (
        <TextField
            classes={{ root: classes.campo }}
            disabled={deshabilitado}
            fullWidth
            label={<Texto id="TablaDetallesPlantilla.CampoPesoSolicitado" />}
            onChange={(event) => manejarCambioCampo(detalle.DocumentoDetalleId, 'PesoFraccionSolicitado', event.target.value)}
            size="small"
            value={detalle.PesoFraccionSolicitado}
        />
    );
};


export default CampoPesoSolicitado;
