export default {
  'sidebar.monitorinventario': 'Monitor inventario',
  'monitorInventarios.Clasificaciones': 'Clasificaciones',
  'monitorInventarios.Bodegas': 'Bodegas',
  'monitorInventarios.valorInventario': 'Valoración inventario',
  'monitorInventarios.cantidad': 'Cantidad',
  'monitorInventarios.stockMinimo': 'Stock mínimo',
  'monitorInventarios.alertaStockMinimo': 'Alerta stock mínimo',
  'monitorInventarios.productoMasAntiguedad': 'Productos más antiguos en bodega',
  'monitorInventarios.top10MasRotacion': 'Top 10 mayor rotación',
  'monitorInventarios.top10MenorRotacion': 'Top 10 menor rotación',
  'monitorInventarios.top10Clientes': 'Top 10 pedidos clientes',
  'monitorInventarios.estadoPedidos': 'Estados pedidos',
  'monitorInventarios.utilizacionBodegas': 'Utilización bodega/s',
  'monitorInventarios.CantidadDisponible': 'Cantidad disponible',
  'monitorInventarios.CantidadUtilizada': 'Cantidad utilizada',
  'monitorInventarios.CantidadInventario': 'Cantidad en inventario',
  'monitorInventarios.FechaCreacion': 'Fecha creación',
};
