import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';

import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import DatosGeneralesCV from '../controladorVista/datosGeneralesCV';
import DivisionesBodegaCV from '../controladorVista/divisionesBodegaCV';
import DivisionesPorDefectoCV from '../controladorVista/divisionesPorDefectoCV';
import PersonasAsociadasCV from '../controladorVista/personasAsociadasCV';
import { IFormularioGestionBodegasVistaProps } from '../interfaces';

const FormularioGestionBodegasVista: React.FunctionComponent<IFormularioGestionBodegasVistaProps> = ({
    alCerrarFormulario,
    bodegaCreada,
    esMovil,
    estado,
    tipoFormulario,
}) => (
    <Dialog open={estado} fullWidth maxWidth="lg">
        <ModalEncabezado
            cerrar={alCerrarFormulario}
            titulo={
                <Texto
                    align="center"
                    color="inherit"
                    id={
                        tipoFormulario === TipoFormulario.Creacion
                            ? 'gestionBodega.agregarBodega'
                            : 'gestionBodega.editarBodega'
                    }
                />
            }
        />
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Accordion defaultExpanded TransitionProps={{ unmountOnExit: false }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Texto id="gestionBodega.datosGenerales" fontWeight="bold" />
                        </AccordionSummary>
                        <AccordionDetails>
                            <DatosGeneralesCV />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion disabled={!bodegaCreada}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Texto id="gestionBodega.divisionesBodega" fontWeight="bold" />
                        </AccordionSummary>
                        <AccordionDetails>
                            <DivisionesBodegaCV />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion disabled={!bodegaCreada}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Texto id="gestionBodega.personasAsociadas" fontWeight="bold" />
                        </AccordionSummary>
                        <AccordionDetails>
                            <PersonasAsociadasCV />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion disabled={!bodegaCreada}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Texto id="gestionBodega.divisionesPorDefecto" fontWeight="bold" />
                        </AccordionSummary>
                        <AccordionDetails>
                            <DivisionesPorDefectoCV />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button onClick={alCerrarFormulario} color="secondary" variant="contained">
                        <Texto id="boton.cerrar" />
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
);

export default FormularioGestionBodegasVista;
