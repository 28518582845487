import { createStyles, Hidden, Theme } from '@material-ui/core';
import { paletaMaterialD } from '@infotrack/presentacion-transversales/constantes';
import temas from 'Infotrack@Transversales/constantes/temas';

export const estilos = (tema: Theme) =>
    createStyles({
        contenedorImagen: {
            padding: tema.spacing(0, 2, 2, 2),
        },
        inputFalso: {
            display: 'none',
        },
        botones: {
            marginRight: tema.spacing(1),
            padding:tema.spacing(0.3,0.3)
        },
        margenIcono: {
            marginRight: tema.spacing(1),
        },
        slideShow: {
            '& .carousel .slide p.legend': {
                backgroundColor: paletaMaterialD.white,
                borderRadius: 5,
                color: paletaMaterialD.black,
                
            },
            scrollY:'hidden'
        },
      
        limiteImagenes: {
            color: paletaMaterialD.red_500,
        },
        
        
    });
