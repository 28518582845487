import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';
import ModeloAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/modelos/Auditoria/ModeloAuditoriaConteo';

class AuditoriaConteoCM {
    private modeloAuditoriaConteo: ModeloAuditoriaConteo;

    constructor() {
        this.modeloAuditoriaConteo = new ModeloAuditoriaConteo();
    }

    public async consultarConteoAuditoria(auditoriaConteoId: string) {
        const respuesta = await this.modeloAuditoriaConteo.consultarConteoAuditoria(auditoriaConteoId);
        return respuesta.data.Entidades[0];
    }

    public async consultarListaConteo(filtro: Partial<IAuditoriaConteo>) {
        const respuesta = await this.modeloAuditoriaConteo.consultarListaConteo(filtro);
        return respuesta.data.Entidades;
    }
}

export default AuditoriaConteoCM;
