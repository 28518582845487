import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloDocumento from 'Infotrack@Modelos/smartStock/documentos';
import IDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/documento';
import IDocumentoConDetalles from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoConDetalle';
import IFiltroGeneralDocumentoConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/filtroGeneralDocumentoConsulta';
import IFiltrosDocumentos from 'Infotrack@Modelos/smartStock/documentos/entidades/filtrosDocumentosMovimientos';
import moment from 'moment';

export default class ControladorModeloDocumento {
    private modeloDocumento: ModeloDocumento;
    constructor() {
        this.modeloDocumento = new ModeloDocumento();
    }
    public consultarDocumentos = (paginador: IPaginador, filtros: IFiltrosDocumentos) =>
        this.modeloDocumento
            .consultarListaDocumentosCompuestos({ paginador, documentos: filtros })
            .then(({ data }) => ({
                ...data,
                Entidades: data.Entidades.map((documento) => ({
                    ...documento,
                    FechaCreacion: moment(documento.FechaCreacion).format('DD/MM/YYYY/hh:mm A'),
                    FechaVencimiento: moment(documento.FechaFinal).format('DD/MM/YYYY/hh:mm A'),
                })),
            }));

    public guardarDocumentoConDetalle(documentoConDetalle: IDocumentoConDetalles) {
        return this.modeloDocumento.guardarDocumentoConDetalle(documentoConDetalle);
    }

    public async consultarGenerarDocumentos(filtroDocumento: IFiltroGeneralDocumentoConsulta) {
        const {
            data: { Entidades },
        } = await this.modeloDocumento.consultaGeneralDocumentosFiltro(filtroDocumento);
        return Entidades[0];
    }

    public cambiarEtapaDevolucionProveedor = async (documento: IDocumento) => {
        const {
            data: { Entidades },
        } = await this.modeloDocumento.cambiarEtapaDevolucionProveedor(documento);
        return Entidades[0];
    };

    public validarDocumentoAbrirFormulario(documento: IDocumento) {
        documento.TipoDocumentoId = 1024;
        if (!documento.TipoDocumentoId || !documento.BodegaDestino || !documento.BodegaOrigen) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else if (
            documento.BodegaOrigen &&
            documento.BodegaDestino &&
            documento.BodegaOrigen !== documento.BodegaDestino
        ) {
            notificacionGlobal('gestionmovimientos.alerta.transladoDivisionesInvalido', 6000, 'warning', true);
            return false;
        } else return true;
    }

    public validarDocumento(documentoConDetalle: IDocumentoConDetalles) {
        if (this.validarDocumentoAbrirFormulario(documentoConDetalle.documento)) {
            if (!documentoConDetalle.documento.DocumentoPadre && documentoConDetalle.documentosDetalles.length === 0) {
                notificacionGlobal('gestionmovimientos.alerta.agregarDetalles', 6000, 'warning', true);
                return false;
            } else return true;
        } else return false;
    }

    public validarFiltros({ BodegaConsulta, FechaInicial, FechaFinal }: IFiltrosDocumentos) {
        if (!BodegaConsulta) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else if (FechaInicial || FechaFinal) {
            if (moment(FechaInicial).isSameOrAfter(FechaFinal)) {
                notificacionGlobal('gestionmovimientos.fechaInvalida', 6000, 'warning', true);
                return false;
            }
            if ((FechaInicial && !FechaFinal) || (FechaFinal && !FechaInicial)) {
                notificacionGlobal('gestionmovimientos.fechaIncompleta', 6000, 'warning', true);
                return false;
            }
        }
        return true;
    }

    public guardarDocumentoDevolucionProveedor(documentoConDetalle: IDocumentoConDetalles) {
        return this.modeloDocumento.guardarDocumentoDevolucionProveedor(documentoConDetalle);
    }

    public editarDevolucionProveedor(documentoConDetalle: IDocumentoConDetalles) {
        return this.modeloDocumento.editarDevolucionProveedor(documentoConDetalle);
    }

    public anularDevolucionProveedor(documento: IDocumento) {
        return this.modeloDocumento.anularDevolucionProveedor(documento);
    }
}
