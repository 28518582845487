export default {
  'sidebar.administracion': 'Administración',
  'sidebar.tiposociedad': 'Tipo Sociedad',
  'tipoSociedad.agregarTipoSociedad': 'Crear Tipo Sociedad',
  'tipoSociedad.confirmacionCreacion': '¿Está seguro que desea crear el registro?',
  'tipoSociedad.confirmacionEdicion': '¿Está seguro que desea editar el registro?',
  'tipoSociedad.confirmacionEliminacion': '¿Está seguro que desea eliminar el registro?',
  'tipoSociedad.modificarTipoSociedad': 'Editar Tipo Sociedad',
  'tipoSociedad.codigoTipoSociedad': 'Código Tipo Sociedad',
  'tipoSociedad.nombreTipoSociedad': 'Nombre Tipo Sociedad',
  'tipoSociedad.estadoTipoSociedad': 'Estado',
};
