import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, compose, Dispatch } from 'redux';

import { IAgencia, IUsuarioInformacion } from '@infotrack/presentacion-modulosbase';

import { cerrarSesionAutenticacion, seleccionarAgencia } from 'Infotrack@Acciones/accionesAutenticacion';
import { cerrarSesionConfiguracion } from 'Infotrack@Acciones/accionesConfiguracionGlobal';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { NOMBRE_APLICACION_POR_DEFECTO } from 'Infotrack@Transversales/constantes/constantesConfig';
import { ILlavesTemas } from 'Infotrack@Transversales/constantes/temas';
import { modificarTituloIconoAplicacion } from 'Infotrack@Transversales/utilitarios/funcionesGenerales';

import { IBarraLateralProps } from '../interfaces/index';
import BarraLateralVista from '../vista/barraLateralVista';

interface IEstadoAProps {
    rutaActiva: string;
    temaActivo: keyof ILlavesTemas;
    usuarioInformacion: IUsuarioInformacion | null;
}

interface IAccionesAProps {
    cerrarSesionAutenticacionAccion: () => void;
    cerrarSesionConfiguracionAccion: () => void;
    seleccionarAgenciaAccion: (agencia: IAgencia | null) => AnyAction;
}

const BarraLateralCV: React.FunctionComponent<IBarraLateralProps &
    IEstadoAProps &
    IAccionesAProps &
    RouteComponentProps> = ({
    accionCerrar,
    cerrarSesionAutenticacionAccion,
    cerrarSesionConfiguracionAccion,
    estadoBarra,
    history,
    rutaActiva,
    seleccionarAgenciaAccion,
    temaActivo,
    usuarioInformacion,
}) => {
    const [refDialogo, setRefDialogo] = React.useState<HTMLElement | null>(null);

    const theme = useTheme();
    const esMovil = useMediaQuery(theme.breakpoints.down('sm'));

    const accionCerrarSesion = () => {
        cerrarSesionAutenticacionAccion();
        cerrarSesionConfiguracionAccion();
        modificarTituloIconoAplicacion(NOMBRE_APLICACION_POR_DEFECTO);
        sessionStorage.clear();
        history.push('/autenticacion');
    };

    const redireccionarInicio = () => {
        history.push(`/${rutaActiva}`);
    };

    const modificarEstadoDialogo = (e: React.MouseEvent<HTMLElement, MouseEvent> | null) => {
        setRefDialogo(e ? e.currentTarget : null);
    };

    const redirecionarAPerfil = () => {
        history.push(`/${rutaActiva}/Administracion/usuario`);
        modificarEstadoDialogo(null);
    };

    const redirecionarACambioContrasenia = () => {
        history.push(`/${rutaActiva}/Usuario/cambioContrasenia`);
        modificarEstadoDialogo(null);
    };

    const redirecionarADatosPersona = () => {
        history.push(`/${rutaActiva}/Usuario/DatosPersonales`);
        modificarEstadoDialogo(null);
    };

    const redireccionarSelector = () => {
        history.push('/seleccionAplicacion');
        seleccionarAgenciaAccion(null);
    };

    return (
        <BarraLateralVista
            accionCerrar={accionCerrar}
            modificarEstadoDialogoPerfil={modificarEstadoDialogo}
            cerrarSesion={accionCerrarSesion}
            estadoBarra={estadoBarra}
            estadoDialogoPerfil={refDialogo}
            esMovil={esMovil}
            irAperfil={redirecionarAPerfil}
            irAselector={redireccionarSelector}
            redireccionar={redireccionarInicio}
            temaActivo={temaActivo}
            usuarioInformacion={usuarioInformacion}
            irCambioContrasenia={redirecionarACambioContrasenia}
            irDatosPersonales={redirecionarADatosPersona}
        />
    );
};

const estadoAProps = (estadoGlobal: IEstadoGlobal): IEstadoAProps => {
    return {
        temaActivo: estadoGlobal.estadoConfiguracion.temaActivo,
        rutaActiva: estadoGlobal.estadoConfiguracion.rutaActiva,
        usuarioInformacion: estadoGlobal.estadoAutenticacion.usuarioInformacion,
    };
};

const accionesAProps = (dispatch: Dispatch): IAccionesAProps => {
    return {
        cerrarSesionAutenticacionAccion: () => dispatch(cerrarSesionAutenticacion()),
        cerrarSesionConfiguracionAccion: () => dispatch(cerrarSesionConfiguracion()),
        seleccionarAgenciaAccion: (agencia: IAgencia | null) => dispatch(seleccionarAgencia(agencia)),
    };
};

export default compose(connect(estadoAProps, accionesAProps), withRouter)(BarraLateralCV) as React.ComponentType<
    IBarraLateralProps
>;
