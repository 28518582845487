import ProveedorEstado from '@infotrack/presentacion-componentes/proveedorEstado';
import React from 'react';
import FormularioGestionBodegaCV from './controladorVista/formularioGestionBodegaCV';
import TablaGestionBodegaCV from './controladorVista/tablaGestionBodegaCV';
import { estadoInicial, reductor } from './reductorGestionBodegas';

const GestionBodegas = () => (
    <ProveedorEstado estadoInicial={estadoInicial} reductor={reductor}>
        <TablaGestionBodegaCV />
        <FormularioGestionBodegaCV />
    </ProveedorEstado>
);

export default GestionBodegas;
