import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import IDivisiones from 'Infotrack@Modelos/smartStock/divisiones/entidades/divisiones';
import IItemMovimiento from 'Infotrack@Modelos/smartStock/movimientos/entidades/itemMovimiento';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import EntidadesItemMovimientoCM from '../controladorModelo/entidadesItemMovimientoCM';
import MovimientoCM from '../controladorModelo/movimientoCM';
import {
    IEstadoAPropiedades,
    IInputsItemMovimientoFiltros,
    IItemDivisionDetalle,
    IParametrosBusquedaItem,
    IParametrosCambioValor,
    ISelectMasivoItemsCVProps,
} from '../interfaces';

import { TipoAccion } from '@infotrack/presentacion-transversales/interfacesComunes';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import SelectMasivoItemsVista from '../vista/selectMasivoItemsVista';

const entidadesItemMovimientoCM = new EntidadesItemMovimientoCM();
const movimientoCM = new MovimientoCM();

const filtrosItemMovimientos: IInputsItemMovimientoFiltros = {
    BodegaId: '',
    CodigoBarras: '',
    DescripcionItem: '',
    Division: null,
};

const paginacionInicial = {
    pagina: 0,
    totalRegistros: 0,
    registrospagina: 10,
};

const SelectMasivoItemsCV: React.FunctionComponent<IEstadoAPropiedades & ISelectMasivoItemsCVProps> = React.forwardRef(
    (
        {
            alCerrarFormulario,
            alModificarDetalles,
            tipoDocumentoId,
            AgenciaId,
            bodegaId,
            bodegaDestinoId,
            desactivarDivisionDestino,
            divisionMultiple,
            estadoFormulario,
            EmpresaId,
            UsuarioId,
        },
        ref
    ) => {
        const [divisionDestino, setDivisionDestino] = React.useState<IDivisiones | null>(null);
        const [divisiones, setDivisiones] = React.useState<IDivisiones[]>([]);
        const [divisionesDestino, setDivisionesDestino] = React.useState<IDivisiones[]>([]);
        const [enEdicion, setEnEdicion] = React.useState(false);
        const [itemSeleccionado, setItemSeleccionado] = React.useState<number>();
        const [itemsMovimientos, setItemsMovimientos] = React.useState<IItemMovimiento[]>([]);
        const [itemsMovimientosAgregados, setItemsMovimientosAgregados] = React.useState<IItemDivisionDetalle[]>([]);
        const { alCambiarValor, setValor, reiniciar, valor } = useInputState(filtrosItemMovimientos);
        const [opcionesPaginacion, setOpcionesPaginacion] = React.useState(paginacionInicial);

        React.useEffect(() => {
            if (bodegaId && tipoDocumentoId) consultarDivisiones(bodegaId, tipoDocumentoId, true);
        }, [bodegaId]);

        React.useEffect(() => {
            if (bodegaDestinoId && tipoDocumentoId) consultarDivisiones(bodegaDestinoId, tipoDocumentoId);
        }, [bodegaDestinoId]);

        React.useEffect(() => {
            if (!estadoFormulario && enEdicion) {
                setOpcionesPaginacion(paginacionInicial);
                setItemsMovimientos([]);
                setEnEdicion(false);
            }
        }, [estadoFormulario]);

        React.useEffect(() => {
            if (itemsMovimientos.length > 0) consultarItemMovimientos();
        }, [opcionesPaginacion.pagina, opcionesPaginacion.registrospagina]);

        React.useImperativeHandle(ref, () => ({
            establecerItems(documentosDetalles: IDocumentoDetalleCompuesto[]) {
                setItemsMovimientosAgregados(
                    documentosDetalles.map(
                        ({
                            Accion,
                            CantidadSolicitada,
                            DescripcionItem,
                            DescripcionDivisionOrigen,
                            DivisionOrigen,
                            ItemId,
                            DivisionDestino,
                            ...detalle
                        }) => ({
                            ...detalle,
                            Accion,
                            CantidadSeleccionada: CantidadSolicitada!,
                            DescripcionItem,
                            DivisionDescripcion: DescripcionDivisionOrigen,
                            DivisionId: DivisionOrigen!,
                            DivisionDestinoId: DivisionDestino,
                            ItemId: ItemId!,
                        })
                    )
                );
            },
            editarItem(itemAEditar: IParametrosBusquedaItem) {
                consultarItemMovimientos(itemAEditar);
                setEnEdicion(true);
                setValor({
                    ...valor,
                    DivisionId: itemAEditar.divisionId,
                    Division: { ...valor.Division, DivisionId: itemAEditar.divisionId, DivisionDescripcion: '' },
                } as any);
            },
            eliminarItem({ divisionId, itemId }: IParametrosBusquedaItem) {
                setItemsMovimientosAgregados((itemsAFiltrar) =>
                    itemsAFiltrar.filter(({ ItemId, DivisionId }) => !(ItemId === itemId && DivisionId === divisionId))
                );
                setItemsMovimientos((itemsAFiltrar) =>
                    itemsAFiltrar
                        .map((item) => ({
                            ...item,
                            Divisiones: item.Divisiones.map((division) => {
                                const existeDivision = item.ItemId === itemId && division.DivisionId === divisionId;
                                return {
                                    ...division,
                                    CantidadSeleccionada: existeDivision ? 0 : division.CantidadSeleccionada,
                                    DivisionDestinoId: existeDivision ? undefined : division.DivisionDestinoId,
                                };
                            }),
                        }))
                        .map((item) => ({
                            ...item,
                            CantidadSeleccionada: item.Divisiones.reduce(
                                (nuevaCantidadDetalle, divisionActual) =>
                                    (nuevaCantidadDetalle += divisionActual.CantidadSeleccionada),
                                0
                            ),
                        }))
                );
            },
            reiniciarEstado() {
                reiniciar();
                setDivisionDestino(null);
                setItemSeleccionado(undefined);
                setItemsMovimientos([]);
                setItemsMovimientosAgregados([]);
            },
        }));

        const consultarItemMovimientos = (filtrosEdicion?: IParametrosBusquedaItem) => {
            if (itemsMovimientos.length > 0) cargarItemsSeleccionados();
            const filtros = {
                BodegaId: bodegaId!,
                CodigoBarras: valor.CodigoBarras,
                DescripcionItem: valor.DescripcionItem,
                DivisionId: filtrosEdicion
                    ? filtrosEdicion.divisionId
                    : Number(valor.Division ? valor.Division!.DivisionId : 0),
                ItemId: filtrosEdicion ? filtrosEdicion.itemId : undefined,
            };
            if (movimientoCM.validarFiltros(filtros)) {
                movimientoCM
                    .consultarItemsMovimientosFiltro(filtros, {
                        Pagina: opcionesPaginacion.pagina,
                        Registros: opcionesPaginacion.registrospagina,
                        TotalRegistros: 0,
                        PropiedadesDeOrdenamiento: [
                            {
                                NombrePropiedad: 'DescripcionItem',
                                Orden: 0,
                            },
                        ],
                    })
                    .then(({ Entidades, Paginador }) => {
                        setItemsMovimientos(validarItemAgregados(Entidades));
                        setOpcionesPaginacion((opciones) => ({
                            ...opciones,
                            totalRegistros: Paginador.TotalRegistros!,
                        }));
                    });
            }
        };

        const consultarDivisiones = (bodega: number, tipoDocumento: number, divisionOrigen?: boolean) => {
            entidadesItemMovimientoCM
                .consultarEntidadesItemMovimiento(Number(bodega), Number(tipoDocumento))
                .then((entidades) => {
                    if (divisionOrigen) setDivisiones(entidades);
                    else setDivisionesDestino(entidades);
                });
        };

        const alExpandirItem = (itemId: number) => {
            setItemSeleccionado(itemId === itemSeleccionado ? undefined : itemId);
        };

        const alCambiarValorCantidades = ({
            Id,
            ItemId,
            NivelModificacion,
            ValorModificacion,
        }: IParametrosCambioValor) => {
            const copiaItemMovimientos = obtenerCopiaItemMovimientos();
            const itemAModificar = copiaItemMovimientos.find((itemMovimiento) => itemMovimiento.ItemId === ItemId)!;
            if (NivelModificacion === 'Item') {
                if (itemAModificar.CantidadDisponible >= ValorModificacion) {
                    itemAModificar.CantidadSeleccionada = ValorModificacion;
                }
            }
            if (NivelModificacion === 'Division') {
                const divisionAModificar = itemAModificar.Divisiones.find(({ DivisionId }) => DivisionId === Id)!;
                if (divisionAModificar.CantidadDisponible >= ValorModificacion) {
                    divisionAModificar.CantidadSeleccionada = ValorModificacion;
                    itemAModificar.CantidadSeleccionada = itemAModificar.Divisiones.reduce(
                        (nuevaCantidadDetalle, divisionActual) =>
                            (nuevaCantidadDetalle += divisionActual.CantidadSeleccionada),
                        0
                    );
                }
            }
            setItemsMovimientos(copiaItemMovimientos);
        };

        const seleccionMasiva = (itemId: number) => {
            const copiaItemMovimientos = obtenerCopiaItemMovimientos();
            const itemAModificar = copiaItemMovimientos.find(({ ItemId }) => ItemId === itemId)!;
            const cantidadSeleccionada = itemAModificar.CantidadSeleccionada;
            itemAModificar.Divisiones.forEach((division) => {
                if (cantidadSeleccionada > 0)
                    division.CantidadSeleccionada =
                        division.CantidadDisponible >= cantidadSeleccionada
                            ? cantidadSeleccionada
                            : division.CantidadDisponible;
            });
            setItemsMovimientos(copiaItemMovimientos);
        };

        const obtenerCopiaItemMovimientos = () =>
            itemsMovimientos.map((itemMovimiento) => ({
                ...itemMovimiento,
                Divisiones: itemMovimiento.Divisiones.map((division) => ({
                    ...division,
                })),
            }));

        const validarItemAgregados = (itemsMovimientosConsulta: IItemMovimiento[]) =>
            itemsMovimientosConsulta
                .map((itemMovimiento) => ({
                    ...itemMovimiento,
                    Divisiones: itemMovimiento.Divisiones.map((division) => {
                        const detalleExistente = itemsMovimientosAgregados.find(
                            ({ ItemId, DivisionId }) =>
                                DivisionId === division.DivisionId && ItemId === itemMovimiento.ItemId
                        );
                        if (detalleExistente)
                            return {
                                ...division,
                                CantidadSeleccionada: detalleExistente.CantidadSeleccionada,
                                DivisionDestinoId: detalleExistente.DivisionDestinoId,
                            };
                        else return division;
                    }),
                }))
                .map((itemMovimiento) => ({
                    ...itemMovimiento,
                    CantidadSeleccionada: itemMovimiento.Divisiones.reduce(
                        (acumulado, { CantidadSeleccionada }) => (acumulado += CantidadSeleccionada),
                        0
                    ),
                }));

        const cargarItemsSeleccionados = (crearDetalles?: boolean) => {
            const nuevosDocumentosDetalles = itemsMovimientos.reduce<IItemDivisionDetalle[]>(
                (detalles, itemMovimiento) => {
                    itemMovimiento.Divisiones.forEach((division) => {
                        if (division.CantidadSeleccionada > 0) {
                            const itemEnMovimientos = itemsMovimientosAgregados.find(
                                ({ ItemId, DivisionId }) =>
                                    ItemId === itemMovimiento.ItemId && DivisionId === division.DivisionId
                            )!;
                            detalles.push({
                                ...itemEnMovimientos,
                                ...itemMovimiento,
                                DivisionDescripcion: division.DivisionDescripcion,
                                DivisionId: division.DivisionId,
                                DivisionDestinoId: division.DivisionDestinoId
                                    ? division.DivisionDestinoId
                                    : divisionDestino
                                    ? Number(divisionDestino.DivisionId)
                                    : undefined,
                            });
                        }
                    });
                    return detalles;
                },
                []
            );
            itemsMovimientosAgregados.forEach((itemMovimiento) => {
                const detalleEnMovimientos = Boolean(
                    nuevosDocumentosDetalles.find(
                        ({ ItemId, DivisionId }) =>
                            ItemId === itemMovimiento.ItemId && DivisionId === itemMovimiento.DivisionId
                    )
                );
                if (!detalleEnMovimientos) nuevosDocumentosDetalles.push(itemMovimiento);
            });
            if (desactivarDivisionDestino) {
                nuevosDocumentosDetalles.forEach((documentoDetalle) => {
                    documentoDetalle.DivisionDestinoId = divisionesDestino
                        ? divisionesDestino[0].DivisionId
                        : undefined;
                });
            }
            if (alModificarDetalles && crearDetalles) crearDocumentosDetalles(nuevosDocumentosDetalles);
            setItemsMovimientosAgregados(nuevosDocumentosDetalles);
        };

        const crearDocumentosDetalles = (items: IItemDivisionDetalle[]) => {
            alModificarDetalles!(
                items.map(
                    (itemMovimiento) =>
                        ({
                            ...itemMovimiento,
                            Accion:
                                itemMovimiento.Accion !== undefined && !enEdicion
                                    ? itemMovimiento.Accion
                                    : enEdicion
                                    ? TipoAccion.editado
                                    : TipoAccion.guardado,
                            CantidadSolicitada: itemMovimiento.CantidadSeleccionada,
                            DescripcionDivisionOrigen: itemMovimiento.DivisionDescripcion,
                            DivisionOrigen: itemMovimiento.DivisionId,
                            DivisionDestino: itemMovimiento.DivisionDestinoId,
                            EmpresaId,
                            DescripcionDivisionDestino: itemMovimiento.DivisionDestinoId
                                ? divisionesDestino.find(
                                      ({ DivisionId }) => itemMovimiento.DivisionDestinoId === DivisionId
                                  )!.DivisionDescripcion
                                : undefined,
                        } as any)
                )
            );
        };

        const alCambiarValorDivisionesDestino = ({ Id, ItemId, ValorModificacion }: IParametrosCambioValor) => {
            setItemsMovimientos((items) =>
                items.map((item) => {
                    if (item.ItemId === ItemId)
                        return {
                            ...item,
                            Divisiones: item.Divisiones.map((division) => {
                                if (division.DivisionId === Id)
                                    return { ...division, DivisionDestinoId: ValorModificacion };
                                else return division;
                            }),
                        };
                    else return item;
                })
            );
        };

        const alCambiarValorDivisionDestino = (nuevaDivisionDestino: IDivisiones) => {
            setDivisionDestino(nuevaDivisionDestino);
        };

        const cambiarPagina = (siguiente: boolean) => {
            setOpcionesPaginacion((opciones) => ({
                ...opciones,
                pagina: siguiente ? opciones.pagina + 1 : opciones.pagina - 1,
            }));
        };

        const cambiarOpcionesRegistros = (opcion: number) => {
            setOpcionesPaginacion((opciones) => ({ ...opciones, registrospagina: opcion }));
        };

        return (
            <SelectMasivoItemsVista
                alAgregarItems={cargarItemsSeleccionados}
                alCambiarValor={alCambiarValor}
                alCambiarValorCantidades={alCambiarValorCantidades}
                alCambiarValorDivisionDestino={alCambiarValorDivisionDestino}
                alCambiarValorDivisionesDestino={alCambiarValorDivisionesDestino}
                alCerrarFormulario={alCerrarFormulario}
                alExpandirItem={alExpandirItem}
                consultarItemMovimientos={consultarItemMovimientos}
                desactivarDivisionDestino={desactivarDivisionDestino}
                divisionDestino={divisionDestino}
                divisiones={divisiones}
                divisionesDestino={divisionesDestino}
                divisionMultiple={divisionMultiple}
                enEdicion={enEdicion}
                estadoFormulario={estadoFormulario}
                filtrosItem={valor}
                itemSeleccionado={itemSeleccionado}
                itemsMovimientos={itemsMovimientos}
                opcionesPaginacion={{ ...opcionesPaginacion, cambiarPagina, cambiarOpcionesRegistros }}
                seleccionMasiva={seleccionMasiva}
            />
        );
    }
);

const estadoAPropiedades = ({ estadoAutenticacion }: IEstadoGlobal): IEstadoAPropiedades => ({
    AgenciaId: estadoAutenticacion.agenciaActual!.IdAgencia,
    EmpresaId: estadoAutenticacion.usuarioInformacion!.IdEmpresa,
    UsuarioId: estadoAutenticacion.usuarioInformacion!.IdUsuario,
});

export default connect<IEstadoAPropiedades, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)((props: any) => <SelectMasivoItemsCV {...props} ref={props.customRef} />);
