export default {
  'sidebar.administracion': 'Administración',
  'sidebar.tiporegimen': 'Tipo Régimen',
  'tipoRegimen.agregarTipoRegimen': 'Crear Tipo Régimen',
  'tipoRegimen.confirmacionCreacion': '¿Está seguro que desea crear el registro?',
  'tipoRegimen.confirmacionEdicion': '¿Está seguro que desea editar el registro?',
  'tipoRegimen.confirmacionEliminacion': '¿Está seguro que desea eliminar el registro?',
  'tipoRegimen.modificarTipoRegimen': 'Editar Tipo Régimen',
  'tipoRegimen.codigoTipoRegimen': 'Código Tipo Régimen',
  'tipoRegimen.nombreTipoRegimen': 'Nombre Tipo Régimen',
  'tipoRegimen.estadoTipoRegimen': 'Estado',
};
