import ModeloPersonas from 'Infotrack@Modelos/administracion/persona';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';

export default class EntidadesBodegaPersonaCM {
  private modeloPersonas: ModeloPersonas;

  constructor() {
    this.modeloPersonas = new ModeloPersonas();
  }

  public consultarListaPersonasPorAgencia(IdAgencia: string) {
    return this.modeloPersonas.consultarListaPersonasPorAgencia(IdAgencia).then(({ data: { Entidades } }) => Entidades);
  }
  public consultarListaPersonasPorEmpresa(IdEmpresa: string) {
    return this.modeloPersonas.consultarListaPersonasPorEmpresa(IdEmpresa).then(({ data: { Entidades } }) => Entidades);
  }
}
