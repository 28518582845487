import AppLocaleData from 'react-intl/locale-data/es';
import espanolRecursos from '../idiomas/es_ES';

const EsLeng = {
  data: AppLocaleData,
  locale: 'es-ES',
  messages: {
    ...espanolRecursos
  }
};
export default EsLeng;
