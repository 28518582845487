import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocio } from 'Infotrack@Modelos/conexiones';
import IItem from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IItem';

class ModeloItem {
    private NOMBRE_CONTROLADOR = 'Item';

    public async consultarListaItemPorFiltro(item: Partial<IItem>) {
        return manejadorRest<IRespuesta<IItem>>(
            () => negocio.post(`${this.NOMBRE_CONTROLADOR}/ConsultarListaFiltro`, item),
            true
        );
    }

    public async consultarListaItemPorEmpresa(idEmpresa: string) {
        return manejadorRest<IRespuesta<IItem>>(
            () => negocio.get(`${this.NOMBRE_CONTROLADOR}/ConsultarItemsEmpresa/${idEmpresa}`),
            true
        );
    }
}
export default ModeloItem;
