import ModeloBodegasDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones';
import IDivisionesBodegasPersonas from 'Infotrack@Modelos/smartStock/divisiones/entidades/divisionesBodegasPersonas';

export default class EntidadesItemMovimientoCM {
  private modeloBodegasDivisiones: ModeloBodegasDivisiones;

  constructor() {
    this.modeloBodegasDivisiones = new ModeloBodegasDivisiones();
  }

  public async consultarEntidadesItemMovimiento(BodegaId: number, TipoDocumentoId: number) {
    const {
      data: { Entidades },
    } = await this.modeloBodegasDivisiones.consultarDivisionesDocumentosDef({ BodegaId, TipoDocumentoId });
    return Entidades;
  }
}
