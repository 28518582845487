import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import SelectMasivoItems from 'Infotrack@Transversales/componentes/selectMasivoItems';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import FormularioDocumentoDetalleCV from '../controladorVista/formularioDocumentoDetalleCV';
import { ITablaDocumentosDetalleVistaProps } from '../interfaces';
import {
    accionesTablaDocumentoDetalle,
    columnasTablaDocumentoDetalle,
    opcionesTablaDocumentoDetalle,
} from './constantes';

const TablaDocumentoDetallesVista: React.FunctionComponent<ITablaDocumentosDetalleVistaProps & InjectedIntlProps> = ({
    alAbrirFormulario,
    alEliminarDocumentoDetalle,
    deshabilitarEdicion,
    documentosDetalles,
    intl: { formatMessage },
    manejadorSeleccionDocumentoDetalle,
}) => (
    <>
        <FormularioDocumentoDetalleCV />
        <Tabla
            actions={accionesTablaDocumentoDetalle(alAbrirFormulario, alEliminarDocumentoDetalle, deshabilitarEdicion)}
            columns={columnasTablaDocumentoDetalle(formatMessage, manejadorSeleccionDocumentoDetalle)}
            data={documentosDetalles}
            title={<Texto id="gestionoperadores.documentosusuarios" fontWeight="bold" />}
            options={opcionesTablaDocumentoDetalle}
        />
    </>
);

export default injectIntl(TablaDocumentoDetallesVista);
