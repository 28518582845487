import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';
import IInformacionAuditoria from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IInformacionAuditoria';
import ModeloAuditoria from 'Infotrack@Modelos/negocioRefactor/modelos/Auditoria/ModeloAuditoria';

class AuditoriaCM {
    private modeloAuditoria: ModeloAuditoria;

    constructor() {
        this.modeloAuditoria = new ModeloAuditoria();
    }

    public async crearAuditoria(auditoria: IAuditoriaCompuesta) {
        if (!this.validarCamposObligatoriosAuditoria(auditoria)) return { Entidades: [], Resultado: false };
        const respuesta = await this.modeloAuditoria.crearAuditoria(this.mapearAuditoria(auditoria));
        return respuesta.data;
    }

    public async consultarAuditoria(filtro: Partial<IAuditoriaCompuesta>) {
        const respuesta = await this.modeloAuditoria.consultarAuditoria(filtro);
        return respuesta.data.Entidades[0];
    }

    public async consultarListaAuditoria(filtro: Partial<IInformacionAuditoria>) {
        const respuesta = await this.modeloAuditoria.consultarListaAuditoria(filtro);
        return respuesta.data.Entidades;
    }

    public async anularAuditoria(auditoriaId: string) {
        const respuesta = await this.modeloAuditoria.anularAuditoria(auditoriaId);
        return respuesta.data.Resultado;
    }

    public async consultarEtapas(documentoId: string) {
        const respuesta = await this.modeloAuditoria.consultarEtapas(documentoId);
        return respuesta.data.Entidades;
    }

    public async cambiarEtapa(documentoId: string, etapaId: number) {
        const respuesta = await this.modeloAuditoria.cambiarEtapa(documentoId, etapaId);
        return respuesta.data.Resultado;
    }

    private validarCamposObligatoriosAuditoria(auditoria: IAuditoriaCompuesta) {
        if (!auditoria.BodegaId) {
            notificacionGlobal('Auditoria.ValidacionNoBodegaId', 3000, 'warning', true);
            return false;
        }
        if (!auditoria.TipoDocumentoId) {
            notificacionGlobal('Auditoria.ValidacionNoTipoDocumentoId', 3000, 'warning', true);
            return false;
        }
        return true;
    }

    /**
     * mapearAuditoria
     */
    private mapearAuditoria(auditoria: IAuditoriaCompuesta) {
        const auditoriaGuardar: IInformacionAuditoria = {
            TipoDocumentoId: auditoria.TipoDocumentoId,
            BodegaId: auditoria.BodegaId,
            Divisiones: auditoria.Divisiones.map((d) => d.DivisionId),
            Familias: auditoria.Familias.map((f) => f.FamiliaId),
            Items: auditoria.Items.map((i) => i.ItemId),
            AuditoriaId: '',
            DocumentoId: '',
            EmpresaId: auditoria.EmpresaId,
            AgenciaId: auditoria.AgenciaId,
        };
        return auditoriaGuardar;
    }
}

export default AuditoriaCM;
