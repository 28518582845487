import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';

interface ICampoPesoProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampo: keyof IDocumentoDetalle,
        nuevoValor: IDocumentoDetalle[keyof IDocumentoDetalle]
    ) => any;
    valor: number | undefined;
    esPesoSolicitado: boolean;
}

const CampoPeso: FunctionComponent<ICampoPesoProps> = ({
    deshabilitado,
    manejarCambioCampo,
    valor,
    esPesoSolicitado,
}) => {
    return (
        <TextField
            disabled={deshabilitado}
            fullWidth
            label={<Texto id="DetallesFormulario.CampoPeso.Nombre" />}
            onChange={(event) => {
                if (/^\d*$/.test(event.target.value)) {
                    manejarCambioCampo(
                        esPesoSolicitado ? 'PesoFraccionSolicitado' : 'PesoFraccionRecibido',
                        event.target.value
                    );
                }
            }}
            value={valor}
        />
    );
};

export default CampoPeso;
