export default {
  'sidebar.administracion': 'Administración',
  'sidebar.grupoactividadeconomica': 'Grupo Actividad Económica',
  'grupoActividadEconomica.agregarGrupoActividadEconomica': 'Crear Grupo Actividad Económica',
  'grupoActividadEconomica.confirmacionCreacion': '¿Está seguro que desea crear el registro?',
  'grupoActividadEconomica.confirmacionEdicion': '¿Está seguro que desea editar el registro?',
  'grupoActividadEconomica.confirmacionEliminacion': '¿Está seguro que desea eliminar el registro?',
  'grupoActividadEconomica.modificarGrupoActividadEconomica': 'Editar Grupo Actividad Económica',
  'grupoActividadEconomica.codigoGrupoActividadEconomica': 'Código Grupo Actividad Económica',
  'grupoActividadEconomica.nombreGrupoActividadEconomica': 'Nombre Grupo Actividad Económica',
  'grupoActividadEconomica.estadoGrupoActividadEconomica': 'Estado',
};
