import { Button, withStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import Texto from '@infotrack/presentacion-componentes/texto';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IEstadoAPropiedades } from '../interfaces';
import estilos from './estilos';

const PaginaNoEncontradaVista: React.FunctionComponent<PropsEstilosMaterialUI<
    RouteComponentProps & IEstadoAPropiedades
>> = ({ classes, history, rutaActiva }) => (
    <div className={classes.contenedorFlex}>
        <div className={classes.contenedorItems}>
            <Texto display="block" id="title.paginaNoEncontrada" className={classes.titulo} />
            <Texto display="block" id="subtitle.paginaNoEncontrada" className={classes.subTitulo} />
            <Button variant="contained" color="primary" onClick={() => history.push(`/${rutaActiva}`)}>
                <Texto id="button.paginaNoEncontrada" color="inherit" /> <HomeIcon className={classes.iconoBoton} />
            </Button>
        </div>
    </div>
);

const estadoAppPropiedades = ({ estadoConfiguracion }: IEstadoGlobal): IEstadoAPropiedades => ({
    rutaActiva: estadoConfiguracion.rutaActiva,
});

export default connect<IEstadoAPropiedades, null, any, IEstadoGlobal>(
    estadoAppPropiedades,
    null
)(withStyles(estilos)(withRouter(PaginaNoEncontradaVista)));
