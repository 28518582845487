import React, { FunctionComponent } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import Texto from '@infotrack/presentacion-componentes/texto';

import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';

interface ICampoSincronizacionMovilProps {
    deshabilitado: boolean;
    manejarCambioCampo: (nombreCampo: keyof ITipoDocumento, nuevoValor: ITipoDocumento[keyof ITipoDocumento]) => any;
    valor: boolean;
}

const CampoSincronizacionMovil: FunctionComponent<ICampoSincronizacionMovilProps> = ({
    deshabilitado,
    manejarCambioCampo,
    valor,
}) => (
    <FormControlLabel
        checked={valor === true}
        control={<Checkbox />}
        disabled={deshabilitado}
        label={<Texto id="TiposDocumento.CampoSincronizacionMovil.Nombre" />}
        onChange={(_, checked) => manejarCambioCampo('SincronizaMovil', checked ? true : false)}
    />
);

export default CampoSincronizacionMovil;
