import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, FunctionComponent, useEffect } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import IItemsConsulta from 'Infotrack@Modelos/smartStock/items/entidades/IItemsConsulta';

interface ICampoCodigoBarrasItemProps {
    deshabilitado: boolean;
    items?: IItemsConsulta[];
    manejarCambioCampo: (
        nombreCampo: keyof IDocumentoDetalle,
        nuevoValor: IItemsConsulta | null
    ) => any;
    valor: number | undefined;
}

const CampoCodigoBarrasItem: FunctionComponent<ICampoCodigoBarrasItemProps> = ({
    deshabilitado,
    items,
    manejarCambioCampo,
    valor,
}) => {
    useEffect(() => {
        ordenarCodigoBarras();
    }, [items]);

    const ordenarCodigoBarras = () => {
        const reg = /[0-9]+/g;
        items!.sort((a, b) => {
            let v0 = a.CodigoBarras.replace(reg, (v) => v.padStart(10, '0'));
            let v1 = b.CodigoBarras.replace(reg, (v) => v.padStart(10, '0'));
            return v0.localeCompare(v1);
        });
    };

    return (
        <Autocomplete
            disabled={deshabilitado}
            onChange={(_: ChangeEvent<{}>, value: IItemsConsulta | null) =>
                manejarCambioCampo('ItemId', value)
            }
            options={items || []}
            getOptionLabel={(i) => i.CodigoBarras}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    label={<Texto id="DetallesFormulario.CampoCodigoBarrasItem.Nombre" />}
                    required
                />
            )}
            value={items ? items.find((i) => i.ItemId === valor) || null : null}
        />
    );
};

export default CampoCodigoBarrasItem;
