export default {
    'pedidos.NumeroDocumento': 'N° Documento',
    'pedidos.TipoDocumento': 'Tipo documento',
    'pedidos.FechaCreacionDocumento': 'Fecha inicial',
    'pedidos.FechaVencimientoDocumento': 'Fecha final',
    'pedidos.PersonaCreacion': 'Personas asociadas',
    'pedidos.EstadoDocumento': 'Estado documento',
    'pedidos.Bodega': 'Bodega',
    'pedidos.Cliente': 'Cliente',
    'pedidos.documento.CodigoDocumento': 'Código',
    'pedidos.documento.DocumentoRelacionado': 'Documento relacionado',
    'pedidos.documento.CodigoDocumentoPadre': 'Documento padre',
    'pedidos.documento.DescripcionProveedor': 'Proveedor',
    'pedidos.documento.FechaCreacion': 'Fecha creación',
    'pedidos.documento.EtapaDescripcion': 'Etapa',
    'pedidos.documento.Observacion': 'Observación',
    'pedidos.documento.DocumentoPadreId': 'Documento padre',
    'pedidos.documento.DocumentoPadre': '¿Es documento padre?',
    'pedidos.documento.editarDocumento': 'Editar documento',
    'pedidos.documento.BodegaOrigen': 'Bodega origen',
    'pedidos.documento.DescripcionCliente': 'Cliente',
    'gestionauditorias.modificarEtapa': 'Cambiar etapa',
    'pedidos.documentoDetalle.DescripcionItem': 'Descripción ítem',
    'pedidos.documentoDetalle.CodigoBarras': 'Código de barras',
    'pedidos.documentoDetalle.CantidadSolicitada': 'Cantidad solicitada',
    'pedidos.documentoDetalle.CantidadRecibida': 'Cantidad entregada',
    'pedidos.documentoDetalle.DivisionOrigen': 'División de origen',
    'pedidos.documentoDetalle.DivisionDestino': 'División de destino',
    'pedidos.documento.datosDocumento': 'Datos documento',
    'pedidos.documentoDetalle.documentoDetalles': 'Detalles del documento',
    'pedidos.documentoDetalle.crearDetalleDocumento': 'Crear detalle',
    'pedidos.documentoDetalle.editarDetalleDocumento': 'Editar detalle',
    'pedidos.documento.titulo.modificarEstadoDocumento': 'Modificar estado pedido',
    'pedidos.alerta.fechaInvalida': 'La fecha final debe ser mayor a la fecha inicial',
    'pedidos.alerta.fechaFaltante': 'Ingrese las dos fechas para poder realizar la búsqueda',
    'pedidos.alerta.cantidadInsuficiente': 'La bodega/división no contiene la cantidad solicitada. Cantidad actual:',
    'pedidos.alerta.faltaBodegaOrigen': 'Por favor seleccione los campos requeridos del documento',
    'pedidos.alerta.confirmarCambioBodega':
        '¿Está seguro que desea cambiar la bodega?, se eliminaran los detalles agregados',
    'pedidos.alerta.detalleEnAlistamiento':
        'El producto ya se encuentra en alistamiento, si desea editarlo solicite que la modificación de la cantidad alistada',
    'pedidos.alerta.cambioEstadoAlistamiento': '¿Está seguro que desea iniciar el alistamiento?',
    'pedidos.alerta.cambioEstadoAlistado': '¿Está seguro que desea finalizar el alistamiento?',
    'pedidos.alerta.cambioEstadoTransito':
        '¿Está seguro que desea iniciar el proceso de transito?, se realizara afectación del inventario',
    'pedidos.alerta.cambioEstadoDespacho':
        '¿Está seguro que desea recibir el pedido?, se realizara afectación del inventario',
    'pedidos.alerta.cantidadMayor': 'La cantidad recibida no puede ser mayor a la solicitada',
    'pedidos.alerta.divisionesIguales': 'La división de origen no puede ser igual a la de destino',

    // TablaPedidos
    'Alistamiento.TablaPedidos.BotonGestionarAlistamiento': 'Gestionar alistamiento',
    'Alistamiento.TablaPedidos.BotonAnular': 'Anular',
    'Alistamiento.TablaPedidos.CodigoDocumentoPredecesor': 'Código',
    'Alistamiento.TablaPedidos.DocumentoRelacionadoPredecesor': 'Documento relacionado',
    'Alistamiento.TablaPedidos.CodigoDocumento': 'Código alistamiento',
    'Alistamiento.TablaPedidos.FechaCreacion': 'Fecha creación',
    'Alistamiento.TablaPedidos.DescripcionCliente': 'Cliente',
    'Alistamiento.TablaPedidos.EtapaDescripcionDocumentoPredecesor': 'Etapa',
    'Alistamiento.TablaPedidos.ObservacionDocumentoPredecesor': 'Observación',
    // ModalAlistamiento
    'ModalAlistamiento.TituloVisualizacion': 'Ver documento',
    'ModalAlistamiento.TituloGestionAlistamiento': 'Gestionar alistamiento',
    'ModalAlistamiento.TituloTablaDetallesPedido': 'Detalles pedido',
    'ModalAlistamiento.TituloTablaDetallesAlistamiento': 'Detalles alistamiento',
    'ModalAlistamiento.ConfirmacionFinalizarAlistamiento': '¿Está seguro que desea el alistamiento?',
    // ModalAlistamientoAcciones
    'ModalAlistamientoAcciones.ConfirmarAlistamiento': 'Confirmar alistamiento',
    // TablaDetallesPedido
    'TablaDetallesPedido.Titulo': 'Detalles pedido',
    'TablaDetallesPedido.DescripcionItem': 'Ítem',
    'TablaDetallesPedido.CodigoBarras': 'Código barras',
    'TablaDetallesPedido.DivisionOrigen': 'División origen',
    'TablaDetallesPedido.Valor': 'Peso',
    'TablaDetallesPedido.Lote': 'Lote',
    'TablaDetallesPedido.FechaVencimiento': 'Fecha vencimiento',
    'TablaDetallesPedido.DivisionDestino': 'División destino',
    'TablaDetallesPedido.CantidadRecibida': 'Ítem',
    'TablaDetallesPedido.TooltipAsignarSeriales': 'Asignar seriales',
    'TablaDetallesPedido.Serial': 'Seriales',
};
