import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import Autenticacion from '@infotrack/presentacion-modulosbase/modulos/Autenticacion/componentes/Autenticacion';
import IAccion from '@infotrack/presentacion-modulosbase/modelos/seguridad/autorizacion/entidades/IAccion';
import IMenu from '@infotrack/presentacion-modulosbase/modelos/seguridad/autorizacion/entidades/IMenu';
import IAgencia from '@infotrack/presentacion-modulosbase/modelos/seguridad/agencia/entidades/IAgencia';
import IUsuarioAplicacionAgencia from '@infotrack/presentacion-modulosbase/modelos/seguridad/usuario/entidades/IUsuarioAplicacionAgencia';
import IUsuarioInformacion from '@infotrack/presentacion-modulosbase/modelos/seguridad/autenticacion/entidades/IUsuarioInformacion';
import SeleccionAplicacion from '@infotrack/presentacion-modulosbase/modulos/SeleccionAplicacion/componentes/SeleccionAplicacion';

import {
    establecerMenus,
    establecerPermisos,
    establecerUsuarioInformacion,
    seleccionarAgencia,
    seleccionarUsuarioAplicacionAgencia,
} from 'Infotrack@Acciones/accionesAutenticacion';
import { seleccionarRutaActiva, seleccionarTema } from 'Infotrack@Acciones/accionesConfiguracionGlobal';
import { seguridad, seguridadToken } from 'Infotrack@Modelos/conexiones';
import ContenedorAplicacion from 'Infotrack@Principal/baseGeneral/componentes/contenedorAplicacion';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import temas, { ILlavesTemas } from 'Infotrack@Transversales/constantes/temas';
import fondoLogin from 'Infotrack@Transversales/recursos/img/fondoLogin.jpg';
import logoInicio from 'Infotrack@Transversales/recursos/img/ABI_Logo_FullColor-Black.svg';
import { modificarTituloIconoAplicacion, useQuery } from 'Infotrack@Transversales/utilitarios/funcionesGenerales';
import CambioContrasenia from '@infotrack/presentacion-modulosbase/modulos/CambioContrasenia/CambioContrasenia';
import Texto from '@infotrack/presentacion-componentes/texto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import { IFiltroEmpresaAplicacion } from 'Infotrack@Modelos/empresas/entidades/IFiltroEmpresaAplicacion';
import { TipoNotificacion } from '@infotrack/presentacion-transversales/interfacesComunes';
import EmpresaFiltroCM from 'Infotrack@Modulos/administracion/empresaold/controladorModelo/empresaFiltroCM';

interface IEstadoAPropsContenedorPrincipal {
    agenciaActual: IAgencia | null;
    rutaActiva: string;
    usuarioInformacion: IUsuarioInformacion | null;
}

interface IAccionesAPropsContenedorPrincipal {
    establecerMenusAccion: (menus: IMenu[]) => AnyAction;
    establecerPermisosAccion: (permisos: IAccion[]) => AnyAction;
    establecerUsuarioInformacionAccion: (usuarioInformacion: IUsuarioInformacion | null) => AnyAction;
    seleccionarAgenciaAccion: (payload: IAgencia) => AnyAction;
    seleccionarUsuarioAplicacionAgenciaAccion: (payload: IUsuarioAplicacionAgencia) => AnyAction;
    seleccionarRutaActivaAccion: (payload: string) => AnyAction;
    seleccionarTemaAccion: (payload: keyof ILlavesTemas) => AnyAction;
}

const empresaFiltroCM = new EmpresaFiltroCM();

const ContenedorPrincipal: React.FunctionComponent<IEstadoAPropsContenedorPrincipal &
    IAccionesAPropsContenedorPrincipal> = ({
    agenciaActual,
    establecerMenusAccion,
    establecerPermisosAccion,
    establecerUsuarioInformacionAccion,
    rutaActiva: NombreAplicacion,
    seleccionarAgenciaAccion,
    seleccionarUsuarioAplicacionAgenciaAccion,
    seleccionarRutaActivaAccion,
    seleccionarTemaAccion,
    usuarioInformacion,
}) => {
    const [bloqueado, setBloqueado] = React.useState(false);
    const [idEmpresa, setIdEmpresa] = React.useState('');
    const [idAplicacion, setIdAplicacion] = React.useState('');

    React.useEffect(() => {
        if (!usuarioInformacion) {
            consultarEmpresaAplicacionPorFiltro();
        }
    }, []);

    const url: string = window.location.hostname;
    const consultarEmpresaFiltro: IFiltroEmpresaAplicacion = {
        UrlAplicacion: url,
    };

    const consultarEmpresaAplicacionPorFiltro = async () => {
        const respuesta = await empresaFiltroCM.consultarEmpresaAplicacionPorFiltro(consultarEmpresaFiltro);
        if (respuesta && respuesta.Entidades && respuesta.Entidades.length > 0) {
            setIdEmpresa(respuesta.Entidades[0].IdEmpresa);
            setIdAplicacion(respuesta.Entidades[0].IdAplicacion);
        }
        if (respuesta.TipoNotificacion === TipoNotificacion.Advertencia) {
            setBloqueado(!bloqueado);
        }
    };

    let query = useQuery();
    const seleccionarAplicacionAgenciaCallback = (
        usuarioAplicacionAgencia: IUsuarioAplicacionAgencia,
        agencia: IAgencia,
        permisos: IAccion[],
        menus: IMenu[],
        idPerfil?: string,
        nombrePerfil?: string
    ) => {
        establecerMenusAccion(menus);
        establecerPermisosAccion(permisos);
        seleccionarRutaActivaAccion(usuarioAplicacionAgencia.NombreAplicacion);
        seleccionarTemaAccion(usuarioAplicacionAgencia.EstiloAplicacion as keyof ILlavesTemas);
        modificarTituloIconoAplicacion(usuarioAplicacionAgencia.NombreAplicacion);
        seleccionarUsuarioAplicacionAgenciaAccion(usuarioAplicacionAgencia);
        seleccionarAgenciaAccion(agencia);
        usuarioInformacion!.IdPerfil! = idPerfil!;
        usuarioInformacion!.NombrePerfil! = nombrePerfil!;
        establecerUsuarioInformacionAccion(usuarioInformacion);
    };

    const cancelarCambioContrasenia = () => {
        establecerUsuarioInformacionAccion(null);
    };

    if (!usuarioInformacion && location.pathname !== '/autenticacion') {
        return <Redirect to="/autenticacion" />;
    }

    if (
        usuarioInformacion !== null &&
        (usuarioInformacion!.TipoUsuario === 2 || usuarioInformacion!.TipoUsuario === 4)
    ) {
        manejadorDialogoGlobal({
            accionConfirmar: () => {
                sessionStorage.clear();
                window.location.reload();
            },
            estado: true,
            mensaje: <Texto id={'El usuario no tiene permitido el acceso al portal web.'} />,
            mostrarCancelar: false,
            tipoDialogo: 'Error',
            titulo: <Texto id="titulo.advertencia" />,
        });
    }

    if (
        usuarioInformacion &&
        (usuarioInformacion.Estado === 4 || usuarioInformacion.Estado === 6) &&
        (usuarioInformacion.TipoUsuario === 1 || usuarioInformacion.TipoUsuario === 3) &&
        location.pathname !== '/cambioContrasenia'
    ) {
        return <Redirect to="/cambioContrasenia" />;
    }
    if (
        usuarioInformacion &&
        usuarioInformacion.Estado !== 4 &&
        usuarioInformacion.Estado !== 6 &&
        !agenciaActual &&
        location.pathname !== '/seleccionAplicacion'
    ) {
        return <Redirect to="/seleccionAplicacion" />;
    }

    if (usuarioInformacion && agenciaActual && !location.pathname.startsWith(`/${NombreAplicacion}`)) {
        return <Redirect to={`/${NombreAplicacion}`} />;
    }

    return (
        <>
            <Route
                path="/autenticacion"
                component={() => (
                    <Autenticacion
                        autenticacionExitosaCallback={establecerUsuarioInformacionAccion}
                        fondoLogin={fondoLogin}
                        logoInicio={logoInicio}
                        seguridad={seguridad}
                        idEmpresa={idEmpresa}
                        idAplicacion={idAplicacion}
                        bloqueado={bloqueado}
                    />
                )}
            />

            <Route
                path="/cambioContrasenia"
                component={() => (
                    <CambioContrasenia
                        aceptaTerminosCondiciones={usuarioInformacion!.AceptaTerminos}
                        estado={usuarioInformacion!.Estado}
                        idUsuario={usuarioInformacion!.IdUsuario}
                        seguridad={seguridadToken}
                        cerrar={cancelarCambioContrasenia}
                    />
                )}
            />

            <Route
                path="/seleccionAplicacion"
                component={() => (
                    <SeleccionAplicacion
                        agenciaActual={agenciaActual}
                        IdEmpresa={usuarioInformacion!.IdEmpresa}
                        IdUsuario={usuarioInformacion!.IdUsuario}
                        seguridad={seguridadToken}
                        seleccionarAplicacionAgenciaCallback={seleccionarAplicacionAgenciaCallback}
                        Temas={temas}
                        IdAplicacion={idAplicacion}
                    />
                )}
            />

            {<Route path={`/${NombreAplicacion}`} render={() => <ContenedorAplicacion />} />}
        </>
    );
};

const estadoAProps = (estadoGlobal: IEstadoGlobal): IEstadoAPropsContenedorPrincipal => {
    return {
        agenciaActual: estadoGlobal.estadoAutenticacion.agenciaActual,
        usuarioInformacion: estadoGlobal.estadoAutenticacion.usuarioInformacion,
        rutaActiva: estadoGlobal.estadoConfiguracion.rutaActiva,
    };
};

const accionesAProps = (dispatch: Dispatch<AnyAction>): IAccionesAPropsContenedorPrincipal => {
    return {
        establecerMenusAccion: (menus: IMenu[]) => dispatch(establecerMenus(menus)),
        establecerPermisosAccion: (permisos: IAccion[]) => dispatch(establecerPermisos(permisos)),
        establecerUsuarioInformacionAccion: (informacionUsuario: IUsuarioInformacion | null) =>
            dispatch(establecerUsuarioInformacion(informacionUsuario)),
        seleccionarAgenciaAccion: (agencia: IAgencia) => dispatch(seleccionarAgencia(agencia)),
        seleccionarUsuarioAplicacionAgenciaAccion: (usuarioAplicacionAgencia: IUsuarioAplicacionAgencia) =>
            dispatch(seleccionarUsuarioAplicacionAgencia(usuarioAplicacionAgencia)),
        seleccionarRutaActivaAccion: (rutaActiva: string) => dispatch(seleccionarRutaActiva(rutaActiva)),
        seleccionarTemaAccion: (tema: keyof ILlavesTemas) => dispatch(seleccionarTema(tema)),
    };
};

export default connect(estadoAProps, accionesAProps)(ContenedorPrincipal);
