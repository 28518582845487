import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import useStyles from '../tablaDetallesPlantillaEstilos';

interface ICampoCantidadProps {
    manejarCambio: (nuevoValor: string) => any;
    valor: string | undefined;
}

const CampoCantidad: FunctionComponent<ICampoCantidadProps> = ({ manejarCambio, valor }) => {
    const classes = useStyles();

    return (
        <TextField
            classes={{ root: classes.campo }}
            fullWidth
            label={<Texto id="TablaDetallesPlantilla.CampoCantidad.Nombre" />}
            onChange={(event) => {
                if (/^\d*$/.test(event.target.value)) {
                    manejarCambio(event.target.value);
                }
            }}
            value={valor || ''}
        />
    );
};

export default CampoCantidad;
