import { createStyles } from '@material-ui/core';
import { paletaMaterialD } from '@infotrack/presentacion-transversales/constantes';

const estilos = createStyles({
    contenedor: {
        padding: '.5em',
    },
    icono: {
        color: paletaMaterialD.white,
    },
    itemLenguajes: {
        minWidth: '150px',
    },
    selectorLenguajes: {
        marginTop: '1.5em',
    },
});

export default estilos;
