import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    List,
    ListItem,
    ListSubheader,
    TextField,
    withStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Texto from '@infotrack/presentacion-componentes/texto';
import { paletaMaterialD } from '@infotrack/presentacion-transversales/constantes';
import { BotonBuscar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { IMonitorInventarioVistaProps } from '../interfaces';
import { RegistroConPorcentaje } from './constantes';
import { estilos } from './estilos';

const customLegend = (value: any) => <Texto display="inline" id={`monitorInventarios.${value}`} />;

const customTooltip = (
    value: any,
    name: any,
    formatMessage: (params: FormattedMessage.MessageDescriptor) => string
) => [`${value.toFixed(2)}%`, formatMessage({ id: `monitorInventarios.${name}` })];

const MonitorInventarioVista: React.FunctionComponent<PropsEstilosMaterialUI<
    IMonitorInventarioVistaProps & InjectedIntlProps
>> = ({
    alCambiarValor,
    alFiltrarRegistros,
    classes,
    dataStockMinimo,
    dataStockMasAntiguo,
    dataMayorRotacion,
    dataMenorRotacion,
    dataUtilizacionBodega,
    entidadesFiltros,
    intl: { formatMessage },
    filtros,
}) => (
    <Grid alignItems="flex-start" justifyContent="space-between" container spacing={2}>
        <Grid item md={4} xs={12}>
            <Texto id="sidebar.monitorinventario" fontWeight="bold" />
        </Grid>
        <Grid item md={8} xs={12}>
            <Grid alignItems="flex-start" container spacing={2} justifyContent="space-between">
                <Grid item md xs={12}>
                    <Autocomplete
                        disableClearable
                        getOptionLabel={({ BodegaDescripcion }) => BodegaDescripcion}
                        onChange={(_: any, bodega: any) => alCambiarValor(bodega, 'Bodega')}
                        options={entidadesFiltros.bodegas}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                label={formatMessage({ id: 'monitorInventarios.Bodegas' })}
                            />
                        )}
                        noOptionsText={<Texto id="label.sinRegistros" />}
                        value={filtros.Bodega!==null?filtros.Bodega:undefined}
                    />
                </Grid>
                <Grid item md xs={12}>
                    <Autocomplete
                        getOptionLabel={({ ClasificacionDescripcion }) => ClasificacionDescripcion}
                        onChange={(_: any, clasificacion: any) => alCambiarValor(clasificacion, 'Clasificacion')}
                        options={entidadesFiltros.clasificaciones}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                label={formatMessage({ id: 'monitorInventarios.Clasificaciones' })}
                            />
                        )}
                        noOptionsText={<Texto id="label.sinRegistros" />}
                        value={filtros.Clasificacion}
                    />
                </Grid>
                <Grid item md="auto" xs={12}>
                    <BotonBuscar onClick={alFiltrarRegistros} />
                </Grid>
            </Grid>
        </Grid>
        <Grid item md={4} xs={12}>
            <Card>
                <CardContent>
                    <List
                        className={classes.contenedorListas}
                        subheader={
                            <ListSubheader disableGutters>
                                <Texto id="monitorInventarios.top10MasRotacion" fontWeight="bold" />
                            </ListSubheader>
                        }
                    >
                        {dataMayorRotacion.length === 0 ? (
                            <ListItem disableGutters>
                                <Texto id="label.sinRegistros" />
                            </ListItem>
                        ) : (
                            dataMayorRotacion.map(({ Cantidad, CantidadComparativa, Descripcion, Id }) => (
                                <ListItem disableGutters key={Id}>
                                    <Grid container justifyContent="space-between">
                                        <Grid item xs="auto">
                                            <Texto mensaje={Descripcion} />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Texto mensaje={Cantidad} />
                                        </Grid>
                                        <RegistroConPorcentaje
                                            classes={classes}
                                            cantidadComparativa={CantidadComparativa!}
                                        />
                                    </Grid>
                                </ListItem>
                            ))
                        )}
                    </List>
                </CardContent>
            </Card>
        </Grid>
        <Grid item md={4} xs={12}>
            <Card>
                <CardContent>
                    <List
                        className={classes.contenedorListas}
                        subheader={
                            <ListSubheader disableGutters>
                                <Texto id="monitorInventarios.top10MenorRotacion" fontWeight="bold" />
                            </ListSubheader>
                        }
                    >
                        {dataMenorRotacion.length === 0 ? (
                            <ListItem disableGutters>
                                <Texto id="label.sinRegistros" />
                            </ListItem>
                        ) : (
                            dataMenorRotacion.map(({ Cantidad, CantidadComparativa, Descripcion, Id }) => (
                                <ListItem disableGutters key={Id}>
                                    <Grid container justifyContent="space-between">
                                        <Grid item xs="auto">
                                            <Texto mensaje={Descripcion} />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Texto mensaje={Cantidad} />
                                        </Grid>
                                        <RegistroConPorcentaje
                                            classes={classes}
                                            cantidadComparativa={CantidadComparativa!}
                                        />
                                    </Grid>
                                </ListItem>
                            ))
                        )}
                    </List>
                </CardContent>
            </Card>
        </Grid>
        <Grid item md={4} xs={12}>
            <Card>
                <CardContent>
                    <List
                        className={classes.contenedorListas}
                        subheader={
                            <ListSubheader>
                                <Texto id="monitorInventarios.alertaStockMinimo" fontWeight="bold" />
                            </ListSubheader>
                        }
                    >
                        {dataStockMinimo.length === 0 ? (
                            <ListItem>
                                <Texto id="label.sinRegistros" />
                            </ListItem>
                        ) : (
                            dataStockMinimo.map(({ Cantidad, CantidadComparativa, Descripcion, Id }) => (
                                <ListItem key={Id}>
                                    <Grid
                                        className={
                                            Cantidad > CantidadComparativa! ? classes.textoNaranja : classes.textoRojo
                                        }
                                        container
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs="auto">
                                            <Texto mensaje={Descripcion} />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Texto mensaje={CantidadComparativa} />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Texto mensaje={Cantidad} />
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))
                        )}
                    </List>
                </CardContent>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card>
                <CardContent>
                    <CardHeader subheader={<Texto id="monitorInventarios.utilizacionBodegas" fontWeight="bold" />} />
                    {dataUtilizacionBodega.length === 0 ? (
                        <Texto id="label.sinRegistros" />
                    ) : (
                        <ResponsiveContainer width="100%" height={240}>
                            <PieChart width={400} height={400}>
                                <Pie dataKey="Cantidad" nameKey="Descripcion" data={dataUtilizacionBodega}>
                                    {dataUtilizacionBodega.map((_, index) => (
                                        <Cell
                                            key={`indice#${index}`}
                                            fill={index === 0 ? paletaMaterialD.blue_500 : paletaMaterialD.orange_500}
                                        />
                                    ))}
                                </Pie>
                                <Legend formatter={customLegend} />
                                <Tooltip formatter={(value, name) => customTooltip(value, name, formatMessage)} />
                            </PieChart>
                        </ResponsiveContainer>
                    )}
                </CardContent>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card>
                <CardContent>
                    <List
                        className={classes.contenedorListas}
                        subheader={
                            <ListSubheader>
                                <Texto id="monitorInventarios.productoMasAntiguedad" fontWeight="bold" />
                            </ListSubheader>
                        }
                    >
                        {dataStockMasAntiguo.length === 0 ? (
                            <ListItem>
                                <Texto id="label.sinRegistros" />
                            </ListItem>
                        ) : (
                            dataStockMasAntiguo.map(({ Cantidad, Descripcion, Id }) => (
                                <ListItem key={Id}>
                                    <Grid container justifyContent="space-between">
                                        <Grid item xs="auto">
                                            <Texto mensaje={Descripcion} />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Texto mensaje={Cantidad} />
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))
                        )}
                    </List>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
);

export default withStyles(estilos)(injectIntl(MonitorInventarioVista));
