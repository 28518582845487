import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import React from 'react';
import DocumentoDetalleCM from '../controladorModelo/documentoDetalleCM';
import { acciones, IEstadoDevoluciones } from '../interfaces';
import TablaDetallesDevolucionesVista from '../vista/tablaDetallesDevolucionesVista';

const documentoDetalleCM = new DocumentoDetalleCM();

const TablaDetallesDevolucionesCV: React.FunctionComponent = () => {
    const [{ detalles, documento, tipoFormulario }, dispatch] = useProveedor<IEstadoDevoluciones>();

    React.useEffect(() => {
        if (documento) consultarDocumentosDetalles();
    }, [documento]);

    const alAbrirFormulario = (detalle: IDocumentoDetalleCompuesto) => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
        dispatch({ type: acciones.ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE, payload: detalle });
    };

    const consultarDocumentosDetalles = async () => {
        dispatch({
            type: acciones.CARGAR_DETALLES,
            payload: await documentoDetalleCM.consultaListaDocumentoDetallesPorFiltro({
                DocumentoId: documento!.DocumentoId!,
            }),
        });
    };

    return (
        <TablaDetallesDevolucionesVista
            alAbrirFormulario={alAbrirFormulario}
            detalles={detalles}
            tipoFormulario={tipoFormulario}
        />
    );
};

export default TablaDetallesDevolucionesCV;
