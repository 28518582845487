enum AbreviaturasTiposDocumentos {
  REC = 'REC',
  RE = 'RE',
  ALM = 'ALM',
  AUD = 'AUD',
  PED = 'PED',
  DES = 'DES',
  TRA = 'TRA',
  ENT = 'ENT',
  SAL = 'SAL',
  OC = 'OC',
}

export default AbreviaturasTiposDocumentos;
