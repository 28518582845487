const mensajes = {
    // SalidaDevolucion
    'SalidaDevolucion.ValidacionNoTipoDocumentoId': 'Debe seleccionar un tipo de documento',
    'SalidaDevolucion.ValidacionNoProveedorId': 'Debe seleccionar un proveedor',
    'SalidaDevolucion.ValidacionNoBodegaOrigen': 'Debe seleccionar una bodega de origen',
    // ModalSalidaDevolucion
    'ModalSalidaDevolucion.TituloCreacion': 'Crear Salida Devolución',
    'ModalSalidaDevolucion.TituloEdicion': 'Editar Salida Devolución',
    'ModalSalidaDevolucion.TituloVisualizacion': 'Ver Salida Devolución',
    'ModalSalidaDevolucion.TituloTablaDetalles': 'Detalles',
    'ModalSalidaDevolucion.TituloTablaDetallesDocumentoBase': 'Detalles Documento Base',
    'permiso.salidadevolucion': 'Acceso',
    // mensajes
    'MensajeSalidaDevolucion.confirmarAnularDocumento': '¿Está seguro que desea anular el documento?',
};

export default mensajes;
