import {
    AppBar,
    Button,
    Card,
    Grid,
    Grow,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Popover,
    Toolbar,
    withStyles,
} from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import Texto from '@infotrack/presentacion-componentes/texto';
import { ILLavesLenguaje } from 'Infotrack@Transversales/internacionalizacion';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import estilos from './estilos';

interface ISelectorIdiomaVistaProps {
    abrirSelector: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    classes: Record<string, string>;
    cerrarSelector: () => void;
    lenguajes: ILLavesLenguaje[];
    lenguajeActivo: string;
    refElem: HTMLElement | null;
    seleccionarLenguaje: (lenguaje: ILLavesLenguaje) => void;
}

const SelectorIdiomaVista: React.FunctionComponent<PropsEstilosMaterialUI<ISelectorIdiomaVistaProps>> = ({
    abrirSelector,
    cerrarSelector,
    classes,
    lenguajes,
    lenguajeActivo,
    seleccionarLenguaje,
    refElem,
}) => (
    <div>
        <Button variant="contained" onClick={abrirSelector}>
            <img src={require(`Infotrack@Transversales/recursos/iconos/${lenguajeActivo}.png`)} />
            <ArrowDownIcon />
        </Button>
        <Popover
            anchorEl={refElem}
            open={Boolean(refElem)}
            onClose={cerrarSelector}
            transformOrigin={{
                horizontal: 'center',
                vertical: 'top',
            }}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
            className={classes.selectorLenguajes}
        >
            <Grow in={Boolean(refElem)}>
                <Card>
                    <AppBar color="primary" position="static">
                        <Toolbar variant="dense">
                            <Texto id="barraLateral.label.lenguajes" />
                        </Toolbar>
                    </AppBar>
                    <List>
                        <Grid container spacing={4} className={classes.contenedor}>
                            {lenguajes.map((lenguaje, indice) => (
                                <Grid item xs={6} key={lenguaje + indice}>
                                    <ListItem
                                        button
                                        divider
                                        className={classes.itemLenguajes}
                                        selected={lenguaje === lenguajeActivo}
                                        onClick={() => seleccionarLenguaje(lenguaje)}
                                    >
                                        <ListItemText>
                                            <Texto id={`lenguajes.label.${lenguaje}`} />
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <img
                                                src={require(`Infotrack@Transversales/recursos/iconos/${lenguaje}.png`)}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </Grid>
                            ))}
                        </Grid>
                    </List>
                </Card>
            </Grow>
        </Popover>
    </div>
);

export default withStyles(estilos)(SelectorIdiomaVista);
