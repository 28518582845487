export default {
  'sidebar.administracion': 'Administración',
  'sidebar.tipocuentabancaria': 'Tipo Cuenta Bancaria',
  'tipoCuentaBancaria.agregarTipoCuentaBancaria': 'Crear Tipo Cuenta Bancaria',
  'tipoCuentaBancaria.confirmacionCreacion': '¿Está seguro que desea crear el registro?',
  'tipoCuentaBancaria.confirmacionEdicion': '¿Está seguro que desea editar el registro?',
  'tipoCuentaBancaria.confirmacionEliminacion': '¿Está seguro que desea eliminar el registro?',
  'tipoCuentaBancaria.modificarTipoCuentaBancaria': 'Editar Tipo Cuenta Bancaria',
  'tipoCuentaBancaria.codigoTipoCuentaBancaria': 'Código Tipo Cuenta Bancaria',
  'tipoCuentaBancaria.nombreTipoCuentaBancaria': 'Nombre Tipo Cuenta Bancaria',
  'tipoCuentaBancaria.estadoTipoCuentaBancaria': 'Estado',
};
