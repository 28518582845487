import React, { useState } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Toolbar,
    Tooltip,
    withStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import { Worker } from '@phuocng/react-pdf-viewer';
import { Viewer } from '@react-pdf-viewer/core';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Texto from '@infotrack/presentacion-componentes/texto';

import { BotonEliminar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';

import { IInputImagenDocumentoVistaProps } from '../interfaces';
import { estilos } from './estilos';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { EstadosFormularioDocumento } from 'Infotrack@Transversales/componentes/FormularioDocumento/enumeraciones';

const InputImagenDocumentoVista: React.FunctionComponent<PropsEstilosMaterialUI<
    IInputImagenDocumentoVistaProps & InjectedIntlProps
>> = ({
    alAbrirCerrarDialogo,
    alEliminarImagen,
    alPasarImagen,
    alRecibirImagen,
    classes,
    estado,
    idTooltip,
    imagenes,
    intl: { formatMessage },
    indiceGaleria,
    limiteImagenes,
    reiniciarGaleria,
    estadoFormulario,
    deshabilitarBoton,
}) => {
    const textoBoton =
        limiteImagenes === 1
            ? formatMessage({
                  id: 'label.inputImagen.limiteImagen',
              })
            : formatMessage({
                  id: 'label.inputImagen.limiteImagenes',
              });

    const [indice, setIndice] = useState(indiceGaleria);

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar } = toolbarPluginInstance;
    const Toolbarpdf = toolbarPluginInstance.Toolbar;

    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        ZoomInMenuItem: () => <></>,
        ZoomIn: () => <></>,
        ZoomOut: () => <></>,
        Zoom: () => <></>,
        Open: () => <></>,
        Search: () => <></>,
        GoToNextPage: () => <></>,
        GoToPreviousPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        CurrentPageInput: () => <></>,
    });

    return (
        <>
            <Grid alignItems="center" container justifyContent="center">
                <Grid item xs={10}>
                    <Button
                        className={classes.botones}
                        color="primary"
                        fullWidth
                        endIcon={<PublishIcon />}
                        onClick={alAbrirCerrarDialogo}
                        variant="contained"
                        disabled={deshabilitarBoton && estadoFormulario === EstadosFormularioDocumento.EDICION}
                    >
                        <Texto id="documentoEvidencias.BotonArchivo" />
                    </Button>
                </Grid>
            </Grid>
            <Dialog open={estado} fullWidth maxWidth="md">
                <AppBar position="static">
                    <Toolbar>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Texto align="center" mensaje="Visualización" />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Card style={{ height: '25%', width: '100%' }}>
                        <CardContent style={{ height: '25%', width: '100%' }}>
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid item xs="auto">
                                    <Tooltip
                                        disableFocusListener={!idTooltip}
                                        disableHoverListener={!idTooltip}
                                        disableTouchListener={!idTooltip}
                                        title={idTooltip ? formatMessage({ id: idTooltip }) : ''}
                                    >
                                        <Button
                                            color="primary"
                                            component="label"
                                            disabled={
                                                imagenes.length > 2 ||
                                                estadoFormulario === EstadosFormularioDocumento.VISUALIZACION
                                            }
                                            variant="contained"
                                        >
                                            <Texto className={classes.margenIcono} id="boton.agregar" />
                                            <AddIcon />
                                            <input
                                                accept="image/*"
                                                className={classes.inputFalso}
                                                onChange={(e) => {
                                                    alRecibirImagen(e);
                                                    e.target.value = '';
                                                }}
                                                type="file"
                                                multiple={false}
                                            />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs="auto">
                                    <BotonEliminar
                                        disabled={
                                            imagenes.length === 0 ||
                                            estadoFormulario === EstadosFormularioDocumento.VISUALIZACION
                                        }
                                        onClick={() => alEliminarImagen()}
                                    />
                                </Grid>
                                {imagenes && imagenes.length > 0 ? (
                                    <Grid item xs={12}>
                                        {!reiniciarGaleria && (
                                            <Card>
                                                <CardContent>
                                                    <Carousel
                                                        className={classes.slideShow}
                                                        showArrows={true}
                                                        showThumbs={false}
                                                        showStatus={false}
                                                        useKeyboardArrows
                                                        infiniteLoop
                                                        onChange={(i) => alPasarImagen(i)}
                                                    >
                                                        {imagenes.map(({ imagen, propiedadesImagen }) => {
                                                            if (propiedadesImagen.type === 'application/pdf') {
                                                                return (
                                                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                                                                        <div>
                                                                            <div>
                                                                                <Toolbarpdf>
                                                                                    {(props: ToolbarSlot) => {
                                                                                        const {
                                                                                            Download,

                                                                                            Print,
                                                                                        } = props;
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    style={{
                                                                                                        padding:
                                                                                                            '0px 2px',
                                                                                                    }}
                                                                                                >
                                                                                                    <Grid
                                                                                                        container
                                                                                                        justifyContent="center"
                                                                                                    >
                                                                                                        <Grid item>
                                                                                                            <Download />
                                                                                                        </Grid>
                                                                                                        <Grid item>
                                                                                                            <Print />
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    }}
                                                                                </Toolbarpdf>
                                                                            </div>
                                                                            <div
                                                                                id="pdfviewer"
                                                                                style={{
                                                                                    height: '40%',
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                <Viewer
                                                                                    fileUrl={imagen as string}
                                                                                    initialPage={1}
                                                                                    plugins={[toolbarPluginInstance]}
                                                                                    defaultScale={0.3}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Worker>
                                                                );
                                                            } else {
                                                                return (
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                imagen ? (imagen as string) : undefined
                                                                            }
                                                                            alt={propiedadesImagen.name}
                                                                        />
                                                                        <figcaption>
                                                                            {propiedadesImagen.name}
                                                                        </figcaption>
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                    </Carousel>
                                                </CardContent>
                                            </Card>
                                        )}
                                    </Grid>
                                ) : (
                                    <Grid item xs={12}>
                                        <Texto
                                            align="center"
                                            id="documentoEvidencias.productoSinImagenes"
                                            variant="h6"
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            <Button color="primary" onClick={alAbrirCerrarDialogo} variant="contained">
                                <Texto id="boton.cerrar" />
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withStyles(estilos)(injectIntl(InputImagenDocumentoVista));
