export default {
    'pedidos.NumeroDocumento': 'N° Document',
    'pedidos.TipoDocumento': 'Type of Document',
    'pedidos.FechaCreacionDocumento': 'Initial Date',
    'pedidos.FechaVencimientoDocumento': 'Final Date',
    'pedidos.PersonaCreacion': 'Associated people',
    'pedidos.Bodega': 'Warehouse',
    'pedidos.Cliente': 'Cliente',
    'pedidos.BodegaOrigenDescripcion': 'Origin warehouse',
    'pedidos.BodegaDestinoDescripcion': 'Destination warehouse',
    'pedidos.EstadoDocumento': 'Document state',
    'pedidos.documento.CodigoDocumento': 'Code',
    'pedidos.documento.DocumentoRelacionado': 'Related document',
    'pedidos.documento.CodigoDocumentoPadre': 'Parent documento',
    'pedidos.documento.DescripcionProveedor': 'Provider',
    'pedidos.documento.FechaEntrega': 'Creation date',
    'pedidos.documento.EtapaDescripcion': 'Stage',
    'pedidos.documento.Observacion': 'Observation',
    'pedidos.documento.DocumentoPadreId': 'Parent document',
    'pedidos.documento.DescripcionCliente': 'Client',
    'pedidos.documento.DocumentoPadre': '¿It is a parent document?',
    'gestionauditorias.modificarEtapa': 'Change stage',
    'pedidos.documentoDetalle.DescripcionItem': 'Item description',
    'pedidos.documentoDetalle.CodigoBarras': 'Barcode',
    'pedidos.documentoDetalle.CantidadSolicitada': 'Request amount',
    'pedidos.documentoDetalle.CantidadRecibida': 'Delivered amount',
    'pedidos.documentoDetalle.DivisionOrigen': 'Origin division',
    'pedidos.documento.titulo.modificarEstadoDocumento': 'Change order status',
    'pedidos.documento.editarDocumento': 'Edit document',
    'pedidos.documento.BodegaOrigen': 'Origin winery',
    'pedidos.documentoDetalle.DivisionDestino': 'Destination division',
    'pedidos.documento.datosDocumento': 'Document data',
    'pedidos.documentoDetalle.crearDetalleDocumento': 'Create detail',
    'pedidos.documentoDetalle.editarDetalleDocumento': 'Edit detail',
    'pedidos.documentoDetalle.documentoDetalles': 'Document details',
    'pedidos.alerta.fechaInvalida': 'The final date must be greater than the initial date',
    'pedidos.alerta.fechaFaltante': 'Enter both dates to perform the search',
    'pedidos.alerta.cantidadInsuficiente':
        'The warehouse/division does not contain the requested amount. Current amount:',
    'pedidos.alerta.faltaBodegaOrigen': 'Please select the required document fields',
    'pedidos.alerta.confirmarCambioBodega':
        'Are you sure you want to change the warehouse?, The added details are removed',
    'pedidos.alerta.detalleEnAlistamiento':
        'The product is already ready, if you wish to edit it, request that the modification of the enlisted quantity',
    'pedidos.alerta.cantidadMayor': 'The amount received cannot be more than requested',
    'pedidos.alerta.divisionesIguales': 'The division of origin cannot be equal to that of destination',

    // ModalAlistamiento
    'ModalAlistamiento.TituloVisualizacion': 'View document',
    'ModalAlistamiento.TituloGestionAlistamiento': 'Manage picking',
    'ModalAlistamiento.TituloTablaDetallesPedido': 'Order details',
    'ModalAlistamiento.TituloTablaDetallesAlistamiento': 'Picking details',
    'ModalAlistamiento.ConfirmacionFinalizarAlistamiento': 'Are you sure you want to confirm the picking?',
    // ModalAlistamientoAcciones
    'ModalAlistamientoAcciones.ConfirmarAlistamiento': 'Confirm picking',
    // TablaPedidos
    'Alistamiento.TablaPedidos.BotonGestionarAlistamiento': 'Manage picking',
    'Alistamiento.TablaPedidos.BotonAnular': 'Void',
    'Alistamiento.TablaPedidos.CodigoDocumentoPredecesor': 'Code',
    'Alistamiento.TablaPedidos.DocumentoRelacionadoPredecesor': 'Related document',
    'Alistamiento.TablaPedidos.CodigoDocumento': 'Picking code',
    'Alistamiento.TablaPedidos.FechaCreacion': 'Creation date',
    'Alistamiento.TablaPedidos.DescripcionCliente': 'Client',
    'Alistamiento.TablaPedidos.EtapaDescripcionDocumentoPredecesor': 'Stage',
    'Alistamiento.TablaPedidos.ObservacionDocumentoPredecesor': 'Observation',
};
