const mensajes = {
    // SalidaInventario
    'OrdenTraslado.ValidacionNoTipoDocumentoId': 'Debe seleccionar un tipo de documento',
    'OrdenTraslado.ValidacionNoBodegaOrigen': 'Debe seleccionar una bodega de origen',
    'OrdenTraslado.ValidacionNoClienteId': 'Debe seleccionar un cliente',
    'sidebar.ordentraslado':'Orden Traslado',
    // ModalSalidaInventario
    'ModalOrdenTraslado.TituloCreacion': 'Crear Orden Traslado',
    'ModalOrdenTraslado.TituloEdicion': 'Editar Orden Traslado',
    'ModalOrdenTraslado.TituloVisualizacion': 'Ver Orden Traslado',
    'ModalOrdenTraslado.TituloTablaDetalles': 'Detalles',
    'ModalOrdenTraslado.TituloTablaDetallesDocumentoBase': 'Detalles Documento Base',
    'permiso.ordentraslado': 'Acceso',
    // mensajes
    'MensajeOrdenTraslado.confirmarAnularDocumento': '¿Está seguro que desea anular el documento?',

};

export default mensajes;
