import React from 'react';
import FormularioDetalleAuditoriaCV from './formularioDetalleAuditoriaCV';
import TablaDetallesAuditoriasCV from './tablaDetallesAuditoriasCV';

const DetallesAuditoriaCV = () => (
  <>
    <TablaDetallesAuditoriasCV />
    <FormularioDetalleAuditoriaCV />
  </>
);

export default DetallesAuditoriaCV;
