export default {
  'permiso.almacenamiento': 'Acceso',
  'permiso.auditoriasinventario': 'Acceso',
  'permiso.banco': 'Acceso',
  'permiso.bodegastipospropiedades': 'Acceso',
  'permiso.clasestecnicas': 'Acceso',
  'permiso.clasificaciones': 'Acceso',
  'permiso.clientes': 'Acceso',
  'permiso.columnas': 'Acceso',
  'permiso.consultainventario': 'Acceso',
  'permiso.despachos': 'Acceso',
  'permiso.divisionestipospropiedades': 'Acceso',
  'permiso.estadosdetalles': 'Acceso',
  'permiso.etapas': 'Acceso',
  'permiso.fabricantes': 'Acceso',
  'permiso.familias': 'Acceso',
  'permiso.filas': 'Acceso',
  'permiso.flujos': 'Acceso',
  'permiso.flujosetapas': 'Acceso',
  'permiso.gestionauditorias': 'Acceso',
  'permiso.gestionbodegas': 'Acceso',
  'permiso.gestioncompras': 'Acceso',
  'permiso.gestionmovimientos': 'Acceso',
  'permiso.gestionpedidos': 'Acceso',
  'permiso.gestionproducto': 'Acceso',
  'permiso.grupoactividadeconomica': 'Acceso',
  'permiso.marcas': 'Acceso',
  'permiso.modulo': 'Acceso',
  'permiso.monitorpedidos': 'Acceso',
  'permiso.pais': 'Acceso',
  'permiso.perfil': 'Acceso',
  'permiso.pisos': 'Acceso',
  'permiso.procesopedidos': 'Acceso',
  'permiso.productos': 'Acceso',
  'permiso.proveedores': 'Acceso',
  'permiso.recepcion': 'Acceso',
  'permiso.referencias': 'Acceso',
  'permiso.resolucionesfacturaciones': 'Acceso',
  'permiso.tipocontribuyente': 'Acceso',
  'permiso.tipocuentabancaria': 'Acceso',
  'permiso.tipoidentificacion': 'Acceso',
  'permiso.tiporegimen': 'Acceso',
  'permiso.tiposbodegas': 'Acceso',
  'permiso.tiposclientes': 'Acceso',
  'permiso.tiposdivisiones': 'Acceso',
  'permiso.tiposdocumentos': 'Acceso',
  'permiso.tiposmovimientos': 'Acceso',
  'permiso.tiposociedad': 'Acceso',
  'permiso.tiposunidades': 'Acceso',
  'permiso.traslados': 'Acceso',
  'permiso.unidades': 'Acceso',
  'permiso.usuario': 'Acceso',
  'permiso.monitorinventario': 'Acceso',
  'permiso.devolucionproveedor': 'Acceso',
  'permiso.historicoinventario': 'Acceso',
  'permiso.reportegeneral': 'Acceso',
  'permiso.gestionoperador': 'Acceso',
};
