import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoAccion, TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import IProductoPrincipal from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/productoPrincipal';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import DocumentoDetalleCM from '../controladorModelo/documentoDetalleCM';
import { IFormularioEdicionDetallesAuditoriasCVProps, IInputsDocumentoDetalle } from '../interfaces';
import { acciones, IEstadoGestionAuditorias } from '../reductorGestionAuditorias';
import FormularioEdicionDetalleAuditoriaVista from '../vista/formularioEdicionDetallesAuditoriasVista';

const valorInicialFormulario: IInputsDocumentoDetalle = {
    CantidadRecibida: '',
    CantidadEntregada: '',
    DivisionDestino: '',
    DivisionOrigen: '',
    FechaVencimiento: null,
    ItemId: '',
    Lote: '',
    Serial: '',
};

const documentoDetalleCM = new DocumentoDetalleCM();

const FormularioEdicionDetallesAuditoriasCV: React.FunctionComponent<IFormularioEdicionDetallesAuditoriasCVProps> = ({
    IdEmpresa,
}) => {
    const [item, setItem] = React.useState<IProductoPrincipal>();
    const { setValor, reiniciar, valor } = useInputState(valorInicialFormulario);
    const [
        {
            divisionPorDefecto,
            divisiones,
            documento,
            estadoFormularioDetalles,
            entidadDocumentoDetalle,
            tipoFormularioDocumentoDetalle,
        },
        dispatch,
    ] = useProveedor<IEstadoGestionAuditorias>();

    const theme = useTheme();
    const esMovil = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        if (entidadDocumentoDetalle) {
            const {
                CantidadEntregada,
                CantidadRecibida,
                CodigoBarras,
                DescripcionItem,
                DivisionOrigen,
                FechaVencimiento,
                ItemId,
                Lote,
                Serial,
            } = entidadDocumentoDetalle;
            setValor({
                ...entidadDocumentoDetalle,
                CantidadEntregada:
                    CantidadEntregada !== undefined && CantidadEntregada !== null ? CantidadEntregada : '',
                CantidadRecibida: CantidadRecibida !== undefined && CantidadRecibida !== null ? CantidadRecibida : '',
                FechaVencimiento: FechaVencimiento ? FechaVencimiento : null,
                Lote: Lote ? Lote : '',
                Serial: Serial ? Serial : '',
                DivisionOrigen: DivisionOrigen ? DivisionOrigen : '',
            } as any);
            setItem({
                DescripcionItem: DescripcionItem ? DescripcionItem : ' DescripcionItem',
                CodigoBarras: CodigoBarras ? CodigoBarras : '',
                ItemId: Number(ItemId),
            } as any);
        }
    }, [entidadDocumentoDetalle]);

    const alCambiarValor = ({
        target,
    }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { checked, name, type, value } = target as EventTarget & HTMLInputElement;
        setValor({ ...valor, [name]: type === 'checkbox' ? checked : value });
    };

    const alSeleccionarItem = (itemSeleccionado?: IProductoPrincipal) => {
        setItem(itemSeleccionado);
    };

    const alCerrarReiniciarFormulario = (cerrar?: boolean) => {
        if (cerrar || tipoFormularioDocumentoDetalle === TipoFormulario.Edicion)
            dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
        dispatch({ type: acciones.ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE, payload: undefined });
        reiniciar();
        setItem(undefined);
    };

    const alGuardarDocumentoDetalle = () => {
        if (documento && documento.EtapaDescripcion === TiposEtapas.Creada) {
            notificacionGlobal('gestionauditorias.alerta.iniciarAuditoria', 6000, 'warning', true);
        } else {
            const nuevoDocumentoDetalle = mapearDetalle();
            if (
                documentoDetalleCM.validarDocumentoDetalle(
                    nuevoDocumentoDetalle,
                    Boolean(documento && documento.EtapaDescripcion === TiposEtapas.Conteo)
                )
            ) {
                manejadorDialogoGlobal({
                    accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                    accionConfirmar: () => alCrearEditarDocumentoDetalle(nuevoDocumentoDetalle),
                    estado: true,
                    mensaje: (
                        <Texto
                            id={
                                tipoFormularioDocumentoDetalle === TipoFormulario.Creacion
                                    ? 'alerta.confirmacionCreacion'
                                    : 'alerta.confirmacionEdicion'
                            }
                        />
                    ),
                    mostrarCancelar: true,
                    tipoDialogo: 'Advertencia',
                    titulo: <Texto id="titulo.advertencia" />,
                });
            }
        }
    };

    const alCrearEditarDocumentoDetalle = (documentoDetalle: IDocumentoDetalleCompuesto) => {
        manejadorDialogoGlobal({ estado: false });
        documentoDetalleCM
            .editarDocumentosDetallesAuditoria({
                Documento: documento!,
                DocumentosDetalles: [
                    {
                        ...documentoDetalle,
                        Accion:
                            tipoFormularioDocumentoDetalle === TipoFormulario.Creacion
                                ? TipoAccion.guardado
                                : TipoAccion.editado,
                    },
                ],
            })
            .then(() => {
                alCerrarReiniciarFormulario();
                dispatch({ type: acciones.RECARGAR_TABLA_DETALLES });
            });
    };

    const mapearDetalle = (): IDocumentoDetalleCompuesto => {
        const { CantidadEntregada, CantidadRecibida, DivisionOrigen, FechaVencimiento } = valor;
        const DescripcionDivisionOrigen = DivisionOrigen
            ? divisiones.find(({ DivisionId }) => DivisionId === Number(DivisionOrigen))!.DivisionDescripcion
            : '';
        const Division = divisionPorDefecto !== undefined ? divisionPorDefecto : DivisionOrigen;
        return {
            ...valor,
            CantidadEntregada: !isNaN(parseInt(CantidadEntregada, 10)) ? Number(CantidadEntregada) : undefined,
            CantidadRecibida: !isNaN(parseInt(CantidadRecibida, 10)) ? Number(CantidadRecibida) : undefined,
            CodigoBarras: item ? item.CodigoBarras : '',
            DescripcionDivisionOrigen,
            DescripcionItem: item ? item.DescripcionItem : '',
            DivisionOrigen: Division ? Number(Division) : undefined,
            EmpresaId: IdEmpresa,
            FechaVencimiento: FechaVencimiento ? FechaVencimiento : '',
            FEFO: item && item.FEFO,
            ItemId: item ? item.ItemId : '',
            LotesItems: item ? item.Lotes : undefined,
            SeriadoItems: item ? item.Seriado : undefined,
        } as any;
    };

    return (
        <FormularioEdicionDetalleAuditoriaVista
            alCambiarValor={alCambiarValor}
            alCerrarReiniciarFormulario={alCerrarReiniciarFormulario}
            alGuardarDocumentoDetalle={alGuardarDocumentoDetalle}
            alSeleccionarItem={alSeleccionarItem}
            divisiones={divisiones}
            divisionPorDefecto={divisionPorDefecto}
            documentoDetalle={valor}
            esUnicoConteo={documento ? documento.BodegaOrigen === null || documento.BodegaDestino === null : false}
            estadoFormulario={estadoFormularioDetalles}
            esMovil={esMovil}
            item={item}
            tipoFormularioDocumentoDetalle={tipoFormularioDocumentoDetalle}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion },
}: IEstadoGlobal): IFormularioEdicionDetallesAuditoriasCVProps => ({
    IdEmpresa: usuarioInformacion!.IdEmpresa,
});

export default connect<IFormularioEdicionDetallesAuditoriasCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(FormularioEdicionDetallesAuditoriasCV);
