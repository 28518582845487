export default {
  'sidebar.administracion': 'Administration',
  'sidebar.tipocontribuyente': 'Taxpayer Type',
  'tipoContribuyente.agregarTipoContribuyente': 'Create Taxpayer Type',
  'tipoContribuyente.confirmacionCreacion': 'Are you sure to create the registry?',
  'tipoContribuyente.confirmacionEdicion': 'You are sure you want to edit the registry?',
  'tipoContribuyente.confirmacionEliminacion': 'Are you sure you want to delete the registry?',
  'tipoContribuyente.modificarTipoContribuyente': 'Edit Taxpayer Type',
  'tipoContribuyente.eliminarTipoContribuyente': 'Delete Taxpayer Type',
  'tipoContribuyente.codigoTipoContribuyente': 'Code Taxpayer Type',
  'tipoContribuyente.nombreTipoContribuyente': 'Name Taxpayer Type',
  'tipoContribuyente.estadoTipoContribuyente': 'State',
  'tipoContribuyente.tipoContribuyente': 'Taxpayer Type',
};
