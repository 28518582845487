import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';

import IAuditoriaConteoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteoDetalle';
import ModeloAuditoriaConteoDetalle from 'Infotrack@Modelos/negocioRefactor/modelos/Auditoria/ModeloAuditoriaConteoDetalle';

class AuditoriaConteoDetalleCM {
    private modeloAuditoriaConteoDetalle: ModeloAuditoriaConteoDetalle;

    constructor() {
        this.modeloAuditoriaConteoDetalle = new ModeloAuditoriaConteoDetalle();
    }

    public async crearConteoDetalle(conteoDetalle: IAuditoriaConteoDetalle[]) {
        if (conteoDetalle.some((d) => !this.validarCantidad(d))) return false;
        const respuesta = await this.modeloAuditoriaConteoDetalle.crearConteoDetalle(conteoDetalle);
        return respuesta.data.Resultado;
    }

    public async consultarListaAuditoriaConteoDetalle(auditoriaConteoId: string) {
        const respuesta = await this.modeloAuditoriaConteoDetalle.consultarListaAuditoriaConteoDetalle(
            auditoriaConteoId
        );
        return respuesta.data.Entidades;
    }

    public async editarConteoDetalle(conteoDetalle: IAuditoriaConteoDetalle[]) {
        const respuesta = await this.modeloAuditoriaConteoDetalle.editarConteoDetalle(conteoDetalle);
        return respuesta.data.Resultado;
    }

    public async compararConteos(idPrimerConteo: string, idSegundoConteo: string) {
        const respuesta = await this.modeloAuditoriaConteoDetalle.compararConteos(idPrimerConteo, idSegundoConteo);
        return respuesta.data.Entidades;
    }

    public async compararConteoUnico(idconteo: string) {
        const respuesta = await this.modeloAuditoriaConteoDetalle.compararConteoUnico(idconteo);
        return respuesta.data.Entidades;
    }

    public async descargarComparacionConteo(idConteo: string) {
        const respuesta = await this.modeloAuditoriaConteoDetalle.descargarComparacionConteo(idConteo);
        return respuesta.data as string;
    }

    public async descargarComparacionConteos(idPrimerConteo: string, idSegundoConteo: string) {
        const respuesta = await this.modeloAuditoriaConteoDetalle.descargarComparacionConteos(
            idPrimerConteo,
            idSegundoConteo
        );
        return respuesta.data as string;
    }

    private validarCantidad(conteoDetalle: IAuditoriaConteoDetalle) {
        if (isNaN(conteoDetalle.Cantidad) || conteoDetalle.Cantidad <= 0) {
            notificacionGlobal('AuditoriaConteoDetalle.ValidacionCantidadIncorrecta', 3000, 'warning', true);
            return false;
        }
        return true;
    }
}

export default AuditoriaConteoDetalleCM;
