import ModeloBodegasDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones';

export default class EntidadesDocumentoDetalleCM {
  private modeloBodegasDivisiones: ModeloBodegasDivisiones;

  constructor() {
    this.modeloBodegasDivisiones = new ModeloBodegasDivisiones();
  }

  public consultarListaDivisiones(BodegaId: number, TipoDocumentoId: number) {
    return this.modeloBodegasDivisiones
      .consultarDivisionesDocumentosDef({ BodegaId, TipoDocumentoId })
      .then(({ data: { Entidades } }) => Entidades.filter((x) => x.Estado === 1));
  }
}
