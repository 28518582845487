import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloDocumentoDetalle from 'Infotrack@Modelos/smartStock/documentosDetalle';
import IDocumentoDetalle from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalle';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import ModeloGestionOperadores from 'Infotrack@Modelos/smartStock/gestionOperadores';
import IDocumentosUsuarios from 'Infotrack@Modelos/smartStock/gestionOperadores/entidades/documentosUsuarios';
import TipoMovimientoDescripcion from 'Infotrack@Modelos/smartStock/tiposMovimientos/entidades/tipoMovimientoDescripcion';

export default class ControladorModeloDocumento {
    private modeloDocumentoDetalle: ModeloDocumentoDetalle;
    private modeloGestionOperadores: ModeloGestionOperadores;
    constructor() {
        this.modeloDocumentoDetalle = new ModeloDocumentoDetalle();
        this.modeloGestionOperadores = new ModeloGestionOperadores();
    }

    public consultarListaDocumentosDetalles(DocumentoId: string, Estado?: number) {
        return this.modeloDocumentoDetalle
            .consultaListaDocumentoDetallesPorFiltro({ DocumentoId, Estado })
            .then(({ data: { Entidades } }) => Entidades);
    }

    public editarDocumentoDetalleDevolucionProveedor(documentoDetalle: IDocumentoDetalle) {
        return this.modeloDocumentoDetalle
            .editarDocumentoDetalleDevolucionProveedor(documentoDetalle)
            .then(({ data: { Entidades } }) => Entidades);
    }

    public guardarDocumentosUsuarios(documentoDetalle: IDocumentosUsuarios) {
        return this.modeloGestionOperadores
            .guardarDocumentosUsuarios(documentoDetalle)
            .then(({ data: { Entidades } }) => Entidades);
    }

    public guardarDocumentoDetalleDevolucionProveedor(documentoDetalle: IDocumentoDetalle) {
        return this.modeloDocumentoDetalle
            .guardarDocumentoDetalleDevolucionProveedor(documentoDetalle)
            .then(({ data: { Entidades } }) => Entidades);
    }

    public editarDocumentosUsuarios(documentoDetalle: IDocumentosUsuarios) {
        return this.modeloGestionOperadores
            .editarDocumentosUsuarios(documentoDetalle)
            .then(({ data: { Entidades } }) => Entidades);
    }

    public eliminarDocumentosUsuarios(documentoDetalle: IDocumentosUsuarios) {
        return this.modeloGestionOperadores
            .eliminarDocumentosUsuarios(documentoDetalle)
            .then(({ data: { Resultado } }) => Resultado);
    }

    public editarListaDocumentosUsuarios(documentoDetalle: IDocumentosUsuarios) {
        return this.modeloGestionOperadores
            .editarListaDocumentosUsuarios(documentoDetalle)
            .then(({ data: { Resultado } }) => Resultado);
    }

    public validarDocumentoDetalle(
        documentoDetalle: IDocumentoDetalleCompuesto,
        tipoDocumento: TipoMovimientoDescripcion
    ) {
        const {
            CantidadSolicitada,
            DivisionDestino,
            DivisionOrigen,
            FechaVencimiento,
            FEFO,
            ItemId,
            SeriadoItems,
            Serial,
            LotesItems,
            Lote,
        } = documentoDetalle;
        if (
            !ItemId ||
            !CantidadSolicitada ||
            (tipoDocumento === 'Entrada' && !DivisionDestino) ||
            (tipoDocumento === 'Salida' && !DivisionOrigen) ||
            (tipoDocumento !== 'Entrada' && tipoDocumento !== 'Salida' && (!DivisionDestino || !DivisionOrigen)) ||
            (LotesItems && !Lote) ||
            (SeriadoItems && !Serial) ||
            (FEFO && !FechaVencimiento)
        ) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else return true;
    }

    public AlmacenamientoDetallesMasivo(documentoDetalle: IDocumentoDetalle) {
        return this.modeloDocumentoDetalle
            .AlmacenamientoDetallesMasivo(documentoDetalle)
            .then(({ data: { Entidades } }) => Entidades);
    }
}
