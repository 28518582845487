import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import {
    manejadorDialogoGlobal,
    manejadorIndicadorCargaGlobal,
} from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IDocumentoCompuesto from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuesto';
import IDocumentoConDetalle from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoConDetalle';
import IProveedores from 'Infotrack@Modelos/smartStock/proveedores/entidades/proveedores';
import ITipoDocumentoCompuesto from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/tipoDocumentoCompuesto';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import DocumentoCM from '../controladorModelo/documentoCM';
import EntidadesDocumentoCM from '../controladorModelo/entidadesDocumentoCM';
import { IFormularioDocumentosCVProps, IInputsDocumento } from '../interfaces';
import { acciones, IEstadoGestionMovimientos } from '../reductorGestionMovimientos';
import FormularioDocumentosVista from '../vista/formularioDocumentosVista';
import usuario from 'Infotrack@Transversales/internacionalizacion/idiomas/en_US/usuario';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';

const documentoCM = new DocumentoCM();
const entidadesDocumentoCM = new EntidadesDocumentoCM();

const FormularioDocumentosCV: React.FunctionComponent<IFormularioDocumentosCVProps> = ({
    AgenciaId,
    EmpresaId,
    UsuarioId,
}) => {
    const [bodegas, setBodegas] = React.useState<IBodega[]>([]);
    const [documentosPadre, setDocumentosPadre] = React.useState<IDocumentoCompuesto[]>([]);
    const [tiposDocumentos, setTiposDocumentos] = React.useState<ITipoDocumentoCompuesto[]>([]);
    const [proveedores, setProveedores] = React.useState<IProveedores[]>([]);
    const [
        { documento, documentosDetalles, entidadDocumento, estadoFormulario, tipoFormularioDocumento },
        dispatch,
    ] = useProveedor<IEstadoGestionMovimientos>();

    const tipoDocumento = documento.TipoDocumento ? documento.TipoDocumento.TipoDocumentoAbreviatura : undefined;

    React.useEffect(() => {
        cargarEntidadesDocumento();
    }, []);

    React.useEffect(() => {
        if (entidadDocumento) {
            const {
                BodegaDestino,
                BodegaOrigen,
                BodegaOrigenDescripcion,
                BodegaDestinoDescripcion,
                DocumentoId,
                DocumentoPadreId,
                DocumentoRelacionado,
                TipoDocumentoId,
                TipoDocumentoAbreviatura,
                TipoDocumentoDescripcion,
                Observacion,
                ProveedorId,
            } = entidadDocumento;
            const proveedor = proveedores.find(({ ProveedorId: Id }) => Id === ProveedorId);
            dispatch({
                type: acciones.MODIFICAR_DOCUMENTO,
                payload: {
                    ...entidadDocumento,
                    BodegaDestino: BodegaDestino
                        ? { BodegaId: BodegaDestino, BodegaDescripcion: BodegaDestinoDescripcion }
                        : '',
                    BodegaOrigen: BodegaOrigen
                        ? { BodegaId: BodegaOrigen, BodegaDescripcion: BodegaOrigenDescripcion }
                        : '',
                    DocumentoId: DocumentoId ? DocumentoId : '',
                    DocumentoPadreId: DocumentoPadreId ? DocumentoPadreId : '',
                    DocumentoRelacionado: DocumentoRelacionado ? DocumentoRelacionado : '',
                    TipoDocumento: { TipoDocumentoAbreviatura, TipoDocumentoDescripcion, TipoDocumentoId },
                    Observacion: Observacion ? Observacion : '',
                    Proveedor: proveedor ? proveedor : '',
                    ProveedorId: ProveedorId ? ProveedorId : '',
                },
            });
        }
    }, [entidadDocumento]);

    const alCerrarFormulario = () => {
        if (
            documentosDetalles.length > 0 &&
            documentosDetalles.every((finalizacion) => finalizacion.FinalizacionProceso === false)
        ) {
            notificacionGlobal('gestionoperadores.alerta.ResponsableFinalizacion', 6000, 'warning', true);
            dispatch({ type: acciones.RECARGAR_TABLA });
        } else {
            dispatch({ type: acciones.RECARGAR_TABLA });
            dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
        }
    };

    const alCambiarValor = (
        evento: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        omitirValidacionReinicio?: boolean
    ) => {
        const { checked, name, type, value } = evento.target as EventTarget & HTMLInputElement;
        if (name === 'DocumentoPadre') {
            if (!documento.DocumentoPadre) {
                dispatch({
                    type: acciones.MODIFICAR_DOCUMENTO,
                    payload: { ...documento, ['DocumentoPadreId']: '', [name]: !documento.DocumentoPadre },
                });
                return;
            }
        }
        dispatch({
            type: acciones.MODIFICAR_DOCUMENTO,
            payload: { ...documento, [name]: type === 'checkbox' ? checked : value },
        });
    };

    const confirmarReinicioDocumentoDetalles = (
        nombreValor: string,
        nuevoValor: IInputsDocumento[keyof IInputsDocumento]
    ) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => {
                alCambiarValorAutocomplete(nombreValor, nuevoValor, true);
                manejadorDialogoGlobal({ estado: false });
                dispatch({ type: acciones.REINICIAR_DOCUMENTO_DETALLES });
            },
            estado: true,
            mensaje: <Texto id="gestionmovimientos.alerta.confirmacionReinicioDocumentoDetalle" />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const cargarEntidadesDocumento = () => {
        const filtros = { AgenciaId, EmpresaId, UsuarioId,Estadoo:estadoActivo };
        entidadesDocumentoCM.consultarListaEntidadesDocumento(filtros).then(
            ({
                bodegas: bodegasCargadas,
                //tiposDocumentos: tiposDocumentosCargados,
                proveedores: proveedoresCargados,
            }) => {
                setBodegas(bodegasCargadas);
                //setTiposDocumentos(tiposDocumentosCargados);
                setProveedores(proveedoresCargados);
            }
        );
    };

    const cargarDocumentosPadre = async (TipoDocumentoId: number) => {
        setDocumentosPadre(await entidadesDocumentoCM.consultarListaDocumentosPadre({ TipoDocumentoId }));
    };

    const mapearDatos = (): IDocumentoConDetalle => {
        const {
            BodegaDestino,
            BodegaOrigen,
            DocumentoPadre,
            DocumentoPadreId,
            DocumentoRelacionado,
            Observacion,
            Proveedor,
            TipoDocumento,
        } = documento;

        return {
            documento: {
                ...documento,
                BodegaDestino: BodegaDestino ? Number(BodegaDestino.BodegaId) : undefined,
                BodegaOrigen: BodegaOrigen ? Number(BodegaOrigen.BodegaId) : undefined,
                DocumentoPadre,
                DocumentoPadreId: DocumentoPadreId ? DocumentoPadreId : undefined,
                DocumentoRelacionado,
                EmpresaId,
                Observacion,
                ProveedorId: Proveedor ? Number(Proveedor.ProveedorId) : undefined,
                TipoDocumentoId: TipoDocumento ? Number(TipoDocumento.TipoDocumentoId) : 0,
            },
            documentosDetalles: [],
        };
    };

    const confimarCreacionDocumento = () => {
        const documentoConDetalle = mapearDatos();
        if (documentoCM.validarDocumento(documentoConDetalle))
            manejadorDialogoGlobal({
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                accionConfirmar: () => alGuardarDocumento(documentoConDetalle),
                estado: true,
                mensaje: <Texto id="alerta.confirmacionCreacion" />,
                mostrarCancelar: true,
                tipoDialogo: 'Advertencia',
                titulo: <Texto id="titulo.advertencia" />,
            });
    };

    const alGuardarDocumento = (documentoConDetalle: IDocumentoConDetalle) => {
        manejadorDialogoGlobal({ estado: false });
        documentoCM.guardarDocumentoDevolucionProveedor(documentoConDetalle).then(() => {
            alCerrarFormulario();
            dispatch({ type: acciones.RECARGAR_TABLA });
        });
    };

    const alEditarDocumento = () => {
        const documentoConDetalle = mapearDatos();
        if (documentoCM.validarDocumento(documentoConDetalle))
            manejadorDialogoGlobal({
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                accionConfirmar: () => {
                    documentoCM.editarDevolucionProveedor(documentoConDetalle).then(() => {
                        manejadorDialogoGlobal({ estado: false });
                        alCerrarFormulario();
                        dispatch({ type: acciones.RECARGAR_TABLA });
                    });
                },
                estado: true,
                mensaje: <Texto id="alerta.confirmacionEdicion" />,
                mostrarCancelar: true,
                tipoDialogo: 'Advertencia',
                titulo: <Texto id="titulo.advertencia" />,
            });
    };

    const confirmarCambioEtapa = () => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: alCambiarEtapa,
            estado: true,
            mensaje: <Texto id="alerta.confirmacionEdicion" />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const alCambiarEtapa = async () => {
        const documentoAModificar = mapearDatos().documento;
        const peticionAEjecutar = documentoCM.cambiarEtapaDevolucionProveedor;
        manejadorDialogoGlobal({ estado: false });
        await peticionAEjecutar(documentoAModificar);
        const nuevoDocumento = await documentoCM.consultarGenerarDocumentos({
            DocumentoId: documentoAModificar.DocumentoId,
        });
        dispatch({
            type: acciones.MODIFICAR_DOCUMENTO,
            payload: { ...documento, EtapaDescripcion: nuevoDocumento.EtapaDescripcion },
        });
        dispatch({ type: acciones.RECARGAR_TABLA });
    };

    const alCambiarValorAutocomplete = (
        nombreValor: string,
        nuevoValor: IInputsDocumento[keyof IInputsDocumento],
        omitirValidacionReinicio?: boolean
    ) => {
        if (
            (nombreValor === 'BodegaOrigen' ||
                nombreValor === 'BodegaDestino' ||
                nombreValor === 'TipoDocumento' ||
                nombreValor === 'Proveedor') &&
            documentosDetalles.length > 0 &&
            omitirValidacionReinicio !== true
        ) {
            confirmarReinicioDocumentoDetalles(nombreValor, nuevoValor);
            return;
        }
        if (nombreValor === 'TipoDocumento') {
            const nuevoTipoDocumento = nuevoValor as ITipoDocumentoCompuesto;
            cargarDocumentosPadre(Number(nuevoTipoDocumento.TipoDocumentoId));
            if (tipoDocumento) {
                dispatch({
                    type: acciones.ESTABLECER_TIPO_MOVIMIENTO,
                    payload: nuevoTipoDocumento.TipoMovimientoDescripcion,
                });
                dispatch({
                    type: acciones.MODIFICAR_DOCUMENTO,
                    payload: {
                        ...documento,
                        ['BodegaOrigen']: null,
                        ['BodegaDestino']: null,
                        ['Proveedor']: null,
                        [nombreValor]: nuevoValor,
                    },
                });
                return;
            }
        } else if (nombreValor === 'BodegaOrigen') {
            if (documento) {
                dispatch({
                    type: acciones.MODIFICAR_DOCUMENTO,
                    payload: { ...documento, BodegaOrigen: nuevoValor, BodegaDestino: nuevoValor },
                });
                return;
            }
        } else if (nombreValor === 'Proveedor') {
            if (documento) {
                dispatch({
                    type: acciones.MODIFICAR_DOCUMENTO,
                    payload: { ...documento, Proveedor: nuevoValor },
                });
                return;
            }
        }
        dispatch({
            type: acciones.MODIFICAR_DOCUMENTO,
            payload: {
                ...documento,
                [nombreValor]: nuevoValor,
            },
        });
    };

    return (
        <FormularioDocumentosVista
            alCambiarEtapa={confirmarCambioEtapa}
            alCambiarValor={alCambiarValor}
            alCambiarValorAutocomplete={alCambiarValorAutocomplete}
            alCerrarFormulario={alCerrarFormulario}
            alGuardarDocumento={confimarCreacionDocumento}
            alEditarDocumento={alEditarDocumento}
            documento={documento}
            entidadesDocumento={{ bodegas, documentosPadre, tiposDocumentos, proveedores }}
            estadoFormulario={estadoFormulario}
            tipoFormularioDocumento={tipoFormularioDocumento}
            tipoDocumento={tipoDocumento}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion, agenciaActual },
}: IEstadoGlobal): IFormularioDocumentosCVProps => ({
    AgenciaId: agenciaActual!.IdAgencia,
    EmpresaId: usuarioInformacion!.IdEmpresa,
    UsuarioId: usuarioInformacion!.IdUsuario,
});

export default connect<IFormularioDocumentosCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(FormularioDocumentosCV);
