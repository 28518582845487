import { createMuiTheme, Theme } from '@material-ui/core';
import { coloresBase } from '@infotrack/presentacion-transversales/constantes';
import  createTheme   from '@material-ui/core/styles/';
import { estilosMaterialUI } from '../constantesConfig';

const smartStock = createMuiTheme({
    palette: {
        ...estilosMaterialUI.palette,
        primary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: '#505d63',
        },
        secondary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: '#505d63',
        },
    },
    props: {
        ...estilosMaterialUI.props,
        MuiTextField: {
            ...estilosMaterialUI.props!.MuiTextField,
            variant: 'outlined',
        },
        MuiCheckbox: {
            color: 'primary',
        },
    },
    overrides: {
        ...estilosMaterialUI.overrides,
        MuiAppBar: {
            colorPrimary: { backgroundColor: "#e5b611" },
        },
        MuiButton: {
            root: {
                textTransform: 'capitalize',
            },
        },
        MuiToolbar: {
            root: {
                '@media (max-width: 600px)': {
                    overflowX: 'auto',
                },
            },
        },
        MuiFormControl: {
            root: {
                '@media (max-width: 600px)': {
                    minWidth: '12em !important',
                },
            },
        },
        MuiListItemIcon: {
            root: { minWidth: 34 },
        },
    },
    typography: {
        button: {
            textTransform: 'capitalize',
        },
    },
});

const smartRoute = createMuiTheme({
    ...estilosMaterialUI,
    palette: {
        ...estilosMaterialUI.palette,
        primary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartRoute,
        },
        secondary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartRouteSecundario,
        },
    },
});

const smartWork = createMuiTheme({
    ...estilosMaterialUI,
    palette: {
        ...estilosMaterialUI.palette,
        primary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartWork,
        },
        secondary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartWorkSecundario,
        },
    },
});

const smartTruck = createMuiTheme({
    ...estilosMaterialUI,
    palette: {
        ...estilosMaterialUI.palette,
        primary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartTruck,
        },
        secondary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartTruckSecundario,
        },
    },
});

const smartIot = createMuiTheme({
    ...estilosMaterialUI,
    palette: {
        ...estilosMaterialUI.palette,
        primary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartIot,
        },
        secondary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartIotSecundario,
        },
    },
});

const smartSuite = createMuiTheme({
    ...estilosMaterialUI,
    palette: {
        ...estilosMaterialUI.palette,
        primary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartSuitePrimario,
        },
        secondary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartSuiteSecundario,
        },
    },
});

const temas = {
    SmartIot: smartIot,
    SmartRoute: smartRoute,
    SmartStock: smartStock,
    SmartSuite: smartSuite,
    SmartTruck: smartTruck,
    SmartWork12: smartWork,
};

export interface ILlavesTemas {
    SmartIot: Theme;
    SmartRoute: Theme;
    SmartStock: Theme;
    SmartSuite: Theme;
    SmartTruck: Theme;
    SmartWork12: Theme;
}

export default temas;
