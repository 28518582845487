import { addLocaleData } from 'react-intl';
import enLeng from './configuracionIdioma/en_US';
import esLeng from './configuracionIdioma/es_ES';

const AppLocale = {
  en: enLeng,
  es: esLeng
};

addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.es.data);

export type ILLavesLenguaje = 'es' | 'en';

export const lenguajesDisponibles: ILLavesLenguaje[] = ['es', 'en'];

export default AppLocale;
