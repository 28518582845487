import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocio } from 'Infotrack@Modelos/conexiones';
import IItemInventario from 'Infotrack@Modelos/negocioRefactor/entidades/negocio/IItemInventario';
import IMovimientoSerialItemDivision from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoSerialItemDivision';
import IMovimientoItemPesoConsulta from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoItemPesoConsulta';
import IMovimientoItemBodegaConsulta from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoItemBodegaConsulta';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';

class ModeloMovimiento {
    private NOMBRE_CONTROLADOR = 'Movimiento';

    public async stockItem(idBodega: number, idItem: number) {
        return manejadorRest<IRespuesta<IItemInventario>>(
            () => negocio.get(`${this.NOMBRE_CONTROLADOR}/StockItem/${idBodega}/${idItem}`),
            true
        );
    }

    public async stockItemDisponible(idBodega: number, idItem: number) {
        return manejadorRest<IRespuesta<IItemInventario>>(
            () => negocio.get(`${this.NOMBRE_CONTROLADOR}/StockItemDisponible/${idBodega}/${idItem}`),
            true
        );
    }
  

    public consultarMovimientosItemSerialPeso(movimientoConsulta: IMovimientoItemPesoConsulta) {
        return manejadorRest<IRespuesta<IMovimientoSerialItemDivision>>(
            () => negocio.post(`Movimiento/ConsultarListaSerialesItemDivisionPeso`, movimientoConsulta),
            true
        );
    }

    public consultarMovimientosItemBodega(movimientoConsulta: Partial<IMovimientoItemBodegaConsulta>) {
        return manejadorRest<IRespuesta<IDocumentoDetalleConsultaGeneral>>(
            () => negocio.post(`Movimiento/StockItemsLista`, movimientoConsulta),
            true
        );
    }
    public consultarMovimientosItemSerial(idDivision:number,itemid: number,idEmpresa: string) {     
        return manejadorRest<IRespuesta<IMovimientoSerialItemDivision>>(
         () => negocio.get(`Movimiento/ConsultarListaSerialesItemDivision/${idDivision}/${itemid}/${idEmpresa}`),
         true
     );
   
 }
}

export default ModeloMovimiento;
