import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import AbreviaturasTiposDocumentos from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/abreviaturasTiposDocumento';
import { IRefSelectMasivo } from 'Infotrack@Transversales/componentes/selectMasivoItems';
import React from 'react';
import DocumentoCM from '../controladorModelo/documentoCM';
import DocumentoDetalleCM from '../controladorModelo/documentoDetalleCM';
import { acciones, IEstadoGestionMovimientos } from '../reductorGestionMovimientos';
import TablaDocumentoDetallesVista from '../vista/tablaDocumentoDetallesVista';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';

const documentoCM = new DocumentoCM();
const documentoDetalleCM = new DocumentoDetalleCM();

const TablaDocumentoDetallesCV: React.FunctionComponent = () => {
    const [
        { documento, documentosDetalles, estadoFormularioDetalle, entidadDocumento, tipoFormularioDocumento },
        dispatch,
    ] = useProveedor<IEstadoGestionMovimientos>();
    const ref = React.useRef<IRefSelectMasivo>();

    React.useEffect(() => {
        if (entidadDocumento) cargarDetalles();
    }, []);

    React.useEffect(() => {
        if (documento.TipoDocumento && ref.current) ref.current.reiniciarEstado();
    }, [documento.TipoDocumento]);

    const alAbrirFormulario = (documentoDetalle?: IDocumentoDetalleCompuesto) => {
        if (documento.DocumentoPadre) {
            notificacionGlobal('gestionmovimientos.alerta.documentoPadreNoDetalle', 6000, 'warning', true);

            return;
        }

        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
        dispatch({ type: acciones.ESTABLECER_ENTIDAD_DOCUMENTO_DETALLE, payload: documentoDetalle });
    };

    const cargarDetalles = async () => {
        dispatch({
            type: acciones.CARGAR_DOCUMENTOS_DETALLES,
            payload: await documentoDetalleCM.consultarListaDocumentosDetalles(entidadDocumento!.DocumentoId!, 1),
        });
    };

    const alEliminarDocumentoDetalle = (documentoDetalle: IDocumentoDetalleCompuesto) => {
        if (documentosDetalles.length === 1 && tipoFormularioDocumento !== TipoFormulario.Creacion) {
            notificacionGlobal('devolucion.alerta.agregarDetalles', 6000, 'warning', true);
        } else {
            manejadorDialogoGlobal({
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                accionConfirmar: () => {
                    if (tipoFormularioDocumento !== TipoFormulario.Creacion) {
                        documentoDetalle.CantidadSolicitada = 0;
                        documentoDetalle.CantidadEntregada = 0;
                        documentoDetalle.Estado = 0;
                        documentoDetalleCM.editarDocumentoDetalleDevolucionProveedor(documentoDetalle);
                    }
                    dispatch({ type: acciones.ELIMINAR_DOCUMENTO_DETALLE, payload: documentoDetalle });
                    if (ref.current)
                        ref.current.eliminarItem({
                            divisionId: documentoDetalle.DivisionOrigen!,
                            itemId: documentoDetalle.ItemId!,
                        });
                    manejadorDialogoGlobal({ estado: false });
                },
                estado: true,
                mensaje: <Texto id="alerta.confirmacionEliminacion" />,
                mostrarCancelar: true,
                tipoDialogo: 'Advertencia',
                titulo: <Texto id="titulo.advertencia" />,
            });
        }
    };

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
    };

    const alModificarDetalles = (nuevosDocumentosDetalles: any[]) => {
        let errorDestino = false;
        nuevosDocumentosDetalles.forEach((element) => {
            if (!element.DivisionDestinoId) {
                errorDestino = true;
            }
        });
        if (errorDestino) {
            notificacionGlobal('gestionmovimientos.alerta.destino', 6000, 'warning', true);
        } else {
            alCerrarFormulario();
            if (
                documento.TipoDocumento &&
                documento.TipoDocumento.TipoDocumentoAbreviatura === AbreviaturasTiposDocumentos.SAL
            )
                dispatch({
                    type: acciones.CARGAR_DOCUMENTOS_DETALLES,
                    payload: nuevosDocumentosDetalles.map((docDetalle) => ({
                        ...docDetalle,
                        DivisionDestino: docDetalle.DivisionOrigen,
                        DescripcionDivisionDestino: docDetalle.DescripcionDivisionOrigen,
                    })),
                });
            else dispatch({ type: acciones.CARGAR_DOCUMENTOS_DETALLES, payload: nuevosDocumentosDetalles });
        }
    };

    return (
        <TablaDocumentoDetallesVista
            alAbrirFormulario={alAbrirFormulario}
            alEliminarDocumentoDetalle={alEliminarDocumentoDetalle}
            deshabilitarEdicion={
                documento.DocumentoPadre ||
                tipoFormularioDocumento === TipoFormulario.Visualizacion ||
                documento.EtapaDescripcion === TiposEtapas.Cerrada ||
                documento.EtapaDescripcion === TiposEtapas.Almacenado ||
                documento.EtapaDescripcion === TiposEtapas.Revertido ||
                documento.EtapaDescripcion === TiposEtapas.EnDevolucion ||
                documento.EtapaDescripcion === TiposEtapas.Anulado
            }
            documentosDetalles={documentosDetalles}
        />
    );
};

export default TablaDocumentoDetallesCV;
