import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloDocumentoDetalle from 'Infotrack@Modelos/smartStock/documentosDetalle';
import IDocumentoConDetallesAccion from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoConDetallesAccion';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';

export default class ControladorModeloDocumento {
    private modeloDocumentoDetalle: ModeloDocumentoDetalle;
    constructor() {
        this.modeloDocumentoDetalle = new ModeloDocumentoDetalle();
    }

    public consultarListaDocumentosDetalles(DocumentoId: string) {
        return this.modeloDocumentoDetalle
            .consultaListaDocumentoDetallesPorFiltro({ DocumentoId })
            .then(({ data: { Entidades } }) =>
                Entidades.map((entidad, indice) => ({ ...entidad, IdTemporal: indice + 1 }))
            );
    }

    public validarDocumentoDetalle(documentoDetalle: IDocumentoDetalleCompuesto) {
        const { CantidadSolicitada, CantidadEntregada, CantidadRecibida, ItemId } = documentoDetalle;
        if (!ItemId || (!CantidadSolicitada && !CantidadEntregada)) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else if (
            (CantidadRecibida && CantidadEntregada! > Number(CantidadRecibida)) ||
            (CantidadSolicitada && CantidadEntregada! > Number(CantidadSolicitada))
        ) {
            notificacionGlobal('despachos.alerta.cantidadEntregadaInvalida', 6000, 'warning', true);
            return false;
        } else return true;
    }

    public async modificarDocumentoDetalles(documentoPedido: IDocumentoConDetallesAccion) {
        return this.modeloDocumentoDetalle.modificarDocumentoDetallesDespachos(documentoPedido);
    }
    public consultarDocumentoCompuestoDetalles(documentoDetalleCompuesto: Partial<IDocumentoDetalleCompuesto>) {
        return this.modeloDocumentoDetalle
            .ConsultarDocumentoCompuestoDetalles(documentoDetalleCompuesto)
            .then(({ data: { Entidades } }) => Entidades);
    }
}
