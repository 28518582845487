import React from 'react';
import { connect } from 'react-redux';

import { IMenu } from '@infotrack/presentacion-modulosbase';
import { IModulo } from '@infotrack/presentacion-transversales/interfacesComunes';

import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import Modulos from 'Infotrack@Transversales/rutasModulos';

import { IRutasCVProps } from '../interfaces';
import RutasVista from '../vista/rutasVista';

interface IEstadoAPropiedadesRutasCV {
    menus: IMenu[];
    rutaActiva: string;
}

const RutasCV: React.FunctionComponent<IRutasCVProps & IEstadoAPropiedadesRutasCV> = ({
    menus,
    rutaBaseActiva,
    rutaActiva,
}) => {
    const [modulos, setModulos] = React.useState<Array<IModulo<React.ReactNode>>>([]);

    const primeraCarga = React.useRef(true);

    const cargarRutas = () => {
        const rutasAplicacion: string[] = [];
        menus.forEach((menu) => {
            menu.SubMenu.forEach((submenu) => {
                rutasAplicacion.push(submenu.RutaSubMenu.toLowerCase());
            });
        });
        const modulosAplicacion = Modulos.reduce(
            (modulosFiltrados: Array<IModulo<React.ReactNode>>, modulo: IModulo<React.ReactNode>) => {
                const { rutaModulo, subModulos, noValidarPermisos } = modulo;
                const moduloAEvaluar = { ...modulo };
                const contieneModulo =
                    rutasAplicacion.filter((rutaApp) => rutaApp.includes(rutaModulo.toLowerCase())).length !== 0 ||
                    noValidarPermisos;
                if (contieneModulo) {
                    const nuevosSubModulos = subModulos.filter(
                        ({ rutaSubModulo }) =>
                            rutasAplicacion.filter((rutaApp) =>
                                rutaApp.includes(
                                    `${rutaActiva.toLowerCase()}/${rutaModulo.toLowerCase()}/${rutaSubModulo.toLowerCase()}`
                                )
                            ).length !== 0 || noValidarPermisos
                    );
                    if (nuevosSubModulos.length !== 0) {
                        moduloAEvaluar.subModulos = nuevosSubModulos;
                        modulosFiltrados.push(moduloAEvaluar);
                    }
                }
                return modulosFiltrados;
            },
            []
        );
        setModulos(modulosAplicacion);
    };

    if (primeraCarga.current) {
        cargarRutas();
        primeraCarga.current = false;
    }

    return <RutasVista rutaBaseActiva={rutaBaseActiva} rutaActiva={rutaActiva} modulos={modulos} />;
};

const estadoAPropiedades = (estadoGlobal: IEstadoGlobal): IEstadoAPropiedadesRutasCV => ({
    menus: estadoGlobal.estadoAutenticacion.menus,
    rutaActiva: estadoGlobal.estadoConfiguracion.rutaActiva,
});

export default connect<IEstadoAPropiedadesRutasCV, null, any, IEstadoGlobal>(estadoAPropiedades, null)(RutasCV);
