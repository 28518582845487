import EmisorEventos from '@infotrack/presentacion-utilitarios/emisorEventos';
import { IDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes/interfaces';
import React from 'react';
import DialogoGlobalVista from '../vista/dialogoGlobalVista';

const estadoInicial: IDialogoGlobal = {
    accionCancelar: () => {},
    accionConfirmar: () => {},
    estado: false,
    mensaje: '',
    mostrarCancelar: false,
    tipoDialogo: 'Informativa',
    titulo: '',
};

const DialogoGlobalCV = () => {
    const [configDialogo, setConfigDialogo] = React.useState<IDialogoGlobal>(estadoInicial);
    const [estadoDialogo, setEstadoDialogo] = React.useState<boolean>(false);
    const cargaInicial = React.useRef(true);

    React.useEffect(() => {
        return () => {
            EmisorEventos.eliminarObservador('MANEJAR_ALERTA');
        };
    }, []);

    const manejarEstadoDialog = (config: IDialogoGlobal = estadoInicial) => {
        if (config.estado) setConfigDialogo({ ...configDialogo, ...config });
        setEstadoDialogo(config.estado);
    };

    if (cargaInicial.current) {
        EmisorEventos.agregarObservador('MANEJAR_ALERTA', manejarEstadoDialog);
        cargaInicial.current = false;
    }

    const reiniciarDialogo = () => {
        setConfigDialogo(estadoInicial);
    };

    return (
        <DialogoGlobalVista
            accionCancelar={configDialogo.accionCancelar}
            accionConfirmar={configDialogo.accionConfirmar}
            estado={estadoDialogo}
            mensaje={configDialogo.mensaje}
            mostrarCancelar={configDialogo.mostrarCancelar}
            tipoDialogo={configDialogo.tipoDialogo}
            reiniciarDialogo={reiniciarDialogo}
            titulo={configDialogo.titulo}
        />
    );
};

export default DialogoGlobalCV;
