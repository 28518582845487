import { createStyles, Theme } from '@material-ui/core';
import { paletaMaterialD } from '@infotrack/presentacion-transversales/constantes';

export const estilosDesplegableItems = (tema: Theme) =>
    createStyles({
        contenedorSelectMasivo: {
            '& .MuiExpansionPanelSummary-content': {
                margin: '8px 0',
            },
            '& .MuiExpansionPanelDetails-root': {
                padding: '8px 24px 0px',
            },
        },
        contenedorItems: { maxHeight: '45vh', overflowY: 'auto' },
        contendorTarjetasFiltros: { padding: tema.spacing(2), '&:last-child': { paddingBottom: tema.spacing(2) } },
        descripciones: { marginRight: tema.spacing(2) },
        itemSeleccionado: { backgroundColor: '#e0e0e0' },
        listaDivisiones: { width: '100%' },
        textoSinRegistros: { margin: tema.spacing(2, 0) },
        tituloSelect: { justifyContent: 'center' },
        listaProductos: { width: '100%' },
    });
