import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import ModeloDocumento from 'Infotrack@Modelos/smartStock/documentos';
import IFiltrosDocumentos from 'Infotrack@Modelos/smartStock/documentos/entidades/filtrosDocumentosMovimientos';
import moment from 'moment';

export default class DocumentoCM {
    private modeloDocumento: ModeloDocumento;

    constructor() {
        this.modeloDocumento = new ModeloDocumento();
    }

    public consultarListaDocumentosAuditoria = (paginador: IPaginador, documentos: IFiltrosDocumentos) =>
        this.modeloDocumento
            .consultarListaDocumentosAuditoriaCompuestos({ paginador, documentos })
            .then(({ data }) => data);

    public validarFiltros({ BodegaConsulta, FechaInicial, FechaFinal }: IFiltrosDocumentos) {
        if (!BodegaConsulta) {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
            return false;
        } else if (FechaInicial || FechaFinal) {
            if (moment(FechaInicial).isSameOrAfter(FechaFinal)) {
                notificacionGlobal('auditoriasinventario.alerta.fechasInicialInvalida', 6000, 'warning', true);
                return false;
            }
            if ((FechaInicial && !FechaFinal) || (FechaFinal && !FechaInicial)) {
                notificacionGlobal('auditoriasinventario.alerta.fechaIncompleta', 6000, 'warning', true);
                return false;
            }
        }
        return true;
    }
}
