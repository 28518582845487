import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import React from 'react';
import DocumentoDetalleCM from '../controladorModelo/documentoDetalleCM';
import { acciones, IEstadoDevoluciones, IInputsDocumentoDetalle } from '../interfaces';
import FormularioDocumentosDetalleVista from '../vista/formularioDetallesVista';

const valorInicialFormulario: IInputsDocumentoDetalle = {
    CantidadEntregada: '',
    CantidadRecibida: '',
    CantidadSolicitada: '',
    DescripcionItem: '',
};

const documentoDetalleCM = new DocumentoDetalleCM();

const FormularioDocumentoDetalleCV: React.FunctionComponent = () => {
    const { setValor, reiniciar, valor } = useInputState(valorInicialFormulario);
    const [{ estadoFormularioDetalle, entidadDocumentoDetalle }, dispatch] = useProveedor<IEstadoDevoluciones>();

    const theme = useTheme();
    const esMovil = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        if (entidadDocumentoDetalle) {
            const { DivisionDestino, DivisionOrigen, FechaVencimiento, CantidadEntregada } = entidadDocumentoDetalle;
            setValor({
                ...entidadDocumentoDetalle,
                CantidadEntregada: CantidadEntregada ? CantidadEntregada : '',
                DivisionDestino: DivisionDestino ? DivisionDestino : '',
                DivisionOrigen: DivisionOrigen ? DivisionOrigen : '',
                FechaVencimiento: FechaVencimiento ? FechaVencimiento : null,
            } as any);
        }
    }, [entidadDocumentoDetalle]);

    const alCambiarValor = ({
        target,
    }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { checked, name, type, value } = target as EventTarget & HTMLInputElement;
        setValor({ ...valor, [name]: type === 'checkbox' ? checked : value });
    };

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
        reiniciar();
    };

    const alGuardarDocumentoDetalle = () => {
        const nuevoDocumentoDetalle = mapearDetalle();
        if (documentoDetalleCM.validarDocumentoDetalle(nuevoDocumentoDetalle)) {
            alCerrarFormulario();
            dispatch({ type: acciones.EDITAR_DOCUMENTO_DETALLE, payload: nuevoDocumentoDetalle });
        }
    };

    const mapearDetalle = (): IDocumentoDetalleCompuesto => {
        const { CantidadEntregada } = valor;
        return {
            ...valor,
            CantidadEntregada: Number(CantidadEntregada),
        } as any;
    };

    return (
        <FormularioDocumentosDetalleVista
            alCambiarValor={alCambiarValor}
            alCerrarFormulario={alCerrarFormulario}
            alGuardarDocumentoDetalle={alGuardarDocumentoDetalle}
            documentoDetalle={valor}
            estadoFormulario={estadoFormularioDetalle}
            esMovil={esMovil}
        />
    );
};

export default FormularioDocumentoDetalleCV;
