import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    TextField,
    Toolbar,
} from '@material-ui/core';
import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import { BotonBuscar, BotonLimpiar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import FormularioEdicionDetallesAuditoriasCV from '../controladorVista/formularioEdicionDetallesAuditoriasCV';
import { ITablaEdicionDetallesAuditoriasVistaProps } from '../interfaces';
import {
    accionesDetallesAuditorias,
    accionesMovimientos,
    columnasDetallesAuditorias,
    columnasMovimientos,
    opcionesDetalleAuditoria,
    opcionesDiferencias,
} from './constantes';

const TablaEdicionDetallesAuditoriasVista: React.FunctionComponent<ITablaEdicionDetallesAuditoriasVistaProps &
    InjectedIntlProps> = ({
    alAbrirFormularioDetalle,
    alCambiarValor,
    alCerrarFormulario,
    alEliminarDetalle,
    alGenerarDiferencias,
    confirmarCambioDeEstadoAuditoria,
    consultaDetallesAuditoria,
    consultaMovimientos,
    consultarDetallesAuditoria,
    consultarMovimientos,
    documento,
    divisiones,
    esUnicoConteo,
    estadoFormularioDetalles,
    intl: { formatMessage },
    filtros,
    generarDiferencias,
    refTabla,
    refTablaMovimientos,
}) => (
    <Dialog fullWidth open={estadoFormularioDetalles} maxWidth="lg">
        <AppBar position="static">
            <Toolbar>
                <Grid container justifyContent="center">
                    <Texto align="center" id="gestionauditorias.titulo.editarDetallesAuditoria" />
                </Grid>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container justifyContent="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Texto id="gestionauditorias.datosDocumento" fontWeight="bold" />
                                </Grid>
                                <Grid item md xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="gestionauditorias.BodegaOrigenDescripcion" />}
                                        InputProps={{ readOnly: true }}
                                        name="BodegaOrigen"
                                        variant="outlined"
                                        value={documento!.BodegaOrigenDescripcion}
                                    />
                                </Grid>
                                <Grid item md xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="gestionauditorias.etapaDescripcion" />}
                                        InputProps={{ readOnly: true }}
                                        name="EtapaDescripcion"
                                        variant="outlined"
                                        value={documento!.EtapaDescripcion}
                                    />
                                </Grid>
                                <Grid item md xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="gestionauditorias.observaciones" />}
                                        name="Observacion"
                                        variant="outlined"
                                        value={documento!.Observacion}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid alignItems="center" container justifyContent="space-between" spacing={2}>
                                <Grid item xs={12}>
                                    <Texto id="gestionauditorias.busquedaDetalles" fontWeight="bold" />
                                </Grid>
                                <Grid item md xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="gestionauditorias.Detalles.CodigoBarras" />}
                                        onChange={alCambiarValor}
                                        name="CodigoBarras"
                                        value={filtros.CodigoBarras}
                                    />
                                </Grid>
                                <Grid item md xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="gestionauditorias.Detalles.DescripcionItem" />}
                                        onChange={alCambiarValor}
                                        name="DescripcionItem"
                                        value={filtros.DescripcionItem}
                                    />
                                </Grid>
                                <Grid item md xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="gestionauditorias.Detalles.Division" />}
                                        onChange={alCambiarValor}
                                        name="DivisionId"
                                        value={filtros.DivisionId}
                                        select
                                    >
                                        {divisiones.map(({ DivisionDescripcion, DivisionId }) => (
                                            <MenuItem key={DivisionId} value={DivisionId}>
                                                {DivisionDescripcion}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item md="auto" xs={12}>
                                    <Grid alignItems="center" container justifyContent="space-between" spacing={2}>
                                        <Grid item md="auto" xs={6}>
                                            <BotonLimpiar fullWidth onClick={() => consultarDetallesAuditoria(true)} />
                                        </Grid>
                                        <Grid item md="auto" xs={6}>
                                            <BotonBuscar fullWidth onClick={() => consultarDetallesAuditoria()} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Tabla
                        actions={accionesDetallesAuditorias(
                            alAbrirFormularioDetalle,
                            consultarDetallesAuditoria,
                            alEliminarDetalle,
                            formatMessage
                        )}
                        columns={columnasDetallesAuditorias(formatMessage, esUnicoConteo)}
                        data={consultaDetallesAuditoria}
                        tableRef={refTabla}
                        title={<Texto id="gestionauditorias.detallesDelDocumento" fontWeight="bold" />}
                        options={opcionesDetalleAuditoria}
                    />
                </Grid>
                {!generarDiferencias && (
                    <Grid item xs="auto">
                        <Button color="primary" onClick={alGenerarDiferencias} variant="contained">
                            <Texto id="gestionauditorias.boton.generarDiferencias" />
                        </Button>
                    </Grid>
                )}
                {generarDiferencias && (
                    <Grid item xs={12}>
                        <Tabla
                            actions={accionesMovimientos(consultarMovimientos, formatMessage)}
                            columns={columnasMovimientos(formatMessage)}
                            data={consultaMovimientos}
                            title={<Texto id="gestionauditorias.titulo.productoSinContar" fontWeight="bold" />}
                            tableRef={refTablaMovimientos}
                            options={opcionesDiferencias}
                        />
                    </Grid>
                )}
            </Grid>
            <FormularioEdicionDetallesAuditoriasCV />
        </DialogContent>
        <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xs="auto">
                    <Button color="secondary" onClick={alCerrarFormulario} variant="contained">
                        <Texto
                            id={
                                documento && documento.EtapaDescripcion !== TiposEtapas.Cerrada
                                    ? 'boton.cancelar'
                                    : 'boton.cerrar'
                            }
                        />
                    </Button>
                </Grid>
                {documento && documento.EtapaDescripcion !== TiposEtapas.Cerrada && (
                    <>
                        <Grid item xs="auto">
                            <Button color="primary" onClick={confirmarCambioDeEstadoAuditoria} variant="contained">
                                <Texto id="gestionauditorias.modificarEtapa" />
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Button color="primary" onClick={alCerrarFormulario} variant="contained">
                                <Texto id="boton.guardar" />
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
        </DialogActions>
    </Dialog>
);

export default injectIntl(TablaEdicionDetallesAuditoriasVista);
