import React, { ChangeEvent } from 'react';
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    InputAdornment,
    MenuItem,
    TextField,
} from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';

import Texto from '@infotrack/presentacion-componentes/texto';
import { esEnteroODecimal } from '@infotrack/utilitarios/expresionesRegulares';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import IAgencia from '@infotrack/presentacion-modulosbase/modelos/seguridad/agencia/entidades/IAgencia';

import { IDatosGeneralesVistaProps } from '../interfaces';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';

const CustomTextField = (props: TextFieldProps) => (
    <TextField
        {...props}
        fullWidth
        required={props.required !== undefined ? props.required : true}
        value={props.value ? props.value : ''}
        variant="outlined"
    />
);

const DatosGeneralesVista: React.FunctionComponent<IDatosGeneralesVistaProps> = ({
    alCambiarValor,
    alCambiarValorAutocomplete,
    alCrearEditarBodega,
    bodega,
    bodegas,
    municipios,
    tiposBodega,
    tipoFormulario,
    listaAgencias,
}) => (
    <Grid container spacing={4}>
        <Grid item sm={6}>
            <Card>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Texto id="gestionBodega.titulo.datosBodega" fontWeight="bold" />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextField
                                inputProps={{ maxLength: 250 }}
                                label={<Texto display="inline" id="gestionBodega.BodegaDescripcion" />}
                                onChange={alCambiarValor}
                                name="BodegaDescripcion"
                                value={bodega.BodegaDescripcion}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextField
                                inputProps={{ maxLength: 50 }}
                                label={<Texto display="inline" id="gestionBodega.BodegaCodigo" />}
                                onChange={alCambiarValor}
                                name="BodegaCodigo"
                                value={bodega.BodegaCodigo}
                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <CustomTextField
                                inputProps={{ maxLength: 200 }}
                                label={<Texto display="inline" id="gestionBodega.Direccion" />}
                                onChange={alCambiarValor}
                                name="Direccion"
                                value={bodega.Direccion}
                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <CustomTextField
                                label={<Texto display="inline" id="gestionBodega.TipoBodegaDescripcion" />}
                                onChange={alCambiarValor}
                                name="TipoBodegaId"
                                select
                                value={bodega.TipoBodegaId}
                                helperText={
                                    tipoFormulario === TipoFormulario.Edicion &&
                                    !tiposBodega.find(
                                        (x) =>
                                            x.TipoBodegaId === parseInt(bodega.TipoBodegaId) &&
                                            x.Estado === estadoActivo
                                    ) ? (
                                        <Texto id="gestionBodega.TipoBodegaInactiva" />
                                    ) : (
                                        ''
                                    )
                                }
                                error={
                                    tipoFormulario === TipoFormulario.Edicion &&
                                    !tiposBodega.find(
                                        (x) =>
                                            x.TipoBodegaId === parseInt(bodega.TipoBodegaId) &&
                                            x.Estado === estadoActivo
                                    )
                                        ? true
                                        : false
                                }
                            >
                                {tiposBodega.filter((x) => x.Estado === true || x.Estado === 1).length === 0 ? (
                                    <MenuItem value="">
                                        <Texto id="label.sinRegistros" />
                                    </MenuItem>
                                ) : (
                                    tiposBodega
                                        .filter((x) => x.Estado === true || x.Estado === 1)
                                        .map(({ TipoBodegaDescripcion, TipoBodegaId }) => (
                                            <MenuItem key={TipoBodegaId} value={TipoBodegaId}>
                                                {TipoBodegaDescripcion}
                                            </MenuItem>
                                        ))
                                )}
                            </CustomTextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                getOptionLabel={({ NombreMunicipio }: any) => NombreMunicipio}
                                onChange={(_: any, ciudad: any) => alCambiarValorAutocomplete(ciudad, 'Ciudad')}
                                options={municipios.filter((x) => x.EstadoMunicipio === 1)}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        required
                                        label={<Texto display="inline" id="gestionBodega.DescripcionCiudad" />}
                                    />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={bodega.Ciudad}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                getOptionLabel={({ BodegaDescripcion }) => BodegaDescripcion}
                                onChange={(_: any, bodegaPadre: any) =>
                                    alCambiarValorAutocomplete(bodegaPadre, 'BodegaPadre')
                                }
                                options={bodegas.filter((x) => x.Estado === 1)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={<Texto display="inline" id="gestionBodega.BodegaPadre" />}
                                        helperText={
                                            tipoFormulario === TipoFormulario.Edicion &&
                                            bodega.BodegaPadreId !== null &&
                                            !bodegas.find(
                                                (x) =>
                                                    (bodega.BodegaPadre !== null &&
                                                        x.BodegaId === bodega.BodegaPadre.BodegaPadreId) ||
                                                    (bodega.BodegaPadreId === x.BodegaId && x.Estado === estadoActivo)
                                            ) ? (
                                                <Texto id="gestionBodega.BodegaPadreInactiva" />
                                            ) : (
                                                ''
                                            )
                                        }
                                    />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={bodega.BodegaPadre}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                getOptionLabel={({ NombreAgencia }) => NombreAgencia}
                                onChange={(_: ChangeEvent<{}>, value: IAgencia | null) =>
                                    alCambiarValorAutocomplete(value !== null ? value.IdAgencia : '', 'AgenciaId')
                                }
                                options={listaAgencias}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={<Texto display="inline" id="gestionBodega.Agencia" />}
                                      required={tipoFormulario === TipoFormulario.Creacion}
                                    />
                                )}
                                disabled={tipoFormulario === TipoFormulario.Edicion}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={listaAgencias.find((a) => a.IdAgencia === bodega.AgenciaId) || null}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={bodega.Estado} onChange={alCambiarValor} />}
                                label={<Texto id="gestionBodega.Estado" display="inline" />}
                                name="Estado"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item sm={6}>
            <Card>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Texto id="gestionBodega.titulo.medidasBodega" fontWeight="bold" />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Texto id="gestionBodega.medida.dimensiones" />
                                        </InputAdornment>
                                    ),
                                }}
                                label={<Texto display="inline" id="gestionBodega.Ancho" />}
                                onChange={(e) =>
                                    esEnteroODecimal(e.target.value, {
                                        cantidadEnteros: 6,
                                        cantidadDecimales: 2,
                                    }) && alCambiarValor(e)
                                }
                                name="Ancho"
                                value={bodega.Ancho}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Texto id="gestionBodega.medida.dimensiones" />
                                        </InputAdornment>
                                    ),
                                }}
                                label={<Texto display="inline" id="gestionBodega.Largo" />}
                                onChange={(e) =>
                                    esEnteroODecimal(e.target.value, {
                                        cantidadEnteros: 6,
                                        cantidadDecimales: 2,
                                    }) && alCambiarValor(e)
                                }
                                name="Largo"
                                value={bodega.Largo}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Texto id="gestionBodega.medida.dimensiones" />
                                        </InputAdornment>
                                    ),
                                }}
                                label={<Texto display="inline" id="gestionBodega.Alto" />}
                                onChange={(e) =>
                                    esEnteroODecimal(e.target.value, {
                                        cantidadEnteros: 6,
                                        cantidadDecimales: 2,
                                    }) && alCambiarValor(e)
                                }
                                name="Alto"
                                value={bodega.Alto}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextField
                                label={<Texto display="inline" id="gestionBodega.Latitud" />}
                                onChange={(e) => alCambiarValor(e)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Texto id="gestionBodega.medida.coordenadas" />
                                        </InputAdornment>
                                    ),
                                }}
                                name="Latitud"
                                value={bodega.Latitud}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextField
                                label={<Texto display="inline" id="gestionBodega.Longitud" />}
                                onChange={(e) => alCambiarValor(e)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Texto id="gestionBodega.medida.coordenadas" />
                                        </InputAdornment>
                                    ),
                                }}
                                name="Longitud"
                                value={bodega.Longitud}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item container justifyContent="flex-end" spacing={2}>
            <Grid item>
                <Button onClick={alCrearEditarBodega} color="primary" variant="contained">
                    <Texto id={tipoFormulario === TipoFormulario.Creacion ? 'boton.guardar' : 'boton.editar'} />
                </Button>
            </Grid>
        </Grid>
    </Grid>
);

export default DatosGeneralesVista;
