import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import Texto from '@infotrack/presentacion-componentes/texto';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IClientes from 'Infotrack@Modelos/smartStock/clientes/entidades/clientes';
import IDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/documento';
import IDocumentoCompuestoMovimiento from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuestoMovimiento';
import IFiltrosDocumentos from 'Infotrack@Modelos/smartStock/documentos/entidades/filtrosDocumentosMovimientos';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import DocumentosCM from '../controladorModelo/documentosCM';
import EntidadesFiltrosDocumentoCM from '../controladorModelo/entidadesFiltrosDocumentoCM';
import { IInputsFiltrosDocumentos, ITablaDespachosCVProps } from '../interfaces';
import { acciones, IEstadoDespachos } from '../reductorDespachos';
import TablaDespachosVista from '../vista/tablaDespachosVista';

const documentosCM = new DocumentosCM();
const entidadesFiltrosDocumentoCM = new EntidadesFiltrosDocumentoCM();

const valoresInicialesFiltros: IInputsFiltrosDocumentos = {
    Cliente: null,
    CodigoDocumento: '',
    TipoDocumentoId: '',
    TipoMovimientoDescripcion: '',
    BodegaDescripcion: '',
    FechaCreacion: null,
    FechaVencimiento: null,
    BodegaId: '',
    EtapaId: '',
};

const TablaDespachosCV: React.FunctionComponent<ITablaDespachosCVProps> = ({ IdAgencia, IdEmpresa, IdUsuario }) => {
    const [bodegas, setBodegas] = React.useState<IBodega[]>([]);
    const [clientes, setClientes] = React.useState<IClientes[]>([]);
    const [etapas, setEtapas] = React.useState<IEtapas[]>([]);
    const { alCambiarValor, reiniciar, valor } = useInputState<IInputsFiltrosDocumentos>(valoresInicialesFiltros);
    const [{ recargarTabla }, dispatch] = useProveedor<IEstadoDespachos>();

    const refTabla = React.createRef<any>();

    React.useEffect(() => {
        cargarEntidadesFiltros();
    }, []);

    React.useEffect(() => {
        if (recargarTabla && valor.BodegaId) {
            cargarDocumentos();
            dispatch({ type: acciones.RECARGAR_TABLA });
        }
    }, [recargarTabla]);

    const alAbrirFormulario = (documento?: IDocumentoCompuestoMovimiento) => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
        if (documento)
            dispatch({
                type: acciones.ESTABLECER_ENTIDAD_DOCUMENTO,
                payload: {
                    ...documento,
                    DocumentoPadre: documento.DocumentoPadre ? documento.DocumentoPadre : false,
                    DocumentoPadreId: documento.DocumentoPadreId ? documento.DocumentoPadreId : '',
                },
            });
    };

    const mapearFiltros = (): IFiltrosDocumentos => ({
        BodegaConsulta: valor.BodegaId ? Number(valor.BodegaId) : null,
        ClienteId: valor.Cliente ? valor.Cliente.ClienteId : null,
        CodigoDocumento: valor.CodigoDocumento ? valor.CodigoDocumento : null,
        EmpresaId: IdEmpresa,
        EtapaId: valor.EtapaId ? Number(valor.EtapaId) : null,
        FechaFinal: valor.FechaVencimiento ? valor.FechaVencimiento : null,
        FechaInicial: valor.FechaCreacion ? valor.FechaCreacion : null,
        TipoDocumentoId: valor.TipoDocumentoId ? Number(valor.TipoDocumentoId) : null,
        UsuarioId: IdUsuario,
    });

    const consultaDocumentos = paginacionRemota(
        documentosCM.consultarListaDocumentosPedidos,
        mapearFiltros(),
        'FechaCreacion',
        !Boolean(valor.BodegaId)
    );

    const cargarDocumentos = async (limpiarFiltros?: boolean) => {
        if (limpiarFiltros) {
            reiniciar();
            refTabla.current.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
        } else if (documentosCM.validarFiltros(mapearFiltros()))
            refTabla.current.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
    };
 
    const cargarEntidadesFiltros = () => {
        entidadesFiltrosDocumentoCM
            .consultarEntidadesFiltrosDocumento({ AgenciaId: IdAgencia, UsuarioId: IdUsuario, EmpresaId: IdEmpresa })
            .then(({ bodegas: bodegasCargadas, clientes: clientesCargados, etapas: etapasCargadas }) => {
                setBodegas(bodegasCargadas);
                setClientes(clientesCargados);
                setEtapas(etapasCargadas);
            });
    }; 

    const confimarAnulacion = (documento: IDocumento) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => anularDocumento(documento),
            estado: true,
            mensaje: <Texto id="alerta.confirmacionAnulación" />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const anularDocumento = (documento: IDocumento) => {
        manejadorDialogoGlobal({ estado: false });
        documentosCM.anularDocumento(documento).then(() => {
            cargarDocumentos();
        });
    };

    return (
        <TablaDespachosVista
            alAbrirFormulario={alAbrirFormulario}
            alAnularDocumento={confimarAnulacion}
            alCambiarValor={alCambiarValor}
            cargarDocumentos={cargarDocumentos}
            consultaDocumentos={consultaDocumentos}
            entidadesFiltro={{ bodegas, clientes, etapas }}
            filtrosPedidos={valor}
            refTabla={refTabla}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion, agenciaActual },
}: IEstadoGlobal): ITablaDespachosCVProps => ({
    IdEmpresa: usuarioInformacion!.IdEmpresa,
    IdAgencia: agenciaActual!.IdAgencia,
    IdUsuario: usuarioInformacion!.IdUsuario,
});

export default connect<ITablaDespachosCVProps, null, any, IEstadoGlobal>(estadoAPropiedades, null)(TablaDespachosCV);
