import notificacionGlobal from "@infotrack/presentacion-componentes/notificacionGlobal";
import ModeloPlantilla from "Infotrack@Modelos/smartStock/plantilla";
import IPlantilla from "Infotrack@Modelos/smartStock/plantilla/entidades/IPlantilla";

export default class PlantillaServicioCM {

  private modeloPlantillaServicio: ModeloPlantilla;

  constructor() {
    this.modeloPlantillaServicio = new ModeloPlantilla();
  }

  public async DescargarPlantilla(plantilla: IPlantilla) {

    const respuesta = await this.modeloPlantillaServicio.DescargarPlantilla(plantilla);
    return respuesta.data as string;
  }

  

 
}