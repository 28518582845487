import { MenuItem, TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';

interface ICampoEtapaIdProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampo: keyof IAuditoriaCompuesta,
        nuevoValor: IAuditoriaCompuesta[keyof IAuditoriaCompuesta]
    ) => any;
    cambiarEtapa: (etapaId: number,esEtapaFinalizado:boolean) => void;
    etapaSiguiente: IEtapas[];
    valor: string | undefined;
}

const CampoEtapaId: FunctionComponent<ICampoEtapaIdProps> = ({
    deshabilitado,
    manejarCambioCampo,
    cambiarEtapa,
    etapaSiguiente,
    valor,
}) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="Auditoria.CampoEtapaId.Nombre" />}
        onChange={(event) => manejarCambioCampo('EtapaDocumento', event.target.value)}
        value={valor}
    >
        {etapaSiguiente.map((etapa) => (
            <MenuItem key={etapa.EtapaId} value={etapa.EtapaDescripcion}>
                {etapa.EtapaDescripcion}
            </MenuItem>
        ))}
    </TextField>
);

export default CampoEtapaId;
