import { createStyles, Theme } from '@material-ui/core';

const estilos = ({ palette }: Theme) =>
  createStyles({
    contenedorMenu: { overflow: 'auto', height: '55%', width: '100%' },
    menuSeleccionado: {
      backgroundColor: `${palette.primary.main} !important`,
      borderLeft: `3px solid ${palette.primary.dark}`,
    },
    textoMenu: {
      color: palette.text.secondary,
    },
    textoMenuSeleccionado: {
      color: palette.primary.contrastText,
    },
    textoSubmenus: {
      paddingLeft: 15,
      wordBreak: 'break-word',
    },
  });

export default estilos;
