import { AnyAction } from 'redux';

import {
    CERRAR_SESION_CONFIGURACION,
    SELECCIONAR_LENGUAJE,
    SELECCIONAR_MENU,
    SELECCIONAR_RUTA_ACTIVA,
    SELECCIONAR_SUBMENU,
    SELECCIONAR_TEMA,
} from 'Infotrack@Acciones/tiposAcciones';
import { obtenerConfiguracionApp } from 'Infotrack@Transversales/utilitarios/funcionesGenerales';

import { IEstadoConfiguracion } from './interfacesReductores';

const estadoPersistido = obtenerConfiguracionApp();

export default (estado = estadoPersistido.estadoConfiguracion, action: AnyAction): IEstadoConfiguracion => {
    switch (action.type) {
        case CERRAR_SESION_CONFIGURACION:
            return {
                ...estado,
                lenguajeActivo: 'es',
                menuSeleccionado: 0,
                rutaActiva: 'app',
                subMenuSeleccionado: '',
                temaActivo: 'SmartStock',
            };
        case SELECCIONAR_RUTA_ACTIVA:
            return {
                ...estado,
                rutaActiva: action.payload,
            };
        case SELECCIONAR_TEMA:
            return {
                ...estado,
                temaActivo: action.payload,
            };
        case SELECCIONAR_LENGUAJE:
            return {
                ...estado,
                lenguajeActivo: action.payload,
            };
        case SELECCIONAR_MENU:
            return {
                ...estado,
                menuSeleccionado: action.payload,
            };
        case SELECCIONAR_SUBMENU:
            return {
                ...estado,
                subMenuSeleccionado: action.payload,
            };
        default:
            return estado;
    }
};
