import ModeloGestionBodegas from 'Infotrack@Modelos/smartStock/bodegas';
import IBodegaPersonaFiltro from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaPersonaFiltro';

export default class EntidadesFiltroDocumentosCM {
    private modeloGestionBodegas: ModeloGestionBodegas;

    constructor() {
        this.modeloGestionBodegas = new ModeloGestionBodegas();
    }

    public consultarEntidadesFiltro(bodegaPersonaFiltro: IBodegaPersonaFiltro) {
        return Promise.all([this.modeloGestionBodegas.consultarBodegasPersonasDoc(bodegaPersonaFiltro)]).then(
            (entidades) => ({
                bodegas: entidades[0].data.Entidades,
            })
        );
    }
}
