import { AnyAction } from 'redux';

import { IAgencia } from '@infotrack/presentacion-modulosbase';

import {
    CERRAR_SESION_AUTENTICACION,
    ESTABLECER_MENUS,
    ESTABLECER_PERMISOS,
    ESTABLECER_USUARIO_INFORMACION,
    SELECCIONAR_AGENCIA,
    SELECCIONAR_USUARIO_APLICACION_AGENCIA,
} from 'Infotrack@Acciones/tiposAcciones';
import { obtenerConfiguracionApp } from 'Infotrack@Transversales/utilitarios/funcionesGenerales';

import { IEstadoAutenticacion } from './interfacesReductores';

const estadoPersistido = obtenerConfiguracionApp();

export default (estado = estadoPersistido.estadoAutenticacion, action: AnyAction): IEstadoAutenticacion => {
    switch (action.type) {
        case CERRAR_SESION_AUTENTICACION:
            return {
                ...estado,
                agenciaActual: null,
                menus: [],
                permisos: [],
                usuarioAplicacionAgenciaActual: null,
                usuarioInformacion: null,
            };
        case ESTABLECER_USUARIO_INFORMACION:
            return {
                ...estado,
                usuarioInformacion: action.payload,
            };
        case ESTABLECER_MENUS:
            return {
                ...estado,
                menus: action.payload,
            };
        case ESTABLECER_PERMISOS:
            return {
                ...estado,
                permisos: action.payload,
            };
        case SELECCIONAR_USUARIO_APLICACION_AGENCIA:
            return {
                ...estado,
                usuarioAplicacionAgenciaActual: action.payload,
            };
        case SELECCIONAR_AGENCIA:
            const agencia: IAgencia = action.payload;
            return {
                ...estado,
                agenciaActual: agencia,
            };
        default:
            return estado;
    }
};
