export default {
    'sidebar.procesos': 'Processes',
    'sidebar.recepcion': 'Reception',
    'recepcion.bodega': 'warehouse',
    'recepcion.proveedor': 'Provider',
    'recepcion.numeroDocumento': 'N° Document',
    'recepcion.confirmarRecepcion': 'Confirm reception',
    'recepcion.documentos': 'Documents',
    'recepcion.documentoPadre': 'Parent document',
    'recepcion.datosDocumento': 'Document Data',
    'recepcion.detallesDocumento': 'Document details',
    'recepcion.bodegaDestino': 'Destiny warehouse',
    'recepcion.observaciones': 'Observations',
    'recepcion.etapaDescripcion': 'Stage',
    'recepcion.documento.FechaEntrega': 'Creation date',
    'recepcion.documentoDetalle.DescripcionItem': 'Product',
    'recepcion.documentoDetalle.CodigoBarras': 'Barcode',
    'recepcion.documentoDetalle.CantidadSolicitada': 'Requested amount',
    'FormularioDetallesRecepcion.CantidadSolicitada': 'Cantidad',
    'recepcion.documentoDetalle.CantidadRecibida': 'Received amount',
    'recepcion.documentosdocumentoDetalle.CantidadActual': ' Current ammount',
    'recepcion.documentoDetalle.iniciarRecepcion': 'Start reception',
    'recepcion.documentoDetalle.confirmarRecepcion': 'Confirm reception',
    'recepcion.documentos.verDetalle': 'See detail',
    'recepcion.documentos.detalle': 'Detail document',
    'recepcion.documentos.alertaFiltroBodega': 'Please select a warehouse',
    'recepcion.documentos.alertaFiltroProveedorDocumento': 'Please select a search field',
    'recepcion.documentos.alertaFiltroProveedorSinBodega': 'To filter by supplier you must select a winery',
    'recepcion.documentos.alertaLotes': 'This product requires a serial number',
    'recepcion.documentos.alertaSeriado': 'This product requires registering a lot number',
    'recepcion.documentos.alertaCantidadRecibida': 'The amount received must be greater than previously entered',
    'recepcion.documentos.alertaFIFO': 'This product requires an expiration date',
    'recepcion.documentosdocumentoDetalle.edicionParcial': 'Partial',
    'recepcion.documentosdocumentoDetalle.edicionTotal': 'Total',
    'recepcion.documentosdocumentoDetalle.recepcion': 'Reception',
    'recepcion.documentosdocumentoDetalle.DivisionDestino': 'Destination division',
    'recepcion.documentosdocumentoDetalle.CantidadRecibida': 'Amount',
    'recepcion.documentosdocumentoDetalle.Lote': 'Lot',
    'recepcion.documentosdocumentoDetalle.FechaVencimiento': 'Expiration date',
    'recepcion.documentosdocumentoDetalle.Serial': 'Serial',
    'recepcion.confirmacionInicioRecepcion': 'Are you sure you want to start receiving?',
    'recepcion.confirmacionFinRecepcion': 'Are you sure you want to finish the reception?',
    'recepcion.procesos.campoBodegaObligatorio': 'The winery field is mandatory',

    // TablaRecepcion
    'TablaRecepcion.CodigoDocumentoPredecesor': 'Code',
    'TablaRecepcion.DocumentoRelacionadoPredecesor': 'Related document',
    'TablaRecepcion.CodigoDocumento': 'Child document code',
    'TablaRecepcion.FechaCreacion': 'Creation date',
    'TablaRecepcion.DescripcionProveedor': 'Supplier',
    'TablaRecepcion.EtapaDescripcionDocumentoPredecesor': 'Stage',
    'TablaRecepcion.ObservacionDocumentoPredecesor': 'Observation',
    'TablaRecepcion.BotonGestionarRecepcion': 'Manage reception',
    'TablaRecepcion.BotonAnular': 'Void',
    'TablaRecepcion.MensajeConfirmacionAnularDocumento': 'Are you sure that you want to void the document?',
    // ModalRecepcion
    'ModalRecepcion.TituloTablaDetallesPlantilla': 'Purchase order details',
    'ModalRecepcion.ValidacionNoCantidad': 'Quantity must be greater than 0',
    'ModalRecepcion.ValidacionNoDivisionOrigen': 'You must select an origin division',
    'ModalRecepcion.ValidacionNoDivisionDestino': 'You must select a destination division',
    'ModalRecepcion.ValidacionNoLote': 'You must specify a lot',
    'ModalRecepcion.ValidacionSerialesIncompletos': 'You must complete all serials',
    'ModalRecepcion.TituloTablaDetalles': 'Reception details',
    'ModalRecepcion.BotonConfirmarRecepcion': 'Confirm reception',
    'ModalRecepcion.ConfirmacionFinalizarRecepcion': 'Are you sure you want to end the reception?',
    // FormularioDetallesRecepcion
    'FormularioDetallesRecepcion.Titulo': 'Add details',
    'FormularioDetallesRecepcion.CantidadRecibida': 'Quantity',
    'FormularioDetallesRecepcion.DivisionDestino': 'Destination division',
    'FormularioDetallesRecepcion.Lote': 'Lot',
    'FormularioDetallesRecepcion.Valor': 'Weight',
    'FormularioDetallesRecepcion.FechaVencimiento': 'Expiration date',
    'FormularioDetallesRecepcion.TooltipAsignarSeriales': 'Assign serials',
};
