import Proveedor from '@infotrack/presentacion-componentes/proveedorEstado';
import React from 'react';
import FormularioDespachosCV from './controladorVista/formularioDespachosCV';
import TablaDespachosCV from './controladorVista/tablaDespachosCV';
import { estadoInicial, reductor } from './reductorDespachos';

const Despachos: React.FunctionComponent = () => (
    <Proveedor estadoInicial={estadoInicial} reductor={reductor}>
        <TablaDespachosCV />
        <FormularioDespachosCV />
    </Proveedor>
);

export default Despachos;
