import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IClientes from 'Infotrack@Modelos/smartStock/clientes/entidades/clientes';
import IEstadisticasDashboard from 'Infotrack@Modelos/smartStock/estadisticasDashboard/entidades/estadisticasDashboard';
import EstadoCumplimientoPedido from 'Infotrack@Modelos/smartStock/estadisticasDashboard/entidades/estadoCumplimientoPedido';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import EntidadesFiltroCM from '../controladorModelo/entidadesFiltrosCM';
import EstadisticasDashboardCM from '../controladorModelo/estadisticasDashboardCM';
import { IInputsFiltros, IMonitorInventarioCVProps } from '../interfaces';
import { acciones, IEstadoMonitorPedidos } from '../reductorMonitorPedidos';
import MonitorPedidosVista from '../vista/monitorPedidosVista';

const entidadesFiltroCM = new EntidadesFiltroCM();
const estadisticasDashboardCM = new EstadisticasDashboardCM();
const estadoInicialFiltros: IInputsFiltros = {
    Bodega: null,
    Cliente: null,
};

const MonitorPedidosCV: React.FunctionComponent<IMonitorInventarioCVProps> = ({ AgenciaId, EmpresaId, UsuarioId }) => {
    const [bodegas, setBodegas] = React.useState<IBodega[]>([]);
    const [clientes, setClientes] = React.useState<IClientes[]>([]);
    const [dataEstadoPedidos, setDataEstadoPedidos] = React.useState<IEstadisticasDashboard[]>([]);
    const [dataCumplimientoPedidos, setDataCumplimientoPedidos] = React.useState<IEstadisticasDashboard[]>([]);
    const [dataCumplimientoPedidosHistorico, setDataCumplimientoPedidosHistorico] = React.useState<
        IEstadisticasDashboard[]
    >([]);
    const [dataTop10PedidosClientes, setDataTop10PedidosClientes] = React.useState<IEstadisticasDashboard[]>([]);
    const { setValor, valor } = useInputState(estadoInicialFiltros);
    const [_, dispatch] = useProveedor<IEstadoMonitorPedidos>();
    const cargaInicial = React.useRef(true);

    React.useEffect(() => {
        consultarEntidadesFiltros();
    }, []);

    React.useEffect(() => {
        if (cargaInicial.current && valor.Bodega) {
            consultarDataGraficas();
            cargaInicial.current = false;
        }
    }, [valor]);

    const consultarEntidadesFiltros = () => { 
        entidadesFiltroCM
            .consultarEntidadesFiltros({ AgenciaId, UsuarioId, EmpresaId })
            .then(({ bodegas: bodegasCargadas, clientes: clientesCargados }) => {
                setBodegas(bodegasCargadas);
                setClientes(clientesCargados);
                if (bodegasCargadas[0]) setValor({ ...valor, Bodega: bodegasCargadas[0] });
            });
    };

    const consultarDataGraficas = () => {
        estadisticasDashboardCM
            .consultarEstadisticasDashboard({
                EmpresaId,
                BodegaId: Number(valor.Bodega!.BodegaId),
                ClienteId: valor.Cliente ? valor.Cliente!.ClienteId : undefined,
            })
            .then(({ estadosPedidos, pedidosVigentes, pedidosHistoricos, pedidosClientes }) => {
                setDataEstadoPedidos(estadosPedidos);
                setDataCumplimientoPedidos(pedidosVigentes);
                setDataCumplimientoPedidosHistorico(pedidosHistoricos);
                setDataTop10PedidosClientes(pedidosClientes);
            });
    };

    const alAbrirFormularioEstadoPedidos = (etapaId: number) => {
        dispatch({
            type: acciones.ESTABLECER_FILTROS_ESTADISTICAS,
            payload: {
                bodegaId: Number(valor.Bodega!.BodegaId),
                etapaId,
                clienteId: valor.Cliente ? valor.Cliente!.ClienteId : undefined,
            },
        });
        dispatch({ type: acciones.CAMBIAR_FORMULARIO_ESTADO_PEDIDOS });
    };

    const alAbrirFormularioCumplimientoPedidos = (estadoCumplimientoPedido: EstadoCumplimientoPedido) => {
        dispatch({
            type: acciones.ESTABLECER_FILTROS_ESTADISTICAS,
            payload: {
                bodegaId: Number(valor.Bodega!.BodegaId),
                estadoCumplimientoPedido,
                clienteId: valor.Cliente ? valor.Cliente!.ClienteId : undefined,
            },
        });
        dispatch({ type: acciones.CAMBIAR_FORMULARIO_ESTADO_CUMPLIMIENTO_PEDIDOS });
    };

    const alCambiarValor = (nombreValor: string, nuevoValor: IInputsFiltros[keyof IInputsFiltros]) => {
        setValor({ ...valor, [nombreValor]: nuevoValor });
    };

    return (
        <MonitorPedidosVista
            alAbrirFormularioCumplimientoPedidos={alAbrirFormularioCumplimientoPedidos}
            alAbrirFormularioEstadoPedidos={alAbrirFormularioEstadoPedidos}
            alCambiarValor={alCambiarValor}
            alFiltrarRegistros={consultarDataGraficas}
            dataEstadoPedidos={dataEstadoPedidos}
            dataCumplimientoPedidos={dataCumplimientoPedidos}
            dataCumplimientoPedidosHistorico={dataCumplimientoPedidosHistorico}
            dataTop10PedidosClientes={dataTop10PedidosClientes}
            entidadesFiltros={{ bodegas, clientes }}
            filtros={valor}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion, agenciaActual },
}: IEstadoGlobal): IMonitorInventarioCVProps => ({
    AgenciaId: agenciaActual!.IdAgencia,
    EmpresaId: usuarioInformacion!.IdEmpresa,
    UsuarioId: usuarioInformacion!.IdUsuario,
});

export default connect<IMonitorInventarioCVProps, null, any, IEstadoGlobal>(estadoAPropiedades, null)(MonitorPedidosCV);
