import IAccionEtapaDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAccionEtapaDocumento';
import ModeloAccionEtapaDocumento from 'Infotrack@Modelos/negocioRefactor/modelos/Administracion/ModeloAccionEtapaDocumento';

class AccionEtapaDocumentoCM {
    private modeloAccionEtapaDocumento: ModeloAccionEtapaDocumento;

    constructor() {
        this.modeloAccionEtapaDocumento = new ModeloAccionEtapaDocumento();
    }

    public async consultarAccionesDocumento(tipoDocumentoId: number) {
        const respuesta = await this.modeloAccionEtapaDocumento.consultarAccionesDocumento(tipoDocumentoId);
        return respuesta.data.Entidades;
    }

    public async guardarAccionesEtapaDocumento(accionesEtapaDocumento: IAccionEtapaDocumento[]) {
        await this.modeloAccionEtapaDocumento.guardarAccionesEtapaDocumento(accionesEtapaDocumento);
    }
}

export default AccionEtapaDocumentoCM;
