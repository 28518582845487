import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import IDocumentoCompuesto from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuesto';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import EstadisticasDashboardCM from '../controladorModelo/estadisticasDashboardCM';
import { ITablaPedidosEstadosCVProps } from '../interfaces';
import { acciones, IEstadoMonitorPedidos } from '../reductorMonitorPedidos';
import TablaCumplientoPedidosVista from '../vista/tablaCumplientoPedidosVista';

const estadisticasDashboardCM = new EstadisticasDashboardCM();

const TablaPedidosEstadosCV: React.FunctionComponent<ITablaPedidosEstadosCVProps> = ({ EmpresaId }) => {
    const [{ filtrosEstadisticas, formularioEstadoCumplimientoPedidos }, dispatch] = useProveedor<
        IEstadoMonitorPedidos
    >();
    const [documentos, setDocumentos] = React.useState<IDocumentoCompuesto[]>([]);

    React.useEffect(() => {
        if (formularioEstadoCumplimientoPedidos) consultarDocumentos();
    }, [formularioEstadoCumplimientoPedidos]);

    const consultarDocumentos = async () => {
        setDocumentos(
            await estadisticasDashboardCM.consultarListaCumplimientoPedidos({
                EtapaId: filtrosEstadisticas.etapaId,
                BodegaOrigen: filtrosEstadisticas.bodegaId,
                ClienteId: filtrosEstadisticas.clienteId,
                EmpresaId,
                EstadoCumplimientoPedido: filtrosEstadisticas.estadoCumplimientoPedido,
            } as any)
        );
    };

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_FORMULARIO_ESTADO_CUMPLIMIENTO_PEDIDOS });
        setDocumentos([]);
    };

    return (
        <TablaCumplientoPedidosVista
            alCerrarFormulario={alCerrarFormulario}
            documentos={documentos}
            formularioCumplimientoPedidos={formularioEstadoCumplimientoPedidos}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion },
}: IEstadoGlobal): ITablaPedidosEstadosCVProps => ({
    EmpresaId: usuarioInformacion!.IdEmpresa,
});

export default connect<ITablaPedidosEstadosCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(TablaPedidosEstadosCV);
