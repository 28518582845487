import {
    AppBar,
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Toolbar,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import React from 'react';
import TablaDocumentoDetallesCV from '../controladorVista/tablaDocumentoDetallesCV';
import { IFormularioDocumentosVistaProps } from '../interfaces';

const FormularioDocumentosVista: React.FunctionComponent<IFormularioDocumentosVistaProps> = ({
    alCambiarEtapa,
    alCerrarFormulario,
    alCambiarValor,
    alCambiarValorAutocomplete,
    alGuardarDocumento,
    alEditarDocumento,
    documento,
    entidadesDocumento,
    estadoFormulario,
    tipoFormularioDocumento,
    tipoDocumento: abreviaturaTipoDocumento,
}) => (
    <Dialog open={estadoFormulario} fullWidth maxWidth="lg">
        <AppBar position="static">
            <Toolbar>
                <Grid container justifyContent="center">
                    <Texto
                        align="center"
                        id={
                            tipoFormularioDocumento === TipoFormulario.Edicion
                                ? 'gestionmovimientos.documento.editarDocumento'
                                : 'gestionmovimientos.documento.crearDocumento'
                        }
                    />
                </Grid>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid alignItems="center" container spacing={2}>
                                <Grid item xs={12}>
                                    <Texto id="gestionmovimientos.documento.datosDocumento" fontWeight="bold" />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <Autocomplete
                                        disabled={tipoFormularioDocumento !== TipoFormulario.Creacion}
                                        disableClearable
                                        getOptionLabel={({ BodegaDescripcion }) => BodegaDescripcion}
                                        onChange={(_: any, bodegaOrigen: any) =>
                                            alCambiarValorAutocomplete('BodegaOrigen', bodegaOrigen)
                                        }
                                        options={entidadesDocumento.bodegas}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                fullWidth
                                                label={
                                                    <Texto
                                                        display="inline"
                                                        id="gestionmovimientos.documento.BodegaOrigen"
                                                    />
                                                }
                                            />
                                        )}
                                        noOptionsText={<Texto id="label.sinRegistros" />}
                                        value={documento.BodegaOrigen!}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12} hidden>
                                    <Autocomplete
                                        disableClearable
                                        disabled
                                        getOptionLabel={({ BodegaDescripcion }) => BodegaDescripcion}
                                        onChange={(_: any, bodegaDestino: any) =>
                                            alCambiarValorAutocomplete('BodegaDestino', bodegaDestino)
                                        }
                                        options={entidadesDocumento.bodegas}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                fullWidth
                                                label={
                                                    <Texto
                                                        display="inline"
                                                        id="gestionmovimientos.documento.BodegaDestino"
                                                    />
                                                }
                                            />
                                        )}
                                        noOptionsText={<Texto id="label.sinRegistros" />}
                                        value={documento.BodegaDestino!}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <Autocomplete
                                        getOptionLabel={({ DescripcionProveedor }) => DescripcionProveedor}
                                        disabled={tipoFormularioDocumento !== TipoFormulario.Creacion}
                                        onChange={(_: any, proveedor: any) =>
                                            alCambiarValorAutocomplete('Proveedor', proveedor)
                                        }
                                        options={entidadesDocumento.proveedores}
                                        noOptionsText={<Texto id="label.sinRegistros" />}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                required
                                                label={<Texto id="gestionmovimientos.documento.ProveedorId" />}
                                            />
                                        )}
                                        value={documento.Proveedor}
                                    />
                                </Grid>
                                {tipoFormularioDocumento !== TipoFormulario.Creacion && (
                                    <Grid item md={3} xs={12}>
                                        <TextField
                                            label={<Texto id="gestionmovimientos.documento.EtapaDescripcion" />}
                                            InputProps={{ readOnly: true }}
                                            fullWidth
                                            value={documento.EtapaDescripcion}
                                            variant="outlined"
                                        />
                                    </Grid>
                                )}
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        label={<Texto id="gestionmovimientos.documento.DocumentoRelacionado" />}
                                        name="DocumentoRelacionado"
                                        InputProps={{ readOnly: tipoFormularioDocumento !== TipoFormulario.Creacion }}
                                        onChange={alCambiarValor}
                                        fullWidth
                                        value={documento.DocumentoRelacionado}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        label={<Texto id="gestionmovimientos.documento.Observacion" />}
                                        name="Observacion"
                                        InputProps={{ readOnly: tipoFormularioDocumento !== TipoFormulario.Creacion }}
                                        onChange={alCambiarValor}
                                        fullWidth
                                        value={documento.Observacion}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        disabled={documento.DocumentoPadre}
                                        label={
                                            <Texto
                                                display="inline"
                                                id="gestionmovimientos.documento.DocumentoPadreId"
                                            />
                                        }
                                        name="DocumentoPadreId"
                                        InputProps={{ readOnly: tipoFormularioDocumento !== TipoFormulario.Creacion }}
                                        onChange={alCambiarValor}
                                        fullWidth
                                        select
                                        value={documento.DocumentoPadreId}
                                        variant="outlined"
                                    >
                                        <MenuItem value="">
                                            <Texto id="label.select.ninguno" />
                                        </MenuItem>
                                        {entidadesDocumento.documentosPadre.map(({ CodigoDocumento, DocumentoId }) => (
                                            <MenuItem key={DocumentoId} value={DocumentoId}>
                                                {CodigoDocumento}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(documento.DocumentoPadre)}
                                                color="primary"
                                                onChange={(e) =>
                                                    tipoFormularioDocumento !== TipoFormulario.Visualizacion &&
                                                    alCambiarValor(e)
                                                }
                                            />
                                        }
                                        label={
                                            <Texto id="gestionmovimientos.documento.DocumentoPadre" display="inline" />
                                        }
                                        name="DocumentoPadre"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <TablaDocumentoDetallesCV />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button color="secondary" onClick={alCerrarFormulario} variant="contained">
                        <Texto id="boton.cerrar" />
                    </Button>
                </Grid>
                {documento &&
                    tipoFormularioDocumento !== TipoFormulario.Creacion &&
                    documento.EtapaDescripcion !== TiposEtapas.Revertido &&
                    documento.EtapaDescripcion !== TiposEtapas.Anulado &&
                    documento.EtapaDescripcion !== TiposEtapas.Cerrada && (
                        <Grid item>
                            <Button color="primary" onClick={alCambiarEtapa} variant="contained">
                                <Texto id="gestionmovimientos.documento.cambiarEtapa" />
                            </Button>
                        </Grid>
                    )}
                {documento &&
                    documento.EtapaDescripcion !== TiposEtapas.Cerrada &&
                    documento.EtapaDescripcion !== TiposEtapas.EnDevolucion &&
                    documento.EtapaDescripcion !== TiposEtapas.Revertido && (
                        <Grid item>
                            <Button
                                color="primary"
                                onClick={
                                    tipoFormularioDocumento === TipoFormulario.Creacion
                                        ? alGuardarDocumento
                                        : alEditarDocumento
                                }
                                variant="contained"
                            >
                                <Texto id="boton.guardar" />
                            </Button>
                        </Grid>
                    )}
            </Grid>
        </DialogActions>
    </Dialog>
);

export default FormularioDocumentosVista;
